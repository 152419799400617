import _ from "lodash";
import { createSelector } from "reselect";
import { RESIGN_WITHOUT_CHANGES } from "../constants";
import differenceInDays from "date-fns/differenceInDays";
import parseISO from "date-fns/parseISO";

/**
 * Calculate existing resign / product instance price
 * It's the sum of all the evo services.
 * According to @davet there are no extra pricing rule shenanigans as DC is telling Evo the prices. Not vice versa.
 * Still kinda worried about `TerminationFeeDiscountPercentage` though
 * @param {object} resign product instance
 * @returns {string}
 */
export const getResignPrice = ({ evo_services = [] }) => {
  // TODO: Lets compute this in a transform when API data comes in.
  let price = 0;
  evo_services.forEach(s => {
    price += s.RateBeforeDiscount;
  });
  return price;
};

/**
 * Get Resign Product Instance for a given config
 * @param state
 * @param configId
 * @returns {*}
 */
export const getResignProductInstanceByConfig = (state, configId) => {
  const { resignId } = state.mobile.configs[configId];
  if (!resignId) return false;
  return _.get(state.mobile.productInstances.response, "results", []).find(
    r => r.id === resignId
  );
};

export const getResignProductInstanceById = (mobileState, id) =>
  _.get(mobileState.productInstances.response, "results", []).find(
    r => r.id === id
  );

/**
 * Get the supplier for a given mobile product ID
 *
 * @param state
 * @param productId
 * @returns {undefined}
 */
export const getProductSupplier = (state, productId) => {
  const product = state.mobileSearch.response.products.find(
    p => p.id === productId
  );
  return _.get(product, "first_mobile_component.supplier");
};

/**
 * Some tariff descriptions are misleading as they refer to generic voice products apparently.
 * As specified by @lisa & @joannes hey're overridden by the name of the next evo service
 * ...which will hopefully be the one users recognise
 *
 * @param instance
 * @returns {*}
 */
export const getNonGenericInstanceName = instance => {
  // const excludeNames = [
  //     'VODAFONE DATA',
  //     'VDC - VODAFONE DATA',
  //     'O2DC - O2 Data'
  //     ]
  // if(excludeNames.includes(instance.name)) {
  //     const otherService = instance.evo_services.find(s => !excludeNames.includes(s.Description))
  //     if(otherService) return otherService.Description
  // }
  // return instance.name

  // ...this has now been sorted DC side as it was needed for display on the contracts, so:

  return instance.name_for_resign || instance.name;
};

/**
 * Returns if the order contains resigns without change
 * @param state
 * @returns {boolean}
 */
export const hasResignsWithoutChange = state => {
  let result = false;
  state.mobile.configs.forEach(c => {
    if (c.resignType === RESIGN_WITHOUT_CHANGES) result = true;
  });
  return result;
};

/**
 * Check if any mobile configs are resigns
 * @param configs - state.mobile.configs[]
 * @returns {boolean}
 */
export const hasResigns = configs => configs.findIndex(c => c.resignType) > -1;

/**
 * Get the reign without change product ID
 * ...a generic product returned by DC from v1/Product/Search
 * @param state
 * @returns {any}
 */
export const getResignWithoutChangeProduct = state =>
  _.get(state.mobile.resignProductSearch, "response.products[0].id");

/**
 * Compute the Early Termination Fee applicable to selected resigns.
 * Months left on the contract * price
 *
 * @type {Reselect.Selector<any, any>}
 */
export const getETFPrice = createSelector(
  [
    state => state.mobile.configs,
    state => state.mobile.productInstances.response.results
  ],
  (configs, productInstances) => {
    if (!productInstances) return;
    const price = configs.reduce((total, config) => {
      if (!config.resignId) return total;
      const resignInstance = productInstances.find(
        i => i.id === config.resignId
      );
      const endDate = resignInstance.end_date_ymd;
      const remainingDays = differenceInDays(parseISO(endDate), new Date());
      if (remainingDays > 0) {
        return (
          total + Math.ceil(remainingDays / 28) * getResignPrice(resignInstance)
        );
      } else {
        return total;
      }
    }, 0);
    return price;
  }
);
