import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { setOrderContact } from "../../store/order/actions";
import SelectContact from "../../components/Shared/Contact/SelectContact";
import { IndexedCardInfo } from "../../screens/shared/indexed-card-info";

export default function Contacts({ cardIndex }) {
  const selectedId = useSelector(state => state.order.orderContactId);
  const dispatch = useDispatch();

  return (
    <IndexedCardInfo index={cardIndex} title="Contact">
      <Box display="flex" alignItems="flex-end">
        <SelectContact
          current={selectedId}
          handleUpdate={id => dispatch(setOrderContact(id))}
        />
      </Box>
    </IndexedCardInfo>
  );
}
