import React from "react";
import PropTypes from "prop-types";
import StarIcon from "@material-ui/icons/Star";

export default function InlinePriceIndicator({ style = {} }) {
  return <StarIcon color="primary" style={style} />;
}

InlinePriceIndicator.propTypes = {
  style: PropTypes.object
};
