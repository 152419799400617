import u from "updeep";
import _ from "lodash";
import {
  BROADBAND_ONLY,
  NEW_FTTP,
  NEW_LINE,
  RESIGN,
  TRANSFER
} from "../constants";
import { addSpaceToPostcode } from "../../../helpers/addresses";
import { addWeekdays } from "../../../helpers/date";
import { getIsMPFLine, getIsWLTO } from "../selectors";
import { getRandomString } from "../../../helpers/maths";
import { v4 as uuidv4 } from "uuid";

export default function setBroadbandProduct(action, state) {
  // Copies params of selected product from BB search to configuration.
  // They will be needed to fetch GuidedSale/ProductData and later for the OrderProduct/Create call

  const config = state.configurations[action.configurationIndex];
  const location = state.locations[config.locationIndex];
  const productId = action.productId || action.resignProductId;
  const broadbandProductInstanceId = action.productInstanceId;
  const product =
    _.get(location, "broadbandSearch.response.products", []).find(
      product => product.id === productId
    ) ||
    _.get(state, "broadbandResignProductSearch.response.products", []).find(
      product => product.id === productId
    );
  const { ont_details } = location.lineAvailability.response;

  const isWLTO = getIsWLTO(state, config.locationIndex);

  // Note we can't use getIsMPFLine here because product hasn't been set (this is happening now!)
  // This is only applied via Lubo's (necessary) hack in setNewLineConfiguration
  const isMpfProduct = action.isMpfProduct;

  // If it's broadband on a new line, or a working line takeover, a "Simultaneous Provide" reference should be generated.
  const simProvideRef =
    (location.type === NEW_LINE && !isMpfProduct) || isWLTO
      ? `SIM2${Math.floor(Math.random() * 900000 + 100000)}`
      : false;
  return u(
    {
      configurations: {
        [action.configurationIndex]: {
          broadbandProductId: productId,
          ...(config.type !== "mpf" && { broadbandProductInstanceId }),
          broadbandProperties: productId
            ? {
                as_frontend_uuid: uuidv4(),
                // These params will be needed to fetch GuidedSale/ProductData and later for the OrderProduct/Create call
                contract_length_in_months: state.contractLength,
                product_id: productId, // Duplicate for verification API call

                // These have to be copied over for printing on the contracts I'm pretty sure.
                // Why this doesn't happen in DC I don't know.
                "bb.estimated_download_range":
                  product.first_broadband_component.estimated_download_range,
                "bb.estimated_upload_range":
                  product.first_broadband_component.estimated_upload_range,
                "bb.minimum_guaranteed_speed":
                  product.first_broadband_component.minimum_guaranteed_speed,

                // This is a dynamic property, but shouldn't be user selected, hence this...
                // See `get orderType()` from old version.
                // TODO: Is this fttp technology type? Will it work ootb?
                "bb.order_type": `${
                  product.first_broadband_component.type
                } ${(() => {
                  if (location.type === NEW_LINE) return "Provide";
                  switch (product.first_broadband_component.activation_code) {
                    case "A":
                    case "L":
                    case "G":
                      return "Migrate";
                    case "Z":
                    case "E":
                      return "Provide";
                    default:
                      //for mpf?!
                      return "Migrate";
                  }
                })()}`,
                "bb.activation_code":
                  product.first_broadband_component.activation_code,
                "bb.market_code": product.first_broadband_component.market_code,
                ...((location.type === TRANSFER ||
                  location.type === RESIGN ||
                  location.type === BROADBAND_ONLY) &&
                  !isWLTO && {
                    "bb.cli": location.cli.value
                  }),

                // Install address
                "bb.address.addressReference":
                  location.address.addressReference,
                "bb.address.cssDatabaseCode": location.address.cssDatabaseCode,
                "bb.address.postcode": addSpaceToPostcode(
                  location.address.postcode
                ),
                "bb.address.postTown": location.address.postTown,
                "bb.address.county": location.address.county, // Note this isn't in product data currently but needed for new account field mapping
                "bb.address.premisesName": location.address.premisesName,
                "bb.address.thoroughfareName":
                  location.address.thoroughfareName,
                "bb.address.thoroughfareNumber":
                  location.address.thoroughfareNumber ||
                  location.address.subPremises, // Why is there not a bb.address.subPremises available though? See FB153313

                // Business Directory address
                // TODO: Is bb.residentialDirectory needed here too or are we ignoring it as we are B2B?
                ...(getIsMPFLine(config) && {
                  "bb.businessDirectory.address.addressReference":
                    location.address.addressReference,
                  "bb.businessDirectory.address.postcode": addSpaceToPostcode(
                    location.address.postcode
                  ),
                  "bb.businessDirectory.address.postTown":
                    location.address.postTown,
                  "bb.businessDirectory.address.premisesName":
                    location.address.premisesName,
                  "bb.businessDirectory.address.thoroughfareName":
                    location.address.thoroughfareName,
                  "bb.businessDirectory.address.thoroughfareNumber":
                    location.address.thoroughfareNumber,
                  "bb.businessDirectory.type": "ORDINARY"
                }),

                // Router Address
                // ...required no matter if it's for delivery to site or a different address
                // These fields are editable by the user in the router tab.
                // Also see delivery name and company fields populated in setProductProperty
                "router.building_name": location.address.premisesName,
                "router.door_number":
                  location.address.thoroughfareNumber ||
                  location.address.subPremises,
                "router.postcode": addSpaceToPostcode(
                  location.address.postcode
                ),
                "router.street": location.address.thoroughfareName,
                "router.town": location.address.postTown,
                "router.county": location.address.county,

                // This postcode doesn't appear anywhere in product data.
                // Somehow it's still required to pass DC validation though.
                "bb.postcode": addSpaceToPostcode(location.address.postcode),

                ...(simProvideRef && { "bb.sim_provide_ref": simProvideRef }),
                // Radius config
                "radius.radius_username": `${location.cli.value ||
                  location.address.postcode}${getRandomString(3)}`,
                "radius.radius_password": getRandomString(),

                // Note radius.radius_realm_id is also defaulted. ProductData is needed for that though
                // so see RECEIVE_PRODUCT_DATA action.

                // Required by date
                // TODO: Shouldn't this match the actual appointment is set?
                "bb.required_by_date": _.get(
                  product,
                  "first_broadband_component.estimated_lead_time.appointment",
                  addWeekdays(new Date(), 10, true)
                ),

                order_provisioning: 1, // TODO: This relates to orders vs quotes. Probably need to add logic back to differentiate. ...although quote is rarely used apparently.

                // FTTP Params
                ...(location.type === NEW_FTTP && {
                  // ONT details
                  "bb.ONTType":
                    ont_details.ont_spare_data_ports > 0
                      ? ont_details.ont_type
                      : "NEW", // This gets passed through to SI, hence the camelCase
                  "bb.ontReference": ont_details.ont_reference,
                  "bb.mdu_build_complete": ont_details.mdu_build_complete,
                  "bb.tma_marker_present": ont_details.tma_marker_present,
                  "bb.tma_notice_required": ont_details.tma_notice_required,
                  "bb.tma_permit_required": ont_details.tma_permit_required,
                  "bb.site_visit_reason":
                    ont_details.ont_spare_data_ports > 0 ||
                    ont_details.mdu_build_complete === "1"
                      ? "NO_SITE_VISIT"
                      : "STANDARD" // TP21765: default relevant use cases to NO_SITE_VISIT
                })
              }
            : () => ({}), // "None" is selected, hence reset.

          // Enter the Sim provide ref on the line product too if we need one
          ...(simProvideRef && {
            wlrProperties: {
              adsl_reference: simProvideRef
            }
          })
        }
      }
    },
    state
  );
}
