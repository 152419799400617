import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import VoiceOverOffIcon from "@material-ui/icons/VoiceOverOff";
import Section from "../../../../../components/Shared/Section";
import BulkDynamicField from "../BulkDynamicField";
import { fetchBarsCompatibility } from "../../../../../store/mobile/actionCreators";
import Spinner from "../../../../../components/Shared/Spinner";
import { Grid } from "@material-ui/core";

class Bars extends Component {
  static defaultProps = {};
  static propTypes = {
    targetConfigs: PropTypes.arrayOf(PropTypes.number).isRequired
  };

  state = {};

  componentDidMount() {
    this.props.fetchBarsCompatibility(this.props.targetConfigs[0]);
  }

  render() {
    const {
      targetConfigs,
      productData,
      properties,
      barsCompatibility = {},
      fetchBarsCompatibility
    } = this.props;

    const barDisabled = name =>
      !_.get(barsCompatibility, "response.valid_ids", []).includes(name) &&
      !properties[name] == 1; // eslint-disable-line eqeqeq

    return (
      <Section
        title="Bars"
        avatar={barsCompatibility.fetching ? <Spinner /> : <VoiceOverOffIcon />}
        defaultExpanded={false}
      >
        <Grid container spacing={2}>
          {Object.keys(productData.mobile.dynamic_properties)
            .sort((a, b) => {
              return a < b ? 1 : a > b ? -1 : 0;
            })
            .map((name, i) => {
              if (name.includes("bar_")) {
                return (
                  <Grid item sm={6} key={i}>
                    <BulkDynamicField
                      propertyName={name}
                      afterUpdate={() => {
                        fetchBarsCompatibility(targetConfigs[0]);
                      }}
                      disabled={barDisabled(name)}
                    />
                  </Grid>
                );
              }
              return false;
            })}
        </Grid>
      </Section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const config = state.mobile.configs[ownProps.targetConfigs[0]];
  return {
    productData: state.mobile.productData[config.productId].response,
    barsCompatibility: config.barsCompatibility,
    properties: config.properties
  };
};

export default connect(mapStateToProps, {
  fetchBarsCompatibility
})(Bars);
