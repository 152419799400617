import { useMemo } from "react";

interface CardIndexesProps {
  phoneRequired?: boolean;
  tabletRequired?: boolean;
  dongleRequired?: boolean;
  phoneQuestionAnswered: boolean;
  tabletQuestionAnswered: boolean;
  dongleQuestionAnswered: boolean;
}

/**
 * Get indexes of a hardware cards (old version with multiple tables)
 */
export const useCardIndexes = ({
  phoneRequired,
  tabletRequired,
  dongleRequired,
  phoneQuestionAnswered,
  tabletQuestionAnswered,
  dongleQuestionAnswered
}: CardIndexesProps) =>
  useMemo(() => {
    const response = {
      phoneQuestion: 1,
      phoneTable: 0,
      tabletQuestion: 0,
      tabletTable: 0,
      dongleQuestion: 0,
      dongleTable: 0
    };
    let lastIndex = 1;

    if (phoneQuestionAnswered) {
      if (phoneRequired) {
        lastIndex += 1;
        response.phoneTable = lastIndex;
      }
      lastIndex += 1;
      response.tabletQuestion = lastIndex;
    }

    if (tabletQuestionAnswered) {
      if (tabletRequired) {
        lastIndex += 1;
        response.tabletTable = lastIndex;
      }
      lastIndex += 1;
      response.dongleQuestion = lastIndex;
    }

    if (dongleQuestionAnswered) {
      if (dongleRequired) {
        lastIndex += 1;
        response.dongleTable = lastIndex;
      }
    }

    return response;
  }, [
    phoneRequired,
    tabletRequired,
    dongleRequired,
    phoneQuestionAnswered,
    tabletQuestionAnswered,
    dongleQuestionAnswered
  ]);

export const useTableHeaders = (hasConnection: boolean) =>
  useMemo(() => {
    return [
      {
        label: "Product",
        sortIndex: "name"
      },
      {
        label: "Daisy Fresh",
        // TODO: not yet existing
        sortIndex: "",
        width: 300
      },
      {
        label: "Pay Up Front",
        sortIndex: `price.${hasConnection ? "oneOffWithConnection" : "oneOff"}`,
        width: 280
      }
    ];
  }, [hasConnection]);
