import u from "updeep";
import * as actionType from "../../actionTypes";
import newLocation from "../newLocation";
import * as accountActionTypes from "../../../account/actionTypes";

export default (state, action) => {
  switch (action.type) {
    case accountActionTypes.RECEIVE_ACCOUNT_SETTINGS:
      if (action.response?.settings?.dws_reseller_enabled === "1") {
        return u({ contractLength: 1 }, state);
      } else {
        return state;
      }
    case actionType.SET_CONTRACT_LENGTH:
      return u(
        {
          contractLength: action.contractLength, // Per order, hence not stored with locations or configs.
          configurations: []
        },
        state
      );

    case actionType.REQUEST_LINE_SEARCH:
      return u(
        {
          lineSearch: {
            fetching: true
          }
        },
        state
      );

    case actionType.RECEIVE_LINE_SEARCH:
      return u(
        {
          lineSearch: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    case actionType.ADD_LOCATION:
      return u(
        {
          locations: l => [...l, newLocation]
        },
        state
      );

    case actionType.REMOVE_LOCATION:
      return u(
        {
          locations: ls => [
            ...ls.slice(0, action.index),
            ...ls.slice(action.index + 1)
          ],
          configurations: cs => {
            // remove configurations of the removed location
            const filteredCs = cs.filter(c => c.locationIndex !== action.index);

            // decrement every locationIndex after the removed location
            return filteredCs.map(({ locationIndex, ...rest }) => ({
              locationIndex:
                locationIndex > action.index ? --locationIndex : locationIndex,
              ...rest
            }));
          }
        },
        state
      );

    case actionType.REMOVE_PRODUCTS_FOR_LOCATION:
      return u(
        {
          configurations: u.reject(c => c.locationIndex === action.index)
        },
        state
      );

    default:
      return state;
  }
};
