import {
  FAILED_TO_SAVE_DRAFT,
  INVALID_LOAD_DRAFT,
  INVALID_SAVE_DRAFT,
  RECEIVE_DRAFT,
  REQUEST_LOAD_DRAFT,
  REQUEST_SAVE_DRAFT,
  SAVED_DRAFT
} from "./actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case REQUEST_SAVE_DRAFT:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case INVALID_SAVE_DRAFT:
      return Object.assign({}, state, {
        didInvalidate: true,
        isFetching: false,
        error: action.error
      });
    case SAVED_DRAFT:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false
      });
    case FAILED_TO_SAVE_DRAFT:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        error: action.error
      });
    case REQUEST_LOAD_DRAFT:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case INVALID_LOAD_DRAFT:
      return Object.assign({}, state, {
        didInvalidate: true,
        isFetching: false,
        error: action.error
      });
    case RECEIVE_DRAFT:
      state = action.draft;
      return state;
    default:
      return state;
  }
};
