import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfigProperty } from "../../../../../../store/mobile/actionCreators";
import BulkDynamicField from "../../BulkDynamicField";
import EmailIcon from "@material-ui/icons/Email";

type Props = {
  targetConfigs: string[];
};

export const ResellerBillingNotification = ({ targetConfigs }: Props) => {
  const dispatch = useDispatch();
  const { bill_limit, additional_notification } = useSelector(
    (state: any) => state.mobile.configs
  )[targetConfigs[0]]?.properties;

  useEffect(() => {
    let addNotif, preference;
    if (bill_limit === "" || bill_limit === undefined) {
      addNotif = "0";
      preference = "";
    } else {
      addNotif = "1";
      preference = "email";
    }
    dispatch(
      updateConfigProperty("additional_notification", addNotif, targetConfigs)
    );
    dispatch(
      updateConfigProperty("notification_preference", preference, targetConfigs)
    );
  }, [bill_limit, dispatch, targetConfigs]);

  // eslint-disable-next-line eqeqeq
  if (additional_notification == 1) {
    return (
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <EmailIcon />
        </Box>
        <BulkDynamicField propertyName="notification_contact" />
      </Box>
    );
  }

  return null;
};
