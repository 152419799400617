import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import { DialogContent as Content } from "./DialogContent";

export interface BulkOrderUploadProps {
  open: boolean;
  handleClose: () => void;
}

export const BulkOrderUpload = ({
  open,
  handleClose
}: BulkOrderUploadProps) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Create Bulk Order Request</DialogTitle>
      <DialogContent>
        <Content />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
