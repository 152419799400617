import React, { Component } from "react";
import { connect } from "react-redux";
import RemoteValidationErrors from "../../StepCustomise/wlrBroadband/ConfigurationForm/RemoteValidationErrors";
import { StatusChip } from "@akj-dev/design-system";
import { doRemoteValidation } from "../../../store/wlrBroadband/actions";

class QuoteWLRValidationErrors extends Component {
  state = {};

  // Validate WLR+Broadband configs...
  // Some things need to be configured just for a quote, unlike other product types.

  componentDidMount() {
    const { configs, doRemoteValidation } = this.props;
    for (let i = 0; i < configs.length; i++) {
      doRemoteValidation(i, true);
    }
  }

  render() {
    const { configs } = this.props;

    // Check if any validation requests are fetching:
    let fetching = false;
    configs.forEach(c => {
      if (c.wlrValidation && c.wlrValidation.fetching) fetching = true;
      if (c.wlrValidationQuote && c.wlrValidationQuote.fetching)
        fetching = true;
      if (c.broadbandValidation && c.broadbandValidation.fetching)
        fetching = true;
    });
    return (
      <div>
        {fetching ? (
          <StatusChip
            type="loading"
            title="Validating WLR & Broadband configurations."
            message="Please wait..."
          />
        ) : (
          Array(configs.length)
            .fill()
            .map((e, i) => <RemoteValidationErrors targetConfig={i} key={i} />)
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    configs: state.wlrBroadband.configurations
  };
};

export default connect(mapStateToProps, {
  doRemoteValidation
})(QuoteWLRValidationErrors);
