import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  Button,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles
} from "@material-ui/core";
import _ from "lodash";
import SelectContact from "../../components/Shared/Contact/SelectContact";
import { selectContactForContract } from "../../store/generateContract/actions";
import { generateContract } from "../../store/generateContract/actions";
import { sendForApproval } from "../../store/order/actions";
import { CircularProgress } from "@material-ui/core";
import { getAccountSettings } from "../../store/account/selectors";

export default function GenerateContract() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const approvalRequired = useSelector(state =>
    _.get(state.order.orderStatus.response, "data.approval.required")
  );
  const contactId = useSelector(state => state.generateContract.contactId);
  const fetchingContract = useSelector(
    state => state.generateContract.generatingContract
  );
  const settings = useSelector(state => getAccountSettings(state));
  const fetching = fetchingContract;
  const [open, setOpen] = useState(false);

  const generateAndApprove = () => {
    dispatch(generateContract());
    if (
      approvalRequired &&
      settings.requires_external_approval_for_external_user_orders === "0"
    )
      dispatch(sendForApproval());
  };

  return (
    <Box mt={1}>
      <Button
        variant="contained"
        color="secondary"
        data-cy="generateContractButton"
        onClick={() => setOpen(true)}
        disabled={fetching}
        endIcon={fetching && <CircularProgress size={20} color="inherit" />}
      >
        Send E-Sign Contract
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Send E-Sign Contract</DialogTitle>
        <DialogContent>
          <p>
            Select or add a new contact to generate an digital contract and send
            via email. The recipient will receive a digital contract they can
            electronically sign.
          </p>
          <SelectContact
            current={contactId}
            handleUpdate={id => dispatch(selectContactForContract(id))}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={contactId === ""}
            onClick={generateAndApprove}
          >
            Send digital contract for signing
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  actions: {
    margin: theme.spacing(-3, 1, 1, 1)
  }
}));
