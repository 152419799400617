import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { useSelector } from "react-redux";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import PhoneIcon from "@material-ui/icons/Phone";
import ErrorIcon from "@material-ui/icons/Error";
import PhonelinkIcon from "@material-ui/icons/Phonelink";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import SettingsPhoneIcon from "@material-ui/icons/SettingsPhone";
import ExtensionIcon from "@material-ui/icons/Extension";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import vodaIcon from "../../images/voda@2x.png";
import o2Icon from "../../images/o2@2x.png";
import diseIcon from "../../images/dise@2x.png";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip
} from "@material-ui/core";
import { CheckIcon } from "@akj-dev/design-system";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { getTotalCreditUsed } from "../../store/order/selectors";
import { formatCurrency } from "../../helpers/formatCurrency";
import Typography from "@material-ui/core/Typography";

const SupplierImg = styled.img`
  vertical-align: middle;
  margin: 0 10px 0 0;
`;

const ProductIcon = ({ Icon, indent }) => (
  <Icon
    color="disabled"
    style={{
      verticalAlign: "middle",
      marginLeft: indent ? 40 : 0,
      marginRight: 10
    }}
  />
);

const Quantity = styled(Chip)`
  margin-right: 10px;
  background: ${p => p.theme.palette.info.main};
  color: white;
  font-weight: bold;
`;

const MobileNumber = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
`;

const Username = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
`;

const AirtimeCredit = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  color: ${p => p.theme.palette.text.disabled};
  span {
    font-weight: bold;
  }
`;

const AddCircle = styled(AddCircleIcon)`
  vertical-align: middle;
  margin-right: 10px;
  color: ${p => p.theme.palette.success.main};
`;

const RemoveCircle = styled(RemoveCircleIcon)`
  vertical-align: middle;
  margin-right: 10px;
  color: ${p => p.theme.palette.error.main};
`;

export default function OrderView() {
  const orderStatus = useSelector(state => state.order.orderStatus);

  const products = _.get(orderStatus.response, "data.products", []);
  const price = _.get(orderStatus.response, "data.price", {});
  const totalCreditUsed = getTotalCreditUsed(products);

  const sortedProducts = _.sortBy(products, product => {
    // If product doesn't have a component, return last.
    if (!product.components[0]) return 5;
    const order = {
      mobile: 1,
      mobile_bolt_on: 2,
      hardware: 3,
      extra_services: 4
    };
    // If product type doesn't have an order number assigned, return last.
    return order[product.components[0].module] || 5;
  });

  const iconMap = {
    broadband: SettingsPhoneIcon,
    broadband_extras: WorkOutlineIcon,
    calls: PhoneIcon,
    credit: MonetizationOnIcon,
    discount: MonetizationOnIcon,
    extras: WorkOutlineIcon,
    extra_services: AutorenewIcon,
    hardware: PhonelinkIcon,
    leased_line: PhonelinkIcon,
    line: PhoneIcon,
    line_extras: WorkOutlineIcon,
    linked_to_line: PhoneIcon,
    linked_to_mobile: PhoneAndroidIcon,
    linked_to_product_instance: WorkOutlineIcon,
    maintenance: AutorenewIcon,
    mobile: PhoneAndroidIcon,
    mobile_bolt_on: ExtensionIcon,
    system: ExtensionIcon,
    universal: ExtensionIcon,
    voip: PhonelinkIcon
  };

  if (sortedProducts.length < 1) return false;

  return (
    <Table data-cy="OrderView">
      <TableHead>
        <TableRow>
          <TableCell>Product</TableCell>
          <TableCell>One-off price</TableCell>
          <TableCell>Recurring price</TableCell>
          <TableCell>Configured</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedProducts.map((product, i) => {
          // Products can come back with no components. Might be a DC bug?
          // Deal with it anyway.....
          const firstComponent = product.components[0] || {};

          // If it's a CLI bolt-on, don't show it in the top level. We'll show it under it's parent line
          // Note this check could also be done with `firstComponent.module === 'mobile_bolt_on'`
          // but it seems `mobile_component_id` might cover other eventualities too.
          if (firstComponent.mobile_component_id) return false;

          // If it's a data only component with a linked parent voice component, don't show it on the top level either
          // Like bolt-ons, we'll show it under the parent.
          if (
            firstComponent.is_data_only &&
            sortedProducts.find(
              p => p.components[0].gprs_component_id === firstComponent.id
            )
          )
            return false;

          const row = product => {
            if (!product) return false;
            const firstComponent = product.components[0] || {};
            const isSubComponent = !!(
              firstComponent.mobile_component_id ||
              (firstComponent.is_data_only &&
                sortedProducts.find(
                  p => p.components[0].gprs_component_id === firstComponent.id
                ))
            );
            // If there has been credit used against a product (hardware), deduct this off the one off product price
            // as per: https://auroratarget.tpondemand.com/entity/6938-review-api-response-for-hw-credit.
            const oneOffPrice = firstComponent.credit_used
              ? formatCurrency(
                  parseFloat(product.price.one_off_price_with_promotions) -
                    parseFloat(firstComponent.credit_used)
                )
              : formatCurrency(product.price.one_off_price_with_promotions);

            return (
              <TableRow key={product.id}>
                <TableCell>
                  {isSubComponent && (
                    <ProductIcon indent Icon={SubdirectoryArrowRightIcon} />
                  )}
                  <ProductIcon
                    Icon={iconMap[firstComponent.module] || AddCircleIcon}
                  />
                  {firstComponent.quantity > 1 && (
                    <Quantity size="small" label={firstComponent.quantity}>
                      {firstComponent.quantity}
                    </Quantity>
                  )}
                  {firstComponent.module === "mobile_bolt_on" ? (
                    firstComponent.is_removal ? (
                      <RemoveCircle />
                    ) : (
                      <AddCircle />
                    )
                  ) : (
                    false
                  )}
                  {(() => {
                    switch (firstComponent.supplier) {
                      case "O2":
                        return (
                          <SupplierImg src={o2Icon} width={32} title="O2" />
                        );
                      case "Vodafone":
                      case "VF Direct":
                        return (
                          <SupplierImg
                            src={vodaIcon}
                            width={32}
                            title="Vodafone"
                          />
                        );
                      case "Dise":
                        return (
                          <SupplierImg src={diseIcon} width={32} title="Dise" />
                        );
                      default:
                        return false;
                    }
                  })()}
                  <span
                    style={
                      firstComponent.gprs_component_id ? { color: "#999" } : {}
                    }
                  >
                    {product.name}
                  </span>
                  {firstComponent.mobile_number && (
                    <MobileNumber>
                      <Chip
                        icon={
                          firstComponent.acquisition_method === "resign" ? (
                            <SettingsBackupRestoreIcon />
                          ) : (
                            <PhoneIcon />
                          )
                        }
                        label={firstComponent.mobile_number}
                      />
                    </MobileNumber>
                  )}
                  {firstComponent.airtime_credit_amount && (
                    <AirtimeCredit>
                      Airtime Credit:{" "}
                      <span>£{firstComponent.airtime_credit_amount}</span> /{" "}
                      {firstComponent.airtime_credit_duration} month
                      {firstComponent.airtime_credit_duration > 1 && "s"}
                    </AirtimeCredit>
                  )}
                  {firstComponent.airtime_credit_amount_oneoff && (
                    <AirtimeCredit>
                      Airtime One Off Credit: £
                      {firstComponent.airtime_credit_amount_oneoff}
                    </AirtimeCredit>
                  )}
                  {firstComponent.user_name && (
                    <Username>
                      <Typography variant="body2" color="textSecondary">
                        {firstComponent.user_name.toUpperCase()}
                      </Typography>
                    </Username>
                  )}
                </TableCell>
                <TableCell>{oneOffPrice}</TableCell>
                <TableCell>
                  {formatCurrency(
                    product.price.first_bill_recurring_price_with_promotions
                  )}
                </TableCell>
                <TableCell>
                  {product.is_configured ? (
                    <CheckIcon />
                  ) : (
                    <ErrorIcon color="error" />
                  )}
                </TableCell>
              </TableRow>
            );
          };
          const cliBoltOns = sortedProducts.filter(
            p => p.components[0].mobile_component_id === firstComponent.id
          );

          const linkedGprsProducts = sortedProducts.filter(
            p => p.components[0].id === firstComponent.gprs_component_id
          );

          return [
            row(product),
            ...cliBoltOns.map(row),
            ...linkedGprsProducts.map(row)
          ];
        })}
        <TableRow>
          <TableCell>
            <b>Total:</b>
          </TableCell>
          <TableCell data-cy="summary-price-one-off">
            <b>
              {totalCreditUsed > 0
                ? formatCurrency(
                    parseFloat(price.one_off_price_with_promotions) -
                      totalCreditUsed
                  )
                : formatCurrency(price.one_off_price_with_promotions)}
            </b>
          </TableCell>
          <TableCell data-cy="summary-price-recurring">
            <b>
              {formatCurrency(price.first_bill_recurring_price_with_promotions)}
            </b>
          </TableCell>
          <TableCell> </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
