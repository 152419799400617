import u from "updeep";

import {
  SET_STAGE,
  SET_CUSTOMER_OPTIONS,
  SET_LEAD_OPTIONS,
  SET_FETCHING,
  SET_EXISTING_OPPORTUNITY_NAME,
  SET_EXISTING_SELECTED_CUSTOMER,
  SET_EXISTING_SELECTED_CUSTOMER_ERROR,
  SET_EXISTING_OPPORTUNITY_NAME_ERROR,
  SET_FROM_LEAD_SELECTED_LEAD,
  SET_FROM_LEAD_SELECTED_LEAD_ERROR,
  SET_FROM_LEAD_OPPORTUNITY_NAME,
  SET_FROM_LEAD_OPPORTUNITY_NAME_ERROR,
  SET_NEW_CUSTOMER_FIRST_NAME,
  SET_NEW_CUSTOMER_FIRST_NAME_ERROR,
  SET_NEW_CUSTOMER_LAST_NAME,
  SET_NEW_CUSTOMER_LAST_NAME_ERROR,
  SET_NEW_CUSTOMER_EMAIL,
  SET_NEW_CUSTOMER_EMAIL_ERROR,
  SET_NEW_CUSTOMER_OPPORTUNITY_NAME,
  SET_NEW_CUSTOMER_OPPORTUNITY_NAME_ERROR
} from "./actionTypes";

import { CLEAR_STATE } from "../root/actionTypes";

const initialState = {
  stage: "selectCustomerType",
  fetching: false,
  customerOptions: null,
  leadOptions: null,
  formChooseExistingCustomer: {
    opportunityName: "",
    opportunityNameError: true,
    selectedCustomer: null,
    selectedCustomerError: true
  },
  formCreateNewCustomerFromLead: {
    opportunityName: "",
    opportunityNameError: true,
    selectedLead: null,
    selectedLeadError: true
  },
  formCreateNewCustomer: {
    firstName: "",
    firstNameError: true,
    lastName: "",
    lastNameError: true,
    email: "",
    emailError: true,
    opportunityName: "",
    opportunityNameError: true
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STAGE:
      return { ...state, stage: action.payload };
    case SET_FETCHING:
      return { ...state, fetching: action.payload || false };
    case SET_CUSTOMER_OPTIONS:
      return { ...state, customerOptions: action.payload };
    case SET_LEAD_OPTIONS:
      return { ...state, leadOptions: action.payload };
    case SET_EXISTING_SELECTED_CUSTOMER:
      return u(
        {
          formChooseExistingCustomer: {
            selectedCustomer: action.payload
          }
        },
        state
      );
    case SET_EXISTING_SELECTED_CUSTOMER_ERROR:
      return u(
        {
          formChooseExistingCustomer: {
            selectedCustomerError: action.payload
          }
        },
        state
      );
    case SET_EXISTING_OPPORTUNITY_NAME:
      return u(
        {
          formChooseExistingCustomer: {
            opportunityName: action.payload
          }
        },
        state
      );
    case SET_EXISTING_OPPORTUNITY_NAME_ERROR:
      return u(
        {
          formChooseExistingCustomer: {
            opportunityNameError: action.payload
          }
        },
        state
      );

    case SET_FROM_LEAD_SELECTED_LEAD:
      return u(
        {
          formCreateNewCustomerFromLead: {
            selectedLead: action.payload
          }
        },
        state
      );
    case SET_FROM_LEAD_SELECTED_LEAD_ERROR:
      return u(
        {
          formCreateNewCustomerFromLead: {
            selectedLeadError: action.payload
          }
        },
        state
      );
    case SET_FROM_LEAD_OPPORTUNITY_NAME:
      return u(
        {
          formCreateNewCustomerFromLead: {
            opportunityName: action.payload
          }
        },
        state
      );
    case SET_FROM_LEAD_OPPORTUNITY_NAME_ERROR:
      return u(
        {
          formCreateNewCustomerFromLead: {
            opportunityNameError: action.payload
          }
        },
        state
      );
    case SET_NEW_CUSTOMER_FIRST_NAME:
      return u(
        {
          formCreateNewCustomer: {
            firstName: action.payload
          }
        },
        state
      );
    case SET_NEW_CUSTOMER_FIRST_NAME_ERROR:
      return u(
        {
          formCreateNewCustomer: {
            firstNameError: action.payload
          }
        },
        state
      );
    case SET_NEW_CUSTOMER_LAST_NAME:
      return u(
        {
          formCreateNewCustomer: {
            lastName: action.payload
          }
        },
        state
      );
    case SET_NEW_CUSTOMER_LAST_NAME_ERROR:
      return u(
        {
          formCreateNewCustomer: {
            lastNameError: action.payload
          }
        },
        state
      );
    case SET_NEW_CUSTOMER_EMAIL:
      return u(
        {
          formCreateNewCustomer: {
            email: action.payload
          }
        },
        state
      );
    case SET_NEW_CUSTOMER_EMAIL_ERROR:
      return u(
        {
          formCreateNewCustomer: {
            emailError: action.payload
          }
        },
        state
      );
    case SET_NEW_CUSTOMER_OPPORTUNITY_NAME:
      return u(
        {
          formCreateNewCustomer: {
            opportunityName: action.payload
          }
        },
        state
      );
    case SET_NEW_CUSTOMER_OPPORTUNITY_NAME_ERROR:
      return u(
        {
          formCreateNewCustomer: {
            opportunityNameError: action.payload
          }
        },
        state
      );

    case CLEAR_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};
