import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleStepOneUniversalProducts } from "../../../store/uiState/actions";
import ContractLength from "../../Shared/ContractLength";
import ProductTable from "./ProductTable";
import { IndexedCardInfo } from "../../../screens/shared/indexed-card-info";

const UniversalProducts = () => {
  const dispatch = useDispatch();
  const contractLength = useSelector(state => state.order.contractLength);

  useEffect(() => {
    dispatch(toggleStepOneUniversalProducts());
  }, [dispatch]);

  return (
    <div>
      <IndexedCardInfo index={"1"} title={"Please select contract length"}>
        <ContractLength />
      </IndexedCardInfo>
      {!!contractLength && (
        <IndexedCardInfo index={"2"} title={"Please choose your product(s)"}>
          <ProductTable />
        </IndexedCardInfo>
      )}
    </div>
  );
};

export default UniversalProducts;
