import { getJSON, postJSON } from "../helpers/core";
import { postJSONBackgroundIdempotent } from "../helpers/idempotency";

/**
 * Get settings for account or leadId
 * Note this data is managed in DC at (depending what env obvs):
 * http://daisycentraltesting4.daisygroupplc.com/cgi-bin/index.cgi/Admin/AccountTags (values per account)
 * http://daisycentraltesting4.daisygroupplc.com/cgi-bin/index.cgi/Admin/Tags (global defaults)

 * @param { account: string } | { lead_id: string }
 * @returns {Promise<*>}
 * @constructor
 */
type SettingsOpts = { account: string } | { lead_id: string };
export const Settings = (query: SettingsOpts) =>
  getJSON(`PlatformSettings`, query, false);

/**
 * Get hardware credit available on the stated account
 * @param {string} accountId
 */
export const AccountHardwareCredit = (accountId: string) =>
  getJSON(`Account/AccountHardwareCredit/${accountId}`);

/**
 * Get existing account level mobile bolt-ons on an account
 * @param accountId
 * @returns {Promise<*>}
 * @constructor
 */
export const AccountLevelBoltOnsNetwork = (accountId: string) =>
  getJSON(`Account/AccountLevelBoltOnsNetwork/${accountId}`);

/**
 * Get an account's billing address
 * @param accountId
 * @param order_id
 * @returns {Promise<*>}
 */
export const billingAddress = (accountId: string, order_id?: string) =>
  getJSON(`Account/BillingAddress/${accountId}`, { order_id });

/**
 * Update an account's billing address
 * Note only elements passed as params are changed.
 *
 * Can include any combination of:
 *  - address1
 *  - address2
 *  - address3
 *  - address4
 *  - address5
 *  - country
 *  - postcode
 *
 * @param accountId
 * @param params
 * @returns {Promise<*>}
 */
export const billingAddressPost = (accountId: string, params: any) =>
  postJSON(`Account/BillingAddress/${accountId}`, {
    accept_blank_fields: 1,
    ...params
  });

/**
 * Get all addresses saved on an account.
 * Note: Different format that billing address.
 * @param accountId
 * @param order_id
 * @returns {Promise<*>}
 */
export const allAccountAddresses = (accountId: string, order_id?: string) =>
  getJSON(`Account/AllAccountAddresses/${accountId}`, { order_id });

/**
 * Get all contacts saved on an account.
 * @param accountId
 * @param order_id
 * @returns {Promise<*>}
 * @constructor
 */
export const AllAccountContacts = (accountId: string, order_id?: string) =>
  getJSON(`Account/AllAccountContacts/${accountId}`, { order_id });

/**
 * Get a list of possible contact types
 * @returns {Promise<*>}
 * @constructor
 */
export const ContactTypes = () => getJSON("Account/ContactTypes");

/**
 * Create an account
 * ....a sub account from a main account only I think.
 * Also see Orders/CreateAccount for creating accounts from leads
 * @param params
 * @returns {Promise<*>}
 */
export const Create = (params: any) =>
  postJSONBackgroundIdempotent(`Account/Create`, params);

/**
 * Add a contact to an account
 *
 * This can happen when sending quotes and creating new accounts.
 *
 * Looks like `contact` params can be:
 *  - authority
 *  - salutation
 *  - first_name
 *  - last_name
 *  - primary_email_address
 *  - phone_mobile
 *  - phone_fax
 *  - primary_address_street
 *  - primary_address_city
 *  - primary_address_state
 *  - primary_address_postalcode // ANOTHER address schema?!?!
 *  - primary_address_country
 *
 * ...maybe more too?
 *
 * @param accountId
 * @param contact
 * @returns {Promise<*>}
 * @constructor
 */
export const AddContact = (accountId: string, contact: any) =>
  postJSON(`Account/AddContact/${accountId}`, contact, true, true);

/**
 * Get available end user accounts for the authed user
 * @param limit
 * @param filter
 * @constructor
 */
export const EndUserAccounts = (limit: number, filter: string) =>
  getJSON<EndUserAccountsResponse>("/Account/EndUserAccounts", {
    limit,
    filter
  });

export type EndUserAccountsResponse = {
  data?: {
    accounts: {
      id: string;
      name: string;
    }[];
  };
  status: string;
};

// only supports one at time of writing. endpoint will default to this.
type ThirdPartyBillingCreditVetStatusSuppliers = "VF Direct";

export const ThirdPartyBillingCreditVetStatus = (
  accountId: string,
  supplier?: ThirdPartyBillingCreditVetStatusSuppliers
) =>
  getJSON<ThirdPartyBillingCreditVetStatusResponse>(
    `Account/ThirdPartyBilling/${accountId}`,
    supplier ? { supplier } : undefined
  );

export type ThirdPartyBillingCreditVetStatusResponse = {
  data?: {
    in_flight_connections_count: number;
    credit_check_value: number;
    credit_check_reference: string;
    credit_check_status: "Refer" | "Declined" | "Accepted" | "In Progress";
  };
  status: "success" | "error";
};
