import u from "updeep";
import * as actionTypes from "./actionTypes";
import blankEthernetConfig from "./blankEthernetConfig";
import { addWeekdays } from "../../helpers/date";
import {
  ETHERNET_PRODUCT_EFM,
  ETHERNET_PRODUCT_GEA,
  ETHERNET_PRODUCT_MANAGED,
  ETHERNET_PRODUCT_PTP
} from "./constants";
import { getBackupCapableRouterID } from "./selectors";
import {
  getDCEthernetAddressValid,
  mapWLR3AddressToDCOrderProduct
} from "../../helpers/addresses";

const productSpec = {
  [ETHERNET_PRODUCT_MANAGED]: {
    product: "Managed Interconnect",
    site_b_type: "Ethernet"
  },
  [ETHERNET_PRODUCT_EFM]: {
    product: "Managed Interconnect",
    site_b_type: "EFM"
  },
  [ETHERNET_PRODUCT_GEA]: {
    product: "Managed Interconnect",
    site_b_type: "GEA"
  },
  [ETHERNET_PRODUCT_PTP]: {
    product: "Point to Point",
    site_b_type: "Ethernet"
  }
};

const initialState = {
  configurations: [],
  configurationIndexesSubmitted: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_ETHERNET_CONFIG:
      return u(
        {
          configurations: c => [...c, blankEthernetConfig]
        },
        state
      );

    case actionTypes.REMOVE_ETHERNET_CONFIG:
      return Object.assign({}, state, {
        configurations: [
          ...state.configurations.slice(0, action.index),
          ...state.configurations.slice(action.index + 1)
        ]
      });

    case actionTypes.SET_ETHERNET_CONFIG_TYPE:
      return u(
        {
          configurations: {
            [action.configIndex]: {
              type: action.productType,
              ...blankEthernetConfig,
              ...productSpec[action.productType]
            }
          }
        },
        state
      );

    // Update ethernet config value
    // ...with a few logic exceptions
    case actionTypes.UPDATE_ETHERNET_CONFIG:
      return u(
        {
          configurations: {
            [action.index]: {
              // Update the target field no matter what...
              [action.field]: action.value,
              ...(() => {
                // If we're updating bearer, update speed with appropriate value too.
                if (action.field === "site_b_bearer") {
                  return { speed: false };
                }
                // Can't have a backup line without broadband:
                if (action.field === "backup_line" && action.value === true) {
                  return {
                    backup_broadband: true,
                    selectedRouterId: getBackupCapableRouterID(
                      state,
                      action.index
                    )
                  };
                }
                if (
                  action.field === "backup_broadband" &&
                  action.value === true
                ) {
                  return {
                    selectedRouterId: getBackupCapableRouterID(
                      state,
                      action.index
                    )
                  };
                }
                if (
                  action.field === "backup_broadband" &&
                  action.value === false
                ) {
                  return {
                    backup_broadband: false,
                    backup_line: false
                  };
                }
              })(),
              pricingResults: [],
              pricingRequestStatus: false,
              selectedQuoteId: false
            }
          }
        },
        state
      );

    case actionTypes.SET_ETHERNET_CONFIG_ADDRESS:
      const dcAddress = mapWLR3AddressToDCOrderProduct(
        action.wlr3Address,
        `${action.addressPrefix}site_address_`
      );
      const addressValid = getDCEthernetAddressValid(
        dcAddress,
        action.addressPrefix
      );
      return u(
        {
          configurations: {
            [action.configIndex]: {
              purchaseArgs: dcAddress,
              [`${action.addressPrefix}addressValid`]: addressValid
            }
          }
        },
        state
      );

    case actionTypes.REQUEST_AVAILABLE_ROUTERS:
      return u(
        {
          configurations: {
            [action.configIndex]: {
              availableRouters: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_AVAILABLE_ROUTERS:
      return u(
        {
          configurations: {
            [action.configIndex]: {
              availableRouters: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionTypes.UPDATE_ETHERNET_PRICING_REQUEST_ID:
      return u(
        {
          configurations: {
            [action.index]: {
              pricingRequestId: action.pricingRequestId
            }
          }
        },
        state
      );

    case actionTypes.UPDATE_ETHERNET_PRICING_REQUEST_STATUS:
      return u(
        {
          configurations: {
            [action.index]: {
              pricingRequestStatus: action.status,
              pricingRequestProvidersStatus: action.providersStatus
            }
          }
        },
        state
      );

    case actionTypes.UPDATE_ETHERNET_PRICING_RESULTS:
      return u(
        {
          configurations: {
            [action.index]: {
              pricingResults: action.results
            }
          }
        },
        state
      );

    case actionTypes.RESET_ETHERNET_PRICING_REQUEST:
      return u(
        {
          configurations: {
            [action.index]: {
              pricingResults: [],
              pricingRequestStatus: false,
              pricingRequestId: false,
              selectedResult: false
            }
          }
        },
        state
      );

    case actionTypes.SELECT_ETHERNET_QUOTE:
      const conf = state.configurations[action.index];
      const company =
        action.accountName || conf.site_b_full_address.organisationName || ""; // Passed by DC

      return u(
        {
          configurations: {
            [action.index]: {
              selectedQuoteId: action.quoteId,
              purchaseArgs: {
                customer_required_date: addWeekdays(Date.now(), 45, true),
                site_b_site_address_company: company,
                site_b_cli: conf.site_b_cli,

                ...(conf.type === ETHERNET_PRODUCT_PTP && {
                  site_a_site_address_company: company
                })
              },
              // Set technology here for use later on when determining speed. https://gitlab.com/akj-dev/inbox/issues/789
              technology: action.technology
            }
          }
        },
        state
      );

    case actionTypes.DESELECT_ETHERNET_QUOTE:
      // TODO: Does quote selection need re-thinking entirely now? purchaseArgs would be left copied on deselect.
      return u(
        {
          configurations: {
            [action.index]: {
              selectedQuoteId: false
            }
          }
        },
        state
      );

    case actionTypes.UPDATE_ETHERNET_CUSTOMER_DATA:
      let args;
      if (typeof action.key === "object") {
        // Purchase Args can be updated with an object (i.e. address)
        args = action.key;
      } else {
        // ...or individually
        args = {
          [action.key]: action.value
        };
        // This is a special case where bandwidth allocation has to be auto-populated (advised by Kate Smith).
        // All other data is just copied to the store direct.
        if (action.key === "concurrent_calls") {
          args["bandwidth_allocation"] = action.value * 100;
        }
      }
      return u(
        {
          configurations: {
            [action.index]: {
              purchaseArgs: args
            }
          }
        },
        state
      );

    case actionTypes.REQUEST_ETHERNET_PURCHASE:
      return u(
        {
          configurations: {
            [action.index]: {
              purchaseIsRequesting: true,
              purchaseResponse: () => ({})
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_ETHERNET_PURCHASE:
      return u(
        {
          configurations: {
            [action.index]: {
              purchaseIsRequesting: false,
              purchaseResponse: () => action.response
            }
          }
        },
        state
      );

    case actionTypes.REMOVE_ETHERNET_PURCHASE_ERRORS:
      return u(
        {
          configurations: u.map({
            purchaseResponse: { error: false }
          })
        },
        state
      );

    case actionTypes.SET_ETHERNET_CONFIG_PURCHASE_ARGS_SUBMITTED:
      return {
        ...state,
        configurationIndexesSubmitted: state.configurationIndexesSubmitted.includes(
          action.index
        )
          ? state.configurationIndexesSubmitted
          : state.configurationIndexesSubmitted.concat(action.index)
      };

    default:
      return state;
  }
};
