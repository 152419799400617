import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { StatusChip } from "@akj-dev/design-system";
import { Grid, Typography } from "@material-ui/core";
import { setOtherConfiguration } from "../../../store/wlrBroadband/actions";
import { IndexedTitle } from "../../../screens/shared/indexed-card-info/IndexedTitle";
import { WlrLocationProductCard } from "../../../screens/choose/choose-broadband/wlr-location/wlr-location-product-card/WlrLocationProductCard";
import { WlrLocationTypeEnum } from "../../../screens/choose/choose-broadband/enums";

class ChooseSOGEAProduct extends Component {
  static defaultProps = {};
  static propTypes = {
    locationIndex: PropTypes.number.isRequired
  };

  state = {};

  render() {
    const {
      broadbandSearch,
      locationIndex,
      setOtherConfiguration
    } = this.props;

    if (broadbandSearch.fetching)
      return (
        <StatusChip
          type="loading"
          title="Please wait"
          message="Checking Line & Broadband Availability"
        />
      );

    if (_.get(broadbandSearch.response, "status") === "error")
      return (
        <StatusChip
          type="error"
          title="Error"
          message={broadbandSearch.response.message}
        />
      );

    if (!broadbandSearch.response.products)
      return (
        <StatusChip
          type="info"
          message="This is a broadband only line which does NOT include a WLR voice service. If the customer requires a voice service,
          please quote for a VoIP service separately."
        />
      );

    const sogeaProducts = broadbandSearch.response.products.filter(
      p => p.first_broadband_component.type === "SOGEA"
    );
    if (
      sogeaProducts.length < 1 &&
      broadbandSearch.response.status === "success"
    )
      return (
        <StatusChip
          type="error"
          title="No SOGEA products available at this address."
          message="Please try again."
        />
      );

    return (
      <div>
        <Typography variant="h4" gutterBottom>
          <IndexedTitle
            index={"C"}
            title={"Choose a Product"}
            withMargin={true}
            negative={true}
          />
        </Typography>
        <Grid item md={6}>
          <WlrLocationProductCard
            locationIndex={locationIndex}
            productId={WlrLocationTypeEnum.NEW_SOGEA}
            title={WlrLocationTypeEnum.NEW_SOGEA}
            subtitle={``}
            handleClick={() =>
              setOtherConfiguration(locationIndex, {
                id: WlrLocationTypeEnum.NEW_SOGEA
              })
            }
          />
        </Grid>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  return (state, ownProps) => ({
    broadbandSearch:
      state.wlrBroadband.locations[ownProps.locationIndex]?.broadbandSearch
  });
};

export default connect(makeMapStateToProps, {
  setOtherConfiguration
})(ChooseSOGEAProduct);
