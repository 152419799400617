import React from "react";
import { connect } from "react-redux";
import CreateAccountForm from "./CreateAccountForm";
import { StatusChip } from "@akj-dev/design-system";
import {
  setNewAccountFields,
  createAccount
} from "../../store/account/actions";
import { getAccountSettings } from "../../store/account/selectors";

class StepAccount extends React.Component {
  componentDidMount() {
    this.props.setNewAccountFields();
  }
  render() {
    const {
      accountFetching,
      accountResponse,
      contactFetching,
      contactResponse,
      isVFDirect
    } = this.props;
    return (
      <>
        <CreateAccountForm />
        {accountFetching ? (
          <StatusChip
            type="loading"
            title={
              isVFDirect === "1"
                ? "Creating Account. Credit vetting is being processed"
                : "Creating Account"
            }
            message="Please wait..."
          />
        ) : (
          accountResponse.status === "error" && (
            <StatusChip type="error" message={accountResponse.message} />
          )
        )}
        {contactFetching ? (
          <StatusChip
            type="loading"
            title="Creating Contact"
            message="Please wait..."
          />
        ) : (
          contactResponse.status === "error" && (
            <StatusChip
              type="error"
              message={contactResponse.message}
              retry={createAccount}
            />
          )
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  accountFetching: state.account.createAccount.fetching,
  accountResponse: state.account.createAccount.response,
  contactFetching: state.account.addContact.fetching,
  contactResponse: state.account.addContact.response,
  isVFDirect: getAccountSettings(state).can_access_vf_direct
});

export default connect(mapStateToProps, { setNewAccountFields, createAccount })(
  StepAccount
);
