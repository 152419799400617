import _ from "lodash";
import { ERROR } from "../../helpers/constants";
import * as actionTypes from "./actionTypes";
import {
  RECEIVE_UPDATE_OPPORTUNITY,
  RECEIVE_UPDATE_ORDER_STATUS
} from "./actionTypes";
import { getALBRemovals } from "../mobile/selectors/boltOns";
import { requestAllEthernetPurchases } from "../ethernetProducts/actionsCreators";
import {
  orderBoltOns,
  orderHardwareCredits,
  orderMobileTariffs,
  orderShipping
} from "../mobile/selectors/order";
import { orderWlrBroadbandProducts } from "../wlrBroadband/actions";
import { orderHardware } from "../hardware/hardwareOrders/actionCreators";
import format from "date-fns/format";
import * as OrdersAPI from "../../api/v1/orders";
import * as OrderProductAPI from "../../api/v1/orderProduct";
import * as OpportunityAPI from "../../api/v1/opportunity";
import * as AffinityAPI from "../../api/v1/affinity";
import { REQUEST_GENERATE_QUOTE } from "../quote/actionTypes";
import { orderUniversalProducts } from "../universalProducts/actionCreators";
import { isValidEmail, isValidMobileNumber } from "../../helpers/validation";
import { orderLogicMonitorProducts } from "../monitoringService/actionCreators";
import { getAccountSettings } from "../account/selectors";
import { saveDraftAction } from "../drafts/actionCreators";
import { getHostEnv } from "./selectors/getHostEnv";

export const getNewOrder = orderName => async (dispatch, getState) => {
  if (!orderName) {
    const hostEnv = getHostEnv(getState());
    orderName = `${hostEnv}_${format(new Date(), "yyyyMMddHHmm")}`;
  }
  const { fetching } = getState().order.orderStatus;
  const account = getState().order.accountId;
  if (fetching) return false;
  dispatch({ type: actionTypes.REQUEST_NEW_ORDER });
  const response = await OrdersAPI.create(account, orderName);
  dispatch({ type: actionTypes.RECEIVE_NEW_ORDER, response });
};

export const getOrderStatus = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.REQUEST_UPDATE_ORDER_STATUS });
  const { id, accountId } = getState().order;
  const response = await OrdersAPI.view(id, accountId);
  dispatch({ type: actionTypes.RECEIVE_UPDATE_ORDER_STATUS, response });
};

export const setContractMeta = (key, value) => ({
  type: actionTypes.SET_CONTRACT_META,
  key,
  value
});

/**
 * Set the selected order contact.
 * ID is a UUID from the list returned from AllAccountContacts
 * @param id
 * @returns {{id: *, type: string}}
 */
export const setOrderContact = id => ({
  type: actionTypes.SET_ORDER_CONTACT,
  id
});

/**
 * Set contract length
 * Currently just used for universal products.
 * TODO: Use globally. Currently separate ones exit for WLR / Mobile.
 * ...which is silly. There's only a single contract length per order.
 *
 * @param contractLength
 * @returns {{type: string, contractLength: *}}
 */
export const setContractLength = contractLength => ({
  type: actionTypes.SET_CONTRACT_LENGTH,
  contractLength
});

/**
 * Update early termination fee method on order.
 * This is applicable to resigns that happen when the user still has time left on an existing contract
 *
 * @param event
 * @returns {Function}
 */
export const updateETFMethod = event => async (dispatch, getState) => {
  const order = getState().order;

  if (order.orderStatus.fetching) return false;

  dispatch({ type: actionTypes.REQUEST_UPDATE_ORDER_STATUS });
  const response = await OrdersAPI.update(order.id, {
    account: order.accountId,
    etf_method: event.target.value
  });
  dispatch({ type: RECEIVE_UPDATE_ORDER_STATUS, response });
};

/**
 * Update opportunity
 * This is currently only used for setting the Master Service Agreement flag
 * ...obviously we'd need some different logic should that change.
 *
 * UPDATE:And the MSA flag's been removed again, so this is redundant. FB142047
 * Keeping in place in case other things need to u[date the opportunity in future.
 * @returns {Function}
 */
export const updateOpportunity = () => async (dispatch, getState) => {
  const order = getState().order;
  if (order.opportunityStatus.fetching || !order.is_msa) return false;
  dispatch({ type: actionTypes.REQUEST_UPDATE_OPPORTUNITY });
  const response = await OpportunityAPI.update(order.opportunityId, {
    is_msa: order.is_msa
  });
  dispatch({ type: RECEIVE_UPDATE_OPPORTUNITY, response });
};

export function uploadContract() {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch({ type: actionTypes.REQUEST_UPLOAD_CONTRACT });

    const response = await OrdersAPI.uploadSignedContractPost(
      state.order.accountId,
      state.order.id,
      state.order.contractUpload.receivedAt,
      state.order.contractUpload.signedAt,
      state.order.orderContactId,
      state.order.contractUpload.file
    );

    dispatch({ type: actionTypes.RECEIVE_UPLOAD_CONTRACT, response });

    if (response.status === "error") return;

    // Also request approval after upload has completed if necessary
    const approval = _.get(
      getState().order.orderStatus.response,
      "data.approval",
      {}
    );

    if (approval.required && !approval.in_progress) {
      dispatch(sendForApproval());
    }
  };
}

export const resetUploadStatus = () => ({
  type: actionTypes.RESET_UPLOAD_CONTRACT
});

export const sendForApproval = () => async (dispatch, getState) => {
  const order = getState().order;
  // Don't resubmit approval. This would happen in the LE flow where requires_external_approval_for_external_user_orders
  // is set and approval is requested BEFORE a contract is uploaded. ....which is to do with sales people needing
  // customers to sign while they're on the phone, not waiting for the lead time in getting the actual approval.
  // The contracts have a get-out clause apparently. TP9707
  if (_.get(order, "sendForApproval.response.data.submitted") === 1) return;
  dispatch({ type: actionTypes.REQUEST_SEND_FOR_APPROVAL });
  const response = await OrdersAPI.sendForApproval(order.id, order.accountId);
  dispatch({
    type: actionTypes.RECEIVE_SEND_FOR_APPROVAL,
    response
  });
};

/**
 * Approve an approval. Used for Love Energy.
 * @param reject {boolean}
 * @returns {function(...[*]=)}
 */
export const approveOrRejectApproval = reject => async (dispatch, getState) => {
  dispatch({ type: actionTypes.REQUEST_APPROVE_OR_REJECT_APPROVAL });
  const order = getState().order;
  const response = reject
    ? await AffinityAPI.ApprovalsReject(order.id)
    : await AffinityAPI.ApprovalsApprove(order.id);
  dispatch({
    type: actionTypes.RECEIVE_APPROVE_OR_REJECT_APPROVAL,
    response
  });

  dispatch(saveDraftAction());
};

export const error = message => ({
  type: ERROR,
  message: message
});

export const updateOrderTracking = () => async (dispatch, getState) => {
  const state = getState();
  const accountId = state.order.accountId;
  const leadId = state.order.leadId;
  const { trackingDetails } = state.order;
  const { terminationFees } = state.mobile.daisyFreshAmounts;
  const contactId = state.order.orderContactId;

  dispatch({ type: actionTypes.REQUEST_UPDATE_ORDER_STATUS });

  const response = await OrdersAPI.update(state.order.id, {
    account: accountId,
    lead_id: leadId,
    ...(isValidEmail(trackingDetails) && { tracking_email: trackingDetails }),
    ...(isValidMobileNumber(trackingDetails) && {
      tracking_mobile: trackingDetails
    }),
    ...(parseFloat(terminationFees) > 0 && {
      additional_contract_notes: `A maximum termination fee of £${terminationFees}.`
    }),
    ...(contactId && { order_contact_id: contactId }),
    ...(state.order.cc_sales_person && { cc_sales_person: 1 }),
    ...(state.order.suppress_welcome_email && { suppress_welcome_email: 1 }),
    customer_purchase_number: state.order.customer_purchase_number,
    etf_method: state.order.etfMethod
  });
  dispatch({ type: actionTypes.RECEIVE_UPDATE_ORDER_STATUS, response });
};
/**
 * Add all configured products to the order in DC
 * Logic gets complex around order completion. See https://miro.com/app/board/o9J_kqAeNgQ=/
 * @param quoteOnly {Boolean}
 * @returns {Function}
 */
export const addAllProductsToOrder = (quoteOnly = false) => async (
  dispatch,
  getState
) => {
  // Generating a quote requires a similar API conversation to an order,
  // but happens earlier in the process, and tells DC to skip much of the validation.
  if (quoteOnly) {
    dispatch({ type: REQUEST_GENERATE_QUOTE });
  }

  // Add any Ethernet products - different process to others.
  if (getState().ethernetProducts.configurations.length > 0) {
    await dispatch(requestAllEthernetPurchases()); // TODO: How does this work for just generating quotes?
  }

  // Add or update Mobile, hardware, WLR+BB and Universal (other) products...
  // Note updates would happen instead of additions if a quote had previously been generated.

  await Promise.all([
    dispatch(orderMobileTariffs(null, quoteOnly)),
    dispatch(orderBoltOns()),
    dispatch(orderShipping()),
    dispatch(orderHardwareCredits()),
    dispatch(orderWlrBroadbandProducts(quoteOnly)),
    dispatch(orderHardware()),
    dispatch(orderUniversalProducts()),
    dispatch(orderLogicMonitorProducts()),

    // The order can include Account level bolt-on removals... which are not "part of the order"
    // ....but at the same time..... are.
    !quoteOnly && dispatch(doALBRemovals())
  ]);
  // Once the order calls have completed, recalculate prices and add tracking details.
  await dispatch(recalculatePrices());

  await dispatch(updateOrderTracking());

  // Only send for approval here if the order requires external approval (LE).
  // Existing user account settings from DC.
  const settings = getAccountSettings(getState());

  // Send for approval if necessary.
  if (
    settings.requires_external_approval_for_external_user_orders === "1" &&
    _.get(getState().order.orderStatus, "response.data.approval.required") ===
      1 &&
    !quoteOnly
  ) {
    await dispatch(sendForApproval());
  }
};

/**
 * Send Account Level Bol-on removal requests
 *
 * As advised by @ianc, find any ALB product and add it to the order with
 *  - service_cost_id - which is the ID that comes back from `v1/Product/MobileBoltOnSearch`
 *  - is_removal
 *  - fixed_override_name - the name of the bolt-on. (which is just free text for the business.)
 *
 * Note: This used to be a direct request to `v1/Account/RemoveEvoBundle` but following some
 * problems (see FB 132560) this has changed to the above.
 *
 * @returns {Function}
 */
export const doALBRemovals = () => async (dispatch, getState) => {
  const state = getState();
  if (!state.mobile.boltOnSearch.response?.products) return; // This isn't a mobile order as bolt-ons haven't been fetched.

  const removalData = getALBRemovals(state.mobile);
  const firstBoltOnID =
    state.mobile.boltOnSearch.response.products.length > 0
      ? state.mobile.boltOnSearch.response.products[0].id
      : null;

  await Promise.all(
    removalData.map(async removal => {
      const removalRequest = getState().order.boltOnRemovals[removal.id] || {};
      if (
        removalRequest.fetching ||
        _.get(removalRequest, "response.status") === "success"
      )
        return;

      dispatch({ type: actionTypes.REQUEST_REMOVE_BOLT_ON, id: removal.id });

      const response = await OrderProductAPI.create(firstBoltOnID, {
        account_id: state.order.accountId,
        order_id: state.order.id,
        "mobile_bolt_on-is_removal": 1,
        "mobile_bolt_on-service_cost_id": removal.id,
        skip_price_recalculations: 1
      });
      dispatch({
        type: actionTypes.RECEIVE_REMOVE_BOLT_ON,
        id: removal.id,
        response
      });
    })
  );
};

export const recalculatePrices = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.REQUEST_RECALCULATE_PRICES });
  const order = getState().order;
  const response = await OrdersAPI.recalculatePrices(
    order.id,
    order.accountId,
    order.leadId
  );
  dispatch({ type: actionTypes.RECEIVE_RECALCULATE_PRICES, response });
};

/**
 * Send current order for provisioning.
 * @returns {Function}
 */
export const provisionOrder = () => async (dispatch, getState) => {
  if (getState().order.provision.fetching) return false;

  const { id, accountId } = getState().order;

  dispatch({ type: actionTypes.REQUEST_PROVISION_ORDER });
  const response = await OrdersAPI.provision(id, accountId);
  dispatch({ type: actionTypes.RECEIVE_PROVISION_ORDER, response });
};

/**
 * Set field value for order
 * // TODO: Bin this.
 *
 * @param name {String}
 * @param value {Mixed}
 *
 * @returns {Function}
 */
export const setField = (name, value) => ({
  type: actionTypes.SET_FIELD,
  name,
  value
});

/**
 * Get latest orders placed on the current account
 * @param refresh
 * @returns {Function}
 */
export const getOrdersForAccount = (refresh = false) => async (
  dispatch,
  getState
) => {
  // Don't refresh unnecessarily:
  if (
    !refresh &&
    getState().order.ordersForAccount.response.status === "success"
  )
    return;

  dispatch({ type: actionTypes.REQUEST_ORDERS_FOR_ACCOUNT });
  const response = await OrdersAPI.OrdersForAccount(getState().order.accountId);
  dispatch({ type: actionTypes.RECEIVE_ORDERS_FOR_ACCOUNT, response });
};

/**
 * Get the next 20 orders on the current account
 * @returns {Function}
 */
export const getMoreOrdersForAccount = () => async (dispatch, getState) => {
  const nextPage = getState().order.ordersForAccount.response.next_page;
  if (nextPage) {
    dispatch({ type: actionTypes.REQUEST_ORDERS_FOR_ACCOUNT });
    // The pagination link is a full URL, so split the namespace off it so we can run it through the API class.
    const response = await OrdersAPI.MoreOrdersForAccount(
      nextPage.split("/v1/")[1]
    );
    dispatch({ type: actionTypes.RECEIVE_MORE_ORDERS_FOR_ACCOUNT, response });
  }
};

/**
 * Set the account ID for this session.
 * TODO: Maybe this shouldn't be it's own action? Have a single account ID and update it as part of account create call etc.
 * @param id
 * @returns {{id: *, type: *}}
 */
export const setAccountId = id => ({
  type: actionTypes.SET_ACCOUNT_ID,
  id
});

/**
 * Reset the order state to initial values
 * This is used by the plaform to allow for multiple wizard completions in same session
 * @returns {{ type: string }}
 */
export const resetOrderState = () => ({
  type: actionTypes.RESET_ORDER_STATE
});
