import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgress, Button, TextField, Grid } from "@material-ui/core";
import { StatusChip } from "@akj-dev/design-system";
import FileDisplay from "../../../../../../components/Shared/FileUpload/FileDisplay";
import * as OrdersApi from "../../../../../../api/v1/orders";

interface RequestParamsProps {
  file: any;
  goBack: () => void;
  setJobId: (jobId: string) => void;
}

export const RequestParams = ({
  file,
  goBack,
  setJobId
}: RequestParamsProps) => {
  const orderId = useSelector<any, string>(state => state.order.id);
  const [paramsState, setParamsState] = useState<{
    orderRef: string;
    isSending: boolean;
    apiResponse: {
      status: string;
      message: string;
      data?: any;
      validation_errors?: string[];
    };
  }>({
    orderRef: "",
    isSending: false,
    apiResponse: { status: "", message: "" }
  });
  const { orderRef, isSending, apiResponse } = paramsState;
  const { validation_errors } = apiResponse;

  const changeOrderRef = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newOrderRef = event.target.value;

      setParamsState(oldState => ({
        ...oldState,
        orderRef: newOrderRef
      }));
    },
    [setParamsState]
  );

  const submitOrder = async () => {
    setParamsState(oldState => ({ ...oldState, isSending: true }));

    const res = await OrdersApi.bulkOrderUpload(
      orderRef,
      "80",
      orderId, // TODO: This will need to trigger Orders/Create if we transition DC to not create orders on init. Currently only in step 2.
      file
    );

    setParamsState(oldState => ({
      ...oldState,
      isSending: false,
      apiResponse: res
    }));

    const requestId: string | undefined = res?.data?.bulk_order_request_id;

    if (requestId) {
      setJobId(requestId);
    }
  };

  return (
    <div>
      <FileDisplay file={file} />
      {isSending ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={1} justify="center">
          <Grid item>
            <TextField label="Your Order Reference" onChange={changeOrderRef} />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={submitOrder}>
              Submit Order
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={goBack}>
              Back
            </Button>
          </Grid>
        </Grid>
      )}
      {apiResponse.status === "error" && (
        <StatusChip
          type="error"
          title={`Error: ${apiResponse.message}`}
          message={
            validation_errors && (
              <ul>
                {validation_errors.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ul>
            )
          }
        />
      )}
    </div>
  );
};
