import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { provisionOrder, sendForApproval } from "../../store/order/actions";
import Spinner from "../../components/Shared/Spinner";
import { StatusChip } from "@akj-dev/design-system";
import { Button, Box } from "@material-ui/core";

/**
 * The Affinity Classic, MyAccount and Hardware only flow.
 */
const ProvisionOrderButton = ({
  approval,
  sendingForApproval,
  provision,
  provisionOrder,
  sendForApproval
}) => {
  const fetching = sendingForApproval || provision.fetching;

  return (
    <Box my={1}>
      <Button
        variant="contained"
        color="secondary"
        data-cy="placeOrderButton"
        onClick={() => {
          if (approval.required) {
            sendForApproval();
          } else {
            provisionOrder();
          }
        }}
        endIcon={fetching && <Spinner size={30} color="#aaa" />}
        disabled={fetching}
      >
        Place Order
      </Button>
      {provision.response.status === "error" && (
        <StatusChip
          type="error"
          title="Sorry, there has been a problem placing your order. Please contact support."
          message={provision.response.message}
        />
      )}
    </Box>
  );
};

const mapStateToProps = state => ({
  approval: _.get(state.order.orderStatus.response, "data.approval", {}),
  provision: state.order.provision,
  sendingForApproval: state.order.sendForApproval.fetching
});

export default connect(mapStateToProps, {
  sendForApproval,
  provisionOrder
})(ProvisionOrderButton);
