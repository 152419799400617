import React from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import {
  ReduxWlrLocation,
  WlrLocationBroadbandProduct,
  WlrLocationProduct,
  WlrLocationProductConfig
} from "../../../../../types";
import { BroadbandProductSelect } from "../../../../shared/BroadbandProductSelect";

export interface BroadbandProductTableRowProps {
  style: any;
  contractLength: number;
  location: ReduxWlrLocation;
  product: WlrLocationProduct;
  isMpfProduct: boolean;
  configuration: WlrLocationProductConfig;
  broadbandProducts: WlrLocationBroadbandProduct[];
  handleBroadbandProduct: (cid: number, bid: string) => void;
}

export const BroadbandProductTableRow = ({
  style,
  contractLength,
  location,
  product,
  isMpfProduct,
  configuration,
  broadbandProducts,
  handleBroadbandProduct
}: BroadbandProductTableRowProps) => {
  const classes = useStyles();

  const broadbandProductId = configuration.broadbandProductId ?? "";
  const broadbandProduct = broadbandProducts.find(
    bp => bp.id === broadbandProductId
  );

  const broadbandDetails = broadbandProduct?.first_broadband_component;

  const price =
    broadbandProduct?.price.first_bill_recurring_price_with_promotions;

  return (
    <Box className={classes.root} style={style}>
      <Box className={classes.name}>{product.name ? product.name : "N/A"}</Box>
      <Box className={classes.name}>
        {broadbandDetails?.estimated_download_range
          ? broadbandDetails?.estimated_download_range
          : "N/A"}
      </Box>
      <Box className={classes.name}>
        {broadbandDetails?.estimated_upload_range
          ? broadbandDetails?.estimated_upload_range
          : "N/A"}
      </Box>
      <Box className={classes.name}>
        {broadbandDetails?.minimum_guaranteed_speed
          ? broadbandDetails?.minimum_guaranteed_speed
          : "N/A"}
      </Box>
      <Box className={classes.name}>
        {broadbandDetails?.estimated_lead_time?.lead_time
          ? broadbandDetails?.estimated_lead_time?.lead_time
          : "N/A"}
      </Box>
      {!isMpfProduct && (
        <Box className={classes.broadband}>
          <BroadbandProductSelect
            dense={true}
            broadbandProductId={broadbandProductId}
            broadbandProducts={broadbandProducts}
            wlrProductId={location.type}
            configId={configuration.configId!}
            contractLength={contractLength}
            handleBroadbandProduct={handleBroadbandProduct}
          />
        </Box>
      )}
      <Box className={classes.price}>{price ? `£${price}` : "£0.00"}</Box>
    </Box>
  );
};

export interface RenderBroadbandTableRowProps {
  index: number;
  style: any;
  data: {
    contractLength: number;
    location: ReduxWlrLocation;
    locationProduct: WlrLocationProduct;
    isMpfProduct: boolean;
    products: WlrLocationProductConfig[];
    broadbandProducts: WlrLocationBroadbandProduct[];
    handleBroadbandProduct: (cid: number, bid: string) => void;
  };
}

export const RenderBroadbandTableRow = ({
  index,
  style,
  data
}: RenderBroadbandTableRowProps) => {
  const {
    contractLength,
    location,
    locationProduct: product,
    isMpfProduct,
    products: configurations,
    broadbandProducts,
    handleBroadbandProduct
  } = data;
  const configuration = configurations[index];

  return (
    <BroadbandProductTableRow
      contractLength={contractLength}
      style={style}
      location={location}
      product={product}
      isMpfProduct={isMpfProduct}
      configuration={configuration}
      broadbandProducts={broadbandProducts}
      handleBroadbandProduct={handleBroadbandProduct}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: `0 ${theme.spacing(1.5)}px`
  },
  name: {
    width: 350
  },
  broadband: {
    flexGrow: 1,
    paddingRight: theme.spacing(3)
  },
  price: {
    width: 150,
    minWidth: 150
  }
}));
