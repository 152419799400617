import React from "react";
import {
  RESIGN_WITH_CHANGES,
  RESIGN_WITHOUT_CHANGES
} from "../../../../store/mobile/constants";
import Chip from "../../../../components/Shared/mobile/Chip";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { useTheme } from "@material-ui/core/styles";
import { RowActionsButton } from "../../../shared/RowActionsButton";

const RowActions = ({
  config,
  setNoChanges,
  setChanges,
  resetProduct,
  resetType,
  disabled
}) => {
  const theme = useTheme();
  switch (config.resignType) {
    case RESIGN_WITHOUT_CHANGES:
      return (
        <Chip
          title="Resign without changes"
          color={theme.palette.info.main}
          Icon={AutorenewIcon}
          onClick={resetType}
        />
      );
    case RESIGN_WITH_CHANGES:
      return (
        <Chip
          title="Resign with changes"
          color={theme.palette.success.main}
          Icon={SettingsBackupRestoreIcon}
          onClick={config.productId ? resetProduct : resetType}
          outline={!config.productId}
        />
      );
    default:
      return (
        <div data-cy="resignOptionsBtn">
          <RowActionsButton
            items={[
              {
                label: "Resign without changes",
                dataCy: "resignWithoutChangeBtn",
                disabled,
                onClick: setNoChanges
              },
              {
                label: "Resign with changes",
                dataCy: "resignWithChangeBtn",
                disabled,
                onClick: setChanges
              }
            ]}
          />
        </div>
      );
  }
};

export default RowActions;
