import u from "updeep";
import * as actionTypes from "../actionTypes";

export default (state, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_RESERVED_NUMBERS_LIST:
      return u(
        {
          reservedNumbersSearch: {
            [action.network]: {
              fetching: true
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_RESERVED_NUMBERS_LIST:
      return u(
        {
          reservedNumbersSearch: {
            [action.network]: {
              fetching: false,
              response: () => action.response
            }
          }
        },
        state
      );

    default:
      return state;
  }
};
