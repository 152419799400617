import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deselectEthernetQuote } from "../../../../../store/ethernetProducts/actionsCreators";
import { getConfigRecurringPrice } from "../../../../../store/ethernetProducts/selectors";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Box,
  Button
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { getAccountSettings } from "../../../../../store/account/selectors";

class SelectedProduct extends Component {
  static propTypes = {
    configuration: PropTypes.object.isRequired,
    index: PropTypes.number,
    accountSettingRoundEthernetPrices: PropTypes.oneOf(["0", "1"])
  };
  state = {};

  handleSelectDifferentQuote = () => {
    this.props.dispatch(deselectEthernetQuote(this.props.index));
  };

  render() {
    const { configuration, accountSettingRoundEthernetPrices } = this.props;

    const selectedResult = configuration.pricingResults.filter(
      r => r.id === configuration.selectedQuoteId
    )[0];
    const discountPercentage = configuration.purchaseArgs.discount;
    const { amortised } = configuration.purchaseArgs;

    return (
      <>
        <Box my={1}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Supplier</TableCell>
                <TableCell>Technology</TableCell>
                <TableCell>Term (Months)</TableCell>
                <TableCell>Router Name</TableCell>
                <TableCell>One Off</TableCell>
                <TableCell>Monthly</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{selectedResult.supplier}</TableCell>
                <TableCell>{selectedResult.technology}</TableCell>
                <TableCell>
                  {selectedResult.contract_length_in_months}
                </TableCell>
                <TableCell>{selectedResult.router_name}</TableCell>
                <TableCell>
                  {amortised ? "0.00" : selectedResult.one_off_price}
                </TableCell>
                {discountPercentage !== 0 ? (
                  <TableCell>
                    {getConfigRecurringPrice(
                      configuration,
                      selectedResult,
                      true,
                      accountSettingRoundEthernetPrices === "1"
                    ).toFixed(2)}
                    <span
                      style={{ color: this.props.theme.palette.text.disabled }}
                    >
                      {" "}
                      (
                      {getConfigRecurringPrice(
                        configuration,
                        selectedResult,
                        false,
                        accountSettingRoundEthernetPrices === "1"
                      ).toFixed(2)}
                      )
                    </span>
                  </TableCell>
                ) : (
                  <TableCell>
                    {getConfigRecurringPrice(
                      configuration,
                      selectedResult,
                      true,
                      accountSettingRoundEthernetPrices === "1"
                    ).toFixed(2)}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box my={1}>
          <Button variant="contained" onClick={this.handleSelectDifferentQuote}>
            Select Different Quote
          </Button>
        </Box>
      </>
    );
  }
}

const mapStateToProps = state => ({
  accountSettingRoundEthernetPrices: getAccountSettings(state)
    .round_ethernet_prices_to_next_whole_pound
});

export default connect(mapStateToProps)(withTheme(SelectedProduct));
