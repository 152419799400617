import u from "updeep";
import _ from "lodash";

import * as actionTypes from "./actionTypes";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_QUANTITY:
      // I hope that this doesn't create error
      // if (action.quantity === 0) {
      //   return _.omit(state, action.product.id);
      // } else {
      return u(
        {
          [action.product.id]: {
            quantity: action.quantity,
            product: action.product,
            rows: Array(action.quantity).fill({})
          }
        },
        state
      );
    // }

    case actionTypes.TOGGLE_EXPAND:
      return u(
        {
          [action.productId]: {
            expanded: !state[action.productId]["expanded"]
          }
        },
        state
      );

    case actionTypes.SET_FIELDS:
      // TODO: This action type is too generic. We're ending up with too much BL outside the reducer.
      // TODO: Avoid cloneDeep here. Performance.
      let rows = _.cloneDeep(_.get(state[action.productId], "rows", []));

      // Prevent negative credit values
      if (action.fields.credit_used < 0) action.fields.credit_used = 0;

      if (action.index === undefined) {
        // Set the fields for all configurations of current product
        for (let i = 0; i < state[action.productId].quantity; i++) {
          rows[i] = action.fields;
        }
      } else {
        // Set the fields just for a particular configuration of current product
        rows[action.index] = {
          ...action.fields
        };
      }

      return u(
        {
          [action.productId]: {
            rows,
            // This horribleness: because we need a less generic action, as above.
            ...(action.fields.expanded !== undefined && {
              expanded: action.fields.expanded
            })
          }
        },
        state
      );

    case actionTypes.REQUEST_STOCK_LEVEL:
      return u(
        {
          [action.productId]: {
            stockLevel: {
              fetching: true,
              response: false
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_STOCK_LEVEL:
      return u(
        {
          [action.productId]: {
            stockLevel: {
              fetching: false,
              response: () => action.response
            }
          }
        },
        state
      );

    default:
      return state;
  }
};
