import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import ConfigurationStatus from "./ConfigurationStatus";
import Spinner from "../../../components/Shared/Spinner";
import Checkbox from "@material-ui/core/Checkbox";
import {
  EXISTING_LINE,
  NEW_SOGEA,
  EXISTING_SOGEA,
  NEW_FTTP
} from "../../../store/wlrBroadband/constants";
import { StatusChip } from "@akj-dev/design-system";
import InlinePriceMeta from "../../Shared/Pricing/InlinePriceMeta";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableActionsCell from "../../../components/Shared/TableActionsCell";
import { getIsMPFLine } from "../../../store/wlrBroadband/selectors";

const ConfigTableRow = ({
  c,
  configIndex,
  toggleAction,
  checked,
  editAction,
  deleteAction,
  retryAction
}) => {
  // See loading statuses inline...
  // Also this to disable edit when anything's fetching:
  const fetching =
    c.wlrProductData.fetching ||
    c.broadbandProductData.fetching ||
    c.broadbandValidation.fetching;

  // Show product data error states...

  if (
    !fetching &&
    (c.wlrProductData.response.status === "error" ||
      c.broadbandProductData.response.status === "error")
  )
    return (
      <TableRow key={configIndex}>
        <TableCell colSpan={7}>
          <StatusChip
            type="error"
            title="Error fetching product data."
            retry={retryAction}
          />
        </TableCell>
      </TableRow>
    );

  // If everything's ok, show the config row
  return (
    <TableRow key={configIndex}>
      {toggleAction && (
        <TableCell>
          <Checkbox checked={checked} onChange={toggleAction} />
        </TableCell>
      )}
      <TableCell>
        {c.wlrProductData.fetching ? (
          <Spinner size={30} />
        ) : (
          c.wlrProductData.response.product_name ||
          (c.wlrProductId === EXISTING_LINE && EXISTING_LINE) ||
          (getIsMPFLine(c) && "MPF Line") ||
          (c.wlrProductId === NEW_FTTP && NEW_FTTP) ||
          (c.wlrProductId === NEW_SOGEA && NEW_SOGEA) ||
          (c.wlrProductId === EXISTING_SOGEA && EXISTING_SOGEA)
        )}
      </TableCell>
      <TableCell>
        {(
          parseFloat(
            _.get(
              c.wlrProductData.response,
              "overall_price.one_off_price_with_promotions",
              0
            )
          ) +
          parseFloat(
            _.get(
              c.broadbandProductData.response,
              "overall_price.one_off_price_with_promotions",
              0
            )
          )
        ).toFixed(2)}
        <InlinePriceMeta
          productData={c.wlrProductData.response}
          priceType="ONE_OFF"
        />
        <InlinePriceMeta
          productData={c.broadbandProductData.response}
          priceType="ONE_OFF"
        />
      </TableCell>
      <TableCell>
        {(
          parseFloat(
            _.get(
              c.wlrProductData.response,
              "overall_price.first_bill_recurring_price_with_promotions",
              0
            )
          ) +
          parseFloat(
            _.get(
              c.broadbandProductData.response,
              "overall_price.first_bill_recurring_price_with_promotions",
              0
            )
          )
        ).toFixed(2)}
        <InlinePriceMeta
          productData={c.wlrProductData.response}
          priceType="RECURRING"
        />
        <InlinePriceMeta
          productData={c.broadbandProductData.response}
          priceType="RECURRING"
        />
      </TableCell>
      <TableCell>
        {c.broadbandProductData.fetching ? (
          <Spinner size={30} />
        ) : (
          _.get(c.broadbandProductData.response, "product_name", "None")
        )}
      </TableCell>
      <TableCell>
        {c.broadbandValidation.fetching ? (
          <Spinner size={30} />
        ) : (
          <ConfigurationStatus
            configuration={c}
            onClick={fetching ? () => false : editAction}
          />
        )}
      </TableCell>
      {editAction && (
        <TableActionsCell>
          <IconButton
            disabled={fetching}
            onClick={editAction}
            data-cy="wlrBroadbandEditConfig"
          >
            <EditIcon />
          </IconButton>
          <IconButton disabled={fetching} onClick={deleteAction}>
            <DeleteIcon />
          </IconButton>
        </TableActionsCell>
      )}
    </TableRow>
  );
};

ConfigTableRow.propTypes = {
  c: PropTypes.object.isRequired,
  configIndex: PropTypes.number.isRequired,
  toggleAction: PropTypes.func,
  checked: PropTypes.bool,
  editAction: PropTypes.func,
  deleteAction: PropTypes.func,
  retryAction: PropTypes.func
};

export default ConfigTableRow;
