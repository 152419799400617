import React from "react";
import Icon from "@material-ui/icons/SwapVert";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";

const MPFVoiceOptions = props => {
  return (
    <Section
      title="MPF Voice Options"
      avatar={<Icon />}
      defaultExpanded={false}
    >
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.anonymousCallRejection"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.callerDisplay"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.callWaiting"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.chooseToRefuse"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.cliPresentationOverrideAndRestrictionOverrideByCall"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.cliPresentationRestrictionPermanent"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.reminderCallBasic"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.ringback"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.ringbackRequestRestriction"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.subscriberCallForward"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.subscriberCallForwardRemoteAccess"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.subscriberIncomingCallBar"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.subscriberOutgoingCallBar"
      />

      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.answerService1571"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.cliRetrieval"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.classFiveServices.multiwayCalling"
      />
    </Section>
  );
};

MPFVoiceOptions.propTypes = {};

export default MPFVoiceOptions;
