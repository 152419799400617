import * as LeasedLineQuotesAPI from "../../api/v1/leasedLineQuotes";
import { addAlertMessage } from "../uiState/actions";
import * as actionTypes from "./actionTypes";
import { ETHERNET_PRODUCT_PTP } from "./constants";
import { STEP_CUSTOMISE } from "../uiState/constants";

export function addEthernetConfiguration() {
  return {
    type: actionTypes.ADD_ETHERNET_CONFIG
  };
}

export function removeEthernetConfiguration(index) {
  return {
    type: actionTypes.REMOVE_ETHERNET_CONFIG,
    index
  };
}

export function updateEthernetConfiguration(index, field, value) {
  return {
    type: actionTypes.UPDATE_ETHERNET_CONFIG,
    index,
    field,
    value
  };
}

export const setEthernetConfigAddress = (
  configIndex,
  wlr3Address,
  addressPrefix
) => ({
  type: actionTypes.SET_ETHERNET_CONFIG_ADDRESS,
  configIndex,
  wlr3Address,
  addressPrefix
});

export function setEthernetConfigType(configIndex, productType) {
  return {
    type: actionTypes.SET_ETHERNET_CONFIG_TYPE,
    configIndex,
    productType
  };
}

export function updateEthernetPricingResults(index, results) {
  return {
    type: actionTypes.UPDATE_ETHERNET_PRICING_RESULTS,
    index,
    results
  };
}

/**
 * Update ethernet pricing request status
 *
 * @param index
 * @param status
 * @param providersStatus - Optional. Got added to the response in FB148508
 * @returns {{index: *, type: string, status: *}}
 */
export function updateEthernetPricingRequestStatus(
  index,
  status,
  providersStatus = false
) {
  return {
    type: actionTypes.UPDATE_ETHERNET_PRICING_REQUEST_STATUS,
    index,
    status,
    providersStatus
  };
}

export function updateEthernetPricingRequestId(index, pricingRequestId) {
  return {
    type: actionTypes.UPDATE_ETHERNET_PRICING_REQUEST_ID,
    index,
    pricingRequestId
  };
}

export function resetEthernetPricingRequest(index) {
  return {
    type: actionTypes.RESET_ETHERNET_PRICING_REQUEST,
    index
  };
}

export function selectEthernetQuote(index, quoteId, technology, accountName) {
  return {
    type: actionTypes.SELECT_ETHERNET_QUOTE,
    index,
    quoteId,
    technology,
    accountName
  };
}

export function deselectEthernetQuote(index) {
  return {
    type: actionTypes.DESELECT_ETHERNET_QUOTE,
    index
  };
}

export function updateEthernetCustomerData(index, key, value) {
  return {
    type: actionTypes.UPDATE_ETHERNET_CUSTOMER_DATA,
    index,
    key,
    value
  };
}

function requestEthernetPurchase(index) {
  return {
    type: actionTypes.REQUEST_ETHERNET_PURCHASE,
    index
  };
}
function receiveEthernetPurchase(index, response) {
  return {
    type: actionTypes.RECEIVE_ETHERNET_PURCHASE,
    index,
    response
  };
}

export function removeEthernetPurchaseErrors() {
  return {
    type: actionTypes.REMOVE_ETHERNET_PURCHASE_ERRORS
  };
}

// Async SiteActions

/**
 * Get available router options
 * @param configIndex
 * @returns {Function}
 */
export const getAvailableRouters = configIndex => async (
  dispatch,
  getState
) => {
  const config = getState().ethernetProducts.configurations[configIndex];
  if (config.availableRouters.fetching) return;
  // GEA doesn't ask for bearer. Perhaps the UI should tbh, to follow the pattern for the other types.
  // Wasn't in the original spec though. ...and the routers end point needs it. Advised by @mike to pass 10 or 20.
  let geaSpeed;
  if (config.site_b_type === "GEA") {
    geaSpeed = config.site_b_bearer === "40" ? "10" : "20"; // if it's 80
  }
  dispatch({ type: actionTypes.REQUEST_AVAILABLE_ROUTERS, configIndex });
  const response = await LeasedLineQuotesAPI.routers({
    bearer: config.site_b_bearer,
    speed: config.speed || geaSpeed,
    technology_type: config.site_b_type
  });
  dispatch({
    type: actionTypes.RECEIVE_AVAILABLE_ROUTERS,
    configIndex,
    response
  });
};

/**
 * Make an Ethernet pricing requests to DC API
 *
 * /LeasedLineQuotes/PricingRequest
 *
 * The parameters passed correspond to the DC code here:
 * /lib/SelfService/ExternalServices/v1/LeasedLineQuotes.pm - PricingRequest_POST
 *
 * No order id? order_id: getState().order.id, - pricing requests don't seem to relate to orders. Only to purchase requests.
 *
 * @returns {Function}
 */
export function requestEthernetPricing() {
  return async (dispatch, getState) => {
    const configurations = getState().ethernetProducts.configurations;

    await Promise.all(
      configurations.map(async (c, index) => {
        // Don't re-request pricing if a quote has already been chosen:
        if (c.selectedQuoteId) return;

        // Don't re-request if already in progress either.
        if (c.pricingRequestStatus !== false) return;

        dispatch(updateEthernetPricingRequestStatus(index, "requesting"));

        // Note: Passing false here doesn't get interpreted correctly, so use 0
        const params = {
          account_id: getState().order.accountId,
          customer_quote_reference: `GuidedSales${getState().order.id}`,
          markup_policy_name:
            getState().account?.settings?.response?.settings
              ?.ethernet_markup_policy_name || "retail",
          product: c.product,

          dont_price_the_router: false,
          router_product_id: c.selectedRouterId,

          backup_line: c.backup_line,
          backup_broadband: c.backup_broadband,
          backup_broadband_technology: c.backup_broadband_technology,
          // backup_broadband_provider: c.backup_broadband_provider,

          site_a_bearer: false,

          // Site A: only needed for point to point.
          ...(c.type === ETHERNET_PRODUCT_PTP && {
            site_a_building: c.purchaseArgs.site_a_site_address_building,
            site_a_street: c.purchaseArgs.site_a_site_address_street,
            site_a_post_town: c.purchaseArgs.site_a_site_address_post_town,
            site_a_postcode: c.purchaseArgs.site_a_site_address_postcode,
            site_a_country: c.purchaseArgs.site_a_site_address_country,
            site_a_uprn: c.site_a_uprn,

            // These look unused...
            site_a_cli: false,
            site_a_interconnect: false,
            site_a_type: false
          }),

          // Site B address: needed for all config types.
          site_b_building: c.purchaseArgs.site_b_site_address_building,
          site_b_street: c.purchaseArgs.site_b_site_address_street,
          site_b_post_town: c.purchaseArgs.site_b_site_address_post_town,
          site_b_postcode: c.purchaseArgs.site_b_site_address_postcode,
          site_b_country: c.purchaseArgs.site_b_site_address_country,
          site_b_uprn: c.site_b_uprn,

          site_b_bearer: c.site_b_bearer,
          site_b_cli: c.site_b_cli,
          site_b_type: c.site_b_type,
          site_b_address_reference: false,
          source_ref: false,
          // See note on routers endpoint:
          speed:
            c.speed ||
            (c.site_b_type === "GEA" &&
              (c.site_b_bearer === "40" ? "10" : "20")),
          site_a_cabinet: false,
          site_a_shelf: false,
          site_b_cabinet: false,
          site_b_shelf: false,
          external_source_name: "Guided Sales"
        };

        LeasedLineQuotesAPI.pricingRequest(params)
          .then(result => {
            dispatch(updateEthernetPricingRequestId(index, result.id));
            const promiseInfo = { pending: false };
            const intervalId = setInterval(() => {
              const config = getState().ethernetProducts.configurations[index];
              const status = config.pricingRequestStatus;
              const activeStep = getState().uiState.activeStep;

              if (
                ["partial_results", "complete", "error"].indexOf(status) > -1
              ) {
                clearInterval(intervalId);
              } else if (
                activeStep !== STEP_CUSTOMISE ||
                config.selectedQuoteId
              ) {
                clearInterval(intervalId);
                // TODO: Fix this properly. With the below line, form can redraw mid-fill, wiping fields. Without it, status display when a user changes mind about a quote can be false.
                // dispatch(updateEthernetPricingRequestStatus(index, 'stopped'))
              } else {
                // A little optimization trick, so we do not have two pending calls
                if (!promiseInfo.pending) {
                  dispatch(getEthernetQuoteResults(index, promiseInfo));
                }
              }
            }, 5000);
          })
          .catch(error => {
            dispatch(addAlertMessage(error));
            dispatch(updateEthernetPricingRequestStatus(index, "failed"));
          });
      })
    );
  };
}

/**
 * Get Ethernet Quote results from DC API
 *
 * /LeasedLineQuotes/Results/<quote id>
 *
 * Quote ID is returned from PricingRequest above.
 *
 * @param index - index of the configuration
 * @param promiseInfo - an object where the function stores information about the pending promise
 * @returns {Function}
 */
export function getEthernetQuoteResults(index, promiseInfo) {
  return async (dispatch, getState) => {
    try {
      promiseInfo.pending = true;
      const configuration = getState().ethernetProducts.configurations[index];
      let result = await LeasedLineQuotesAPI.results(
        configuration.pricingRequestId
      );
      promiseInfo.pending = false;
      if (result.request) {
        dispatch(
          updateEthernetPricingRequestStatus(
            index,
            result.request.status,
            result.providers
          )
        );
        dispatch(updateEthernetPricingResults(index, result.results));
      } else if (result.status) {
        dispatch(addAlertMessage(result.message));
      }
    } catch (error) {
      dispatch(addAlertMessage(error));
    }
  };
}

/**
 * Make Ethernet Purchase Request to DC API
 *
 * /LeasedLineQuotes/Purchase/<order_id>
 *
 * See in the DC codebase:
 * /lib/SelfService/ExternalServices/v1/LeasedLineQuotes.pm
 * /lib/SelfService/LeasedLinePricing/OrderBuilder.pm
 *
 * Currently this only makes a new order. Mike Jones is adding an `order_id` field so it can add to an existing one.
 * Also has been known to fail for no good reason in DC, not showing any errors.
 * Working against .62.92 at time of this commit.
 *
 * This handles Ethernet Product Orders. I believe the “DC Request to LeasedLineQuotes/Purchase failed, HTTP Status 401” issue comes from here.
 *
 * @returns {Function}
 */
export function requestAllEthernetPurchases() {
  return async (dispatch, getState) => {
    const configurations = getState().ethernetProducts.configurations;
    let index = 0;
    for (const configuration of configurations) {
      // If the config doesn't already have an order ID, perform a purchase request
      if (!configuration.purchaseResponse.order_id) {
        dispatch(requestEthernetPurchase(index));

        const params = {
          account: getState().order.accountId,
          order_id: getState().order.id,
          placed_via: "guided_sales",
          // Send speed as "200+" if technology is Fibre Flex 200+. https://gitlab.com/akj-dev/inbox/issues/789
          speed:
            configuration.technology === "Fibre Flex 200+"
              ? "200+"
              : configuration.speed,

          // These params have already been passed to PricingRequest earlier but need passing again
          // As DWP needs prices initially without backup services, then has the user specify them
          // at the end, those initial values have to be ignored by DC and get replicated in this
          // final purchase call.
          backup_line: configuration.backup_line,
          backup_broadband: configuration.backup_broadband,
          backup_broadband_technology:
            configuration.backup_broadband_technology,

          // These are the args collected after a quote is chosen. eg. site contact details
          ...configuration.purchaseArgs
        };

        // This handles Ethernet Product Orders. I believe the “DC Request to LeasedLineQuotes/Purchase failed, HTTP Status 401”
        // issue comes from here

        let response = await LeasedLineQuotesAPI.purchase(
          params,
          configuration.selectedQuoteId
        );
        dispatch(receiveEthernetPurchase(index, response));
      }
      index++;
    }
  };
}

// Sets a flag used to determine whether to show validation errors on PurchaseArg form fields
export function setEthernetConfigPurchaseArgsSubmitted(index) {
  return {
    type: actionTypes.SET_ETHERNET_CONFIG_PURCHASE_ARGS_SUBMITTED,
    index
  };
}
