import React from "react";
import PropTypes from "prop-types";
import { StatusChip } from "@akj-dev/design-system";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

class AddressCorrect extends React.Component {
  // These are the address fields from
  // _address_configuration_fields in DC (as advised by @davet)

  state = this.props.address;

  static propTypes = {
    address: PropTypes.object.isRequired,
    prefix: PropTypes.string.isRequired,
    updateAddress: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.open !== prevProps.open) this.setState(this.props.address);
  }

  fields = [
    { key: "site_address_building", label: "Building", required: 1 },
    { key: "site_address_street", label: "Street", required: 1 },
    { key: "site_address_locale", label: "Locale" },
    { key: "site_address_post_town", label: "Post Town", required: 1 },
    { key: "site_address_region", label: "Region" },
    { key: "site_address_postcode", label: "Postcode", required: 1 },
    { key: "site_address_country", label: "Country", required: 1 }
  ];

  render() {
    const { prefix, updateAddress, open } = this.props;

    return (
      <Dialog open={open}>
        <DialogTitle>Address Correction</DialogTitle>
        <DialogContent>
          <StatusChip
            type="error"
            title="Low quality address"
            message="Please enter the required fields highlighted."
          />
          <Grid container spacing={2}>
            {this.fields.map((field, i) => {
              const key = `${prefix}${field.key}`;
              const value = this.state[key];
              return (
                <Grid item sm={6} key={i}>
                  <TextField
                    label={field.label}
                    fullWidth
                    value={value || ""}
                    helperText={
                      field.required && !value && "This field is required."
                    }
                    error={field.required && !value}
                    onChange={event =>
                      this.setState({ [key]: event.target.value })
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            endIcon={<CheckCircleIcon />}
            onClick={() => updateAddress(this.state)}
          >
            Correct
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AddressCorrect;
