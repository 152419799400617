import React from "react";
import { Box, Button, makeStyles, Theme, Typography } from "@material-ui/core";
import AscIcon from "@material-ui/icons/ExpandLess";
import DescIcon from "@material-ui/icons/ExpandMore";
import { VirtualTableHeaderProps } from "./types";
import { VirtualTableSortOrder } from "./enums";

export const VirtualTableHeader = ({
  header,
  activeSort,
  handleSort
}: VirtualTableHeaderProps) => {
  const classes = useStyles();
  const { label, sortIndex, width, minWidth, disabled } = header;
  const { sortIndex: activeSortIndex, order } = activeSort;
  const handleClick = () => handleSort(sortIndex);

  let EndIcon;
  if (sortIndex === activeSortIndex) {
    if (order === VirtualTableSortOrder.ASC) {
      EndIcon = AscIcon;
    } else {
      EndIcon = DescIcon;
    }
  }

  return (
    <Box
      style={
        width
          ? { width, minWidth: minWidth || width }
          : { flexGrow: 1, minWidth }
      }
    >
      {disabled ? (
        <Typography className={classes.text}>{label}</Typography>
      ) : (
        <Button
          className={classes.button}
          endIcon={EndIcon && <EndIcon />}
          onClick={handleClick}
        >
          <Typography className={classes.text}>{label}</Typography>
        </Button>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginLeft: -theme.spacing(0.5)
  },
  text: {
    fontWeight: "bold"
  }
}));
