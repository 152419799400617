import React, { Component } from "react";
import { connect } from "react-redux";
import { getNewOrder } from "../../store/order/actions";
import { StatusChip } from "@akj-dev/design-system";

class NewOrder extends Component {
  componentDidMount() {
    this.props.getNewOrder();
  }

  // Note: Originally this component prompted for an order name.
  // For now we want order creation in the background with no prompt.
  // Have a feeling that may change though, so leaving old code intact.

  // state = {
  //     orderName: '',
  // }
  //
  // setOrderName = event => this.setState({ orderName: event.target.value })

  render() {
    const { orderStatus, getNewOrder } = this.props;

    if (orderStatus.fetching)
      return (
        <StatusChip
          type="loading"
          title="Creating New Order"
          message="Please wait..."
        />
      );

    if (orderStatus.response.status === "error")
      return (
        <StatusChip
          type="error"
          title="Error creating order"
          message="Please contact support."
          retry={getNewOrder}
        />
      );

    return false;
    // const { getNewOrder, fetching } = this.props;
    // const { orderName } = this.state;
    //
    // return (
    //   <Outer>
    //     <h3>Create an order</h3>
    //     <TextField
    //       data-cy="NewOrderName"
    //       label="Order Name"
    //       value={orderName}
    //       onChange={this.setOrderName}
    //       disabled={fetching}
    //     />
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       data-cy="NewOrderButton"
    //       onClick={() => getNewOrder(orderName)}
    //       disabled={fetching}
    //     >
    //       Create
    //     </Button>
    //     {fetching && <div>Fetching...</div>}
    //   </Outer>
    // );
  }
}

// const Outer = styled.div`
//   padding: 20px;
// `;

const mapStateToProps = (state, ownProps) => {
  return {
    orderStatus: state.order.orderStatus
  };
};

export default connect(mapStateToProps, {
  getNewOrder
})(NewOrder);
