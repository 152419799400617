import u from "updeep";
import * as actionTypes from "../actionTypes";

export default (state, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_ORDER_PRODUCT:
      return u(
        {
          configs: {
            [action.configIndex]: {
              orderProduct: {
                fetching: true,
                isUpdate: action.isUpdate,
                response: {}
              }
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_ORDER_PRODUCT:
      return u(
        {
          configs: {
            [action.configIndex]: {
              orderProduct: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionTypes.REQUEST_ORDER_BOLT_ON:
      return u(
        {
          orderBoltOn: {
            [action.productId]: {
              fetching: true,
              response: {}
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_ORDER_BOLT_ON:
      return u(
        {
          orderBoltOn: {
            [action.productId]: {
              fetching: false,
              response: () => action.response
            }
          }
        },
        state
      );

    case actionTypes.REQUEST_HARDWARE_CREDIT:
      return u(
        {
          hardwareCredit: {
            fetching: true,
            isUpdate: action.isUpdate,
            response: {}
          }
        },
        state
      );

    case actionTypes.RECEIVE_HARDWARE_CREDIT:
      return u(
        {
          hardwareCredit: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    default:
      return state;
  }
};
