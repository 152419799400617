import { QuantityInfoTypeHandlerProps, StockLevelHandlerProps } from "./types";
import { useEffect, useMemo } from "react";
import { QuantityInfoType } from "./enum";

/**
 * A Simple custom useEffect for handling a stockLevel
 */
export const useStockLevelHandler = ({
  quantity,
  stockLevel,
  getStockLevel
}: StockLevelHandlerProps) => {
  useEffect(() => {
    if (!stockLevel && !!quantity) {
      getStockLevel?.();
    }
  }, [stockLevel, quantity, getStockLevel]);
};

/**
 * custom useMemo that returns type and message for a QuantityInfo component
 * based on the attributes
 */
export const useQuantityInfoTypeHandler = ({
  quantity,
  actualQuantity,
  maxQuantity,
  stockLevel,
  getStockLevel
}: QuantityInfoTypeHandlerProps) =>
  useMemo(() => {
    const { fetching, response } = stockLevel ?? {};
    const { stock = 0 } = response ?? {};
    const withStock = !!getStockLevel;

    let type = QuantityInfoType.INFO;
    let message = "Select Qty";

    if (quantity === undefined || fetching) {
      return { type, message };
    }

    if (actualQuantity > maxQuantity) {
      type = QuantityInfoType.QTY_ERROR;
      message = `Max ${maxQuantity}`;

      return { type, message };
    }

    if (withStock) {
      if (quantity > stock) {
        type = QuantityInfoType.STOCK_ERROR;
        message = `${stock} in Stock`;
      } else {
        type = QuantityInfoType.SUCCESS;
        message = `${stock} in Stock`;
      }
    }

    return { type, message };
  }, [quantity, actualQuantity, maxQuantity, stockLevel, getStockLevel]);
