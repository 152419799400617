import React from "react";
import Icon from "@material-ui/icons/Group";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";

const CareLevels = ({ isWlrChangePossible }) => {
  return (
    <Section title="Care Levels" avatar={<Icon />}>
      {isWlrChangePossible && (
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.care_level"
        />
      )}
      <WlrBBDynamicField productType="wlr" propertyName="care_level" margin />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.care_level"
        margin
      />
    </Section>
  );
};

export default CareLevels;
