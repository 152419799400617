import React, { Component } from "react";
import { connect } from "react-redux";
import ConfigTableRow from "../../containers/StepCustomise/wlrBroadband/ConfigTableRow";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ResignConfigTableRow from "../StepCustomise/wlrBroadband/ResignsConfigurationTable/ResignConfigTableRow";
import { IndexedCardInfo } from "../../screens/shared/indexed-card-info";
import { getSelectedProductData } from "../../store/wlrBroadband/actions";

class WlrBroadbandStep3 extends Component {
  static defaultProps = {};
  static propTypes = {};

  state = {};

  render() {
    const { configs, cardIndex, getSelectedProductData } = this.props;
    return (
      <IndexedCardInfo index={cardIndex} title="Lines, Calls & Broadband">
        {configs.filter(config => !config.resignProductType).length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>Total One Off Price</TableCell>
                <TableCell>Total Recurring Price</TableCell>
                <TableCell>Broadband</TableCell>
                <TableCell>Configured?</TableCell>
              </TableRow>
            </TableHead>
            <tbody>
              {configs.map((c, i) => {
                if (!c.resignProductType)
                  return (
                    <ConfigTableRow
                      c={c}
                      configIndex={i}
                      key={i}
                      retryAction={getSelectedProductData}
                    />
                  );
                return false;
              })}
            </tbody>
          </Table>
        )}
        {configs.filter(config => config.resignProductType).length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>CLI</TableCell>
                <TableCell />
                <TableCell>Product</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Resign Type</TableCell>
                <TableCell>Configured?</TableCell>
              </TableRow>
            </TableHead>
            <tbody>
              {configs.map((c, i) => {
                if (c.resignProductType)
                  return (
                    <ResignConfigTableRow
                      configuration={c}
                      index={i}
                      key={i}
                      retryAction={getSelectedProductData}
                    />
                  );
                return false;
              })}
            </tbody>
          </Table>
        )}
      </IndexedCardInfo>
    );
  }
}

const mapStateToProps = state => {
  return {
    configs: state.wlrBroadband.configurations
  };
};

export default connect(mapStateToProps, {
  getSelectedProductData
})(WlrBroadbandStep3);
