import { Grid, Typography } from "@material-ui/core";
import React from "react";
import AccountTextField from "../../AccountTextField";
import PostcodeLookup from "../PostcodeLookup";

type Props = {
  suffix?: "" | "2" | "3";
};

export const CompanyAddressFields = ({ suffix = "" }: Props) => (
  <>
    {suffix && (
      <Grid item sm={12}>
        <Typography variant="h4">
          Previous Company Address {parseInt(suffix) - 1}
        </Typography>
      </Grid>
    )}
    <PostcodeLookup prefix="company_" suffix={suffix} />
    <AccountTextField label="Building" name={`company_building${suffix}`} />
    <AccountTextField label="Street" name={`company_street${suffix}`} />
    <AccountTextField label="Post Town" name={`company_post_town${suffix}`} />
    <AccountTextField label="Locale" name={`company_locale${suffix}`} />
    <AccountTextField label="County" name={`company_region${suffix}`} />
    <AccountTextField label="Country" name={`company_country${suffix}`} />
  </>
);
