import React, { Dispatch, SetStateAction, useState } from "react";
import { endOfDay, startOfDay } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Grid,
  Popper,
} from "@material-ui/core";

export interface DateRangeCustomModalProps {
  customOptionRef: React.RefObject<any>;
  open: boolean;
  from: Date;
  to: Date;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setFrom: Dispatch<SetStateAction<Date>>;
  setTo: Dispatch<SetStateAction<Date>>;
}

export const DateRangeCustomModal = ({
  customOptionRef,
  open,
  setOpen,
  from: defaultFrom,
  setFrom: setDefaultFrom,
  to: defaultTo,
  setTo: setDefaultTo,
}: DateRangeCustomModalProps) => {
  const classes = useStyles();

  const [from, setFrom] = useState<Date>(defaultFrom);
  const [to, setTo] = useState<Date>(defaultTo);

  const handleFrom = (date: MaterialUiPickersDate) => {
    if (date) {
      const newFrom = startOfDay(date);
      setFrom(newFrom);
    }
  };
  const handleTo = (date: MaterialUiPickersDate) => {
    if (date) {
      const newTo = endOfDay(date);
      setTo(newTo);
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setDefaultFrom(from);
    setDefaultTo(to);

    setOpen(false);
  };

  const now = new Date();
  return (
    <Popper
      open={open}
      anchorEl={customOptionRef.current}
      className={classes.popper}
      placement="bottom-end"
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Card>
          <CardHeader title="Select custom date range" />
          <CardContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <DatePicker
                    inputVariant="outlined"
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyy"
                    margin="none"
                    id="date-picker-from"
                    label="From"
                    value={from}
                    maxDate={to ?? now}
                    onChange={handleFrom}
                  />
                </Grid>
                <Grid item md={6}>
                  <DatePicker
                    inputVariant="outlined"
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyy"
                    margin="none"
                    id="date-picker-to"
                    label="To"
                    value={to}
                    minDate={from}
                    maxDate={now}
                    onChange={handleTo}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Apply
            </Button>
          </CardActions>
        </Card>
      </ClickAwayListener>
    </Popper>
  );
};

const useStyles = makeStyles({
  popper: {
    zIndex: 1,
  },
  actions: {
    padding: 16,
  },
});
