import React from "react";
import { connect } from "react-redux";
import Section from "../../../../../components/Shared/Section";
import BulkDynamicField from "../BulkDynamicField";
import { startOfMonth, addMonths } from "date-fns";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { StatusChip } from "@akj-dev/design-system";

class AirtimeCredit extends React.Component {
  render() {
    const { contractLengthInMonthsAllProducts } = this.props;
    const startOfNextMonth = startOfMonth(addMonths(new Date(), 1));
    return (
      <Section title="Airtime Credit" avatar={<AccountBalanceIcon />}>
        {!contractLengthInMonthsAllProducts ? (
          <StatusChip
            type="error"
            title="Cannot offer Airtime Credit without a contract length"
          />
        ) : (
          <>
            <BulkDynamicField propertyName="airtime_credit_amount_oneoff" />
            <BulkDynamicField propertyName="airtime_credit_amount" />
            <BulkDynamicField
              propertyName="airtime_credit_start_date"
              minDate={startOfNextMonth}
              shouldDisableDate={date => date.getDate() !== 1}
            />
            <BulkDynamicField
              propertyName="airtime_credit_duration"
              availableOptionsMap={{
                // We don't want all the options DC gives us so:
                0: "None",
                1: "1 Month",
                [contractLengthInMonthsAllProducts]: `${contractLengthInMonthsAllProducts} Months`
              }}
            />
          </>
        )}
      </Section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contractLengthInMonthsAllProducts:
      state.mobile.contractLengthInMonthsAllProducts
  };
};

export default connect(mapStateToProps)(AirtimeCredit);
