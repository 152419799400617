import _ from "lodash";
import { getCliBoltOnByType, getProductDataForConfig } from "./productConfig";
import { getResignPrice, getResignProductInstanceByConfig } from "./resigns";
import {
  ALB_NOT_REQUIRED,
  KEEP_CURRENT_ALB,
  REMOVE_EXISTING_ALB,
  RESIGN_WITHOUT_CHANGES
} from "../constants";
import { createSelector } from "reselect";

/**
 * Compute the amount a customer should be charged per line for Daisy Fresh
 * @type {Reselect.Selector<any, any>}
 */

export const getDaisyFreshPerLineAmount = createSelector(
  [
    state => state.mobile.daisyFreshAmounts,
    state => state.mobile.contractLengthInMonthsAllProducts,
    state => getStandardConfigCount(state)
  ],
  (daisyFreshAmounts, contractLength, configCount) => {
    const { hardwareCredits, terminationFees, etf } = daisyFreshAmounts;
    return (
      Math.round(
        ((hardwareCredits + terminationFees + etf) /
          contractLength /
          configCount) *
          100
      ) / 100
    );
  }
);

/**
 * Compute recurring daisy fresh total fo the whole order.
 * ...memoization may be overkill here....
 * @type {Reselect.Selector<any, any>}
 */
export const getDaisyFreshTotal = createSelector(
  [
    state => getDaisyFreshPerLineAmount(state),
    state => getStandardConfigCount(state)
  ],
  (perLineAmount, configCount) => {
    return perLineAmount * configCount || 0;
  }
);

/**
 * Get how many configs there are that are not "resign without change"
 * These will be the ones applicable for Daisy Fresh and further config.
 * @param configs
 */
export const getStandardConfigCount = createSelector(
  [state => state.mobile.configs],
  configs => {
    let count = 0;
    configs.forEach(c => {
      if (c.resignType !== RESIGN_WITHOUT_CHANGES) count++;
    });
    return count;
  }
);

/**
 * Get maximum allowed Daisy Fresh amount.
 * Total product charges + bolt-ons * contract length * 1.8
 *
 * Should this be memoized with Reselect? No!
 * Timed and for 20+ products, it takes 0.2ms to execute on my Mac.
 *
 * @param state - the mobile part of redux state
 * @returns {number}
 */
export const getDaisyFreshAllowedAmount = state => {
  // const t0 = performance.now();

  let total = 0;

  // Compute total CLI charges:
  state.configs.forEach((config, i) => {
    if (config.productId) {
      // Standard or "resign with change" config
      total += parseFloat(
        // Product data might not have loaded yet, qwhen this is first called going into step 2, so _.get with fallback.
        _.get(
          getProductDataForConfig({ mobile: state }, i),
          "overall_price.first_bill_recurring_price_with_promotions",
          0
        )
      );
      // Any CLI bolt-ons the config may have
      for (let type in config.selectedCliBoltOns) {
        total += parseFloat(
          _.get(
            getCliBoltOnByType(i, type, state),
            "price.first_bill_recurring_price_with_promotions",
            0
          )
        );
      }
    } else {
      // Resign without change, with price from the product instance's evo services
      total += getResignPrice(
        getResignProductInstanceByConfig({ mobile: state }, i)
      );
    }
  });

  // Compute bolt on charges.
  // Iterate data structure, look up bolt-on price + add it to the total
  // TODO: Use `flat` npm package?
  for (let provider in state.selectedBoltOns) {
    for (let type in state.selectedBoltOns[provider]) {
      const p = state.selectedBoltOns[provider][type];
      if (
        p !== ALB_NOT_REQUIRED &&
        p !== KEEP_CURRENT_ALB &&
        p !== REMOVE_EXISTING_ALB
      ) {
        total += _.get(
          _.get(state.boltOnSearch.response, "products", []).find(
            p => p.id === p
          ),
          "price.first_bill_recurring_price_with_promotions",
          0
        );
      }
    }
  }

  total = total * state.contractLength * 1.8;

  // const t1 = performance.now();
  // console.log("Call to getDaisyFreshAllowedAmount took " + (t1 - t0) + " milliseconds.");

  return total;
};

export const productDataIsFetching = state => {
  const { productData } = state.mobile;
  let fetching = false;

  Object.keys(productData).forEach(k => {
    if (productData[k].fetching) fetching = true;
  });

  return fetching;
};
