import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { StatusChip } from "@akj-dev/design-system";

type Props = {
  type: "error" | "success" | "info" | "loading";
  message: string;
  title?: string;
};

export const LineProductSelectorStatusRow = ({
  type,
  message,
  title
}: Props) => {
  const defaultTitle = type === "error" ? "Error" : "Please wait";
  return (
    <TableRow>
      <TableCell colSpan={9}>
        <StatusChip
          type={type}
          title={title || defaultTitle}
          message={message}
        />
      </TableCell>
    </TableRow>
  );
};
