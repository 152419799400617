import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
import { getHostEnv } from "../../../../store/order/selectors/getHostEnv";

/**
 * TODO: Why is this here? DRY. Should just be reusing the main row template in
 * src/js/containers/StepCustomise/Hardware/Products/index.js
 */
export default function Expanded({
  index,
  product,
  config,
  setFields,
  hasConnections,
  canBeLeased,
  canBeLeasedRV
}) {
  const hostEnv = useSelector(getHostEnv);

  const is_leasing = _.get(config.rows, [index, "is_leasing"]);
  const credit_used = _.get(config.rows, [index, "credit_used"]) || "";
  const isLeasedRV = _.get(config.rows, [index, "isLeasedRV"]);
  const oneOffPrice = hasConnections
    ? product.price.oneOffWithConnection
    : product.price.oneOff;
  const leasePrice = isLeasedRV
    ? product.price.leaseRV
    : product.price.leaseNonRV;

  return (
    <TableRow>
      <TableCell />
      <TableCell>{product.name}</TableCell>
      <TableCell>1</TableCell>
      <TableCell>
        &pound;
        {!is_leasing ? (
          <span>{parseFloat(oneOffPrice - credit_used).toFixed(2)}</span>
        ) : (
          "0.00"
        )}
      </TableCell>
      {hostEnv !== "my_account" && [
        <TableCell key={0}>
          <Checkbox
            onChange={(e, checked) =>
              setFields(
                product.id,
                {
                  is_leasing: checked,
                  ...(!checked && { isLeasedRV: false }),
                  credit_used: 0
                },
                index
              )
            }
            checked={is_leasing}
            disabled={!canBeLeased}
          />
        </TableCell>,
        <TableCell key={1}>
          &pound;{is_leasing ? <span>{leasePrice}</span> : "0.00"}
        </TableCell>,
        <TableCell key={2}>
          <Checkbox
            onChange={(e, checked) =>
              setFields(
                product.id,
                {
                  isLeasedRV: checked,
                  ...(checked && { is_leasing: true })
                },
                index
              )
            }
            checked={isLeasedRV}
            disabled={!canBeLeasedRV}
          />
        </TableCell>
      ]}
      <TableCell>
        <TextField
          id={"credit_used_" + product.id + "_i_" + index}
          name={"credit_used_" + product.id + "_i_" + index}
          value={credit_used}
          onChange={e =>
            setFields(product.id, { credit_used: e.target.value }, index)
          }
          style={{ width: "100px" }}
          disabled={is_leasing}
          type="number"
        />
      </TableCell>
      <TableCell />
    </TableRow>
  );
}
