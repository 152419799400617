export const SET_STAGE = "accountSelect.SET_STAGE";
export const SET_CUSTOMER_OPTIONS = "accountSelect.SET_CUSTOMER_OPTIONS";
export const SET_LEAD_OPTIONS = "accountSelect.SET_LEAD_OPTIONS";
export const SET_FETCHING = "accountSelect.SET_FETCHING";
export const SET_EXISTING_SELECTED_CUSTOMER =
  "accountSelect.SET_EXISTING_SELECTED_CUSTOMER";
export const SET_EXISTING_OPPORTUNITY_NAME =
  "accountSelect.SET_EXISTING_OPPORTUNITY_NAME";
export const SET_EXISTING_SELECTED_CUSTOMER_ERROR =
  "accountSelect.SET_EXISTING_SELECTED_CUSTOMER_ERROR";
export const SET_EXISTING_OPPORTUNITY_NAME_ERROR =
  "accountSelect.SET_EXISTING_OPPORTUNITY_NAME_ERROR";
export const SET_FROM_LEAD_SELECTED_LEAD =
  "accountSelect.SET_FROM_LEAD_SELECTED_LEAD";
export const SET_FROM_LEAD_OPPORTUNITY_NAME =
  "accountSelect.SET_FROM_LEAD_OPPORTUNITY_NAME";
export const SET_FROM_LEAD_SELECTED_LEAD_ERROR =
  "accountSelect.SET_FROM_LEAD_SELECTED_LEAD_ERROR";
export const SET_FROM_LEAD_OPPORTUNITY_NAME_ERROR =
  "accountSelect.SET_FROM_LEAD_OPPORTUNITY_NAME_ERROR";
export const SET_NEW_CUSTOMER_FIRST_NAME =
  "accountSelect.SET_NEW_CUSTOMER_FIRST_NAME";
export const SET_NEW_CUSTOMER_FIRST_NAME_ERROR =
  "accountSelect.SET_NEW_CUSTOMER_FIRST_NAME_ERROR";
export const SET_NEW_CUSTOMER_LAST_NAME =
  "accountSelect.SET_NEW_CUSTOMER_LAST_NAME";
export const SET_NEW_CUSTOMER_LAST_NAME_ERROR =
  "accountSelect.SET_NEW_CUSTOMER_LAST_NAME_ERROR";
export const SET_NEW_CUSTOMER_EMAIL = "accountSelect.SET_NEW_CUSTOMER_EMAIL";
export const SET_NEW_CUSTOMER_EMAIL_ERROR =
  "accountSelect.SET_NEW_CUSTOMER_EMAIL_ERROR";
export const SET_NEW_CUSTOMER_OPPORTUNITY_NAME =
  "accountSelect.SET_NEW_CUSTOMER_OPPORTUNITY_NAME";
export const SET_NEW_CUSTOMER_OPPORTUNITY_NAME_ERROR =
  "accountSelect.SET_NEW_CUSTOMER_OPPORTUNITY_NAME_ERROR";
