import _ from "lodash";
import { getISODay, addDays, format } from "date-fns";
import { getBankHolidays } from "./bankHolidays";

/**
 * The date format used in the DC API
 * @type {string}
 */
export const DC_DATE_FORMAT = "yyyy-MM-dd";

/**
 * Today's date as a DC formatted string
 * @type {string}
 */
export const todaysDateString = format(new Date(), DC_DATE_FORMAT);

export const isWorkingDay = date => {
  let isWorkingDay = getISODay(date) !== 6 && getISODay(date) !== 7;
  let notBankHoliday = true;
  if (isWorkingDay) {
    notBankHoliday = isNotBankHoliday(format(date, DC_DATE_FORMAT)); // this.... used mobile eth etc.
  }
  return isWorkingDay && notBankHoliday;
};

export function addWeekdays(date, daysToAdd, returnString = true) {
  while (daysToAdd > 0) {
    date = addDays(date, 1);
    // decrease "days" only if it's a weekday and not a bank holiday.
    if (
      getISODay(date) !== 6 &&
      getISODay(date) !== 7 &&
      isNotBankHoliday(format(date, DC_DATE_FORMAT))
    ) {
      daysToAdd -= 1;
    }
  }
  return returnString ? format(date, DC_DATE_FORMAT) : date;
}

const isNotBankHoliday = dateString => {
  return _.indexOf(getBankHolidays(), dateString) === -1;
};
