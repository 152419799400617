export const SET_ACTIVE_STEP = "uiState.SET_ACTIVE_STEP";
export const ADD_FLASH_MESSAGE = "uiState.ADD_FLASH_MESSAGE";
export const REMOVE_FLASH_MESSAGES = "uiState.REMOVE_FLASH_MESSAGES";
export const TOGGLE_STEP_ONE_MOBILE = "uiState.TOGGLE_STEP_ONE_MOBILE";
export const TOGGLE_STEP_ONE_HARDWARE = "uiState.TOGGLE_STEP_ONE_HARDWARE";
export const TOGGLE_STEP_ONE_ETHERNET = "uiState.TOGGLE_STEP_ONE_ETHERNET";
export const TOGGLE_STEP_ONE_WLR_BROADBAND =
  "uiState.TOGGLE_STEP_ONE_WLR_BROADBAND";
export const TOGGLE_STEP_ONE_UNIVERSAL_PRODUCTS =
  "uiState.TOGGLE_STEP_ONE_UNIVERSAL_PRODUCTS";
export const TOGGLE_STEP_ONE_MONITORING_SERVICE =
  "uiState.TOGGLE_STEP_ONE_MONITORING_SERVICE";
export const ADD_ALERT_MESSAGE = "uiState.ADD_ALERT_MESSAGE";
export const REMOVE_ALERT_MESSAGES = "uiState.REMOVE_ALERT_MESSAGES";
export const SHOW_FINAL_STEP_WARNING = "uiState.SHOW_FINAL_STEP_WARNING";
export const HIDE_FINAL_STEP_WARNING = "uiState.HIDE_FINAL_STEP_WARNING";
export const CONFIRM_FINAL_STEP_WARNING = "uiState.CONFIRM_FINAL_STEP_WARNING";
export const TOGGLE_ORDER_STATUS = "uiState.TOGGLE_ORDER_STATUS";
export const SET_STEPS = "uiState.SET_STEPS";
export const RESET_UI_STATE = "uiState.RESET_UI_STATE";
export const SET_PRODUCT_TYPES = "uiState.SET_PRODUCT_TYPES";
export const SET_ACTIVE_SUB_STEP_CHOOSE = "uiState.SET_ACTIVE_SUB_STEP_CHOOSE";
export const SET_HARDWARE_CONFIG = "uiState.SET_HARDWARE_CONFIG";
export const SET_SHOW_MOBILE_RESIGNS = "uiState.SET_SHOW_MOBILE_RESIGNS";
export const SET_SHOW_WLR_RESIGNS = "uiState.SET_SHOW_WLR_RESIGNS";
