import React from "react";
import Section from "../../../../../components/Shared/Section";
import BuildIcon from "@material-ui/icons/Build";
import ArrayDynamicField from "../ArrayDynamicField";

const UserDefinedFields = ({ dynamicProperties = {} }) => {
  return (
    <Section
      title="User-Defined Fields"
      avatar={<BuildIcon />}
      defaultExpanded={false}
    >
      {Object.values(dynamicProperties).map((dp, index) => {
        if (dp.name.includes("user_defined_field_"))
          return (
            <ArrayDynamicField
              key={index}
              propertyName={dp.name}
              floatingLabelText={dp.label}
              isRootProperty={true}
            />
          );
        return false;
      })}
    </Section>
  );
};

UserDefinedFields.propTypes = {};

export default UserDefinedFields;
