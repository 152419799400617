import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField
} from "@material-ui/core";

export interface RenameDraftProps {
  open: boolean;
  handleRename: (newName: string) => void;
  handleClose: () => void;
}

export const RenameDraft = ({
  open,
  handleRename,
  handleClose
}: RenameDraftProps) => {
  const [newName, setNewName] = useState<string>("");

  const handleNewNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(event.target.value);
  };

  const handleSave = () => {
    handleRename(newName);
    handleClose();
  };

  return (
    <Dialog
      data-cy="GenerateQuote"
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Rename Draft</DialogTitle>
      <DialogContent>
        <TextField
          label="New Name"
          value={newName}
          onChange={handleNewNameChange}
          type="text"
          size="small"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!newName}
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
