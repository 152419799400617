import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { VirtualTable } from "../../../../../components/VirtualTable";
import { ActiveSort } from "../../../../../components/VirtualTable/types";
import { VirtualTableSortOrder } from "../../../../../components/VirtualTable/enums";
import {
  handleActiveSort,
  sortVirtualTableData,
  useDebouncedState
} from "../../../../../components/VirtualTable/utils";
import { ItemsSelected } from "../../../choose-mobile/tariff/product-table/ItemsSelected";
import { WlrLocationProduct, WlrLocationProductConfig } from "../../types";
import { RenderWlrLocationNewLineTableRow } from "./WlrLocationNewLineTableRow";
import { IndexedTitle } from "../../../../shared/indexed-card-info/IndexedTitle";

export const MAX_QUANTITY_BROADBAND = 50;

export interface WlrLocationNewLineTableProps {
  locationIndex: number;
  products: WlrLocationProduct[];
}

export const WlrLocationNewLineTable = ({
  locationIndex,
  products
}: WlrLocationNewLineTableProps) => {
  const classes = useStyles();
  const [activeSort, setActiveSort] = useState<ActiveSort>({
    sortIndex: "name",
    order: VirtualTableSortOrder.DESC
  });
  const {
    state: productFilter,
    debouncedState: debouncedProductFilter,
    handleState: handleProductFilter
  } = useDebouncedState();

  // Location configurations (quantities of products)
  const configurations = useSelector<any, WlrLocationProductConfig[]>(
    state => state.wlrBroadband.configurations ?? []
  );
  const locationConfigurations = configurations.filter(
    c => c.locationIndex === locationIndex
  );
  const locationConfigsCount = locationConfigurations.length;

  const filteredProducts = useMemo(() => {
    return products
      .filter(
        ({ name }) =>
          name.toLowerCase().indexOf(debouncedProductFilter.toLowerCase()) !==
          -1
      )
      .sort(sortVirtualTableData(activeSort));
  }, [products, debouncedProductFilter, activeSort]);

  const productsCount = products.length;

  const handleSort = useCallback(handleActiveSort(setActiveSort), [
    setActiveSort
  ]);

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        <IndexedTitle
          index={"C"}
          title={"Choose a Product"}
          withMargin={true}
          negative={true}
        />
      </Typography>
      <div className={classes.root}>
        <Box className={classes.searchBox}>
          <TextField
            data-cy={"tableSearch"}
            className={classes.search}
            variant="outlined"
            name="filter"
            value={productFilter}
            onChange={handleProductFilter}
            placeholder={`Search ${productsCount} Available Products`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          {!!locationConfigsCount && (
            <ItemsSelected
              maxQuantity={MAX_QUANTITY_BROADBAND}
              configsCount={locationConfigsCount}
            />
          )}
        </Box>
        <VirtualTable
          withScrollButton={true}
          headers={tableHeaders}
          activeSort={activeSort}
          handleSort={handleSort}
          data={{
            locationIndex,
            products: filteredProducts
          }}
          tableSize={500}
          itemSize={80}
          itemCount={filteredProducts.length}
          RenderTableRow={RenderWlrLocationNewLineTableRow}
        />
      </div>
    </>
  );
};

export const tableHeaders = [
  { label: "Product", sortIndex: "name", minWidth: 200 },
  {
    label: "Monthly Line Rental",
    sortIndex: "price.first_bill_recurring_price_with_promotions",
    width: 250
  },
  { label: "Quantity", sortIndex: "qty", width: 250, disabled: true },
  { label: "Broadband", sortIndex: "bb", width: 250, disabled: true },
  { label: "Monthly Broadband", sortIndex: "mb", width: 250, disabled: true }
];

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(1)
  },
  root: {
    width: "100%",
    margin: theme.spacing(1),
    backgroundColor: theme.palette.common.white
  },
  searchBox: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  search: {
    minWidth: "400px"
  }
}));
