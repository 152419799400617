export const getPathToApplication = (
  subDomain: string | undefined,
  pathname: string
): string => {
  const hostname = window.location.hostname;
  const domain = hostname.substring(hostname.indexOf(".") + 1, hostname.length);

  if (hostname.includes("localhost")) {
    return `http://localhost:3000${pathname}`;
  }

  if (!subDomain) {
    return `${window.location.protocol}//${window.location.hostname}${pathname}`;
  }

  return `${window.location.protocol}//${subDomain}.${domain}${pathname}`;
};
