import React, { useState } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { IndexedCardInfo } from "../../../../screens/shared/indexed-card-info";
import AccountToggle from "../../AccountToggle";
import AccountSelectField from "../../AccountSelectField";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsVfDirectConsumerAccount,
  shouldCollectCompanyAddress2,
  shouldCollectCompanyAddress3
} from "../../../../store/account/selectors";
import { CompanyAddressFields } from "./CompanyAddressFields";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useEffect } from "react";
import { differenceInCalendarMonths } from "date-fns/esm";
import { setNewAccountField } from "../../../../store/account/actions";
import { Alert } from "@akj-dev/design-system";

type Props = {
  cardIndex: number;
};

export const CompanyAddress = ({ cardIndex }: Props) => {
  const showAdditionalFields = useSelector(getIsVfDirectConsumerAccount);
  const showAddress2 = useSelector(shouldCollectCompanyAddress2);
  const showAddress3 = useSelector(shouldCollectCompanyAddress3);
  const newAccountValidation = useSelector(
    (state: any) => state.account.newAccountFieldsValidation
  );

  const dispatch = useDispatch();

  const [start, setStart] = useState<Date | null>(null);
  useEffect(() => {
    if (!start) {
      return;
    }
    const now = new Date();
    const months = differenceInCalendarMonths(now, start);
    dispatch(setNewAccountField("company_months_at_address", months));
  }, [start, dispatch]);

  const [start2, setStart2] = useState<Date | null>(null);
  useEffect(() => {
    if (!start2 || !start) {
      return;
    }
    const months = differenceInCalendarMonths(start, start2);
    dispatch(setNewAccountField("company_months_at_address2", months));
  }, [start, start2, dispatch]);

  const [start3, setStart3] = useState<Date | null>(null);
  useEffect(() => {
    if (!start3 || !start2) {
      return;
    }
    const months = differenceInCalendarMonths(start2, start3);
    dispatch(setNewAccountField("company_months_at_address3", months));
  }, [start2, start3, dispatch]);

  return (
    <IndexedCardInfo index={cardIndex} title="Company Address">
      {showAdditionalFields && (
        <Alert
          type="info"
          message="For Sole Trader / Owner Manager we require a minimum of 3 years address history."
        />
      )}
      <form>
        <Grid container spacing={2}>
          <CompanyAddressFields />
          {showAdditionalFields && (
            <>
              <AccountSelectField
                label="Residential Status"
                name="residential_status"
              >
                <MenuItem value="OWNER">Owner</MenuItem>
                <MenuItem value="TENANT">Tenant</MenuItem>
                <MenuItem value="PARENTS">Parents</MenuItem>
                <MenuItem value="OTHER">Other</MenuItem>
              </AccountSelectField>
              <Grid item sm={3}>
                <KeyboardDatePicker
                  error={newAccountValidation.company_months_at_address}
                  helperText={newAccountValidation.company_months_at_address}
                  label="Date from *"
                  format="dd/MM/yyyy"
                  value={start}
                  maxDate={new Date()}
                  onChange={setStart}
                />
              </Grid>
            </>
          )}

          <AccountToggle
            label="Billing address same as company address"
            name="billing_address_same"
          />
        </Grid>

        <>
          {showAddress2 && (
            <Grid container spacing={2}>
              <CompanyAddressFields suffix="2" />
              <Grid item sm={3}>
                <KeyboardDatePicker
                  error={newAccountValidation.company_months_at_address2}
                  helperText={newAccountValidation.company_months_at_address2}
                  label="Date from *"
                  format="dd/MM/yyyy"
                  value={start2}
                  maxDate={start}
                  onChange={setStart2}
                />
              </Grid>
              <Grid item sm={3}>
                <KeyboardDatePicker
                  label="Date to"
                  format="dd/MM/yyyy"
                  value={start}
                  disabled
                  onChange={() => {}}
                />
              </Grid>
            </Grid>
          )}
          {showAddress3 && (
            <Grid container spacing={2}>
              <CompanyAddressFields suffix="3" />
              <Grid item sm={3}>
                <KeyboardDatePicker
                  error={newAccountValidation.company_months_at_address3}
                  helperText={newAccountValidation.company_months_at_address3}
                  label="Date from *"
                  format="dd/MM/yyyy"
                  value={start3}
                  maxDate={start2}
                  onChange={setStart3}
                />
              </Grid>
              <Grid item sm={3}>
                <KeyboardDatePicker
                  label="Date to"
                  format="dd/MM/yyyy"
                  value={start2}
                  disabled
                  onChange={() => {}}
                />
              </Grid>
            </Grid>
          )}
        </>
      </form>
    </IndexedCardInfo>
  );
};
