import React from "react";
import { connect } from "react-redux";
import { getSelectedConfig } from "../../../../store/universalProducts/selectors";
import { selectConfig } from "../../../../store/universalProducts/actionCreators";
import Paper from "@material-ui/core/Paper";
import TableOuter from "../ConfigTable/TableOuter";
import ConfigTableRow from "../ConfigTable/ConfigTableRow";
import DownloadDocuments from "./DownloadDocuments";
import UploadDocuments from "./UploadDocuments";
import Pricing from "./Pricing";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";

function ConfigForm({ configIndex, selectConfig }) {
  const goBack = () => selectConfig(false);
  return (
    <div>
      <Paper style={{ marginBottom: 20 }}>
        <Grid
          item
          sm={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "1rem"
          }}
        >
          <h3>Editing Product Configuration</h3>
          <Button variant="contained" color="primary" onClick={goBack}>
            Return to Product List
          </Button>
        </Grid>
        <TableOuter hideAction>
          <ConfigTableRow configIndex={configIndex} hideAction />
        </TableOuter>
      </Paper>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <DownloadDocuments />
          <Pricing />
        </Grid>
        <Grid item sm={6}>
          <UploadDocuments />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    config: getSelectedConfig(state),
    configIndex: state.universalProducts.selectedConfigIndex
  };
};

export default connect(mapStateToProps, {
  selectConfig
})(ConfigForm);
