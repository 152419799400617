export function mapBroadbandToAccountProperty(propertyName) {
  switch (propertyName) {
    case "bb.contact.businessName":
      return "name";
    case "bb.contact.contactTelephone":
      return "phone";
    case "bb.contact.title":
      return "salutation";
    case "bb.contact.contactForename":
      return "first_name";
    case "bb.contact.contactSurname":
      return "last_name";
    case "bb.address.thoroughfareNumber":
      return "company_building";
    case "bb.address.thoroughfareName":
      return "company_street";
    case "bb.address.postTown":
      return "company_post_town";
    case "bb.address.postcode":
      return "company_postcode";
    case "county":
    case "bb.address.county":
      return "company_region";
    case "company":
      return "name";
    case "contact_telephone":
      return "phone";
    case "contact_name":
      return "first_name";
    case "thoroughfarenumber":
      return "company_building";
    case "thoroughfarename":
      return "company_street";
    case "posttown":
      return "company_post_town";
    case "postcode":
      return "company_postcode";
    default:
      return propertyName;
  }
}

export function mapBroadbandToAccountSalutation(value) {
  switch (value) {
    case "MR":
      return "Mr.";
    case "MRS":
      return "Mrs.";
    case "MS":
      return "Ms.";
    case "MISS":
      return "Miss";
    case "DR":
      return "Dr.";
    case "PROFESSOR":
      return "Prof.";
    default:
      return null;
  }
}
