import React from "react";
import { Box, makeStyles, Theme, Typography } from "@material-ui/core";

export const Info = () => {
  const classes = useStyles();

  return (
    <>
      <Box>
        <Typography className={classes.info} variant="body1">
          Please select one or more types of required products.
        </Typography>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.grey[300],
    padding: theme.spacing(2)
  }
}));
