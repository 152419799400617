import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { StatusChip } from "@akj-dev/design-system";
import { ReduxProductsResponse, ReduxFetchType } from "../../../../store/types";
import { requestMobileSearch } from "../../../../store/mobile/actionCreators";
import { MobileProductConfig, ReduxMobileProductType } from "../../../types";
import { ProductTable } from "./product-table";
import { Loader } from "../../../shared/loader";
import { getAccountSettings } from "../../../../store/account/selectors";
import { useMemo } from "react";
import { transformMobileProductForDisplay } from "../utils";

export const TariffTable = () => {
  const dispatch = useDispatch();
  const { contractLengthInMonthsUIFilter } = useSelector(
    (state: any) => state.mobile
  );

  const settings = useSelector(getAccountSettings);
  const mobileSearch = useSelector<
    any,
    ReduxFetchType<ReduxProductsResponse<ReduxMobileProductType>>
  >(state => state.mobile.mobileSearch);
  const configs = useSelector<any, MobileProductConfig[]>(
    state => state.mobile.configs ?? []
  );

  useEffect(() => {
    if (!mobileSearch?.response?.status) {
      dispatch(
        requestMobileSearch(settings.as_request_dynamic_products === "1")
      );
    }
  }, [dispatch, mobileSearch]);

  const configsCount = configs.filter(config => !config.resignId).length;

  const withO2 = settings.as_can_select_o2_products === "1";
  const supplierProducts = useMemo(
    () =>
      (mobileSearch?.response?.products || [])
        .filter(
          product =>
            !contractLengthInMonthsUIFilter ||
            parseInt(product.minimum_contract_length_in_months) ===
              contractLengthInMonthsUIFilter
        )
        .filter(product => !product.first_mobile_component.is_data_only_voice)
        .filter(
          product => withO2 || product.first_mobile_component.supplier !== "O2"
        )
        .map(transformMobileProductForDisplay),
    [mobileSearch, withO2, contractLengthInMonthsUIFilter]
  );

  const mobileError =
    mobileSearch?.response?.status === "error" &&
    !mobileSearch.fetching &&
    mobileSearch.response.message;

  const showLoader = mobileSearch?.fetching;
  const showTable = !showLoader && !mobileError;

  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"}>
      {showLoader && <Loader />}
      {showTable && (
        <ProductTable
          configsCount={configsCount}
          products={supplierProducts}
          configs={configs}
          isReseller={false}
          withCarrierFilter={withO2}
        />
      )}
      {mobileError && (
        <Box mb={1}>
          <StatusChip
            type="error"
            title="Error finding mobile products"
            message={mobileError}
            retry={() => dispatch(requestMobileSearch())}
          />
        </Box>
      )}
    </Box>
  );
};
