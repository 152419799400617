import React, { useState, PropsWithChildren } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import queryString from "query-string";

import {
  AuthCookies,
  AuthProvider,
  getCookieValue
} from "@akj-dev/affinity-platform";
import { ErrorFullScreen, LoadingFullScreen } from "@akj-dev/design-system";

import { LoginScreen } from "../auth/LoginScreen";
import { UATBanner } from "../components/UATBanner";
import config from "../config";
import { MUIProviders } from "../MUIProviders";

import { StandaloneAppLayout } from "./StandaloneAppLayout";

import "./style.css";

import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie11";

console.log(`
Git Revision: ${process.env.REACT_APP_GIT_SHA}
Stage: ${process.env.REACT_APP_STAGE}

`);

export const StandaloneAppWrapper = ({
  children
}: PropsWithChildren<Record<string, any>>) => {
  const stage = process.env.REACT_APP_STAGE;

  // If in development or uat, respect any existing auth API base URL in cookie
  const [authAPIBaseUrl, setAuthAPIBaseUrl] = useState(
    (stage !== "production" &&
      getCookieValue(AuthCookies.UAT_BANNER_AUTH_API)) ||
      config.authAPIBaseUrl[process.env.REACT_APP_STAGE]
  );

  const query = queryString.parse(window.location.search);

  return (
    <MUIProviders>
      <AuthProvider
        authApiBaseUrl={authAPIBaseUrl}
        loginToken={(query.login_token || "") as string}
        initScreen={() => (
          <LoadingFullScreen message="Loading user session..." />
        )}
        loginScreen={(handleLogin, loading, error) => (
          <>
            {(stage === "uat" || stage === "development") && (
              <UATBanner
                authAPIBaseUrl={authAPIBaseUrl}
                setAuthAPIBaseUrl={setAuthAPIBaseUrl}
              />
            )}
            <LoginScreen
              handleLogin={handleLogin}
              loading={loading}
              error={error}
            />
          </>
        )}
        errorScreen={(message, retry) => {
          return (
            <ErrorFullScreen
              message={`Authentication error: ${message}`}
              retry={retry}
            />
          );
        }}
      >
        {(stage === "uat" || stage === "development") && (
          <UATBanner
            authAPIBaseUrl={authAPIBaseUrl}
            setAuthAPIBaseUrl={setAuthAPIBaseUrl}
          />
        )}
        <Router>
          <StandaloneAppLayout>{children}</StandaloneAppLayout>
        </Router>
      </AuthProvider>
    </MUIProviders>
  );
};
