import { useDispatch, useSelector } from "react-redux";
import { clearState } from "./actionCreators";
import { resetOrderState } from "../order/actions";
// import { receiveAccountSettings } from "../account/actions";
// import { useAuth } from "@akj-dev/affinity-platform";
// import { transformAccountSettingsResponse } from "../../containers/common/LoadAccountSettingsPlatform";
import {
  FAILED_TO_SAVE_DRAFT,
  INVALID_SAVE_DRAFT,
  REQUEST_SAVE_DRAFT,
  SAVED_DRAFT
} from "../drafts/actionTypes";
import * as GuidedSalesAPI from "../../api/v1/guidedSales";
import { addAlertMessage, addFlashMessage } from "../uiState/actions";
import { useHistory } from "react-router-dom";

/**
 * Reset the store for a new order.
 */
export function useStartNewOrder() {
  const dispatch = useDispatch();
  // const { accountSettings, accountMeta } = useAuth();
  return () => {
    // clear root reducer
    dispatch(clearState());
    // clearing root reducer is not enough because config used by initial state will cache the order_id
    dispatch(resetOrderState());
  };
}

/**
 * Save draft and reset the store for a new order.
 */
export function useSaveDraftAndStartNewOrder() {
  const dispatch = useDispatch();
  const history = useHistory();
  const state: any = useSelector(state => state);

  return async () => {
    const requestSaveDraft = { type: REQUEST_SAVE_DRAFT };
    dispatch(requestSaveDraft);

    try {
      const data: any = await GuidedSalesAPI.SaveWizardState(
        state.order.id,
        JSON.stringify(state)
      );

      if (data.status === "success") {
        dispatch({ type: SAVED_DRAFT });

        // clear root reducer
        dispatch(clearState());
        // clearing root reducer is not enough because config used by initial state will cache the order_id
        dispatch(resetOrderState());
        // show success message
        dispatch(addFlashMessage("Order Saved"));

        // Navigate up a level which will be where the CPQ launched from in all current scenarios
        history.push(".");
      } else {
        const failed = { type: FAILED_TO_SAVE_DRAFT, error: data.message };
        dispatch(failed);
        dispatch(addAlertMessage(data.message));
      }
    } catch (error) {
      const invalidateSaveDraft = {
        type: INVALID_SAVE_DRAFT,
        error: error.message
      };
      dispatch(invalidateSaveDraft);
      dispatch(addAlertMessage(error.message));
    }
  };
}
