import { trackEvent } from "@redux-beacon/google-analytics";

/**
 * Simple Event map element for Redux Beacon tracking.
 * @param actionType
 * @returns {{}}
 */
export const trackSimpleEvent = actionType => ({
  [actionType]: trackEvent(
    action => ({
      category: actionType.split(".")[0],
      action: actionType
    }),
    "gtm1"
  )
});
