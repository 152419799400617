import React from "react";
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  Typography
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { toggleSogeaTermsConfirmation } from "../../../store/wlrBroadband/actions";

export interface SogeaTermsInterface {
  locationIndex: number;
}

export const SogeaTerms = ({ locationIndex }: SogeaTermsInterface) => {
  const dispatch = useDispatch();
  const sogeaTermsAccepted = useSelector<any, boolean>(
    state => state.wlrBroadband.locations[locationIndex].sogeaTermsAccepted
  );

  const handleChange = () =>
    dispatch(toggleSogeaTermsConfirmation(locationIndex));

  return (
    <Box mb={2}>
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            We would strongly advise additional considerations whereby the
            customer is reliant on devices which use the existing WLR/MPF
            service such as:
          </Typography>
          <Typography variant="body1" component="ul" gutterBottom>
            <li>Customer who are vulnerable or using pendant service</li>
            <li>
              Security systems, payment devices, fax machines and lift services
            </li>
            <li>
              Red care services or any devices that customer is reliant on which
              uses the WLR service
            </li>
          </Typography>

          <Typography variant="h4" gutterBottom>
            For further guidance please consult the SoGEA product description or
            your DWS Account Manager
          </Typography>
          <FormControlLabel
            label="I accept responsibility that I have read the above and fully understand the customer's requirements and the impact this will have on the WLR services along with any customer devices which currently rely on the WLR/MPF voice service."
            control={
              <Checkbox
                onChange={handleChange}
                checked={sogeaTermsAccepted}
                name="sogeaTermsAccepted"
              />
            }
          />
        </CardContent>
      </Card>
    </Box>
  );
};
