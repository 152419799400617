import u from "updeep";
import { compact } from "lodash";
import * as actionTypes from "../actionTypes";
import { transformMobilePricingData } from "../transformers";
import { getMinimumPortDate } from "../selectors/productConfig";
import { NEW_SIM, PRE_DISPATCHED_SIM } from "../constants";
import { format } from "date-fns";
import { DC_DATE_FORMAT } from "../../../helpers/date";

export default (state, action) => {
  let updates;
  switch (action.type) {
    case actionTypes.REQUEST_MOBILE_PRODUCT_DATA:
      return u(
        {
          productData: {
            [action.productId]: {
              fetching: true,
              response: false
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_MOBILE_PRODUCT_DATA:
      return u(
        {
          productData: {
            [action.productId]: {
              fetching: false,
              response: () => action.response
            }
          }
        },
        state
      );

    case actionTypes.REQUEST_MOBILE_PRICING_DATA:
      updates = {
        configs: {}
      };
      for (const id of action.configIds) {
        updates.configs[id] = {
          productData: {
            fetching: true
          }
        };
      }
      return u(updates, state);

    case actionTypes.RECEIVE_MOBILE_PRICING_DATA:
      updates = {
        configs: {}
      };
      for (let i = 0; i < action.configIds.length; i++) {
        updates.configs[action.configIds[i]] = {
          productData: {
            fetching: false,
            response: () => transformMobilePricingData(action.responses[i])
          }
        };
      }
      return u(updates, state);

    case actionTypes.REQUEST_CLI_BOLT_ON_PRODUCTS:
      return u(
        {
          cliBoltOnSearch: {
            [action.productId]: {
              fetching: true,
              response: false
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_CLI_BOLT_ON_PRODUCTS:
      return u(
        {
          cliBoltOnSearch: {
            [action.productId]: {
              fetching: false,
              response: () => action.response
            }
          }
        },
        state
      );

    case actionTypes.REQUEST_CLI_TOP_UP_BOLT_ON_PRODUCTS:
      return u(
        {
          cliBoltOnSearch: {
            [action.productId]: {
              fetching: true
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_CLI_TOP_UP_BOLT_ON_PRODUCTS:
      // Stop this blowing up when the request fails.
      // TODO: Yes this should probably have proper error handling, but it didn't in the first place so....
      if (!action.response.products) return state;

      return u(
        {
          cliBoltOnSearch: {
            [action.productId]: {
              fetching: false,
              response: {
                products: p => [...p, ...action.response.products]
              }
            }
          }
        },
        state
      );

    // Apply the same value to multiple configurations.
    case actionTypes.UPDATE_CONFIG_PROPERTY:
      updates = {
        configs: {}
      };
      for (const id of action.configIds) {
        // Extra logic stuff depending on the field:
        // Currently just port date.
        // We auto-populate that with different lead times depending on what's going on with the SIM.
        let port_date;
        switch (action.propertyName) {
          case "is_sim_required":
            port_date = format(
              getMinimumPortDate(
                action.value == "1" ? NEW_SIM : PRE_DISPATCHED_SIM // eslint-disable-line eqeqeq
              ),
              DC_DATE_FORMAT
            );
            break;
          case "acquisition_method":
            if (action.value === "port/mig") {
              const { properties } = state.configs[id];
              port_date = format(
                getMinimumPortDate(
                  properties.sim_is_buffer === "1" ||
                    properties.sim_is_required === "0"
                    ? PRE_DISPATCHED_SIM
                    : NEW_SIM
                ),
                DC_DATE_FORMAT
              );
            }
            break;
          case "sim_is_buffer":
            port_date = format(
              getMinimumPortDate(
                action.value === "1" ? PRE_DISPATCHED_SIM : NEW_SIM
              ),
              DC_DATE_FORMAT
            );
            break;
          default:
        }

        updates.configs[id] = {
          properties: {
            [action.propertyName]: action.value,
            ...(port_date && { port_date })
          }
        };
      }
      return u(updates, state);

    // Apply an array of values to multiple mobile configurations.
    // For the PAC & Mobile No. bulk entry requirements
    case actionTypes.ARRAY_UPDATE_CONFIG_PROPERTY:
      let arrayUpdates = {
        configs: {}
      };
      let i = 0;
      const values = compact(action.values);

      for (const id of action.configIds) {
        arrayUpdates.configs[id] = {
          properties: {
            [action.propertyName]: values[i]
              ? values[i].replace(/,/, "").trimLeft()
              : values[i]
          }
        };
        if (i < values.length - 1) i++;
      }
      return u(arrayUpdates, state);

    // Apply product discounts to multiple configurations
    // Similar to the one in src/js/store/wlrBroadband/reducer/actions/configurations.js
    // TODO: Perhaps we can unify this.
    case actionTypes.SET_MOBILE_PRODUCT_DISCOUNT:
      let productDiscountUpdate = {};
      action.configIds.forEach(
        i =>
          (productDiscountUpdate[i] = {
            properties: {
              ...(action.discountType !== null && {
                recurring_discount_type: action.discountType
              }),
              recurring_discount_override: action.value === false ? 0 : 1,
              recurring_discount_value: action.value
            }
          })
      );

      return u(
        {
          configs: productDiscountUpdate
        },
        state
      );

    case actionTypes.CHOOSE_CLI_BOLT_ON:
      return u(
        {
          configs: {
            [action.configId]: {
              selectedCliBoltOns: {
                [action.boltOnType]: action.boltOnId
              }
            }
          }
        },
        state
      );

    case actionTypes.REMOVE_CONFIG:
      return u(
        {
          configs: c => [
            ...c.slice(0, action.index),
            ...c.slice(action.index + 1)
          ]
        },
        state
      );

    case actionTypes.TOGGLE_RESERVED_NUMBERS:
      return u(
        {
          showReserverNumbers: !state.showReserverNumbers
        },
        state
      );

    case actionTypes.TOGGLE_BILL_CAP_CONFIRMATION:
      return u(
        {
          billCapConfirmed: !state.billCapConfirmed
        },
        state
      );

    case actionTypes.TOGGLE_DWS_TERMS_CONFIRMATION:
      return u(
        {
          dwsTermsAccepted: !state.dwsTermsAccepted
        },
        state
      );

    default:
      return state;
  }
};
