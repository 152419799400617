import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import { StatusChip } from "@akj-dev/design-system";
import TableRow from "@material-ui/core/TableRow";

const NoAddressError = () => {
  return (
    <TableRow>
      <TableCell colSpan="9">
        <StatusChip
          type="error"
          title="Error"
          message="This product instance doesn't have a full address. Please update the address in Daisy Central and
              restart the resign process."
        />
      </TableCell>
    </TableRow>
  );
};

export default NoAddressError;
