import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { connect } from "react-redux";
import Products from "./Products/index";
import { availableHardwareCredit } from "../../../store/account/actions";
import CreditWarning from "./CreditWarning";

class Hardware extends Component {
  componentDidMount() {
    Sentry.addBreadcrumb({
      message: `Step 2 Hardware mounted`,
      category: "action"
    });

    this.props.askForAvailableHardwareCredit();
  }

  render() {
    return (
      <>
        <Products />
        <CreditWarning />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  products: state.hardwareProducts
});

const mapDispatchProps = dispatch => ({
  askForAvailableHardwareCredit: () => {
    dispatch(availableHardwareCredit());
  }
});

export default connect(mapStateToProps, mapDispatchProps)(Hardware);
