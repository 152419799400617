import { FieldMergeFunction, Reference } from "@apollo/client";

/**
 * Type Policy:
 * Helper for merging paginated results following "Load More" action
 */
export const mergePaginatedResults: FieldMergeFunction = (
  existing = { data: [] },
  incoming,
  { args }
) => {
  // This is based on solution from the docs:
  // https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
  if (incoming.data && args) {
    const offset = args.page - 1;
    const pageSize = args.first;
    const mergedData = existing.data.slice(0);
    for (let i = 0; i < incoming.data.length; ++i) {
      mergedData[offset * pageSize + i] = incoming.data[i];
    }

    return {
      ...incoming,
      data: mergedData
    };
  }
  return incoming;
};

/**
 * cache.modify helper for updating fields after an object has been added that
 * follow the LightHouse paginatorInfo / data pattern used in affinity-sales-backend
 */
export const addNewObjectToCacheField = (
  newObjRef: Reference | null | undefined,
  existingField: any
) => ({
  ...existingField,
  data: existingField.data ? [newObjRef, ...existingField.data] : undefined,
  paginatorInfo: existingField.paginatorInfo
    ? {
        ...existingField.paginatorInfo,
        total: existingField.paginatorInfo.total + 1
      }
    : undefined
});
