import { getJSON, postJSON } from "../helpers/core";

/**
 * Get available mobile products
 */
export const MobileSearch = (
  account: string,
  is_dynamic = 1,
  lead_id?: string
) =>
  postJSON("Product/MobileSearch", {
    account,
    guided_sales: 1,
    is_dynamic,
    limit: 500,
    // mobile_is_data_only_voice: 0,
    ...(lead_id && { lead_id: lead_id })
  });

/**
 * Get available account level bolt-ons
 */
export const AccountBoltOnSearch = (account: string, is_dynamic = 1) =>
  postJSON("Product/AccountBoltOnSearch", {
    account,
    guided_sales: 1,
    is_dynamic,
    only_mobile_bolt_ons: 1
  });

/**
 * Get available bolt on products
 * Used in step 2 for CLI level ones per product
 */
export const MobileBoltOnSearch = (
  mobile_bolt_on_level: string,
  mobile_voice_product_id: string,
  account: string,
  isTopUpBoltOn: string,
  is_dynamic = 1,
  category_id: string
) =>
  postJSON("Product/MobileBoltOnSearch", {
    account,
    guided_sales: 1,
    is_dynamic,
    only_mobile_bolt_ons: 1,
    mobile_bolt_on_level,
    mobile_voice_product_id,
    ...(isTopUpBoltOn && { mobile_bolt_on_bolt_on_subtype: "top_up" }),
    category_id
  });

/**
 * Get existing product instances on account for resigns.
 * TODO: Figure out exactly which params are necessary
 *
 * @param productType - `Mobile`, `Line` or `Broadband`
 * @param account - Daisy account number
 * @param {number} page - pagination
 * @param params - Custom params
 * @returns {Promise<*>}
 * @constructor
 */

export const ProductInstance = (
  productType: string,
  account: string,
  page: any,
  params: any
) =>
  getJSON(`Product/Instance/${productType}`, {
    account,
    direction: "asc",
    limit: 20,
    offset: 0,
    page,
    hide_terminated: 1,
    single_account_only: true,
    ...params
  });

/**
 * Remove a special rate when resigning a product instance.
 *
 * @param params
 */

export const RemoveSpecialRate = (params: any) =>
  postJSON("Product/Instance/EndDateSpecial", params);

/**
 * Product Search
 *
 * Generic endpoint used to find:
 *  - The product used to do resigns without change.
 *  - Universal Products list
 *
 * @param params
 * @returns {Promise<*>}
 * @constructor
 */
export const search = (params: any) => getJSON("Product/Search", params);

/**
 * Find the Daisy Fresh product
 * @param account
 * @param mobile_bolt_on_service_provider_id
 * @returns {Promise<*>}
 */
export const daisyFreshSearch = (
  account: string,
  mobile_bolt_on_service_provider_id: string
) =>
  getJSON("Product/DaisyFreshSearch", {
    account,
    mobile_bolt_on_service_provider_id
  });

/**
 * Get the Hardware Credit product
 * (used for adding hardware credit to an account when Daisy Fresh is selected)
 * @param account
 * @returns {Promise<void>}
 */
export const hardwareCreditSearch = (account: string) =>
  getJSON("Product/HardwareCreditSearch", { account });

/**
 * Search for hardware products available to an account
 * @param account
 * @param pricing_scheme - a key from available_pricing_schemes array. ...currently all will be shown without this.
 * @param asBlankSimEnabled - TP15998
 * @returns {Promise<*>}
 */
export const hardwareSearch = (
  account: string,
  pricing_scheme = false,
  asBlankSimEnabled = "0",
  is_dynamic = 1
) =>
  getJSON("Product/HardwareSearch", {
    guided_sales: 1,
    is_dynamic,
    // 'hardware-is_leasing': 1,
    ...(asBlankSimEnabled === "0" && { hardware_is_sim: 0 }),
    basic: 1,
    limit: 500, // TODO: Pagination
    account,
    ...(pricing_scheme && { pricing_scheme })
  });

/**
 * Get documents attached to a product
 * PDFs, Excel and such. See FB155292
 * @param product_id
 * @returns {Promise<*>}
 */
export const listDocuments = (product_id: string) =>
  getJSON("Product/ListDocuments", { product_id });

/**
 * Get shipping methods available for stated account
 * @param {string} account
 */
export const shippingSearch = (account: string) =>
  getJSON(`Product/ShippingSearch`, { account });
