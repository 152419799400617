import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { getAddressLine } from "../../../helpers/addresses";

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 300,
    overflowY: "scroll",
    marginTop: theme.spacing(1)
  }
}));

function FilteredList({ items, renderRow, handleItemSelected }) {
  const classes = useStyles();
  const [filterText, setFilterText] = React.useState("");

  const setFilter = event => {
    setFilterText(event.target.value);
  };

  return (
    <div>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item>
          <SearchIcon />
        </Grid>
        <Grid item xs>
          <TextField
            label="Filter"
            value={filterText}
            onChange={setFilter}
            fullWidth
          />
        </Grid>
      </Grid>
      <List component="nav" className={classes.root}>
        {items
          .filter(
            item =>
              !filterText ||
              getAddressLine(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
          )
          .map((item, i) =>
            renderRow(item, i, () => {
              handleItemSelected(item);
            })
          )}
      </List>
    </div>
  );
}

FilteredList.propTypes = {
  items: PropTypes.array.isRequired,
  renderRow: PropTypes.func.isRequired, // signature: function(object: item, int: index, func: onClick)
  handleItemSelected: PropTypes.func.isRequired // signature: function(object: item)
};

export default FilteredList;
