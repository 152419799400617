import * as actionTypes from "./actionTypes";
import * as OrderProductAPI from "../../api/v1/orderProduct";
import _ from "lodash";

/**
 * Request universal products (saga)
 * @returns {{type: string}}
 */
export const requestProductSearch = () => ({
  type: actionTypes.REQUEST_PRODUCT_SEARCH
});

/**
 * Add a product configuration
 * @param productId
 * @returns {{type: string}}
 */
export const addProduct = productId => ({
  type: actionTypes.ADD_PRODUCT,
  productId
});

/**
 * Remove a product configuration
 * @param productId
 * @returns {{type: string}}
 */
export const removeProduct = productId => ({
  type: actionTypes.REMOVE_PRODUCT,
  productId
});

/**
 * Edit a product config
 * Sets the ID to point the config form at on step 2.
 * ...or false to stop editing and get back to the table.
 * @param configIndex
 * @returns {{configIndex: *, type: string}}
 */
export const selectConfig = configIndex => ({
  type: actionTypes.SELECT_CONFIG,
  configIndex
});

export const requestListDocuments = productId => ({
  type: actionTypes.REQUEST_LIST_DOCUMENTS,
  productId
});

/**
 * Add a document from dropzone, ready to upload
 * @param files
 * @returns {{file: *, type: string}}
 */
export const addUserDocument = files => ({
  type: actionTypes.ADD_USER_DOCUMENT,
  files
});

/**
 * Remove a document (before it gets uploaded)
 * @param index
 * @returns {{index: *, type: string}}
 */
export const removeUserDocument = index => ({
  type: actionTypes.REMOVE_USER_DOCUMENT,
  index
});

/**
 * Set a flag for whether the user wants to send a document with the contract or not.
 * @param index
 * @param pref
 * @returns {{pref: *, index: *, type: string}}
 */
export const setUserDocumentSendPref = (index, pref) => ({
  type: actionTypes.SET_USER_DOCUMENT_SEND_PREF,
  index,
  pref
});

/**
 * Set config property - just pricing currently
 * @param property
 * @param value
 * @returns {{property: *, type: string, value: *}}
 */
export const setConfigProperty = (property, value) => ({
  type: actionTypes.SET_CONFIG_PROPERTY,
  property,
  value
});

/**
 * Fire OrderProduct calls for universal products and upload associated documents
 * Note this is not a Saga like the rest of the UP calls as the main process in
 * `src/js/store/order/actions.js` hasn't been refactored yet.
 * @returns {Function}
 */
export const orderUniversalProducts = () => async (dispatch, getState) => {
  const state = getState();
  const { configs } = state.universalProducts;

  await Promise.all(
    configs.map(async (config, configIndex) => {
      // Check if the product's already in the order. (If it is, update instead of create)
      const orderProductId = _.get(config, "orderProduct.response.data.id");
      const orderParams = {
        contract_length_in_months: state.order.contractLength,
        account_id: state.order.accountId,
        order_id: state.order.id,
        "universal-recurring_discount_override": 1,
        "universal-recurring_discount_type": "SpecifyPrice",
        "universal-recurring_discount_value": config.recurringPrice,
        "universal-one_off_discount_override": 1,
        "universal-one_off_discount_type": "SpecifyPrice",
        "universal-one_off_discount_value": config.oneOffPrice
      };

      const shouldUpdate = !!orderProductId;
      dispatch({
        type: actionTypes.REQUEST_ORDER_PRODUCT,
        configIndex,
        isUpdate: shouldUpdate
      });
      const response = shouldUpdate
        ? await OrderProductAPI.update(orderProductId, orderParams)
        : await OrderProductAPI.create(config.productId, orderParams);
      dispatch({
        type: actionTypes.RECEIVE_ORDER_PRODUCT,
        configIndex,
        response
      });
      const productInstanceId = _.get(
        getState().universalProducts.configs[configIndex].orderProduct.response,
        "data.id"
      );
      if (productInstanceId) {
        await Promise.all(
          config.userDocuments.map(async (doc, docIndex) => {
            dispatch({
              type: actionTypes.REQUEST_UPLOAD_DOCUMENT,
              configIndex,
              docIndex
            });
            const response = await OrderProductAPI.uploadDocument(
              productInstanceId,
              doc,
              config.userDocumentsSendPref[docIndex]
            );
            dispatch({
              type: actionTypes.RECEIVE_UPLOAD_DOCUMENT,
              configIndex,
              docIndex,
              response
            });
          })
        );
      } else {
        console.log("No product instance ID returned to upload docs against");
      }
    })
  );
};
