import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { getPathToApplication } from "../helpers/getPathToApplication";

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: 0,
    minHeight: 75
  },
  actions: {
    padding: 16
  },
  label: {
    fontSize: theme.typography.pxToRem(14)
  }
}));

function HomeScreenCard({ title, description, link, subDomain }) {
  const classes = useStyles();

  const onGoToClick = () => {
    window.location.href = getPathToApplication(subDomain, link);
  };

  return (
    <Card raised>
      <CardHeader title={title} />
      <CardContent className={classes.content}>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className={classes.label}
          onClick={onGoToClick}
        >
          Go to {title}
        </Button>
      </CardActions>
    </Card>
  );
}

HomeScreenCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  subDomain: PropTypes.string.isRequired
};

export default HomeScreenCard;
