import * as rootActionTypes from "./actionTypes";

/**
 * Rehydrate store from saved order state from DC.
 * @param state
 * @returns {{type: string}}
 */
export const loadWizardState = state => ({
  type: rootActionTypes.LOAD_WIZARD_STATE,
  state
});

export const clearState = () => ({ type: rootActionTypes.CLEAR_STATE });
