import html2canvas from "html2canvas";
import * as actions from "./actionTypes";
import * as AffinitySupportAPI from "../../api/v1/affinitySupport";
import { addAlertMessage, addFlashMessage } from "../uiState/actions";

export const open = () => {
  return async (dispatch, getState) => {
    html2canvas(document.body).then(function(canvas) {
      dispatch(screenshot(canvas.toDataURL()));
    });

    dispatch({ type: actions.OPEN });
  };
};

export const screenshot = screenshot => ({
  type: actions.SCREENSHOT,
  screenshot
});

export const close = () => ({
  type: actions.CLOSE
});

export const onChange = event => ({
  type: actions.UPDATE,
  [event.target.name]: event.target.value
});

export const create = () => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.CREATING_SUPPORT_CASE });

    // Get case details from state
    const caseDetails = getState().supportCase;

    if (!caseDetails.short_description || !caseDetails.description) {
      return dispatch({ type: actions.VALIDATION_FAILED });
    }

    // Get the order from the state
    const order = getState().order;

    try {
      const supportCase = await AffinitySupportAPI.Create({
        account: order.accountId,
        order: order.id,
        ...caseDetails
      });

      if (supportCase.status === "error") throw new Error(supportCase.message);
      console.info(supportCase);

      dispatch({ type: actions.SUPPORT_CASE_CREATED, supportCase });
      dispatch(
        addFlashMessage("Support case #" + supportCase.ticket + " was created.")
      );
    } catch (error) {
      dispatch({ type: actions.CREATING_SUPPORT_CASE_FAILED });
      dispatch(addAlertMessage(error));
    }
  };
};
