import { call, put, takeLeading, takeEvery, select } from "redux-saga/effects";
import * as ProductAPI from "../../api/v1/product";
import { getAccountId } from "../order/selectors";
import * as actionTypes from "./actionTypes";
import { getAccountSettings } from "../account/selectors";

/**
 * Get available products in the universal product category.
 * @param action
 * @returns {IterableIterator<*>}
 */
function* fetchProductSearch(action) {
  const accountId = yield select(getAccountId);
  const accountSettings = yield select(getAccountSettings);
  const response = yield call(ProductAPI.search, {
    account_id: accountId,
    category_id: accountSettings.category_id_universal
  });
  yield put({ type: actionTypes.RECEIVE_PRODUCT_SEARCH, response });
}

export function* watchProductSearch() {
  yield takeLeading(actionTypes.REQUEST_PRODUCT_SEARCH, fetchProductSearch);
}

function* fetchListDocuments({ productId }) {
  const response = yield call(ProductAPI.listDocuments, productId);
  yield put({ type: actionTypes.RECEIVE_LIST_DOCUMENTS, productId, response });
}

export function* watchListDocuments() {
  yield takeEvery(actionTypes.REQUEST_LIST_DOCUMENTS, fetchListDocuments);
}
