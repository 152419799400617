import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import ArrayDynamicField from "../ArrayDynamicField";
import { verifySimSerials } from "../../../../../store/mobile/actionCreators";
import { StatusChip, CheckIcon, LoaderButton } from "@akj-dev/design-system";
import ErrorIcon from "@material-ui/icons/Error";
import { Box } from "@material-ui/core";
import { TargetConfigsContext } from "../../../context/TargetConfigsContext";

const SIM_UNVERIFIED = "SIM_UNVERIFIED";
const SIM_VERIFIED = "SIM_VERIFIED";
const SIM_ERROR = "SIM_ERROR";

class SimSerialVerification extends Component {
  static propTypes = {};
  static contextType = TargetConfigsContext;

  render() {
    const { verifySimSerials, configs } = this.props;
    const { targetConfigs } = this.context;

    const isFetchingSIM = _.find(
      configs,
      c => _.get(c, "simValidCheck.fetching") === true
    );

    let simStatus = SIM_UNVERIFIED;
    for (const id of targetConfigs) {
      if (_.get(configs[id], "simValidCheck.response.is_valid_sim_number"))
        simStatus = SIM_VERIFIED;
    }
    for (const id of targetConfigs) {
      if (
        _.get(configs[id], "simValidCheck.response.is_valid_sim_number") === 0
      )
        simStatus = SIM_ERROR;
    }

    return (
      <Box>
        <ArrayDynamicField
          propertyName="sim_buffer_serial"
          floatingLabelText="SIM Serial"
          disabled={isFetchingSIM}
        />
        <Box display="flex">
          <LoaderButton
            loading={isFetchingSIM}
            onClick={() => verifySimSerials(targetConfigs)}
            data-cy="SimSerialVerify"
            color="primary"
            disabled={!configs[0].properties.sim_buffer_serial}
          >
            Verify
          </LoaderButton>
          <Box ml={1}>
            {simStatus === SIM_VERIFIED && (
              <CheckIcon data-cy="SimVerified" fontSize="large" />
            )}
            {simStatus === SIM_ERROR && (
              <ErrorIcon fontSize="large" color="error" />
            )}
          </Box>
        </Box>
        {_.get(configs[0], "simValidCheck.response.status") === "error" && (
          <Box mt={1}>
            <StatusChip
              type="error"
              title="Sim Validation Error"
              message={configs[0].simValidCheck.response.message}
            />
          </Box>
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // TODO: just take the simValidCheck state
    configs: state.mobile.configs
  };
};

export default connect(mapStateToProps, {
  verifySimSerials
})(SimSerialVerification);
