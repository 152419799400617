import React from "react";
import { connect } from "react-redux";
import Section from "../../../../components/Shared/Section";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import FileDrop from "../../../../components/Shared/FileUpload/FileDrop";
import {
  addUserDocument,
  removeUserDocument,
  setUserDocumentSendPref
} from "../../../../store/universalProducts/actionCreators";
import UserDocumentChip from "./UserDocumentChip";

class UploadDocuments extends React.Component {
  static propTypes = {};
  state = {};

  render() {
    const {
      addUserDocument,
      removeUserDocument,
      setUserDocumentSendPref,
      userDocuments,
      userDocumentsSendPref
    } = this.props;
    return (
      <Section title="Upload Documents" avatar={<InsertDriveFileIcon />}>
        <FileDrop
          onDrop={files => {
            addUserDocument(files);
          }}
          message="Drag completed documents here"
        />
        {userDocuments.map((file, i) => (
          <UserDocumentChip
            file={file}
            sendPref={userDocumentsSendPref[i]}
            key={i}
            removeAction={() => removeUserDocument(i)}
            sendPrefAction={pref => setUserDocumentSendPref(i, pref)}
          />
        ))}
      </Section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedConfigIndex } = state.universalProducts;
  return {
    userDocuments:
      state.universalProducts.configs[selectedConfigIndex].userDocuments,
    userDocumentsSendPref:
      state.universalProducts.configs[selectedConfigIndex].userDocumentsSendPref
  };
};

export default connect(mapStateToProps, {
  addUserDocument,
  removeUserDocument,
  setUserDocumentSendPref
})(UploadDocuments);
