import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckIcon from "@material-ui/icons/Check";
import { useTheme } from "@material-ui/core/styles";

const Container = styled.div`
  color: ${props => props.color};
  display: flex;
  align-items: center;
  i {
    margin-right: 8px;
  }
`;

const SmallStatus = ({ type, style, children }) => {
  const theme = useTheme();

  let color, icon;
  switch (type) {
    case "error":
      color = theme.palette.error.main;
      icon = <ErrorOutlineIcon />;
      break;
    case "info":
      color = theme.palette.info.main;
      icon = <InfoOutlinedIcon />;
      break;
    case "success":
      color = theme.palette.success.main;
      icon = <CheckIcon />;
      break;
    default:
  }
  return (
    <Container color={color} style={style}>
      {icon}
      {children}
    </Container>
  );
};

SmallStatus.propTypes = {
  message: PropTypes.node,
  type: PropTypes.oneOf(["error", "success", "info"])
};

export default SmallStatus;
