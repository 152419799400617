import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { FormHelperText, Select as SelectField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FieldContainer from "../../WlrBBDynamicField/FieldContainer";
import styled from "styled-components";
import {
  getDynamicPropertyValue,
  makeGetDynamicPropertyInstances,
  makeGetDynamicPropertyValues
} from "../../../../../../store/wlrBroadband/selectors";
import {
  doRemoteValidation,
  getProductData,
  setProductProperty,
  validateProductProperty
} from "../../../../../../store/wlrBroadband/actions";

const Price = styled.div`
  display: inline-block;
  padding-left: 10px;
  font-size: 0.9em;
  line-height: 1;
  font-weight: bold;
  vertical-align: middle;
`;

const Description = styled.div`
  font-size: 0.9em;
  line-height: 1.6em;
  color: #999;
  max-width: 400px;
  white-space: normal;
`;

class SelectRouter extends Component {
  render() {
    const {
      targetConfigs,
      dynamicPropertyInstances,
      propertyValues,
      propertyValidation,
      setProductProperty,
      getProductData,
      doRemoteValidation,
      validateProductProperty,
      siteVisitReason
    } = this.props;

    const dynamicProperty = dynamicPropertyInstances[0];
    const propertyValue = propertyValues[0];

    // Available router options are dependant on the site visit reason.
    const availableOptionDetails = _.filter(
      dynamicProperty.available_option_details,
      option => {
        if (
          siteVisitReason === "NO_SITE_VISIT" &&
          option.install_type === "Managed"
        )
          return false;
        if (
          (siteVisitReason === "STANDARD" || siteVisitReason === "PREMIUM") &&
          option.install_type === "Self"
        )
          return false;
        return true;
      }
    );

    return (
      <FieldContainer>
        <FormControl fullWidth error={propertyValidation} margin="normal">
          <InputLabel>{`${dynamicProperty.label}${
            dynamicProperty.is_required ? " *" : ""
          }`}</InputLabel>
          <SelectField
            value={propertyValue ? propertyValue : ""}
            onChange={event => {
              setProductProperty(
                targetConfigs,
                "broadband",
                "router_id",
                event.target.value
              );
              targetConfigs.forEach(config => {
                getProductData(config, "broadband");
              });
              validateProductProperty(
                targetConfigs,
                "broadband",
                "router_id",
                dynamicProperty
              );
              targetConfigs.forEach(config => {
                doRemoteValidation(config);
              });
            }}
          >
            <MenuItem value={null} />
            {availableOptionDetails.map(option => {
              let recurringPrice = _.get(
                option,
                "first_bill_recurring_price_with_promotions",
                0
              );
              let oneOffPrice = _.get(
                option,
                "one_off_price_with_promotions",
                0
              );
              if (parseFloat(recurringPrice) === 0) recurringPrice = false;
              if (parseFloat(oneOffPrice) === 0) oneOffPrice = false;
              const description = _.get(option, "product_description", false);
              return (
                <MenuItem key={option.product_id} value={option.product_id}>
                  <div>
                    {option.product_name}
                    {oneOffPrice && <Price>£{oneOffPrice} (one off)</Price>}
                    {recurringPrice && <Price>£{recurringPrice} / month</Price>}
                    {description && <Description>{description}</Description>}
                  </div>
                </MenuItem>
              );
            })}
          </SelectField>
          {propertyValidation && (
            <FormHelperText>{propertyValidation}</FormHelperText>
          )}
        </FormControl>
      </FieldContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const getDynamicPropertyInstances = makeGetDynamicPropertyInstances();
  const getDynamicPropertyValues = makeGetDynamicPropertyValues();
  return {
    dynamicPropertyInstances: getDynamicPropertyInstances(state, ownProps),
    propertyValues: getDynamicPropertyValues(state, ownProps),
    propertyValidation:
      state.wlrBroadband.configurations[ownProps.targetConfigs[0]].validation[
        ownProps.propertyName
      ],
    siteVisitReason: getDynamicPropertyValue(
      state,
      "broadband",
      ownProps.targetConfigs[0],
      "bb.site_visit_reason"
    )
  };
};

export default connect(mapStateToProps, {
  setProductProperty,
  getProductData,
  doRemoteValidation,
  validateProductProperty
})(SelectRouter);
