import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FileDrop from "../../../components/Shared/FileUpload/FileDrop";
import {
  resetUploadStatus,
  setContractMeta,
  uploadContract
} from "../../../store/order/actions";
import { StatusChip } from "@akj-dev/design-system";
import StatusLarge from "../../../components/Shared/StatusLarge";
import Button from "@material-ui/core/Button";
import { DC_DATE_FORMAT } from "../../../helpers/date";
import {
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Box,
  CircularProgress
} from "@material-ui/core";

class Content extends Component {
  static defaultProps = {};
  static propTypes = {
    closeDialog: PropTypes.func.isRequired
  };
  state = {};

  setSignedAt = (e, date) => {
    this.props.dispatch(setContractMeta("signedAt", date));
  };

  setReceivedAt = (e, date) => {
    this.props.dispatch(setContractMeta("receivedAt", date));
  };

  setContractFile = files => {
    this.props.dispatch(setContractMeta("file", files[0]));
  };

  doUploadContract = (event, date) => {
    this.props.dispatch(uploadContract());
  };

  doResetUploadStatus = () => {
    this.props.dispatch(resetUploadStatus());
  };

  render() {
    const {
      uploadSending,
      uploadResponse,
      receivedAt,
      signedAt,
      file
    } = this.props;

    const contractId = _.get(uploadResponse, "data.contract_id", false);

    const dialogActions = contractId ? (
      <Button variant="contained" onClick={this.props.closeDialog}>
        Close
      </Button>
    ) : (
      [
        <Button
          variant="contained"
          key="cancel"
          onClick={this.props.closeDialog}
          disabled={uploadSending}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>,
        uploadResponse ? (
          <Button
            variant="contained"
            color="primary"
            key="retry"
            onClick={this.doResetUploadStatus}
            disabled={uploadSending}
          >
            Retry
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            key="upload"
            onClick={this.doUploadContract}
            disabled={uploadSending || !file}
            data-cy="UploadContractButton"
          >
            Upload
          </Button>
        )
      ]
    );

    return (
      <>
        <DialogContent>
          {uploadSending || uploadResponse ? (
            <div className="u-text-center">
              {uploadSending && (
                <Box
                  m={4}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box mb={4}>
                    <CircularProgress size={80} />
                  </Box>
                  <Typography variant="h5">Uploading Contract</Typography>
                </Box>
              )}
              {uploadResponse && (
                <div>
                  {contractId ? (
                    <StatusLarge
                      type="success"
                      title={`Success. Contract ID: ${contractId}`}
                    />
                  ) : (
                    <StatusLarge
                      type="error"
                      title="Sorry, something went wrong."
                      message={uploadResponse.message}
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            <Box my={2}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  {file ? (
                    <StatusChip title="File selected" message={file.name} />
                  ) : (
                    <FileDrop
                      onDrop={this.setContractFile}
                      message="Drag a signed contract here"
                      maxSize={3e7}
                    />
                  )}
                </Grid>
                <Grid item sm={12}>
                  <Typography variant="h4">
                    Please enter contract information:
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <KeyboardDatePicker
                    label="Received Date"
                    id="receivedDate"
                    value={receivedAt}
                    onChange={this.setReceivedAt}
                    fullWidth
                    format={DC_DATE_FORMAT}
                  />
                </Grid>
                <Grid item sm={6}>
                  <KeyboardDatePicker
                    label="Signed Date"
                    id="signedDate"
                    value={signedAt}
                    onChange={this.setSignedAt}
                    fullWidth
                    format={DC_DATE_FORMAT}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uploadSending: state.order.contractUpload.sending,
    uploadResponse: state.order.contractUpload.response,
    receivedAt: state.order.contractUpload.receivedAt,
    signedAt: state.order.contractUpload.signedAt,
    file: state.order.contractUpload.file
  };
};

export default connect(mapStateToProps)(Content);
