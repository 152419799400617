import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { setActiveStep } from "../../store/uiState/actions";
import { Button, Grid, Box } from "@material-ui/core";
import {
  STEP_CHOOSE,
  STEP_SUMMARY,
  STEP_ACCOUNT
} from "../../store/uiState/constants";
import { CancelSaleButton } from "./CancelSaleButton";
import {
  getBackButtonDisabled,
  getNextButtonDisabled,
  getSaveAndExitButtonDisabled
} from "../../store/uiState/selectors";
import { getHostEnv } from "../../store/order/selectors/getHostEnv";

/**
 * Main footer nav for all but "Choose" step of the CPQ
 * Also see packages/cpq/src/screens/shared/footer/index.tsx
 * TODO: Why are there two? Combine?
 * @returns {JSX.Element|*}
 * @constructor
 */
export function StepNavFooter() {
  const dispatch = useDispatch();
  const hostEnv = useSelector(getHostEnv);
  const activeStep = useSelector((state: any) => state.uiState.activeStep);
  const steps = useSelector((state: any) => state.uiState.steps);
  const backButtonDisabled = useSelector(getBackButtonDisabled);
  const nextButtonDisabled = useSelector(getNextButtonDisabled);
  const hasCompletedCreateAccount = useSelector(
    (state: any) => state.account.createAccount.response?.status === "success"
  );
  const saveExitButtonDisabled = useSelector(getSaveAndExitButtonDisabled);

  const doStep = (i: number) => {
    let availableSteps = steps;
    if (activeStep !== STEP_ACCOUNT && hasCompletedCreateAccount) {
      availableSteps = steps.filter((step: any) => step !== STEP_ACCOUNT);
    }
    const nextStep = availableSteps[availableSteps.indexOf(activeStep) + i];
    if (nextStep) dispatch(setActiveStep(nextStep));
  };

  const getCancelSaleButtonIfAllowed = () => {
    if (hostEnv === "dc") {
      return null;
    }
    if (saveExitButtonDisabled) {
      return null;
    }
    return <CancelSaleButton />;
  };

  if (activeStep === STEP_SUMMARY) {
    return getCancelSaleButtonIfAllowed();
  }

  return (
    <Box mt={1}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex">
            {activeStep !== STEP_CHOOSE && (
              <Box marginRight={1}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => doStep(-1)}
                  disabled={backButtonDisabled}
                  data-cy="StepNavFooterBack"
                >
                  Previous
                </Button>
                {hostEnv !== "dc" && getCancelSaleButtonIfAllowed()}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          {activeStep !== STEP_CHOOSE && (
            <Button
              color="primary"
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              disabled={nextButtonDisabled}
              onClick={() => doStep(1)}
              data-cy="StepNavFooterNext"
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
