import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  option: {
    fontSize: 14
  }
});

export function TableFilterAutocomplete({
  label,
  onChange,
  options,
  value,
  id,
  ...props
}) {
  const classes = useStyles();
  return (
    <Autocomplete
      classes={classes}
      size="small"
      id={id}
      options={options ? options.filter(o => o) : []}
      value={value || null}
      onChange={onChange}
      renderInput={params => (
        <TextField {...params} label={label} variant="outlined" />
      )}
      {...props}
    />
  );
}

TableFilterAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string
};
