import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  makeGetAvailableCliBoltOnsByType,
  makeGetAvailableCliTopUpBoltOns,
  makeGetSelectedCliBoltOn,
  makeGetAvailableCliBoltOnsBySlot
} from "../../../../../../store/mobile/selectors/productConfig";
import { chooseCliBoltOn } from "../../../../../../store/mobile/actionCreators";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

class CliBoltOnSelectField extends React.PureComponent {
  static defaultProps = { mode: "type" };
  static propTypes = {
    title: PropTypes.string.isRequired,
    configId: PropTypes.number.isRequired,
    boltOnType: PropTypes.string.isRequired, // DC category
    mode: PropTypes.oneOf(["slot", "type"])
  };
  state = {};

  render() {
    const {
      chooseCliBoltOn,
      configId,
      boltOnType,
      availableCliBoltOns,
      availableCliTopUpBoltOns,
      selectedCliBoltOn,
      title,
      mode
    } = this.props;
    const hasSelectedCliTopUpBoltOn =
      mode !== "slot" &&
      !!availableCliTopUpBoltOns.find(
        boltOn => boltOn.id === selectedCliBoltOn
      );

    if (mode !== "slot" && availableCliBoltOns.length < 1) return null;

    return (
      <div>
        <FormControl fullWidth margin="normal">
          <InputLabel>{title}</InputLabel>
          <Select
            onChange={event => {
              chooseCliBoltOn(configId, boltOnType, event.target.value);
            }}
            value={hasSelectedCliTopUpBoltOn ? 0 : selectedCliBoltOn}
            disabled={boltOnType === "data" && hasSelectedCliTopUpBoltOn}
          >
            {[{ id: 0, name: "Not required" }]
              .concat(availableCliBoltOns)
              .map(boltOn => {
                const price = _.get(
                  boltOn,
                  ["price", "first_bill_recurring_price_with_promotions"],
                  false
                );
                return (
                  <MenuItem value={boltOn.id} key={boltOn.id}>
                    {boltOn.name + (price ? " - £" + price : "")}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getSelectedCliBoltOn = makeGetSelectedCliBoltOn();
  const getAvailableCliBoltOns = makeGetAvailableCliBoltOnsByType();
  const getAvailableCliTopUpBoltOns = makeGetAvailableCliTopUpBoltOns();
  const getAvailableCliSlotBoltOns = makeGetAvailableCliBoltOnsBySlot();
  return (state, ownProps) => ({
    selectedCliBoltOn: getSelectedCliBoltOn(state, ownProps),
    availableCliBoltOns:
      ownProps.mode === "slot"
        ? getAvailableCliSlotBoltOns(state, ownProps)
        : getAvailableCliBoltOns(state, ownProps),
    availableCliTopUpBoltOns: getAvailableCliTopUpBoltOns(state, ownProps)
  });
};

export default connect(makeMapStateToProps, {
  chooseCliBoltOn
})(CliBoltOnSelectField);
