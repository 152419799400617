export const REQUEST_PRODUCT_SEARCH =
  "universalProducts.REQUEST_PRODUCT_SEARCH";
export const RECEIVE_PRODUCT_SEARCH =
  "universalProducts.RECEIVE_PRODUCT_SEARCH";

export const ADD_PRODUCT = "universalProducts.ADD_PRODUCT";
export const REMOVE_PRODUCT = "universalProducts.REMOVE_PRODUCT";

export const SELECT_CONFIG = "universalProducts.SELECT_CONFIG";

export const REQUEST_LIST_DOCUMENTS =
  "universalProducts.REQUEST_LIST_DOCUMENTS";
export const RECEIVE_LIST_DOCUMENTS =
  "universalProducts.RECEIVE_LIST_DOCUMENTS";

export const ADD_USER_DOCUMENT = "universalProducts.ADD_USER_DOCUMENT";
export const REMOVE_USER_DOCUMENT = "universalProducts.REMOVE_USER_DOCUMENT";

export const SET_USER_DOCUMENT_SEND_PREF =
  "universalProducts.SET_USER_DOCUMENT_SEND_PREF";

export const SET_CONFIG_PROPERTY = "universalProducts.SET_CONFIG_PROPERTY";

export const REQUEST_ORDER_PRODUCT = "universalProducts.REQUEST_ORDER_PRODUCT";
export const RECEIVE_ORDER_PRODUCT = "universalProducts.RECEIVE_ORDER_PRODUCT";

export const REQUEST_UPLOAD_DOCUMENT =
  "universalProducts.REQUEST_UPLOAD_DOCUMENT";
export const RECEIVE_UPLOAD_DOCUMENT =
  "universalProducts.RECEIVE_UPLOAD_DOCUMENT";
