import React from "react";
import { Button } from "@material-ui/core";
import { QuantitySyncButtonProps } from "./types";

export const QuantitySyncButton = ({ handleSync }: QuantitySyncButtonProps) => {
  return (
    <Button color={"primary"} variant={"outlined"} onClick={handleSync}>
      Update
    </Button>
  );
};
