import React from "react";
import PropTypes from "prop-types";
import ChangeIcon from "./ChangeIcon";
import FieldContainer from "./FieldContainer";
import TextField from "@material-ui/core/TextField";

const Number = ({
  dynamicProperty,
  setProperty,
  propertyValue,
  showWlrChangeIcon,
  propertyValidation,
  recalculatePrices,
  validateConfig,
  disabled
}) => {
  return (
    <FieldContainer>
      <TextField
        fullWidth
        label={`${dynamicProperty.label}${
          dynamicProperty.is_required ? " *" : ""
        }`}
        onChange={e => setProperty(e.target.value)}
        onBlur={() => {
          recalculatePrices();
          validateConfig();
        }}
        value={propertyValue || ""}
        type="number"
        disabled={disabled}
        helperText={propertyValidation}
        error={propertyValidation}
      />
      {showWlrChangeIcon && <ChangeIcon />}
    </FieldContainer>
  );
};

Number.propTypes = {
  dynamicProperty: PropTypes.object.isRequired,
  propertyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Number;
