import React from "react";
import { connect } from "react-redux";
import { ExpandingCard } from "@akj-dev/design-system";
import FolderIcon from "@material-ui/icons/Folder";
import ConfigTableRow from "./ConfigTableRow";
import TableOuter from "./TableOuter";

function ConfigTable({ configCount }) {
  return (
    <ExpandingCard
      title="Other Products"
      subtitle={`${configCount} Selected`}
      avatar={<FolderIcon />}
      defaultExpanded
    >
      <TableOuter>
        {Array(configCount)
          .fill("")
          .map((_, i) => (
            <ConfigTableRow key={i} configIndex={i} />
          ))}
      </TableOuter>
    </ExpandingCard>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    configCount: state.universalProducts.configs.length
  };
};

export default connect(mapStateToProps, {})(ConfigTable);
