import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { VirtualTable } from "../../../../../components/VirtualTable";
import { VirtualTableSortOrder } from "../../../../../components/VirtualTable/enums";
import { ActiveSort } from "../../../../../components/VirtualTable/types";
import {
  handleActiveSort,
  sortVirtualTableData,
  useDebouncedState
} from "../../../../../components/VirtualTable/utils";
import { MobileProduct, MobileProductConfig } from "../../../../types";
import { CarrierFilterEnum, DataFilterEnum } from "./enums";
import {
  carrierFilterOptions,
  tableHeaders,
  dataFilterOptions
} from "./constants";
import { MAX_QTY, RenderProductTableRow } from "./ProductTableRow";
import { ItemsSelected } from "./ItemsSelected";

interface ProductTableProps {
  configsCount: number;
  products: MobileProduct[];
  configs: MobileProductConfig[];
  isReseller: boolean;
  withCarrierFilter?: boolean;
  withDataFilter?: boolean;
}

export const ProductTable = ({
  configsCount,
  products,
  configs,
  isReseller,
  withCarrierFilter = true,
  withDataFilter = true
}: ProductTableProps) => {
  const classes = useStyles();
  const {
    state: productFilter,
    debouncedState: debouncedProductFilter,
    handleState: handleProductFilter
  } = useDebouncedState();
  const [carrierFilter, setCarrierFilter] = useState<CarrierFilterEnum>(
    CarrierFilterEnum.ALL
  );
  const [dataFilter, setDataFilter] = useState<DataFilterEnum>(
    DataFilterEnum.ALL
  );

  const [activeSort, setActiveSort] = useState<ActiveSort>({
    sortIndex: "name",
    order: VirtualTableSortOrder.ASC
  });

  const handleSort = useCallback(handleActiveSort(setActiveSort), [
    setActiveSort
  ]);

  const filteredProducts = useMemo(() => {
    return products
      .map(product => ({
        ...product,
        configsCount: configs.filter(
          c => c.productId === product.id && !c.resignId
        ).length
      }))
      .filter(
        product =>
          product.name
            .toLowerCase()
            .indexOf(debouncedProductFilter.toLowerCase()) !== -1 ||
          product.configsCount > 0
      )
      .filter(
        product =>
          carrierFilter === CarrierFilterEnum.ALL ||
          carrierFilter.toLowerCase() === product.network?.toLowerCase()
      )
      .filter(
        (product: any) =>
          dataFilter === DataFilterEnum.ALL ||
          (dataFilter === DataFilterEnum.DATA && product.dataOnly) ||
          (dataFilter === DataFilterEnum.VOICE && !product.dataOnly)
      )
      .sort(
        sortVirtualTableData({
          sortIndex: "name",
          order: VirtualTableSortOrder.ASC
        })
      );
  }, [products, configs, debouncedProductFilter, carrierFilter, dataFilter]);

  const sortedProducts = useMemo(() => {
    return filteredProducts.sort(sortVirtualTableData(activeSort));
  }, [filteredProducts, activeSort]);

  const productsCount = products.length;

  return (
    <Box className={classes.root}>
      {withDataFilter && (
        <Box>
          {dataFilterOptions.map(({ id, label }) => (
            <Button
              className={
                dataFilter === id
                  ? classes.activeDataFilter
                  : classes.dataFilter
              }
              onClick={() => setDataFilter(id)}
              data-cy={`${id.toLowerCase()}`}
              key={id}
            >
              {label}
            </Button>
          ))}
        </Box>
      )}
      <Box className={classes.searchBox}>
        <TextField
          data-cy={"tableSearch"}
          className={classes.search}
          variant="outlined"
          name="filter"
          value={productFilter}
          onChange={handleProductFilter}
          placeholder={`Search ${productsCount} Available Products`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        {!!configsCount && (
          <ItemsSelected maxQuantity={MAX_QTY} configsCount={configsCount} />
        )}
      </Box>
      {withCarrierFilter && (
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="carrierFilter"
            row
            name="carrierFilter"
            value={carrierFilter}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCarrierFilter(event.target.value as CarrierFilterEnum);
            }}
          >
            {carrierFilterOptions.map(({ id, label }) => (
              <FormControlLabel
                value={id}
                key={id}
                control={<Radio />}
                label={label}
                data-cy={`${id.toLowerCase()}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      <VirtualTable
        withScrollButton={true}
        headers={tableHeaders(isReseller)}
        activeSort={activeSort}
        handleSort={handleSort}
        data={{
          products: sortedProducts,
          allConfigsCount: configsCount
        }}
        tableSize={500}
        itemSize={80}
        itemCount={sortedProducts.length}
        RenderTableRow={RenderProductTableRow}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  dataFilter: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(1)
  },
  activeDataFilter: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    borderRadius: 0,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  search: {
    minWidth: "400px",
    marginBottom: theme.spacing(1)
  }
}));
