import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BROADBAND_ONLY,
  TRANSFER
} from "../../../store/wlrBroadband/constants";
import {
  doBroadbandSearch,
  getInstallationDetails,
  getLineAvailability
} from "../../../store/wlrBroadband/actions";
import { isValidPhoneNumber } from "../../../helpers/validation";
import { Button } from "@material-ui/core";

interface FindProductsProps {
  locationIndex: number;
}

/**
 * Find broadband products and line availability / installation details for a
 * a location with a defined address.
 * @param locationIndex
 * @constructor
 */
export const FindProducts = ({ locationIndex }: FindProductsProps) => {
  const dispatch = useDispatch();
  const location = useSelector(
    (state: any) => state.wlrBroadband.locations[locationIndex]
  );

  // Transfer and BB also need to know the CLI. ....because that has the existing services.
  const disabled =
    ((location.type === TRANSFER || location.type === BROADBAND_ONLY) &&
      !isValidPhoneNumber(location.cli.value)) ||
    !location.address.addressReference;
  return (
    <Button
      variant={disabled ? "outlined" : "contained"}
      color="primary"
      onClick={() => {
        // Find line availability and BB products at the address
        dispatch(getLineAvailability(locationIndex));
        dispatch(doBroadbandSearch(locationIndex));
        // Transfers and broadband only flows need to find out about the existing services at the address.
        if (location.type === TRANSFER || location.type === BROADBAND_ONLY) {
          dispatch(getInstallationDetails(locationIndex));
        }
      }}
      disabled={disabled}
      style={{ height: 58 }}
    >
      Find Products
    </Button>
  );
};
