import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import SmallButton from "../../../../components/Shared/SmallButton";
import {
  setWlrResignProduct,
  addResignLocation,
  setBroadbandProduct
} from "../../../../store/wlrBroadband/actions";
import {
  getIsMultiLineProduct,
  isActivationCodeP
} from "../../../../store/wlrBroadband/selectors";
import { SAME_PRODUCT_WITH_CHANGES } from "../../../../store/wlrBroadband/constants";
import InlinePriceIndicator from "../../../Shared/Pricing/InlinePriceIndicator";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getResignProductForProductInstanceServiceType } from "../../../../store/wlrBroadband/selectors";
import { withTheme } from "@material-ui/core/styles";

class AddBroadband extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelect: false
    };
  }

  showSelect = () => {
    this.setState({ showSelect: true });
    this.props.addResignLocation(
      this.props.productInstance,
      SAME_PRODUCT_WITH_CHANGES
    );
  };

  render() {
    const {
      broadbandSearch,
      broadbandProductId,
      isMultiLineProduct,
      installationDetails,
      isActivationCodeP,
      location,
      fetchingBroadbandSearch,
      fetchingInstallationDetails,
      configurationIndex,
      setBroadbandProduct,
      theme
    } = this.props;

    if (fetchingInstallationDetails || fetchingBroadbandSearch) {
      return <SmallButton secondary label="Loading..." />;
    }

    if (location && _.isEmpty(location.address))
      return <SmallButton secondary label="No Broadband" disabled />;

    if (
      isMultiLineProduct ||
      _.get(installationDetails, "response.service_type") ===
        "PSTN_MULTI_LINE_AUX"
    )
      return <SmallButton secondary label="No Broadband" disabled />;

    if (isActivationCodeP)
      return <SmallButton secondary label="No Broadband" disabled />;

    return (
      <div>
        {this.state.showSelect || broadbandProductId ? (
          <Select
            value={broadbandProductId || false}
            onChange={event => {
              // Adding a new broadband product so a broadband product instance id
              // doesn't need to be passed.
              setBroadbandProduct(configurationIndex, event.target.value);
            }}
            fullWidth={true}
            data-cy="AddResignBroadbandSelect"
          >
            <MenuItem value={false}>None</MenuItem>
            {broadbandSearch.map(product => {
              if (product.first_broadband_component?.type === "MPF")
                return false;
              if (product.first_broadband_component?.type === "FTTP")
                return false;
              return (
                <MenuItem
                  style={{ width: "100%" }}
                  value={product.id}
                  key={product.id}
                >
                  {
                    <div>
                      <b>{product.name}</b>
                      {product.price
                        .first_bill_recurring_price_with_promotions !==
                        product.recurring_price_without_promotions && (
                        <InlinePriceIndicator />
                      )}
                      <div
                        style={{
                          fontSize: "0.9em",
                          color: theme.palette.text.disabled
                        }}
                      >
                        {product.description}{" "}
                        <span
                          style={{
                            color: theme.palette.success.main,
                            fontSize: "0.9em",
                            paddingLeft: 8
                          }}
                        >
                          £
                          {
                            product.price
                              .first_bill_recurring_price_with_promotions
                          }
                        </span>
                      </div>
                    </div>
                  }
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          <SmallButton
            secondary
            label="Add Broadband"
            onClick={this.showSelect}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const location = state.wlrBroadband.locations[ownProps.locationIndex];
  const configurationIndex = state.wlrBroadband.configurations.indexOf(
    ownProps.configuration
  );
  return {
    fetchingBroadbandSearch: _.get(location, "broadbandSearch.fetching", false),
    fetchingInstallationDetails: _.get(
      location,
      "installationDetails.fetching",
      false
    ),
    broadbandSearch: _.get(location, "broadbandSearch.response.products", []),
    isActivationCodeP: isActivationCodeP(state, ownProps),
    broadbandProductId: ownProps.configuration.broadbandProductId,
    installationDetails: _.get(location, "installationDetails", {}),
    isMultiLineProduct: getIsMultiLineProduct(
      state,
      ownProps.configuration.wlrProductId
    ),
    resignProduct: getResignProductForProductInstanceServiceType(
      state,
      ownProps.productInstance
    ),
    location,
    configurationIndex
  };
};

export default connect(mapStateToProps, {
  setWlrResignProduct,
  addResignLocation,
  setBroadbandProduct
})(withTheme(AddBroadband));
