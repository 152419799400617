import React from "react";
import { Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useSelector } from "react-redux";
import { NEW_LINE } from "../../../store/wlrBroadband/constants";
import FormControl from "@material-ui/core/FormControl";
import { ReduxWlrLocation } from "./types";
import { getAccountSettings } from "../../../store/account/selectors";

export interface ContractLengthProps {
  contractLength: number;
  locations: ReduxWlrLocation[];
  setContractLength: (v: number) => void;
  setLocationType: (i: number, t: string) => void;
  doLineSearch: () => void;
}

export const ContractLength = ({
  contractLength,
  setContractLength,
  locations,
  setLocationType,
  doLineSearch
}: ContractLengthProps) => {
  const settings = useSelector(getAccountSettings);

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const length = event.target.value as number;
    setContractLength(length);

    // Contract length of 3 months is only available for New Line.
    if (length === 3) {
      locations.forEach((location, index) => setLocationType(index, NEW_LINE));
    }

    // Contract length can influence price
    doLineSearch();
  };

  return (
    <Grid item sm={12} md={6} lg={6}>
      <FormControl variant={"outlined"} fullWidth>
        <InputLabel
          htmlFor="contractLengthSelectField"
          style={{
            minWidth: 150
          }}
        >
          Contract Length
        </InputLabel>
        <Select
          labelId="contractLengthSelectField"
          data-cy="contractLengthSelectField"
          label="Contract Length"
          value={contractLength || ""}
          onChange={handleChange}
          fullWidth
          disabled={settings.dws_reseller_enabled === "1"}
          style={{
            minWidth: 150
          }}
        >
          <MenuItem value={1}>1 months</MenuItem>
          <MenuItem value={3}>3 months</MenuItem>
          <MenuItem value={12}>12 months</MenuItem>
          <MenuItem value={24}>24 months</MenuItem>
          <MenuItem value={36}>36 months</MenuItem>
          <MenuItem value={48}>48 months</MenuItem>
          <MenuItem value={60}>60 months</MenuItem>
          <MenuItem value={72}>72 months</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
};
