import * as actionTypes from "./actionTypes";
import * as ProductAPI from "../../../api/v1/product";
import { getAccountSettings } from "../../account/selectors";

export const search = () => async (dispatch, getState) => {
  // TP15998
  const settings = getAccountSettings(getState());
  const asBlankSimEnabled = settings.as_blank_sim_enabled;
  dispatch({ type: actionTypes.REQUEST_HARDWARE_SEARCH });
  const response = await ProductAPI.hardwareSearch(
    getState().order.accountId,
    false,
    asBlankSimEnabled
  );
  dispatch({ type: actionTypes.RECEIVE_HARDWARE_SEARCH, response });
};
