/**
 * Default values for v1/PlatformSettings/ACCOUNT_ID
 * ...when there's either been a general error or no account is available to
 * retrieve settings for. eg. AS has been launched from a lead
 * TODO: Retire this. It's way out of date and not good to fallback now there's so much feature toggling
 */
export default {
  status: "success",
  settings: {
    // UI section visibility
    as_mobile_order_enabled: "1",
    as_mobile_resign_enabled: "0", // If there's no account, we couldn't resign anything
    as_wlrbb_resign_enabled: "0",
    as_wlrbb_order_enabled: "1",
    as_hardware_order_enabled: "1",
    as_ethernet_order_enabled: "1",
    as_monitoring_order_enabled: "0",
    as_universal_enabled: "1",
    as_generate_contract: "1",

    // New account creation meta
    contract_type_id: "7",
    sales_person_id: "6724",
    dbam_name: "BP.RingFenced",
    external_account_manager_name: "BP.RingFenced",
    assigned_user_name: "IndirectCustomerServices",

    // Category IDs for product groups being pulled from DC.
    // These vary between UAT DC and production
    category_id_logic_monitor: "550",
    category_id_universal: "549"
  }
};
