import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Pricing from "../../../../../containers/Shared/Pricing/index";
import {
  getProductData,
  setProductDiscount,
  setProductProperty,
  setPricingScheme
} from "../../../../../store/wlrBroadband/actions";

class WlrBBPricingWrapper extends Component {
  static propTypes = {
    targetConfig: PropTypes.number.isRequired
  };

  state = {};

  render() {
    const {
      // Store
      wlrProductData,
      broadbandProductData,
      wlrProperties,
      broadbandProperties,

      // Actions
      setWlrPricingScheme,
      setBBPricingScheme,
      setWlrDiscount,
      setBBDiscount,
      setWlrServiceTariff,
      setBBServiceTariff
    } = this.props;
    return (
      <>
        <Pricing
          title="WLR Pricing"
          productData={wlrProductData}
          configProperties={wlrProperties}
          setPricingScheme={setWlrPricingScheme}
          setDiscount={setWlrDiscount}
          setServiceTariff={setWlrServiceTariff}
        />
        <Pricing
          title="Broadband Pricing"
          productData={broadbandProductData}
          configProperties={broadbandProperties}
          setPricingScheme={setBBPricingScheme}
          setDiscount={setBBDiscount}
          setServiceTariff={setBBServiceTariff}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const config = state.wlrBroadband.configurations[ownProps.targetConfig];
  return {
    wlrProductData: config.wlrProductData,
    broadbandProductData: config.broadbandProductData,
    wlrProperties: config.wlrProperties,
    broadbandProperties: config.broadbandProperties
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const updateProductData = productType =>
    dispatch(getProductData(ownProps.targetConfig, productType));
  return {
    setWlrPricingScheme: scheme => {
      dispatch(setPricingScheme(ownProps.targetConfig, "wlr", scheme));
      updateProductData("wlr");
    },
    setBBPricingScheme: scheme => {
      dispatch(setPricingScheme(ownProps.targetConfig, "broadband", scheme));
      updateProductData("broadband");
    },
    setWlrDiscount: (priceType, discountType, discountValue) => {
      dispatch(
        setProductDiscount(
          [ownProps.targetConfig],
          "wlr",
          priceType,
          discountType,
          discountValue
        )
      );
      updateProductData("wlr");
    },
    setBBDiscount: (priceType, discountType, discountValue) => {
      dispatch(
        setProductDiscount(
          [ownProps.targetConfig],
          "broadband",
          priceType,
          discountType,
          discountValue
        )
      );
      updateProductData("broadband");
    },
    setWlrServiceTariff: tariff => {
      dispatch(
        setProductProperty(
          [ownProps.targetConfig],
          "wlr",
          "service_tariff_id",
          tariff
        )
      );
      updateProductData("wlr");
    },
    setBBServiceTariff: tariff => {
      dispatch(
        setProductProperty(
          [ownProps.targetConfig],
          "broadband",
          "service_tariff_id",
          tariff
        )
      );
      updateProductData("broadband");
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WlrBBPricingWrapper);
