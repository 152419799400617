import u from "updeep";
import * as actions from "./actionTypes";

const initialState = {
  open: false,
  loading: false,
  screenshot: "",
  invalid: false,
  category: "Enquiry",
  system: "Affinity Sales",
  short_description: "", // TODO: This reducer is still incorrect. Fix properly when there's more time.
  description: "",
  type_id: 1,
  caseDetails: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN:
      return u(
        {
          open: true
        },
        state
      );
    case actions.CLOSE:
      return u(
        {
          open: false
        },
        state
      );
    case actions.SCREENSHOT:
      return u(
        {
          screenshot: action.screenshot
        },
        state
      );
    case actions.UPDATE:
      return u(
        {
          invalid: false,
          ...action
        },
        state
      );
    case actions.VALIDATION_FAILED:
      return u(
        {
          invalid: true
        },
        state
      );
    case actions.CREATING_SUPPORT_CASE:
      return u(
        {
          loading: true
        },
        state
      );
    case actions.SUPPORT_CASE_CREATED:
      return u(
        {
          open: false,
          loading: false,
          caseDetails: action.supportCase
        },
        state
      );
    case actions.CREATING_SUPPORT_CASE_FAILED:
      return u(
        {
          loading: false
        },
        state
      );
    default:
      return state;
  }
};
