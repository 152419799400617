import u from "updeep";
import * as actionTypes from "./actionTypes";
import { BILLING } from "./constants";

const initialState = {
  addressType: BILLING,
  accountAddressIndex: false,
  newAddress: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ADDRESS_TYPE:
      return u(
        {
          addressType: action.addressType
        },
        state
      );

    case actionTypes.SELECT_ACCOUNT_ADDRESS:
      return u(
        {
          accountAddressIndex: action.index
        },
        state
      );

    case actionTypes.SET_NEW_ADDRESS_ELEMENT:
      return u(
        {
          newAddress: {
            [action.element]: action.value
          }
        },
        state
      );

    default:
      return state;
  }
};
