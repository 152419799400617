import _ from "lodash";

/**
 * Check if any mobile configs have new SIM cards specified.
 * This will mean we need to collect delivery details for them.
 *
 * @param state
 */
export const getMobileSIMsRequired = state => {
  let required = false;
  state.mobile.configs.forEach(c => {
    if (_.get(c, "properties.is_sim_required") == 1) required = true; // eslint-disable-line eqeqeq
  });
  return required;
};
