import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";

const OrderAPIResponseApprovalMessages = ({ approval }) => {
  let messages = "";

  if (approval.in_progress) {
    messages = "The order is awaiting approval: ";

    _.forEach(approval.messages, value => {
      messages += value.messages ? value.messages.join(", ") : "";
    });
  } else {
    messages = approval.messages ? approval.messages.join(", ") : "";
  }

  return (
    <Outer>
      <h3>{approval.state}</h3>
      <p>{messages}</p>
    </Outer>
  );
};

const Outer = styled.div`
  padding: 0 10px 10px;
  margin: 20px 0;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
`;

const mapStateToProps = state => ({
  approval: _.get(state.order.orderStatus.response, "data.approval")
});

export default connect(mapStateToProps)(OrderAPIResponseApprovalMessages);
