import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import FileIcon from "./FileIcon";

function DocumentChip({ filename, href }) {
  return (
    <Paper>
      <Container href={href} target="_blank">
        <IconOuter>
          <FileIcon {...{ filename }} />
        </IconOuter>
        {filename}
      </Container>
    </Paper>
  );
}

DocumentChip.propTypes = {
  filename: PropTypes.string.isRequired,
  href: PropTypes.string
};

const Container = styled.a`
  display: flex;
  align-items: center;
`;
const IconOuter = styled.div`
  width: 70px;
  padding: 10px;
`;

export default DocumentChip;
