// Contract length.

// Set contract length for all products
export const SET_CONTRACT_LENGTH_IN_MONTHS_ALL_PRODUCTS =
  "mobile.SET_CONTRACT_LENGTH_IN_MONTHS_ALL_PRODUCTS";

export const SET_CONTRACT_LENGTH_IN_MONTHS_UI_FILTER =
  "mobile.SET_CONTRACT_LENGTH_IN_MONTHS_UI_FILTER";

// Co-terminus contracts have a different length to the selection we provide in the dropdown
// and have an extra flag sent with the OrderProduct/Create call.
export const TOGGLE_CO_TERMINUS = "mobile.TOGGLE_CO_TERMINUS";

// New Lines

// Query the API to start step 1:

// What products are available? (as in lines)
export const REQUEST_MOBILE_SEARCH = "mobile.REQUEST_MOBILE_SEARCH";
export const RECEIVE_MOBILE_SEARCH = "mobile.RECEIVE_MOBILE_SEARCH";

// What Account level bolt-ons are available?
export const REQUEST_BOLT_ON_SEARCH = "mobile.REQUEST_BOLT_ON_SEARCH";
export const RECEIVE_BOLT_ON_SEARCH = "mobile.RECEIVE_BOLT_ON_SEARCH";

// What existing account level bolt-ons does this account have?
export const REQUEST_ACCOUNT_BOLT_ONS = "mobile.REQUEST_ACCOUNT_BOLT_ONS";
export const RECEIVE_ACCOUNT_BOLT_ONS = "mobile.RECEIVE_ACCOUNT_BOLT_ONS";

// What product instances are on the account, that we could resign.
export const REQUEST_PRODUCT_INSTANCES = "mobile.REQUEST_PRODUCT_INSTANCES";
export const RECEIVE_PRODUCT_INSTANCES = "mobile.RECEIVE_PRODUCT_INSTANCES";

// Find the product we use to resign products without changes
export const REQUEST_RESIGN_PRODUCT = "mobile.REQUEST_RESIGN_PRODUCT";
export const RECEIVE_RESIGN_PRODUCT = "mobile.RECEIVE_RESIGN_PRODUCT";

// Get user selections:

// Select from available account level bolt-ons, or set "remove existing" flag.
export const SET_ACCOUNT_BOLT_ON = "mobile.SET_ACCOUNT_BOLT_ON";

export const SET_ACCOUNT_BOLT_ON_START_DATE =
  "mobile.SET_ACCOUNT_BOLT_ON_START_DATE";

export const SET_RESIGN_START_DATE = "mobile.SET_RESIGN_START_DATE";

// Set quantity of mobile product to spawn configs for.
export const SET_PRODUCT_QUANTITY = "mobile.SET_PRODUCT_QUANTITY";

// Delete an individual config (in step 2)
export const REMOVE_CONFIG = "mobile.REMOVE_CONFIG";

// Resigns

// Set type. No changes or with changes
export const SET_RESIGN_TYPE = "mobile.SET_RESIGN_TYPE";

// Set new product ID, if it's a resign with changes
export const SET_RESIGN_PRODUCT = "mobile.SET_RESIGN_PRODUCT";

// Remove the resign config, if the user decides not to resign
export const REMOVE_RESIGN = "mobile.REMOVE_RESIGN";

// Get product data from the API for step 2 config:
export const REQUEST_MOBILE_PRODUCT_DATA = "mobile.REQUEST_MOBILE_PRODUCT_DATA";
export const RECEIVE_MOBILE_PRODUCT_DATA = "mobile.RECEIVE_MOBILE_PRODUCT_DATA";

// Get updated pricing data when pricing has been altered on a config.
export const REQUEST_MOBILE_PRICING_DATA = "mobile.REQUEST_MOBILE_PRICING_DATA";
export const RECEIVE_MOBILE_PRICING_DATA = "mobile.RECEIVE_MOBILE_PRICING_DATA";

// Save user inputs on fields from that data
export const UPDATE_CONFIG_PROPERTY = "mobile.UPDATE_CONFIG_PROPERTY";
export const ARRAY_UPDATE_CONFIG_PROPERTY =
  "mobile.ARRAY_UPDATE_CONFIG_PROPERTY";
export const SET_MOBILE_PRODUCT_DISCOUNT = "mobile.SET_MOBILE_PRODUCT_DISCOUNT";

// Do client side validation of a config property
export const VALIDATE_CONFIG_PROPERTY = "mobile.VALIDATE_CONFIG_PROPERTY";
export const VALIDATE_ALL_CONFIG_PROPERTIES =
  "mobile.VALIDATE_ALL_CONFIG_PROPERTIES";

// Get any CLI level bolt-ons selected products may have
export const REQUEST_CLI_BOLT_ON_PRODUCTS =
  "mobile.REQUEST_CLI_BOLT_ON_PRODUCTS";
export const RECEIVE_CLI_BOLT_ON_PRODUCTS =
  "mobile.RECEIVE_CLI_BOLT_ON_PRODUCTS";

// Get any CLI level top-up bolt-ons selected products may have
export const REQUEST_CLI_TOP_UP_BOLT_ON_PRODUCTS =
  "mobile.REQUEST_CLI_TOP_UP_BOLT_ON_PRODUCTS";
export const RECEIVE_CLI_TOP_UP_BOLT_ON_PRODUCTS =
  "mobile.RECEIVE_CLI_TOP_UP_BOLT_ON_PRODUCTS";

// Get existing dynamic property values for resigns.
export const REQUEST_RESIGN_PROPERTY_VALUES =
  "mobile.REQUEST_RESIGN_PROPERTY_VALUES";
export const RECEIVE_RESIGN_PROPERTY_VALUES =
  "mobile.RECEIVE_RESIGN_PROPERTY_VALUES";

// And store selection
export const CHOOSE_CLI_BOLT_ON = "mobile.CHOOSE_CLI_BOLT_ON";

// Check PAC codes if it's a migration
export const REQUEST_PAC_VERIFICATION = "mobile.REQUEST_PAC_VERIFICATION";
export const RECEIVE_PAC_VERIFICATION = "mobile.RECEIVE_PAC_VERIFICATION";

// Check STAC code if it's a new number and the existing user has one.
export const REQUEST_STAC_VERIFICATION = "mobile.REQUEST_STAC_VERIFICATION";
export const RECEIVE_STAC_VERIFICATION = "mobile.RECEIVE_STAC_VERIFICATION";

// Check SIM number is valid
export const REQUEST_SIM_VERIFICATION = "mobile.REQUEST_SIM_VERIFICATION";
export const RECEIVE_SIM_VERIFICATION = "mobile.RECEIVE_SIM_VERIFICATION";

// Check bars compatibility
// Note the valid_ids response is showing the EXTRA bars that could be selected following on from the current selection
// Not the entire list. Kinda strange, but hey-ho.
export const REQUEST_BARS_COMPATIBILITY = "mobile.REQUEST_BARS_COMPATIBILITY";
export const RECEIVE_BARS_COMPATIBILITY = "mobile.RECEIVE_BARS_COMPATIBILITY";

// Get the ID of the Daisy Fresh product
export const REQUEST_DAISY_FRESH_SEARCH = "mobile.REQUEST_DAISY_FRESH_SEARCH";
export const RECEIVE_DAISY_FRESH_SEARCH = "mobile.RECEIVE_DAISY_FRESH_SEARCH";

// User sets required Daisy fresh values
export const SET_DAISY_FRESH_HARDWARE_CREDITS =
  "mobile.SET_DAISY_FRESH_HARDWARE_CREDITS";
export const SET_DAISY_FRESH_TERMINATION_FEES =
  "mobile.SET_DAISY_FRESH_TERMINATION_FEES";
export const SET_DAISY_FRESH_ETF = "mobile.SET_DAISY_FRESH_ETF";
// ...all three values get added up to figure out the final amount.

// Get the ID odf the product we use to add any hardware credit specified in Daisy Fresh
export const REQUEST_HARDWARE_CREDIT_PRODUCT =
  "mobile.REQUEST_HARDWARE_CREDIT_PRODUCT";
export const RECEIVE_HARDWARE_CREDIT_PRODUCT =
  "mobile.RECEIVE_HARDWARE_CREDIT_PRODUCT";

// Users must confirm they've offered a bill cap before proceeding past step 2
export const TOGGLE_BILL_CAP_CONFIRMATION =
  "mobile.TOGGLE_BILL_CAP_CONFIRMATION";

// DWS Resellers must accept additional terms
export const TOGGLE_DWS_TERMS_CONFIRMATION =
  "mobile.TOGGLE_DWS_TERMS_CONFIRMATION";

export const REQUEST_ORDER_PRODUCT = "mobile.REQUEST_ORDER_PRODUCT";
export const RECEIVE_ORDER_PRODUCT = "mobile.RECEIVE_ORDER_PRODUCT";

export const REQUEST_ORDER_BOLT_ON = "mobile.REQUEST_ORDER_BOLT_ON";
export const RECEIVE_ORDER_BOLT_ON = "mobile.RECEIVE_ORDER_BOLT_ON";

export const REQUEST_HARDWARE_CREDIT = "mobile.REQUEST_HARDWARE_CREDIT";
export const RECEIVE_HARDWARE_CREDIT = "mobile.RECEIVE_HARDWARE_CREDIT";

export const TOGGLE_RESERVED_NUMBERS = "mobile.TOGGLE_RESERVED_NUMBERS";
export const REQUEST_RESERVED_NUMBERS_LIST =
  "mobile.REQUEST_RESERVED_NUMBERS_LIST";
export const RECEIVE_RESERVED_NUMBERS_LIST =
  "mobile.RECEIVE_RESERVED_NUMBERS_LIST";
