import React from "react";
import Chip from "../../../../components/Shared/mobile/Chip";
import ExtensionIcon from "@material-ui/icons/Extension";
import { useTheme } from "@material-ui/core/styles";

const BoltOnChip = ({ title, price }) => {
  const theme = useTheme();
  return (
    <Chip
      Icon={ExtensionIcon}
      title={
        <span>
          {title}
          {!!price && <b> - £{price.toFixed(2)}</b>}
        </span>
      }
      color={theme.palette.secondary.main}
    />
  );
};

BoltOnChip.propTypes = {};

export default BoltOnChip;
