import _ from "lodash";

export function getNextId(collection) {
  let keys = Object.keys(collection);
  keys = _.sortBy(keys, function(num) {
    return Math.ceil(num);
  });
  let lastId = keys[keys.length - 1];
  let nextId = lastId ? parseFloat(lastId) + 1 : 1;
  return nextId;
}

export function getNextIdFromArrayOfObjectsWithIdAsProperty(array) {
  const object = convertArrayToObjectUsingParameterAsProperty(array, "id");
  const nextId = getNextId(object);
  return nextId;
}

export function formatPostcodeWithRemovedSpace(postcode) {
  return postcode.replace(/\s+/g, "").toUpperCase();
}

export function hasValue(object, fieldName = null) {
  if (fieldName == null) {
    if (typeof object !== "undefined" && object !== null) {
      return true;
    }
  } else {
    if (
      fieldExists(object, fieldName) &&
      object[fieldName] !== null &&
      object[fieldName] !== ""
    ) {
      return true;
    }
  }

  return false;
}

export function fieldExists(object, fieldName) {
  return object.hasOwnProperty(fieldName);
}

export function convertArrayToObjectUsingParameterAsProperty(array, property) {
  let newObject = {};
  array.forEach(function(arrayItem) {
    let propertyKey = arrayItem[property];
    newObject[propertyKey] = arrayItem;
  });
  return newObject;
}

export function isWlrBroadband(configuration) {
  return hasValue(configuration, "line-adsl_reference");
}
