import { createSelector } from "reselect";
import _ from "lodash";
import { getDeliveryParams } from "../hardwareDelivery/selectors";
import { totalConfigHardwareCredit } from "./configurations/selectors";

/**
 * Build ordering parameters for hardware products
 * @param {object} state - full redux store state
 * @returns {Array}
 */
export function hardwareProducts(state) {
  let products = [];
  Object.keys(state.hardwareConfigurations).forEach(productId => {
    const config = state.hardwareConfigurations[productId];
    // Check product exists, as configurations without products can now exist (if a user checks the stock of a product
    // but doesn't select it: https://gitlab.com/akj-dev/inbox/-/issues/101.
    if (config.product && config.rows.length >= 1) {
      // We often end up with lots of identical rows, where quantity > 1 but leasing has been selected.
      // These need to be sent to DC as one order line (FB159275) so:
      const allRowsEqual = config.rows.every(row =>
        _.isEqual(config.rows[0], row)
      );

      if (allRowsEqual) {
        products.push(getParams(state, config, 0, true));
      } else {
        config.rows.forEach((row, i) =>
          products.push(getParams(state, config, i, false))
        );
      }
    }
  });

  return products;
}

/**
 * Build ordering parameters for a single hardware config product row
 * @param state
 * @param config
 * @param rowIndex
 * @param singleOrderLine
 * @returns {object}
 */
function getParams(state, config, rowIndex, singleOrderLine) {
  const row = config.rows[rowIndex];
  const quantity = singleOrderLine ? config.quantity : 1;
  const credit_used = singleOrderLine
    ? totalConfigHardwareCredit(config)
    : parseFloat(row.credit_used) || 0;
  const { pricingSchemeName, oneOffPrice } = getPricingScheme(
    row.is_leasing,
    row.isLeasedRV,
    config.product.id,
    state
  );
  return {
    id: config.product.id,
    identifier: `${config.product.id}_${rowIndex}`,
    params: {
      "hardware-product_id": config.product.id,
      "hardware-quantity": quantity,
      "hardware-pricing_scheme": pricingSchemeName,
      ...(row.is_leasing && { contract_length_in_months: 24 }),
      ...getDeliveryParams(state),
      "hardware-credit_used": credit_used,
      // Note: As advised by @davet and @ianc, the original price still needs specifying for HW products
      // The credit_used param is like a discount voucher that DC applies internally. The price itself doesn't change
      ...(row.credit_used > 0 && {
        "hardware-price_amendment_reason": "Daisy Fresh from Guided Sales",
        "hardware-one_off_discount_override": "1",
        "hardware-one_off_discount_value": oneOffPrice,
        "hardware-one_off_discount_type": "SpecifyPrice"
      })
    }
  };
}

/**
 * Get selected pricing scheme for a config (or config row)
 * @param lease {boolean}
 * @param rv {boolean}
 * @param productId {string}
 * @param state {Object}
 * @returns {{oneOffPrice: *, pricingSchemeName: *}}
 */
function getPricingScheme(lease, rv, productId, state) {
  const hasConnections = state.mobile.configs.length > 0;

  // Get the pricing data we got from HardwareSearch in step 1.
  const product = state.hardwareProducts.response.products.find(
    p => p.id === productId
  );

  let pricingScheme;
  if (lease) {
    if (rv) {
      pricingScheme = "leaseRV";
    } else {
      pricingScheme = "leaseNonRV";
    }
  } else {
    if (hasConnections) {
      pricingScheme = "oneOffWithConnection";
    } else {
      pricingScheme = "oneOff";
    }
  }
  const pricingSchemeName = product.pricingSchemes[pricingScheme] || "DEFAULT";
  const oneOffPrice = product.price[pricingScheme];

  return { pricingSchemeName, oneOffPrice };
}

/**
 * Count total number of hardware items chosen by the user.
 * @param state
 * @returns {number}
 */
export const getHardwareCount = createSelector(
  [state => state.hardwareConfigurations],
  hardwareConfigurations => {
    let count = 0;
    for (const id in hardwareConfigurations) {
      count += hardwareConfigurations[id].quantity || 0;
    }
    return count;
  }
);

/**
 * Returns true if any hardware item is leased.
 * Required for business logic introduced on FB153325
 * @type {Reselect.Selector<any, boolean> | Reselect.Selector<any, any>}
 */
export const getHasLeasedHardware = createSelector(
  [state => state.hardwareConfigurations],
  hardwareConfigurations => {
    let is_leasing = false;
    for (const id in hardwareConfigurations) {
      const conf = hardwareConfigurations[id];
      if (conf.is_leasing) {
        is_leasing = true;
        break;
      }
      // Can be in two places unfortunately
      if (conf.rows) {
        for (const row of conf.rows) {
          if (row.is_leasing) {
            is_leasing = true;
            break;
          }
        }
      }
    }
    return is_leasing;
  }
);

export const isHardwareOnly = state =>
  getHardwareCount(state) > 0 &&
  state.mobile.configs.length === 0 &&
  state.ethernetProducts.configurations.length === 0 &&
  state.wlrBroadband.configurations.length === 0;

/**
 * Returns true if any hardware products are in the process of being ordered.
 * Used for disabling navigation in final step.
 * @param state
 * @returns {boolean}
 */
export const hardwareOrderProductCallsFetching = createSelector(
  [state => state.hardwareOrders],
  hardwareOrders =>
    Object.keys(hardwareOrders).reduce(
      (fetching, orderNo) => fetching || hardwareOrders[orderNo].fetching,
      false
    )
);
