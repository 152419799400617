import React, { Dispatch, SetStateAction, MouseEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonGroup } from "@material-ui/core";
import { DateRangeEnum, dateRanges } from "./enums";

export interface DateRangeSelectorProps {
  customOptionRef: React.RefObject<HTMLButtonElement>;
  dateRangeSelected: DateRangeEnum;
  setDateRangeSelected: Dispatch<SetStateAction<DateRangeEnum>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const DateRangeSelector = ({
  customOptionRef,
  dateRangeSelected,
  setDateRangeSelected,
  setOpen
}: DateRangeSelectorProps) => {
  const classes = useStyles();

  return (
    <ButtonGroup className={classes.group} size="large">
      {dateRanges.map(({ id, label }) => {
        const isCustom = id === DateRangeEnum.CUSTOM;

        const handleChange = (event: MouseEvent<HTMLButtonElement>) => {
          if (isCustom) {
            event.stopPropagation();
            setOpen(o => !o);
          }
          setDateRangeSelected(id);
        };

        return (
          <Button
            key={id}
            ref={isCustom ? customOptionRef : null}
            className={dateRangeSelected === id ? classes.button : ""}
            variant={dateRangeSelected === id ? "contained" : "outlined"}
            onClick={handleChange}
          >
            {label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

const useStyles = makeStyles(theme => ({
  group: {
    backgroundColor: theme.palette.common.white
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    },
    "&:active": {
      boxShadow: "none"
    }
  }
}));
