import React from "react";
import PropTypes from "prop-types";
import HomeIcon from "@material-ui/icons/Home";
import { ExpandingCard } from "@akj-dev/design-system";
import SiteAddress from "../../../../../components/Shared/Ethernet/SiteAddress";

const SiteInstallationAddress = props => {
  return (
    <ExpandingCard
      title="Site Installation Address"
      avatar={<HomeIcon />}
      defaultExpanded={true}
    >
      <SiteAddress {...props} />
    </ExpandingCard>
  );
};

SiteInstallationAddress.propTypes = {
  purchaseArgs: PropTypes.object.isRequired,
  prefix: PropTypes.string.isRequired // `site_a` or `site_b`
};

export default SiteInstallationAddress;
