import React, { useReducer, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { Table } from "./Table";
import {
  SalesStatusQuotes,
  SalesStatusDateRange,
  SalesStatusOptions,
  SalesStatusEndCustomerAccounts
} from "../../../api/v1/affinity";
import { getUnixTime } from "date-fns";
import { DeleteDraftModal } from "../DeleteDraftModal";

const initialState = {
  initialLoadComplete: false,
  fetchingTableData: false,
  rows: [],
  errors: {
    tableData: false,
    optionData: false,
    endCustomerAccounts: false
  },
  page: 1,
  limit: 15,
  count: 0,
  sort: ["createdAtTimestamp", "desc"],
  fetchingEndCustomerAccounts: false,
  endCustomerAccounts: [],
  fetchingOptionsData: false,
  options: {
    opportunityName: [],
    customerEmail: [],
    customerName: [],
    createdAtTimestamp: [],
    quoteId: [],
    createdByName: [],
    opportunityCount: []
  },
  filters: {
    customerEmail: [],
    opportunityName: [],
    customerName: [],
    createdAtTimestamp: [],
    quoteId: [],
    createdByName: [],
    endCustomerAccountNo: [],
    opportunityCount: []
  },
  deleteModalOpenForQuoteId: undefined
};

function reducer(state, action) {
  switch (action.type) {
    case "setFetchingEndCustomerAccounts":
      return { ...state, fetchingEndCustomerAccounts: action.payload };
    case "setEndCustomerAccounts":
      return { ...state, endCustomerAccounts: action.payload };
    case "setFetchingTableData":
      return { ...state, fetchingTableData: action.payload };
    case "setFetchingOptionsData":
      return { ...state, fetchingOptionsData: action.payload };
    case "setErrors":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.key]: action.payload.error
        }
      };
    case "setLimit":
      return { ...state, limit: action.payload };
    case "setRows":
      return { ...state, rows: action.payload, initialLoadComplete: true };
    case "setPage":
      return { ...state, page: action.payload };
    case "setCount":
      return { ...state, count: action.payload };
    case "setSort":
      return { ...state, sort: action.payload };
    case "setOptionsByKey":
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.key]: action.payload.options
        }
      };
    case "setFiltersByKey":
      return {
        ...state,
        page: 1,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.filters
        }
      };
    case "resetFilters":
      return {
        ...state,
        page: 1,
        filters: initialState.filters
      };
    case "setDeleteModalOpenForQuoteId":
      return {
        ...state,
        deleteModalOpenForQuoteId: action.payload
      };
    default:
      return state;
  }
}

export function SalesStatusDataTable({
  status,
  title,
  description,
  orderSource = "Affinity Sales"
}) {
  const isMountedRef = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchIfMounted = action =>
    isMountedRef.current ? dispatch(action) : () => {};

  const fetchEndCustomerAccounts = useCallback(async () => {
    dispatchIfMounted({
      type: "setFetchingEndCustomerAccounts",
      payload: true
    });
    dispatchIfMounted({
      type: "setErrors",
      payload: {
        key: "endCustomerAccounts",
        error: false
      }
    });

    try {
      const data = await SalesStatusEndCustomerAccounts(status);

      dispatchIfMounted({ type: "setEndCustomerAccounts", payload: data });
    } catch (err) {
      console.error("Error fetching end customer accounts: " + err);
      dispatchIfMounted({
        type: "setErrors",
        payload: {
          key: "endCustomerAccounts",
          error: true
        }
      });
    }
    dispatchIfMounted({
      type: "setFetchingEndCustomerAccounts",
      payload: false
    });
  }, [status]);

  useEffect(() => {
    isMountedRef.current = true;
    fetchEndCustomerAccounts();
    return () => (isMountedRef.current = false);
  }, [fetchEndCustomerAccounts]);

  const fetchFilterOptions = useCallback(() => {
    dispatchIfMounted({
      type: "setErrors",
      payload: {
        key: "optionData",
        error: false
      }
    });
    dispatchIfMounted({ type: "setFetchingOptionsData", payload: true });
    const optionsEndpoints = [
      "opportunityName",
      "customerEmail",
      "customerName",
      "quoteId",
      "createdByName",
      "opportunityCount",
      "endCustomerAccountNo"
    ];

    const commonFilters = {
      status,
      opportunityName: state.filters.opportunityName[0],
      customerEmail: state.filters.customerEmail[0],
      customerName: state.filters.customerName[0],
      quoteId: state.filters.quoteId[0],
      createdByName: state.filters.createdByName[0],
      endCustomerAccountNo: state.filters.endCustomerAccountNo[0],
      opportunityCount: state.filters.opportunityCount[0]
    };
    Promise.all([
      SalesStatusDateRange(commonFilters),
      ...optionsEndpoints.map(option =>
        SalesStatusOptions(
          {
            ...commonFilters,
            createdAtTimestamp_gte: state.filters.createdAtTimestamp[0],
            createdAtTimestamp_lte: state.filters.createdAtTimestamp[1]
          },
          option
        )
      )
    ])
      .then(([dateRangeOptions, ...rest]) => {
        dispatchIfMounted({
          type: "setOptionsByKey",
          payload: {
            key: "createdAtTimestamp",
            options: [
              dateRangeOptions.createdAtTimestampMin || getUnixTime(new Date()),
              dateRangeOptions.createdAtTimestampMax || getUnixTime(new Date())
            ]
          }
        });

        rest.forEach((result, i) => {
          dispatchIfMounted({
            type: "setOptionsByKey",
            payload: {
              key: optionsEndpoints[i],
              options: result.map(option => option.value)
            }
          });
        });
      })
      .catch(err => {
        console.error("Error fetching table filter options: " + err);
        dispatchIfMounted({
          type: "setErrors",
          payload: {
            key: "optionData",
            error: true
          }
        });
      })
      .finally(() =>
        dispatchIfMounted({ type: "setFetchingOptionsData", payload: false })
      );
  }, [status, state.filters]);

  useEffect(() => {
    isMountedRef.current = true;
    fetchFilterOptions();
    return () => (isMountedRef.current = false);
  }, [fetchFilterOptions]);

  const fetchTableData = useCallback(async () => {
    dispatchIfMounted({
      type: "setErrors",
      payload: {
        key: "tableData",
        error: false
      }
    });
    dispatchIfMounted({ type: "setFetchingTableData", payload: true });
    try {
      const data = await SalesStatusQuotes({
        page: state.page,
        limit: state.limit,
        sortBy: state.sort[0],
        order: state.sort[1],
        filters: {
          // don't currently support multiple filter terms except for date,
          // however here we could convert to multiple values eg. to comma seperated
          status,
          opportunityName: state.filters.opportunityName[0],
          customerEmail: state.filters.customerEmail[0],
          customerName: state.filters.customerName[0],
          createdAtTimestamp_gte: state.filters.createdAtTimestamp[0],
          createdAtTimestamp_lte: state.filters.createdAtTimestamp[1],
          quoteId: state.filters.quoteId[0],
          createdByName: state.filters.createdByName[0],
          endCustomerAccountNo: state.filters.endCustomerAccountNo[0],
          opportunityCount: state.filters.opportunityCount[0]
        },
        orderSource
      });

      dispatchIfMounted({ type: "setRows", payload: data.results });
      dispatchIfMounted({ type: "setCount", payload: data.meta.totalCount });
    } catch (err) {
      console.error("Error fetching table data: " + err);
      dispatchIfMounted({
        type: "setErrors",
        payload: {
          key: "tableData",
          error: true
        }
      });
    }
    dispatchIfMounted({ type: "setFetchingTableData", payload: false });
  }, [status, state.page, state.limit, state.sort, state.filters, orderSource]);

  useEffect(() => {
    isMountedRef.current = true;
    fetchTableData();
    return () => (isMountedRef.current = false);
  }, [fetchTableData]);

  return (
    <>
      <Table
        description={description}
        errors={state.errors}
        initialLoadComplete={state.initialLoadComplete}
        loadingTableData={state.fetchingTableData || state.fetchingOptionsData}
        loading={state.fetchingEndCustomerAccounts}
        dispatch={dispatch}
        rows={state.rows}
        title={title}
        page={state.page}
        count={state.count}
        rowsPerPage={state.limit}
        sort={state.sort}
        endCustomerAccounts={state.endCustomerAccounts}
        options={state.options}
        filters={state.filters}
        status={status}
        getQuoteIdLink={createQuoteIdLink}
        onDeleteOpportunity={quoteId => {
          dispatchIfMounted({
            type: "setDeleteModalOpenForQuoteId",
            payload: quoteId
          });
        }}
        showDeleteOpportunity={status === "draft"}
        showOpportunityCount={status === "lead"}
        hidePriceColumns={status === "lead"}
        hideDownloadColumn={status === "lead"}
        hideEmailColumn={status !== "lead"}
        hideOpportunityNameColumn={status === "lead"}
      />
      <DeleteDraftModal
        open={state.deleteModalOpenForQuoteId}
        orderId={state.deleteModalOpenForQuoteId}
        onClose={reason => {
          dispatchIfMounted({
            type: "setDeleteModalOpenForQuoteId",
            payload: undefined
          });
          if (reason === "success") {
            fetchEndCustomerAccounts();
            fetchFilterOptions();
            fetchTableData();
          }
        }}
      />
    </>
  );
}

function createQuoteIdLink(quoteId, status) {
  // Note: quoteId can actually be leadId (aka prospect) when it's
  // returned from the API according to @robert.moore , when state=lead
  // This is the short for lead ID not the UUID style one.
  if (!quoteId) {
    return;
  }

  if (
    status === "draft" ||
    status === "approval" ||
    status === "internal_approval"
  ) {
    return {
      to: `/sales/new/cpq?order_id=${quoteId}`,
      tooltip: "launch sales wizard"
    };
  }

  if (status === "lead") {
    return {
      to: `/sales/new/cpq?lead_search=${quoteId}`,
      tooltip: "launch sales wizard"
    };
  }

  if (status === "completed") {
    return {
      to: `/orders/status?view=detail&id=${quoteId}`,
      tooltip: "view order detail"
    };
  }
}

SalesStatusDataTable.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  orderSource: PropTypes.string
};
