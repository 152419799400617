import ethernetProductsEvents from "./ethernetProducts/gaEventsMap";
import hardwareEvents from "./hardware/gaEventsMap";
import mobileEvents from "./mobile/gaEventsMap";
import monitoringServiceEvents from "./monitoringService/gaEventsMap";
import orderEvents from "./order/gaEventsMap";
import uiStateEvents from "./uiState/gaEventsMap";
import universalProductsEvents from "./universalProducts/gaEventsMap";
import wlrBroadbandEvents from "./wlrBroadband/gaEventsMap";

const eventsMap = {
  ...ethernetProductsEvents,
  ...hardwareEvents,
  ...mobileEvents,
  ...monitoringServiceEvents,
  ...orderEvents,
  ...uiStateEvents,
  ...universalProductsEvents,
  ...wlrBroadbandEvents
};

export default eventsMap;
