import React from "react";
import _ from "lodash";
import Section from "../../../../../components/Shared/Section";
import BulkDynamicField from "../BulkDynamicField";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import { connect } from "react-redux";
import { getProductDataForConfig } from "../../../../../store/mobile/selectors/productConfig";
import Grid from "@material-ui/core/Grid";

const APNs = ({ supplier }) => {
  return (
    <Section title="APNs" avatar={<TrackChangesIcon />} defaultExpanded={false}>
      <Grid container>
        {supplier === "O2" ? (
          <>
            <Grid item sm={6}>
              <BulkDynamicField propertyName="2041" />
            </Grid>
            <Grid item sm={6}>
              <BulkDynamicField propertyName="58" />
            </Grid>
          </>
        ) : (
          <>
            <Grid item sm={6}>
              <BulkDynamicField propertyName="wap" />
            </Grid>
            <Grid item sm={6}>
              <BulkDynamicField propertyName="tethering" />
            </Grid>
            <Grid item sm={12}>
              <BulkDynamicField propertyName="apn_4" />
            </Grid>
            <Grid item sm={12}>
              <BulkDynamicField propertyName="apn_5" />
            </Grid>
          </>
        )}
      </Grid>
    </Section>
  );
};

APNs.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  supplier: _.get(
    getProductDataForConfig(state, ownProps.targetConfigs[0]),
    "mobile.product_component_data.supplier"
  )
});

export default connect(mapStateToProps)(APNs);
