import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Step,
  StepButton,
  Stepper as MuiStepper,
  Typography
} from "@material-ui/core";
import { setActiveStep } from "../../../../store/uiState/actions";
import { ProductTypesEnum, SaleStepEnum } from "../../../enums";
import {
  saleStepLabelObject,
  saleSubStepChooseLabelObject
} from "../../../constants";

export interface ReduxStepperProps {
  activeStep?: SaleStepEnum;
  visited?: boolean[];
  steps?: SaleStepEnum[];
  activeSubStepChoose?: ProductTypesEnum;
}

export const Stepper = () => {
  const classes = useStyles();

  const { activeStep, steps, visited, activeSubStepChoose } = useSelector<
    { uiState: ReduxStepperProps },
    ReduxStepperProps
  >(state => ({
    activeStep: state.uiState.activeStep,
    visited: state.uiState.visited,
    steps: state.uiState.steps,
    activeSubStepChoose: state.uiState.activeSubStepChoose
  }));
  const dispatch = useDispatch();

  const handleActiveSteps = (stepId: SaleStepEnum) => {
    dispatch(setActiveStep(stepId));
  };

  return (
    <Box className={classes.root}>
      <MuiStepper className={classes.stepper} activeStep={1}>
        {steps?.map((stepId, i) => {
          const active = stepId === activeStep;
          const completed = !active && !!visited?.[i];

          const label = saleStepLabelObject[stepId];
          const optionalLabel =
            saleSubStepChooseLabelObject[
              activeSubStepChoose ?? ProductTypesEnum.SELECT
            ];
          // show optional subtitle only when the step is active and "Choose"
          const optional =
            stepId === activeStep &&
            stepId === SaleStepEnum.STEP_CHOOSE &&
            optionalLabel;
          const handleClick = () => handleActiveSteps(stepId);

          return (
            <Step key={stepId} completed={completed} active={active}>
              <StepButton disabled={false} onClick={handleClick}>
                <Typography className={classes.stepLabel} variant="body1">
                  {label}
                </Typography>
                {optional && (
                  <Typography variant="inherit">{optional}</Typography>
                )}
              </StepButton>
            </Step>
          );
        })}
      </MuiStepper>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    alignItems: "center",
    flexGrow: 1
  },
  stepper: {
    padding: 0
  },
  stepLabel: {
    display: "flex"
  }
});
