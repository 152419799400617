import React from "react";
import Icon from "@material-ui/icons/Book";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";

const MPFDirectoryEnquiry = props => {
  return (
    <Section
      title="Directory Enquiry (MPF)"
      avatar={<Icon />}
      defaultExpanded={false}
    >
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.businessDirectory.businessName"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.businessDirectory.businessDescription"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.businessDirectory.businessSuffix"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.businessDirectory.type"
      />

      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.businessDirectory.address.thoroughfareNumber"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.businessDirectory.address.thoroughfareName"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.businessDirectory.address.premisesName"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.businessDirectory.address.subPremises"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.businessDirectory.address.postTown"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.businessDirectory.address.postcode"
      />
    </Section>
  );
};

MPFDirectoryEnquiry.propTypes = {};

export default MPFDirectoryEnquiry;
