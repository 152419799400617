import * as actionTypes from "./actionTypes";
import { trackEvent } from "@redux-beacon/google-analytics";
import { trackSimpleEvent } from "../../helpers/reduxBeacon";

const gaEventsMap = {
  ...trackSimpleEvent(actionTypes.ADD_ETHERNET_CONFIG),
  ...trackSimpleEvent(actionTypes.REMOVE_ETHERNET_CONFIG),
  [actionTypes.SET_ETHERNET_CONFIG_TYPE]: trackEvent(
    action => ({
      category: "ethernet",
      action: actionTypes.SET_ETHERNET_CONFIG_TYPE,
      label: action.productType
    }),
    "gtm1"
  )
};

export default gaEventsMap;
