import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { QuantityInfoProps } from "./types";
import { QuantityInfoType } from "./enum";

export const QuantityInfo: React.FC<QuantityInfoProps> = ({
  type,
  children
}) => {
  const { info, error, success } = useStyles();
  let className = info;

  if (
    type === QuantityInfoType.STOCK_ERROR ||
    type === QuantityInfoType.QTY_ERROR
  ) {
    className = error;
  }
  if (type === QuantityInfoType.SUCCESS) {
    className = success;
  }

  return (
    <Typography className={className} variant={"body1"}>
      {children}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    color: theme.palette.grey[300]
  },
  error: {
    color: theme.palette.error.main
  },
  success: {
    color: theme.palette.success.main
  }
}));
