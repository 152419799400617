import { useSelector } from "react-redux";
import React from "react";
import * as Sentry from "@sentry/browser";
import { StepNavFooter } from "./global/StepNavFooter";
import OrderStatus from "./global/OrderStatus/index";
import FlashMessages from "../components/Shared/FlashMessages";
import AlertMessages from "../components/Shared/AlertMessages";
import FinalStepWarning from "../components/Shared/FinalStepWarning";
import {
  STEP_ACCOUNT,
  STEP_CHOOSE,
  STEP_CUSTOMISE,
  STEP_DELIVERY,
  STEP_SUMMARY
} from "../store/uiState/constants";
import { Header } from "../screens/shared/header";
import { Choose } from "../screens/choose";
import { Customise } from "../screens/customise";
import { Account } from "../screens/account";
import { Delivery } from "../screens/delivery";
import { Summary } from "../screens/summary";

function ActiveStep({ activeStep }: any) {
  switch (activeStep) {
    case STEP_CHOOSE:
      return <Choose />;
    case STEP_CUSTOMISE:
      return <Customise />;
    case STEP_ACCOUNT:
      return <Account />;
    case STEP_DELIVERY:
      return <Delivery />;
    case STEP_SUMMARY:
      return <Summary />;
    default:
      return <div />;
  }
}

export const Wizard: React.FC = () => {
  // TODO: Define DefaultRootState so we don't have to :any here
  const activeStep = useSelector((state: any) => state.uiState.activeStep);
  const orderStatusOpen = useSelector(
    (state: any) => state.uiState.orderStatusOpen
  );

  React.useEffect(() => {
    Sentry.addBreadcrumb({
      message: "GS UI Initialized",
      category: "action"
    });
  }, []);

  return (
    // Class name used to provide "autoscrolling" functionallity while going through
    // the CPQ process. Class name used in packages/affinity-sales/src/store/uiState/actions.js
    // See https://auroratarget.tpondemand.com/entity/43510-auto-scroll-to-top-of-page
    // for more information.
    <div className="WizardClass">
      <Header />
      <FlashMessages />
      <AlertMessages />
      <FinalStepWarning />
      {// @ts-ignore
      orderStatusOpen && <OrderStatus />}
      <ActiveStep activeStep={activeStep} />
      <StepNavFooter />
    </div>
  );
};
