import { connect } from "react-redux";
import React from "react";
import _ from "lodash";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { removeAlertMessages } from "../../store/uiState/actions";
import { ERROR } from "../../helpers/constants";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  .icon {
    width: 40px;
    height: 24px;
    flex-shrink: 0;
  }
`;

class AlertMessages extends React.Component {
  componentDidUpdate() {
    // TODO: Make keyboard focus work.
    // See https://github.com/mui-org/material-ui/issues/3008
    // if(this.props.alertMessages.length > 0) {
    //     ReactDOM.findDOMNode(this.button).focus()
    // }
  }

  render() {
    const { alertMessages, removeAlertMessages } = this.props;

    const title = _.uniq(alertMessages.map(m => m.title)).join(" / ");
    return (
      <Dialog
        open={alertMessages.length > 0}
        onClose={removeAlertMessages}
        TransitionComponent={Transition}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {alertMessages.map((alert, key) => (
            <AlertItem key={key}>
              <div className="icon">
                {alert.type === ERROR ? (
                  <ErrorIcon color="error" />
                ) : (
                  <InfoIcon color="secondary" />
                )}
              </div>
              <div dangerouslySetInnerHTML={{ __html: alert.message }} />
            </AlertItem>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            data-cy="removeAlertMessages"
            variant="contained"
            color="primary"
            onClick={removeAlertMessages}
            ref={node => (this.button = node)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
const mapStateToProps = state => {
  return {
    alertMessages: state.uiState.alertMessages
  };
};

export default connect(mapStateToProps, {
  removeAlertMessages
})(AlertMessages);
