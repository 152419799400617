import React from "react";
import Icon from "@material-ui/icons/Security";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";

const RadiusConfiguration = props => {
  return (
    <Section
      title="Radius Configuration"
      avatar={<Icon />}
      defaultExpanded={false}
    >
      <WlrBBDynamicField
        productType="broadband"
        propertyName="radius.radius_username"
        margin
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="radius.radius_password"
        margin
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="radius.radius_realm_id"
        margin
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="radius.routed_ip_quantity"
        margin
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="radius.wan_ip_type"
        margin
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="radius.wan_ipv6_type"
        margin
      />
    </Section>
  );
};

RadiusConfiguration.propTypes = {};

export default RadiusConfiguration;
