import React from "react";
import LineProductOverview from "./LineProductOverview";
import BroadbandProductOverview from "./BroadbandProductOverview";

const StandardProduct = ({
  configuration,
  isChecked,
  toggleRow,
  productInstance,
  setChanges,
  setNoChanges,
  removeResign,
  isLinkedProductInstance
}) => {
  return productInstance.type === "wlr" ? (
    <LineProductOverview
      configuration={configuration}
      isChecked={isChecked}
      toggleRow={toggleRow}
      productInstance={productInstance}
      setChanges={setChanges}
      setNoChanges={setNoChanges}
      removeResign={removeResign}
      isLinkedProductInstance={isLinkedProductInstance}
    />
  ) : (
    <BroadbandProductOverview
      configuration={configuration}
      isChecked={isChecked}
      toggleRow={toggleRow}
      productInstance={productInstance}
      setChanges={setChanges}
      setNoChanges={setNoChanges}
      removeResign={removeResign}
      isLinkedProductInstance={isLinkedProductInstance}
    />
  );
};

export default StandardProduct;
