import styled from "styled-components";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const AddressChip = ({ address, remove }) => {
  return (
    <Address>
      {address}
      <Action>
        <IconButton onClick={remove}>
          <CloseIcon htmlColor="white" />
        </IconButton>
      </Action>
    </Address>
  );
};

const Address = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  background: ${p => p.theme.palette.primary.main};
  border-radius: 5px;
  color: white;
`;
const Action = styled.div`
  flex: 1;
  text-align: right;
`;

export default AddressChip;
