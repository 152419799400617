import * as actionTypes from "./actionTypes";
import u from "updeep";

const initialState = {
  alertPreferences: {
    emailAddress: "",
    phoneNumber: ""
  },
  productSearch: {
    fetching: false,
    response: {}
  },
  configs: []
};

const blankConfig = {
  productId: false,
  oneOffPrice: "",
  recurringPrice: "",
  orderProduct: {
    fetching: false,
    response: {}
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_PRODUCT_SEARCH:
      return u(
        {
          productSearch: {
            fetching: true
          }
        },
        state
      );
    case actionTypes.RECEIVE_PRODUCT_SEARCH:
      return u(
        {
          productSearch: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );
    case actionTypes.SET_ALERT_PREFERENCES:
      return u(
        {
          alertPreferences: action.alertPreferences
        },
        state
      );
    case actionTypes.ADD_PRODUCT:
      return u(
        {
          configs: [
            ...state.configs,
            {
              ...blankConfig,
              productId: action.productId,
              oneOffPrice: action.oneOffPrice,
              recurringPrice: action.recurringPrice
            }
          ]
        },
        state
      );
    case actionTypes.REMOVE_PRODUCT:
      return u(
        {
          configs: state.configs.filter(
            (item, index) => index !== action.configIndex
          )
        },
        state
      );
    case actionTypes.REQUEST_ORDER_PRODUCT:
      return u(
        {
          configs: {
            [action.configIndex]: {
              orderProduct: {
                fetching: true,
                isUpdate: action.isUpdate
              }
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_ORDER_PRODUCT:
      return u(
        {
          configs: {
            [action.configIndex]: {
              orderProduct: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );
    default:
      return state;
  }
};
