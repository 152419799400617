import React from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { formatCurrency } from "../../../../../helpers/formatCurrency";

export interface PriceProps {
  price: number;
  label: string;
}

export const Price = ({ price, label }: PriceProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.price}>
      <span
        className={classes.main}
        data-cy={`price-${label.replace(/ +/g, "-")}`}
      >
        {formatCurrency(price)}
      </span>
      <span className={classes.label}>{label}</span>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  price: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  main: {
    fontSize: "16px",
    color: theme.palette.primary.main
  },
  label: {
    fontSize: "10px"
  }
}));
