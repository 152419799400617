import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  InputAdornment,
  makeStyles,
  TextField,
  Theme
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { StatusChip } from "@akj-dev/design-system";
import { search } from "../../../store/hardware/products/actions";
import { getStockLevel } from "../../../store/hardware/configurations/actions";
import { VirtualTable } from "../../../components/VirtualTable";
import { ActiveSort } from "../../../components/VirtualTable/types";
import { VirtualTableSortOrder } from "../../../components/VirtualTable/enums";
import {
  handleActiveSort,
  sortVirtualTableData,
  useDebouncedState
} from "../../../components/VirtualTable/utils";
import { Loader } from "../../shared/loader";
import { ItemsSelected } from "../choose-mobile/tariff/product-table/ItemsSelected";
import { ReduxHardwareTableType } from "./types";
import { useTableHeaders } from "./hooks";
import { MAX_QTY, RenderHardwareTableRow } from "./ChooseHardwareTableRow";

export const ChooseHardwareTable = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const {
    hardwareProducts,
    hardwareConfigurations,
    hasConnections
  } = useSelector<any, ReduxHardwareTableType>(state => ({
    hardwareProducts: state.hardwareProducts,
    hardwareConfigurations: state.hardwareConfigurations,
    hasConnections: (state.mobile?.config?.length ?? 0) > 0
  }));
  const {
    state: productFilter,
    debouncedState: debouncedProductFilter,
    handleState: handleProductFilter
  } = useDebouncedState();
  const [activeSort, setActiveSort] = useState<ActiveSort>({
    sortIndex: "name",
    order: VirtualTableSortOrder.ASC
  });

  const handleSort = useCallback(handleActiveSort(setActiveSort), [
    setActiveSort
  ]);

  const fetching = hardwareProducts.fetching;
  const hardwareProductsResponse = hardwareProducts.response;
  const status = hardwareProductsResponse?.status;
  const products = hardwareProductsResponse?.products ?? [];
  const error = hardwareProductsResponse?.message;
  const productsCount = products.length;
  const configsCount = Object.values(hardwareConfigurations ?? {})
    .map(({ quantity = 0 }) => quantity)
    .reduce((a, b) => a + b, 0);

  const handleStockLevel = useCallback(
    (productId: number) => {
      dispatch(getStockLevel(productId));
    },
    [dispatch]
  );

  const filteredProducts = useMemo(() => {
    return products
      .filter(
        ({ name }) =>
          name.toLowerCase().indexOf(debouncedProductFilter.toLowerCase()) !==
          -1
      )
      .sort(({ name: nameA = "" }, { name: nameB = "" }) =>
        nameA.localeCompare(nameB)
      );
  }, [products, debouncedProductFilter]);
  const sortedProducts = useMemo(() => {
    return filteredProducts.sort(sortVirtualTableData(activeSort));
  }, [filteredProducts, activeSort]);

  const tableHeaders = useTableHeaders(hasConnections);

  useEffect(() => {
    if (status !== "success") {
      dispatch(search());
    }
  }, [status, dispatch]);

  const showLoader = fetching;
  const showError = !fetching && error;
  const showTable = !showLoader && !error;

  return (
    <Box className={classes.root}>
      {showLoader && <Loader />}
      {showTable && (
        <>
          <Box className={classes.searchBox}>
            <TextField
              data-cy={"tableSearch"}
              className={classes.search}
              variant="outlined"
              name="filter"
              value={productFilter}
              onChange={handleProductFilter}
              placeholder={`Search ${productsCount} Available Products`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            {!!configsCount && (
              <ItemsSelected
                maxQuantity={MAX_QTY}
                configsCount={configsCount}
              />
            )}
          </Box>
          <VirtualTable
            withScrollButton={true}
            headers={tableHeaders}
            activeSort={activeSort}
            handleSort={handleSort}
            data={{
              products: sortedProducts,
              hasConnections,
              configsCount,
              handleStockLevel,
              hardwareConfigurations
            }}
            tableSize={500}
            itemSize={80}
            itemCount={sortedProducts.length}
            RenderTableRow={RenderHardwareTableRow}
          />
        </>
      )}
      {showError && (
        <Box mb={1}>
          <StatusChip
            type="error"
            title="Error finding price book mobile products"
            message={error}
            retry={() => dispatch(search())}
          />
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: `calc(100% - ${theme.spacing(3)}px) !important`,
    display: "flex",
    flexDirection: "column",
    margin: `0 ${theme.spacing(1.5)}px`
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  search: {
    minWidth: "400px",
    marginBottom: theme.spacing(1)
  }
}));
