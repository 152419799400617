import React from "react";
import NoProductChangesRow from "./NoProductChangesRow";
import ProductChangesRow from "./ProductChangesRow";
import { connect } from "react-redux";
import RowActions from "../RowActions";
import {
  getProductInstances,
  getProducts
} from "../../../../../store/wlrBroadband/selectors";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES
} from "../../../../../store/wlrBroadband/constants";
import { LineProductSelector } from "../LineProductSelector";

const LineProductOverview = ({
  configuration,
  isChecked,
  toggleRow,
  productInstance,
  setChanges,
  setNoChanges,
  removeResign,
  isLinkedProductInstance,
  products,
  productInstances
}) => {
  const resignProduct = products.find(
    product => product.id === configuration.wlrProductId
  );
  // const canAddBroadband =
  //   productInstances.filter(item => item.pin === productInstance.pin).length ===
  //     1 && productInstance.line_details?.service_type !== "ISDN30E";
  return (
    <>
      {configuration.wlrProductId &&
      configuration.wlrProductId !== "Resign" &&
      resignProduct ? (
        <ProductChangesRow
          configuration={configuration}
          isChecked={isChecked}
          toggleRow={toggleRow}
          productInstance={productInstance}
          isLinkedProductInstance={isLinkedProductInstance}
          resignProduct={resignProduct}
          canAddBroadband={false} // Need account with product instance to
          // test.
          actions={
            <RowActions
              configuration={configuration}
              productInstance={productInstance}
              removeResign={removeResign}
              hasProductId={configuration.wlrProductId}
            />
          }
        />
      ) : (
        <NoProductChangesRow
          isChecked={isChecked}
          toggleRow={toggleRow}
          productInstance={productInstance}
          isLinkedProductInstance={isLinkedProductInstance}
          actions={
            <RowActions
              configuration={configuration}
              productInstance={productInstance}
              setChanges={setChanges}
              setNoChanges={setNoChanges}
              removeResign={removeResign}
              hasProductId={configuration.wlrProductId}
            />
          }
        />
      )}
      {(configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES ||
        configuration.resignProductType === NEW_PRODUCT) &&
        !configuration.wlrProductId && (
          <LineProductSelector
            configuration={configuration}
            productInstance={productInstance}
          />
        )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const locationIndex = ownProps.configuration.locationIndex;
  return {
    productInstances: getProductInstances(state),
    products: getProducts(state, locationIndex)
  };
};

export default connect(mapStateToProps)(LineProductOverview);
