import React from "react";
import { connect } from "react-redux";
import { setContractLength } from "../../store/order/actions";
import { Select, MenuItem } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

class ContractLength extends React.Component {
  render() {
    const { contractLength, setContractLength } = this.props;

    return (
      <FormControl variant={"outlined"} fullWidth>
        <InputLabel htmlFor="contractLengthSelectField">
          Contract Length
        </InputLabel>
        <Select
          label={"Contract Length"}
          variant={"outlined"}
          fullWidth
          value={contractLength}
          onChange={event => {
            setContractLength(event.target.value);
          }}
        >
          <MenuItem value={12}>12 months</MenuItem>
          <MenuItem value={24}>24 months</MenuItem>
          <MenuItem value={36}>36 months</MenuItem>
          <MenuItem value={48}>48 months</MenuItem>
          <MenuItem value={60}>60 months</MenuItem>
          <MenuItem value={72}>72 months</MenuItem>
        </Select>
      </FormControl>
    );
  }
}

const mapStateToProps = state => {
  return {
    contractLength: state.order.contractLength
  };
};

export default connect(mapStateToProps, {
  setContractLength
})(ContractLength);
