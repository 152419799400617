import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  setNewAccountField,
  validateNewAccountField
} from "../../store/account/actions";
import { getNewAccountFieldRequired } from "../../store/account/selectors";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";

class AccountTextField extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    autoComplete: PropTypes.string,
    maxLength: PropTypes.number
  };

  render() {
    const {
      label,
      name,
      value,
      handleChange,
      handleBlur,
      errorText,
      required,
      disabled,
      autoComplete,
      maxLength
    } = this.props;
    return (
      <Grid item sm={6}>
        <TextField
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          label={`${label}${required ? " *" : ""}`}
          name={name}
          value={value || ""}
          helperText={errorText}
          error={!!errorText}
          disabled={disabled}
          autoComplete={autoComplete}
          inputProps={{ maxLength }}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  value: state.account.newAccountFields[ownProps.name],
  required: getNewAccountFieldRequired(state, ownProps),
  errorText: state.account.newAccountFieldsValidation[ownProps.name]
});

const mapDispatchToProps = dispatch => ({
  handleChange: e =>
    dispatch(setNewAccountField(e.target.name, e.target.value)),
  handleBlur: e => dispatch(validateNewAccountField(e.target.name))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountTextField);
