import React, { Component } from "react";
import _ from "lodash";
import ConfigurationStatus from "../ConfigurationStatus";
import Spinner from "../../../../components/Shared/Spinner";
import Checkbox from "@material-ui/core/Checkbox";
import { StatusChip } from "@akj-dev/design-system";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableActionsCell from "../../../../components/Shared/TableActionsCell";
import ResignTypeChip from "./ResignTypeChip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { SAME_PRODUCT } from "../../../../store/wlrBroadband/constants";
import {
  getOverallBroadbandPriceForResign,
  getProductInstanceForConfiguration,
  getTotalPrice
} from "../../../../store/wlrBroadband/selectors";
import { connect } from "react-redux";
import ConfigStatus from "../../../../components/Shared/ConfigStatus";
import {
  setWlrResignProduct,
  setBroadbandResignProduct,
  getProductData
} from "../../../../store/wlrBroadband/actions";
import { addAlertMessage } from "../../../../store/uiState/actions";

class ResignConfigTableRow extends Component {
  render() {
    const {
      resignProductType,
      productInstance,
      configuration,
      index,
      checked,
      toggleAction,
      editAction,
      deleteAction,
      retryAction,
      overallBroadbandPrice
    } = this.props;

    if (resignProductType === SAME_PRODUCT)
      return (
        <TableRow key={index}>
          {toggleAction && <TableCell />}
          <TableCell>{configuration.pin}</TableCell>
          <TableCell />
          <TableCell>
            {productInstance.name_for_resign || productInstance.name}
          </TableCell>
          <TableCell>{getTotalPrice(productInstance.evo_services)}</TableCell>
          <TableCell>
            <ResignTypeChip configuration={configuration} />
          </TableCell>
          <TableCell>
            <ConfigStatus isValid={true} />
          </TableCell>
          <TableActionsCell>
            <IconButton onClick={deleteAction}>
              <DeleteIcon />
            </IconButton>
          </TableActionsCell>
        </TableRow>
      );

    if (!configuration.wlrProductData && !configuration.broadbandProductData)
      return false;

    const fetching =
      configuration.wlrProductData.fetching ||
      configuration.broadbandProductData.fetching ||
      configuration.broadbandValidation.fetching;

    if (
      !fetching &&
      (configuration.wlrProductData.response.status === "error" ||
        configuration.broadbandProductData.response.status === "error")
    )
      return (
        <TableRow key={index}>
          <TableCell colSpan={8}>
            <StatusChip
              type="error"
              title="Error fetching product data."
              retry={retryAction}
            />
          </TableCell>
        </TableRow>
      );

    return (
      <TableRow key={index}>
        {toggleAction && (
          <TableCell>
            <Checkbox checked={checked} onChange={toggleAction} />
          </TableCell>
        )}
        <TableCell>{configuration.pin}</TableCell>
        <TableCell />
        <TableCell>
          {fetching ? (
            <Spinner size={30} />
          ) : (
            <>
              <div>
                {configuration.wlrProductData.response &&
                  configuration.wlrProductData.response.product_name}
              </div>
              <div>
                {configuration.broadbandProductData.response &&
                  configuration.broadbandProductData.response.product_name}
              </div>
            </>
          )}
        </TableCell>
        <TableCell>
          {(
            parseFloat(
              _.get(
                configuration.wlrProductData.response,
                "overall_price.first_bill_recurring_price_with_promotions",
                0
              )
            ) + overallBroadbandPrice
          ).toFixed(2)}
        </TableCell>
        <TableCell>
          <ResignTypeChip configuration={configuration} />
        </TableCell>
        <TableCell>
          {configuration.broadbandValidation.fetching ? (
            <Spinner size={30} />
          ) : (
            <ConfigurationStatus
              configuration={configuration}
              onClick={fetching ? () => false : editAction}
            />
          )}
        </TableCell>
        {editAction && (
          <TableActionsCell>
            <IconButton
              disabled={fetching}
              onClick={editAction}
              data-cy="wlrBroadbandEditResignWChangeConfig"
            >
              <EditIcon />
            </IconButton>
            <IconButton disabled={fetching} onClick={deleteAction}>
              <DeleteIcon />
            </IconButton>
          </TableActionsCell>
        )}
      </TableRow>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const productInstance = getProductInstanceForConfiguration(
    state,
    ownProps.index
  );
  return {
    resignProductType: ownProps.configuration.resignProductType,
    productInstance,
    overallBroadbandPrice: getOverallBroadbandPriceForResign(
      ownProps.configuration,
      true
    )
  };
};

export default connect(mapStateToProps, {
  setWlrResignProduct,
  setBroadbandResignProduct,
  getProductData,
  addAlertMessage
})(ResignConfigTableRow);
