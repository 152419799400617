import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import { StatusChip } from "@akj-dev/design-system";
import * as OrdersApi from "../../../../../../api/v1/orders";

interface RequestStatusProps {
  jobId?: string;
}

export const RequestStatus = ({ jobId }: RequestStatusProps) => {
  const [statusState, setStatusState] = useState<{
    response: {
      status: string;
      data?: any;
      message?: string;
    };
    interval?: number;
  }>({
    response: {
      // NOTE: This is the format we expect back from DC
      status: "success",
      data: {
        order_reference: "",
        id: "",
        number_failed: 0,
        number_provisioned: 0,
        number_validated: 0,
        status: "Requesting"
      }
    }
  });

  useEffect(() => {
    const interval = setInterval(async () => {
      const response: any = await OrdersApi.bulkOrderStatus(jobId);
      setStatusState((oldState: any) => ({ ...oldState, response }));

      if (response.status === "error") {
        // TODO: What's complete condition?
        clearInterval(statusState.interval);
      }
    }, 3000);

    setStatusState(oldState => ({ ...oldState, interval } as any));

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = statusState?.response?.data;
  const message = statusState?.response?.message;

  if (!data) {
    // Something has gone horribly wrong in general...
    return (
      <StatusChip type="error" title="Error uploading file" message={message} />
    );
  }
  /**
   * The batch processor has returned it's own status.
   * NOTE: Status can be.....
   * STATUS_LOADING      => 'Loading';
   * STATUS_PENDING      => 'Pending';
   * STATUS_VALIDATING   => 'Validating';
   * STATUS_VALIDATED    => 'Validated';
   * STATUS_PROVISIONING => 'Provisioning';
   * STATUS_COMPLETE     => 'Complete';
   * STATUS_REJECTED     => 'Rejected';
   * STATUS_CANCELLED    => 'Cancelled';
   */
  let progress;
  switch (data.status) {
    case "Complete":
      progress = (
        <CheckIcon htmlColor="#4caf50" style={{ width: 60, height: 60 }} />
      );
      break;
    case "Rejected":
    case "Cancelled":
      progress = (
        <ErrorIcon htmlColor="#f44336" style={{ width: 60, height: 60 }} />
      );
      break;
    default:
      progress = <CircularProgress size={80} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Box my={1}>{progress}</Box>
          <Typography variant="h5">{data.status}</Typography>
        </Box>
      </Grid>
      <Grid item sm={6}>
        <List>
          <ListItem>
            <ListItemText>
              Job ID: <b>{data.id}</b>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              Order Reference: <b>{data.order_reference}</b>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              Provisioned: <b>{data.number_provisioned}</b>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              Validated: <b>{data.number_validated}</b>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              Failed: <b>{data.number_failed}</b>
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
      <Grid item sm={12}>
        <StatusChip
          type="info"
          message="This window can be closed while the job completes."
        />
      </Grid>
    </Grid>
  );
};
