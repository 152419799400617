import u from "updeep";
import _ from "lodash";
import * as actions from "./actionTypes";
import { newAccountValidations } from "./validation";
import { getFieldValidationMessage } from "./selectors";
import accountSettingsDefault from "../../config/accountSettings.default";

const initialState = {
  availableHardwareCredit: 0,
  isFetchingAvailableHardwareCredit: false,

  boltOns: {},

  settings: {
    fetching: true,
    response: {},
  },

  billingAddress: {
    fetching: false,
    response: {},
  },

  accountAddresses: {
    fetching: false,
    response: {},
    selectedIndex: false,
  },

  // Either account contacts when we have an account, or lead contacts when we don't
  contacts: {
    fetching: false,
    response: {},
    selectedId: false,
  },
  orderContactId: false,

  contactTypes: {
    fetching: false,
    response: {},
  },

  // New account form fields, with defaults.
  newAccountFields: {
    billing_address_same: 1,
    billing_country: "United Kingdom",
    company_country: "United Kingdom",
    create_cost_centre_csv: "0",
    ebilling_enabled: "1",
    large_print_bill: "0",
    ok_to_email: "0",
    ok_to_phone: "0",
    payment_type: "DD",
  },

  // Validation messages to display on new account fields
  newAccountFieldsValidation: {},

  // v1/Orders/CreateAccount when creating new accounts from leads
  // or:
  // v1/Account/Create when creating as sub accounts (MIC)
  createAccount: {
    fetching: false,
    response: {},
  },
  // ID of newly created sub account. Only for contact creation atm?
  // TODO: put this on an action in the order reducer instead? Can it just swap the main account ID?
  newAccountId: false,

  // v1/Account/AddContact/
  addContact: {
    fetching: false,
    response: {},
  },

  // v1/Account/ThirdPartyBilling/
  creditVet: {
    fetching: false,
    hasError: false,
    response: {},
  },
};

/**
 * Account settings for use outside of component lifecycle.
 * Just API helpers currently. Populated on store action below
 * TODO: Looks unused... retire?
 */
export let accountSettings = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_ACCOUNT_SETTINGS:
      return u(
        {
          settings: {
            fetching: true,
          },
        },
        state
      );
    case actions.RECEIVE_ACCOUNT_SETTINGS:
      let settings;
      if (action.response.status !== "success") {
        settings = accountSettingsDefault;
      } else {
        settings = u(action.response, accountSettingsDefault);
      }

      accountSettings = settings;
      return u(
        {
          settings: {
            fetching: false,
            response: settings,
          },
        },
        state
      );
    case actions.REQUEST_AVAILABLE_HARDWARE_CREDIT:
      return u(
        {
          isFetchingAvailableHardwareCredit: true,
        },
        state
      );
    case actions.RECEIVE_AVAILABLE_HARDWARE_CREDIT:
      return u(
        {
          availableHardwareCredit: action.credit,
          isFetchingAvailableHardwareCredit: false,
        },
        state
      );

    case actions.REQUEST_BILLING_ADDRESS:
    case actions.REQUEST_UPDATE_BILLING_ADDRESS:
      return u(
        {
          billingAddress: {
            fetching: true,
          },
        },
        state
      );

    case actions.RECEIVE_BILLING_ADDRESS:
      return u(
        {
          billingAddress: {
            fetching: false,
            response: () => action.response,
          },
        },
        state
      );

    case actions.REQUEST_ALL_ACCOUNT_ADDRESSES:
      return u(
        {
          accountAddresses: {
            fetching: true,
          },
        },
        state
      );

    case actions.RECEIVE_ALL_ACCOUNT_ADDRESSES:
      return u(
        {
          accountAddresses: {
            fetching: false,
            response: () => action.response,
          },
        },
        state
      );

    case actions.REQUEST_ALL_CONTACTS:
      return u(
        {
          contacts: {
            fetching: true,
          },
        },
        state
      );

    case actions.RECEIVE_ALL_CONTACTS:
      return u(
        {
          contacts: {
            fetching: false,
            response: () => action.response,
          },
          // We refresh account contacts after adding a new one, so clear response from the add call while we're at it.
          addContact: {
            response: () => ({}),
          },
        },
        state
      );

    case actions.REQUEST_CONTACT_TYPES:
      return u(
        {
          contactTypes: {
            fetching: true,
          },
        },
        state
      );

    case actions.RECEIVE_CONTACT_TYPES:
      return u(
        {
          contactTypes: {
            fetching: false,
            response: () => action.response,
          },
        },
        state
      );

    case actions.SET_NEW_ACCOUNT_FIELD:
      return u(
        {
          newAccountFields: {
            [action.fieldId]: action.value,
            ...(action.fieldId === "email" && { ebilling_email: action.value }),
          },
        },
        state
      );

    case actions.DELETE_NEW_ACCOUNT_FIELD:
      const {
        [action.fieldId]: value,
        ...withoutDeleted
      } = state.newAccountFields;

      return u({ newAccountFields: u.constant(withoutDeleted) }, state);

    case actions.VALIDATE_NEW_ACCOUNT_FIELD:
      return u(
        {
          newAccountFieldsValidation: {
            [action.fieldId]: getFieldValidationMessage(state, action.fieldId),
          },
        },
        state
      );

    case actions.VALIDATE_ALL_NEW_ACCOUNT_FIELDS:
      const errors = {};
      Object.keys(newAccountValidations).forEach((name) => {
        errors[name] = getFieldValidationMessage(state, name);
      });

      return u(
        {
          newAccountFieldsValidation: errors,
        },
        state
      );

    case actions.REQUEST_CREATE_ACCOUNT:
      return u(
        {
          createAccount: {
            fetching: true,
          },
        },
        state
      );

    case actions.RECEIVE_CREATE_ACCOUNT:
      return u(
        {
          createAccount: {
            fetching: false,
            response: () => action.response,
          },
          newAccountId: _.get(action.response, "data.id"),
        },
        state
      );

    case actions.REQUEST_ADD_CONTACT:
      return u(
        {
          addContact: {
            fetching: true,
          },
        },
        state
      );

    case actions.RECEIVE_ADD_CONTACT:
      return u(
        {
          addContact: {
            fetching: false,
            response: () => action.response,
          },
        },
        state
      );

    case actions.SET_NEW_ACCOUNT_FIELDS:
      return u(
        {
          newAccountFields: action.newAccountFields,
        },
        state
      );

    /**
     * Auto-populate new account form.
     * Field names don't match up as usual though, hence the mapping here.
     * We went into this thinking they would :-(
     * https://gitlab.com/akj-dev/inbox/issues/526
     */
    case actions.SET_NEW_ACCOUNT_FIELDS_FROM_CONTACT:
      const contact =
        state.contacts.response.data.contacts[action.contactIndex];
      return u(
        {
          newAccountFields: {
            // company_building: "",
            // company_street: contact.primary_address_street, // This has new lines and an "address" TextArea in the DC form. FFS
            // company_post_town: "",
            // company_region: "",
            company_postcode: contact.primary_address_postalcode,
            // company_country: contact.primary_address_country, // never populated + it's defaulted to UK already...
            // salutation: contact.salutation, // Always "Mr" but no UI in DC lead creation UI (eg http://daisycentraltesting10.daisy.group/cgi-bin/index.cgi/Leads/create/35890)
            first_name: contact.first_name,
            last_name: contact.last_name,
            phone: contact.primary_telephone_number,
            // fax: "",
            mobile: contact.mobile_phone_number,
            email: contact.primary_email_address,
            // birthdate: "",
            ebilling_email: contact.primary_email_address,
          },
        },
        state
      );

    case actions.REQUEST_CREDIT_VET:
      return u(
        {
          creditVet: {
            fetching: true,
          },
        },
        state
      );

    case actions.SET_CREDIT_VET_ERROR:
      return u(
        {
          creditVet: {
            fetching: false,
            hasError: true,
            response: {},
          },
        },
        state
      );

    // note this is whether the call was successful rather than the outcome of the credit vet against the account returned from VF
    case actions.SET_CREDIT_VET_SUCCESS_RESPONSE:
      return u(
        {
          creditVet: {
            hasError: false,
            response: action.payload,
            fetching: false,
          },
        },
        state
      );

    case actions.RESET_ACCOUNT_STATE:
      return { ...initialState, accountId: false };

    default:
      return state;
  }
};
