import { NEW_LINE } from "../constants";

// WLR+Broadband configurations belong to locations. Each location can have multiple configurations:

const newLocation = {
  type: NEW_LINE,
  address: {},
  sogeaTermsAccepted: false,
  cli: {
    // Existing line only
    value: "",
    validating: false,
    validation: {} // API vi/GuidedSales/ValidateCLI
  },

  lineAvailability: {
    fetching: false,
    response: {}
  }, // API: v1/WLR3/LineAvailability - details about available capacity at address

  installationDetails: {
    fetching: false,
    response: {}
  }, // API: v1/WLR3/InstallationDetails - details about an existing CLI. service_type tells us which transfer product to offer.

  wltoDetails: {
    fetching: false,
    response: {}
  }, // API: WLR3/ValidateWorkingLineTakeOver - details about a CLI that's not on the BT network. WLTO is different than a standard line transfer.

  broadbandSearch: {
    fetching: false,
    response: {}
  }, // API: v1/Product/BroadbandSearch - bb products available at address (also returns speed info which we don't use)

  numbers: {
    fetching: false,
    response: {}
  }, // API: v1/WLR3/FindNumbersAtAddress,

  tagsCheck: {
    fetching: false,
    response: {}
  } // API: v1/BroadbandAvailability/TagsCheck,
};

export default newLocation;
