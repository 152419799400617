import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { initBankHolidays } from "../../helpers/bankHolidays";
import { setActiveSubStepChoose } from "../../store/uiState/actions";
import MonitoringServiceProducts from "../../containers/StepChoose/MonitoringService";
import UniversalProducts from "../../containers/StepChoose/UniversalProducts";
import {
  saleStepLabelObject,
  saleSubStepChooseLabelObject
} from "../constants";
import { ProductTypesEnum, SaleStepEnum } from "../enums";
import { TitleAndInfo } from "../shared/title-and-info";
import { Footer } from "../shared/footer";
import { SubStepActionEnum } from "./enums";
import { ChooseProductTypes } from "./choose-product-types";
import { ChooseMobile } from "./choose-mobile";
import { ChooseHardware } from "./choose-hardware";
import { ChooseBroadband } from "./choose-broadband";
import { ChooseEthernet } from "./choose-ethernet";
import { getAccountSettings } from "../../store/account/selectors";
import { AccountSettings } from "@akj-dev/affinity-platform";

type ReduxChooseType = {
  productTypes: ProductTypesEnum[];
  activeStep: SaleStepEnum;
  activeSubStepChoose: ProductTypesEnum;
};

export const Choose = () => {
  const dispatch = useDispatch();
  const { productTypes, activeStep, activeSubStepChoose } = useSelector<
    any,
    ReduxChooseType
  >(state => ({
    productTypes: state.uiState.productTypes ?? [],
    activeStep: state.uiState.activeStep,
    activeSubStepChoose:
      state.uiState.activeSubStepChoose ?? ProductTypesEnum.SELECT
  }));
  const title =
    activeStep === SaleStepEnum.STEP_CHOOSE
      ? saleSubStepChooseLabelObject[activeSubStepChoose]
      : saleStepLabelObject[activeStep];

  const settings: AccountSettings = useSelector(getAccountSettings);
  const dwsTermsAccepted = useSelector<any, boolean>(
    state => state.mobile.dwsTermsAccepted
  );
  const continueButtonDisabled =
    productTypes.length === 0 ||
    (settings.dws_reseller_enabled === "1" &&
      productTypes.includes(ProductTypesEnum.MOBILE) &&
      !dwsTermsAccepted);

  const handleContinue = () => {
    dispatch(setActiveSubStepChoose(SubStepActionEnum.CONTINUE));
  };

  const backButtonHidden = activeSubStepChoose === ProductTypesEnum.SELECT;
  const handleBack = () => {
    dispatch(setActiveSubStepChoose(SubStepActionEnum.BACK));
  };

  useEffect(() => {
    // TODO: Stop this re-fetching when we already have up-to-date data
    initBankHolidays();
  }, []);

  return (
    <Box>
      <TitleAndInfo title={title} />
      {activeSubStepChoose === ProductTypesEnum.SELECT && (
        <ChooseProductTypes />
      )}
      {activeSubStepChoose === ProductTypesEnum.MOBILE && <ChooseMobile />}
      {activeSubStepChoose === ProductTypesEnum.HARDWARE && <ChooseHardware />}
      {activeSubStepChoose === ProductTypesEnum.BROADBAND && (
        <ChooseBroadband />
      )}
      {activeSubStepChoose === ProductTypesEnum.ETHERNET && <ChooseEthernet />}
      {activeSubStepChoose === ProductTypesEnum.MONITORING && (
        <MonitoringServiceProducts />
      )}
      {activeSubStepChoose === ProductTypesEnum.UNIVERSAL && (
        <UniversalProducts />
      )}
      <Footer
        continueDisabled={continueButtonDisabled}
        backHidden={backButtonHidden}
        onContinue={handleContinue}
        onBack={handleBack}
      />
    </Box>
  );
};
