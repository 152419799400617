import u from "updeep";
import * as actionTypes from "../actionTypes";
import * as accountActionTypes from "../../account/actionTypes";

export default (state, action) => {
  switch (action.type) {
    case accountActionTypes.RECEIVE_ACCOUNT_SETTINGS:
      if (action.response?.settings?.dws_reseller_enabled === "1") {
        return u({ contractLengthInMonthsAllProducts: 1 }, state);
      } else {
        return state;
      }

    case actionTypes.SET_CONTRACT_LENGTH_IN_MONTHS_ALL_PRODUCTS:
      // Reset any airtime credit durations on existing configs.
      // They can only be 1 month or contract length, so become invalid.
      let resetConfigs = {};
      state.configs.forEach((c, i) => {
        resetConfigs[i] = {
          properties: {
            airtime_credit_duration: null
          }
        };
      });

      return u(
        {
          contractLengthInMonthsAllProducts: action.contractLength,
          configs: resetConfigs
        },
        state
      );

    case actionTypes.SET_CONTRACT_LENGTH_IN_MONTHS_UI_FILTER:
      return u(
        {
          contractLengthInMonthsUIFilter: action.filterValue
        },
        state
      );

    case actionTypes.TOGGLE_CO_TERMINUS:
      return u(
        {
          contractCoTerminus: !state.contractCoTerminus
        },
        state
      );

    default:
      return state;
  }
};
