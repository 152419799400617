import React from "react";
import { connect } from "react-redux";
import { StatusChip } from "@akj-dev/design-system";
import { getTotalOrderHardwareCredit } from "../../../store/hardware/configurations/selectors";
import { getHardwareOrderTotalBeforeCredit } from "../../../store/order/selectors";

class CreditWarning extends React.Component {
  render() {
    if (this.props.totalOrderHardwareCredit > this.props.hardwareOrderTotal) {
      return (
        <StatusChip
          type="error"
          title="Hardware credit must not exceed hardware order value"
          message="Please reduce the amount before continuing."
        />
      );
    }
    return false;
  }
}

const mapStateToProps = (state, ownProps) => ({
  totalOrderHardwareCredit: getTotalOrderHardwareCredit(
    state.hardwareConfigurations
  ),
  hardwareOrderTotal: getHardwareOrderTotalBeforeCredit(state)
});

export default connect(mapStateToProps)(CreditWarning);
