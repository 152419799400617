import u from "updeep";
import * as actionTypes from "./actionTypes";

const initialState = {};

/**
 * Hardware Orders reducer.
 * TODO: Refactor the whole hardware section state shape to use one root reducer like the WLR+Mobile sections
 * Having it split like this and merged with combineReducers() instead of a simple reduce() function means
 * we lose interoperability between the different sections, thus limiting the scope of BL we can carry out in the reducer.
 * BL should be done in the reducer 99% of the time.
 *
 * TODO: Store these OrderProduct responses with the configs themselves, so quotes will work properly? (see WLR+Mobile sections)
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_ORDER_PRODUCT:
      return u(
        {
          [action.identifier]: {
            fetching: true,
            isUpdate: action.isUpdate,
            response: {}
          }
        },
        state
      );

    case actionTypes.RECEIVE_ORDER_PRODUCT:
      return u(
        {
          [action.identifier]: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );
    default:
      return state;
  }
};
