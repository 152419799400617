import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DiscountIcon from "@material-ui/icons/ImportExport";
import { Slider, Box, Typography } from "@material-ui/core";
import { updateEthernetCustomerData } from "../../../../../store/ethernetProducts/actionsCreators";

class AdjustPrice extends Component {
  static propTypes = {
    index: PropTypes.number
  };

  updatePrice = (event, value) => {
    this.props.dispatch(
      updateEthernetCustomerData(this.props.index, "discount", value)
    );
  };

  render() {
    const configuration = this.props.configurations[this.props.index];
    const discountPercentage = configuration.purchaseArgs.discount;
    // const originalPrice = _.filter(configuration.pricingResults, {
    //   id: configuration.selectedQuoteId
    // })[0].recurring_price;
    return (
      <Box display="flex">
        <Box display="flex" mr={1}>
          <DiscountIcon />
          <Typography noWrap>Discount %</Typography>
        </Box>
        <Slider
          value={discountPercentage}
          min={-16.5}
          max={20}
          onChange={this.updatePrice}
          step={0.5}
          getAriaValueText={value => `${value.toFixed(1)}%`}
          valueLabelDisplay="on"
        />
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    configurations: state.ethernetProducts.configurations
  };
};

export default connect(mapStateToProps)(AdjustPrice);
