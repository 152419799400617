export const ADD_ETHERNET_CONFIG = "ethernet.ADD_ETHERNET_CONFIG";
export const REMOVE_ETHERNET_CONFIG = "ethernet.REMOVE_ETHERNET_CONFIG";
export const SET_ETHERNET_CONFIG_TYPE = "ethernet.SET_ETHERNET_CONFIG_TYPE";
export const UPDATE_ETHERNET_CONFIG = "ethernet.UPDATE_ETHERNET_CONFIG";
export const SET_ETHERNET_CONFIG_ADDRESS =
  "ethernet.SET_ETHERNET_CONFIG_ADDRESS";

export const SET_ETHERNET_CONFIG_PURCHASE_ARGS_SUBMITTED =
  "ethernet.SET_ETHERNET_CONFIG_PURCHASE_ARGS_SUBMITTED";

// Get a list of routers the user can pick from (for the purposes of the quote)
export const REQUEST_AVAILABLE_ROUTERS = "ethernet.REQUEST_AVAILABLE_ROUTERS";
export const RECEIVE_AVAILABLE_ROUTERS = "ethernet.RECEIVE_AVAILABLE_ROUTERS";

export const UPDATE_ETHERNET_PRICING_REQUEST_ID =
  "ethernet.UPDATE_ETHERNET_PRICING_REQUEST_ID";
export const UPDATE_ETHERNET_PRICING_REQUEST_STATUS =
  "ethernet.UPDATE_ETHERNET_PRICING_REQUEST_STATUS";
export const UPDATE_ETHERNET_PRICING_RESULTS =
  "ethernet.UPDATE_ETHERNET_PRICING_RESULTS";
export const RESET_ETHERNET_PRICING_REQUEST =
  "ethernet.RESET_ETHERNET_PRICING_REQUEST";
export const SELECT_ETHERNET_QUOTE = "ethernet.SELECT_ETHERNET_QUOTE";
export const DESELECT_ETHERNET_QUOTE = "ethernet.DESELECT_ETHERNET_QUOTE";
export const UPDATE_ETHERNET_CUSTOMER_DATA =
  "ethernet.UPDATE_ETHERNET_CUSTOMER_DATA";
export const REQUEST_ETHERNET_PURCHASE = "ethernet.REQUEST_ETHERNET_PURCHASE";
export const RECEIVE_ETHERNET_PURCHASE = "ethernet.RECEIVE_ETHERNET_PURCHASE";
export const REMOVE_ETHERNET_PURCHASE_ERRORS =
  "ethernet.REMOVE_ETHERNET_PURCHASE_ERRORS";
