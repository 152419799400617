import React from "react";
import ProductTitle from "../../../../components/Shared/mobile/ProductTitle";
import Checkbox from "@material-ui/core/Checkbox";
import { getNonGenericInstanceName } from "../../../../store/mobile/selectors/resigns";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const SelectedChangesRow = ({
  isChecked,
  toggleRow,
  productInstance,
  newProduct,
  actions
}) => {
  return (
    <TableRow selected={isChecked}>
      <TableCell>
        <Checkbox onChange={toggleRow} checked={isChecked} />
      </TableCell>
      <TableCell>{productInstance.pin}</TableCell>
      <TableCell>
        <ProductTitle network={productInstance.network.name}>
          {getNonGenericInstanceName(productInstance)}
        </ProductTitle>
      </TableCell>
      <TableCell>
        <ArrowForwardIcon />
      </TableCell>
      <TableCell>
        <ProductTitle network={newProduct.first_mobile_component.supplier}>
          {newProduct.name}
        </ProductTitle>
      </TableCell>
      <TableCell>
        {newProduct.price.first_bill_recurring_price_with_promotions}
      </TableCell>
      <TableCell className="u-nowrap">{productInstance.end_date_ymd}</TableCell>
      <TableCell align="right" style={{ paddingRight: 0 }}>
        {actions}
      </TableCell>
    </TableRow>
  );
};

SelectedChangesRow.propTypes = {};

export default SelectedChangesRow;
