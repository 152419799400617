import React from "react";
import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import { getNetworkIcon } from "../choose/choose-mobile/utils";

export interface CarrierFieldProps {
  network: string;
}

export const CarrierField = ({ network }: CarrierFieldProps) => {
  const classes = useStyles();
  const avatar = getNetworkIcon(network);

  return (
    <Box className={classes.root}>
      <Box className={classes.avatar}>
        {avatar && <img src={avatar} width="40" alt="" />}
      </Box>
      <Typography variant={"body1"}>{network}</Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    width: 40,
    marginRight: theme.spacing(1)
  }
}));
