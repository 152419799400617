import React, { Component } from "react";
import PropTypes from "prop-types";
import SelectedProduct from "./sections/SelectedProduct";
import AdjustPrice from "./sections/AdjustPrice";
import SiteInstallationAddress from "./sections/SiteInstallationAddress";
import CustomerRequiredDate from "./sections/CustomerRequiredDate";
import SiteContact from "./sections/SiteContact";
import Location from "./sections/Location";
import Voice from "./sections/Voice";
import {
  ETHERNET_PRODUCT_MANAGED,
  ETHERNET_PRODUCT_PTP
} from "../../../../store/ethernetProducts/constants";
import { Grid, Typography } from "@material-ui/core";

class EthernetOrderForm extends Component {
  static propTypes = {
    configuration: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
  };

  render() {
    const { configuration, index } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <SelectedProduct index={index} configuration={configuration} />
        </Grid>
        <Grid item sm={12}>
          <AdjustPrice index={index} />
        </Grid>
        {configuration.type === ETHERNET_PRODUCT_PTP && (
          <>
            <Grid item sm={12}>
              <Typography variant="h3">Site A</Typography>
            </Grid>
            <Grid item sm={6}>
              <SiteInstallationAddress
                purchaseArgs={configuration.purchaseArgs}
                prefix="site_a"
              />
            </Grid>
            <Grid item sm={12}>
              <SiteContact prefix="site_a" index={this.props.index} />
            </Grid>
            <Grid item sm={12}>
              <Location prefix="site_a" index={this.props.index} />
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h3">Site B</Typography>
            </Grid>
          </>
        )}

        <Grid item sm={6}>
          <SiteInstallationAddress
            purchaseArgs={configuration.purchaseArgs}
            prefix="site_b"
          />
        </Grid>
        <Grid item sm={6}>
          <CustomerRequiredDate index={this.props.index} />
        </Grid>
        <Grid item sm={12}>
          <SiteContact prefix="site_b" index={this.props.index} />
          <Location prefix="site_b" index={this.props.index} />
          {configuration.type === ETHERNET_PRODUCT_MANAGED && (
            <Voice
              purchaseArgs={configuration.purchaseArgs}
              index={this.props.index}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default EthernetOrderForm;
