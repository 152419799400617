import React from "react";
import { connect } from "react-redux";
import { StatusChip } from "@akj-dev/design-system";

const EthernetMessage = ({ orderId }) => {
  return (
    <StatusChip
      type="info"
      title="If your order requires a manual pricing change or it includes a replacement circuit, please do not ‘Generate Contract’"
      message={
        <span>
          Please email the order number {orderId} to{" "}
          <a href="mailto:TelcoCommercial@daisygroup.com">
            TelcoCommercial@daisygroup.com
          </a>
        </span>
      }
    />
  );
};

const mapStateToProps = state => ({
  orderId: state.order.id
});

export default connect(mapStateToProps)(EthernetMessage);
