import React from "react";
import PropTypes from "prop-types";
import ErrorIcon from "@material-ui/icons/Error";
import ArrowIcon from "@material-ui/icons/KeyboardArrowRight";
import { CheckIcon } from "@akj-dev/design-system";

const ConfigStatus = ({ isValid, onClick }) => {
  return (
    <div className="ConfigurationStatus">
      {isValid ? (
        <CheckIcon />
      ) : (
        <div className="false" onClick={onClick}>
          <ErrorIcon color="error" />
          <ArrowIcon color="error" />
        </div>
      )}
    </div>
  );
};

ConfigStatus.propTypes = {
  isValid: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

export default ConfigStatus;
