import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";
import ChangeIcon from "./ChangeIcon";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Outer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Boolean = ({
  dynamicProperty,
  setProperty,
  propertyValue,
  showWlrChangeIcon,
  recalculatePrices,
  validateConfig,
  disabled
}) => {
  if (propertyValue === "0") propertyValue = 0; // Yes... That actually comes back from the API. Oh dear...

  return (
    <Outer>
      <FormControlLabel
        label={
          <span>
            {`${dynamicProperty.label}${
              dynamicProperty.is_required ? " *" : ""
            }`}
            {showWlrChangeIcon && <ChangeIcon style={{ marginBottom: -8 }} />}
          </span>
        }
        control={
          <Checkbox
            onChange={(e, isInputChecked) => {
              setProperty(isInputChecked ? 1 : 0);
              recalculatePrices();
              validateConfig();
            }}
            checked={!!propertyValue}
            disabled={disabled}
          />
        }
      />
    </Outer>
  );
};

Boolean.propTypes = {
  dynamicProperty: PropTypes.object.isRequired,
  propertyValue: PropTypes.oneOf([0, 1, "0", "1"])
};

export default Boolean;
