import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { updateETFMethod } from "../../store/order/actions";
import { StatusChip } from "@akj-dev/design-system";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

class ETFMethod extends Component {
  state = {};

  render() {
    const { fetching, orderStatus, updateETFMethod } = this.props;
    if (fetching)
      return (
        <StatusChip
          type="loading"
          title="Updating ETF Method."
          message="Please wait..."
        />
      );

    const etfMethod = _.get(orderStatus, "data.etf_method", null);
    const validETFMethods = _.get(orderStatus, "data.valid_etf_methods", []);

    return (
      <div>
        <FormControl fullWidth>
          <InputLabel>Choose an ETF Method</InputLabel>
          <Select value={etfMethod} onChange={updateETFMethod}>
            {validETFMethods.map(m => (
              <MenuItem key={m} value={m}>
                {_.capitalize(m)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    fetching: state.order.orderStatus.fetching,
    orderStatus: state.order.orderStatus.response
  };
};

export default connect(mapStateToProps, {
  updateETFMethod
})(ETFMethod);
