import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { setProductTypes } from "../../../store/uiState/actions";
import { useGetProductTypeCards } from "../hooks";
import { ReduxAccountSettingsEnum } from "../../enums";
import { DataCardCheckbox } from "../../shared/DataCardCheckbox";
import { Info } from "./Info";
import { getAccountSettings } from "../../../store/account/selectors";
import { AccountSettings } from "@akj-dev/affinity-platform";
import { DwsTerms } from "./DwsTerms";

export type ReduxChooseProductTypesType = {
  productTypes: ReduxAccountSettingsEnum[];
};

export const ChooseProductTypes = () => {
  const productTypeCards = useGetProductTypeCards();
  const dispatch = useDispatch();
  const { productTypes } = useSelector<any, ReduxChooseProductTypesType>(
    state => ({
      productTypes: state.uiState.productTypes ?? []
    })
  );
  const settings: AccountSettings = useSelector(getAccountSettings);

  const productTypesObject: { [k in ReduxAccountSettingsEnum]?: boolean } = {};
  productTypes.forEach(pt => (productTypesObject[pt] = true));

  const showDwsTerms =
    settings.dws_reseller_enabled === "1" &&
    productTypes.includes(ReduxAccountSettingsEnum.MOBILE);

  const handleCheckProductType = (id: ReduxAccountSettingsEnum) => () => {
    const newProductTypeObject = { ...productTypesObject };
    newProductTypeObject[id] = !newProductTypeObject[id];

    dispatch(
      setProductTypes(
        productTypeCards
          .map(card => card.id)
          .filter(id => !!newProductTypeObject[id])
      )
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        {productTypeCards.map(({ id, label, dataCy, Icon }) => (
          <Grid key={label} item xs={12} sm={6} md={4} lg={3}>
            <DataCardCheckbox
              title={label}
              checked={!!productTypesObject[id]}
              handleClick={handleCheckProductType(id)}
              dataCy={dataCy}
              Icon={Icon}
            />
          </Grid>
        ))}
      </Grid>
      <Info />
      {showDwsTerms && <DwsTerms />}
    </>
  );
};
