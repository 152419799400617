import React from "react";
import MPFNoProductChangesSubRow from "./MPFNoProductChangesSubRow";
import MPFProductChangesSubRow from "./MPFProductChangesSubRow";
import { connect } from "react-redux";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES
} from "../../../../../store/wlrBroadband/constants";
import { BroadbandProductSelector } from "../BroadbandProductSelector";
import {
  getIsMultiLineProduct,
  getProducts
} from "../../../../../store/wlrBroadband/selectors";

const MPFBroadbandProductOverview = ({
  configuration,
  productInstance,
  removeResign,
  setChanges,
  isMultiLineProduct,
  allProducts
}) => {
  const resignProduct = allProducts.find(
    product => product.id === configuration.broadbandProductId
  );
  return (
    <>
      {configuration.broadbandProductId && resignProduct ? (
        <MPFProductChangesSubRow
          productInstance={productInstance}
          resignProduct={resignProduct}
          removeResign={removeResign}
          productInstanceType="broadband"
        />
      ) : (
        <MPFNoProductChangesSubRow
          productInstance={productInstance}
          configuration={configuration}
          onClick={setChanges}
          removeResign={removeResign}
          productInstanceId={configuration.broadbandProductId}
          productInstanceType="broadband"
          disabled={!configuration.wlrProductId || isMultiLineProduct}
        />
      )}
      {(configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES ||
        configuration.resignProductType === NEW_PRODUCT) &&
        configuration.wlrProductId &&
        !configuration.broadbandProductId && (
          <BroadbandProductSelector
            configuration={configuration}
            productInstance={productInstance}
          />
        )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const locationIndex = ownProps.configuration.locationIndex;
  return {
    allProducts: getProducts(state, locationIndex),
    isMultiLineProduct: getIsMultiLineProduct(
      state,
      ownProps.configuration.wlrProductId
    )
  };
};

export default connect(mapStateToProps)(MPFBroadbandProductOverview);
