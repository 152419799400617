import React from "react";
import { connect } from "react-redux";
import { getETFPrice } from "../../../store/mobile/selectors/resigns";
// import { StatusChip } from "@akj-dev/design-system";

const ETFPrice = ({ etfPrice }) => {
  if (!etfPrice || etfPrice <= 0) return false;

  // Temporarily disable as the figure's wrong. Requested by @joannes
  return <div />;
  // return (
  //   <StatusChip
  //     type="info"
  //     title={`Early Termination Fee: ${etfPrice.toFixed(2)}`}
  //     message={
  //       <ul style={{ paddingLeft: 20 }}>
  //         <li>
  //           If you are resigning without hardware prior to the end of the
  //           initial term you should increase the contract length.
  //         </li>
  //         <li>
  //           If you are resigning with hardware prior to the end of the initial
  //           term ETF's will apply. This can be paid upfront on the next invoice
  //           or spread as a cost over the new contract term.
  //         </li>
  //       </ul>
  //     }
  //   />
  // );
};

const mapStateToProps = (state, ownProps) => {
  return {
    etfPrice: getETFPrice(state)
  };
};

export default connect(mapStateToProps)(ETFPrice);
