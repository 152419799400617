import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { requestAllContacts } from "../../../../../../store/account/actions";
import { updateConfigProperty } from "../../../../../../store/mobile/actionCreators";
import { getAccountId } from "../../../../../../store/order/selectors";
import { getAccountSettings } from "../../../../../../store/account/selectors";
import Section from "../../../../../../components/Shared/Section";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import BulkDynamicField from "../../BulkDynamicField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { StatusChip } from "@akj-dev/design-system";
import { ResellerBillingNotification } from "./ResellerBillingNotification";
import { StandardBillingNotification } from "./StandardBillingNotification";

/**
 * targetConfigs
 *
 *
 * @param props
 * @constructor
 */

class BillLimits extends React.Component {
  static propTypes = {
    targetConfigs: PropTypes.arrayOf(PropTypes.number).isRequired
  };

  componentDidMount() {
    // Get contacts... unless we're creating a new account, which wouldn't have any.
    const { accountId, hasContacts, requestAllContacts } = this.props;
    if (accountId && !hasContacts) {
      requestAllContacts();
    }
  }

  render() {
    const { bill_limit, settings } = this.props;

    return (
      <Section title="Bill Limits" avatar={<MonetizationOnIcon />}>
        <BulkDynamicField
          propertyName="bill_limit"
          InputProps={{
            startAdornment: <InputAdornment position="start">£</InputAdornment>
          }}
        />
        <StatusChip
          type="info"
          message={
            <span>
              {settings.dws_reseller_enabled === "1"
                ? "This is your bill limit with your supplier, manage your limits appropriately"
                : "Notifications for this Bill Limit will be sent to the end user by SMS."}{" "}
              {bill_limit !== "" &&
              bill_limit == 0 && ( // eslint-disable-line eqeqeq
                  <b>
                    Note: You are adding a zero bill limit. This means the
                    customer won't be able to spend beyond their included
                    allowance.
                  </b>
                )}
            </span>
          }
        />
        {settings.dws_reseller_enabled === "1" ? (
          <ResellerBillingNotification
            targetConfigs={this.props.targetConfigs}
          />
        ) : (
          <StandardBillingNotification
            targetConfigs={this.props.targetConfigs}
          />
        )}
      </Section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  bill_limit: _.get(
    state.mobile.configs[ownProps.targetConfigs[0]],
    "properties.bill_limit"
  ),
  contacts: state.account.contacts,
  accountId: getAccountId(state),
  settings: getAccountSettings(state)
});

export default connect(mapStateToProps, {
  updateConfigProperty,
  requestAllContacts
})(BillLimits);
