import _ from "lodash";
import * as actionTypes from "./actionTypes";
import { hardwareProducts } from "../selectors";
import * as OrdersProductAPI from "../../../api/v1/orderProduct";

/**
 * Make OrderProduct calls for hardware.
 * @param retry {boolean}
 * @returns {Function}
 */
export const orderHardware = retry => async (dispatch, getState) => {
  const products = hardwareProducts(getState());

  await Promise.all(
    products.map(async (p, i) => {
      const state = getState();

      // Don't send two calls at once.
      // Although tbh this shouldn't happen anyway as getOrderProductCallsFetching() stops nav in the UI.
      if (_.get(state.hardwareOrders, `${p.identifier}.fetching`)) return;

      // Check if the product's already in the order. (If it is, update instead of create)
      const orderProductId = _.get(
        state.hardwareOrders,
        `${p.identifier}.response.data.id`
      );

      // If we're just retrying, skip if there's an order product ID
      if (retry && orderProductId) return;

      const shouldUpdate = !!orderProductId;

      dispatch({
        type: actionTypes.REQUEST_ORDER_PRODUCT,
        identifier: p.identifier,
        isUpdate: shouldUpdate
      });

      const params = {
        ...p.params,
        account_id: state.order.accountId,
        order_id: state.order.id
      };

      const response = shouldUpdate
        ? await OrdersProductAPI.update(orderProductId, params)
        : await OrdersProductAPI.create(p.id, params);

      dispatch({
        type: actionTypes.RECEIVE_ORDER_PRODUCT,
        identifier: p.identifier,
        response
      });
    })
  );
};
