import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { IndexedCardInfo } from "../../shared/indexed-card-info";
import { ChooseHardwareTable } from "./ChooseHardwareTable";

export const ChooseHardware = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <IndexedCardInfo index={1} title={"Please select required hardware"}>
        <ChooseHardwareTable />
      </IndexedCardInfo>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  }
});
