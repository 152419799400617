import _ from "lodash";

/**
 * Pull pricing data from a product data response
 * We'll already have the rest of it stored in mobile.productData
 * Hence this keeps the size of the store down.
 * @param response
 * @returns {object}
 */
export function transformMobilePricingData(response) {
  return {
    status: response.status,
    message: response.message,
    mobile: {
      component_price: _.get(response, "mobile.component_price")
    },
    // do we need this too? (CLI bolt-ons?)
    overall_price: response.overall_price
  };
}
