import * as actionTypes from "./actionTypes";
import { trackSimpleEvent } from "../../helpers/reduxBeacon";
import { trackEvent } from "@redux-beacon/google-analytics";

const gaEventsMap = {
  ...trackSimpleEvent(actionTypes.TOGGLE_STEP_ONE_MOBILE),
  ...trackSimpleEvent(actionTypes.TOGGLE_STEP_ONE_HARDWARE),
  ...trackSimpleEvent(actionTypes.TOGGLE_STEP_ONE_WLR_BROADBAND),
  ...trackSimpleEvent(actionTypes.TOGGLE_STEP_ONE_UNIVERSAL_PRODUCTS),
  ...trackSimpleEvent(actionTypes.TOGGLE_STEP_ONE_MONITORING_SERVICE),
  ...trackSimpleEvent(actionTypes.TOGGLE_STEP_ONE_ETHERNET),
  [actionTypes.SET_ACTIVE_STEP]: trackEvent(
    action => ({
      category: "uiState",
      action: actionTypes.SET_ACTIVE_STEP,
      value: action.step
    }),
    "gtm1"
  )
};

export default gaEventsMap;
