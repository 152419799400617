import React from "react";
import { Box, Card, Theme, makeStyles } from "@material-ui/core";
import { Basket } from "./basket";
import { Stepper } from "./stepper";
import { CreditVetStatus } from "./credit-vet-status";
import { useSelector } from "react-redux";
import {
  getAccountSettings,
  isCreditVetProcessAttempted
} from "../../../store/account/selectors";

export function Header() {
  const classes = useStyles();
  const isVfDirect =
    useSelector(getAccountSettings).can_access_vf_direct === "1";

  const creditVetProcessAttempted = useSelector(isCreditVetProcessAttempted);
  return (
    <Box className={classes.wrapper}>
      <Card className={classes.root}>
        <Stepper />
        <Basket />
      </Card>
      {isVfDirect && creditVetProcessAttempted && <CreditVetStatus />}
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "sticky",
    zIndex: 10,
    top: 0,

    // custom width 100 % because of LayoutContainers.tsx
    width: "calc(100% + 80px)",
    marginLeft: "-32px"
  },
  root: {
    display: "flex",
    transform: "translateZ(0px)", // this creates a seperate layer for <Card/> so the box shadow is not cut off by <CreditVet/>
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2.5),
    borderRadius: 0
  }
}));
