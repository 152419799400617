import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  RESIGN_WITH_CHANGES,
  RESIGN_WITHOUT_CHANGES
} from "../../../store/mobile/constants";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import InputIcon from "@material-ui/icons/Input";
import { useTheme } from "@material-ui/core/styles";

const Outer = styled.div`
  display: flex;
  align-items: center;
`;
const ConnectionType = ({ type }) => {
  const theme = useTheme();
  switch (type) {
    case RESIGN_WITH_CHANGES:
      return (
        <Outer>
          <SettingsBackupRestoreIcon htmlColor={theme.palette.success.main} />
          Resign with changes
        </Outer>
      );
    case RESIGN_WITHOUT_CHANGES:
      return (
        <Outer>
          <AutorenewIcon htmlColor={theme.palette.info.main} />
          Resign without changes
        </Outer>
      );
    case "new": // Dynamic property value
      return (
        <Outer>
          <AddCircleOutlineIcon htmlColor={theme.palette.primary.main} />
          New
        </Outer>
      );
    case "port/mig": // Dynamic property value
      return (
        <Outer>
          <InputIcon htmlColor={theme.palette.primary.main} />
          Port / migration
        </Outer>
      );
    default:
      return (
        <Outer>
          Unknown acquisition method: <b>{type}</b>
        </Outer>
      );
  }
};

ConnectionType.propTypes = {
  type: PropTypes.string.isRequired
};

export default ConnectionType;
