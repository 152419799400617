import { connect } from "react-redux";
import Mobile from "./Mobile";
import Hardware from "./Hardware";
import { getHardwareCount } from "../../store/hardware/selectors";
import WlrBroadband from "./wlrBroadband";
import Ethernet from "./Ethernet/index";
import UniversalProducts from "./UniversalProducts/index";
import MonitoringService from "./MonitoringService/index";
import React from "react";
import { IndexedCardInfo } from "../../screens/shared/indexed-card-info";
import { getHostEnv } from "../../store/order/selectors/getHostEnv";

class StepCustomise extends React.Component {
  render() {
    const {
      mobileOpen,
      hardwareOpen,
      wlrBroadbandOpen,
      ethernetOpen,
      universalProductsOpen,
      monitoringServiceProductsOpen,
      hostEnv
    } = this.props;

    let indexNumber = 0;

    return (
      <div>
        {hostEnv !== "my_account" && mobileOpen && (
          <IndexedCardInfo index={++indexNumber} title="Mobile">
            <Mobile />
          </IndexedCardInfo>
        )}

        {hardwareOpen && (
          <IndexedCardInfo index={++indexNumber} title="Hardware">
            <Hardware />
          </IndexedCardInfo>
        )}

        {hostEnv !== "my_account" && wlrBroadbandOpen && (
          <IndexedCardInfo
            index={++indexNumber}
            title="Lines, Calls & Broadband"
          >
            <WlrBroadband />
          </IndexedCardInfo>
        )}

        {hostEnv !== "my_account" && ethernetOpen && (
          <IndexedCardInfo index={++indexNumber} title="Ethernet">
            <Ethernet />
          </IndexedCardInfo>
        )}

        {hostEnv !== "my_account" && universalProductsOpen && (
          <IndexedCardInfo index={++indexNumber} title="Other Products">
            <UniversalProducts />
          </IndexedCardInfo>
        )}

        {hostEnv !== "my_account" && monitoringServiceProductsOpen && (
          <IndexedCardInfo
            index={++indexNumber}
            title="Monitoring as a Service"
          >
            <MonitoringService />
          </IndexedCardInfo>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    mobileOpen: state.mobile.configs.length > 0,
    hardwareOpen: getHardwareCount(state) > 0,
    wlrBroadbandOpen: state.wlrBroadband.configurations.length > 0,
    ethernetOpen: state.ethernetProducts.configurations.length > 0,
    universalProductsOpen: state.universalProducts.configs.length > 0,
    monitoringServiceProductsOpen: state.monitoringService.configs.length > 0,
    hostEnv: getHostEnv(state)
  };
};

export default connect(mapStateToProps)(StepCustomise);
