import { createSelector } from "reselect";

/**
 * Get the total number of configurations for a certain product ID
 * Used to show totals on step 1 table
 * @param configs
 * @param productId
 */
export function getProductConfigCount(configs, productId) {
  let count = 0;
  configs.forEach(c => {
    if (c.productId === productId) count++;
  });
  return count;
}

/**
 * Get the currently selected config
 * @param state
 * @returns {*}
 */
export const getSelectedConfig = state =>
  state.universalProducts.configs[state.universalProducts.selectedConfigIndex];

/**
 * Get product name for a config index
 * Note this is memoized as it's doing more than simple object prop selection.
 * ...although tbh I'm not 100% sure it's worth it as it still re-computes for every table row.
 * @returns {Reselect.Selector<any, any>}
 * */
export const makeGetConfigProductName = () =>
  createSelector(
    [
      (state, props) =>
        state.universalProducts.configs[props.configIndex].productId,
      state => state.universalProducts.productSearch.response
    ],
    (productId, productSearch) => {
      if (!Array.isArray(productSearch.products)) return false;
      return productSearch.products.find(p => p.id === productId).name;
    }
  );

/**
 * Get list of documents associated with a config's product ID
 * @param state
 * @param configIndex
 * @returns {*}
 */
export const getConfigDocuments = (state, configIndex = false) => {
  if (configIndex === false)
    configIndex = state.universalProducts.selectedConfigIndex;
  const productId = state.universalProducts.configs[configIndex].productId;
  return state.universalProducts.productDocuments[productId];
};

/**
 * Test if universal product configs are valid. Prices much be entered for each, even if they're 0.
 * @param config
 * @returns {boolean}
 */
export const getIsUniversalProductConfigValid = config =>
  config.oneOffPrice !== "" && config.recurringPrice !== "";

/**
 * Test if universal product has at least one document uploaded.
 * @param config
 * @returns {boolean}
 */
export const getDoesUniversalProductHaveDocuments = config =>
  config.userDocuments.length > 0;
