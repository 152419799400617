import _ from "lodash";
import queryString from "query-string";
import { globalAxiosInstance } from "../../Cpq";
import { AxiosRequestConfig } from "axios";

/**
 * Base fetch function
 * TODO: Look at refactoring the whole API layer to make better use of Axios. Diff for CPQ split already huge atm though
 */
async function apiFetch(
  url: string,
  config: AxiosRequestConfig = {},
  catchJsonErrors = true
) {
  let response;
  // Run the fetch and capture general network errors
  try {
    response = await globalAxiosInstance({
      url: `ExternalServices/v1/${url.replace(/^\/+/g, "")}`,
      ...config
    });
  } catch (e) {
    throw new Error(`DC Request to ${url.split("?")[0]} failed. ${e.message}`);
    // TODO: These will replicate error messages as they're also thrown in addAlertMessage most (all?) of the time
    // when getJSON & postJSON aren't used. Need to decide which way we're going.
  }

  // Capture error codes from the server (although these will be rare as errors normally come back as 200 anyway)
  if (response.status !== 200) {
    throw new Error(
      `DC Request to ${url.split("?")[0]} failed, HTTP status ${
        response.status
      }`
    );
  }

  // Parse JSON and capture errors when the API doesn't return JSON at all
  const data = response.data;
  if (!(data instanceof Object)) {
    throw new Error(
      `DC Request to ${url.split("?")[0]} failed. Response is not valid JSON`
    );
  }

  // Capture error codes in the API's JSON response (eg. { status: 'FAILED_REQUEST', message: '.....' }
  //
  // In some circumstances, un-successful calls shouldn't be caught as errors, hence the catchJsonErrors var.
  // Such as:
  // - status: 'pending' for ethernet PricingRequest
  // - status: 'error' for WLR3/Validate
  // - status: 'error' for PlatformSettings
  if (catchJsonErrors && data.status !== "success" && data.status !== "ok") {
    let message;
    if (typeof data.message === "object") {
      // Some calls (like partially failed OrderProduct/Create) can have weird structures for error messages...
      // This is because a DC sub component is failing I think.
      // Update: It appears to be even weirder now. See src/js/store/wlrBroadband/reducer/actions/configurations.js RECEIVE_ORDER_PRODUCT
      const arrayMessage = _.get(data, "message[0].message");
      if (arrayMessage) {
        message = arrayMessage;
      } else {
        message = JSON.stringify(data.message, null, 2);
      }
    } else {
      message = data.message;
    }
    throw new Error(`DC Request to ${url} failed. Message: ${message}.`);
  }

  return data;
}

/**
 * Convert boolean values to 1 & 0 for Perl API and QueryString compatibility.
 * Without this, false ends up in DC as string "false", which evaluates to true, unlike string "0"
 * Also strips null values, which I have a feeling could mess things up similarly.
 * Option for removing false values entirely is used by ethernet, so things like CLI don't end up as "0"
 * ...should probably just change defaults to null...
 *
 * @param obj
 * @param removeFalseValues
 */
export function convertBoolValues(
  obj: Record<string | number, any>,
  removeFalseValues = false
) {
  const result: Record<string | number, any> = {};
  _.forOwn(obj, (value, key) => {
    switch (value) {
      case true:
        result[key] = 1;
        break;
      case false:
        if (!removeFalseValues) result[key] = 0;
        break;
      case null:
        break;
      case undefined:
        break;
      default:
        result[key] = value;
    }
  });
  return result;
}

/**
 * Stringify an object to use in API requests.
 *
 * @param params (Object)
 * @param removeFalseValues (Bool)
 */
function requestBody(params: object, removeFalseValues = false) {
  return queryString.stringify(convertBoolValues(params, removeFalseValues));
}

export type GeneralAPIError = {
  status: "error";
  message: string;
};

export function isAPIError(
  response: any | GeneralAPIError
): response is GeneralAPIError {
  return (response as any).status === "error";
}

/**
 * Perform a JSON GET API request
 */
export async function getJSON<P>(
  url: string,
  params?: object,
  catchJsonErrors = true,
  removeFalseValues = false
): Promise<P | GeneralAPIError> {
  if (params) {
    url = `${url}?${requestBody(params, removeFalseValues)}`;
  }
  try {
    return await apiFetch(url, { method: "GET" }, catchJsonErrors);
  } catch (e) {
    console.error(e);
    return {
      status: "error",
      message: e.message
    };
  }
}

/**
 * Perform a JSON POST API request
 */
export async function postJSON<P>(
  url: any,
  body: object = {},
  catchJsonErrors = true,
  removeFalseValues = false,
  sendBodyAsFormData = true
): Promise<P | GeneralAPIError> {
  try {
    return await apiFetch(
      url,
      {
        method: "POST",
        headers: {
          "Content-type": sendBodyAsFormData
            ? "application/x-www-form-urlencoded; charset=UTF-8"
            : "application/json"
        },
        // TODO: sendBodyAsFormData really the same effect as multiPartPostJSON? FormData is probably better
        data: sendBodyAsFormData ? requestBody(body, removeFalseValues) : body
      },
      catchJsonErrors
    );
  } catch (e) {
    console.error(e);
    return {
      status: "error",
      message: e.message
    };
  }
}

/**
 * multipart/form-data POST
 * When files need to be sent (eg. contracts), multipart encoding is needed as oppose to urlencoded.
 * See https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
 *
 * @param url
 * @param bodyParams
 * @returns {Promise<*>}
 */
export async function multiPartPostJSON(
  url: string,
  bodyParams: Record<string, string>
) {
  let data = new FormData();
  for (let prop in bodyParams) {
    data.append(prop, bodyParams[prop]);
  }

  try {
    return await apiFetch(
      url,
      {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data
      },
      false
    );
  } catch (e) {
    return {
      status: "error",
      message: e.message
    };
  }
}
