import React from "react";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    whiteSpace: "nowrap"
  }
});

/**
 * Cell for end of table rows.
 * Should contain <IconButton>s
 * @param props
 * @returns {*}
 * @constructor
 */
export default function TableActionsCell(props: TableCellProps) {
  const classes = useStyles();
  return (
    <TableCell
      align="right"
      padding="none"
      className={classes.root}
      {...props}
    />
  );
}
