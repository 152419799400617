import * as constants from "./constants";

/**
 * Get the hardware delivery address parameters for an OrderProduct call
 * based on user selection in delivery details page.
 * @param state
 * @returns {*}
 */
export function getDeliveryParams(state) {
  switch (state.hardwareDelivery.addressType) {
    case constants.BILLING:
      return {
        use_billing_address: 1
      };

    case constants.EXISTING:
      return prefixAddress(
        state.account.accountAddresses.response.data.addresses[
          state.hardwareDelivery.accountAddressIndex
        ]
      );

    case constants.NEW:
      return prefixAddress(state.hardwareDelivery.newAddress);

    default:
      return {};
  }
}

/**
 * Add "site_address_" prefix to fields
 * @param address
 */
function prefixAddress(address) {
  let siteAddress = {};
  Object.keys(address).forEach(
    k => (siteAddress[`site_address_${k}`] = address[k])
  );
  return siteAddress;
}
