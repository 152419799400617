import React from "react";
import Icon from "@material-ui/icons/Redeem";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";

const CallBundlesWlrChange = props => {
  return (
    <Section
      title="Call Bundles (Existing Line)"
      avatar={<Icon />}
      defaultExpanded={false}
    >
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.fixed_line_call_bundle_id"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.mobile_call_bundle_id"
      />
    </Section>
  );
};

export default CallBundlesWlrChange;
