import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { StatusChip } from "@akj-dev/design-system";
import Spinner from "../../../../../../components/Shared/Spinner";
import {
  doAddWlrAppointment,
  getWlrAppointments,
  setWlrAppointment
} from "../../../../../../store/wlrBroadband/actions";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { getIsSalesPerson } from "../../../../../../store/account/selectors";

class ChooseWlrAppointment extends Component {
  static defaultProps = {};
  static propTypes = {
    targetConfig: PropTypes.number.isRequired
  };

  state = {};

  componentDidMount() {
    if (!_.get(this.props.appointments.response, "appointments", []).length) {
      this.props.getWlrAppointments(this.props.targetConfig);
    }
  }

  render() {
    const {
      targetConfig,
      appointments,
      getWlrAppointments,
      setWlrAppointment,
      doAddWlrAppointment,
      addWlrAppointment,
      isSalesPerson
    } = this.props;

    if (appointments.fetching)
      return (
        <StatusChip
          type="loading"
          title="Please Wait"
          message="Loading WLR Appointments"
        />
      );

    if (_.get(appointments.response, "status") === "error")
      return (
        <StatusChip
          type="error"
          title="Sorry"
          message="Something went wrong fetching WLR appointments."
          retry={() => getWlrAppointments(targetConfig)}
        />
      );

    return (
      <div>
        <FormControl fullWidth margin="normal">
          <InputLabel>Choose your WLR Appointment time</InputLabel>
          <Select
            onChange={event =>
              setWlrAppointment(targetConfig, event.target.value, isSalesPerson)
            }
            value={appointments.selectedIndex}
            style={{ width: "100%" }}
            disabled={
              addWlrAppointment.fetching ||
              addWlrAppointment.response.status === "success"
            }
            data-cy="wlrAppointmentSelect"
          >
            <MenuItem value={null} />
            {appointments.response.appointments.map((a, i) => (
              <MenuItem value={i} key={i}>
                {a.string}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {appointments.selectedIndex !== null && (
          <p>
            This appointment date is only reserved for 4 hours. If the contract
            is signed after 4 hour of receipt the next available appointment
            will be given.
          </p>
        )}
        {// TP32603: If the user is a sales person, then actual reserving of
        // the appointment will take place later, after account creation and
        // just before ordering of the products.
        !isSalesPerson && (
          <Button
            variant="contained"
            color="secondary"
            endIcon={
              addWlrAppointment.fetching && <Spinner size={30} color="#aaa" />
            }
            onClick={() => doAddWlrAppointment(targetConfig)}
            disabled={
              addWlrAppointment.fetching ||
              addWlrAppointment.response.status === "success" ||
              appointments.selectedIndex === null
            }
            data-cy="reserveWlrAppointment"
          >
            Reserve WLR Appointment
          </Button>
        )}
        {addWlrAppointment.response.status === "success" && (
          <StatusChip
            type="success"
            title="Appointment Reserved"
            message={`Reference: ${addWlrAppointment.response.appointment_reference}`}
            style={{ marginTop: 20 }}
          />
        )}
        {addWlrAppointment.response.status === "error" && (
          <StatusChip
            type="error"
            title="Sorry"
            message="Something went wrong reserving your WLR appointment."
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    appointments:
      state.wlrBroadband.configurations[ownProps.targetConfig].wlrAppointments,
    addWlrAppointment:
      state.wlrBroadband.configurations[ownProps.targetConfig]
        .addWlrAppointment,
    isSalesPerson: getIsSalesPerson(state)
  };
};

export default connect(mapStateToProps, {
  getWlrAppointments,
  setWlrAppointment,
  doAddWlrAppointment
})(ChooseWlrAppointment);
