import React from "react";
import {
  Badge,
  IconButton,
  Popover,
  makeStyles,
  Theme
} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { SaleSummaryType } from "../types";
import { SaleSummary } from "./SaleSummary";

export interface BasketButtonProps extends SaleSummaryType {}

export const BasketButton = ({
  data,
  totalCount,
  totalOneOffPrice,
  totalRecurringPrice
}: BasketButtonProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        classes={{ root: classes.button }}
        color="secondary"
        disabled={true}
        // onClick={handleClick}
      >
        <Badge badgeContent={totalCount} color="error">
          <ShoppingCartIcon color="disabled" />
        </Badge>
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <SaleSummary
          data={data}
          totalRecurringPrice={totalRecurringPrice}
          totalOneOffPrice={totalOneOffPrice}
        />
      </Popover>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: 0,
    paddingLeft: theme.spacing(0.5)
  }
}));
