export enum SaleStatusEnum {
  PROSPECTS = "lead",
  OPPORTUNITIES = "draft",
  APPROVALS = "approval",
  SUPPLIER_APPROVALS = "internal_approval",
  SENT = "sent",
  VIEWED = "viewed",
  COMPLETED = "completed",
  EXPIRED = "expired",
  DECLINED = "declined",
  CANCELLED = "cancelled",
}
export enum SaleStatusLabelEnum {
  "lead" = "prospects",
  "draft" = "opportunities",
  "approval" = "approvals",
  "internal_approval" = "supplier approvals",
  "sent" = "sent",
  "viewed" = "viewed",
  "completed" = "completed",
  "expired" = "expired",
  "declined" = "declined",
  "cancelled" = "deleted",
}
