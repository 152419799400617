import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  requestReservedNumbersList,
  updateConfigProperty
} from "../../../../../../store/mobile/actionCreators";
import { StatusChip } from "@akj-dev/design-system";
import { getProductDataForConfig } from "../../../../../../store/mobile/selectors/productConfig";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

class ReservedNumbers extends React.Component {
  static propTypes = {
    targetConfigs: PropTypes.arrayOf(PropTypes.number).isRequired
  };
  state = {};

  componentDidMount() {
    const { fetching, response } = this.props.reservedNumbersSearch;
    if (!(fetching || response?.status === "ok")) {
      this.props.requestReservedNumbersList(this.props.newNetwork);
    }
  }

  render() {
    const {
      requestReservedNumbersList,
      reservedNumbersSearch,
      updateConfigProperty,
      newNetwork,
      targetConfigs,
      mobile_number
    } = this.props;

    if (targetConfigs.length > 1) return false;

    if (reservedNumbersSearch.fetching) {
      return (
        <StatusChip
          type="loading"
          title="Please wait"
          message="Loading reserved numbers"
        />
      );
    }

    if (_.get(reservedNumbersSearch.response, "status") === "error") {
      return (
        <StatusChip
          type="error"
          title="Error"
          message="Something went wrong finding reserved numbers."
          retry={() => requestReservedNumbersList(newNetwork)}
        />
      );
    }

    return (
      <FormControl fullWidth margin="normal">
        <InputLabel>Mobile Number</InputLabel>
        <Select
          name="mobile_number"
          value={mobile_number || "0"}
          onChange={event =>
            updateConfigProperty(
              "mobile_number",
              event.target.value === "0" ? "" : event.target.value,
              targetConfigs
            )
          }
        >
          <MenuItem value="0">Assign from available numbers</MenuItem>
          {_.map(
            _.get(reservedNumbersSearch, "response.result", []),
            number => (
              <MenuItem value={number} key={number}>
                {number}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const firstConfig = state.mobile.configs[ownProps.targetConfigs[0]];
  const productDataForConfig = getProductDataForConfig(
    state,
    ownProps.targetConfigs[0]
  );
  const newNetwork = _.get(
    productDataForConfig,
    "mobile.product_component_data.supplier",
    false
  );

  return {
    newNetwork: newNetwork,
    reservedNumbersSearch: _.get(
      state,
      "mobile.reservedNumbersSearch." + newNetwork,
      {}
    ),
    mobile_number: firstConfig.properties.mobile_number
  };
};

export default connect(mapStateToProps, {
  requestReservedNumbersList,
  updateConfigProperty
})(ReservedNumbers);
