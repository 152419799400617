import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import {
  requestResignProduct,
  requestProductInstances,
  removeResign,
  setResignProduct,
  setResignType
} from "../../../../store/mobile/actionCreators";
import Checkbox from "@material-ui/core/Checkbox";
import ResignProductSelector from "./ResignProductSelector";
import {
  RESIGN_WITH_CHANGES,
  RESIGN_WITHOUT_CHANGES
} from "../../../../store/mobile/constants";
import RowActions from "./RowActions";
import SelectedChangesRow from "./SelectedChangesRow";
import NoChangesRow from "./NoChangesRow";
import { StatusChip } from "@akj-dev/design-system";
import ETFPrice from "../../../../containers/StepCustomise/Mobile/ETFPrice";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, InputAdornment, TextField, withStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { ResignsTableActions } from "./ResignsTableActions";
import { Loader } from "../../../shared/loader";

class Resigns extends React.Component {
  state = {
    selected: [],
    bulkSelectVisible: false,
    filter: ""
  };

  componentDidMount() {
    const {
      productInstances,
      requestProductInstances,
      resignProductSearch,
      requestResignProduct
    } = this.props;

    if (!productInstances.response.results) {
      requestProductInstances();
    }
    if (!resignProductSearch.response.products) {
      requestResignProduct();
    }
  }

  isChecked = id => this.state.selected.indexOf(id) > -1;
  allChecked = () =>
    this.state.selected.length ===
    _.get(this.props.productInstances.response, "results", []).length;

  toggleRow = id => {
    let { selected } = this.state;
    const i = selected.indexOf(id);
    if (i > -1) {
      selected.splice(i, 1);
    } else {
      selected.push(id);
    }
    this.setState({ selected });
  };

  toggleAllRows = () => {
    if (this.allChecked()) {
      this.setState({ selected: [] });
    } else {
      this.setState({
        selected: _.get(
          this.props.productInstances.response,
          "results",
          []
        ).map((line, i) => i)
      });
    }
  };

  deselectAll = () => this.setState({ selected: [] });

  showBulkSelect = () => this.setState({ bulkSelectVisible: true });
  hideBulkSelect = () =>
    this.setState({ bulkSelectVisible: false, selected: [] });

  setFilter = e => {
    this.setState({ filter: e.target.value });
  };

  doFilter = resign => {
    const term = this.state.filter;
    if (!term) return true;
    const match = e => e.toLowerCase().includes(term.toLowerCase());
    if (match(resign.pin) || match(resign.name) || match(resign.end_date_ymd))
      return true;
  };

  clearFilter = () => {
    this.setState({ filter: "" });
  };

  configCount = () => this.props.configs.filter(c => !!c.resignType).length;

  sortByNetwork = (products, network) => {
    if (!products) return products;
    return [...products]
      .filter(p => !p.first_mobile_component.is_data_only_voice)
      .sort(p => (p.first_mobile_component.supplier === network.name ? -1 : 1));
  };

  bulkSelectByCLI = (text, results) => {
    const array = text.split(/,|[, ]|\n/);
    let selected = [];
    results.forEach((item, index) => {
      const pin = item.pin;
      if (array.find(item => item === pin)) selected.push(index);
    });
    this.setState({ selected });
  };

  render() {
    const {
      mobileSearch,
      productInstances: {
        fetching,
        response: { status, message, results = [] }
      },
      configs,
      resignProductSearch,
      setResignType,
      setResignProduct,
      removeResign,
      requestProductInstances,
      requestResignProduct,
      classes
    } = this.props;
    const { selected, bulkSelectVisible, filter } = this.state;

    const selectedInstanceIds = selected.map(i => results[i].id);

    if (status === "error")
      return (
        <StatusChip
          type="error"
          title="Error"
          message={message}
          retry={requestProductInstances}
        />
      );

    if (fetching) {
      return <Loader />;
    }

    return (
      <>
        <Box className={classes.root}>
          <Box className={classes.searchBox}>
            <TextField
              data-cy={"resignTableSearch"}
              className={classes.search}
              variant="outlined"
              name="filter"
              value={filter}
              onChange={this.setFilter}
              placeholder={`Search ${results.length} Available Resigns`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <ResignsTableActions
            infoLabel={`${selected.length} Selected / ${results.length} Available`}
            disabled={selected.length < 2}
            doResignWithChanges={this.showBulkSelect}
            doResignWithoutChanges={() => {
              this.hideBulkSelect();
              setResignType(selectedInstanceIds, RESIGN_WITHOUT_CHANGES);
              this.setState({ selected: [] });
            }}
            doResignNone={() => {
              this.hideBulkSelect();
              removeResign(selectedInstanceIds);
            }}
            bulkSelectByCLI={text => this.bulkSelectByCLI(text, results)}
          />
        </Box>
        {bulkSelectVisible && (
          <ResignProductSelector
            fetching={mobileSearch.fetching}
            products={mobileSearch.response.products}
            resignProductId={false}
            setResignProduct={id => {
              setResignProduct(selectedInstanceIds, id);
              this.hideBulkSelect();
            }}
          />
        )}
        <Table size="small" data-cy="mobileResignsTable">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 30 }}>
                <Checkbox
                  onChange={this.toggleAllRows}
                  checked={this.allChecked()}
                />
              </TableCell>
              <TableCell>CLI</TableCell>
              <TableCell colSpan={2}>Product</TableCell>
              <TableCell>Bolt-ons / Selected Product</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell className="u-text-right" style={{ minWidth: 120 }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((productInstance, i) => {
              const config =
                configs.find(c => c.resignId === productInstance.id) || {};

              if (!this.doFilter(productInstance)) return false;

              if (
                config.resignType === RESIGN_WITH_CHANGES &&
                config.productId
              ) {
                return (
                  <SelectedChangesRow
                    key={i}
                    i={i}
                    isChecked={this.isChecked(i)}
                    toggleRow={() => this.toggleRow(i)}
                    productInstance={productInstance}
                    newProduct={mobileSearch.response.products.find(
                      p => p.id === config.productId
                    )}
                    actions={
                      <RowActions
                        config={config}
                        resetProduct={() => {
                          setResignProduct([productInstance.id], false);
                        }}
                      />
                    }
                  />
                );
              }
              return [
                <NoChangesRow
                  key={i}
                  i={i}
                  isChecked={this.isChecked(i)}
                  toggleRow={() => this.toggleRow(i)}
                  productInstance={productInstance}
                  actions={
                    <RowActions
                      config={config}
                      setChanges={() => {
                        this.deselectAll();
                        setResignType(
                          [productInstance.id],
                          RESIGN_WITH_CHANGES
                        );
                      }}
                      setNoChanges={() =>
                        setResignType(
                          [productInstance.id],
                          RESIGN_WITHOUT_CHANGES
                        )
                      }
                      resetType={() => removeResign([productInstance.id])}
                      disabled={selected.length > 1}
                    />
                  }
                />,
                config.resignType === RESIGN_WITH_CHANGES && (
                  <TableRow key={`${i}_sel`}>
                    <TableCell colSpan="8">
                      <ResignProductSelector
                        fetching={mobileSearch.fetching}
                        products={this.sortByNetwork(
                          mobileSearch.response.products,
                          productInstance.network
                        )}
                        setResignProduct={id =>
                          setResignProduct([productInstance.id], id)
                        }
                        resignProductId={config.productId}
                      />
                    </TableCell>
                  </TableRow>
                )
              ];
            })}
          </TableBody>
        </Table>
        {!resignProductSearch.fetching &&
          resignProductSearch.response.status === "error" && (
            <StatusChip
              type="error"
              title="Error finding resign product"
              message={resignProductSearch.response.message}
              retry={requestResignProduct}
            />
          )}
        {!resignProductSearch.fetching &&
          resignProductSearch.response.status === "success" &&
          resignProductSearch.response.products.length < 1 && (
            <StatusChip
              type="error"
              title="Error finding resign product"
              message="No products returned from Daisy Central."
              retry={requestResignProduct}
            />
          )}
        <ETFPrice />
      </>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  search: {
    minWidth: "400px",
    marginBottom: theme.spacing(1)
  }
});

const mapStateToProps = state => ({
  mobileSearch: state.mobile.mobileSearch,
  configs: state.mobile.configs,
  productInstances: state.mobile.productInstances,
  resignProductSearch: state.mobile.resignProductSearch
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    setResignType,
    setResignProduct,
    removeResign,
    requestProductInstances,
    requestResignProduct
  })(Resigns)
);
