import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { mapWLR3AddressToDCOrderProduct } from "../../../helpers/addresses";
import { setNewAccountField } from "../../../store/account/actions";
import AddressPicker from "../../../components/Shared/AddressPicker";
import { Grid } from "@material-ui/core";

export default function PostcodeLookup({ prefix, suffix = "" }) {
  const dispatch = useDispatch();
  const postcode = useSelector(
    state => state.account.newAccountFields[`${prefix}postcode${suffix}`]
  );

  const saveAddress = address => {
    const dcAddress = mapWLR3AddressToDCOrderProduct(address, prefix, suffix);
    Object.keys(dcAddress).forEach(field => {
      dispatch(setNewAccountField(field, dcAddress[field]));
    });
  };
  const setPostcode = value =>
    dispatch(setNewAccountField(`${prefix}postcode${suffix}`, value));

  return (
    <Grid item sm={12}>
      <AddressPicker
        saveAddress={saveAddress}
        postcode={postcode}
        setPostcode={setPostcode}
      />
    </Grid>
  );
}
