import React, { Component } from "react";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import { connect } from "react-redux";
import { setAlertPreferences } from "../../../store/monitoringService/actionCreators";
import { isValidMobileNumber, isValidEmail } from "../../../helpers/validation";
import Section from "../../../components/Shared/Section";
import LogicMonitorIcon from "../../../components/Shared/LogicMonitorIcon";
import { TextField, Grid, Box } from "@material-ui/core";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: "",
      phoneNumber: ""
    };
  }

  setEmailAddress = event =>
    this.setState({ emailAddress: event.target.value });
  setPhoneNumber = event => this.setState({ phoneNumber: event.target.value });

  render() {
    const { alertPreferences, setAlertPreferences } = this.props;
    return (
      <Section avatar={<LogicMonitorIcon />}>
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={6}>
            <Box display="flex" alignItems="center">
              <EmailIcon style={{ marginRight: "5px" }} />
              <TextField
                data-cy="alertPrefEmail"
                label="Email Address"
                value={this.state.emailAddress}
                fullWidth
                helperText={
                  this.state.emailAddress &&
                  !isValidEmail(this.state.emailAddress) &&
                  "Email address is not valid."
                }
                error={
                  this.state.emailAddress &&
                  !isValidEmail(this.state.emailAddress)
                }
                onChange={this.setEmailAddress}
                onBlur={() =>
                  setAlertPreferences({
                    ...alertPreferences,
                    emailAddress: this.state.emailAddress
                  })
                }
              />
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box display="flex" alignItems="center">
              <PhoneIcon style={{ marginRight: "5px" }} />
              <TextField
                data-cy="alertPrefPhone"
                label="Mobile Number"
                value={this.state.phoneNumber}
                fullWidth
                helperText={
                  this.state.phoneNumber &&
                  !isValidMobileNumber(this.state.phoneNumber) &&
                  "Mobile number is not valid."
                }
                error={
                  this.state.phoneNumber &&
                  !isValidMobileNumber(this.state.phoneNumber)
                }
                onChange={this.setPhoneNumber}
                onBlur={() =>
                  setAlertPreferences({
                    ...alertPreferences,
                    phoneNumber: this.state.phoneNumber
                  })
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Section>
    );
  }
}

const mapStateToProps = state => {
  return {
    alertPreferences: state.monitoringService.alertPreferences
  };
};

export default connect(mapStateToProps, {
  setAlertPreferences
})(Index);
