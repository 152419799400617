import React from "react";
import { connect } from "react-redux";
import { IndexedCardInfo } from "../../screens/shared/indexed-card-info";
import AccountDetails from "./sections/AccountDetails";
import { CompanyAddress } from "./sections/CompanyAddress/CompanyAddress";
import BillingAddress from "./sections/BillingAddress";
import ContactDetails from "./sections/ContactDetails";
import Payment from "./sections/Payment";
import BillOptions from "./sections/BillOptions";
import MarketingPreferences from "./sections/MarketingPreferences";
import AutoPopulate from "./AutoPopulate";
import { getLeadId } from "../../store/order/selectors";
import { getAccountSettings } from "../../store/account/selectors";

const cardIndexes = ["A", "B", "C", "D", "E", "F", "G", "H"];

class CreateAccountForm extends React.Component {
  render() {
    const { billing_address_same, leadId, settings } = this.props;
    let index = -1;

    return (
      <IndexedCardInfo index={1} title="Please enter new account details">
        <div data-cy="newAccountForm">
          {leadId && <AutoPopulate />}
          <AccountDetails cardIndex={cardIndexes[++index]} />
          <CompanyAddress cardIndex={cardIndexes[++index]} />
          {!billing_address_same && (
            <BillingAddress cardIndex={cardIndexes[++index]} />
          )}
          <ContactDetails cardIndex={cardIndexes[++index]} />
          <Payment cardIndex={cardIndexes[++index]} />
          {settings.as_can_add_bill_options === "1" && (
            <BillOptions cardIndex={cardIndexes[++index]} />
          )}
          <MarketingPreferences cardIndex={cardIndexes[++index]} />
        </div>
      </IndexedCardInfo>
    );
  }
}

const mapStateToProps = state => ({
  billing_address_same: state.account.newAccountFields.billing_address_same,
  business_entity_type: state.account.newAccountFields.business_entity_type,
  leadId: getLeadId(state),
  settings: getAccountSettings(state)
});

export default connect(mapStateToProps)(CreateAccountForm);
