import React from "react";
import _ from "lodash";
import LowPriorityInfoChip from "../../../components/Shared/LowPriorityInfoChip";
import { Box, Chip } from "@material-ui/core";
import { StatusChip } from "@akj-dev/design-system";
import { useSelector } from "react-redux";
import { getAccountSettings } from "../../../store/account/selectors";

function InstallationDetails({ installationDetails }) {
  const accountSettings = useSelector(getAccountSettings);
  const selectServices = _.get(
    installationDetails,
    "calling_and_network_features.calling_and_network_feature",
    []
  );
  return (
    <LowPriorityInfoChip>
      {installationDetails.ownership_confirmation === "true" && (
        <Box mb={1}>
          {accountSettings.as_stop_daisy_line_orders === "1" ? (
            <StatusChip
              type="error"
              title="Existing Daisy Line"
              message="Sorry, you may not proceed with this order."
              style={{ marginTop: 0 }}
            />
          ) : (
            <Chip label="Existing Daisy Line" color="primary" />
          )}
        </Box>
      )}
      <Box mb={1}>
        <b>Line Type: </b>
        {installationDetails.line_type}
      </Box>
      <Box mb={1}>
        <b>Service Type: </b>
        {installationDetails.service_type}
      </Box>
      <div>
        <b>Select Services: </b>
        {selectServices.length > 0 ? (
          <ul>
            {selectServices.map((service, i) => (
              <li key={i}>{service.cnf_name}</li>
            ))}
          </ul>
        ) : (
          "None"
        )}
      </div>
    </LowPriorityInfoChip>
  );
}

export default InstallationDetails;
