import React from "react";
import Icon from "@material-ui/icons/Book";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";
import { Grid } from "@material-ui/core";

const DirectoryEnquiry = props => {
  return (
    <Section
      title="Directory Enquiry"
      avatar={<Icon />}
      defaultExpanded={false}
    >
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="directory_description"
          />
        </Grid>
        <Grid item sm={12}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="end_user_name_for_999"
          />
        </Grid>
        <Grid item sm={12}>
          <WlrBBDynamicField productType="wlr" propertyName="company" />
        </Grid>
        <Grid item sm={12}>
          <WlrBBDynamicField productType="wlr" propertyName="entry_type" />
        </Grid>
        <Grid item sm={12}>
          <WlrBBDynamicField productType="wlr" propertyName="directory_style" />
        </Grid>
        <Grid item sm={12}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="directory_suffix"
          />
        </Grid>
      </Grid>
    </Section>
  );
};

DirectoryEnquiry.propTypes = {};

export default DirectoryEnquiry;
