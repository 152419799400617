import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import { SaleStepEnum } from "../../enums";
import { ChipInfo } from "./ChipInfo";
import { getHostEnv } from "../../../store/order/selectors/getHostEnv";

type ReduxTitleAndInfoType = {
  steps: SaleStepEnum[];
  activeStep: SaleStepEnum;
  orderId: string;
  accountId: string;
  customerName: string;
  opportunityNumber: string;
  opportunityName: string;
  opportunityId: string;
  leadNumber: string;
};

export const TitleAndInfo = ({ title }: { title: string }) => {
  const classes = useStyles();

  const {
    orderId,
    accountId,
    customerName,
    opportunityName,
    opportunityNumber,
    opportunityId,
    leadNumber,
    steps,
    activeStep
  } = useSelector<any, ReduxTitleAndInfoType>(state => ({
    orderId: state.order.id,
    accountId: state.order.accountId,
    customerName: state.order.customerName,
    opportunityName: state.order.opportunityName,
    opportunityNumber: state.order.opportunityNumber,
    opportunityId: state.order.opportunityId,
    leadNumber: state.order.leadNumber,
    steps: state.uiState.steps,
    activeStep: state.uiState.activeStep
  }));
  const hostEnv = useSelector(getHostEnv);

  const activeStepIndex = steps.indexOf(activeStep);
  const deliveryStepIndex = steps.indexOf(SaleStepEnum.STEP_DELIVERY);

  const isHiddenForLE =
    hostEnv === "platform" && activeStepIndex < deliveryStepIndex;

  const withHref = hostEnv === "dc";

  const chipLabels = useMemo(() => {
    const labels: { label: string; href?: string }[] = [];

    if (withHref) {
      [
        {
          condition: !!accountId,
          label: `Account: ${accountId}`,
          href: `/cgi-bin/index.cgi/CustomerService/AccountOverview/${accountId}`
        },
        {
          condition: !!opportunityId,
          label: `Opportunity ID: ${opportunityNumber}`,
          href: `/cgi-bin/index.cgi/CustomerService/Opportunities/view/${opportunityId}`
        },
        {
          condition: !!orderId,
          label: `Order ID: ${orderId}`,
          href: `/cgi-bin/index.cgi/Orders/edit/${orderId}`
        }
      ].forEach(({ condition, label, href }) => {
        if (condition) {
          labels.push({ label, href });
        }
      });
    } else {
      [
        { condition: !!customerName, label: `Customer Name: ${customerName}` },
        {
          condition: !isHiddenForLE && !!accountId,
          label: `Account: ${accountId}`
        },
        { condition: !!leadNumber, label: `Prospect: ${leadNumber}` },
        {
          condition: !!opportunityName,
          label: `Sale Name: ${opportunityName}`
        },
        { condition: !!orderId, label: `Order ID: ${orderId}` }
      ].forEach(({ condition, label }) => {
        if (condition) {
          labels.push({ label });
        }
      });
    }

    return labels;
  }, [
    withHref,
    orderId,
    accountId,
    opportunityId,
    opportunityNumber,
    opportunityName,
    customerName,
    isHiddenForLE,
    leadNumber
  ]);

  return (
    <Box
      className={classes.title}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h2">{title}</Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        {chipLabels.map(({ label, href }) => (
          <ChipInfo key={label} label={label} href={href} />
        ))}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(3.5)
  }
}));
