import React from "react";
import AccountTextField from "../AccountTextField";
import { Grid } from "@material-ui/core";
import { IndexedCardInfo } from "../../../screens/shared/indexed-card-info";

const Payment = ({ cardIndex }) => {
  return (
    <IndexedCardInfo index={cardIndex} title="Payment">
      <form autoComplete="off">
        <Grid container spacing={2}>
          <AccountTextField
            label="Bank Name"
            name="bank_name"
            autoComplete="off"
          />
          <AccountTextField
            label="Account Name"
            name="bank_account_name"
            autoComplete="off"
          />
          <AccountTextField
            label="Account Number"
            name="bank_account_number"
            autoComplete="off"
          />
          <AccountTextField
            label="Sort Code"
            name="bank_account_sortcode"
            autoComplete="off"
          />
        </Grid>
      </form>
    </IndexedCardInfo>
  );
};

export default Payment;
