import { createSelector } from "reselect";
import _ from "lodash";
import {
  getHardwareCount,
  hardwareOrderProductCallsFetching
} from "../../hardware/selectors";
import {
  ethernetOrderProductCallsFetching,
  getConfigRecurringPrice
} from "../../ethernetProducts/selectors";
import { mobileOrderProductCallsFetching } from "../../mobile/selectors/order";
import {
  getOverallBroadbandPriceForResign,
  getProductInstanceForConfiguration,
  getTotalPrice,
  wlrBBOrderProductCallsFetching
} from "../../wlrBroadband/selectors";
import { RESIGN_WITHOUT_CHANGES } from "../../mobile/constants";
import { getResignPrice } from "../../mobile/selectors/resigns";
import { getDaisyFreshTotal } from "../../mobile/selectors/daisyFresh";
import flatten from "flat";
import { getBoltOnProductDataByID } from "../../mobile/selectors/boltOns";
import {
  configurationTotalLeasePrice,
  configurationTotalOneOffPrice
} from "../../hardware/configurations/selectors";
import {
  NEW_PRODUCT,
  SAME_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES
} from "../../wlrBroadband/constants";

import { getAccountSettings } from "../../account/selectors";

/**
 * Returns true if any OrderProduct calls are running.
 * Used to disable step navigation when mid-order.
 * @param state
 * @returns {boolean}
 */
export const getOrderProductCallsFetching = state =>
  hardwareOrderProductCallsFetching(state) ||
  ethernetOrderProductCallsFetching(state) ||
  mobileOrderProductCallsFetching(state) ||
  wlrBBOrderProductCallsFetching(state) ||
  state.order.orderStatus.fetching ||
  state.order.recalculatePrices.fetching;

/**
 * Get total cost of selected mobile products in current order
 * @type {Reselect.Selector<any, any>}
 */
const getMobileOrderTotal = createSelector(
  [
    state => state.mobile.configs,
    state => state.mobile.mobileSearch.response.products,
    state => state.mobile.productInstances.response.results,
    state => state.mobile.cliBoltOnSearch,
    state => state.mobile.productData
  ],
  (configs, mobileProducts, productInstances, cliBoltOnSearch, productData) => {
    let oneOff = 0,
      recurring = 0;

    if (configs.length > 0 && mobileProducts) {
      configs.forEach(config => {
        if (productInstances && config.resignId) {
          if (config.resignType === RESIGN_WITHOUT_CHANGES) {
            const instance = productInstances.find(
              i => i.id === config.resignId
            );
            if (instance) {
              recurring += getResignPrice(instance);
              return;
            }
          }
        }

        const product = mobileProducts.find(p => p.id === config.productId);
        if (product) {
          const price =
            // Either the per config product data with discounted price data
            productData?.[config.productId]?.response?.overall_price ||
            // or the generic one from MobileSearch (when no price adjustment has been made)
            product.price;
          recurring += parseFloat(
            price.first_bill_recurring_price_with_promotions
          );
          oneOff += parseFloat(price.one_off_price_with_promotions);

          if (config.selectedCliBoltOns) {
            const cliBoltOns = _.values(flatten(config.selectedCliBoltOns));

            cliBoltOns.forEach(id => {
              const boltOn = _.get(
                cliBoltOnSearch[config.productId],
                "response.products",
                []
              ).find(p => p.id === id);
              if (boltOn) {
                oneOff +=
                  parseFloat(boltOn.price.one_off_price_with_promotions) || 0;
                recurring +=
                  parseFloat(
                    boltOn.price.first_bill_recurring_price_with_promotions
                  ) || 0;
              }
            });
          }
        } else {
          console.log("Product not found...");
        }
      });
    }

    return { oneOff, recurring };
  }
);

/**
 * Get total cost of selected Account Level BoltOns
 * @type {Reselect.Selector<any, number> | Reselect.Selector<any, any>}
 */
const getALBOrderTotal = createSelector(
  [state => state.mobile.selectedBoltOns, state => state.mobile.boltOnSearch],
  (selectedBoltOns, boltOnSearch) => {
    let total = 0;

    const boltOns = _.values(flatten(selectedBoltOns));

    boltOns.forEach(id => {
      const product = getBoltOnProductDataByID({ boltOnSearch }, id);
      if (product) {
        total +=
          parseFloat(
            product.price.first_bill_recurring_price_with_promotions
          ) || 0;
      }
    });

    return total;
  }
);

/**
 * Get total hardware cost
 * @type {Reselect.Selector<any>}
 */
export const getHardwareOrderTotal = createSelector(
  [
    state => state.hardwareConfigurations,
    state => state.hardwareProducts.response.products,
    state => state.mobile.configs.length > 0
  ],
  (hardwareConfigurations, products, hasConnections) => {
    let oneOff = 0,
      recurring = 0;

    _.forEach(hardwareConfigurations, config => {
      if (config.quantity) {
        oneOff += parseFloat(
          configurationTotalOneOffPrice(config, products, hasConnections)
        );
        recurring += parseFloat(configurationTotalLeasePrice(config, products));
      }
    });
    return {
      oneOff,
      recurring
    };
  }
);

/**
 * Get total hardware cost before credit has been subtracted
 * @type {Reselect.Selector<any>}
 */
export const getHardwareOrderTotalBeforeCredit = createSelector(
  [
    state => state.hardwareConfigurations,
    state => state.hardwareProducts.response.products,
    state => state.mobile.configs.length > 0
  ],
  (hardwareConfigurations, products, hasConnections) => {
    let oneOff = 0;

    _.forEach(hardwareConfigurations, config => {
      if (config.quantity) {
        oneOff += parseFloat(
          configurationTotalOneOffPrice(config, products, hasConnections, true)
        );
      }
    });
    return oneOff;
  }
);

/**
 * Get total WLR + Broadband cost
 * @type {Reselect.Selector<any, any>}
 */
const getWlrBroadbandOrderTotal = createSelector(
  [
    state => state.wlrBroadband.locations,
    state => state.wlrBroadband.configurations,
    state => state
  ],
  (locations, configs, state) => {
    let oneOff = 0,
      recurring = 0;

    // TODO: Step 1 (different compute)
    configs.forEach((config, index) => {
      if (
        config.resignProductType &&
        config.resignProductType === SAME_PRODUCT
      ) {
        const productInstance = getProductInstanceForConfiguration(
          state,
          index
        );
        if (productInstance) {
          recurring += parseFloat(getTotalPrice(productInstance.evo_services));
          return;
        }
      }

      if (config.wlrProductId && config.wlrProductData) {
        oneOff += parseFloat(
          _.get(
            config.wlrProductData.response,
            "overall_price.one_off_price_with_promotions",
            0
          )
        );
        recurring += parseFloat(
          _.get(
            config.wlrProductData.response,
            "overall_price.first_bill_recurring_price_with_promotions",
            0
          )
        );
      }

      if (
        config.broadbandProductId &&
        config.broadbandProductData &&
        !config.resignProductType
      ) {
        oneOff += parseFloat(
          _.get(
            config.broadbandProductData.response,
            "overall_price.one_off_price_with_promotions",
            0
          )
        );
        recurring += parseFloat(
          _.get(
            config.broadbandProductData.response,
            "overall_price.first_bill_recurring_price_with_promotions",
            0
          )
        );
      }
      if (
        config.broadbandProductId &&
        config.broadbandProductData &&
        (config.resignProductType === SAME_PRODUCT_WITH_CHANGES ||
          config.resignProductType === NEW_PRODUCT)
      ) {
        oneOff += getOverallBroadbandPriceForResign(config, false);
        recurring += getOverallBroadbandPriceForResign(config, true);
      }
    });
    return {
      oneOff,
      recurring
    };
  }
);

export const getEthernetTotal = createSelector(
  [
    state => state.ethernetProducts,
    state => getAccountSettings(state).round_ethernet_prices_to_next_whole_pound
  ],
  (ethernetProducts, roundEthernetPrices) => {
    let oneOff = 0,
      recurring = 0;

    ethernetProducts.configurations.forEach(configuration => {
      if (configuration.selectedQuoteId) {
        const selectedResult = configuration.pricingResults.filter(
          result => result.id === configuration.selectedQuoteId
        );
        oneOff += parseFloat(selectedResult[0].one_off_price) || 0;
        recurring += getConfigRecurringPrice(
          configuration,
          selectedResult[0],
          true,
          roundEthernetPrices === "1"
        );
      }
    });

    return {
      oneOff,
      recurring
    };
  }
);

export const getLogicMonitorProductsTotal = createSelector(
  [state => state.monitoringService],
  monitoringService => {
    let oneOff = 0,
      recurring = 0;
    monitoringService.configs.forEach(item => {
      oneOff += parseFloat(item.oneOffPrice) || 0;
      recurring += parseFloat(item.recurringPrice) || 0;
    });

    return {
      oneOff,
      recurring
    };
  }
);

/**
 * Compute total one-off price for entire order.
 * @type {Reselect.Selector<any, number> | Reselect.Selector<any, any>}
 */
export const getOneOffOrderTotal = createSelector(
  [
    state => getMobileOrderTotal(state),
    state => getHardwareOrderTotal(state),
    state => getWlrBroadbandOrderTotal(state),
    state => getEthernetTotal(state),
    state => getLogicMonitorProductsTotal(state)
  ],

  (
    mobileOrderTotal,
    hardwareOrderTotal,
    wlrBroadbandOrderTotal,
    ethernetTotal,
    logicMonitorTotal
  ) => {
    return (
      mobileOrderTotal.oneOff +
        hardwareOrderTotal.oneOff +
        wlrBroadbandOrderTotal.oneOff +
        ethernetTotal.oneOff ||
      0 + logicMonitorTotal.oneOff ||
      0
    );
  }
);

/**
 * Compute total one-off price for entire order.
 * @type {Reselect.Selector<any, number> | Reselect.Selector<any, any>}
 */
export const getRecurringOrderTotal = createSelector(
  [
    state => getMobileOrderTotal(state),
    state => getDaisyFreshTotal(state),
    state => getALBOrderTotal(state),
    state => getHardwareOrderTotal(state),
    state => getWlrBroadbandOrderTotal(state),
    state => getEthernetTotal(state),
    state => getLogicMonitorProductsTotal(state)
  ],

  (
    mobileOrderTotal,
    daisyFreshTotal,
    albOrderTotal,
    hardwareOrderTotal,
    wlrBroadbandOrderTotal,
    ethernetTotal,
    logicMonitorTotal
  ) => {
    return (
      mobileOrderTotal.recurring +
        daisyFreshTotal +
        albOrderTotal +
        hardwareOrderTotal.recurring +
        wlrBroadbandOrderTotal.recurring +
        ethernetTotal.recurring ||
      0 + logicMonitorTotal.recurring ||
      0
    );
  }
);

/**
 * Get the Account ID for this GS session
 * @param state
 */
export const getAccountId = state => state.order.accountId;

/**
 * Get the current order ID
 * @param state
 * @returns {*}
 */
export const getOrderId = state => state.order.id;

/**
 * Get the current Lead ID
 * @param state
 */
export const getLeadId = state => state.order.leadId;

/**
 * Get uploaded contract ID
 * @param state
 * @returns {undefined}
 */
export const getUploadedContractId = state =>
  _.get(state.order, "contractUpload.response.data.contract_id");

/**
 * Get provisioning bundle ID
 * When an order has been provisioned via Orders/Provision call
 * @param state
 * @returns {undefined}
 */
export const getProvisioningBundleId = state =>
  _.get(state.order, "provision.response.data.bundle_id");

/**
 * Get "Sent for approval" flag from response.
 *
 * @param state
 * @returns {any}
 */
export const getApprovalSubmitted = state =>
  _.get(state.order.sendForApproval, "response.data.submitted");

/**
 * Calculate the total credit used for a hardware order.
 * @param products
 */
export const getTotalCreditUsed = (products = []) => {
  let creditUsed = 0;
  products.forEach(product => {
    if (product.components[0] && product.components[0].credit_used)
      creditUsed += parseFloat(product.components[0].credit_used);
  });
  return creditUsed.toFixed(2);
};

/**
 * Count total number of items on an order returns true if no products false if theres is products
 * @param state
 * @returns {number}
 */
export const getDoesOrderNotHaveAnyProducts = state =>
  state.mobile.configs.length < 1 &&
  state.ethernetProducts.configurations.length < 1 &&
  state.wlrBroadband.configurations.length < 1 &&
  getHardwareCount(state) < 1 &&
  state.universalProducts.configs < 1 &&
  state.monitoringService.configs < 1;

/**
 * Get whether we need to show the approve/reject options or not
 *
 * @param state
 * @returns {boolean}
 */
export const getRequiresCustomerApproval = state => {
  const accountSettings = getAccountSettings(state);
  const order = state.order;

  const approval = _.get(order.orderStatus.response, "data.approval");

  if (!approval) {
    return false;
  }

  if (
    accountSettings.requires_external_approval_for_external_user_orders !== "1"
  ) {
    return false;
  }

  if (approval?.rejected || !approval.required) {
    return false;
  }

  const hasPendingCustomerApproval = rules => {
    return !!Object.values(rules).find(rule => {
      return (
        rule.required &&
        Array.isArray(rule.messages) &&
        rule.messages.find(message => {
          return /Customer Approval/.test(message);
        })
      );
    });
  };

  if (
    approval.hasOwnProperty("rules") &&
    approval.rules.constructor.name === "Object"
  ) {
    // this will be a Views::v2::Order response
    return hasPendingCustomerApproval(approval.rules);
  }

  if (
    approval.hasOwnProperty("messages") &&
    approval.messages.constructor.name === "Object"
  ) {
    // this will be a Views::v1::Order response for an order which has been sent for approval
    return hasPendingCustomerApproval(approval.messages);
  }

  if (Array.isArray(approval?.messages)) {
    // this will be a Views::v1::Order response for an order which hasnt been sent for approval yet
    return !!approval?.messages.find(message => {
      return /Customer Approval/.test(message);
    });
  }

  return false;
};
