import React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  .icon {
    width: 40px;
    height: 24px;
    flex-shrink: 0;
  }
`;

export default class ExistingSoGEAAlertDialog extends React.Component {
  state = { checked: false };

  render() {
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
        <DialogTitle>
          Please read before progressing with the order.
        </DialogTitle>
        <DialogContent>
          <AlertItem>
            <div className="icon">
              <ErrorIcon color="error" />
            </div>
            <div>
              <div>
                Please ensure the below is read and understood in full before
                proceeding with this order. The SoGEA order will convert the
                existing services (line & any broadband service) with a single
                data only Broadband service whereby:
              </div>
              <br />
              <li>The WLR (line) or MPF voice service will CEASE</li>
              <li>The telephone number will be unretainable</li>
              <li>
                You will not be able to revert the service to a WLR/MPF (voice +
                broadband)
              </li>
              <br />
              <div>
                If the customer requires a voice service, please quote for a
                VoIP service separately along with a number import (if you wish
                to retain the number). Please confirm with the customer prior to
                placing any order if any of the following services are active,
                if so, please do not proceed with this order:
              </div>
              <br />
              <li>Customer who are vulnerable or using pendant service</li>
              <li>
                Security systems, payment devices, fax machines and lift
                services
              </li>
              <li>
                Red care services or any devices that customer is reliant on
                which uses the WLR service
              </li>
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.checked}
                    onChange={() =>
                      this.setState({ checked: !this.state.checked })
                    }
                  />
                }
                label={
                  <div style={{ fontSize: "14px" }}>
                    I confirm that I have read and understood the above along
                    with the impact this may have on the customer.
                  </div>
                }
              />
            </div>
          </AlertItem>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            disabled={!this.state.checked}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
