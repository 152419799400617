import React from "react";
import { useSelector } from "react-redux";
import BulkDynamicField from "../BulkDynamicField";
import ArrayDynamicField from "../ArrayDynamicField";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import Section from "../../../../../components/Shared/Section";
import { getAccountSettings } from "../../../../../store/account/selectors";

export const AdvancedConfiguration = () => {
  const accountSettings = useSelector(getAccountSettings);
  return (
    <Section
      title="Advanced Configuration"
      avatar={<SettingsApplicationsIcon />}
    >
      <ArrayDynamicField
        propertyName="user_name"
        floatingLabelText="User Name"
      />
      {accountSettings.as_can_apply_wwcap === "1" && (
        <BulkDynamicField propertyName="wwcap_enabled" />
      )}
      {accountSettings.as_can_apply_bundle_notifications_opt_out === "1" && (
        <BulkDynamicField propertyName="bundle_notifications_opt_out" />
      )}
    </Section>
  );
};
