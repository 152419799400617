import React, { Component } from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  hideFinalStepWarning,
  confirmFinalStepWarning,
  setActiveStep
} from "../../store/uiState/actions";
import Button from "@material-ui/core/Button";

class FinalStepWarning extends Component {
  handleOk = () => {
    this.props.confirmFinalStepWarning();
    const { activeStep, steps } = this.props;
    const nextStep = steps[steps.indexOf(activeStep) + 1];
    if (nextStep) this.props.setActiveStep(nextStep);
  };
  handleCancel = () => {
    this.props.hideFinalStepWarning();
  };

  render() {
    return (
      <Dialog open={this.props.show}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You will not be able to go back to the previous step. Are you sure
            you wish to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleOk}
            data-cy="FinalStepConfirmButton"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    show: state.uiState.showFinalStepWarning,
    activeStep: state.uiState.activeStep,
    steps: state.uiState.steps
  };
};

export default connect(mapStateToProps, {
  hideFinalStepWarning,
  confirmFinalStepWarning,
  setActiveStep
})(FinalStepWarning);
