import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleBillCapConfirmation } from "../../../store/mobile/actionCreators";
import Checkbox from "@material-ui/core/Checkbox";
import { getBillLimitsNotSet } from "../../../store/mobile/selectors/productConfig";
import LowPriorityInfoChip from "../../../components/Shared/LowPriorityInfoChip";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class BillCapConfirmation extends Component {
  state = {};

  render() {
    const {
      billCapConfirmed,
      billLimitsNotSet,
      toggleBillCapConfirmation
    } = this.props;

    if (billLimitsNotSet)
      return (
        <LowPriorityInfoChip>
          <FormControlLabel
            label="I confirm I have offered a Mobile Bill Cap for out of bundle spend."
            control={
              <Checkbox
                checked={billCapConfirmed}
                onChange={toggleBillCapConfirmation}
                data-cy="billCapConfirmed"
              />
            }
          />
        </LowPriorityInfoChip>
      );

    return false;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    billCapConfirmed: state.mobile.billCapConfirmed,
    billLimitsNotSet: getBillLimitsNotSet(state)
  };
};

export default connect(mapStateToProps, { toggleBillCapConfirmation })(
  BillCapConfirmation
);
