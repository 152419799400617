import React from "react";
import { connect } from "react-redux";
import EthernetCard from "../../Shared/Ethernet/EthernetCard";
import PricingRequestStatus from "../../../components/Step2/Ethernet/PricingRequestStatus";
import EthernetOrderForm from "./EthernetOrderForm/index";
import CompletedOrderInfo from "./CompletedOrderInfo";
import SortableTable from "../../../components/Shared/SortableTable";
import Checkbox from "@material-ui/core/Checkbox";
import Popover from "../../../components/Popover";
import { StatusChip } from "@akj-dev/design-system";
import {
  selectEthernetQuote,
  updateEthernetCustomerData
} from "../../../store/ethernetProducts/actionsCreators";
import { getConfigRecurringPrice } from "../../../store/ethernetProducts/selectors";
import { ETHERNET_PRODUCT_EFM } from "../../../store/ethernetProducts/constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import { getAccountSettings } from "../../../store/account/selectors";

class EthernetConfiguration extends React.Component {
  static propTypes = {
    accountSettingRoundEthernetPrices: PropTypes.oneOf(["0", "1"])
  };

  render() {
    const {
      configuration,
      configIndex,
      selectEthernetQuote,
      updateEthernetCustomerData,
      accountSettingRoundEthernetPrices,
      accountName
    } = this.props;

    const { amortised } = configuration.purchaseArgs;
    const isEFM = configuration.type === ETHERNET_PRODUCT_EFM;

    return (
      <EthernetCard configuration={configuration}>
        {configuration.purchaseResponse.order_id ? (
          <CompletedOrderInfo configuration={configuration} />
        ) : (
          <span>
            <div style={{ textAlign: "right" }}>
              <FormControlLabel
                label="Amortise installation costs?"
                labelPlacement="start"
                control={
                  <Checkbox
                    onChange={(e, checked) =>
                      updateEthernetCustomerData(
                        configIndex,
                        "amortised",
                        checked
                      )
                    }
                    value={amortised}
                  />
                }
              />
            </div>
            {configuration.selectedQuoteId ? (
              <EthernetOrderForm
                configuration={configuration}
                index={configIndex}
                key={configIndex}
              />
            ) : (
              <span>
                {configuration.pricingResults.length > 0 && (
                  <SortableTable
                    columns={[
                      false,
                      {
                        header: "Supplier",
                        accessor: "supplier"
                      },
                      {
                        header: "Technology",
                        accessor: "technology"
                      },
                      ...(isEFM
                        ? [
                            {
                              header: "Min. Speed",
                              accessor: "minimum_speed"
                            },
                            {
                              header: "Max. Speed",
                              accessor: "maximum_speed"
                            }
                          ]
                        : []),
                      {
                        header: "Term (Months)",
                        accessor: "contract_length_in_months"
                      },
                      {
                        header: "Router Name",
                        accessor: "router_name"
                      },
                      {
                        header: "One Off",
                        accessor: "one_off_price"
                      },
                      {
                        header: "Monthly",
                        accessor: "recurring_price"
                      }
                    ]}
                    data={configuration.pricingResults}
                    defaultSortColumn="supplier"
                    renderRow={(data, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          <Checkbox
                            onChange={() =>
                              selectEthernetQuote(
                                configIndex,
                                data.id,
                                data.technology,
                                accountName
                              )
                            }
                            data-cy="SelectEthernetConfig"
                          />
                        </TableCell>
                        <TableCell>
                          {data.supplier}
                          {data.provider_messages.length > 0 && (
                            <Popover title="Additional Information">
                              {data.provider_messages.map((m, i) => (
                                <p key={i}>{m}</p>
                              ))}
                            </Popover>
                          )}
                        </TableCell>
                        <TableCell>{data.technology}</TableCell>
                        {isEFM && (
                          <>
                            <TableCell>{data.minimum_speed}</TableCell>
                            <TableCell>{data.maximum_speed}</TableCell>
                          </>
                        )}
                        <TableCell>{data.contract_length_in_months}</TableCell>
                        <TableCell>{data.router_name}</TableCell>
                        <TableCell>
                          {amortised ? "0.00" : data.one_off_price}
                        </TableCell>
                        <TableCell>
                          {getConfigRecurringPrice(
                            configuration,
                            data,
                            true,
                            accountSettingRoundEthernetPrices === "1"
                          ).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    )}
                  />
                )}
                <PricingRequestStatus configuration={configuration} />
                {!(configuration.site_a_uprn || configuration.site_b_uprn) && (
                  <StatusChip
                    type="error"
                    title="Warning"
                    message="A UPRN reference is required for accurate Virgin pricing."
                  />
                )}
              </span>
            )}
          </span>
        )}
      </EthernetCard>
    );
  }
}

const mapStateToProps = state => ({
  accountSettingRoundEthernetPrices: getAccountSettings(state)
    .round_ethernet_prices_to_next_whole_pound,
  accountName: state.order.accountName
});

export default connect(mapStateToProps, {
  updateEthernetCustomerData,
  selectEthernetQuote
})(EthernetConfiguration);
