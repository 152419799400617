import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import vodaIcon from "../../../images/voda@2x.png";
import o2Icon from "../../../images/o2@2x.png";
import diseIcon from "../../../images/dise@2x.png";
import { StatusChip } from "@akj-dev/design-system";

const Product = styled.div`
  display: flex;
  align-items: center;
  > img {
    margin-right: 10px;
  }
`;

const ProductTitle = ({ network, children }) => {
  return (
    <Product>
      {(() => {
        switch (network) {
          case "O2":
            return <img src={o2Icon} width={32} title="O2" alt="O2" />;
          case "Vodafone":
          case "VF Direct":
            return (
              <img src={vodaIcon} width={32} title="Vodafone" alt="Vodafone" />
            );
          case "Dise":
            return <img src={diseIcon} width={32} title="Dise" alt="Dise" />;
          default:
            return (
              <StatusChip
                type="error"
                title="Unknown network"
                message={<pre>{JSON.stringify(network, null, 2)}</pre>}
              />
            );
        }
      })()}
      <div>{children}</div>
    </Product>
  );
};

ProductTitle.propTypes = {
  network: PropTypes.oneOf(["O2", "Vodafone", "Dise", "VF Direct"])
};

export default ProductTitle;
