import React from "react";
import { Grid } from "@material-ui/core";
import { IndexedCardInfo } from "../../../screens/shared/indexed-card-info";
import AccountTextField from "../AccountTextField";
import PostcodeLookup from "./PostcodeLookup";

export default function BillingAddress({ cardIndex }) {
  return (
    <IndexedCardInfo index={cardIndex} title="Billing Address">
      <form>
        <Grid container spacing={2}>
          <PostcodeLookup prefix="billing_" />
          <AccountTextField label="Building" name="billing_building" />
          <AccountTextField label="Street" name="billing_street" />
          <AccountTextField label="Post Town" name="billing_post_town" />
          <AccountTextField label="County" name="billing_region" />
          <AccountTextField label="Country" name="billing_country" />
        </Grid>
      </form>
    </IndexedCardInfo>
  );
}
