import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  updateConfigProperty,
  validateConfigProperty
} from "../../../../store/mobile/actionCreators";
import { StatusChip } from "@akj-dev/design-system";
import NonUniformIndicator from "./NonUniformIndicator";
import withContextToProps from "../../../../helpers/withContextToProps";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import { DC_DATE_FORMAT } from "../../../../helpers/date";
import { FormHelperText, Tooltip } from "@material-ui/core";
import { TargetConfigsContext } from "../../context/TargetConfigsContext";

class BulkDynamicField extends React.PureComponent {
  static propTypes = {
    propertyName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    afterUpdate: PropTypes.func, // Action to fire after input change. For BarsCompatibilities currently

    availableOptionsMap: PropTypes.object, // Override options coming back from DC

    // Date field only:
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    defaultDate: PropTypes.object,
    shouldDisableDate: PropTypes.func
  };

  static defaultProps = {
    afterUpdate: () => false
  };

  state = {
    showNonUniform: true
  };

  hideNonUniformWarning = () => {
    if (!this.props.disabled) {
      this.setState({
        showNonUniform: false
      });
      // TODO: Use refs or something to bring focus to input element on reveal
    }
  };

  render() {
    const {
      targetConfigs,
      propertyName,
      disabled,
      availableOptionsMap,
      afterUpdate, // Props
      dynamicProperty,
      currentValue,
      validation,
      nonUniform, // Store
      updateConfigProperty,
      validateConfigProperty, // Dispatch
      helperText,
      InputProps
    } = this.props;

    if (!dynamicProperty)
      return (
        <StatusChip
          type="error"
          message={`Unknown dynamic property ${propertyName}`}
        />
      );

    // Test if properties across selected configuration IDs are uniform.
    // If not, display a multiple property warning

    if (nonUniform && this.state.showNonUniform) {
      return (
        <NonUniformIndicator
          label={dynamicProperty.label}
          action={this.hideNonUniformWarning}
        />
      );
    }

    // Set common properties for all field types
    const commonProps = {
      name: dynamicProperty.name,
      value: currentValue || "",
      onChange: event => {
        updateConfigProperty(propertyName, event.target.value, targetConfigs);
        afterUpdate();
      },
      label:
        dynamicProperty.label + (dynamicProperty.is_required === 1 ? " *" : ""),
      disabled: disabled || dynamicProperty.is_editable !== 1,
      fullWidth: true,
      helperText: validation || helperText,
      error: !!validation,
      InputProps
    };

    // Return the appropriate JSX, adding / overriding props as necessary
    if (dynamicProperty.is_integer) {
      // Unused

      return <div>is_integer</div>;
    } else if (dynamicProperty.is_text) {
      // TODO: This autocomplete was used for cost centres, but appears to have been broken for a while.
      //  The whole "is_text" + "available_option_map" (which is normally reserved for "is_select" seems
      //  a bit janky too. Investigate and re-add with MUIv4 component if needed.

      //   if (dynamicProperty.available_option_map) {
      //     return (
      //       <AutoComplete
      //         name={dynamicProperty.name}
      //         value={currentValue || ""}
      //         floatingLabelText={
      //           dynamicProperty.label +
      //           (dynamicProperty.is_required === 1 ? " *" : "")
      //         }
      //         disabled={disabled || dynamicProperty.is_editable !== 1}
      //         fullWidth={true}
      //         dataSource={_.map(
      //           dynamicProperty.available_option_map,
      //           value => value
      //         )}
      //         onUpdateInput={value => {
      //           updateConfigProperty(propertyName, value, targetConfigs);
      //           afterUpdate();
      //         }}
      //       />
      //     );
      //   }

      // TODO: Speed improvement. Maybe handle this the same as ethernet with a sub component storing state locally,
      // then saving it to Redux state only onBlur... thus requiring a lot less computation.
      return (
        <TextField
          {...commonProps}
          onBlur={() => {
            validateConfigProperty(propertyName, targetConfigs);
          }}
          margin="normal"
        />
      );
    } else if (dynamicProperty.is_select) {
      let options;

      if (availableOptionsMap) {
        options = availableOptionsMap;
      } else {
        options = dynamicProperty.available_option_map;
      }

      return (
        <FormControl fullWidth margin="normal" error={commonProps.error}>
          <InputLabel>{commonProps.label}</InputLabel>
          <Select
            name={commonProps.name}
            value={commonProps.value}
            disabled={commonProps.disabled}
            fullWidth
            onChange={event => {
              updateConfigProperty(
                propertyName,
                event.target.value,
                targetConfigs
              );
              validateConfigProperty(propertyName, targetConfigs);
              afterUpdate();
            }}
          >
            {Object.entries(options) //TP26839: Sort alphabetically
              .sort(([, a], [, b]) => a.localeCompare(b))
              .map(entry => {
                const [key, value] = [entry[0], entry[1]];
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
          </Select>
          {commonProps.error && (
            <FormHelperText>{commonProps.helperText}</FormHelperText>
          )}
        </FormControl>
      );
    } else if (dynamicProperty.is_decimal) {
      return (
        <TextField
          {...commonProps}
          type="text"
          min={0}
          onChange={event => {
            const value = event.target.value
              .replace(/[^\d.]/g, "")
              .replace(/[.](?=.*[.])/g, "");
            updateConfigProperty(propertyName, value, targetConfigs);
            afterUpdate();
          }}
          onBlur={() => {
            validateConfigProperty(propertyName, targetConfigs);
          }}
          margin="normal"
        />
      );
    } else if (dynamicProperty.is_boolean) {
      // Mobile is_boolean properties are different from other places
      // (they come from various sources according to @jim)
      // They *should* have available_option_map with true and false values
      // (that are different for different fields...sometime string "yes" and "no")
      // but might not always, in which case the should default to 0 and 1.
      // Why is nothing simple?

      const checkedVal = _.get(
        dynamicProperty,
        "available_option_map.checked",
        1
      );
      const uncheckedVal = _.get(
        dynamicProperty,
        "available_option_map.unchecked",
        0
      );

      // The weird DC/Perl '0' vs 0 thing again...
      // eslint-disable-next-line eqeqeq
      const toggled = currentValue == checkedVal;

      return (
        <Tooltip
          title={
            propertyName === "service_5g"
              ? "To enable 5G services, the device and SIM also need to be 5G compatible."
              : ""
          }
          placement="right"
        >
          <FormControlLabel
            control={
              <Switch
                onChange={event => {
                  updateConfigProperty(
                    propertyName,
                    event.target.checked ? checkedVal : uncheckedVal,
                    targetConfigs
                  );
                  validateConfigProperty(propertyName, targetConfigs);
                  afterUpdate();
                }}
                checked={toggled}
                name={dynamicProperty.name}
                color="primary"
              />
            }
            label={commonProps.label}
          />
        </Tooltip>
      );
    } else if (dynamicProperty.is_date) {
      return (
        <Box my={1}>
          <KeyboardDatePicker
            {...commonProps}
            onChange={(event, date) => {
              updateConfigProperty(propertyName, date, targetConfigs);
              validateConfigProperty(propertyName, targetConfigs);
              afterUpdate();
            }}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            format={DC_DATE_FORMAT}
            shouldDisableDate={this.props.shouldDisableDate}
            value={currentValue}
          />
        </Box>
      );
    } else {
      return <div>Error: Unknown field type.</div>;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const config = state.mobile.configs[ownProps.targetConfigs[0]];
  const currentValue = config.properties[ownProps.propertyName];
  return {
    // Get the property info. Name, available options etc.
    dynamicProperty:
      state.mobile.productData[config.productId].response.mobile
        .dynamic_properties[ownProps.propertyName],
    currentValue,
    // Get any validation message
    validation: _.get(config.validation, ownProps.propertyName),
    // Test if properties across selected configuration IDs are uniform.
    nonUniform: (() => {
      for (const id in ownProps.targetConfigs) {
        if (
          currentValue !==
          state.mobile.configs[ownProps.targetConfigs[id]].properties[
            ownProps.propertyName
          ]
        )
          return true;
      }
      return false;
    })()
  };
};

// We need the context for picking out state in mapStateToProps, and it's not available there
// hence wrap the main component to pass it in.

const ConnectedBulkDynamicField = connect(mapStateToProps, {
  updateConfigProperty,
  validateConfigProperty
})(BulkDynamicField);

export default withContextToProps(
  ConnectedBulkDynamicField,
  TargetConfigsContext
);
