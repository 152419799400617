import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getOrderContact } from "../../../store/account/selectors";
import {
  getAccountId,
  getApprovalSubmitted,
  getOrderId,
  getProvisioningBundleId,
  getUploadedContractId
} from "../../../store/order/selectors";
import { getHostEnv } from "../../../store/order/selectors/getHostEnv";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { CheckCircleAnimated } from "../CheckCircleAnimated";
import { getPendingContractId } from "../../../store/generateContract/selectors";
import { doOrderCompleteCallback } from "../../../helpers/externalPortals";
import { ViewSalesStatusButton } from "./ViewSalesStatusButton";
import { ActionButton } from "./ActionButton";

interface CompleteMessageProps {}

// const bounce = keyframes`
//   to {
//     transform: scale(1);
//   }
// `;

const Outer = styled.div`
  display: flex;
  align-items: center;
  background: ${p => p.theme.palette.success.main};
  border-radius: 4px;
  padding: 32px 16px;
  margin-top: 16px;
  color: white;
  transform: scale(1);
  //animation:  0.2s ease-in-out forwards;
`;

const Message = styled.div`
  margin-left: 16px;
`;

/**
 * Message shown when order / sale / whatever is complete
 *
 * - The action is different depending on where AS has been launched from.
 * - The message is different depending on what type of order has been made
 *
 * @constructor
 */
export const CompleteMessage: React.FC<CompleteMessageProps> = () => {
  const orderContact: any = useSelector(getOrderContact);
  const accountId: string = useSelector(getAccountId);
  const orderId: string = useSelector(getOrderId);
  const pendingContractId = useSelector(getPendingContractId);
  const uploadedContractId = useSelector(getUploadedContractId);
  const provisioningBundleId = useSelector(getProvisioningBundleId);
  const approvalSubmitted = useSelector(getApprovalSubmitted);

  const hostEnv = useSelector(getHostEnv);

  useEffect(() => {
    doOrderCompleteCallback();
  }, []);

  return (
    <Outer data-cy="CompleteMessage">
      <CheckCircleAnimated />
      <Message>
        <Typography variant="h3">
          Congratulations! Your {provisioningBundleId ? "order" : "sale"} is
          complete.
        </Typography>
        {pendingContractId && (
          <Typography variant="subtitle1">
            Contract successfully sent to <b>{orderContact?.full_name}</b> for
            Sales ID {orderId}. <br />
          </Typography>
        )}
        {uploadedContractId && (
          <Typography variant="subtitle1">
            Contract successfully uploaded for Sales ID {orderId}. <br />
          </Typography>
        )}
        {provisioningBundleId && (
          <Typography variant="subtitle1">
            Provisioning successful. ID {orderId}. <br />
            Bundle ID: {provisioningBundleId}
          </Typography>
        )}
        {approvalSubmitted && (
          <Typography variant="subtitle1">
            Order successful. ID {orderId}. <br />
            Your order / request has been submitted for approval.
          </Typography>
        )}
        {hostEnv === "platform" && <ViewSalesStatusButton />}
        {hostEnv === "dc" && (
          <ActionButton
            variant="contained"
            href={`/cgi-bin/index.cgi/CustomerService/AccountOverview/${accountId}`}
          >
            Go to Account Overview
          </ActionButton>
        )}
        {hostEnv === "my_account" && (
          <ActionButton variant="contained" onClick={() => window.close()}>
            Close window and return to My Account
          </ActionButton>
        )}
      </Message>
    </Outer>
  );
};
