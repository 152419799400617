import { createSelector } from "reselect";
import { STEP_DELIVERY } from "./constants";

/**
 * Decide if the stepper nav back button is disabled
 */
export const getBackButtonDisabled = createSelector(
  [state => state.account.createAccount],
  createAccount => {
    return createAccount.fetching;
  }
);

/**
 * Decide if the stepper nav next button is disabled
 */
export const getNextButtonDisabled = createSelector(
  [
    state => state.uiState.activeStep,
    state => state.account.createAccount.fetching,
    state => state.account.billingAddress.response.status
    // TODO: Product data call failures etc. state.mobile.productData.xxxxx.response.status = "error"
  ],
  (activeStep, createAccountFetching, billingAddressStatus) => {
    if (createAccountFetching) return true;
    if (billingAddressStatus !== "success" && activeStep === STEP_DELIVERY)
      return true;
    return false;
  }
);

/**
 * Decide if the save and exit buttons should be disabled
 * Hide it when we are creating the account or the order/orderproducts
 * Probably going to have more conditions added to this over time
 */
export const getSaveAndExitButtonDisabled = createSelector(
  [
    state => state.account.createAccount,
    state => state.order.recalculatePrices,
    state => state.order.orderStatus,
    state => state.mobile.configs,
    state => state.wlrBroadband.configurations,
    state => state.hardwareProducts.configurations,
    state => state.universalProducts.configurations,
    state => state.monitoringService.configurations,
    state => state.ethernetProducts.configurations
  ],
  (
    createAccount,
    recalculatePrices,
    orderStatus,
    mobileConfigs,
    wlrBBConfigs,
    hardwareConfigs,
    universalConfigs,
    monitoringServiceConfigs,
    ethernetConfigs
  ) => {
    if (
      createAccount.fetching ||
      recalculatePrices.fetching ||
      orderStatus.fetching
    ) {
      return true;
    }

    let productsAreBeingConfigured = false;

    if (Array.isArray(mobileConfigs)) {
      mobileConfigs.forEach(config => {
        if (config.orderProduct?.fetching) {
          productsAreBeingConfigured = true;
        }
      });
    }

    if (Array.isArray(wlrBBConfigs)) {
      wlrBBConfigs.forEach(config => {
        if (
          config.addWlrAppointment?.fetching ||
          config.addBroadbandAppointment?.fetching ||
          config.orderProduct?.wlr?.fetching ||
          config.orderProduct?.broadband?.fetching
        ) {
          productsAreBeingConfigured = true;
        }
      });
    }

    if (Array.isArray(hardwareConfigs)) {
      hardwareConfigs.forEach(config => {
        if (config.orderProduct?.fetching) {
          productsAreBeingConfigured = true;
        }
      });
    }

    if (Array.isArray(universalConfigs)) {
      universalConfigs.forEach(config => {
        if (config.orderProduct?.fetching) {
          productsAreBeingConfigured = true;
        }
      });
    }

    if (Array.isArray(monitoringServiceConfigs)) {
      monitoringServiceConfigs.forEach(config => {
        if (config.orderProduct?.fetching) {
          productsAreBeingConfigured = true;
        }
      });
    }

    if (Array.isArray(ethernetConfigs)) {
      ethernetConfigs.forEach(config => {
        if (config.purchaseIsRequesting) {
          productsAreBeingConfigured = true;
        }
      });
    }

    return productsAreBeingConfigured;
  }
);
