import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import WifiIcon from "@material-ui/icons/Wifi";
import SettingsPowerIcon from "@material-ui/icons/SettingsPower";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import { ReduxAccountSettingsEnum } from "../enums";
import { ProductTypeCardType } from "./types";

export const mobile: ProductTypeCardType = {
  id: ReduxAccountSettingsEnum.MOBILE,
  label: "Mobile",
  dataCy: "product-type-mobile",
  Icon: PhoneIphoneIcon
};

export const wlrBroadband: ProductTypeCardType = {
  id: ReduxAccountSettingsEnum.BROADBAND,
  label: "Lines, Calls & Broadband",
  dataCy: "product-type-broadband",
  Icon: WifiIcon
};

export const hardware: ProductTypeCardType = {
  id: ReduxAccountSettingsEnum.HARDWARE,
  label: "Hardware",
  dataCy: "product-type-hardware",
  Icon: SettingsPowerIcon
};

export const ethernet: ProductTypeCardType = {
  id: ReduxAccountSettingsEnum.ETHERNET,
  label: "Ethernet",
  dataCy: "product-type-ethernet",
  Icon: SettingsEthernetIcon
};

export const monitoring: ProductTypeCardType = {
  id: ReduxAccountSettingsEnum.MONITORING,
  label: "Monitoring",
  dataCy: "product-type-monitoring",
  Icon: ShowChartIcon
};

export const universal: ProductTypeCardType = {
  id: ReduxAccountSettingsEnum.UNIVERSAL,
  label: "Universal",
  dataCy: "product-type-universal",
  Icon: FormatAlignJustifyIcon
};
