import React from "react";
import TextFieldLowUpdate from "../../../components/TextFieldLowUpdate";
import SliderLowUpdate from "../../../components/SliderLowUpdate";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Grid } from "@material-ui/core";
import { isValidMonetaryAmount } from "../../../helpers/validation";

function DiscountFields({
  discountType,
  discountValue,
  priceType,
  setDiscount,
  minPrice,
  fullPrice,
  maxPercent
}) {
  let title = "Discount";
  if (priceType === "recurring") title = "Recurring Discount";
  if (priceType === "activation_fee.one_off") title = "Activation Fee Discount";

  const maxDiscount = parseFloat(fullPrice) - parseFloat(minPrice);

  return (
    <>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>{title}</InputLabel>
            <Select
              value={discountType || false}
              onChange={event =>
                setDiscount(priceType, event.target.value, null)
              }
            >
              <MenuItem value={false}>None</MenuItem>
              <MenuItem value="DiscountPercentage">
                Discount Percentage
              </MenuItem>
              <MenuItem value="DiscountAmount">Discount Amount</MenuItem>
              <MenuItem value="SpecifyPrice">Specify Price</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {discountType === "SpecifyPrice" && (
          <Grid item sm={6}>
            <TextFieldLowUpdate
              label="Specify Price"
              value={discountValue || ""}
              onBlur={event => setDiscount(priceType, null, event.target.value)}
              validate={value => {
                if (value && !isValidMonetaryAmount(value))
                  return "Please enter a valid amount.";
                if (parseFloat(value) < minPrice)
                  return `Price too low. Minimum is £${minPrice}`;
              }}
              type="number"
              fullWidth
              margin="normal"
            />
          </Grid>
        )}
        {discountType === "DiscountAmount" && (
          <Grid item sm={6}>
            <TextFieldLowUpdate
              label="Discount Amount"
              value={discountValue || ""}
              onBlur={event => setDiscount(priceType, null, event.target.value)}
              validate={value => {
                if (value && !isValidMonetaryAmount(value))
                  return "Please enter a valid amount.";
                if (parseFloat(value) > maxDiscount)
                  return `Discount too high. Maximum is £${maxDiscount.toFixed(
                    2
                  )}`;
              }}
              type="number"
              fullWidth
              margin="normal"
            />
          </Grid>
        )}
      </Grid>
      {discountType === "DiscountPercentage" && (
        <Grid item sm={12}>
          <SliderLowUpdate
            min={0}
            max={maxPercent}
            step={1}
            value={discountValue || 0}
            onDragStop={value => {
              setDiscount(priceType, null, value);
            }}
            data-cy="discountSlider"
          />
        </Grid>
      )}
    </>
  );
}

export default DiscountFields;
