import React, { Component } from "react";
import { connect } from "react-redux";
import { ExpandingCard } from "@akj-dev/design-system";
import Checkbox from "@material-ui/core/Checkbox";
import { getAddressLine } from "../../../helpers/addresses";
import {
  getSelectedProductData,
  removeWlrConfiguration,
  removeResignLocation
} from "../../../store/wlrBroadband/actions";
import ConfigurationForm from "./ConfigurationForm/";
import ConfigTableRow from "./ConfigTableRow";
import * as Sentry from "@sentry/browser";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography, Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import HomeIcon from "@material-ui/icons/Home";
import { RESIGN } from "../../../store/wlrBroadband/constants";
import ResignsConfigurationTable from "./ResignsConfigurationTable";
import _ from "lodash";
import {
  TargetConfigsContext,
  TargetConfigsProvider
} from "../context/TargetConfigsContext";

class WlrBroadbandComponent extends Component {
  static defaultProps = {};
  static propTypes = {};

  static contextType = TargetConfigsContext;

  state = {
    formOpen: false
  };

  componentDidMount() {
    Sentry.addBreadcrumb({
      message: `Step 2 Lines, Calls & Broadband mounted`,
      category: "action"
    });
    this.props.getSelectedProductData();
  }

  toggleSingle = configIndex => {
    const { setTargetConfigs, targetConfigs } = this.context;

    const i = targetConfigs.indexOf(configIndex);
    if (i !== -1) {
      setTargetConfigs([
        ...targetConfigs.slice(0, i),
        ...targetConfigs.slice(i + 1)
      ]);
    } else {
      setTargetConfigs([...targetConfigs, configIndex]);
    }
  };

  /**
   * Only configs with product IDs can be edited (i.e. not "Same product" ones) so....
   */
  allCheckable = () => {
    const checkable = [];
    this.props.configurations.forEach((c, i) => {
      if (c.wlrProductId || c.broadbandProductId) checkable.push(i);
    });
    return checkable;
  };

  toggleAll = () => {
    const { configurations } = this.props;
    const { targetConfigs, setTargetConfigs } = this.context;

    if (configurations.length > targetConfigs.length) {
      setTargetConfigs(this.allCheckable());
    } else {
      setTargetConfigs([]);
    }
  };

  selectSingle = configIndex => {
    this.context.setTargetConfigs([configIndex]);
  };

  configCount = () =>
    this.props.configurations.filter(c => !!c.resignProductType).length;

  openForm = () => {
    this.setState({ formOpen: true });
  };

  closeForm = () => {
    this.setState({ formOpen: false });
  };

  render() {
    const {
      locations,
      configurations,
      removeWlrConfiguration,
      getSelectedProductData,
      removeResignLocation
    } = this.props;

    const { targetConfigs } = this.context;

    if (this.state.formOpen)
      return <ConfigurationForm closeAction={this.closeForm} />;
    return (
      <div>
        <Typography variant="h3">Configure your products</Typography>
        <Box my={1} style={{ opacity: targetConfigs.length < 2 ? 0.5 : 1 }}>
          <span className="label">Bulk Actions</span>
          <IconButton
            onClick={this.openForm}
            disabled={targetConfigs.length < 2}
            data-cy="wlrBroadbandBulkEditButton"
          >
            <EditIcon />
          </IconButton>
        </Box>
        {configurations.filter(config => config.productInstanceId).length >
          0 && (
          <ResignsConfigurationTable
            selectedConfigs={targetConfigs}
            configCount={this.configCount()}
            checked={targetConfigs.length === configurations.length}
            toggleAll={this.toggleAll}
            toggleAction={index => this.toggleSingle(index)}
            editAction={index => {
              this.selectSingle(index);
              this.openForm();
            }}
            deleteAction={locationIndex => removeResignLocation(locationIndex)}
            retryAction={getSelectedProductData}
          />
        )}
        {locations.map((location, locationIndex) => {
          if (location.type === RESIGN || _.isEmpty(location.address))
            return false;
          return (
            <ExpandingCard
              title={getAddressLine(location.address) || "New Location"}
              subtitle={location.type}
              avatar={<HomeIcon />}
              key={locationIndex}
              defaultExpanded
            >
              <Table size="small" data-cy="wlrBroadbandStep2Table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={targetConfigs.length === configurations.length}
                        onChange={this.toggleAll}
                        data-cy="wlrBroadbandToggleAll"
                      />
                    </TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Total One Off Price</TableCell>
                    <TableCell>Total Recurring Price</TableCell>
                    <TableCell>Broadband</TableCell>
                    <TableCell>Configured?</TableCell>
                    <TableCell width="112"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {configurations.map((c, configIndex) => {
                    if (c.locationIndex !== locationIndex) return false;
                    return (
                      <ConfigTableRow
                        key={configIndex}
                        c={c}
                        configIndex={configIndex}
                        checked={targetConfigs.indexOf(configIndex) !== -1}
                        toggleAction={() => this.toggleSingle(configIndex)}
                        editAction={() => {
                          this.selectSingle(configIndex);
                          this.openForm();
                        }}
                        deleteAction={() => removeWlrConfiguration(configIndex)}
                        retryAction={getSelectedProductData}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </ExpandingCard>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    locations: state.wlrBroadband.locations,
    configurations: state.wlrBroadband.configurations
  };
};

const ConnectedWrlBroadband = connect(mapStateToProps, {
  getSelectedProductData,
  removeWlrConfiguration,
  removeResignLocation
})(WlrBroadbandComponent);

const WlrBroadband = () => (
  <TargetConfigsProvider>
    <ConnectedWrlBroadband />
  </TargetConfigsProvider>
);

export default WlrBroadband;
