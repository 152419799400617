import contractLength from "./contractLength";
import newConfigs from "./newConfigs";
import resigns from "./resigns";
import boltOns from "./boltOns";
import productConfig from "./productConfig";
import validation from "./validation";
import daisyFresh from "./daisyFresh";
import reservedNumbers from "./reservedNumbers";
import order from "./order";
import { ALB_NOT_REQUIRED } from "../constants";

const initialState = {
  contractCoTerminus: false,

  // Available mobile products to order
  mobileSearch: {
    fetching: false,
    response: {}
  },

  // The product we use to resign products without changes
  resignProductSearch: {
    fetching: false,
    response: {}
  },

  // Resignable products on account
  productInstances: {
    fetching: false,
    response: {}
  },

  // Reserved numbers
  reservedNumbersSearch: {
    Vodafone: {
      fetching: false,
      response: false
    },
    O2: {
      fetching: false,
      response: false
    }
  },

  /**
   * Configs user is creating. Structure something like:
   * {
   *   productId: <product ID>,
   *   resignId: <id from product instance, if it's a resign>
   *   resignType: <RESIGN_WITH_CHANGES || RESIGN_WITHOUT_CHANGES> ....if it's a resign
   *   properties: {
   *     <property name>: <value> ....taken from dynamic_properties like WLR+BB
   *     ...
   *   },
   *   selectedCliBoltOns: {}, // when there are / can be any. Not for all products
   *   pacCodeCheck: {
   *     fetching: false,
   *     response: false,
   *   },
   *   simValidCheck: {
   *     fetching: false,
   *     response: false,
   *   },
   *   resignPropertyValues: { // This is a ProductData call, but only partial response is used, merged into properties: {}
   *      fetching: false,
   *      status: false,
   *   }
   */
  configs: [],

  // Available account bolt ons
  boltOnSearch: {
    fetching: false,
    response: false
  },

  // Existing account bolt-ons
  accountBoltOns: {
    fetching: false,
    response: false
  },

  /**
   * Account level bolt-ons user selects
   *
   * each node can be either:
   *  - false: do nothing
   *  - REMOVE_EXISTING: tell DC to cancel existing bolt-on
   *  - <id>: new product ID
   */
  selectedBoltOns: {
    2: {
      // Vodafone (service provider ID)
      data: ALB_NOT_REQUIRED,
      voice: ALB_NOT_REQUIRED,
      additional_bundle_1: ALB_NOT_REQUIRED,
      text: ALB_NOT_REQUIRED
    },
    1: {
      // O2
      data: ALB_NOT_REQUIRED,
      voice: ALB_NOT_REQUIRED,
      additional_bundle_1: ALB_NOT_REQUIRED,
      text: ALB_NOT_REQUIRED
    }
  },

  // Optional account level bolt on start date
  boltOnStartDate: false,

  // Optional resign start date
  resignStartDate: false,

  /**
   * Responses from bolt-on order product calls. Like...
   * {
   *   [productId]: {
   *     fetching: false,
   *     response: false,
   *   },
   *   ...
   * }
   */
  orderBoltOn: {},

  /**
   * Product data needs fetching for all products selected, so structure =
   * {
   *   [productId]: {
   *     fetching: false,
   *     response: false,
   *   }
   * }
   * We can't use product data responses for pricing like we do in WLR+BB,
   * as the values don't seem to update when CLI bolt-ons etc. are applied.
   * Hence one query per product ID, used over all matching configs
   */

  productData: {},

  /**
   * Data about CLI Bolt-ons that can be added to each product is a separate thing again,
   * hence needs fetching in a similar way to product data
   * {
   *   [productId]: {
   *     fetching: false,
   *     response: false,
   *   }
   * }
   */
  cliBoltOnSearch: {},

  /**
   * Simple lookup call to get the product used for Daisy Fresh
   * (which accumulates hardware credit over the course of the contract)
   */
  daisyFreshSearch: {
    fetching: false,
    response: false
  },

  /**
   * Lookup call to get the hardware credit product, which will be used to apply
   * any HW credit specified by Daisy Fresh
   */
  hardwareCreditProductSearch: {
    fetching: false,
    response: false
  },

  /**
   * Call to add the hardware credit to the order
   */
  hardwareCredit: {
    fetching: false,
    response: {}
  },

  /**
   * Store the user selected amounts required, which we'll use later to compute final price
   * ...which will be added to the order as a bolt-on for each mobile config
   */
  daisyFreshAmounts: {
    hardwareCredits: 0,
    terminationFees: 0, // Termination fee from existing provider that we're offsetting.
    etf: 0, // Early termination fee, for resigns.
    limitExceeded: false
  },

  /**
   * Annoying special case field - users must confirm they've offered a bill cap before proceeding.
   * This does not exist in DC product data. Just logic in GS.
   */
  billCapConfirmed: false,

  /**
   * Special case for DWS Resellers - they have to accept additional terms to proceed with mobile orders.
   * TP36005
   */
  dwsTermsAccepted: false
};

// Combine the chunks of reducer together (run sequentially)
export default (state = initialState, action) => {
  return [
    contractLength,
    newConfigs,
    resigns,
    boltOns,
    productConfig,
    validation,
    daisyFresh,
    reservedNumbers,
    order
  ].reduce((nextState, reducer) => reducer(nextState, action), state);
};
