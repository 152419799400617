import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBroadbandProduct } from "../../../../../../../../store/wlrBroadband/actions";
import { VirtualTable } from "../../../../../../../../components/VirtualTable";
import { ActiveSort } from "../../../../../../../../components/VirtualTable/types";
import { VirtualTableSortOrder } from "../../../../../../../../components/VirtualTable/enums";
import {
  ReduxWlrLocation,
  WlrLocationBroadbandProduct,
  WlrLocationProduct,
  WlrLocationProductConfig
} from "../../../../../types";
import { RenderBroadbandTableRow } from "./BroadbandProductTableRow";

export interface BroadbandProductTableProps {
  location: ReduxWlrLocation;
  product: WlrLocationProduct;
  configurations: WlrLocationProductConfig[];
}

export const BroadbandProductTable = ({
  location,
  product,
  configurations
}: BroadbandProductTableProps) => {
  const [activeSort] = useState<ActiveSort>({
    sortIndex: "name",
    order: VirtualTableSortOrder.ASC
  });
  const dispatch = useDispatch();
  const contractLength: number = useSelector(
    (state: any) => state.wlrBroadband.contractLength
  );
  const isMpfProduct = product.first_broadband_component?.type === "MPF";
  const headers = tableHeaders(isMpfProduct);

  const broadbandProducts: WlrLocationBroadbandProduct[] =
    location.broadbandSearch?.response?.products ?? [];

  const handleBroadbandProduct = useCallback(
    (configId: number, broadbandId: string) => {
      dispatch(setBroadbandProduct(configId, broadbandId));
    },
    [dispatch]
  );

  return (
    <VirtualTable
      headers={headers}
      activeSort={activeSort}
      handleSort={() => {}}
      data={{
        contractLength,
        location: location,
        products: configurations,
        locationProduct: product,
        isMpfProduct,
        broadbandProducts,
        handleBroadbandProduct
      }}
      tableSize={400}
      itemSize={50}
      itemCount={configurations.length}
      RenderTableRow={RenderBroadbandTableRow}
    />
  );
};

const tableHeaders = (isMpfProduct: boolean) => [
  { label: "Product", sortIndex: "name", disabled: true, width: 250 },
  {
    label: "Normally Available Download Speeds",
    sortIndex: "name",
    disabled: true,
    width: 250
  },
  {
    label: "Normally Available Upload Speeds",
    sortIndex: "name",
    disabled: true,
    width: 250
  },
  {
    label: "Minimum Guaranteed Download Speed",
    sortIndex: "name",
    disabled: true,
    width: 250
  },
  { label: "Lead Time", sortIndex: "name", disabled: true, width: 250 },
  ...(isMpfProduct
    ? []
    : [
        {
          label: "Select Broadband",
          sortIndex: "broadband",
          disabled: true,
          width: 250
        }
      ]),
  { label: "Price From", sortIndex: "price", disabled: true, width: 150 }
];
