import React from "react";
import Chip from "../../../../components/Shared/mobile/Chip";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
  SAME_PRODUCT
} from "../../../../store/wlrBroadband/constants";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { useTheme } from "@material-ui/core/styles";

const ResignTypeChip = ({ configuration }) => {
  const theme = useTheme();
  switch (configuration.resignProductType) {
    case NEW_PRODUCT:
      return (
        <Chip
          title="New Product"
          color={theme.palette.success.main}
          Icon={SettingsBackupRestoreIcon}
          outline={true}
        />
      );
    case SAME_PRODUCT_WITH_CHANGES:
      return (
        <Chip
          title="Same Product With Changes"
          color={theme.palette.info.main}
          Icon={AutorenewIcon}
          outline={true}
        />
      );
    case SAME_PRODUCT:
      return (
        <Chip
          title="Same Product"
          color={theme.palette.info.main}
          Icon={AutorenewIcon}
          outline={true}
        />
      );
    default:
      return null;
  }
};

export default ResignTypeChip;
