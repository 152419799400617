import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import HomeIcon from "@material-ui/icons/Home";
import { ExpandingCard } from "@akj-dev/design-system";
import { getAddressLine } from "../../../../helpers/addresses";
import ChooseFTTPProduct from "../../../../containers/StepChoose/wlrBroadband/ChooseFTTPProduct";
import ChooseSOGEAProduct from "../../../../containers/StepChoose/wlrBroadband/ChooseSOGEAProduct";
import ChooseLineProducts from "../../../../containers/StepChoose/wlrBroadband/ChooseLineProducts";
import Spinner from "../../../../components/Shared/Spinner";
import { getAccountSettings } from "../../../../store/account/selectors";
import { getSingleLineProducts } from "../../../../store/wlrBroadband/selectors";
import {
  removeLocation,
  removeProductsForLocation,
  setLocationAddress,
  setLocationCli,
  setLocationType
} from "../../../../store/wlrBroadband/actions";
import { ReduxWlrLocation } from "../types";
import { WlrLocationTypeEnum } from "../enums";
import { WlrLocationInstallType } from "./WlrLocationInstallType";
import { WlrLocationFindLocation } from "./WlrLocationFindLocation";
import { SogeaTerms } from "../SogeaTerms";

export interface WlrLocationProps {
  location: ReduxWlrLocation;
  locationIndex: number;
  open: boolean;
  handleLocationOpened: (i: number) => void;
}
/**
 * WLR & Broadband location
 * Chooses tye of install and sets address.
 * Wraps different product selection components depending on selection and run mode
 *
 * Product info:
 * https://miro.com/app/board/o9J_lRZqr3k=/
 *
 */
export const WlrLocation = ({
  location,
  locationIndex,
  open,
  handleLocationOpened
}: WlrLocationProps) => {
  // Redux State
  const contractLength: number = useSelector(
    (state: any) => state.wlrBroadband.contractLength
  );
  const configurations = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );
  const lineSearchFetching = useSelector(
    (state: any) => state.wlrBroadband.lineSearch.fetching
  );
  const settings = useSelector(getAccountSettings);
  const singleLineProducts = useSelector(getSingleLineProducts);

  const showSogeaTerm =
    settings.dws_reseller_enabled === "1" &&
    (location.type === WlrLocationTypeEnum.NEW_SOGEA ||
      location.type === WlrLocationTypeEnum.EXISTING_SOGEA);

  // Redux Actions
  const dispatch = useDispatch();
  const handleLocationType = (locationType: string) =>
    dispatch(setLocationType(locationIndex, locationType));
  const handleLocationCli = (index: number, newCli: string) =>
    dispatch(setLocationCli(index, newCli));
  const saveAddress = (address: any) =>
    dispatch(setLocationAddress({ index: locationIndex, address }));

  return (
    <ExpandingCard
      title={getAddressLine(location.address) || "New Location"}
      subtitle={location.type}
      avatar={lineSearchFetching ? <Spinner /> : <HomeIcon />}
      data-cy={`Location-${locationIndex}`}
      expanded={open}
      onHeaderClick={() => handleLocationOpened(locationIndex)}
      headerAction={
        <Button
          color="primary"
          startIcon={<DeleteIcon />}
          onClick={e => {
            e.stopPropagation();
            dispatch(removeLocation(locationIndex));
          }}
        >
          Remove This Location
        </Button>
      }
    >
      {open && (
        <>
          <WlrLocationInstallType
            contractLength={contractLength}
            location={location}
            setLocationType={handleLocationType}
            withSingleLineProducts={singleLineProducts.length > 0}
          />
          <WlrLocationFindLocation
            location={location}
            locationIndex={locationIndex}
            handleLocationCli={handleLocationCli}
            saveAddress={saveAddress}
          />
          <Grid item>
            {location.type === WlrLocationTypeEnum.NEW_FTTP ? (
              <ChooseFTTPProduct locationIndex={locationIndex} />
            ) : location.type === WlrLocationTypeEnum.NEW_SOGEA ? (
              <ChooseSOGEAProduct locationIndex={locationIndex} />
            ) : (
              <ChooseLineProducts locationIndex={locationIndex} />
            )}
            {showSogeaTerm && <SogeaTerms locationIndex={locationIndex} />}
          </Grid>
          <Box display="flex" justifyContent="space-between" mt={1}>
            {configurations.find(
              (config: any) => config.locationIndex === locationIndex
            ) && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() =>
                  dispatch(removeProductsForLocation(locationIndex))
                }
              >
                Remove All Products
              </Button>
            )}
          </Box>
        </>
      )}
    </ExpandingCard>
  );
};
