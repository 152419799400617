import React, { Component } from "react";
import { connect } from "react-redux";
import { StatusChip } from "@akj-dev/design-system";
import SelectTier from "../../../components/Step1/MonitoringService/SelectTier";
import {
  addProduct,
  removeProduct,
  requestProductSearch
} from "../../../store/monitoringService/actionCreators";
import {
  getIndexOfProductConfig,
  getProductConfigCount,
  getSortedProducts
} from "../../../store/monitoringService/selectors";
import { Grid } from "@material-ui/core";
import { IndexedCardInfo } from "../../../screens/shared/indexed-card-info";

class Products extends Component {
  componentDidMount() {
    if (this.props.productCount < 1) {
      this.props.requestProductSearch();
    }
  }

  render() {
    const {
      configs,
      products,
      productCount,
      addProduct,
      removeProduct
    } = this.props;
    return (
      <IndexedCardInfo index={"1"} title={"Select devices to be monitored"}>
        {products.response.status === "error" ? (
          <StatusChip
            type="error"
            title="Error"
            message="Something went wrong finding logic monitor products."
            retry={requestProductSearch}
          />
        ) : (
          productCount && (
            <div>
              <Grid container spacing={2}>
                {getSortedProducts(products.response.products).map(
                  (item, index) => {
                    const productConfigCount = getProductConfigCount(
                      configs,
                      item.id
                    );
                    return (
                      <Grid key={index} item sm={4}>
                        <SelectTier
                          name={item.name}
                          price={
                            item.price
                              .first_bill_recurring_price_with_promotions ||
                            "0.00"
                          }
                          description={
                            item.description || "Sample description."
                          }
                          quantity={productConfigCount}
                          index={index}
                          addProduct={() =>
                            addProduct(
                              item.id,
                              item.price.one_off_price_with_promotions,
                              item.price
                                .first_bill_recurring_price_with_promotions
                            )
                          }
                          removeProduct={() =>
                            removeProduct(
                              getIndexOfProductConfig(configs, item.id)
                            )
                          }
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </div>
          )
        )}
      </IndexedCardInfo>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.monitoringService.productSearch,
    productCount: (
      state.monitoringService.productSearch.response.products || []
    ).length,
    configs: state.monitoringService.configs
  };
};

export default connect(mapStateToProps, {
  requestProductSearch,
  addProduct,
  removeProduct
})(Products);
