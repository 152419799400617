import React from "react";
import { Box, Button } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import TextsmsIcon from "@material-ui/icons/Textsms";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import { useDispatch, useSelector } from "react-redux";
import { getHasContacts } from "../../../../../../store/account/selectors";
import {
  updateConfigProperty,
  validateConfigProperty
} from "../../../../../../store/mobile/actionCreators";
import BulkDynamicField from "../../BulkDynamicField";
import SelectContact from "../../../../../../components/Shared/Contact/SelectContact";

type Props = {
  targetConfigs: string[];
};

export const StandardBillingNotification = ({ targetConfigs }: Props) => {
  const dispatch = useDispatch();

  // store values
  const {
    additional_notification,
    notification_preference,
    notification_contact
  } = useSelector((state: any) => state.mobile.configs)[
    targetConfigs[0]
  ].properties;

  const validationError = useSelector((state: any) => state.mobile.configs)[
    targetConfigs[0]
  ].validation?.notification_contact;

  const hasContacts = useSelector(getHasContacts);
  const contacts = useSelector((state: any) => state.account.contacts);

  // actions
  const resetContact = () => {
    dispatch(updateConfigProperty("notification_contact", "", targetConfigs));
  };

  const validateContact = () => {
    dispatch(validateConfigProperty("notification_contact", targetConfigs));
  };

  const updateContact = (value: string) => {
    dispatch(
      updateConfigProperty("notification_contact", value, targetConfigs)
    );
  };

  const useEBillingAddress = () => {
    const contact = contacts.response.data.contacts.find(
      (c: any) => c.cyclone_contact == 1 // eslint-disable-line eqeqeq
    )?.email1;
    updateContact(contact);
    validateContact();
  };

  const setAccountContact = (id: string) => {
    updateContact(id);
    validateContact();
  };

  return (
    <>
      <BulkDynamicField propertyName="additional_notification" />
      {additional_notification == 1 && ( // eslint-disable-line eqeqeq
        <div>
          <BulkDynamicField
            propertyName="notification_preference"
            afterUpdate={resetContact}
          />
          {(() => {
            switch (notification_preference) {
              case "email":
                return (
                  <div>
                    <Button
                      variant="contained"
                      disabled={!hasContacts}
                      onClick={useEBillingAddress}
                    >
                      Use e-billing address
                    </Button>
                    <Box display="flex" alignItems="center">
                      <Box mr={1}>
                        <EmailIcon />
                      </Box>
                      <BulkDynamicField propertyName="notification_contact" />
                    </Box>
                  </div>
                );
              case "sms":
                return (
                  <Box display="flex" alignItems="center">
                    <Box mr={1}>
                      <TextsmsIcon />
                    </Box>
                    <BulkDynamicField propertyName="notification_contact" />
                  </Box>
                );
              case "letter":
                return (
                  <Box display="flex" alignItems="center" mt={1}>
                    <Box mr={1}>
                      <ContactMailIcon />
                    </Box>
                    <Box flexGrow={1}>
                      <SelectContact
                        current={notification_contact}
                        handleUpdate={setAccountContact}
                        errorText={validationError}
                      />
                    </Box>
                  </Box>
                );
              default:
                return false;
            }
          })()}
        </div>
      )}
    </>
  );
};
