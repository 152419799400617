import React, { FC } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import { WlrLocationProductConfig } from "../../types";
import { WlrLocationTypeEnum } from "../../enums";
import { ProductCard } from "../../../../shared/ProductCard";
import { BroadbandSelect } from "./BroadbandSelect";

export interface WlrLocationProductCardProps {
  locationIndex: number;
  productId: WlrLocationTypeEnum;
  title: string;
  subtitle?: string;
  handleClick: () => void;
}

export const WlrLocationProductCard: FC<WlrLocationProductCardProps> = ({
  locationIndex,
  productId,
  title,
  subtitle,
  handleClick
}) => {
  const classes = useStyles();

  const configurations = useSelector<any, WlrLocationProductConfig[]>(
    state => state.wlrBroadband.configurations ?? []
  );
  const productConfiguration = configurations.find(
    c => c.wlrProductId === productId && c.locationIndex === locationIndex
  );

  const selected = !!productConfiguration;

  return (
    <ProductCard
      dataCy={`card-${title}`}
      checked={selected}
      handleClick={selected ? undefined : handleClick}
    >
      <div className={classes.body}>
        <Typography>{title}</Typography>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
        {selected && (
          <BroadbandSelect
            locationIndex={locationIndex}
            productId={productId}
            broadbandProductId={productConfiguration?.broadbandProductId}
          />
        )}
      </div>
    </ProductCard>
  );
};

const useStyles = makeStyles({
  body: {
    height: 100
  },
  subtitle: {
    fontSize: "0.75rem"
  }
});
