import * as actionTypes from "./actionTypes";
import * as WlrBroadbandAPI from "../../api/v1/wlrBroadband";
import _ from "lodash";
import {
  BROADBAND_ONLY,
  EXISTING_LINE,
  NEW_FTTP,
  NEW_PRODUCT,
  NUMBER_RES_NEXT,
  SAME_PRODUCT_WITH_CHANGES,
  TRANSFER
} from "./constants";
import {
  getDCOrderParams,
  getDynamicPropertyValue,
  getIsMPFLine
} from "./selectors";
import { addWeekdays } from "../../helpers/date";
import * as OrderProductAPI from "../../api/v1/orderProduct";
import { EXISTING_SOGEA, NEW_SOGEA, RESIGN } from "./constants";
import * as ProductAPI from "../../api/v1/product";
import { getAccountId } from "../order/selectors";
import { getAccountSettings } from "../account/selectors";
import newConfiguration from "./reducer/newConfiguration";
import setBroadbandProductState from "./reducer/setBroadbandProduct";
import {
  hasLocationAddress,
  hasProductInstanceAddress,
  mapToProductInstanceAddress,
  mapToLocationAddress
} from "../../helpers/addresses";

export const setContractLength = contractLength => ({
  type: actionTypes.SET_CONTRACT_LENGTH,
  contractLength
});

export const addLocation = () => ({ type: actionTypes.ADD_LOCATION });

export const removeLocation = index => ({
  type: actionTypes.REMOVE_LOCATION,
  index
});

export const removeProductsForLocation = index => ({
  type: actionTypes.REMOVE_PRODUCTS_FOR_LOCATION,
  index
});

/**
 * Set Location Type
 * @param index - Location Index
 * @param locationType
 */
export const setLocationType = (index, locationType) => ({
  type: actionTypes.SET_LOCATION_TYPE,
  index,
  locationType
});

export const setLocationAddress = ({ index, address, isResign = false }) => ({
  type: actionTypes.SET_LOCATION_ADDRESS,
  index,
  address,
  isResign
});

export const setLocationCli = (index, cli) => ({
  type: actionTypes.SET_LOCATION_CLI,
  index,
  cli
});

export const addResignLocation = (productInstance, resignProductType) => async (
  dispatch,
  getState
) => {
  const { pin } = productInstance;
  const index = getState().wlrBroadband.locations.findIndex(
    location => location.type === RESIGN && location.cli.value === pin
  );
  const location = getState().wlrBroadband.locations[index];
  if (index === -1)
    dispatch({ type: actionTypes.ADD_RESIGN_LOCATION, productInstance });
  if (
    resignProductType === SAME_PRODUCT_WITH_CHANGES ||
    resignProductType === NEW_PRODUCT
  ) {
    const hasPostcode =
      hasLocationAddress({ location, isPostcodeOnly: true }) ||
      hasProductInstanceAddress(productInstance, true);
    if (!hasPostcode) {
      dispatch(getTagsCheck(pin));
    }

    if (
      resignProductType === NEW_PRODUCT &&
      (hasLocationAddress({ location }) ||
        hasProductInstanceAddress(productInstance))
    ) {
      dispatch(getLineAvailabilityForResign(productInstance));
      dispatch(doBroadbandSearchForResign(productInstance));
    }
  }
};

export const removeResignLocation = index => ({
  type: actionTypes.REMOVE_RESIGN_LOCATION,
  index
});

export const addWlrConfiguration = (locationIndex, productId) => ({
  type: actionTypes.ADD_WLR_CONFIGURATION,
  locationIndex,
  productId
});

export const removeWlrConfiguration = index => ({
  type: actionTypes.REMOVE_WLR_CONFIGURATION,
  index
});

export const setNewLineConfiguration = (locationIndex, product, qty) => (
  dispatch,
  getState
) => {
  const productId = product.id;
  const isMpfProduct = product?.first_broadband_component?.type === "MPF";

  const state = getState().wlrBroadband;
  const configurations = state.configurations;
  const productConfigs = configurations.filter(
    config =>
      config.locationIndex === locationIndex &&
      config.wlrProductId === productId
  );
  const restConfigs = configurations.filter(
    config =>
      config.wlrProductId !== productId ||
      config.locationIndex !== locationIndex
  );
  const productConfigsCount = productConfigs.length;

  let newProductConfigs = _.cloneDeep(productConfigs);
  if (productConfigsCount > qty) {
    newProductConfigs = productConfigs.filter((_, i) => i < qty);
  }
  if (productConfigsCount < qty) {
    let newConfig = newConfiguration({ locationIndex, productId }, state);

    // TODO: This is really ugly, but without refactor it's the only option
    if (isMpfProduct) {
      [newConfig] = setBroadbandProductState(
        { configurationIndex: 0, productId, isMpfProduct: true },
        { ...state, configurations: [newConfig] }
      ).configurations;
    }

    const newConfigsLength = qty - productConfigsCount;

    for (let i = 0; i < newConfigsLength; ++i) {
      newProductConfigs.push(_.cloneDeep(newConfig));
    }
  }

  const newConfigs = [...restConfigs, ...newProductConfigs];

  dispatch({
    type: actionTypes.SET_CONFIGURATION,
    configurations: newConfigs
  });
};

export const setOtherConfiguration = (locationIndex, product) => (
  dispatch,
  getState
) => {
  const productId = product.id;
  const isMpfProduct = product?.first_broadband_component?.type === "MPF";

  const state = getState().wlrBroadband;
  const configurations = state.configurations;
  const restConfigs = configurations.filter(
    config =>
      config.wlrProductId !== productId ||
      config.locationIndex !== locationIndex
  );

  let newConfig = newConfiguration({ locationIndex, productId }, state);

  // TODO: This is really ugly, but without refactor it's the only option
  if (isMpfProduct) {
    [newConfig] = setBroadbandProductState(
      { configurationIndex: 0, productId, isMpfProduct: true },
      { ...state, configurations: [newConfig] }
    ).configurations;
  }

  const newConfigs = [...restConfigs, newConfig];

  dispatch({
    type: actionTypes.SET_CONFIGURATION,
    configurations: newConfigs
  });
};

/**
 * Validate CLI format
 * Used on step 1 for line transfers
 * @param index
 */

export const doValidateCli = index => async (dispatch, getState) => {
  const cli = getState().wlrBroadband.locations[index].cli.value;
  if (cli) {
    dispatch({ type: actionTypes.REQUEST_VALIDATE_CLI, index });
    const response = await WlrBroadbandAPI.validateCLI(cli);
    dispatch({ type: actionTypes.RECEIVE_VALIDATE_CLI, index, response });
  }
};

/**
 * Perform Line Search
 * Finds all available line products regardless of location capabilities
 * i.e. WLR, ISDN
 */

export const doLineSearch = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.REQUEST_LINE_SEARCH });
  const response = await WlrBroadbandAPI.lineSearch(
    getState().wlrBroadband.contractLength
  );
  dispatch({ type: actionTypes.RECEIVE_LINE_SEARCH, response });
};

/**
 * Get Line Availability
 * This is for new line installs only. Main piece of info: number of spare pairs,
 * which determines how many products we could install.
 *
 * @param index (location index)
 */

export const getLineAvailability = index => async (dispatch, getState) => {
  const address = getState().wlrBroadband.locations[index].address;
  const account = getAccountId(getState());

  // Check the user's chosen an address before running the call:
  if (!address.cssDatabaseCode || !address.addressReference) {
    return dispatch({
      type: actionTypes.RECEIVE_LINE_AVAILABILITY,
      index,
      response: {
        status: "error",
        message: "Please choose an address first."
      }
    });
  }

  dispatch({ type: actionTypes.REQUEST_LINE_AVAILABILITY, index });
  const response = await WlrBroadbandAPI.lineAvailability(
    address.cssDatabaseCode,
    address.addressReference,
    account
  );
  dispatch({ type: actionTypes.RECEIVE_LINE_AVAILABILITY, index, response });
};

/**
 * Get Installation details
 * For transfers and broadband only provides. Tells us the type of line that's there (service_type)
 * and a load of other things.
 *
 * @param index (location index)
 */

export const getInstallationDetails = index => async (dispatch, getState) => {
  const location = getState().wlrBroadband.locations[index];
  const account = getAccountId(getState());

  // Check the user's chosen an address and postcode before running the call:
  if (!location.address.postcode || !location.cli) {
    return dispatch({
      type: actionTypes.RECEIVE_INSTALLATION_DETAILS,
      index,
      response: {
        status: "error",
        message: "Please choose an address and CLI first."
      }
    });
  }

  dispatch({ type: actionTypes.REQUEST_INSTALLATION_DETAILS, index });
  const response = await WlrBroadbandAPI.installationDetails(
    location.address.postcode,
    location.cli.value,
    null,
    account
  );
  dispatch({ type: actionTypes.RECEIVE_INSTALLATION_DETAILS, index, response });

  // If we don't succeed first time, and this message appears, check if it's a WLTO...
  // No status code for this... Same 'FAILED_REQUEST' for completely incorrect numbers.
  // Let's hope the wording doesn't change.
  // TODO: Saw this message too? "There was a problem accessing the service: Installation DN is not recognised by Openreach"
  // TODO: @jim check the SI API for a suitable code....
  if (
    response.message ===
      "There was a problem accessing the service: Telephone number is not a working line. This indicates either the line has ceased or it has been exported to another operator (e.g. Virgin Media)." ||
    response.message ===
      "Directory Number entered not present. This means the number is not on the BT network (e.g. a Virgin Media line)."
  ) {
    dispatch(getValidateWorkingLineTakeOver(index));
  }
};

/**
 * Get details for a Working Line Takeover
 * i.e. a non-BT line that fails the installationDetails check
 * @param index
 * @returns {function(*, *)}
 */
export const getValidateWorkingLineTakeOver = index => async (
  dispatch,
  getState
) => {
  const location = getState().wlrBroadband.locations[index];

  dispatch({ type: actionTypes.REQUEST_WLTO_DETAILS, index });
  const response = await WlrBroadbandAPI.validateWorkingLineTakeOver(
    location.cli.value,
    location.address.addressReference,
    location.address.cssDatabaseCode,
    getAccountId(getState())
  );
  dispatch({ type: actionTypes.RECEIVE_WLTO_DETAILS, index, response });

  if (getAccountSettings(getState()).dws_reseller_enabled !== "1") {
    // The normal broadband search won't return correct results as it's against the existing line
    // which will be TalkTalk, not what it will become after the takeover. The TAGS check will fail
    // Hence do another one against just the address.
    dispatch(doBroadbandSearch(index, true));
  } else {
    // or if this is a DWS reseller, do nothing here. Broadband Search is taken care of by the Graph
    // TODO: But what about the TAGS check failure mentioned above?
    // Do we need to do the same thing excluding the CLI?
  }
};

/**
 * Perform broadband search
 * Finds available broadband products at a given location
 *
 * @param index
 * @param {boolean} noCli - exclude CLI from request for WLTO orders.
 */
export const doBroadbandSearch = (index, noCli = false) => async (
  dispatch,
  getState
) => {
  const location = getState().wlrBroadband.locations[index];

  dispatch({ type: actionTypes.REQUEST_BROADBAND_SEARCH, index });

  // TODO: Double check correct params for this. All same, with addition of CLI for existing lines?
  // Postcode really needed too?
  const response = await WlrBroadbandAPI.broadbandSearch(
    getState().order.accountId,
    location.address.addressReference,
    location.address.cssDatabaseCode,
    location.address.postcode,
    (location.type === TRANSFER ||
      location.type === BROADBAND_ONLY ||
      location.type === NEW_FTTP) &&
      !noCli &&
      location.cli.value,
    getState().wlrBroadband.contractLength
  );
  dispatch({ type: actionTypes.RECEIVE_BROADBAND_SEARCH, index, response });
};

/**
 * Sets the broadband product belonging to a configuration
 * @param configurationIndex
 * @param productId
 */
export const setBroadbandProduct = (configurationIndex, productId) => ({
  type: actionTypes.SET_BROADBAND_PRODUCT,
  configurationIndex,
  productId
});

/**
 * Get selected product data
 * Iterates over all configurations in all locations, then fires off multiple getProductData actions to do API requests
 * This data is used for field info etc. on configuration forms
 */

export const getSelectedProductData = () => (dispatch, getState) => {
  getState().wlrBroadband.configurations.forEach((c, i) => {
    if (
      c.wlrProductId &&
      c.wlrProductId !== EXISTING_LINE &&
      !getIsMPFLine(c) &&
      c.wlrProductId !== NEW_FTTP &&
      c.wlrProductId !== RESIGN &&
      c.wlrProductId !== NEW_SOGEA &&
      c.wlrProductId !== EXISTING_SOGEA
    )
      dispatch(getProductData(i, "wlr"));
    if (c.broadbandProductId) dispatch(getProductData(i, "broadband"));
  });
};

/**
 * Get product data for either the wlr or bb product in a config (saga)
 * @param configurationIndex
 * @param {string} productType - (wlr || broadband)
 */
export const getProductData = (configurationIndex, productType) => ({
  type: actionTypes.REQUEST_PRODUCT_DATA,
  configurationIndex,
  productType
});

/**
 * Sets a configuration property
 * Used on the step 2 configuration form
 *
 * @param targetConfigs
 * @param productType
 * @param propertyName
 * @param value
 */
export const setProductProperty = (
  targetConfigs,
  productType,
  propertyName,
  value
) => ({
  type: actionTypes.SET_PRODUCT_PROPERTY,
  targetConfigs,
  productType,
  propertyName,
  value
});

export const setPricingScheme = (targetConfig, productType, schemeName) => ({
  type: actionTypes.SET_PRICING_SCHEME,
  targetConfig,
  productType,
  schemeName
});

/**
 * Sets product discount parameters
 *
 * @param {array} targetConfigs
 * @param {'wlr' | 'broadband'} productType
 * @param {'one_off' | 'recurring'} priceType
 * @param {'DiscountPercentage' | 'DiscountAmount' | 'SpecifyPrice'} discountType
 * @param {string | number} value
 * @returns {{targetConfigs: *, priceType: *, discountType: *, type: string, value: *, productType: *}}
 */
export const setProductDiscount = (
  targetConfigs,
  productType,
  priceType,
  discountType,
  value
) => ({
  type: actionTypes.SET_PRODUCT_DISCOUNT,
  targetConfigs,
  productType,
  priceType,
  discountType,
  value
});

export const removeProductDiscount = (
  targetConfigs,
  productType,
  propertyNamePrefix
) => ({
  type: actionTypes.REMOVE_PRODUCT_DISCOUNT,
  targetConfigs,
  productType,
  propertyNamePrefix
});

export const validateProductProperty = (
  targetConfigs,
  productType,
  propertyName,
  dynamicProperty
) => ({
  type: actionTypes.VALIDATE_PRODUCT_PROPERTY,
  targetConfigs,
  productType,
  propertyName,
  dynamicProperty
});

/**
 * Fetch Broadband Appointments
 * Returns a list of time slots for the user to select in the configuration form
 * @param configurationIndex
 */
export const getBroadbandAppointments = configurationIndex => async (
  dispatch,
  getState
) => {
  const config = getState().wlrBroadband.configurations[configurationIndex];
  const location = getState().wlrBroadband.locations[config.locationIndex];
  const productData = config.broadbandProductData.response;
  const estimatedLeadTime = _.get(
    location.broadbandSearch.response.products.find(
      p => p.id === config.broadbandProductId
    ),
    "first_broadband_component.estimated_lead_time.appointment",
    addWeekdays(new Date(), 10, true)
  );
  // Unlike other types, SI have given SOGEA two variations, which are SOGEA_NEW and SOGEA_EXISTING.
  // SOGEA_EXISTING is used for migrations from an existing SoGEA installation, which we can't determine atm.
  // So always use SOGEA_NEW for now. 03/03/2020
  const isSOGEA = productData.broadband.product_component_data.type === "SOGEA";
  const type = isSOGEA
    ? "SOGEA_NEW"
    : productData.broadband.product_component_data.type;
  const site_visit_reason = getDynamicPropertyValue(
    getState(),
    "broadband",
    configurationIndex,
    "bb.site_visit_reason"
  );
  const account = getAccountId(getState());

  dispatch({
    type: actionTypes.REQUEST_BROADBAND_APPOINTMENTS,
    configurationIndex
  });
  const response = await WlrBroadbandAPI.getBBAppointmentAvailability(
    productData.broadband.product_component_data.supplier,
    productData.broadband.product_component_data.supplier_product_ref,
    type +
      (config.broadbandProperties["bb.sim_provide_ref"] &&
      !isSOGEA &&
      !getIsMPFLine(config)
        ? "_SIM2"
        : ""),
    location.address.addressReference,
    location.address.cssDatabaseCode,
    estimatedLeadTime,
    site_visit_reason,
    account
  );
  dispatch({
    type: actionTypes.RECEIVE_BROADBAND_APPOINTMENTS,
    configurationIndex,
    response
  });
};

/**
 * Store the users selection of broadband appointment
 * This index will be used to book the appointment later
 *
 * @param configurationIndex
 * @param selectedIndex
 * @param isSalesPerson
 */
export const setBroadbandAppointment = (
  configurationIndex,
  selectedIndex,
  isSalesPerson = false
) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_BROADBAND_APPOINTMENT,
      configurationIndex,
      selectedIndex,
      isSalesPerson
    });
    dispatch(doRemoteValidation(configurationIndex));
  };
};

/**
 * Fetch WLR Appointments
 * Returns a list of time slots for the user to select in the configuration form
 * @param configurationIndex
 */
export const getWlrAppointments = configurationIndex => async (
  dispatch,
  getState
) => {
  const state = getState();
  const config = state.wlrBroadband.configurations[configurationIndex];
  const location = state.wlrBroadband.locations[config.locationIndex];
  const account = getAccountId(getState());

  dispatch({ type: actionTypes.REQUEST_WLR_APPOINTMENTS, configurationIndex });
  const response = await WlrBroadbandAPI.wlr3GetAppointments(
    getDynamicPropertyValue(state, "wlr", configurationIndex, "service_type"),
    getDynamicPropertyValue(state, "wlr", configurationIndex, "product_type"), // Note DCT10 reports this as BASIC for both, which is in error. On live multi-line is PREMIUM
    getDynamicPropertyValue(
      state,
      "wlr",
      configurationIndex,
      "number_of_channels"
    ),
    location.address.addressReference,
    location.address.cssDatabaseCode,
    _.get(
      config.broadbandProductData.response,
      "broadband.product_component_data.supplier_product_ref"
    ),
    account
  );
  dispatch({
    type: actionTypes.RECEIVE_WLR_APPOINTMENTS,
    configurationIndex,
    response
  });
};

/**
 * Store the users selection of WLR appointment
 * This index will be used to book the appointment later
 *
 * @param configurationIndex
 * @param selectedIndex
 * @param isSalesPerson
 */
export const setWlrAppointment = (
  configurationIndex,
  selectedIndex,
  isSalesPerson = false
) => ({
  type: actionTypes.SET_WLR_APPOINTMENT,
  configurationIndex,
  selectedIndex,
  isSalesPerson
});

/**
 * Get available CLIs at a location for number reservation
 *
 * @param locationIndex
 */
export const getAvailableNumbers = locationIndex => async (
  dispatch,
  getState
) => {
  const location = getState().wlrBroadband.locations[locationIndex];
  const account = getAccountId(getState());
  dispatch({ type: actionTypes.REQUEST_FIND_NUMBERS, locationIndex });
  const response = await WlrBroadbandAPI.findNumbersAtAddress(
    location.address.addressReference,
    location.address.cssDatabaseCode,
    account
  );
  dispatch({ type: actionTypes.RECEIVE_FIND_NUMBERS, locationIndex, response });
};

/**
 * Set the WLR number reservation type.
 * Users can either enter one manually, pick from a list or get the next available one
 *
 * @param configurationIndex
 * @param reservationType
 */
export const setNumberReservationType = (
  configurationIndex,
  reservationType
) => ({
  type: actionTypes.SET_NUMBER_RESERVATION_TYPE,
  configurationIndex,
  reservationType
});

/**
 * Set the CLI the user wants to reserve
 * @param configurationIndex
 * @param number
 */
export const setReservedNumber = (configurationIndex, number) => ({
  type: actionTypes.SET_RESERVED_NUMBER,
  configurationIndex,
  number
});

/**
 * Attempt to reserve a CLI for a WLR install
 * Either the next available one or a specific one selected by the user
 * On success, this will return a reservation reference for us to use during the main WLR order.
 *
 * @param configurationIndex
 */
export const doNumberReservation = configurationIndex => async (
  dispatch,
  getState
) => {
  const config = getState().wlrBroadband.configurations[configurationIndex];
  const location = getState().wlrBroadband.locations[config.locationIndex];
  const account = getAccountId(getState());

  dispatch({ type: actionTypes.REQUEST_RESERVE_NUMBER, configurationIndex });
  let response;
  if (config.numberReservation.type === NUMBER_RES_NEXT) {
    response = await WlrBroadbandAPI.reserveNextNumber(
      // Note exchange code is in location.installationDetails.response.css_exchange_code for existing lines.
      // Number reservation wouldn't happen for those though afaik.
      location.lineAvailability.response.css_exchange_code,
      config.wlrProductData.response.line.product_component_data.service_type,
      location.address.cssDatabaseCode,
      account
    );
  } else {
    response = await WlrBroadbandAPI.reserveNumber(
      location.lineAvailability.response.css_exchange_code,
      config.wlrProductData.response.line.product_component_data.service_type,
      config.numberReservation.selectedNumber,
      account
    );
  }
  dispatch({
    type: actionTypes.RECEIVE_RESERVE_NUMBER,
    configurationIndex,
    response
  });
  dispatch(doRemoteValidation(configurationIndex));
};

/**
 * Add WLR appointment as selected by the user
 *
 * @param configurationIndex
 */
export const doAddWlrAppointment = configurationIndex => async (
  dispatch,
  getState
) => {
  const state = getState();
  const config = state.wlrBroadband.configurations[configurationIndex];
  const location = state.wlrBroadband.locations[config.locationIndex];
  const appointment =
    config.wlrAppointments.response.appointments[
      config.wlrAppointments.selectedIndex
    ];
  const account = getAccountId(getState());
  // const supplier_product_ref = _.get(
  //   config.broadbandProductData.response,
  //   "broadband.product_component_data.supplier_product_ref"
  // );

  dispatch({
    type: actionTypes.REQUEST_ADD_WLR_APPOINTMENT,
    configurationIndex
  });
  const response = await WlrBroadbandAPI.wlr3AddAppointment({
    address_reference: location.address.addressReference,
    appointment_date: appointment.date,
    appointment_timeslot: appointment.timeslot,
    css_database_code: location.address.cssDatabaseCode,
    number_of_channels:
      getDynamicPropertyValue(
        state,
        "wlr",
        configurationIndex,
        "number_of_channels"
      ) || 1,
    product_type: getDynamicPropertyValue(
      state,
      "wlr",
      configurationIndex,
      "product_type"
    ),
    service_type: getDynamicPropertyValue(
      state,
      "wlr",
      configurationIndex,
      "service_type"
    ),
    // supplier_product_ref isn't needed here directly according to @davet (was in before)
    // ...(supplier_product_ref === 'BT_21CN_FTTC_GFAST' && { gfast: 1 })
    account
  });
  dispatch({
    type: actionTypes.RECEIVE_ADD_WLR_APPOINTMENT,
    configurationIndex,
    response
  });
  dispatch(doRemoteValidation(configurationIndex));
};

/**
 * Add Broadband appointment as selected by the user
 * TODO: This and the WLR one are very similar. Same for the views. Combine?
 *
 * @param configurationIndex
 */
export const doAddBroadbandAppointment = configurationIndex => async (
  dispatch,
  getState
) => {
  const config = getState().wlrBroadband.configurations[configurationIndex];
  const productData = config.broadbandProductData.response;
  const location = getState().wlrBroadband.locations[config.locationIndex];
  const appointment =
    config.broadbandAppointments.response.appointments[
      config.broadbandAppointments.selectedIndex
    ];
  const supplier_product_ref = _.get(
    productData,
    "broadband.product_component_data.supplier_product_ref"
  );
  const account = getAccountId(getState());

  dispatch({
    type: actionTypes.REQUEST_ADD_BROADBAND_APPOINTMENT,
    configurationIndex
  });
  // This service_type is not the same thing as product data service_type....
  // I think it's the type of service the engineer provides.
  // Unlike other types, SI have given SOGEA two variations, which are SOGEA_NEW and SOGEA_EXISTING.
  // SOGEA_EXISTING is used for migrations from an existing SoGEA installation, which we can't determine atm.
  // So always use SOGEA_NEW for now. 03/03/2020
  const isSOGEA = productData.broadband.product_component_data.type === "SOGEA";
  const service_type = isSOGEA
    ? "SOGEA_NEW"
    : config.broadbandProperties["bb.sim_provide_ref"]
    ? "FTTC_SIM2"
    : "FTTC";
  const site_visit_reason = getDynamicPropertyValue(
    getState(),
    "broadband",
    configurationIndex,
    "bb.site_visit_reason"
  );
  const response = await WlrBroadbandAPI.broadbandAddAppointment({
    product_type: "BASIC",
    supplier: productData.broadband.product_component_data.supplier,
    // supplier_product_ref isn't needed here directly according to @davet (was in before)
    ...(supplier_product_ref === "BT_21CN_FTTC_GFAST" && { gfast: 1 }),
    service_type,
    number_of_channels: config.wlrProperties.number_of_channels || 1,
    address_reference: location.address.addressReference,
    css_database_code: location.address.cssDatabaseCode,
    appointment_date: appointment.date,
    appointment_timeslot: appointment.timeslot,
    site_visit_reason,
    account
  });
  dispatch({
    type: actionTypes.RECEIVE_ADD_BROADBAND_APPOINTMENT,
    configurationIndex,
    response,
    appointment
  });
  dispatch(doRemoteValidation(configurationIndex));
};

/**
 * Validate config params against WLR and Broadband Validation API endpoints
 * Abort if local validation isn't passed.
 * This is to test a configuration before it's sent as an OrderProduct/Create call
 *
 * @param configurationIndex
 * @param quoteOnly {boolean}
 */
export const doRemoteValidation = (
  configurationIndex,
  quoteOnly = false
) => async (dispatch, getState) => {
  // Check the local validation
  const config = getState().wlrBroadband.configurations[configurationIndex];
  const validation = config.validation;
  let validated = true;
  for (let prop in validation) {
    if (validation[prop].length > 0) validated = false;
  }

  // If it's passed, ask the API about it:
  if (validated) {
    const { wlrProductId, broadbandProductId } = config;

    await Promise.all([
      wlrProductId !== EXISTING_LINE &&
        !getIsMPFLine(config) &&
        wlrProductId !== NEW_FTTP &&
        wlrProductId !== RESIGN &&
        wlrProductId !== NEW_SOGEA &&
        wlrProductId !== EXISTING_SOGEA &&
        dispatch(validateWlr(configurationIndex, quoteOnly)),
      broadbandProductId &&
        dispatch(validateBroadband(configurationIndex, quoteOnly))
    ]);
  }
};

/**
 * Validate WLR.
 * Triggered by doRemoteValidation
 *
 * @param configurationIndex
 * @param quoteOnly {boolean}
 */
const validateWlr = (configurationIndex, quoteOnly) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: quoteOnly
      ? actionTypes.REQUEST_VALIDATE_WLR_QUOTE_ONLY
      : actionTypes.REQUEST_VALIDATE_WLR,
    configurationIndex
  });
  const response = await WlrBroadbandAPI.wlrValidation(
    getState(),
    configurationIndex,
    quoteOnly
  );
  dispatch({
    type: quoteOnly
      ? actionTypes.RECEIVE_VALIDATE_WLR_QUOTE_ONLY
      : actionTypes.RECEIVE_VALIDATE_WLR,
    configurationIndex,
    response
  });
};

/**
 * Validate Broadband.
 * Triggered by doRemoteValidation.
 * Extra router_provisioning param has to be sent if there's a router in the order, or the delivery address won't be validated.
 *
 * @param configurationIndex
 * @param quoteOnly {boolean}
 */
const validateBroadband = (configurationIndex, quoteOnly) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: actionTypes.REQUEST_VALIDATE_BROADBAND,
    configurationIndex
  });

  let requirements = ["broadband_provisioning"];

  // TODO: There's a bug here when user de-selects the router: the old state is read below until the next validation call,
  // meaning the router_provisioning requirement is sent even when no router is in the order, causing it to fail.
  if (
    getDynamicPropertyValue(
      getState(),
      "broadband",
      configurationIndex,
      "router_id"
    )
  ) {
    requirements.push("router_provisioning");
  }
  const response = await WlrBroadbandAPI.broadbandValidation(
    getState(),
    configurationIndex,
    requirements,
    quoteOnly
  );

  dispatch({
    type: actionTypes.RECEIVE_VALIDATE_BROADBAND,
    configurationIndex,
    response
  });
};

/**
 * Make OrderProduct calls for WLR & BB products
 * @param quoteOnly {boolean}
 * @returns {Function}
 */
export const orderWlrBroadbandProducts = (quoteOnly = false) => async (
  dispatch,
  getState
) => {
  await Promise.all(
    getState().wlrBroadband.configurations.map(async (config, i) => {
      // IDs of the products we're ordering
      const { wlrProductId, broadbandProductId, orderProduct } = config;
      // Any existing IDs of those products in the order.
      const wlrOrderProductId = _.get(orderProduct, "wlr.response.data.id");
      const broadbandOrderProductId = _.get(
        orderProduct,
        "broadband.response.data.id"
      );

      // If it's not an existing line, MPF or FTTP install (which don't require WLR lines)
      // Make the WLR order request followed by any dependent broadband request
      // (which will use the returned order component ID of the line)
      if (
        wlrProductId !== EXISTING_LINE &&
        !getIsMPFLine(config) &&
        wlrProductId !== NEW_FTTP &&
        wlrProductId !== RESIGN &&
        wlrProductId !== EXISTING_SOGEA &&
        wlrProductId !== NEW_SOGEA
      ) {
        const params = getDCOrderParams(getState(), i, "wlr", quoteOnly);
        const shouldUpdate = !!wlrOrderProductId;
        dispatch(requestOrderProduct(i, "wlr", shouldUpdate));
        const wlrResponse = shouldUpdate
          ? await OrderProductAPI.update(wlrOrderProductId, params)
          : await OrderProductAPI.create(params.product_id, params);
        dispatch(receiveOrderProduct(i, "wlr", wlrResponse));

        if (broadbandProductId) {
          const bbParams = {
            ...getDCOrderParams(getState(), i, "broadband", quoteOnly),
            "broadband-line_component_id": _.get(
              wlrResponse,
              "data.components[0].id"
            ) // This needs passing as well as SIM2 ref for simultaneous provisioning to work....
          };

          const shouldUpdate = !!broadbandOrderProductId;
          dispatch(requestOrderProduct(i, "broadband", shouldUpdate));
          const broadbandResponse = shouldUpdate
            ? await OrderProductAPI.update(broadbandOrderProductId, bbParams)
            : await OrderProductAPI.create(bbParams.product_id, bbParams);
          dispatch(receiveOrderProduct(i, "broadband", broadbandResponse));
        }

        // If there's no line to order (BB only / existing / mpf etc), just do the BB.
      } else if (broadbandProductId) {
        const params = getDCOrderParams(getState(), i, "broadband", quoteOnly);
        const shouldUpdate = !!broadbandOrderProductId;
        dispatch(requestOrderProduct(i, "broadband", shouldUpdate));
        const broadbandResponse = shouldUpdate
          ? await OrderProductAPI.update(broadbandOrderProductId, params)
          : await OrderProductAPI.create(params.product_id, params);
        dispatch(receiveOrderProduct(i, "broadband", broadbandResponse));
      }

      // Has the user selected any special rates to remove when resigning? If so, remove them here.
      const specialRatesToRemove = getState().wlrBroadband.specialRatesToRemove;
      specialRatesToRemove.forEach(async specialRate => {
        dispatch(removeSpecialRate(specialRate));
      });
    })
  );
};

/**
 * Request order product response
 * @param configurationIndex
 * @param productType
 * @param isUpdate {boolean}
 * @returns {{type: string, configurationIndex: *, productType: *}}
 */
const requestOrderProduct = (configurationIndex, productType, isUpdate) => ({
  type: actionTypes.REQUEST_ORDER_PRODUCT,
  configurationIndex,
  productType,
  isUpdate
});
/**
 * Receive order product response.
 * @param configurationIndex
 * @param productType
 * @param response
 */
const receiveOrderProduct = (configurationIndex, productType, response) => ({
  type: actionTypes.RECEIVE_ORDER_PRODUCT,
  configurationIndex,
  productType,
  response
});

/**
 * Get resignable line product instances
 */
export const requestLineProductInstances = () => ({
  type: actionTypes.REQUEST_LINE_PRODUCT_INSTANCES
});

/**
 * Get resignable broadband product instances
 */
export const requestBroadbandProductInstances = () => ({
  type: actionTypes.REQUEST_BROADBAND_PRODUCT_INSTANCES
});

/**
 * Set the resign type.
 */
export const setResignType = (productInstances, resignProductType) => async (
  dispatch,
  getState
) => {
  dispatch({
    locations: getState().wlrBroadband.locations,
    productInstances,
    resignProductType,
    type: actionTypes.SET_RESIGN_TYPE
  });
};

/**
 * Remove configurations by the resignable product instance's id.
 * @param productInstanceIds - configurations to act upon.
 */
export const removeResign = productInstanceIds => ({
  productInstanceIds,
  type: actionTypes.REMOVE_RESIGN
});

/**
 * Sets the chosen wlr product for a NEW_PRODUCT or SAME_PRODUCT_WITH_CHANGES resign.
 * @param productInstance
 * @param resignProductId
 * @param resignProductType
 */
export const setWlrResignProduct = (
  productInstance,
  resignProductId,
  resignProductType
) => async (dispatch, getState) => {
  const locationIndex = getState().wlrBroadband.locations.findIndex(
    location => location.cli.value === productInstance.pin
  );
  dispatch({
    productInstanceId: productInstance.id,
    resignProductId,
    resignProductType,
    locationIndex,
    type: actionTypes.SET_RESIGN_WLR_PRODUCT
  });
};

/**
 * Sets the chosen broadband product for a NEW_PRODUCT or SAME_PRODUCT_WITH_CHANGES resign.
 * @param productInstance
 * @param resignProductId
 * @param type
 */
export const setBroadbandResignProduct = (
  productInstance,
  resignProductId,
  type
) => async (dispatch, getState) => {
  const locationIndex = getState().wlrBroadband.locations.findIndex(
    location => location.cli.value === productInstance.pin
  );
  const configurationIndex = getState().wlrBroadband.configurations.findIndex(
    configuration =>
      configuration.pin === productInstance.pin && configuration.type === type
  );
  dispatch({
    configurationIndex,
    locationIndex,
    resignProductId,
    type: actionTypes.SET_RESIGN_BROADBAND_PRODUCT,
    productInstanceId: productInstance.id
  });
};

/**
 * Get the line availability for a resign.
 * @param productInstance - required to find the location it belongs to.
 */

export const getLineAvailabilityForResign = productInstance => async (
  dispatch,
  getState
) => {
  const locations = getState().wlrBroadband.locations;
  const index = locations.findIndex(
    location => location.cli.value === productInstance.pin
  );
  const address = locations[index].address;
  const account = getAccountId(getState());
  dispatch({ type: actionTypes.REQUEST_LINE_AVAILABILITY, index });
  const response = await WlrBroadbandAPI.lineAvailability(
    address.cssDatabaseCode,
    address.addressReference,
    account
  );
  dispatch({
    type: actionTypes.RECEIVE_LINE_AVAILABILITY,
    index,
    response
  });
};

/**
 * Perform broadband search and find the available broadband products we can resign to.
 * @param productInstance - required to find the location it belongs to.
 */
export const doBroadbandSearchForResign = productInstance => async (
  dispatch,
  getState
) => {
  const locations = getState().wlrBroadband.locations;
  const index = locations.findIndex(
    location => location.cli.value === productInstance.pin
  );

  dispatch({ type: actionTypes.REQUEST_BROADBAND_SEARCH, index });
  const response = await WlrBroadbandAPI.broadbandSearch(
    getState().order.accountId,
    locations[index].address.addressReference,
    locations[index].address.cssDatabaseCode,
    locations[index].address.postcode,
    null,
    getState().wlrBroadband.contractLength
  );
  dispatch({
    type: actionTypes.RECEIVE_BROADBAND_SEARCH,
    index,
    response
  });
};

/**
 * Toggle Sogea Terms checkbox
 * @param index - Location Index
 */
export const toggleSogeaTermsConfirmation = index => ({
  type: actionTypes.TOGGLE_SOGEA_TERMS_CONFIRMATION,
  index
});

/**
 * Find the product used to do resigns without change. (saga)
 * @returns {{type: string}}
 */
export const requestResignProduct = () => ({
  type: actionTypes.REQUEST_SAME_RESIGN_PRODUCT
});

/**
 * Find the product used to do resigns for same product with change. (saga)
 * @returns {{type: string}}
 */
export const requestLineResignProduct = () => ({
  type: actionTypes.REQUEST_LINE_RESIGN_PRODUCT
});

/**
 * Find the product used to do resigns for same product with change. (saga)
 * @returns {{type: string}}
 */
export const requestBroadbandResignProduct = () => ({
  type: actionTypes.REQUEST_BROADBAND_RESIGN_PRODUCT
});

/**
 * Remove a special rate when resigning a product.
 *
 * @param specialRate - this is an object containing an id, type and date. Type can be 'number' or 'tariff'
 * and the date is the resign start date (which by default, is the first of next month).
 */
export const removeSpecialRate = specialRate => async (dispatch, getState) => {
  const { id, type, date } = specialRate;
  const account = getAccountId(getState());
  const index = getState().wlrBroadband.specialRatesToRemove.findIndex(
    specialRate => specialRate.id === id
  );
  dispatch({
    type: actionTypes.REQUEST_REMOVE_SPECIAL_RATE,
    index
  });
  const response = await ProductAPI.RemoveSpecialRate({
    account,
    id,
    type,
    date
  });
  dispatch({
    type: actionTypes.RECEIVE_REMOVE_SPECIAL_RATE,
    index,
    response
  });
};

/**
 * Set global resign start date
 * @param date
 * @returns {{date: *, type: *}}
 */
export const setResignStartDate = date => ({
  type: actionTypes.SET_RESIGN_START_DATE,
  date
});

export const addSpecialRateToRemove = (id, type, date) => ({
  type: actionTypes.ADD_SPECIAL_RATE_TO_REMOVE,
  id,
  specialRateType: type,
  date
});

export const deleteSpecialRateToRemove = id => ({
  type: actionTypes.DELETE_SPECIAL_RATE_TO_REMOVE,
  id
});

export const addAppointments = () => async (dispatch, getState) => {
  await Promise.all(
    getState().wlrBroadband.configurations.map(async (config, index) => {
      const bbAppointments = _.get(
        config,
        "broadbandAppointments.response.appointments"
      );
      const hasBBAppointment =
        _.get(config, "addBroadbandAppointment.response.status") === "success";
      const wlrAppointments = _.get(
        config,
        "wlrAppointments.response.appointments"
      );
      const hasWlrAppointment =
        _.get(config, "addWlrAppointment.response.status") === "success";
      const hasSelectedBBAppointment =
        bbAppointments &&
        bbAppointments[config.broadbandAppointments.selectedIndex];
      const hasSelectedWlrAppointment =
        wlrAppointments &&
        wlrAppointments[config.wlrAppointments.selectedIndex];
      if (hasSelectedBBAppointment && !hasBBAppointment)
        await dispatch(doAddBroadbandAppointment(index));
      if (hasSelectedWlrAppointment && !hasWlrAppointment)
        await dispatch(doAddWlrAppointment(index));
    })
  );
};

/**
 * Used during a resign when a product instance does not have a postcode.
 * Returns the postcode that is linked to a pin.
 *
 * @param pin
 */
export const getTagsCheck = pin => async (dispatch, getState) => {
  const locations = getState().wlrBroadband.locations;
  const locationIndex = locations.findIndex(
    location => location.cli.value === pin
  );
  const account = getAccountId(getState());

  dispatch({ type: actionTypes.REQUEST_TAGS_CHECK, locationIndex });
  const response = await WlrBroadbandAPI.getTagsCheck(account, pin);
  dispatch({
    type: actionTypes.RECEIVE_TAGS_CHECK,
    locationIndex,
    response
  });
  if (response.status === "success")
    dispatch(
      setLocationAddress({
        locationIndex,
        address: { postcode: response.postcode },
        isResign: true
      })
    );
};

/**
 * Update a product instance.
 * Used during a resign.
 *
 * @param id
 * @param pin
 * @param configurationIndex
 * @param address
 */
export const doUpdateProductInstance = (
  id,
  pin,
  configurationIndex,
  address
) => async (dispatch, getState) => {
  const locations = getState().wlrBroadband.locations;
  const locationIndex = locations.findIndex(
    location => location.cli.value === pin
  );
  const account = getAccountId(getState());
  dispatch({
    type: actionTypes.REQUEST_UPDATE_PRODUCT_INSTANCE,
    configurationIndex
  });
  const response = await WlrBroadbandAPI.updateProductInstance(id, {
    ...mapToProductInstanceAddress(address),
    account
  });
  dispatch({
    type: actionTypes.RECEIVE_UPDATE_PRODUCT_INSTANCE,
    configurationIndex,
    response
  });
  if (response.status === "success")
    dispatch(
      setLocationAddress({
        index: locationIndex,
        address: mapToLocationAddress(response.result),
        isResign: true
      })
    );
};
