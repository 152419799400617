import React, { useState } from "react";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { StatusChip } from "@akj-dev/design-system";
import { isValidTextRegex } from "../../shared/utils/validation";
import * as yup from "yup";
import { TextField } from "formik-material-ui";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { LeadCreateParams, LeadsCreate } from "../../api/v1/accounts";
import { LoaderButton } from "@akj-dev/design-system";
import { NewOrderForAccountOrLead } from "../../api/v1/guidedSales";
import { useHistory, useRouteMatch } from "react-router-dom";
import { orderToCpqParams } from "./utils/orderToCpqParams";

/**
 * Create new prospect
 */
export const NewProspect = () => {
  const history = useHistory();
  let { url } = useRouteMatch();
  const [error, setError] = useState("");

  const initialValues: LeadCreateParams = {
    first_name: "",
    last_name: "",
    email: "",
    name: ""
  };

  const validationSchema = yup.object().shape({
    first_name: yup
      .string()
      .matches(isValidTextRegex, "Must not contain special characters")
      .required("Please enter a first name"),
    last_name: yup
      .string()
      .matches(isValidTextRegex, "Must not contain special characters")
      .required("Please enter a last name"),
    email: yup
      .string()
      .email("Must be a valid email address")
      .required("Please enter an email address"),
    name: yup
      .string()
      .matches(isValidTextRegex, "Must not contain special characters")
      .required("Please enter an opportunity name")
  });

  const handleSubmit = async (
    values: LeadCreateParams,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    const leadResponse = await LeadsCreate(values);
    const leadId = leadResponse.data.data?.lead?.id;
    if (leadId) {
      const orderResponse = await NewOrderForAccountOrLead({
        lead_id: leadId,
        name: values.name,
        order_source: "Affinity Sales"
      });
      if (orderResponse.data.status === "success") {
        const params = orderToCpqParams(orderResponse.data);
        history.push(`${url}/cpq?${params}`);
      } else {
        setError(orderResponse.data.message || "Sorry, error creating order");
      }
    } else {
      setError(leadResponse.data.message || "Sorry, error creating prospect");
    }
    setSubmitting(false);
  };

  return (
    <Card>
      <CardHeader title="Create New Prospect" />
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <Field
                    margin="dense"
                    component={TextField}
                    name="first_name"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6}>
                  <Field
                    margin="dense"
                    component={TextField}
                    name="last_name"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6}>
                  <Field
                    margin="dense"
                    component={TextField}
                    name="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6}>
                  <Field
                    margin="dense"
                    component={TextField}
                    name="name"
                    label="Add an opportunity name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12}>
                  <LoaderButton
                    loading={isSubmitting}
                    onClick={submitForm}
                    color="primary"
                  >
                    Create new prospect and continue
                  </LoaderButton>
                </Grid>
                {error && (
                  <Grid item sm={12}>
                    <StatusChip
                      type="error"
                      title="Error creating prospect"
                      message={error}
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};
