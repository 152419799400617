// strings should be unique across reducers so namespace them with the reducer name

export const REQUEST_ACCOUNT_SETTINGS = "account.REQUEST_ACCOUNT_SETTINGS";
export const RECEIVE_ACCOUNT_SETTINGS = "account.RECEIVE_ACCOUNT_SETTINGS";

export const REQUEST_AVAILABLE_HARDWARE_CREDIT =
  "account.REQUEST_AVAILABLE_HARDWARE_CREDIT";
export const RECEIVE_AVAILABLE_HARDWARE_CREDIT =
  "account.RECEIVE_AVAILABLE_HARDWARE_CREDIT";

export const SET_NEW_ACCOUNT_FIELD = "account.SET_NEW_ACCOUNT_FIELD";
export const DELETE_NEW_ACCOUNT_FIELD = "account.DELETE_NEW_ACCOUNT_FIELD";
export const VALIDATE_NEW_ACCOUNT_FIELD = "account.VALIDATE_NEW_ACCOUNT_FIELD";
export const VALIDATE_ALL_NEW_ACCOUNT_FIELDS =
  "account.VALIDATE_ALL_NEW_ACCOUNT_FIELDS";

export const REQUEST_CREATE_ACCOUNT = "account.REQUEST_CREATE_ACCOUNT";
export const RECEIVE_CREATE_ACCOUNT = "account.RECEIVE_CREATE_ACCOUNT";

export const REQUEST_ADD_CONTACT = "account.REQUEST_ADD_CONTACT";
export const RECEIVE_ADD_CONTACT = "account.RECEIVE_ADD_CONTACT";

export const REQUEST_ALL_CONTACTS = "account.REQUEST_ALL_CONTACTS";
export const RECEIVE_ALL_CONTACTS = "account.RECEIVE_ALL_CONTACTS";

export const REQUEST_CONTACT_TYPES = "account.REQUEST_CONTACT_TYPES";
export const RECEIVE_CONTACT_TYPES = "account.RECEIVE_CONTACT_TYPES";

// For initial billing address and updates
export const REQUEST_BILLING_ADDRESS = "account.REQUEST_BILLING_ADDRESS";
export const REQUEST_UPDATE_BILLING_ADDRESS =
  "account.REQUEST_UPDATE_BILLING_ADDRESS";
export const RECEIVE_BILLING_ADDRESS = "account.RECEIVE_BILLING_ADDRESS";

export const REQUEST_ALL_ACCOUNT_ADDRESSES =
  "account.REQUEST_ALL_ACCOUNT_ADDRESSES";
export const RECEIVE_ALL_ACCOUNT_ADDRESSES =
  "account.RECEIVE_ALL_ACCOUNT_ADDRESSES";

export const SET_NEW_ACCOUNT_FIELDS = "account.SET_NEW_ACCOUNT_FIELDS";
export const SET_NEW_ACCOUNT_FIELDS_FROM_CONTACT =
  "account.SET_NEW_ACCOUNT_FIELDS_FROM_CONTACT";

export const REQUEST_CREDIT_VET = "account.REQUEST_CREDIT_VET";
export const SET_CREDIT_VET_ERROR = "account.SET_CREDIT_VET_ERROR";
export const SET_CREDIT_VET_SUCCESS_RESPONSE =
  "account.SET_CREDIT_VET_SUCCESS_RESPONSE";

export const RESET_ACCOUNT_STATE = "account.RESET_ACCOUNT_STATE";
