import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";
import { StatusChip } from "@akj-dev/design-system";
import ChangeIcon from "./ChangeIcon";
import FieldContainer from "./FieldContainer";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText } from "@material-ui/core";
import { Select as SelectField } from "@material-ui/core";

const Price = styled.div`
  display: inline-block;
  padding-left: 10px;
  font-size: 0.9em;
  line-height: 1;
  font-weight: bold;
  vertical-align: middle;
`;

const Description = styled.div`
  font-size: 0.9em;
  line-height: 1.6em;
  color: #999;
  max-width: 400px;
  white-space: normal;
`;

const Select = ({
  dynamicProperty,
  setProperty,
  propertyValue,
  propertyValidation,
  showWlrChangeIcon,
  recalculatePrices,
  validateConfig,
  disabled,
  margin
}) => {
  // Because of how Perl (DC) stores hashes, available_option_map (that contains user readable names)
  // isn't always in the correct order. Hence we use available_options, that's an array and hence is
  // in a stable order as a basis for the <MenuItem/> display
  const options = dynamicProperty.available_options;
  const optionMap = dynamicProperty.available_option_map;

  // Some dynamic_properties influence pricing data. If that's the case, they will have a available_option_details node
  const optionDetails = dynamicProperty.available_option_details;

  // On testing10, radius.radius_realm_id returns no options.
  // This is an error and breaks the whole render without this catch:
  if (!options)
    return (
      <StatusChip
        type="error"
        title={`No options available for ${dynamicProperty.label}`}
        message="Please raise a support case."
      />
    );

  let menuItems = options.map(k => {
    let recurringPrice = _.get(
      optionDetails,
      [k, "first_bill_recurring_price_with_promotions"],
      0
    );
    let oneOffPrice = _.get(
      optionDetails,
      [k, "one_off_price_with_promotions"],
      0
    );
    if (parseFloat(recurringPrice) === 0) recurringPrice = false;
    if (parseFloat(oneOffPrice) === 0) oneOffPrice = false;
    const description = _.get(optionDetails, [k, "product_description"], false);

    return {
      value: k,
      label: optionMap[k],
      element: (
        <div>
          {optionMap[k]}
          {oneOffPrice && <Price>£{oneOffPrice} (one off)</Price>}
          {recurringPrice && <Price>£{recurringPrice} / month</Price>}
          {description && <Description>{description}</Description>}
        </div>
      )
    };
  });

  // TODO: some available_options arrays (care levels) are built from hashes in the first place apparently
  // and hence can also have unreliable order. Kill. Me.
  if (dynamicProperty.name === "care_level") {
    menuItems.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }

      // labels must be equal
      return 0;
    });
  }

  if (dynamicProperty.name === "bb.care_level") {
    // Check the size first: STANDARD - 8, ENHANCED - 8, PREMIUM  - 7
    // Theck check first char: S > E

    menuItems.sort((a, b) => {
      if (a.label.length > b.label.length) {
        return -1;
      }

      if (a.label.length < b.label.length) {
        return 1;
      }

      // labels must be equal
      if (a.label[0] > b.label[0]) {
        return -1;
      }

      if (a.label[0] < b.label[0]) {
        return 1;
      }

      return 0;
    });
  }

  return (
    <FieldContainer>
      <FormControl
        fullWidth
        error={propertyValidation}
        margin={margin ? "normal" : "none"}
      >
        <InputLabel>{`${dynamicProperty.label}${
          dynamicProperty.is_required ? " *" : ""
        }`}</InputLabel>
        <SelectField
          onChange={event => {
            setProperty(event.target.value);
            recalculatePrices();
            validateConfig();
          }}
          value={propertyValue ? propertyValue : ""}
          disabled={disabled}
        >
          <MenuItem value={null} />
          {menuItems.map(menuItem => {
            return (
              <MenuItem key={menuItem.value} value={menuItem.value}>
                {menuItem.element}
              </MenuItem>
            );
          })}
          {propertyValidation && (
            <FormHelperText>{propertyValidation}</FormHelperText>
          )}
        </SelectField>
      </FormControl>
      {showWlrChangeIcon && <ChangeIcon />}
    </FieldContainer>
  );
};

Select.propTypes = {
  dynamicProperty: PropTypes.object.isRequired,
  propertyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Select;
