import { useSelector, useDispatch } from "react-redux";
import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import { removeFlashMessageByIdAction } from "../../store/uiState/actions";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  info: {
    backgroundColor: theme.palette.primary.main
  }
}));

function FlashMessages() {
  const classes = useStyles();
  const flashMessages = useSelector(state => state.uiState.flashMessages);
  const dispatch = useDispatch();

  const onClose = id => dispatch(removeFlashMessageByIdAction(id));

  // TODO: The logic to close these needs refactoring.
  //  Removing the components immediately means transition animations are ignored.
  //  See https://material-ui.com/components/snackbars/#consecutive-snackbars
  return (
    <div>
      {flashMessages.length
        ? flashMessages.map((flashMessage, key) => (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              autoHideDuration={6000}
              open={true}
              onClose={e => onClose(flashMessage.id)}
              key={key}
            >
              <SnackbarContent
                className={classes.info}
                message={
                  <Box display="flex" alignItems="center">
                    <Box mr={1}>
                      <InfoIcon />
                    </Box>
                    {flashMessage.message}
                  </Box>
                }
                action={
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={e => onClose(flashMessage.id)}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              />
            </Snackbar>
          ))
        : null}
    </div>
  );
}

export default FlashMessages;
