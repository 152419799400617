import React from "react";
import { Box } from "@material-ui/core";
import StepAccount from "../../containers/StepAccount";
import { TitleAndInfo } from "../shared/title-and-info";

export const Account = () => {
  return (
    <Box>
      <TitleAndInfo title={"Create Account"} />
      <StepAccount />
    </Box>
  );
};
