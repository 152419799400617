import React from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { IndexedCardInfo } from "../../../screens/shared/indexed-card-info";
import AccountSelectField from "../AccountSelectField";
import { useSelector } from "react-redux";
import { getAccountSettings } from "../../../store/account/selectors";

const MarketingPreferences = ({ cardIndex }) => {
  const accountSettings = useSelector(getAccountSettings);
  const shouldShowAdditionalFields =
    accountSettings.can_access_vf_direct === "1";
  return (
    <IndexedCardInfo index={cardIndex} title="Marketing Preferences">
      <form>
        <Grid container spacing={2}>
          <AccountSelectField
            label="Opt in to email for sales/marketing"
            name="ok_to_email"
          >
            <MenuItem value="0">No</MenuItem>
            <MenuItem value="1">Yes</MenuItem>
          </AccountSelectField>
          <AccountSelectField
            label="Opt in to telephone for sales/marketing"
            name="ok_to_phone"
          >
            <MenuItem value="0">No</MenuItem>
            <MenuItem value="1">Yes</MenuItem>
          </AccountSelectField>
          {shouldShowAdditionalFields && (
            <>
              <AccountSelectField
                label="Opt in to SMS for sales/marketing"
                name="ok_to_sms"
              >
                <MenuItem value="0">No</MenuItem>
                <MenuItem value="1">Yes</MenuItem>
              </AccountSelectField>
              <AccountSelectField
                label="Opt in to MMS for sales/marketing"
                name="ok_to_mms"
              >
                <MenuItem value="0">No</MenuItem>
                <MenuItem value="1">Yes</MenuItem>
              </AccountSelectField>
              <AccountSelectField
                label="Opt in to post for sales/marketing"
                name="ok_to_post"
              >
                <MenuItem value="0">No</MenuItem>
                <MenuItem value="1">Yes</MenuItem>
              </AccountSelectField>
            </>
          )}
        </Grid>
      </form>
    </IndexedCardInfo>
  );
};

export default MarketingPreferences;
