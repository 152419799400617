import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { validatePostcode } from "../../../helpers/validation";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { ChooseAddressDialog } from "./ChooseAddressDialog";
import { getAccountId } from "../../../store/order/selectors";
import { connect } from "react-redux";

class AddressPicker extends React.Component {
  state = {
    dialogOpen: false,
    postcode: ""
  };

  static propTypes = {
    saveAddress: PropTypes.func.isRequired,
    includeUPRN: PropTypes.bool,
    saveUPRN: PropTypes.func,
    postcode: PropTypes.string, // ...when it's optionally controlled
    setPostcode: PropTypes.func
  };

  setPostcode = event => {
    if (this.props.setPostcode) {
      this.props.setPostcode(event.target.value);
    } else {
      this.setState({ postcode: event.target.value });
    }
  };

  openDialog = () => {
    this.setState({
      dialogOpen: true,
      uprnSelect: false,
      filter: ""
    });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  getAddresses = () => {
    this.openDialog();
    this.setState({ addresses: false, error: false, fetching: true });
  };

  render() {
    const { dialogOpen } = this.state;

    const postcode = this.props.postcode || this.state.postcode;

    return (
      <>
        <Box
          mr={2}
          display="flex"
          alignItems="flex-start"
          data-cy="AddressPicker"
        >
          <TextField
            variant={"outlined"}
            label="Postcode"
            name="postcode"
            style={{ width: 140, marginRight: 10 }}
            onChange={this.setPostcode}
            onKeyDown={e => e.keyCode === 13 && this.getAddresses()}
            value={postcode}
            helperText={
              postcode &&
              !validatePostcode(postcode) &&
              "Must be a valid postcode"
            }
            error={!!(postcode && !validatePostcode(postcode))}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={this.getAddresses}
            data-cy="AddressPickerButton"
            disabled={!validatePostcode(postcode)}
            style={{ margin: 8 }}
          >
            Choose Address
          </Button>
        </Box>
        <ChooseAddressDialog
          open={dialogOpen}
          onClose={this.closeDialog}
          postcode={postcode}
          includeUPRN={this.props.includeUPRN}
          setAddress={this.props.saveAddress}
          setUPRN={uprn => this.props.saveUPRN(uprn.uprn)}
          accountId={this.props.account}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: getAccountId(state)
  };
};

export default connect(mapStateToProps)(AddressPicker);
