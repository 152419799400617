import React from "react";
import PropTypes from "prop-types";
import InlinePriceIndicator from "./InlinePriceIndicator";

/**
 *
 * @param productData
 * @param priceType
 * @returns {*}
 * @constructor
 */
function InlinePriceMeta({ productData, priceType }) {
  const component =
    productData.line || productData.broadband || productData.mobile;
  if (!component) return false; // i.e. the product data hasn't loaded yet.

  const {
    promotion_id,
    one_off_discount_type,
    recurring_discount_type
  } = component.component_price;

  if (promotion_id || one_off_discount_type || recurring_discount_type) {
    // TODO: This should have a popover on step 2, when we'll have product data and hence full info on discounts etc.
    return <InlinePriceIndicator />;
  } else {
    return false;
  }
}

InlinePriceMeta.propTypes = {
  productData: PropTypes.object.isRequired,
  priceType: PropTypes.oneOf(["ONE_OFF", "RECURRING"])
};
export default InlinePriceMeta;
