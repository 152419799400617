import React from "react";
import { connect } from "react-redux";
import ConfigTable from "./ConfigTable";
import ConfigForm from "./ConfigForm";

function UniversalProducts({ selectedConfigIndex }) {
  return (
    <>
      <h2>Configure your product(s)</h2>
      {selectedConfigIndex === false ? <ConfigTable /> : <ConfigForm />}
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedConfigIndex: state.universalProducts.selectedConfigIndex
  };
};

export default connect(mapStateToProps, {})(UniversalProducts);
