import React from "react";
import PropTypes from "prop-types";
import EthernetIcon from "@material-ui/icons/SettingsEthernet";
import { ETHERNET_PRODUCT_GEA } from "../../../store/ethernetProducts/constants";
import { ExpandingCard } from "@akj-dev/design-system";

const EthernetCard = ({ configuration, children }) => {
  const { product, site_b_type, site_b_bearer, speed, type } = configuration;
  let subtitle;

  if (type === ETHERNET_PRODUCT_GEA) {
    subtitle = `Bearer: ${site_b_bearer} Mbit/s`;
  } else {
    subtitle = `Bearer: ${site_b_bearer} Mbit/s - Speed: ${speed} Mbit/s`;
  }

  return (
    <ExpandingCard
      title={`${product} - ${site_b_type}`}
      subtitle={subtitle}
      avatar={<EthernetIcon />}
      defaultExpanded
    >
      {children}
    </ExpandingCard>
  );
};

EthernetCard.propTypes = {
  configuration: PropTypes.object.isRequired,
  deleteAction: PropTypes.func
};

export default EthernetCard;
