import React from "react";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import { SAME_PRODUCT } from "../../../../store/wlrBroadband/constants";
import {
  requestLineProductInstances,
  requestBroadbandProductInstances,
  setResignType,
  removeResign,
  requestResignProduct,
  requestLineResignProduct,
  requestBroadbandResignProduct,
  addResignLocation,
  removeResignLocation
} from "../../../../store/wlrBroadband/actions";
import { StatusChip } from "@akj-dev/design-system";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Box,
  TextField,
  InputAdornment
} from "@material-ui/core";
import {
  getProductInstances,
  isLinkedProductInstance
} from "../../../../store/wlrBroadband/selectors";
import StandardProductInstance from "./StandardProductInstance";
import MPFProductInstance from "./MPFProductInstance";
import { Loader } from "../../../../screens/shared/loader";
import SearchIcon from "@material-ui/icons/Search";
import { ResignsTableActions } from "../../../../screens/choose/choose-broadband/resigns/ResignsTableActions";

class Resigns extends React.Component {
  state = {
    selected: [],
    filter: ""
  };

  componentDidMount() {
    const {
      hasLineProducts,
      hasBroadbandProducts,
      requestLineProductInstances,
      requestBroadbandProductInstances,
      resignProductSearch,
      requestResignProduct,
      lineResignProductSearch,
      requestLineResignProduct,
      broadbandResignProductSearch,
      requestBroadbandResignProduct
    } = this.props;

    if (!resignProductSearch.response.products) {
      requestResignProduct();
    }
    if (!lineResignProductSearch.response.products) {
      requestLineResignProduct();
    }
    if (!broadbandResignProductSearch.response.products) {
      requestBroadbandResignProduct();
    }
    if (!hasLineProducts) {
      requestLineProductInstances();
    }
    if (!hasBroadbandProducts) {
      requestBroadbandProductInstances();
    }
  }

  isChecked = id => this.state.selected.indexOf(id) > -1;
  allChecked = productInstances =>
    this.state.selected.length === productInstances.length;
  toggleRow = id => {
    let { selected } = this.state;
    const i = selected.indexOf(id);
    if (i > -1) {
      selected.splice(i, 1);
    } else {
      selected.push(id);
    }
    this.setState({ selected });
  };
  toggleAllRows = productInstances => {
    if (this.allChecked(productInstances)) {
      this.setState({ selected: [] });
    } else {
      this.setState({
        selected: productInstances.map((line, i) => i)
      });
    }
  };
  deselectAll = () => this.setState({ selected: [] });

  setFilter = e => {
    this.setState({ filter: e.target.value });
  };
  doFilter = resign => {
    const term = this.state.filter;
    if (!term) return true;
    const match = e => e.toLowerCase().includes(term.toLowerCase());
    if (match(resign.pin) || match(resign.name)) return true;
  };
  clearFilter = () => {
    this.setState({ filter: "" });
  };
  configCount = () =>
    this.props.configurations.filter(c => !!c.resignProductType).length;
  bulkSelectByCLI = (text, productInstances) => {
    const array = text.split(/,|[, ]|\n/);
    let selected = [];
    productInstances.forEach((item, index) => {
      const pin = item.pin;
      if (array.find(item => item === pin)) selected.push(index);
    });
    this.setState({ selected });
  };

  setChanges = (productInstance, resignProductType) => {
    this.props.addResignLocation(productInstance, resignProductType);
    this.props.setResignType([productInstance], resignProductType);
    this.deselectAll();
  };

  setNoChanges = productInstance => {
    this.props.addResignLocation(productInstance, SAME_PRODUCT);
    this.props.setResignType([productInstance], SAME_PRODUCT);
    this.deselectAll();
  };

  render() {
    const { selected, filter } = this.state;
    const {
      setResignType,
      removeResign,
      configurations,
      resignProductSearch,
      lineResignProductSearch,
      broadbandResignProductSearch,
      fetching,
      productInstances,
      requestResignProduct,
      requestLineResignProduct,
      requestBroadbandResignProduct,
      removeResignLocation,
      addResignLocation,
      classes,
      lineProductInstances,
      broadbandProductInstances,
      requestLineProductInstances,
      requestBroadbandProductInstances
    } = this.props;
    const selectedProductInstances = selected.map(
      index => productInstances[index]
    );

    if (fetching) {
      return <Loader />;
    }
    return (
      <>
        <Box className={classes.root}>
          <Box className={classes.searchBox}>
            <TextField
              data-cy={"resignTableSearch"}
              className={classes.search}
              variant="outlined"
              name="filter"
              value={filter}
              onChange={this.setFilter}
              placeholder={`Search ${productInstances.length} Available Resigns`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <ResignsTableActions
            infoLabel={`${selected.length} Selected / ${productInstances.length} Available`}
            disabled={selected.length < 2}
            doResignWithoutChanges={() => {
              selectedProductInstances.forEach(productInstance => {
                addResignLocation(productInstance, SAME_PRODUCT);
                setResignType([productInstance], SAME_PRODUCT);
              });
              this.setState({ selected: [] });
            }}
            doResignNone={() => {
              removeResign(
                selectedProductInstances.map(
                  productInstance => productInstance.id
                )
              );
              this.setState({ selected: [] });
            }}
            bulkSelectVisible={false}
            bulkSelectByCLI={text =>
              this.bulkSelectByCLI(text, productInstances)
            }
          />
        </Box>
        <Table size="small" data-cy="wlrBroadbandResignsTable">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 30 }}>
                <Checkbox
                  onChange={() => this.toggleAllRows(productInstances)}
                  checked={this.allChecked(productInstances)}
                />
              </TableCell>
              <TableCell>CLI</TableCell>
              <TableCell />
              <TableCell colSpan={3}>Product</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell className="u-text-right" style={{ minWidth: 120 }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productInstances.map((productInstance, index) => {
              // Check if a configuration using this product instance
              // already exists.
              const configuration =
                configurations.find(
                  c =>
                    c[`${productInstance.type}ProductInstanceId`] ===
                    productInstance.id
                ) || {};
              // Check if this product instance belongs to a linked
              // configuration (wlr and broadband).
              const linkedConfiguration = configurations.find(
                c =>
                  c.wlrProductInstanceId &&
                  c.broadbandProductInstanceId === productInstance.id
              );
              if (!this.doFilter(productInstance)) return false;
              if (productInstance.type === "mpf")
                return (
                  <MPFProductInstance
                    key={index}
                    configuration={configuration}
                    isChecked={this.isChecked(index)}
                    toggleRow={() => this.toggleRow(index)}
                    productInstance={productInstance}
                    setChanges={resignProductType =>
                      this.setChanges(productInstance, resignProductType)
                    }
                    setNoChanges={() => this.setNoChanges(productInstance)}
                    removeResign={() => {
                      removeResignLocation(configuration.locationIndex);
                      removeResign([productInstance.id]);
                    }}
                  />
                );
              return (
                <StandardProductInstance
                  key={index}
                  configuration={linkedConfiguration || configuration}
                  isChecked={this.isChecked(index)}
                  toggleRow={() => this.toggleRow(index)}
                  productInstance={productInstance}
                  setChanges={resignProductType =>
                    this.setChanges(productInstance, resignProductType)
                  }
                  setNoChanges={() => this.setNoChanges(productInstance)}
                  removeResign={() =>
                    removeResignLocation(configuration.locationIndex)
                  }
                  isLinkedProductInstance={isLinkedProductInstance(
                    productInstances,
                    productInstance,
                    index
                  )}
                />
              );
            })}
          </TableBody>
        </Table>
        {!resignProductSearch.fetching &&
          resignProductSearch.response.status === "error" && (
            <StatusChip
              type="error"
              title="Error finding resign product"
              message={resignProductSearch.response.message}
              retry={requestResignProduct}
            />
          )}
        {!resignProductSearch.fetching &&
          resignProductSearch.response.status === "success" &&
          resignProductSearch.response.products.length < 1 && (
            <StatusChip
              type="error"
              title="Error finding resign product"
              message="No products returned from Daisy Central."
              retry={requestResignProduct}
            />
          )}
        {!lineResignProductSearch.fetching &&
          lineResignProductSearch.response.status === "error" && (
            <StatusChip
              type="error"
              title="Error finding line resign product"
              message={lineResignProductSearch.response.message}
              retry={requestLineResignProduct}
            />
          )}
        {!lineResignProductSearch.fetching &&
          lineResignProductSearch.response.status === "success" &&
          lineResignProductSearch.response.products.length < 1 && (
            <StatusChip
              type="error"
              title="Error finding line resign product"
              message="No line products returned from Daisy Central."
              retry={requestLineResignProduct}
            />
          )}
        {!broadbandResignProductSearch.fetching &&
          broadbandResignProductSearch.response.status === "error" && (
            <StatusChip
              type="error"
              title="Error finding broadband resign product"
              message={broadbandResignProductSearch.response.message}
              retry={requestBroadbandResignProduct}
            />
          )}
        {!broadbandResignProductSearch.fetching &&
          broadbandResignProductSearch.response.status === "success" &&
          broadbandResignProductSearch.response.products.length < 1 && (
            <StatusChip
              type="error"
              title="Error finding broadband resign product"
              message="No broadband products returned from Daisy Central."
              retry={requestBroadbandResignProduct}
            />
          )}
        {!lineProductInstances.fetching &&
          lineProductInstances.response.status === "error" && (
            <StatusChip
              type="error"
              title="Error finding line product instances"
              message={lineProductInstances.response.message}
              retry={requestLineProductInstances}
            />
          )}
        {!broadbandProductInstances.fetching &&
          broadbandProductInstances.response.status === "error" && (
            <StatusChip
              type="error"
              title="Error finding broadband product instances"
              message={broadbandProductInstances.response.message}
              retry={requestBroadbandProductInstances}
            />
          )}
      </>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  search: {
    minWidth: "400px",
    marginBottom: theme.spacing(1)
  }
});

const mapStateToProps = state => ({
  configurations: state.wlrBroadband.configurations,
  lineResignProductSearch: state.wlrBroadband.lineResignProductSearch,
  broadbandResignProductSearch: state.wlrBroadband.broadbandResignProductSearch,
  resignProductSearch: state.wlrBroadband.resignProductSearch,
  fetching:
    state.wlrBroadband.lineProductInstances.fetching ||
    state.wlrBroadband.broadbandProductInstances.fetching ||
    state.wlrBroadband.lineSearch.fetching ||
    state.wlrBroadband.lineResignProductSearch.fetching ||
    state.wlrBroadband.broadbandResignProductSearch.fetching ||
    state.wlrBroadband.resignProductSearch.fetching,
  hasLineProducts: state.wlrBroadband.lineProductInstances.response.results,
  hasBroadbandProducts:
    state.wlrBroadband.broadbandProductInstances.response.results,
  productInstances: getProductInstances(state),
  lineProductInstances: state.wlrBroadband.lineProductInstances,
  broadbandProductInstances: state.wlrBroadband.broadbandProductInstances
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    requestLineProductInstances,
    requestBroadbandProductInstances,
    setResignType,
    removeResign,
    requestResignProduct,
    requestLineResignProduct,
    requestBroadbandResignProduct,
    addResignLocation,
    removeResignLocation
  })(Resigns)
);
