import React from "react";
import { connect } from "react-redux";
import { Grid, MenuItem } from "@material-ui/core";
import { IndexedCardInfo } from "../../../screens/shared/indexed-card-info";
import AccountTextField from "../AccountTextField";
import AccountSelectField from "../AccountSelectField";
import AccountDatePicker from "../AccountDatePicker";

const ContactDetails = ({ cardIndex }) => {
  return (
    <IndexedCardInfo index={cardIndex} title="Contact Details">
      <form>
        <Grid container spacing={2}>
          <AccountSelectField label="Salutation" name="salutation">
            <MenuItem value="Dr.">Dr.</MenuItem>
            <MenuItem value="Miss">Miss</MenuItem>
            <MenuItem value="Mr.">Mr.</MenuItem>
            <MenuItem value="Mrs.">Mrs.</MenuItem>
            <MenuItem value="Ms.">Ms.</MenuItem>
            <MenuItem value="Prof.">Prof.</MenuItem>
          </AccountSelectField>
          <AccountTextField label="First Name" name="first_name" />
          <AccountTextField label="Last Name" name="last_name" />
          <AccountTextField label="Telephone No." name="phone" maxLength={15} />
          <AccountTextField label="Fax No." name="fax" />
          <AccountTextField label="Mobile No." name="mobile" maxLength={15} />
          <AccountTextField label="Email Address" name="email" />
          <AccountDatePicker
            label="Date Of Birth"
            name="birthdate"
            format="dd/MM/yyyy"
            disableFuture
          />
        </Grid>
      </form>
    </IndexedCardInfo>
  );
};

const mapStateToProps = state => ({
  business_entity_type: state.account.newAccountFields.business_entity_type
});

export default connect(mapStateToProps)(ContactDetails);
