import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Section from "../../../../../components/Shared/Section";
import StarsIcon from "@material-ui/icons/Stars";
import WlrBBDynamicField from "../WlrBBDynamicField/";
import { StatusChip } from "@akj-dev/design-system";
import { NEW_LINE } from "../../../../../store/wlrBroadband/constants";
import { Grid } from "@material-ui/core";

// This is everything belonging to category: Calling and Network Features
// Except:
//
// presentation_number
// direct_connect_number
// divert_to_number
// presentation_number & cnf_telephone_number

const CallingFeatures = ({ caller_display, locationType }) => {
  return (
    <Section
      title="Calling Features"
      avatar={<StarsIcon />}
      defaultExpanded={false}
    >
      <Grid container spacing={2}>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="bypass_number" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="choose_to_refuse"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="three_way_calling"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="anonymous_call_rejection"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="raw_call_data" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="reminder_call" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="ring_back_inhibit"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="bar_use_of_call_return"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="call_sign" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="indirect_access_call_barring"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="caller_display" />
          {!caller_display && locationType === NEW_LINE && (
            <StatusChip
              type="info"
              title="This service is added FOC with a new line."
              message="Only remove if customer requested"
            />
          )}
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="voice_prompt" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="change_bypass_number"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="calling_or_direct"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="called_party_answer"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="call_waiting" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="ring_back" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="withhold_number" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="inbound_call_management"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="messaging_product"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="call_barring" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="call_diversion" />
        </Grid>
      </Grid>
    </Section>
  );
};

CallingFeatures.propTypes = {
  targetConfig: PropTypes.number.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const config = state.wlrBroadband.configurations[ownProps.targetConfig];
  return {
    locationType: state.wlrBroadband.locations[config.locationIndex].type,
    caller_display: !!config.wlrProperties.caller_display
  };
};

export default connect(mapStateToProps)(CallingFeatures);
