import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  setNewAccountField,
  validateNewAccountField
} from "../../store/account/actions";
import { getNewAccountFieldRequired } from "../../store/account/selectors";
import { Grid } from "@material-ui/core";
import { DC_DATE_FORMAT, todaysDateString } from "../../helpers/date";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import { withTheme } from "@material-ui/core/styles";

class AccountDatePicker extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disableFuture: PropTypes.bool
  };

  render() {
    const {
      label,
      name,
      value,
      handleChange,
      hintText,
      required,
      disableFuture,
      theme
    } = this.props;
    return (
      <Grid item sm={6}>
        <FormControl fullWidth error={!!hintText}>
          <InputLabel>{`${label}${required ? " *" : ""}`}</InputLabel>
          <KeyboardDatePicker
            onChange={handleChange}
            name={name}
            value={value || todaysDateString}
            format={DC_DATE_FORMAT}
            disableFuture={disableFuture}
            clearable
            inputProps={{
              style: { color: !value && theme.palette.text.disabled }
            }}
          />
          {hintText && (
            <FormHelperText error={!!hintText}>{hintText}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  value: state.account.newAccountFields[ownProps.name],
  required: getNewAccountFieldRequired(state, ownProps),
  hintText: state.account.newAccountFieldsValidation[ownProps.name]
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: (event, date) => {
    dispatch(setNewAccountField(ownProps.name, date));
    dispatch(validateNewAccountField(ownProps.name));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(AccountDatePicker));
