import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from "@material-ui/core";
import { getAccountId } from "../../../../store/order/selectors";
import {
  requestAccountBoltOns,
  requestBoltOnSearch
} from "../../../../store/mobile/actionCreators";
import { getAccountSettings } from "../../../../store/account/selectors";
import BoltOnSelectField from "./BoltOnSelectField";
import { getNetworkIcon } from "../utils";
import { boltOnFields } from "./constants";
import { ReduxBoltOnTableType } from "./types";
import { StatusChip } from "@akj-dev/design-system";
import { Loader } from "../../../shared/loader";
import { ReduxAccountSettingsEnum } from "../../../enums";

export const BoltOnTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { accountId, boltOnSearch, accountBoltOns } = useSelector<
    any,
    ReduxBoltOnTableType
  >(state => ({
    accountId: getAccountId(state),
    boltOnSearch: state.mobile.boltOnSearch,
    accountBoltOns: state.mobile.accountBoltOns,
    selectedBoltOns: state.mobile.selectedBoltOns
  }));
  const settings = useSelector<
    any,
    { [k in ReduxAccountSettingsEnum]: "0" | "1" }
  >(getAccountSettings);

  const withO2 = settings.as_can_select_o2_products === "1";
  const providers = useMemo(() => {
    const localProviders = [{ id: "2", label: "Vodafone" }];

    if (withO2) {
      localProviders.push({ id: "1", label: "O2" });
    }

    return localProviders;
  }, [withO2]);

  const dataLoaded =
    boltOnSearch?.response?.status === "success" &&
    accountBoltOns?.response?.status === "success";

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(requestBoltOnSearch());
      accountId && dispatch(requestAccountBoltOns());
    }
  }, [accountId, dispatch, dataLoaded]);

  const boltOnErrorMessage =
    boltOnSearch?.response?.status === "error" &&
    boltOnSearch.response?.message;
  const accountBoltOnsErrorMessage =
    accountBoltOns?.response?.status === "error" &&
    accountBoltOns.response?.message;

  const errorMessage = boltOnErrorMessage || accountBoltOnsErrorMessage;

  const showLoader = boltOnSearch.fetching || accountBoltOns.fetching;
  const showTable = !showLoader && !errorMessage;

  return (
    <Box className={classes.table}>
      {showLoader && <Loader />}
      {showTable && (
        <Table data-cy="BoltOnsTable">
          <TableHead>
            <TableRow>
              <TableCell>Carrier</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Voice</TableCell>
              <TableCell>International</TableCell>
              <TableCell>Text</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {providers.map(provider => (
              <TableRow
                data-cy={`boltOn-provider-${provider.label.toLowerCase()}`}
                key={provider.id}
              >
                <TableCell>
                  <img src={getNetworkIcon(provider.label)} width="40" alt="" />
                </TableCell>
                {boltOnFields.map(field => (
                  <TableCell
                    data-cy={`boltOn-field-${field.label.toLowerCase()}`}
                    key={field.id}
                  >
                    <BoltOnSelectField
                      title={field.label}
                      boltOnType={field.id}
                      serviceProviderId={provider.id}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!!errorMessage && (
        <StatusChip
          type="error"
          title="Error"
          message={errorMessage}
          retry={() => dispatch(requestAccountBoltOns())}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  table: {
    width: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column"
  }
});
