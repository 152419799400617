import React from "react";
import { useAuth } from "@akj-dev/affinity-platform";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { ThumbUp, ThumbDown } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { approveOrRejectApproval } from "../../store/order/actions";

interface ApproveQuoteProps {}

/**
 * Approve Quote button
 * This is a platform only function, available when user has relevant permission
 * Used for Love Energy. TP9701
 *
 * @constructor
 */
export const ApproveQuote: React.FC<ApproveQuoteProps> = () => {
  const { userRole } = useAuth();
  const dispatch = useDispatch();
  const fetching = useSelector(
    (state: any) => state.order.approveApproval.fetching
  );
  const response = useSelector(
    (state: any) => state.order.orderStatus.response
  );

  if (userRole.affinity_sales_can_approve_quotes) {
    if (fetching)
      return (
        <Box ml={1}>
          <CircularProgress color="inherit" />
        </Box>
      );
    if (response.status === "error")
      return (
        <div>Sorry, there has been an error with your approval request.</div>
      );
    return (
      <Box>
        <Button
          color="inherit"
          variant="outlined"
          endIcon={<ThumbUp />}
          onClick={() => dispatch(approveOrRejectApproval(false))}
          style={{ marginRight: 10 }}
        >
          Approve
        </Button>
        <Button
          color="inherit"
          variant="outlined"
          endIcon={<ThumbDown />}
          onClick={() => dispatch(approveOrRejectApproval(true))}
        >
          Reject
        </Button>
      </Box>
    );
  }

  return null;
};
