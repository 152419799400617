import React, { Component } from "react";
import { connect } from "react-redux";
import { ExpandingCard } from "@akj-dev/design-system";
import SectionIcon from "@material-ui/icons/SignalCellular3Bar";
import { hasResigns } from "../../../../store/mobile/selectors/resigns";
import ConfigTableRow from "./ConfigTableRow";
import { ConfigTableOuter } from "./ConfigTableOuter";
import ETFPrice from "../ETFPrice";
import ResignStartDate from "../ResignStartDate";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { TargetConfigsContext } from "../../context/TargetConfigsContext";

class MobileConfigurationTable extends Component {
  static contextType = TargetConfigsContext;

  state = {
    selected: [],
    filter: ""
  };

  isChecked = id => this.state.selected.indexOf(id) > -1;

  /**
   * Only configs with product IDs can be edited (i.e. not "Resign without changes" ones) so....
   */
  allCheckable = () => {
    const checkable = [];
    this.props.configs.forEach((c, i) => {
      if (c.productId) checkable.push(i);
    });
    return checkable;
  };

  allChecked = () => this.state.selected.length === this.allCheckable().length;

  toggleRow = id => {
    let { selected } = this.state;
    const i = selected.indexOf(id);
    if (i > -1) {
      selected.splice(i, 1);
    } else {
      selected.push(id);
    }
    this.setState({ selected });
  };

  toggleAllRows = () => {
    if (this.allChecked()) {
      this.setState({ selected: [] });
    } else {
      this.setState({ selected: this.allCheckable() });
    }
  };

  deselectAll = () => this.setState({ selected: [] });

  editSingle = id => {
    const { setTargetConfigs } = this.context;

    this.deselectAll();
    setTargetConfigs([id]);
  };

  render() {
    const { setTargetConfigs } = this.context;
    const { configs } = this.props;
    const { selected } = this.state;

    return (
      <ExpandingCard
        defaultExpanded
        data-cy="step2MobileConfigTable"
        title="Configure Your Products"
        subtitle={configs.length + " Selected"}
        avatar={<SectionIcon />}
      >
        <div>
          <div
            className="bulk-actions"
            style={{ opacity: selected.length > 0 ? 1 : 0.5 }}
          >
            <span className="label">Bulk Actions</span>
            <IconButton
              onClick={() => setTargetConfigs(selected)}
              data-cy="mobileBulkEdit"
            >
              <EditIcon />
            </IconButton>
          </div>
          <ConfigTableOuter
            allChecked={this.allChecked()}
            toggleAllRows={this.toggleAllRows}
          >
            {configs.map((config, i) => (
              <ConfigTableRow
                configIndex={i}
                checked={this.isChecked(i)}
                editSingle={this.editSingle}
                toggleRow={this.toggleRow}
                key={i}
              />
            ))}
          </ConfigTableOuter>
          <ETFPrice />
          {hasResigns(configs) && <ResignStartDate />}
        </div>
      </ExpandingCard>
    );
  }
}

const mapStateToProps = state => {
  return {
    configs: state.mobile.configs,
    cliBoltOnSearch: state.mobile.cliBoltOnSearch
  };
};

export default connect(mapStateToProps, {})(MobileConfigurationTable);
