import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StatusChip } from "@akj-dev/design-system";
import {
  doALBRemovals,
  getOrderStatus,
  recalculatePrices,
  updateOrderTracking
} from "../../store/order/actions";
import { orderHardwareCredits } from "../../store/mobile/selectors/order";
import { SaleProductStatusChip } from "./SaleProductStatusChip";
import {
  orderMobileTariffs,
  orderBoltOns
} from "../../store/mobile/selectors/order";
import { orderWlrBroadbandProducts } from "../../store/wlrBroadband/actions";
import { orderHardware } from "../../store/hardware/hardwareOrders/actionCreators";
import { orderUniversalProducts } from "../../store/universalProducts/actionCreators";
import { orderLogicMonitorProducts } from "../../store/monitoringService/actionCreators";
import { requestAllEthernetPurchases } from "../../store/ethernetProducts/actionsCreators";

/**
 * Show status of order product calls
 *
 * Optionally just the errors & fetching states, which we'd show for retry.
 * @param hideSuccess
 * @returns {*}
 * @constructor
 */
export default function OrderProductStatus({ hideSuccess }) {
  const mobileConfigs = useSelector(state => state.mobile.configs),
    orderBoltOn = useSelector(state => state.mobile.orderBoltOn),
    hardwareCredit = useSelector(state => state.mobile.hardwareCredit),
    wlrBBConfigs = useSelector(state => state.wlrBroadband.configurations),
    ethernetConfigs = useSelector(
      state => state.ethernetProducts.configurations
    ),
    hardwareOrders = useSelector(state => state.hardwareOrders),
    universalProductConfigs = useSelector(
      state => state.universalProducts.configs
    ),
    logicMonitorProductConfigs = useSelector(
      state => state.monitoringService.configs
    ),
    order = useSelector(state => state.order),
    specialRatesToRemove = useSelector(
      state => state.wlrBroadband.specialRatesToRemove
    );

  const dispatch = useDispatch();

  /**
   * When a user retries, order status needs to be updated so product lines and totals are visible
   */
  const retry = async action => {
    await dispatch(action);
    await dispatch(recalculatePrices());
    await dispatch(getOrderStatus());
  };

  return (
    <div>
      {order.orderStatus.response.status === "error" && (
        <StatusChip
          type="error"
          title="Error retrieving order status"
          message={order.orderStatus.response.message}
          retry={() => retry(updateOrderTracking())}
          hasMarginBottom
        />
      )}
      {// Mobile Status
      mobileConfigs.map((c, i) => (
        <SaleProductStatusChip
          request={c.orderProduct}
          title="mobile product"
          hideSuccess={hideSuccess}
          key={i}
          retry={() => retry(orderMobileTariffs(true))}
        />
      ))}
      {// Mobile Bolt-on Status
      Object.keys(orderBoltOn).map((id, i) => (
        <SaleProductStatusChip
          request={orderBoltOn[id]}
          title="mobile bolt-on"
          hideSuccess={hideSuccess}
          retry={() => retry(orderBoltOns())}
          key={i}
        />
      ))}
      {// Mobile bolt-on removal status
      Object.keys(order.boltOnRemovals).map(id => (
        <SaleProductStatusChip
          request={order.boltOnRemovals[id]}
          title="account bolt-on removal request"
          retry={() => retry(doALBRemovals())}
          hideSuccess={hideSuccess}
          key={id}
        />
      ))}
      {/* Hardware credit (from Daisy Fresh) */}
      <SaleProductStatusChip
        request={hardwareCredit}
        title="hardware credit"
        retry={() => retry(orderHardwareCredits())}
        hideSuccess={hideSuccess}
      />
      {// WLR+Broadband Status
      wlrBBConfigs.map(
        config =>
          config.orderProduct &&
          Object.keys(config.orderProduct).map(productType => {
            // TODO: Retry
            return (
              <SaleProductStatusChip
                request={config.orderProduct[productType]}
                title="lines, Calls and Broadband product"
                hideSuccess={hideSuccess}
                retry={() => retry(orderWlrBroadbandProducts())}
                key={productType}
              />
            );
          })
      )}
      {// Hardware ordering status
      Object.keys(hardwareOrders).map((identifier, i) => (
        <SaleProductStatusChip
          request={hardwareOrders[identifier]}
          title="hardware product"
          hideSuccess={hideSuccess}
          retry={() => retry(orderHardware(true))}
          key={i}
        />
      ))}
      {// Ethernet ordering status
      // Note the API response is a very different shape so SaleProductStatusChip can't be used.
      // It doesn't even have "status" in teh response
      ethernetConfigs.map((c, i) => {
        if (c.purchaseIsRequesting)
          return (
            <StatusChip
              type="loading"
              title="Adding ethernet product to order."
              message="Please wait..."
              key={"eth" + i}
              hasMarginBottom
            />
          );
        if (c.purchaseResponse.error || c.purchaseResponse.status === "error")
          return (
            <StatusChip
              type="error"
              title="Error adding ethernet product to order."
              message={c.purchaseResponse.error}
              key={"eth" + i}
              retry={() => retry(requestAllEthernetPurchases())}
              hasMarginBottom
            />
          );
        return false;
      })}
      {// Universal Product ordering status
      universalProductConfigs.map((c, i) => (
        <SaleProductStatusChip
          request={c.orderProduct}
          title="universal product"
          retry={() => retry(orderUniversalProducts())}
          hideSuccess={hideSuccess}
          key={i}
        />
      ))}
      {// Logic Monitor Products ordering status.
      logicMonitorProductConfigs.map((item, i) => (
        <SaleProductStatusChip
          request={item.orderProduct}
          title="logic monitor product"
          retry={() => retry(orderLogicMonitorProducts())}
          hideSuccess={hideSuccess}
          key={i}
        />
      ))}
      {universalProductConfigs.map((config, i) =>
        config.userDocumentsUpload.map((doc, i) => (
          <SaleProductStatusChip
            title="product document"
            request={doc}
            hideSuccess={hideSuccess}
            key={i}
          />
        ))
      )}
      {(() => {
        if (order.recalculatePrices.fetching)
          return (
            <StatusChip
              type="loading"
              title="Calculating Prices"
              message="Please wait..."
              hasMarginBottom
            />
          );
        if (order.recalculatePrices.response.status === "error")
          return (
            <StatusChip
              type="error"
              title="Error Calculating Prices"
              message={order.recalculatePrices.response.message}
              retry={() => retry(recalculatePrices())}
              hasMarginBottom
            />
          );
      })()}
      {order.orderStatus.fetching && (
        <StatusChip
          type="loading"
          title="Updating order attributes."
          message="Please wait..."
          hasMarginBottom
        />
      )}
      {order.sendForApproval.fetching && (
        <StatusChip
          type="loading"
          title="Sending for Approval."
          message="Please wait..."
          hasMarginBottom
        />
      )}
      {// Special rates removal status
      specialRatesToRemove.map((specialRate, index) => {
        if (specialRate.removeSpecialRate.fetching)
          return (
            <StatusChip
              type="loading"
              title="Removing special rate"
              message="Please wait..."
              key={"sr" + index}
              hasMarginBottom
            />
          );
        if (
          specialRate.removeSpecialRate.response.status === "error" ||
          specialRate.removeSpecialRate.response.status === "warning"
        )
          return (
            <StatusChip
              type="error"
              title="Error removing special rate."
              message={specialRate.removeSpecialRate.response.message}
              key={"sr" + index}
              hasMarginBottom
            />
          );
        if (specialRate.removeSpecialRate.response.status === "success")
          return (
            <StatusChip
              type="success"
              title="Special rate successfully ended."
              message={specialRate.removeSpecialRate.response.msg}
              key={"sr" + index}
              hasMarginBottom
            />
          );
        return false;
      })}
    </div>
  );
}
