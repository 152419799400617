import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Button,
  Box,
  Paper,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import {
  requestAllContacts,
  setNewAccountFieldsFromContact
} from "../../store/account/actions";
import { StatusChip } from "@akj-dev/design-system";

export default function AutoPopulate() {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const contacts = useSelector((state: any) => state.account.contacts);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(requestAllContacts(false, true));
    // dispatch useEffect deps... see:
    // https://github.com/facebook/create-react-app/issues/6880#issuecomment-488158024
  }, [dispatch]);

  if (contacts.fetching)
    return (
      <Box mb={1}>
        <StatusChip
          title="Searching or contacts"
          message="Please wait..."
          type="loading"
        />
      </Box>
    );

  if (contacts.response.status === "error")
    return (
      <Box mb={1}>
        <StatusChip
          title="Error fetching lead contacts"
          message="Please contact support"
          type="error"
        />
      </Box>
    );

  const contactsList = contacts?.response?.data?.contacts || [];
  const hasContacts = contactsList.length > 0;

  if (!hasContacts) return false;

  return (
    <Box my={1}>
      <Paper className={classes.paper}>
        <Box display="flex" p={1} alignItems="center">
          <Typography variant="h5">Fill with existing details:</Typography>

          <FormControl className={classes.formControl}>
            <InputLabel>Select a contact on this lead</InputLabel>
            <Select
              value={selectedIndex}
              onChange={event => setSelectedIndex(event.target.value as number)}
            >
              {contactsList.map((contact: any, i: number) => (
                <MenuItem value={i} key={i}>
                  {contact.nice_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="secondary"
            endIcon={<HowToRegIcon />}
            onClick={() =>
              dispatch(setNewAccountFieldsFromContact(selectedIndex))
            }
            disabled={selectedIndex === -1}
            data-cy="AutoPopulateButton"
          >
            Fill details
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.grey[50]
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: 0,
    minWidth: 300
  }
}));
