import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateEthernetCustomerData } from "../../../../store/ethernetProducts/actionsCreators";
import { ethernetPurchaseArgsValidationErrors } from "../../../../store/ethernetProducts/selectors";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from "@material-ui/core";

class FormSelectField extends Component {
  static propTypes = {
    helperText: PropTypes.string.isRequired,
    param: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    showError: PropTypes.bool.isRequired
  };

  render() {
    const {
      helperText,
      param,
      options,
      configurations,
      updateEthernetCustomerData,
      index,
      errors,
      showError
    } = this.props;
    const configuration = configurations[index];
    const hasError = errors.includes(param);
    return (
      <FormControl fullWidth error={showError && hasError}>
        <InputLabel>{helperText}</InputLabel>
        <Select
          value={configuration.purchaseArgs[param] || "0"}
          disabled={configuration.purchaseIsRequesting}
          onChange={event =>
            updateEthernetCustomerData(index, param, event.target.value)
          }
        >
          {Object.keys(options).map((key, i) => (
            <MenuItem key={i} value={key}>
              {options[key]}
            </MenuItem>
          ))}
        </Select>
        {showError && hasError && (
          <FormHelperText>This is a required field</FormHelperText>
        )}
      </FormControl>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    configurations: state.ethernetProducts.configurations,
    errors: ethernetPurchaseArgsValidationErrors(state, ownProps.index),
    showError: state.ethernetProducts.configurationIndexesSubmitted.includes(
      ownProps.index
    )
  };
};

const mapDispatchProps = dispatch => {
  return {
    updateEthernetCustomerData: (index, key, value) => {
      dispatch(updateEthernetCustomerData(index, key, value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchProps)(FormSelectField);
