import u from "updeep";
import * as actions from "./actionTypes";

const initialState = {
  contactId: "",
  generatingContract: false,
  contract: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SELECT_CONTACT_FOR_CONTRACT:
      return u(
        {
          contactId: action.id
        },
        state
      );
    case actions.GENERATING_CONTRACT:
      return u(
        {
          generatingContract: true
        },
        state
      );
    case actions.CONTRACT_GENERATED:
      return u(
        {
          generatingContract: false,
          contract: action.contract,
          openRedirectDialog: true
        },
        state
      );
    case actions.GENERATING_CONTRACT_FAILED:
      return u(
        {
          generatingContract: false
        },
        state
      );
    default:
      return state;
  }
};
