import * as actionTypes from "../monitoringService/actionTypes";
import * as ProductAPI from "../../api/v1/product";
import { call, put, select, takeLeading } from "redux-saga/effects";
import { getAccountId } from "../order/selectors";
import { getAccountSettings } from "../account/selectors";

/**
 * Get available products in the logic monitor category.
 * @param action
 * @returns {IterableIterator<*>}
 */
function* fetchProductSearch(action) {
  const accountId = yield select(getAccountId);
  const accountSettings = yield select(getAccountSettings);
  const response = yield call(ProductAPI.search, {
    account_id: accountId,
    category_id: accountSettings.category_id_logic_monitor
  });
  yield put({ type: actionTypes.RECEIVE_PRODUCT_SEARCH, response });
}

export function* watchProductSearch() {
  yield takeLeading(actionTypes.REQUEST_PRODUCT_SEARCH, fetchProductSearch);
}
