import * as actionTypes from "./actionTypes";
import * as MobileAPI from "../../../api/v1/mobile";

let validationMessages = [];

/**
 * Set fields for a product
 *
 * @param productId {Int}
 * @param fields {Object}
 * @param index {Int}
 *
 * @returns {Object}
 */
export const setFields = (productId, fields, index) => ({
  type: actionTypes.SET_FIELDS,
  productId,
  fields,
  index
});

/**
 * Set quantity for a product
 *
 * @param product {Object}
 * @param quantity {Int}
 *
 * @returns {Object}
 */
export const setQuantity = (product, quantity) => ({
  type: actionTypes.SET_QUANTITY,
  product,
  quantity: parseInt(quantity || 0)
});

/**
 * Expand/collapse a product
 *
 * @param productId {Int}
 *
 * @returns {Object}
 */
export const toggleExpand = productId => ({
  type: actionTypes.TOGGLE_EXPAND,
  productId
});

/**
 * Validation errors
 *
 * @param configurations {Object}
 *
 * @returns {String}
 */
export function validationErrors(configurations) {
  return validationMessages.join("\n");
}

export const getStockLevel = productId => async dispatch => {
  dispatch({ type: actionTypes.REQUEST_STOCK_LEVEL, productId });
  const response = await MobileAPI.getStockLevel(productId);
  dispatch({ type: actionTypes.RECEIVE_STOCK_LEVEL, productId, response });
};
