import React, { useCallback, useEffect, useRef } from "react";
import { makeStyles, OutlinedInput, Theme } from "@material-ui/core";
import { QuantityInputFieldProps } from "./types";

export const QuantityInputField = ({
  disabled,
  showError,
  inputValue,
  setInputValue,
  showInput,
  setShowInput,
  handleBlur
}: QuantityInputFieldProps) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setShowInput(true);

      const numberValue = parseInt(e.target.value);
      if (isNaN(numberValue)) {
        setInputValue(undefined);
      } else {
        setInputValue(numberValue);
      }
    },
    [setInputValue, setShowInput]
  );

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus();
    }
  }, [showInput]);

  return (
    <OutlinedInput
      className={classes.field}
      error={showError}
      type={"number"}
      disabled={disabled}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      inputProps={{ ref: inputRef }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    width: theme.spacing(5)
  }
}));
