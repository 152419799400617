import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from "redux";
// import immutableStateInvariantMiddleware from "redux-immutable-state-invariant";
import rootSaga from "./rootSaga";
import { createMiddleware as createBeaconMiddleware } from "redux-beacon";
import GoogleAnalytics from "@redux-beacon/google-analytics";
import gaEventsMap from "./rootGaEventsMap";

// Setup Google Analytics tracking
const ga = GoogleAnalytics();

const beaconMiddleware = createBeaconMiddleware(gaEventsMap, ga);

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();

  // Check for state mutations.
  // Development only.
  // NOTE: This makes things really slow so don't run unless diagnosing something specific...
  // const middleware = [
  //   immutableStateInvariantMiddleware({
  //     ignore: [
  //       // See https://github.com/BBB/dropzone-redux-form-example/issues/5
  //       "order.contractUpload.file",
  //       "universalProducts.configs" // just for userDocuments inside each...
  //     ]
  //   }),
  //   sagaMiddleware,
  //   thunk
  // ];

  const middleware = [sagaMiddleware, thunk, beaconMiddleware];

  // Use Redux Dev Tools Chrome Extension if it's installed
  // https://github.com/zalmoxisus/redux-devtools-extension
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
