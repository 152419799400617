import hash from "object-hash";
import {
  GeneralAPIError,
  isAPIError,
  multiPartPostJSON,
  postJSON
} from "./core";
import * as APIJob from "../v1/apiJob";
import { accountSettings } from "../../store/account/reducer";

type RequestParams = Record<string, string | number | false | null | undefined>;

/**
 * Idempotency API helpers.
 * See TP42718
 */

/**
 * Generates a unique hash for idempotent requests
 * Based on URL and request params
 */
export function getIdempotencyKey(endpoint: string, params?: RequestParams) {
  const hashInput = {
    endpoint,
    params,
    accountId: accountSettings.meta?.id,
    spId: accountSettings.settings?.sales_person_id
  };
  const o = hash.sha1(hashInput);
  return o;
}

/**
 * Makes a backgrounded & idempotent DC API request
 * @param url
 * @param params
 */
export function postJSONBackgroundIdempotent<T>(
  url: string,
  params?: RequestParams
): Promise<T> {
  const idempotency_key = getIdempotencyKey(url, params);

  return new Promise(async (resolve, reject) => {
    let poll: number | null = null;
    const call = async () => {
      const response: any = await postJSON(
        url,
        {
          ...params,
          idempotency_key,
          background: 1
        },
        false
      );
      if (response.status !== "pending") {
        if (poll !== null) clearInterval(poll);
        resolve(response);
      }
    };
    // Call it once immediately
    await call();
    // Then call every 5 seconds until resolution
    poll = window.setInterval(call, 5000);
  });
}

/**
 * Makes a backgrounded & idempotent multi-part DC API request
 * Note this different to postJSONBackgroundIdempotent because we don't want to
 * send the original payload multiple times. It's normally large, eg. document
 * uploads
 */
export function multiPartPostJSONBackgroundIdempotent<T>(
  url: string,
  bodyParams?: RequestParams
): Promise<T | GeneralAPIError> {
  const idempotency_key = getIdempotencyKey(url, bodyParams);

  return new Promise(async (resolve, reject) => {
    // Make the initial request...
    const initResponse: any = await multiPartPostJSON(url, {
      ...bodyParams,
      idempotency_key,
      background: "1"
    });

    // Then check status every 5 seconds until resolution
    const poll = window.setInterval(async () => {
      const jobResponse = await APIJob.Result(initResponse.background_id);

      if (jobResponse.status !== "pending") {
        clearInterval(poll);
        resolve(
          isAPIError(jobResponse)
            ? jobResponse
            : (jobResponse.response_data as T)
        );
      }
    }, 5000);
  });
}
