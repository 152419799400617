import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import Filter from "./Filter";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

class SortableTable extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    renderRow: PropTypes.func.isRequired,
    defaultSortColumn: PropTypes.string,
    limitHeight: PropTypes.bool
  };

  state = {
    filter: "",
    sortColumn: false,
    sortDirection: false
  };

  componentDidMount() {
    if (this.props.defaultSortColumn)
      this.setState({ sortColumn: this.props.defaultSortColumn });
  }

  setFilter = e => {
    this.setState({ filter: e.target.value });
  };

  doFilter = row => {
    const term = this.state.filter;
    if (!term) return true;
    for (let col of this.props.columns) {
      const subject = _.get(row, col.accessor);
      if (subject && `${subject}`.toLowerCase().includes(term.toLowerCase()))
        return true;
    }
  };

  clearFilter = () => {
    this.setState({ filter: "" });
  };

  /**
   * Set sort column and direction
   * Cycle through asc/desc/off if it's the same column
   * @param accessor
   */
  setSort = accessor => {
    this.setState(s => {
      if (this.state.sortColumn === accessor) {
        switch (s.sortDirection) {
          case false:
            return { sortDirection: "desc" };
          case "desc":
            return { sortDirection: "asc" };
          case "asc":
            return { sortDirection: false, sortColumn: false };
          default:
        }
      } else {
        return {
          sortDirection: "desc",
          sortColumn: accessor
        };
      }
    });
  };

  render() {
    const { columns, data, renderRow, limitHeight } = this.props;
    const { filter, sortColumn, sortDirection } = this.state;

    let displayData;
    if (!sortColumn) {
      displayData = data;
    } else {
      const isNum = sortColumn.includes("price");
      displayData = data.slice().sort((a, b) => {
        if (!sortColumn) return 0;
        let va = _.get(a, sortColumn),
          vb = _.get(b, sortColumn);

        if (isNum) {
          va = parseFloat(va);
          vb = parseFloat(vb);
        }

        if (va < vb) return sortDirection === "asc" ? -1 : 1;
        if (va > vb) return sortDirection === "asc" ? 1 : -1;
        return 0;
      });
    }

    return (
      <Grid container>
        <Grid item xs={8} />
        <Grid item xs={4}>
          <Filter
            value={filter}
            setFilter={this.setFilter}
            clearFilter={this.clearFilter}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={limitHeight ? { maxHeight: 300, overflowY: "auto" } : {}}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((col, i) =>
                    col.header ? (
                      <TableCell key={i}>
                        <SortHeading
                          onClick={() => this.setSort(col.accessor)}
                          align={col.align}
                        >
                          {col.header}
                          {col.accessor && (
                            <>
                              {this.state.sortColumn === col.accessor ? (
                                this.state.sortDirection === "desc" ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )
                              ) : (
                                <UnfoldMoreIcon />
                              )}
                            </>
                          )}
                        </SortHeading>
                      </TableCell>
                    ) : (
                      <TableCell key={i} />
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {displayData.map((d, i) => this.doFilter(d) && renderRow(d, i))}
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const SortHeading = styled.button`
  background: none;
  border: none;
  color: #9e9e9e;
  font-size: 12px;
  padding: 0;
  outline: 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${props =>
    props.align === "right" ? "flex-end" : "flex-start"};
`;

export default SortableTable;
