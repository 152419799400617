import { ProductTypesEnum, SaleStepEnum } from "./enums";

export const saleStepLabelObject = {
  [SaleStepEnum.STEP_CHOOSE]: "Select",
  [SaleStepEnum.STEP_CUSTOMISE]: "Customise",
  [SaleStepEnum.STEP_ACCOUNT]: "Account",
  [SaleStepEnum.STEP_DELIVERY]: "Delivery",
  [SaleStepEnum.STEP_SUMMARY]: "Summary"
};
export const saleSubStepChooseLabelObject = {
  [ProductTypesEnum.SELECT]: "Product Types",
  [ProductTypesEnum.MOBILE]: "Mobile Tariffs",
  [ProductTypesEnum.HARDWARE]: "Hardware",
  [ProductTypesEnum.BROADBAND]: "Lines, Calls & Broadband",
  [ProductTypesEnum.ETHERNET]: "Ethernet",
  [ProductTypesEnum.MONITORING]: "Monitoring Products",
  [ProductTypesEnum.UNIVERSAL]: "Universal Products"
};
