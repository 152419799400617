import { connect } from "react-redux";
import React, { Component } from "react";
import EthernetConfiguration from "./EthernetConfiguration";
import { requestEthernetPricing } from "../../../store/ethernetProducts/actionsCreators";
import * as Sentry from "@sentry/browser";

class Ethernet extends Component {
  componentDidMount() {
    Sentry.addBreadcrumb({
      message: `Step 2 Ethernet mounted`,
      category: "action"
    });
    this.props.requestEthernetPricing();
  }

  render() {
    const { configurations } = this.props;
    return (
      <div data-cy="Step2Ethernet">
        {configurations.map((configuration, i) => {
          return (
            <EthernetConfiguration
              configuration={configuration}
              configIndex={i}
              key={i}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    configurations: state.ethernetProducts.configurations
  };
};

export default connect(mapStateToProps, {
  requestEthernetPricing
})(Ethernet);
