/**
 * Get the total number of configurations for a certain product ID
 * Used to show totals on step 1 table
 * @param configs
 * @param productId
 */
export function getProductConfigCount(configs, productId) {
  let count = 0;
  configs.forEach(item => {
    if (item.productId === productId) count++;
  });
  return count;
}

/**
 * Get products sorted alphabetically by name.
 * @param products
 */
export function getSortedProducts(products) {
  products = products.slice().sort(function(a, b) {
    return a.name.localeCompare(b.name);
  });
  return products;
}

/**
 * Get index of product config.
 * @param configs
 * @param productId
 */
export function getIndexOfProductConfig(configs, productId) {
  const configIndex = configs.findIndex(item => item.productId === productId);
  return configIndex;
}
