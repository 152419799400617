import React from "react";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";

export function SalesStatusWrapper({
  children,
  menu,
  title,
  loading,
  error,
  description
}) {
  const getContent = () => {
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }

    if (loading) {
      return <CircularProgress />;
    }

    return children;
  };

  return (
    <Box
      height="calc(100vh - 182px)"
      display="flex"
      flexDirection="column"
      paddingTop={1}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
        marginBottom={2}
      >
        <Box>
          <Typography variant="h2" gutterBottom>
            {title}
          </Typography>
          <Typography variant="caption">{description}</Typography>
        </Box>
        {menu}
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {getContent()}
      </Box>
    </Box>
  );
}

SalesStatusWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  loading: PropTypes.bool,
  menu: PropTypes.node,
  title: PropTypes.string.isRequired
};
