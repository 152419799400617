import styled from "styled-components";

const LowPriorityInfoChip = styled.div`
  background: #dedddf;
  border-radius: 5px;
  padding: 16px;
  margin-top: 16px;
`;

export default LowPriorityInfoChip;
