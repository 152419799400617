import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, Switch } from "@material-ui/core";
import { setShowMobileResigns } from "../../../../store/uiState/actions";

export const ResignsToggle = () => {
  const dispatch = useDispatch();
  const showMobileResigns = useSelector<any, boolean>(
    state => state.uiState.showMobileResigns
  );

  const handleShowResigns = () => {
    dispatch(setShowMobileResigns());
  };

  return (
    <FormControlLabel
      data-cy={"mobileResignsToggle"}
      control={
        <Switch onChange={handleShowResigns} checked={showMobileResigns} />
      }
      label="Show contracts available for resign"
    />
  );
};
