import React from "react";
import {
  MenuItem,
  Select,
  FormControl,
  makeStyles,
  Theme
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import InlinePriceIndicator from "../../../../../containers/Shared/Pricing/InlinePriceIndicator";
import { WlrLocationBroadbandProduct } from "../../types";
import { WlrLocationTypeEnum } from "../../enums";

export interface BroadbandProductSelectProps {
  label?: string;
  dense?: boolean;
  broadbandProductId: string;
  broadbandProducts: WlrLocationBroadbandProduct[];
  wlrProductId: WlrLocationTypeEnum;
  configId: number;
  contractLength: number;
  handleBroadbandProduct: (cid: number, bid: string) => void;
}

export const BroadbandProductSelect = ({
  label,
  dense,
  broadbandProductId,
  broadbandProducts,
  wlrProductId,
  configId,
  contractLength,
  handleBroadbandProduct
}: BroadbandProductSelectProps) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} variant={"outlined"}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        data-cy="AddBroadbandSelect"
        type={"outlined"}
        classes={{ root: dense ? classes.dense : undefined }}
        label={label}
        value={broadbandProductId}
        onChange={event => {
          handleBroadbandProduct(configId, event.target.value as string);
        }}
      >
        <MenuItem value={""}>
          <em>None</em>
        </MenuItem>
        {broadbandProducts.map(p => {
          if (p.first_broadband_component?.type === "MPF") return null;
          if (
            wlrProductId !== WlrLocationTypeEnum.NEW_FTTP &&
            p.first_broadband_component?.type === "FTTP"
          )
            return null;
          // TP29038: Only show FTTP products if 'New FTTP Provide' is
          // selected.
          if (
            wlrProductId === WlrLocationTypeEnum.NEW_FTTP &&
            p.first_broadband_component?.type !== "FTTP"
          )
            return null;
          if (
            contractLength === 3 &&
            p.first_broadband_component.type !== "FTTC"
          )
            return null;
          if (
            (wlrProductId !== WlrLocationTypeEnum.NEW_SOGEA &&
              wlrProductId !== WlrLocationTypeEnum.EXISTING_SOGEA &&
              p.first_broadband_component.type === "SOGEA") ||
            ((wlrProductId === WlrLocationTypeEnum.NEW_SOGEA ||
              wlrProductId === WlrLocationTypeEnum.EXISTING_SOGEA) &&
              p.first_broadband_component.type !== "SOGEA")
          )
            return null;
          return (
            <MenuItem className={classes.menuItem} value={p.id} key={p.id}>
              {
                <div className={classes.product}>
                  <span>
                    <b>{p.name} </b>
                    {p.price.first_bill_recurring_price_with_promotions !==
                      p.recurring_price_without_promotions && (
                      <InlinePriceIndicator style={{ fontSize: 15 }} />
                    )}
                    {p.description}{" "}
                  </span>
                  <span className={classes.price}>
                    £{p.price.first_bill_recurring_price_with_promotions}
                  </span>
                </div>
              }
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    fontSize: "0.9em",
    color: theme.palette.text.disabled
  },
  price: {
    color: theme.palette.success.main,
    fontSize: "0.9em",
    paddingLeft: 8
  },
  dense: {
    padding: theme.spacing(0.75)
  },
  formControl: {
    width: "200px"
  },
  menuItem: {
    width: "100%"
  },
  product: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    overflow: "hidden"
  }
}));
