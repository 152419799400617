import u from "updeep";
import _ from "lodash";
import * as actionTypes from "./actionTypes";
import { todaysDateString } from "../../helpers/date";
import { RECEIVE_ACCOUNT_SETTINGS } from "../account/actionTypes";
import { INIT_CPQ } from "../root/actionTypes";

const initialState = {
  // Wizard data is set on the window by DC and should always be present.

  // GS normally starts with an account ID to place an order on
  // This can be the end user account ID on the platform which is different from the head account ID in the account
  // reducer.
  accountId: "",
  accountName: "",

  // Lead ID is set when this is a new account creation pathway
  // i.e. GS has been launched from a lead view in DC
  leadId: "",
  // Like the lead ID but shorter and human readable AFAIK...
  leadNumber: "",

  // GS can either be passed an order & opportunity at init (by DC)
  // or make one itself going into step 2 (which happens with MyAccount)
  // Hopefully we'll always do the latter eventually, but the existing opportunity flow in DC stop this currently.
  id: "",

  // We show a link to the opportunity (if there is one) in the toolbar
  // We also set things directly on the opportunity (not the order). Like the MSA flag.
  opportunityId: "",
  opportunityNumber: "",
  opportunityName: "",

  customerName: "",

  hostEnv: "",
  userResignsPermission: 0,

  quoteOnly: false,

  // contractLength: 24,

  contractUpload: {
    sending: false,
    response: false,
    receivedAt: todaysDateString,
    signedAt: todaysDateString,
    file: false
  },
  trackingDetails: "",

  orderContactId: "",

  // v1/Orders/View + Update
  orderStatus: {
    fetching: false,
    response: {}
  },

  // v1/Opportunity/Update
  opportunityStatus: {
    fetching: false,
    response: {}
  },

  /**
   * Responses from ALB removal requests.
   * Like:
   * {
   *    [bundleId]: {
   *      fetching: false,
   *      response: { ... }
   *    },
   *    [bundleId]: {
   *      ...
   * }
   */
  boltOnRemovals: {},

  orderProductIDs: {},

  recalculatePrices: {
    fetching: false,
    response: {}
  },

  ordersForAccount: {
    fetching: false,
    response: {}
  },

  provision: {
    fetching: false,
    response: {}
  },

  sendForApproval: {
    fetching: false,
    response: {}
  },

  approveApproval: {
    fetching: false // Fetch needs to be reflected in approval UI
    // response: {} // but the response is orderStatus. Hence this mirrors the former
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_CPQ:
      return u(
        {
          accountId: action.accountId,
          accountName: action.accountName,
          id: action.orderId,
          leadId: action.leadId,
          leadNumber: action.leadNumber,
          opportunityId: action.opportunityId,
          opportunityNumber: action.opportunityNumber,
          opportunityName: action.opportunityName,
          customerName: action.customerName,
          hostEnv: action.hostEnv,
          userResignsPermission: action.userResignsPermission
        },
        state
      );

    // Creation of new blank order in DC
    case actionTypes.REQUEST_NEW_ORDER:
      return u(
        {
          orderStatus: {
            fetching: true
          }
        },
        state
      );

    // This receives from:
    // v1/Orders/Create
    case actionTypes.RECEIVE_NEW_ORDER:
      return u(
        {
          orderStatus: {
            fetching: false,
            response: () => action.response
          },
          id: _.get(action.response, "data.id"),
          opportunityId: _.get(action.response, "data.opportunity.id"),
          opportunityNumber: _.get(action.response, "data.opportunity.number")
        },
        state
      );

    case RECEIVE_ACCOUNT_SETTINGS:
      return u(
        {
          // Default suppression of welcome emails for VF Direct users.
          // Would be better to do this via DC, but never mind 🤨
          suppress_welcome_email:
            action?.response?.settings?.can_access_vf_direct === "1"
        },
        state
      );

    // This is used for both v1/Orders/Update and v1/Orders/View calls.
    // Both return the full order view
    case actionTypes.REQUEST_UPDATE_ORDER_STATUS:
      return u(
        {
          orderStatus: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_UPDATE_ORDER_STATUS:
      return u(
        {
          orderStatus: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    case actionTypes.REQUEST_UPDATE_OPPORTUNITY:
      return u(
        {
          opportunityStatus: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_UPDATE_OPPORTUNITY:
      return u(
        {
          opportunityStatus: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    case actionTypes.SET_ORDER_CONTACT:
      return u(
        {
          orderContactId: action.id
        },
        state
      );

    case actionTypes.REQUEST_SEND_FOR_APPROVAL:
      return u(
        {
          sendForApproval: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_SEND_FOR_APPROVAL:
      return u(
        {
          sendForApproval: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    // Note: This sends order status just like orders/update
    case actionTypes.REQUEST_APPROVE_OR_REJECT_APPROVAL:
      return u(
        {
          approveApproval: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_APPROVE_OR_REJECT_APPROVAL:
      // We don't want to wipe existing order state from the store while approval is processing so....
      if (action.response?.status === "pending") return state;
      return u(
        {
          approveApproval: {
            fetching: false
          },
          orderStatus: {
            response: () => action.response
          }
        },
        state
      );

    case actionTypes.SET_QUOTE_ONLY:
      return Object.assign({}, state, {
        quoteOnly: action.value
      });

    case actionTypes.SET_CONTRACT_LENGTH:
      return u(
        {
          contractLength: action.contractLength
        },
        state
      );

    case actionTypes.REQUEST_RECALCULATE_PRICES:
      return u(
        {
          recalculatePrices: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_RECALCULATE_PRICES:
      return u(
        {
          recalculatePrices: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    // Signed Contract Upload

    case actionTypes.SET_CONTRACT_META:
      return u(
        {
          contractUpload: {
            [action.key]: action.value
          }
        },
        state
      );

    case actionTypes.REQUEST_UPLOAD_CONTRACT:
      return u(
        {
          contractUpload: {
            sending: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_UPLOAD_CONTRACT:
      return u(
        {
          contractUpload: {
            sending: false,
            response: action.response
          }
        },
        state
      );

    case actionTypes.RESET_UPLOAD_CONTRACT:
      return u(
        {
          contractUpload: {
            sending: false,
            response: false,
            receivedAt: todaysDateString,
            signedAt: todaysDateString,
            file: false
          }
        },
        state
      );

    // Tracking Details

    case actionTypes.SET_TRACKING_DETAILS:
      return u(
        {
          trackingDetails: action.value
        },
        state
      );

    case actionTypes.REQUEST_GENERATE_CONTRACT:
      return Object.assign({}, state, {
        isRequstingGenerateContract: true
      });

    case actionTypes.RECEIVE_GENERATE_CONTRACT:
      return Object.assign({}, state, {
        isRequstingGenerateContract: false
      });

    case actionTypes.REQUEST_REMOVE_BOLT_ON:
      return u(
        {
          boltOnRemovals: {
            [action.id]: {
              fetching: true,
              response: () => ({})
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_REMOVE_BOLT_ON:
      return u(
        {
          boltOnRemovals: {
            [action.id]: {
              fetching: false,
              response: () => action.response
            }
          }
        },
        state
      );

    case actionTypes.SET_FIELD: // TODO: Bin this
      return u(
        {
          [action.name]: action.value
        },
        state
      );

    case actionTypes.REQUEST_ORDERS_FOR_ACCOUNT:
      return u(
        {
          ordersForAccount: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_ORDERS_FOR_ACCOUNT:
      return u(
        {
          ordersForAccount: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    case actionTypes.RECEIVE_MORE_ORDERS_FOR_ACCOUNT:
      if (action.response.status === "success") {
        return u(
          {
            ordersForAccount: {
              fetching: false,
              response: {
                orders: orders => [...orders, ...action.response.orders]
              }
            }
          },
          state
        );
      } else {
        return u(
          {
            ordersForAccount: {
              fetching: false,
              response: () => action.response
            }
          },
          state
        );
      }

    case actionTypes.REQUEST_PROVISION_ORDER:
      return u(
        {
          provision: {
            fetching: true,
            response: () => ({})
          }
        },
        state
      );

    case actionTypes.RECEIVE_PROVISION_ORDER:
      return u(
        {
          provision: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    case actionTypes.SET_ACCOUNT_ID:
      return u(
        {
          accountId: action.id
        },
        state
      );

    case actionTypes.RESET_ORDER_STATE:
      return { ...initialState, id: false, accountId: false };

    default:
      return state;
  }
};
