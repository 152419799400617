import React from "react";
import styled from "styled-components";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { NEW_PRODUCT } from "../../../../../store/wlrBroadband/constants";
import Chip from "../../../../../components/Shared/mobile/Chip";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import PhoneIcon from "@material-ui/icons/Phone";
import PublicIcon from "@material-ui/icons/Public";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme } from "@material-ui/core/styles";

const CustomCell = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

function MPFNoProductChangesSubRow({
  disabled,
  productInstance,
  configuration,
  removeResign,
  productInstanceId,
  productInstanceType,
  onClick
}) {
  const theme = useTheme();
  const cli = productInstance.pin;
  return (
    <TableRow>
      <TableCell />
      <TableCell colSpan={2}>{cli}</TableCell>
      <TableCell colSpan={2}>
        <CustomCell>
          {productInstanceType === "wlr" ? (
            <PhoneIcon
              style={{
                color: disabled
                  ? theme.palette.text.disabled
                  : theme.palette.text.secondary,
                marginLeft: 0,
                marginRight: 10
              }}
            />
          ) : (
            <PublicIcon
              style={{
                color: disabled
                  ? theme.palette.text.disabled
                  : theme.palette.text.secondary,
                marginLeft: 0,
                marginRight: 10
              }}
            />
          )}
          <div style={{ color: disabled && "#b8b7b9", marginRight: 10 }}>
            {productInstanceType === "wlr"
              ? "Select WLR Product"
              : "Select Broadband Product"}
          </div>
          <ArrowForwardIcon
            style={{
              color: disabled
                ? theme.palette.text.disabled
                : theme.palette.text.secondary
            }}
          />
        </CustomCell>
      </TableCell>
      <TableCell />
      <TableCell>-</TableCell>
      <TableCell />
      <TableCell className="u-text-right" style={{ paddingRight: 0 }}>
        {configuration.resignProductType === NEW_PRODUCT &&
        ((productInstanceType === "wlr" && configuration.wlrProductId) ||
          (productInstanceType === "broadband" &&
            configuration.broadbandProductId)) ? (
          <Chip
            title="New Product"
            color={theme.palette.success.main}
            Icon={AutorenewIcon}
            onClick={removeResign}
            outline={!productInstanceId}
          />
        ) : (
          <Tooltip title="New product">
            <span>
              <IconButton
                disabled={disabled}
                onClick={onClick}
                data-cy="mpfNewProductBtn"
              >
                <AutorenewIcon
                  style={{
                    color: disabled
                      ? theme.palette.text.disabled
                      : theme.palette.text.secondary
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}

export default MPFNoProductChangesSubRow;
