import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddAppointmentStatusChip } from "./AddAppointmentStatusChip";
import {
  doAddBroadbandAppointment,
  doAddWlrAppointment
} from "../../store/wlrBroadband/actions";

/**
 * Show status of add appointment calls
 *
 * @param hideSuccess
 * @returns {*}
 * @constructor
 */
export default function AddAppointmentsStatus({ hideSuccess }) {
  const wlrBBConfigs = useSelector(state => state.wlrBroadband.configurations);
  const dispatch = useDispatch();

  const retry = async action => {
    await dispatch(action);
  };

  return (
    <div>
      {// Add wlr appointment status
      wlrBBConfigs.map((config, index) => {
        if (!config.addWlrAppointment) return false;
        return (
          <AddAppointmentStatusChip
            request={config.addWlrAppointment}
            hideSuccess={hideSuccess}
            key={`wlr${index}`}
            productType="wlr"
            retry={() => retry(doAddWlrAppointment(index))}
          />
        );
      })}
      {// Add broadband appointment status
      wlrBBConfigs.map((config, index) => {
        if (!config.addBroadbandAppointment) return false;
        return (
          <AddAppointmentStatusChip
            request={config.addBroadbandAppointment}
            hideSuccess={hideSuccess}
            key={`broadband${index}`}
            productType="broadband"
            retry={() => retry(doAddBroadbandAppointment(index))}
          />
        );
      })}
    </div>
  );
}
