import React from "react";
import { connect } from "react-redux";
import MoreIcon from "@material-ui/icons/More";
import RefreshIcon from "@material-ui/icons/Refresh";
import { makeStyles } from "@material-ui/core/styles";
import {
  getOrdersForAccount,
  getMoreOrdersForAccount
} from "../../../store/order/actions";
import { StatusChip } from "@akj-dev/design-system";
import SortableTable from "../../../components/Shared/SortableTable";
import OrderStatusChip from "./OrderStatusChip";
import Button from "@material-ui/core/Button";
import { ExpandingCard } from "@akj-dev/design-system";
import WorkIcon from "@material-ui/icons/Work";
import { Box } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  }
}));

function OrderStatus({ orders, getOrdersForAccount, getMoreOrdersForAccount }) {
  const classes = useStyles();
  React.useEffect(() => {
    getOrdersForAccount();
  }, [getOrdersForAccount]);

  if (orders.response.status === "error")
    return (
      <StatusChip
        type="error"
        title="Error retrieving orders for account"
        message={orders.response.message}
      />
    );

  if (orders.response.orders)
    return (
      <Box mt={2}>
        <ExpandingCard
          title="Your Orders"
          subtitle={`${orders.response.orders.length} total`}
          avatar={<WorkIcon />}
          defaultExpanded
        >
          <SortableTable
            limitHeight
            columns={[
              {
                header: "Order ID",
                accessor: "id"
              },
              {
                header: "Status",
                accessor: "status"
              },
              {
                header: "Date Placed",
                accessor: "date_modified"
              },
              {
                header: "One off price",
                accessor: "price.one_off_price_with_promotions"
              },
              {
                header: "Recurring price",
                accessor: "price.first_bill_recurring_price_with_promotions"
              }
            ]}
            data={orders.response.orders}
            renderRow={(data, index) => (
              <TableRow key={index}>
                <TableCell>{data.id}</TableCell>
                <TableCell>
                  <OrderStatusChip status={data.status} />
                </TableCell>
                <TableCell>{data.date_modified}</TableCell>
                <TableCell>
                  {data.price.one_off_price_with_promotions}
                </TableCell>
                <TableCell>
                  {data.price.first_bill_recurring_price_with_promotions}
                </TableCell>
              </TableRow>
            )}
          />
          <Button
            variant="contained"
            endIcon={<MoreIcon />}
            disabled={orders.fetching}
            onClick={() => getMoreOrdersForAccount()}
            className={classes.button}
          >
            Load more
          </Button>
          <Button
            variant="contained"
            endIcon={<RefreshIcon />}
            disabled={orders.fetching}
            onClick={() => getOrdersForAccount(true)}
            className={classes.button}
          >
            Refresh
          </Button>
        </ExpandingCard>
      </Box>
    );

  return false;
}

const mapStateToProps = state => ({
  orders: state.order.ordersForAccount
});

export default connect(mapStateToProps, {
  getOrdersForAccount,
  getMoreOrdersForAccount
})(OrderStatus);
