import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, Switch } from "@material-ui/core";
import { setShowWlrResigns } from "../../../../store/uiState/actions";
import { removeLocation } from "../../../../store/wlrBroadband/actions";
import { WlrLocationProductConfig } from "../types";

export const ResignsToggle = () => {
  const dispatch = useDispatch();
  const showWlrResigns = useSelector<any, boolean>(
    state => state.uiState.showWlrResigns
  );
  const configurations = useSelector<any, WlrLocationProductConfig[]>(
    state => state.wlrBroadband.configurations ?? []
  );

  const handleShowResigns = () => {
    dispatch(setShowWlrResigns());

    // If initial open location in wlrBroadband section is empty when
    // expanding resigns, remove the empty location.
    if (!configurations.find(c => c.locationIndex === 0)) {
      dispatch(removeLocation(0));
    }
  };

  return (
    <FormControlLabel
      data-cy={"wlrBroadbandResignsToggle"}
      control={<Switch onChange={handleShowResigns} checked={showWlrResigns} />}
      label="Show contracts available for resign"
    />
  );
};
