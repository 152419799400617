import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Chip = ({ className, Icon, title, onClick, color }) => {
  return (
    <div className={className} onClick={onClick}>
      <Icon color="inherit" />
      <div style={{ paddingLeft: 5 }}>{title}</div>
    </div>
  );
};

Chip.propTypes = {
  Icon: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  outline: PropTypes.bool,
  noWrap: PropTypes.bool,
  onClick: PropTypes.func
};

export default styled(Chip)`
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 5px 5px 5px 0;
  padding: 2px 8px 2px 2px;
  background: ${props => (props.outline ? "#fff" : props.color)};
  border: 1px solid ${props => props.color};
  color: ${props => (props.outline ? props.color : "#fff")};
  max-width: 260px;
  font-size: 12px;
  ${props => props.noWrap && "white-space: nowrap;"}
  ${props =>
    props.onClick &&
    `
    cursor: pointer;
    font-weight: bold;
  `}
`;
