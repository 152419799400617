import { all } from "redux-saga/effects";
import * as mobile from "./mobile/sagas";
import * as wlrBroadband from "./wlrBroadband/sagas";
import * as universalProducts from "./universalProducts/sagas";
import * as monitoringService from "./monitoringService/sagas";
import * as account from "./account/sagas";

export default function* rootSaga() {
  yield all([
    mobile.watchMobileSearch(),
    mobile.watchBoltOnSearch(),
    mobile.watchProductInstances(),
    mobile.watchCliBoltOnProducts(),
    mobile.watchCliTopUpBoltOnProducts(),
    mobile.watchResignProductSearch(),
    mobile.watchAccountBoltOns(),
    mobile.watchMobileProductData(),
    mobile.watchMobilePricingData(),
    mobile.watchResignPropertyValues(),
    mobile.watchDaisyFreshSearch(),
    mobile.watchHardwareCreditProductSearch(),
    mobile.watchCheckPacCode(),
    mobile.watchCheckStacCode(),
    mobile.watchSimVerification(),
    mobile.watchBarsCompatibility(),
    mobile.watchReservedNumbersList(),
    wlrBroadband.watchProductData(),
    wlrBroadband.watchLineProductInstances(),
    wlrBroadband.watchBroadbandProductInstances(),
    wlrBroadband.watchResignProductSearch(),
    wlrBroadband.watchLineResignProductSearch(),
    wlrBroadband.watchBroadbandResignProductSearch(),
    universalProducts.watchProductSearch(),
    universalProducts.watchListDocuments(),
    monitoringService.watchProductSearch(),
    account.watchAccountSettings(),
    account.watchBillingAddress(),
    account.watchSetBillingAddress(),
    account.watchAllAccountAddresses(),
    account.watchAllAccountContacts(),
    account.watchContactTypes(),
    account.watchRequestCreditVetStatus()
  ]);
}
