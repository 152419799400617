import React from "react";
import { useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import { useAuth } from "@akj-dev/affinity-platform";
import {
  SubNav,
  ProductContainer,
  ViewContainer,
  ContentContainer,
  StandaloneAppWrapper,
  SwitchRoutes,
  BrandedDocumentTitle
} from "@akj-dev/affinity-suite";

import { getRoutes } from "./routes";
import { AffinitySalesApolloProvider } from "./api/AffinitySalesApolloProvider";

const queryClient = new QueryClient();

const AppStandaloneComponent = () => {
  const { accountSettings } = useAuth();
  const routes = getRoutes(accountSettings);
  const { pathname } = useLocation();

  return (
    <QueryClientProvider client={queryClient}>
      <AffinitySalesApolloProvider>
        <ProductContainer>
          <BrandedDocumentTitle title="Sales" />
          {pathname.includes(routes.rootPath) && (
            <SubNav routes={routes} rootTitle="Sales" />
          )}
          <ViewContainer data-cy="SalesViewContainer">
            <ContentContainer>
              <SwitchRoutes routes={routes} />
            </ContentContainer>
          </ViewContainer>
        </ProductContainer>
      </AffinitySalesApolloProvider>
    </QueryClientProvider>
  );
};

export const AppStandalone = () => (
  <StandaloneAppWrapper>
    <AppStandaloneComponent />
  </StandaloneAppWrapper>
);
