import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setContractLengthAllMobileProducts,
  setContractLengthUIFilter,
  toggleCoTerminus
} from "../store/mobile/actionCreators";
import {
  getHardwareCount,
  getHasLeasedHardware
} from "../store/hardware/selectors";
import SmallStatus from "./Shared/SmallStatus";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { getAccountSettings } from "../store/account/selectors";

class ContractLength extends Component {
  canSetCoterminus = () => {
    return this.props.settings.as_can_create_coterminus_contract === "1";
  };

  canSetContractLengthForAllProducts = () => {
    return this.props.settings.can_access_vf_direct !== "1";
  };

  getMenuItemValues = () => {
    if (this.props.settings.can_access_vf_direct === "1") {
      return [1, 12, 18, 24, 36];
    }
    return [1, 3, 12, 24, 36, 48, 60, 72];
  };

  handleContractLengthInputChange = value => {
    if (this.canSetContractLengthForAllProducts()) {
      // the selected values from the contract length control sets global contract length for all products in redux
      this.props.setContractLengthAllMobileProducts(value);
    } else {
      // the selected value from the contract length control to be used as a filter
      this.props.setContractLengthUIFilter(value);
    }
  };

  getContractLength = () => {
    if (this.canSetContractLengthForAllProducts()) {
      // uses the globally applied contract length from redux store
      return this.props.contractLengthInMonthsAllProducts;
    }

    return this.props.contractLengthInMonthsUIFilter;
  };

  render() {
    const {
      contractCoTerminus,
      toggleCoTerminus,
      hardwareCount,
      hasLeasedHardware
    } = this.props;
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={12} md={6} lg={6}>
          <FormControl variant={"outlined"} fullWidth>
            <InputLabel
              htmlFor="contractLengthSelectField"
              style={{
                minWidth: 150
              }}
            >
              Contract Length
            </InputLabel>
            <Select
              labelId="contractLengthSelectField"
              data-cy="contractLengthSelectField"
              label="Contract Length"
              value={this.getContractLength() || ""}
              onChange={event =>
                this.handleContractLengthInputChange(event.target.value)
              }
              disabled={
                contractCoTerminus ||
                this.props.settings.dws_reseller_enabled === "1"
              }
              style={{
                minWidth: 150
              }}
            >
              {this.getMenuItemValues().map(value => (
                <MenuItem key={value} value={value}>
                  {value} month{value > 1 && "s"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          {this.canSetCoterminus() && (
            <FormControlLabel
              control={
                <Switch
                  onChange={toggleCoTerminus}
                  checked={contractCoTerminus}
                />
              }
              label="Co Terminus"
            />
          )}
        </Grid>
        <Grid item>
          {contractCoTerminus && (
            <TextField
              type="number"
              label="Number of Months"
              value={this.getContractLength()}
              onChange={event =>
                this.handleContractLengthInputChange(event.target.value)
              }
            />
          )}
        </Grid>
        {this.getContractLength() !== 24 &&
          hardwareCount > 0 &&
          hasLeasedHardware && (
            <Grid item sm={12}>
              <SmallStatus type="error" style={{ margin: "-20px 0 30px 0" }}>
                <span>
                  You must select a <b>24 month</b> contract length when your
                  order contains leased hardware.
                </span>
              </SmallStatus>
            </Grid>
          )}
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    contractLengthInMonthsAllProducts:
      state.mobile.contractLengthInMonthsAllProducts,
    contractLengthInMonthsUIFilter: state.mobile.contractLengthInMonthsUIFilter,
    contractCoTerminus: state.mobile.contractCoTerminus,
    hardwareCount: getHardwareCount(state),
    hasLeasedHardware: getHasLeasedHardware(state),
    settings: getAccountSettings(state)
  };
};

export default connect(mapStateToProps, {
  setContractLengthAllMobileProducts,
  setContractLengthUIFilter,
  toggleCoTerminus
})(ContractLength);
