import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import Section from "../../../../../components/Shared/Section";
import BulkDynamicField from "../BulkDynamicField";
import SimCardIcon from "@material-ui/icons/SimCard";
import SimSerialVerification from "./SimSerialVerification";
import {
  getProductSupplier,
  getResignProductInstanceByConfig
} from "../../../../../store/mobile/selectors/resigns";
import {
  canKeepSim,
  isMigration
} from "../../../../../store/mobile/selectors/productConfig";
import { getAccountSettings } from "../../../../../store/account/selectors";
import { StatusChip } from "@akj-dev/design-system";

class SimCard extends React.Component {
  static propTypes = {
    targetConfigs: PropTypes.arrayOf(PropTypes.number).isRequired
  };

  render() {
    const {
      existingNetwork,
      acquisitionMethod,
      simIsBuffer,
      isMigration,
      canKeepSim,
      settings,
      isESIM,
      supplier
    } = this.props;

    return (
      <Section title="SIM Card" avatar={<SimCardIcon />}>
        <BulkDynamicField
          propertyName="is_sim_required"
          disabled={
            !(
              (acquisitionMethod === "port/mig" && isMigration && canKeepSim) ||
              (acquisitionMethod === "internal_port" &&
                existingNetwork === "Dise")
            )
          }
        />

        {supplier === "O2" && settings.dws_reseller_enabled !== "1" && (
          <BulkDynamicField propertyName="sim_type" />
        )}
        {settings.dws_reseller_enabled !== "1" && (
          <BulkDynamicField
            propertyName="sim_is_buffer"
            disabled={settings.as_pre_dispatched_sim_required === "1"}
          />
        )}
        {simIsBuffer === "1" && <SimSerialVerification />}
        {isESIM && (
          <StatusChip
            type="info"
            message="Not all handsets are E-SIM compatible. Please confirm the customers handset is compatible before processing. E-SIM provided by Daisy is not compatible with wearables such a smart watches."
          />
        )}
      </Section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const resignProductInstance = getResignProductInstanceByConfig(
    state,
    ownProps.targetConfigs[0]
  );
  const firstConfig = state.mobile.configs[ownProps.targetConfigs[0]];
  return {
    settings: getAccountSettings(state),
    existingNetwork: _.get(resignProductInstance, "network.name", false),
    acquisitionMethod: firstConfig.properties.acquisition_method,
    simIsBuffer: firstConfig.properties.sim_is_buffer,
    isMigration: isMigration(state, ownProps.targetConfigs[0]),
    canKeepSim: canKeepSim(firstConfig),
    isESIM: firstConfig.properties.sim_type === "esim",
    supplier: getProductSupplier(state.mobile, firstConfig.productId)
  };
};

export default connect(mapStateToProps)(SimCard);
