import React from "react";
import { Button, makeStyles, Theme } from "@material-ui/core";
import ArrowDown from "@material-ui/icons/ExpandMore";

export interface ScrollButtonProps {
  listRef: React.MutableRefObject<any>;
  setShowScrollButton: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ScrollButton = ({
  listRef,
  setShowScrollButton
}: ScrollButtonProps) => {
  const classes = useStyles();

  const handleClick = () => {
    setShowScrollButton(false);

    listRef.current?.scrollTo({
      left: 0,
      top: 100,
      behavior: "smooth"
    });
  };

  return (
    <Button
      startIcon={<ArrowDown />}
      className={classes.button}
      variant={"contained"}
      color={"primary"}
      onClick={handleClick}
    >
      More
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    position: "absolute",
    bottom: theme.spacing(1),
    left: "50%",
    zIndex: 10
  }
}));
