import React from "react";
import { useDispatch } from "react-redux";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import {
  getAvailableRouters,
  setEthernetConfigType
} from "../../../../store/ethernetProducts/actionsCreators";
import { ProductCard } from "../../../shared/ProductCard";
import { EthernetTypeEnum } from "../enums";

const ethernetTypeOptions = [
  {
    type: EthernetTypeEnum.ETHERNET_PRODUCT_MANAGED,
    title: "Ethernet",
    subtitle: "Managed Internet Access"
  },
  {
    type: EthernetTypeEnum.ETHERNET_PRODUCT_EFM,
    title: "EFM",
    subtitle: "Managed Internet Access"
  },
  {
    type: EthernetTypeEnum.ETHERNET_PRODUCT_GEA,
    title: "GEA",
    subtitle: "Managed Internet Access"
  },
  {
    type: EthernetTypeEnum.ETHERNET_PRODUCT_PTP,
    title: "Ethernet",
    subtitle: "Point to Point"
  }
];

export interface EthernetConfigurationTypeProps {
  configIndex: number;
  selectedType: EthernetTypeEnum;
}

export const EthernetConfigurationType = ({
  configIndex,
  selectedType
}: EthernetConfigurationTypeProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const setConfigType = (type: EthernetTypeEnum) => {
    dispatch(setEthernetConfigType(configIndex, type));

    // PTP isn't supplied with a router according to @katesmith
    if (type !== EthernetTypeEnum.ETHERNET_PRODUCT_PTP) {
      dispatch(getAvailableRouters(configIndex));
    }
  };

  return (
    <Grid container spacing={2}>
      {ethernetTypeOptions.map(({ title, subtitle, type }) => (
        <Grid key={type} item md={3}>
          <ProductCard
            dataCy={`card-${type}`}
            checked={selectedType === type}
            handleClick={() => setConfigType(type)}
          >
            <div className={classes.body}>
              <Typography>{title}</Typography>
              <Typography className={classes.subtitle}>{subtitle}</Typography>
            </div>
          </ProductCard>
        </Grid>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles({
  body: {
    height: 80
  },
  subtitle: {
    fontSize: "0.75rem"
  }
});
