import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TableScrollWrap from "../../../../components/Shared/TableScrollWrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
import {
  getConfigsWithMonthlyAirtimeCredit,
  getConfigsWithOneOffAirtimeCredit
} from "../../../../store/mobile/selectors/productConfig";

interface Props {
  allChecked?: boolean;
  toggleAllRows?: () => void;
  children: React.ReactNode;
}

export const ConfigTableOuter = ({
  allChecked,
  toggleAllRows,
  children
}: Props) => {
  const hasConfigsWithMonthlyAirtimeCredit =
    useSelector(getConfigsWithMonthlyAirtimeCredit).length > 0;
  const hasConfigsWithOneOffAirtimeCredit =
    useSelector(getConfigsWithOneOffAirtimeCredit).length > 0;

  return (
    <TableScrollWrap>
      <Table size="small">
        <TableHead>
          <TableRow>
            {toggleAllRows && (
              <TableCell>
                <Checkbox
                  data-cy="mobileSelectAll"
                  checked={allChecked}
                  onChange={toggleAllRows}
                />
              </TableCell>
            )}
            <TableCell>CLI</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Price</TableCell>
            {hasConfigsWithMonthlyAirtimeCredit && (
              <TableCell>Monthly Credit</TableCell>
            )}
            {hasConfigsWithOneOffAirtimeCredit && (
              <TableCell>One Off Credit</TableCell>
            )}
            <TableCell>Connection Type</TableCell>
            <TableCell>Configured?</TableCell>
            {toggleAllRows && <TableCell className="actions">Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableScrollWrap>
  );
};
