import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  setQuoteContact,
  resetQuote,
  generateQuote as generateQuoteAction
} from "../../../store/quote/actions";
import { addAllProductsToOrder } from "../../../store/order/actions";
import SelectContact from "../../../components/Shared/Contact/SelectContact";
import OrderProductStatus from "../../../containers/StepSummary/OrderProductStatus";
import { StatusChip } from "@akj-dev/design-system";
import StatusLarge from "../../../components/Shared/StatusLarge";
import QuoteWLRValidationErrors from "./QuoteWLRValidationErrors";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box
} from "@material-ui/core";
import { STEP_SUMMARY } from "../../../store/uiState/constants";

export default function GenerateQuote({ open, handleClose }) {
  const generateQuote = useSelector(state => state.quote.generateQuote);
  const quoteContactId = useSelector(state => state.quote.quoteContactId);
  const activeStep = useSelector(state => state.uiState.activeStep);
  const orderId = useSelector(state => state.order.id);
  const wlrConfigCount = useSelector(
    state => state.wlrBroadband.configurations.length
  );
  const dispatch = useDispatch();

  const doGenerateQuote = async () => {
    // Only add or update products on order if we're not already at the summary.
    // Because of DC performance issues, even updating is too slow. Hence this.
    if (activeStep !== STEP_SUMMARY) {
      await dispatch(addAllProductsToOrder(true));
    }
    await dispatch(generateQuoteAction());
  };

  const closeAndReset = () => {
    dispatch(resetQuote());
    handleClose();
  };

  const quoteId = _.get(generateQuote.response, "data.quote_id");

  return (
    <Dialog
      data-cy="GenerateQuote"
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Generate Quote</DialogTitle>
      <DialogContent>
        {!!wlrConfigCount && <QuoteWLRValidationErrors />}
        {(() => {
          if (generateQuote.fetching)
            return (
              <>
                <Box mb={1}>
                  <StatusChip
                    type="loading"
                    title="Generating Quote"
                    message="Please wait..."
                  />
                </Box>
                <OrderProductStatus />
              </>
            );

          if (quoteId)
            return (
              <StatusLarge
                type="success"
                message={`Quote generated${
                  orderId ? " for Sales ID" + orderId : ""
                }`}
              />
            );

          if (generateQuote.response.status === "error")
            return (
              <StatusLarge
                type="error"
                title="Quote generation failed."
                message={generateQuote.response.message}
              />
            );

          return (
            <Box display="flex" alignItems="flex-end">
              <SelectContact
                current={quoteContactId}
                handleUpdate={id => dispatch(setQuoteContact(id))}
              />
            </Box>
          );
        })()}
      </DialogContent>
      <DialogActions>
        {quoteId || generateQuote.response.status === "error" ? (
          <Button variant="contained" onClick={closeAndReset}>
            OK
          </Button>
        ) : (
          <>
            <Button onClick={handleClose} disabled={generateQuote.fetching}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={doGenerateQuote}
              disabled={generateQuote.fetching || _.isEmpty(quoteContactId)}
            >
              Send
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
