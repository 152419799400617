import u from "updeep";
import _ from "lodash";
import * as actionTypes from "../actionTypes";
import { KEEP_CURRENT_ALB } from "../constants";
import { boltOnsMap } from "../selectors/boltOns";

export default (state, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_BOLT_ON_SEARCH:
      return u(
        {
          boltOnSearch: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_BOLT_ON_SEARCH:
      return u(
        {
          boltOnSearch: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    case actionTypes.REQUEST_ACCOUNT_BOLT_ONS:
      return u(
        {
          accountBoltOns: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_ACCOUNT_BOLT_ONS:
      // As well as storing the response about existing account bolt-ons
      // we store selection defaults to keep any existing bolt-ons per category
      // Categories have to be mapped, hence the complexity
      let selectionUpdate = {
        1: {},
        2: {}
      };
      const result = _.get(action.response, "data.result");
      try {
        if (result) {
          for (let selectionType in boltOnsMap) {
            boltOnsMap[selectionType].forEach(existingType => {
              if (result[existingType]) {
                // The result can have differing keys :-(
                Object.keys(result[existingType]).forEach(network => {
                  const r = result[existingType][network];
                  if (r) {
                    selectionUpdate[r.network.id][
                      selectionType
                    ] = KEEP_CURRENT_ALB;
                  }
                });
              }
            });
          }
        }
      } catch (error) {
        // Note a bad response shape kills the reducer here, hence the catch.
        console.error(
          "Error mapping account bolt-ons (RECEIVE_ACCOUNT_BOLT_ONS)",
          error
        );
      }
      return u(
        {
          accountBoltOns: {
            fetching: false,
            response: () => action.response
          },
          selectedBoltOns: selectionUpdate
        },
        state
      );

    case actionTypes.SET_ACCOUNT_BOLT_ON:
      return u(
        {
          selectedBoltOns: {
            [action.serviceProviderId]: {
              [action.boltOnType]: action.value
            }
          }
        },
        state
      );

    case actionTypes.SET_ACCOUNT_BOLT_ON_START_DATE:
      return u(
        {
          boltOnStartDate: action.date
        },
        state
      );

    default:
      return state;
  }
};
