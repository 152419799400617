import u from "updeep";
import * as actionTypes from "./actionTypes";
import { transformHardwareSearch } from "../transformers";

const initialState = {
  fetching: false,
  response: {},
  configurations: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_HARDWARE_SEARCH:
      return u(
        {
          fetching: true
        },
        state
      );

    case actionTypes.RECEIVE_HARDWARE_SEARCH:
      return u(
        {
          fetching: false,
          response: transformHardwareSearch(action.response)
        },
        state
      );

    default:
      return state;
  }
};
