import React from "react";
import styled from "styled-components";
import Chip from "../../../../../components/Shared/mobile/Chip";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PhoneIcon from "@material-ui/icons/Phone";
import PublicIcon from "@material-ui/icons/Public";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { getTotalPrice } from "../../../../../store/wlrBroadband/selectors";
import { withTheme } from "@material-ui/core/styles";

const CustomCell = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

class MPFProductChangesSubRow extends React.Component {
  render() {
    const {
      productInstance,
      resignProduct,
      removeResign,
      productInstanceType,
      theme
    } = this.props;
    const cli = productInstance.pin;
    const isResignProduct = resignProduct.components[0].is_resign;
    const name = resignProduct.name_for_resign || resignProduct.name;
    const price =
      resignProduct.price.first_bill_recurring_price_with_promotions;
    return (
      <TableRow>
        <TableCell style={{ backgroundColor: "#e0e0e0" }} />
        <TableCell colSpan={2}>{cli}</TableCell>
        <TableCell colSpan={2}>
          <CustomCell>
            {productInstanceType === "wlr" ? (
              <PhoneIcon
                style={{ color: theme.palette.grey[500], marginRight: 10 }}
              />
            ) : (
              <PublicIcon
                style={{ color: theme.palette.grey[500], marginRight: 10 }}
              />
            )}
            <div>{name}</div>
          </CustomCell>
        </TableCell>
        <TableCell />
        <TableCell>
          {isResignProduct
            ? getTotalPrice(productInstance.evo_services)
            : price}
        </TableCell>
        <TableCell />
        <TableCell style={{ paddingRight: 0 }}>
          <Chip
            title="New Product"
            color={theme.palette.success.main}
            Icon={AutorenewIcon}
            onClick={removeResign}
          />
        </TableCell>
      </TableRow>
    );
  }
}

export default withTheme(MPFProductChangesSubRow);
