import React from "react";
import PropTypes from "prop-types";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ChangeIcon from "./ChangeIcon";
import FieldContainer from "./FieldContainer";
import { DC_DATE_FORMAT } from "../../../../../helpers/date";

// TODO: Check the minDate logic is ok. Nothing needs to be set in the past from the property value & property value always exists.

const DateField = ({
  dynamicProperty,
  setProperty,
  propertyValue,
  showWlrChangeIcon,
  propertyValidation,
  recalculatePrices,
  validateConfig,
  disabled
}) => {
  return (
    <FieldContainer>
      <KeyboardDatePicker
        label={`${dynamicProperty.label}${
          dynamicProperty.is_required ? " *" : ""
        }`}
        onChange={(e, date) => {
          setProperty(date);
          recalculatePrices();
          validateConfig();
        }}
        value={propertyValue}
        minDate={propertyValue}
        disabled={disabled}
        format={DC_DATE_FORMAT}
        {...(propertyValidation && { errorText: propertyValidation })}
      />
      {showWlrChangeIcon && <ChangeIcon />}
    </FieldContainer>
  );
};

DateField.propTypes = {
  dynamicProperty: PropTypes.object.isRequired,
  propertyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DateField;
