import u from "updeep";
import * as actionTypes from "./actionTypes";

const initialState = {
  // v1/Product/Search
  productSearch: {
    fetching: false,
    response: {}
  },
  configs: [],
  // v1/Product/ListDocuments
  // per product ID,
  productDocuments: {},
  selectedConfigIndex: false
};

const blankConfig = {
  productId: false,
  oneOffPrice: "",
  recurringPrice: "",
  // Populated from DropZone
  userDocuments: [],
  userDocumentsSendPref: [],
  // and uploaded via
  // v1/OrderProduct/UploadDocument/
  userDocumentsUpload: [
    // responses stored per config:
    // {
    //   fetching: false,
    //   response: {}
    // }
  ],
  orderProduct: {
    fetching: false,
    response: {}
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_PRODUCT_SEARCH:
      return u(
        {
          productSearch: {
            fetching: true
          }
        },
        state
      );
    case actionTypes.RECEIVE_PRODUCT_SEARCH:
      return u(
        {
          productSearch: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    case actionTypes.ADD_PRODUCT:
      return u(
        {
          configs: [
            ...state.configs,
            {
              ...blankConfig,
              productId: action.productId
            }
          ]
        },
        state
      );

    case actionTypes.REMOVE_PRODUCT:
      return u(
        {
          configs: state.configs.filter(c => c.productId !== action.productId)
        },
        state
      );

    case actionTypes.SELECT_CONFIG:
      return u(
        {
          selectedConfigIndex: action.configIndex
        },
        state
      );

    case actionTypes.REQUEST_LIST_DOCUMENTS:
      return u(
        {
          productDocuments: {
            [action.productId]: {
              fetching: true,
              response: {}
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_LIST_DOCUMENTS:
      return u(
        {
          productDocuments: {
            [action.productId]: {
              fetching: false,
              response: () => action.response
            }
          }
        },
        state
      );

    case actionTypes.ADD_USER_DOCUMENT:
      return u(
        {
          configs: {
            [state.selectedConfigIndex]: {
              userDocuments: d => [...d, ...action.files],
              userDocumentsSendPref: p => [...p, false]
            }
          }
        },
        state
      );

    case actionTypes.REMOVE_USER_DOCUMENT:
      return u(
        {
          configs: {
            [state.selectedConfigIndex]: {
              userDocuments: d => d.filter((d, i) => i !== action.index),
              userDocumentsSendPref: d => d.filter((d, i) => i !== action.index)
            }
          }
        },
        state
      );

    case actionTypes.SET_USER_DOCUMENT_SEND_PREF:
      return u(
        {
          configs: {
            [state.selectedConfigIndex]: {
              userDocumentsSendPref: {
                [action.index]: action.pref
              }
            }
          }
        },
        state
      );

    case actionTypes.SET_CONFIG_PROPERTY:
      return u(
        {
          configs: {
            [state.selectedConfigIndex]: {
              [action.property]: action.value
            }
          }
        },
        state
      );

    case actionTypes.REQUEST_ORDER_PRODUCT:
      return u(
        {
          configs: {
            [action.configIndex]: {
              orderProduct: {
                fetching: true,
                isUpdate: action.isUpdate
              }
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_ORDER_PRODUCT:
      return u(
        {
          configs: {
            [action.configIndex]: {
              orderProduct: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionTypes.REQUEST_UPLOAD_DOCUMENT:
      return u(
        {
          configs: {
            [action.configIndex]: {
              userDocumentsUpload: {
                [action.docIndex]: {
                  fetching: true
                }
              }
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_UPLOAD_DOCUMENT:
      return u(
        {
          configs: {
            [action.configIndex]: {
              userDocumentsUpload: {
                [action.docIndex]: {
                  fetching: false,
                  response: () => action.response
                }
              }
            }
          }
        },
        state
      );

    default:
      return state;
  }
};
