import React from "react";
import Icon from "@material-ui/icons/SettingsPhone";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";

const CallTariff = ({ isWlrChangePossible }) => {
  return (
    <Section title="Call Tariff" avatar={<Icon />}>
      {isWlrChangePossible && (
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.call_tariff_id"
        />
      )}
      <WlrBBDynamicField
        productType="wlr"
        propertyName="call_tariff_id"
        isRootProperty={true}
      />
    </Section>
  );
};

CallTariff.propTypes = {};

export default CallTariff;
