// Location types
export const NEW_LINE = "New Line";
export const TRANSFER = "Line Transfer";
export const BROADBAND_ONLY = "Existing Line (Broadband Only)";
export const NEW_FTTP = "New FTTP Provide";
export const RESIGN = "Resign";

// Config types

// wlrProductId for Broadband Only
export const EXISTING_LINE = "Existing Line";

export const NEW_SOGEA = "New SoGEA Provide";
export const EXISTING_SOGEA = "SoGEA FTTC (Broadband Only)";

// Number Reservation types
export const NUMBER_RES_NEXT = "Reserve Next Number";
export const NUMBER_RES_CUSTOM = "Reserve custom number";
export const NUMBER_RES_CHOOSE = "Choose from available numbers";

// Resign types
export const NEW_PRODUCT = "NEW_PRODUCT";
export const SAME_PRODUCT_WITH_CHANGES = "SAME_PRODUCT_WITH_CHANGES";
export const SAME_PRODUCT = "SAME_PRODUCT";

// Special rate types
export const TARIFF = "tariff";
export const NUMBER = "number";

// TP32603: The placeholder appointment reference.
export const AWAITING = "AWAITING";
