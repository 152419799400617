import React from "react";
import styled from "styled-components";
import Icon from "@material-ui/icons/Link";

const CustomLinkIcon = styled(Icon)`
  background-color: ${p => p.theme.palette.primary.main};
  border-radius: 20px;
  color: #fff;
  padding: 5px;
  position: absolute;
  top: -43px;
`;

const LinkIcon = () => {
  return (
    <div style={{ position: "relative" }}>
      <CustomLinkIcon />
    </div>
  );
};

export default LinkIcon;
