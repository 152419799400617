import React from "react";
import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";
import ProductTitle from "../../../../components/Shared/mobile/ProductTitle";
import Spinner from "../../../../components/Shared/Spinner";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableScrollWrap from "../../../../components/Shared/TableScrollWrap";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import { makeStyles } from "@material-ui/core";

const Outer = styled.div`
  display: flex;
  width: 100%;
`;

const StatusTag = styled.div`
  background: ${p => p.theme.palette.success.main};
  color: #fff;
  width: 140px;
  height: 50px;
  padding: 12px 0;
  border-radius: 5px 0 0 5px;
  text-align: center;
`;

const useStyles = makeStyles(theme => ({
  table: {
    background: theme.palette.grey[50]
  }
}));

const ResignProductSelector = ({
  fetching,
  products = [],
  setResignProduct,
  resignProductId
}) => {
  const classes = useStyles();
  return (
    <Outer>
      <StatusTag>
        {fetching ? <Spinner color="white" /> : <AutorenewIcon />}
      </StatusTag>
      <TableScrollWrap style={{ flex: "1 0 auto" }}>
        <Table className={classes.table} size="small">
          <TableBody>
            {products.map(p => {
              if (resignProductId && p.id !== resignProductId) return false;
              return (
                <TableRow key={p.id}>
                  <TableCell>
                    <ProductTitle network={p.first_mobile_component.supplier}>
                      {p.name}
                      {p.first_mobile_component.is_data_only ? (
                        <WifiTetheringIcon titleAccess="Data only tariff" />
                      ) : (
                        false
                      )}
                    </ProductTitle>
                  </TableCell>
                  <TableCell>
                    {p.price.first_bill_recurring_price_with_promotions}
                  </TableCell>
                  <TableCell>
                    <div style={{ float: "right" }}>
                      <Checkbox
                        onChange={() => setResignProduct(p.id)}
                        checked={resignProductId === p.id}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableScrollWrap>
    </Outer>
  );
};

export default ResignProductSelector;
