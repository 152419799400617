import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { isValidPhoneNumber } from "../../../../helpers/validation";

export interface WlrLocationCliFieldProps {
  locationIndex: number;
  isOptional: boolean;
  handleLocationCli: (index: number, newCli: string) => void;
}

export const WlrLocationCliField = ({
  locationIndex,
  isOptional,
  handleLocationCli
}: WlrLocationCliFieldProps) => {
  const [cli, setCli] = useState("");
  const setLocationCli = () => handleLocationCli(locationIndex, cli);

  return (
    <TextField
      label={`Phone Number${isOptional ? " (Optional)" : ""}`}
      variant={"outlined"}
      data-cy="LocationCLI"
      value={cli}
      helperText={
        cli && !isValidPhoneNumber(cli) && "Must be a valid Phone Number"
      }
      error={!!cli && !isValidPhoneNumber(cli)}
      onChange={e => setCli(e.target.value)}
      onBlur={setLocationCli}
    />
  );
};
