import React from "react";
import { StatusChip } from "@akj-dev/design-system";
import Icon from "@material-ui/icons/Settings";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";

const LineConfiguration = props => {
  return (
    <Section
      title="Line Configuration"
      avatar={<Icon />}
      defaultExpanded={false}
    >
      <WlrBBDynamicField
        productType="wlr"
        propertyName="installation_type"
        margin
      />
      <WlrBBDynamicField productType="wlr" propertyName="product_type" margin />
      <WlrBBDynamicField
        productType="wlr"
        propertyName="redcare_installation"
      />
      <StatusChip
        type="info"
        title="Warning:"
        message="Selecting Redcare will remove any existing broadband product"
      />
      <WlrBBDynamicField productType="wlr" propertyName="nr_extra_extensions" />
      <WlrBBDynamicField productType="wlr" propertyName="termination_type" />
      <WlrBBDynamicField productType="wlr" propertyName="cps_required" />
    </Section>
  );
};

LineConfiguration.propTypes = {};

export default LineConfiguration;
