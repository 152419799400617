import React, { PropsWithChildren } from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";

export interface ProductSelectProps<TValue> {
  value?: TValue;
  handleChange: (v: TValue) => void;
  label?: string;
  dataCy?: string;
}

export const ProductSelect = <TValue extends string | number>({
  label,
  dataCy,
  value,
  handleChange,
  children
}: PropsWithChildren<ProductSelectProps<TValue>>) => {
  return (
    <FormControl fullWidth variant={"outlined"} data-cy={dataCy}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        label={label}
        value={value}
        onChange={event => {
          handleChange(event.target.value as TValue);
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
};
