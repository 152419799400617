import {
  STEP_CHOOSE,
  STEP_CUSTOMISE,
  STEP_ACCOUNT,
  STEP_DELIVERY,
  STEP_SUMMARY
} from "./constants";

/**
 * Get steps
 * @param accountRequired {boolean}
 * @returns {*}
 */
export const getSteps = accountRequired => {
  return accountRequired
    ? [STEP_CHOOSE, STEP_CUSTOMISE, STEP_ACCOUNT, STEP_DELIVERY, STEP_SUMMARY]
    : [STEP_CHOOSE, STEP_CUSTOMISE, STEP_DELIVERY, STEP_SUMMARY];
};
