import queryString from "query-string";
import {
  affinityTheme,
  vodaTheme,
  daisyTheme,
  dwpTheme
} from "@akj-dev/design-system";
import {
  affinityFavicon,
  vodafoneFavicon,
  dwpFavicon,
  daisyFavicon
} from "@akj-dev/design-system";

export enum Brand {
  AFFINITY = "AFFINITY",
  VODAFONE = "VODAFONE",
  DAISY = "DAISY",
  DWS = "DWS"
}

export const parseBrandFromUrl = () => {
  let override = "";
  const search = queryString.parse(window.location.search);
  if (typeof search.brand === "string") {
    override = search.brand.toUpperCase();
  }

  switch (override || window.location.hostname) {
    case "VODAFONE":
    case "vodafone.affinity.io":
    case "vodafone.uat.affinity.io":
      return Brand.VODAFONE;
    case "DWS":
    case "dwpbeta.digitalwholesalesolutions.com":
      return Brand.DWS;
    case "DAISY":
      return Brand.DAISY;
    case "AFFINITY":
    default:
      return Brand.AFFINITY;
  }
};

const getThemeForBrand = (brand: Brand) => {
  switch (brand) {
    case Brand.VODAFONE:
      return vodaTheme;
    case Brand.DAISY:
      return daisyTheme;
    case Brand.DWS:
      return dwpTheme;
    case Brand.AFFINITY:
    default:
      return affinityTheme;
  }
};

export const getTheme = () => getThemeForBrand(parseBrandFromUrl());

type BrandMeta = {
  name: string;
  documentTitle: string;
};
const getMetaForBrand: (brand: Brand) => BrandMeta = (brand: Brand) => {
  switch (brand) {
    case Brand.VODAFONE:
      return { name: "Vodafone Portal", documentTitle: "DWP" };
    case Brand.DAISY:
      return { name: "Daisy", documentTitle: "Daisy" };
    case Brand.DWS:
      return { name: "DWP Beta", documentTitle: "DWP" };
    case Brand.AFFINITY:
    default:
      return { name: "Affinity", documentTitle: "Affinity" };
  }
};

export const getBrandMeta = () => getMetaForBrand(parseBrandFromUrl());

const getFaviconForBrand = (brand: Brand) => {
  switch (brand) {
    case Brand.VODAFONE:
      return vodafoneFavicon;
    case Brand.DAISY:
      return daisyFavicon;
    case Brand.DWS:
      return dwpFavicon;
    case Brand.AFFINITY:
    default:
      return affinityFavicon;
  }
};

export const getFavicon = () => getFaviconForBrand(parseBrandFromUrl());
