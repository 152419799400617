import { useMemo } from "react";
import { EthernetTypeEnum } from "./enums";
const options100or1000 = [
  { value: "100", label: "100 Mbit/s" },
  { value: "1000", label: "1000 Mbit/s" }
];
const options10to100 = [
  { value: "10", label: "10 Mbit/s" },
  { value: "20", label: "20 Mbit/s" },
  { value: "30", label: "30 Mbit/s" },
  { value: "40", label: "40 Mbit/s" },
  { value: "50", label: "50 Mbit/s" },
  { value: "60", label: "60 Mbit/s" },
  { value: "70", label: "70 Mbit/s" },
  { value: "80", label: "80 Mbit/s" },
  { value: "90", label: "90 Mbit/s" },
  { value: "100", label: "100 Mbit/s" }
];
const options100to1000 = [
  { value: "100", label: "100 Mbit/s" },
  { value: "200", label: "200 Mbit/s" },
  { value: "300", label: "300 Mbit/s" },
  { value: "400", label: "400 Mbit/s" },
  { value: "500", label: "500 Mbit/s" },
  { value: "600", label: "600 Mbit/s" },
  { value: "700", label: "700 Mbit/s" },
  { value: "800", label: "800 Mbit/s" },
  { value: "900", label: "900 Mbit/s" },
  { value: "1000", label: "1000 Mbit/s" }
];
const optionsEfm = [
  { value: "10", label: "10 Mbit/s" },
  { value: "20", label: "20 Mbit/s" }
];
const optionsFttc = [
  { value: "40", label: "FTTC 40:10 Mbit/s" },
  { value: "80", label: "FTTC 80:20 Mbit/s" }
];

export const useEthernetOptions = (
  type: EthernetTypeEnum,
  siteBBearer: string
): {
  bearerOptions: { value: string; label: string }[];
  speedOptions: { value: string; label: string }[];
} =>
  useMemo(() => {
    if (
      type === EthernetTypeEnum.ETHERNET_PRODUCT_MANAGED ||
      type === EthernetTypeEnum.ETHERNET_PRODUCT_PTP
    ) {
      return {
        bearerOptions: options100or1000,
        speedOptions: siteBBearer === "100" ? options10to100 : options100to1000
      };
    }

    if (type === EthernetTypeEnum.ETHERNET_PRODUCT_GEA) {
      return {
        bearerOptions: optionsFttc,
        speedOptions: []
      };
    }

    if (type === EthernetTypeEnum.ETHERNET_PRODUCT_EFM) {
      return {
        bearerOptions: optionsEfm,
        speedOptions: optionsEfm.filter(
          ({ value }) => parseInt(value, 10) <= parseInt(siteBBearer, 10)
        )
      };
    }

    return {
      bearerOptions: [],
      speedOptions: []
    };
  }, [type, siteBBearer]);
