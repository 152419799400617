import React from "react";
import Section from "../../../../../components/Shared/Section";
import HomeIcon from "@material-ui/icons/Home";
import ArrayDynamicField from "../ArrayDynamicField";

const CostCentres = props => {
  return (
    <Section title="Cost Centres" avatar={<HomeIcon />} defaultExpanded={false}>
      <ArrayDynamicField
        propertyName="cost_centre_1"
        floatingLabelText="Cost Centre 1"
      />
      <ArrayDynamicField
        propertyName="cost_centre_2"
        floatingLabelText="Cost Centre 2"
      />
      <ArrayDynamicField
        propertyName="cost_centre_3"
        floatingLabelText="Cost Centre 3"
      />
    </Section>
  );
};

CostCentres.propTypes = {};

export default CostCentres;
