import React from "react";
import { Box } from "@material-ui/core";
import StepSummary from "../../containers/StepSummary";
import { TitleAndInfo } from "../shared/title-and-info";

export const Summary = () => {
  return (
    <Box>
      <TitleAndInfo title={"Summary"} />
      <StepSummary />
    </Box>
  );
};
