import React from "react";
import { connect } from "react-redux";
import {
  setNewAccountField,
  validateNewAccountField
} from "../../store/account/actions";
import PropTypes from "prop-types";
import { getNewAccountFieldRequired } from "../../store/account/selectors";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Grid } from "@material-ui/core";

class AccountSelectField extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.node
  };

  render() {
    const {
      label,
      name,
      value,
      handleChange,
      errorText,
      required,
      children
    } = this.props;
    return (
      <Grid item sm={6}>
        <FormControl fullWidth error={!!errorText}>
          <InputLabel>{`${label}${required ? " *" : ""}`}</InputLabel>
          <Select onChange={handleChange} name={name} value={value || ""}>
            {children}
          </Select>
          {errorText && (
            <FormHelperText error={!!errorText}>{errorText}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  value: state.account.newAccountFields[ownProps.name],
  required: getNewAccountFieldRequired(state, ownProps),
  errorText: state.account.newAccountFieldsValidation[ownProps.name]
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: event => {
    dispatch(setNewAccountField(ownProps.name, event.target.value));
    dispatch(validateNewAccountField(ownProps.name));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSelectField);
