import u from "updeep";
import * as actionType from "../../actionTypes";
import newConfiguration from "../newConfiguration";
import setBroadbandProduct from "../setBroadbandProduct";
import newLocation from "../newLocation";
import {
  NEW_PRODUCT,
  RESIGN,
  SAME_PRODUCT_WITH_CHANGES
} from "../../constants";
import { mapToLocationAddress } from "../../../../helpers/addresses";

export default (state, action) => {
  switch (action.type) {
    case actionType.REQUEST_LINE_PRODUCT_INSTANCES:
      return u(
        {
          lineProductInstances: {
            fetching: true
          }
        },
        state
      );

    case actionType.RECEIVE_LINE_PRODUCT_INSTANCES:
      if (
        state.lineProductInstances.response.results &&
        action.response.status === "success"
      ) {
        return u(
          {
            lineProductInstances: {
              fetching: false,
              response: {
                results: r => [...r, ...action.response.results]
              }
            }
          },
          state
        );
      } else
        return u(
          {
            lineProductInstances: {
              fetching: false,
              response: () => action.response
            }
          },
          state
        );

    case actionType.REQUEST_BROADBAND_PRODUCT_INSTANCES:
      return u(
        {
          broadbandProductInstances: {
            fetching: true
          }
        },
        state
      );

    case actionType.RECEIVE_BROADBAND_PRODUCT_INSTANCES:
      if (
        state.broadbandProductInstances.response.results &&
        action.response.status === "success"
      ) {
        return u(
          {
            broadbandProductInstances: {
              fetching: false,
              response: {
                results: r => [...r, ...action.response.results]
              }
            }
          },
          state
        );
      } else
        return u(
          {
            broadbandProductInstances: {
              fetching: false,
              response: () => action.response
            }
          },
          state
        );

    case actionType.SET_RESIGN_TYPE:
      return u(
        {
          configurations: configurations => {
            let newConfigurations = [...configurations];
            action.productInstances.forEach(productInstance => {
              const locationIndex = action.locations.findIndex(
                location => location.cli.value === productInstance.pin
              );
              newConfigurations.push({
                productInstanceId: productInstance.id,
                [`${productInstance.type}ProductInstanceId`]: productInstance.id,
                resignProductType: action.resignProductType,
                pin: productInstance.pin,
                type: productInstance.type,
                locationIndex,
                ...((action.resignProductType === SAME_PRODUCT_WITH_CHANGES ||
                  action.resignProductType === NEW_PRODUCT) && {
                  updateProductInstance: {
                    fetching: false,
                    response: {}
                  } // API: v1/Product/Instance/Update
                })
              });
            });
            return newConfigurations;
          }
        },
        state
      );

    case actionType.REMOVE_RESIGN:
      return u(
        {
          configurations: c =>
            c.filter(
              c => !action.productInstanceIds.includes(c.productInstanceId)
            )
        },
        state
      );

    case actionType.SET_RESIGN_WLR_PRODUCT:
      return u(
        {
          configurations: configurations => {
            let newConfigurations = [...configurations];
            const index = newConfigurations.findIndex(
              c => c.productInstanceId === action.productInstanceId
            );
            if (index > -1) {
              newConfigurations[index] = {
                ...newConfigurations[index],
                resignProductType: action.resignProductType,
                ...newConfiguration(action, state)
              };
            } else {
              newConfigurations.push({
                productInstanceId: action.productInstanceId,
                resignProductType: action.resignProductType,
                ...newConfiguration(action, state)
              });
            }
            return newConfigurations;
          }
        },
        state
      );

    case actionType.SET_RESIGN_BROADBAND_PRODUCT:
      return setBroadbandProduct(action, state);

    case actionType.REQUEST_SAME_RESIGN_PRODUCT:
      return u(
        {
          resignProductSearch: {
            fetching: true
          }
        },
        state
      );

    case actionType.RECEIVE_SAME_RESIGN_PRODUCT:
      return u(
        {
          resignProductSearch: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    case actionType.REQUEST_LINE_RESIGN_PRODUCT:
      return u(
        {
          lineResignProductSearch: {
            fetching: true
          }
        },
        state
      );

    case actionType.RECEIVE_LINE_RESIGN_PRODUCT:
      return u(
        {
          lineResignProductSearch: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    case actionType.REQUEST_BROADBAND_RESIGN_PRODUCT:
      return u(
        {
          broadbandResignProductSearch: {
            fetching: true
          }
        },
        state
      );

    case actionType.RECEIVE_BROADBAND_RESIGN_PRODUCT:
      return u(
        {
          broadbandResignProductSearch: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    case actionType.ADD_RESIGN_LOCATION:
      const { address, pin } = action.productInstance;
      return u(
        {
          locations: l => [
            ...l,
            {
              ...newLocation,
              type: RESIGN,
              address: mapToLocationAddress(address || {}),
              cli: { ...newLocation.cli, value: pin }
            }
          ]
        },
        state
      );

    case actionType.REMOVE_RESIGN_LOCATION:
      return u(
        {
          locations: l => [
            ...l.slice(0, action.index),
            ...l.slice(action.index + 1)
          ],
          configurations: u.reject(c => c.locationIndex === action.index)
        },
        state
      );

    case actionType.REQUEST_REMOVE_SPECIAL_RATE:
      return u(
        {
          specialRatesToRemove: {
            [action.index]: {
              removeSpecialDate: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_REMOVE_SPECIAL_RATE:
      return u(
        {
          specialRatesToRemove: {
            [action.index]: {
              removeSpecialRate: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.SET_RESIGN_START_DATE:
      return u(
        {
          resignStartDate: action.date
        },
        state
      );

    case actionType.ADD_SPECIAL_RATE_TO_REMOVE:
      const specialRate = {
        id: action.id,
        type: action.specialRateType,
        date: action.date,
        removeSpecialRate: {
          fetching: false,
          response: {}
        } // API: v1/Product/Instance/EndDateSpecial
      };
      return u(
        {
          specialRatesToRemove: s => [...s, specialRate]
        },
        state
      );

    case actionType.DELETE_SPECIAL_RATE_TO_REMOVE:
      return u(
        {
          specialRatesToRemove: s => s.filter(s => !action.id === s.id)
        },
        state
      );

    case actionType.REQUEST_TAGS_CHECK:
      return u(
        {
          locations: {
            [action.locationIndex]: {
              tagsCheck: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_TAGS_CHECK:
      return u(
        {
          locations: {
            [action.locationIndex]: {
              tagsCheck: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.REQUEST_UPDATE_PRODUCT_INSTANCE:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              updateProductInstance: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_UPDATE_PRODUCT_INSTANCE:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              updateProductInstance: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    default:
      return state;
  }
};
