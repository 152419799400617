import React from "react";
import PropTypes from "prop-types";
import Icon from "@material-ui/icons/DateRange";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";
import { StatusChip } from "@akj-dev/design-system";

const RequiredByDate = ({ isFTTP }) => {
  return (
    <Section title="Required By Date" avatar={<Icon />}>
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.required_by_date"
      />
      {isFTTP && (
        <StatusChip
          type="info"
          title="An engineer appointment isn’t required."
          message="As there is an existing ONT present at this address, the FTTP service will be activated on the existing ONT on the Required By Date selected."
        />
      )}
    </Section>
  );
};

RequiredByDate.propTypes = {
  isFTTP: PropTypes.bool
};

export default RequiredByDate;
