import React, { Component } from "react";
import PropTypes from "prop-types";
import { ExpandingCard } from "@akj-dev/design-system";
import HomeIcon from "@material-ui/icons/Home";
import { ETHERNET_PRODUCT_PTP } from "../../../store/ethernetProducts/constants";
import EthernetCard from "../../Shared/Ethernet/EthernetCard";
import SiteAddress from "../../../components/Shared/Ethernet/SiteAddress";
import CompletedOrderInfo from "../../StepCustomise/Ethernet/CompletedOrderInfo";

// TODO: Go straight back to step 2 on DC validation errors, not here.

class EthernetOrderConfirmation extends Component {
  static propTypes = {
    configuration: PropTypes.object.isRequired
  };

  render() {
    const { configuration } = this.props;

    return (
      <EthernetCard configuration={configuration}>
        <ExpandingCard
          defaultExpanded
          title="Router delivery address"
          avatar={<HomeIcon />}
        >
          {configuration.type === ETHERNET_PRODUCT_PTP ? (
            <h2>N/A</h2>
          ) : (
            <SiteAddress
              purchaseArgs={configuration.purchaseArgs}
              prefix="site_b"
            />
          )}
        </ExpandingCard>
        {configuration.purchaseResponse.order_id && (
          <CompletedOrderInfo configuration={configuration} />
        )}
      </EthernetCard>
    );
  }
}

export default EthernetOrderConfirmation;
