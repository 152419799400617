import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { StatusChip } from "@akj-dev/design-system";
import Spinner from "../../../../../../components/Shared/Spinner";
import {
  doAddBroadbandAppointment,
  getBroadbandAppointments,
  setBroadbandAppointment
} from "../../../../../../store/wlrBroadband/actions";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { getIsSalesPerson } from "../../../../../../store/account/selectors";
import { getDynamicPropertyValue } from "../../../../../../store/wlrBroadband/selectors";

class ChooseBroadbandAppointment extends Component {
  static defaultProps = {};
  static propTypes = {
    targetConfig: PropTypes.number.isRequired
  };

  state = {};

  componentDidMount() {
    if (!_.get(this.props.appointments.response, "appointments", []).length) {
      this.props.getBroadbandAppointments(this.props.targetConfig);
    }
  }

  render() {
    const {
      targetConfig,
      appointments,
      getBroadbandAppointments,
      setBroadbandAppointment,
      doAddBroadbandAppointment,
      addBroadbandAppointment,
      isSalesPerson,
      siteVisitReason,
      productComponentType
    } = this.props;

    if (appointments.fetching)
      return (
        <StatusChip
          type="loading"
          title="Please Wait"
          message="Loading Broadband Appointments"
        />
      );

    if (productComponentType === "SOGEA" && siteVisitReason === null)
      return (
        <StatusChip
          type="info"
          message="You must provide a site visit reason to view available appointments."
        />
      );

    if (_.get(appointments.response, "status") === "error")
      return (
        <StatusChip
          type="error"
          title="Sorry"
          message="Something went wrong fetching broadband appointments."
          retry={() => getBroadbandAppointments(this.props.targetConfig)}
        />
      );

    return (
      <div>
        <FormControl fullWidth margin="normal">
          <InputLabel>Choose your Broadband Appointment time</InputLabel>
          <Select
            onChange={event =>
              setBroadbandAppointment(
                targetConfig,
                event.target.value,
                isSalesPerson
              )
            }
            value={appointments.selectedIndex}
            style={{ width: "100%" }}
            disabled={
              addBroadbandAppointment.fetching ||
              addBroadbandAppointment.response.status === "success"
            }
            data-cy="bbAppointmentSelect"
          >
            <MenuItem value={null} />
            {appointments.response.appointments.map((a, i) => (
              <MenuItem value={i} key={i}>
                {a.string}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {appointments.selectedIndex !== null && (
          <p>
            This appointment date is only reserved for 4 hours. If the contract
            is signed after 4 hour of receipt the next available appointment
            will be given.
          </p>
        )}
        {// TP32603: If the user is a sales person, then actual reserving of
        // the appointment will take place later, after account creation and
        // just before ordering of the products.
        !isSalesPerson && (
          <Button
            variant="contained"
            color="secondary"
            endIcon={
              addBroadbandAppointment.fetching && (
                <Spinner size={30} color="#aaa" />
              )
            }
            onClick={() => doAddBroadbandAppointment(targetConfig)}
            disabled={
              addBroadbandAppointment.fetching ||
              addBroadbandAppointment.response.status === "success" ||
              appointments.selectedIndex === null
            }
            data-cy="reserveBbAppointment"
          >
            Reserve Broadband Appointment
          </Button>
        )}
        {addBroadbandAppointment.response.status === "success" && (
          <StatusChip
            type="success"
            title="Appointment Reserved"
            message={`Reference: ${addBroadbandAppointment.response.appointment_reference}`}
            style={{ marginTop: 20 }}
          />
        )}
        {addBroadbandAppointment.response.status === "error" && (
          <StatusChip
            type="error"
            title="Sorry"
            message="Something went wrong reserving your WLR appointment."
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    appointments:
      state.wlrBroadband.configurations[ownProps.targetConfig]
        .broadbandAppointments,
    addBroadbandAppointment:
      state.wlrBroadband.configurations[ownProps.targetConfig]
        .addBroadbandAppointment,
    isSalesPerson: getIsSalesPerson(state),
    siteVisitReason: getDynamicPropertyValue(
      state,
      "broadband",
      ownProps.targetConfig,
      "bb.site_visit_reason"
    ),
    productComponentType:
      state.wlrBroadband.configurations[ownProps.targetConfig]
        .broadbandProductData.response.broadband.product_component_data.type
  };
};

export default connect(mapStateToProps, {
  getBroadbandAppointments,
  setBroadbandAppointment,
  doAddBroadbandAppointment
})(ChooseBroadbandAppointment);
