import React from "react";
import { connect } from "react-redux";
import SortableTable from "../../../components/Shared/SortableTable";
import {
  requestProductSearch,
  addProduct,
  removeProduct
} from "../../../store/universalProducts/actionCreators";
import { StatusChip } from "@akj-dev/design-system";
import { getProductConfigCount } from "../../../store/universalProducts/selectors";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableActionsCell from "../../../components/Shared/TableActionsCell";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { withTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

class ProductTable extends React.Component {
  componentDidMount() {
    if (this.props.productCount < 1) {
      this.props.requestProductSearch();
    }
  }

  render() {
    const {
      products,
      requestProductSearch,
      productCount,
      configs,
      addProduct,
      removeProduct,
      theme
    } = this.props;
    return (
      <div data-cy="UniversalProductTable">
        <Typography>
          {`${configs.length} Selected / ${productCount} Available`}
        </Typography>
        {products.response.status === "error" ? (
          <StatusChip
            type="error"
            title="Error"
            message="Something went wrong finding hardware products."
            retry={requestProductSearch}
          />
        ) : (
          productCount && (
            <SortableTable
              columns={[
                {
                  header: "Product",
                  accessor: "name"
                },
                {
                  header: "Quantity",
                  accessor: false,
                  align: "right"
                },
                {
                  header: "",
                  accessor: false
                }
              ]}
              data={products.response.products}
              renderRow={(product, index) => {
                const productConfigCount = getProductConfigCount(
                  configs,
                  product.id
                );
                return (
                  <TableRow key={index} selected={!!productConfigCount}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell align="right">{productConfigCount}</TableCell>
                    <TableActionsCell>
                      <IconButton
                        onClick={() => addProduct(product.id)}
                        data-cy="incrementUniversalProduct"
                        style={{ color: theme.palette.success.main }}
                      >
                        <AddIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => removeProduct(product.id)}
                        style={{ color: theme.palette.error.main }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </TableActionsCell>
                  </TableRow>
                );
              }}
            />
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.universalProducts.productSearch,
    productCount: (
      state.universalProducts.productSearch.response.products || []
    ).length,
    configs: state.universalProducts.configs
  };
};

export default connect(mapStateToProps, {
  requestProductSearch,
  addProduct,
  removeProduct
})(withTheme(ProductTable));
