import React from "react";
import PropTypes from "prop-types";
import ChangeIcon from "./ChangeIcon";
import FieldContainer from "./FieldContainer";
import TextField from "@material-ui/core/TextField";

const Text = ({
  dynamicProperty,
  setProperty,
  propertyValue,
  propertyValidation,
  showWlrChangeIcon,
  recalculatePrices,
  validateConfig,
  disabled,
  margin
}) => {
  return (
    <FieldContainer>
      <TextField
        fullWidth
        margin={margin ? "normal" : "none"}
        label={`${dynamicProperty.label}${
          dynamicProperty.is_required ? " *" : ""
        }`}
        onChange={e => setProperty(e.target.value)}
        onBlur={() => {
          recalculatePrices();
          validateConfig();
        }}
        value={propertyValue || ""}
        disabled={disabled}
        helperText={propertyValidation}
        error={propertyValidation}
        name={dynamicProperty.name}
      />
      {showWlrChangeIcon && <ChangeIcon />}
    </FieldContainer>
  );
};

Text.propTypes = {
  dynamicProperty: PropTypes.object.isRequired,
  propertyValue: PropTypes.string
};

export default Text;
