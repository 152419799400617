import React from "react";
import { makeStyles, Theme } from "@material-ui/core";

export interface BroadbandTotalPriceProps {
  totalPrice: string;
}

export const BroadbandTotalPrice = ({
  totalPrice
}: BroadbandTotalPriceProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.label}>Total</div>
      <div className={classes.price}>{totalPrice}</div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontWeight: "bold"
  },
  label: {
    paddingRight: theme.spacing(3)
  },
  price: {
    width: 150,
    marginRight: theme.spacing(1.5)
  }
}));
