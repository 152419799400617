import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { setQuantity } from "../../../store/hardware/configurations/actions";
import { QuantityField } from "../../../components/QuantityField";
import {
  HardwareConfiguration,
  HardwareProduct,
  ReduxHardwareConfigurations
} from "./types";

export const MAX_QTY = 200;
export const QTY_THRESHOLD = 10;

export interface ChooseHardwareTableRowProps {
  style: any;
  product: HardwareProduct;
  configsCount: number;
  configuration?: HardwareConfiguration;
  hasConnection: boolean;
  getStockLevel: (id: number) => void;
  setQuantity?: (q: number) => void;
}

export const ChooseHardwareTableRow = ({
  style,
  product,
  configsCount,
  configuration,
  hasConnection,
  getStockLevel
}: ChooseHardwareTableRowProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const oneOffPrice =
    product?.price?.[hasConnection ? "oneOffWithConnection" : "oneOff"];

  const stockLevel = configuration?.stockLevel;
  const quantity = configuration?.quantity;

  const productId = product.id;
  const handleStockLevel = useCallback(() => getStockLevel(productId), [
    productId,
    getStockLevel
  ]);

  return (
    <Box data-cy={product.name} className={classes.root} style={style}>
      <Box className={classes.name}>{product.name}</Box>
      <Box className={classes.daisy}>
        {/*TODO: Add recurring option*/}
        Not Available
      </Box>
      <Box className={classes.upFront}>
        <QuantityField
          color={"primary"}
          label={"Up Front"}
          price={oneOffPrice}
          quantity={quantity}
          quantityThreshold={QTY_THRESHOLD}
          actualQuantity={configsCount}
          maxQuantity={MAX_QTY}
          setQuantity={value => dispatch(setQuantity(product, value))}
          stockLevel={stockLevel}
          getStockLevel={handleStockLevel}
        />
      </Box>
    </Box>
  );
};

export interface RenderHardwareTableRowProps {
  index: number;
  style: any;
  data: {
    hasConnections: boolean;
    configsCount: number;
    handleStockLevel: (id: number) => void;
    products: HardwareProduct[];
    hardwareConfigurations: ReduxHardwareConfigurations;
  };
}

export const RenderHardwareTableRow = ({
  index,
  style,
  data
}: RenderHardwareTableRowProps) => {
  const {
    hasConnections,
    configsCount,
    handleStockLevel,
    products,
    hardwareConfigurations
  } = data;
  const product = products[index];
  const configuration = hardwareConfigurations[product.id];

  return (
    <ChooseHardwareTableRow
      style={style}
      product={product}
      configsCount={configsCount}
      configuration={configuration}
      hasConnection={hasConnections}
      getStockLevel={handleStockLevel}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: `0 ${theme.spacing(1.5)}px`
  },
  name: {
    marginRight: theme.spacing(1),
    flexGrow: 1
  },
  daisy: {
    width: 300,
    minWidth: 300
  },
  upFront: {
    width: 280,
    minWidth: 280
  }
}));
