import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import BulkDynamicField from "../BulkDynamicField";
import ArrayDynamicField from "../ArrayDynamicField";
import { verifyPacCodes } from "../../../../../store/mobile/actionCreators";
import { StatusChip } from "@akj-dev/design-system";
import { Grid, Button, Box, CircularProgress } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { CheckIcon } from "@akj-dev/design-system";
import { getAccountSettings } from "../../../../../store/account/selectors";
import { TargetConfigsContext } from "../../../context/TargetConfigsContext";

const PAC_UNVERIFIED = "PAC_UNVERIFIED";
const PAC_VERIFIED = "PAC_VERIFIED";
const PAC_ERROR = "PAC_ERROR";

class PacVerification extends Component {
  static propTypes = {
    verifyPacCodes: PropTypes.func.isRequired,
    isVFDirect: PropTypes.bool.isRequired,
    configs: PropTypes.array
  };

  static contextType = TargetConfigsContext;

  state = {
    showVCNPACNotAllowedMessage: false
  };

  doServerSidePACVerification = () => {
    const { targetConfigs } = this.context;

    this.setState({ showVCNPACNotAllowedMessage: false });
    this.props.verifyPacCodes(targetConfigs);
  };

  handleClickVerify = () => {
    if (!this.props.isVFDirect) {
      return this.doServerSidePACVerification();
    }

    const { targetConfigs } = this.context;

    // additional client side check after clicking verify but before sending to server
    // VF Direct Partners cannot proceed with PAC codes starting with VCN - show a message instead
    const PACFieldInputValue = this.props.configs[targetConfigs[0]].properties[
      "pac"
    ];

    const regex = new RegExp(/^VCN/i);
    if (regex.test(PACFieldInputValue)) {
      return this.setState({ showVCNPACNotAllowedMessage: true });
    }

    this.doServerSidePACVerification();
  };

  render() {
    const { configs } = this.props;
    const { targetConfigs } = this.context;

    // Do we need to show the input fields or the API request status?
    const isVerifyingPacs = _.find(
      configs,
      c => _.get(c, "pacCodeCheck.fetching") === true
    );

    // Get PAC verification status
    // ...sure there's a nicer way to do this:
    let pacStatus = PAC_UNVERIFIED;
    let validated = false;

    // Are there verified PACs?
    for (const id of targetConfigs) {
      if (configs[id].properties.pac_expiry_date) pacStatus = PAC_VERIFIED;
    }

    // Are relevant fields not empty & validated?
    for (const id of targetConfigs) {
      if (
        configs[id].properties.pac &&
        configs[id].properties.mobile_number &&
        configs[id].validation &&
        !configs[id].validation.mobile_number &&
        !configs[id].validation.pac
      )
        validated = true;
    }

    // Are there errors though? These take precedence.
    for (const id of targetConfigs) {
      if (_.get(configs[id], "pacCodeCheck.response.status") === "error")
        pacStatus = PAC_ERROR;
    }

    if (isVerifyingPacs)
      return (
        <div>
          {targetConfigs.map(id => {
            if (_.get(configs[id], "pacCodeCheck.fetching")) {
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  m={2}
                  key={id}
                >
                  <Box mb={1}>
                    <CircularProgress />
                  </Box>
                  <Box>
                    Verifying <b>{configs[id].properties.pac}</b> /{" "}
                    <b>{configs[id].properties.mobile_number}</b>
                  </Box>
                </Box>
              );
            }
            return false;
          })}
        </div>
      );

    return (
      <div>
        <ArrayDynamicField
          propertyName="mobile_number"
          floatingLabelText="Mobile Number"
          helperText="Accepts domestic (07) and E164 (+447) formats, but other common formats not accepted (e.g. 00447, 447)"
        />
        <ArrayDynamicField propertyName="pac" floatingLabelText="PAC Code" />
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item sm={6}>
            <Box display="flex" alignItems="center">
              <Button
                variant="contained"
                onClick={this.handleClickVerify}
                disabled={!validated}
              >
                Verify
              </Button>
              <Box ml={2}>
                {pacStatus === PAC_VERIFIED && <CheckIcon />}
                {pacStatus === PAC_ERROR && <ErrorIcon color="error" />}
              </Box>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <BulkDynamicField propertyName="pac_expiry_date" disabled={true} />
          </Grid>
        </Grid>
        {this.state.showVCNPACNotAllowedMessage && (
          <StatusChip
            type="error"
            title="VCN PAC Codes"
            message={
              <span>
                VCN PAC codes come from the Gemini Billing Platform so you are
                unable to process a migration through Affinity, these migration
                requests are classed as Transfer Of Ownerships so please{" "}
                <a href="/support/new-ticket">
                  raise a Ticket to the DWS Service Delivery team
                </a>{" "}
                who will manually process your request , NB the numbers are only
                migrated on current contract terms to your base, you will need
                to process an Upgrade to start receiving revenue share.
              </span>
            }
          />
        )}

        {pacStatus === PAC_ERROR && (
          <StatusChip
            type="error"
            title="Error verifying PACs"
            message={_.get(
              configs[targetConfigs[0]],
              "pacCodeCheck.response.message"
            )}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    configs: state.mobile.configs,
    isVFDirect: getAccountSettings(state).can_access_vf_direct === "1"
  };
};

export default connect(mapStateToProps, {
  verifyPacCodes
})(PacVerification);
