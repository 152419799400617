import React from "react";
import { CircularProgress, makeStyles, Theme } from "@material-ui/core";

export interface LoaderProps {
  text?: string;
}

export const Loader = ({
  text = "Loading, please be patient..."
}: LoaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={64} thickness={5} />
      <span className={classes.text}>{text}</span>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "300px",
    alignItems: "center",
    justifyContent: "center"
  },
  text: {
    padding: theme.spacing(1.5),
    color: theme.palette.grey[300]
  }
}));
