import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";
import Spinner from "../../../../../components/Shared/Spinner";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";
import { Button, Box } from "@material-ui/core";
import RouterIcon from "@material-ui/icons/Router";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import EditIcon from "@material-ui/icons/Edit";
import SelectRouter from "./SiteVisitReason/SelectRouter";

const Delivery = styled.div`
  margin: 10px 0;
  opacity: 0.8;
  font-size: 0.9em;
  svg {
    vertical-align: middle;
    margin-right: 10px;
  }
`;

class Router extends Component {
  static propTypes = {
    targetConfigs: PropTypes.array.isRequired
  };

  state = {
    showAddress: false
  };

  toggleAddress = () => {
    this.setState({
      showAddress: !this.state.showAddress
    });
  };

  render() {
    const {
      targetConfigs,
      deliveryCharge,
      routerId,
      fetching,
      isSOGEA,
      isBTWFTTC
    } = this.props;
    return (
      <Section title="Router" avatar={<RouterIcon />}>
        <p>Choose a router if one is required:</p>
        {isSOGEA || isBTWFTTC ? (
          <SelectRouter
            targetConfigs={targetConfigs}
            productType="broadband"
            propertyName="router_id"
          />
        ) : (
          <WlrBBDynamicField productType="broadband" propertyName="router_id" />
        )}
        {targetConfigs.length === 1 && routerId && (
          <Delivery>
            {fetching ? (
              <Spinner />
            ) : (
              <span>
                {parseFloat(deliveryCharge) > 0 && (
                  <span>
                    <LocalShippingIcon />
                    Delivery Charge: <b>£{deliveryCharge}</b>
                  </span>
                )}
              </span>
            )}
          </Delivery>
        )}
        <Box mt={1}>
          <Button
            variant="contained"
            endIcon={<EditIcon />}
            onClick={this.toggleAddress}
          >
            Edit Address
          </Button>
        </Box>
        {this.state.showAddress && (
          <div>
            <WlrBBDynamicField
              productType="broadband"
              propertyName="router.first_name"
              margin
            />
            <WlrBBDynamicField
              productType="broadband"
              propertyName="router.last_name"
              margin
            />
            <WlrBBDynamicField
              productType="broadband"
              propertyName="router.company"
              margin
            />
            <WlrBBDynamicField
              productType="broadband"
              propertyName="router.email"
              margin
            />
            <WlrBBDynamicField
              productType="broadband"
              propertyName="router.telephone"
              margin
            />
            <WlrBBDynamicField
              productType="broadband"
              propertyName="router.building_name"
              margin
            />
            <WlrBBDynamicField
              productType="broadband"
              propertyName="router.door_number"
              margin
            />
            <WlrBBDynamicField
              productType="broadband"
              propertyName="router.street"
              margin
            />
            <WlrBBDynamicField
              productType="broadband"
              propertyName="router.town"
              margin
            />
            <WlrBBDynamicField
              productType="broadband"
              propertyName="router.county"
              margin
            />
            <WlrBBDynamicField
              productType="broadband"
              propertyName="router.postcode"
              margin
            />
          </div>
        )}
      </Section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const config = state.wlrBroadband.configurations[ownProps.targetConfigs[0]];
  return {
    routerId: config.broadbandProperties.router_id,
    fetching: config.broadbandProductData.fetching,
    deliveryCharge: _.get(
      config.broadbandProductData.response,
      "broadband.router_delivery.one_off_price_with_promotions"
    )
  };
};

export default connect(mapStateToProps)(Router);
