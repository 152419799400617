import u from "updeep";
import * as actionTypes from "./actionTypes";

const initialState = {
  quoteContactId: "",
  generateQuote: {
    fetching: false,
    response: {}
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_QUOTE_CONTACT:
      return u(
        {
          quoteContactId: action.id
        },
        state
      );
    case actionTypes.REQUEST_GENERATE_QUOTE:
      return u(
        {
          generateQuote: {
            fetching: true
          }
        },
        state
      );
    case actionTypes.RECEIVE_GENERATE_QUOTE:
      return u(
        {
          generateQuote: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );
    default:
      return state;
  }
};
