import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EthernetOrderConfirmation from "./OrderConfirmation";
import { IndexedCardInfo } from "../../../screens/shared/indexed-card-info";

class EthernetStep3 extends Component {
  static propTypes = {
    configurations: PropTypes.array.isRequired
  };

  render() {
    const { configurations, cardIndex } = this.props;

    return (
      <IndexedCardInfo index={cardIndex} title={"Ethernet"}>
        {configurations.map((configuration, i) => {
          return (
            <EthernetOrderConfirmation
              configuration={configuration}
              index={i}
              key={i}
            />
          );
        })}
      </IndexedCardInfo>
    );
  }
}

const mapStateToProps = state => {
  return {
    configurations: state.ethernetProducts.configurations
  };
};

export default connect(mapStateToProps)(EthernetStep3);
