import React from "react";
import { StatusChip } from "@akj-dev/design-system";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

function ServiceTariff({
  service_tariff_id,
  configServiceTariffId,
  setServiceTariff
}) {
  if (service_tariff_id)
    return (
      <FormControl fullWidth margin="normal">
        <InputLabel>Service Tariff</InputLabel>
        <Select
          value={
            configServiceTariffId ||
            parseInt(service_tariff_id.current_value) ||
            service_tariff_id.default_value
          }
          onChange={event => setServiceTariff(event.target.value)}
        >
          {service_tariff_id.available_options.map((option, i) => (
            <MenuItem value={option} key={i}>
              {service_tariff_id.available_option_map[option]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );

  return (
    <StatusChip
      type="error"
      title="Product data error."
      message="No service tariff ID available."
    />
  );
}

export default ServiceTariff;
