import React from "react";
import Chip from "../../../../components/Shared/mobile/Chip";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
  SAME_PRODUCT
} from "../../../../store/wlrBroadband/constants";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { RowActionsButton } from "../../../../screens/shared/RowActionsButton";

// TODO: Why do we have two separate RowActions components? (one for wlr & one for mobile)
//  Is there definitely enough difference to justify that?
const RowActions = ({
  configuration,
  setNoChanges,
  setChanges,
  removeResign,
  fetching,
  hasProductId,
  productInstance,
  theme,
  resignProduct,
  linkedConfiguration
}) => {
  const isResignProduct = resignProduct?.components[0]?.is_resign;
  const hasLinkedSameProductConfig =
    !!linkedConfiguration &&
    linkedConfiguration.resignProductType === SAME_PRODUCT;
  if (
    configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES &&
    resignProduct &&
    !isResignProduct
  )
    return (
      <Chip
        title="New Product"
        color={theme.palette.success.main}
        Icon={AutorenewIcon}
        onClick={removeResign}
        outline={!hasProductId}
      />
    );
  switch (configuration.resignProductType) {
    case NEW_PRODUCT:
      return (
        <Chip
          title="New Product"
          color={theme.palette.success.main}
          Icon={AutorenewIcon}
          onClick={removeResign}
          outline={!hasProductId}
        />
      );
    case SAME_PRODUCT_WITH_CHANGES:
      return (
        <Chip
          title="Same Product With Changes"
          color={theme.palette.primary.main}
          Icon={AutorenewIcon}
          onClick={removeResign}
          outline={!hasProductId}
        />
      );
    case SAME_PRODUCT:
      return (
        <Chip
          title="Same Product"
          color={theme.palette.primary.main}
          Icon={SettingsBackupRestoreIcon}
          onClick={removeResign}
        />
      );
    default:
      return (
        <div data-cy="resignOptionsBtn">
          <RowActionsButton
            items={[
              {
                label: "Same product",
                dataCy: "wlrBroadbandResignSPWOChangeBtn",
                disabled: !!fetching,
                onClick: setNoChanges
              },
              {
                label: "Same product with changes",
                dataCy: "wlrBroadbandResignSPWChangeBtn",
                disabled: !!fetching || !!hasLinkedSameProductConfig,
                onClick: () => setChanges(SAME_PRODUCT_WITH_CHANGES)
              },
              ...(productInstance && productInstance.type !== "wlr"
                ? [
                    {
                      label: "New product",
                      dataCy: "wlrBroadbandResignNPWChangeBtn",
                      disabled: !!fetching || !!hasLinkedSameProductConfig,
                      onClick: () => setChanges(NEW_PRODUCT)
                    }
                  ]
                : [])
            ]}
          />
        </div>
      );
  }
};

const mapStateToProps = (state, ownProps) => {
  const configurations = state.wlrBroadband.configurations;
  const linkedConfiguration = configurations.find(
    config =>
      config.pin === ownProps.productInstance.pin &&
      (config.type === "wlr" || config.type === "mpf")
  );
  return {
    fetching:
      state.wlrBroadband.lineProductInstances.fetching ||
      state.wlrBroadband.broadbandProductInstances.fetching ||
      state.wlrBroadband.lineSearch.fetching ||
      state.wlrBroadband.lineResignProductSearch.fetching ||
      state.wlrBroadband.broadbandResignProductSearch.fetching ||
      state.wlrBroadband.resignProductSearch.fetching,
    linkedConfiguration
  };
};

export default connect(mapStateToProps)(withTheme(RowActions));
