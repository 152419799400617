import u from "updeep";
import _ from "lodash";
import * as actionTypes from "../actionTypes";
import { format } from "date-fns";
import { DC_DATE_FORMAT } from "../../../helpers/date";
import { newConfig } from "../defaults";
import { getMinimumPortDate } from "../selectors/productConfig";
import { STAC } from "../constants";

export default (state, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_MOBILE_SEARCH:
      return u(
        {
          mobileSearch: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_MOBILE_SEARCH:
      return u(
        {
          mobileSearch: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    case actionTypes.SET_PRODUCT_QUANTITY:
      const { qty, productId } = action;
      const newQty = parseInt(qty);

      // active configs for productId
      let productConfigs = state.configs.filter(
        c => c.productId === productId && !c.resignId
      );

      // rest of configs
      const restConfigs = state.configs.filter(
        c => c.productId !== productId || c.resignId
      );

      // Get Actual Quantity of products
      const actualQty = productConfigs.length;

      // Remove configs if desired quantity is less
      if (newQty < actualQty) {
        productConfigs = productConfigs.filter((_, i) => i < newQty);
      }

      // Add configs if desired quantity is more
      if (newQty > actualQty) {
        let newConfigsLength = newQty - actualQty;

        while (newConfigsLength) {
          productConfigs.push(
            newConfig(state, action.productId, action.settings)
          );
          newConfigsLength -= 1;
        }
      }

      const configs = [...restConfigs, ...productConfigs];
      return u(
        {
          configs
        },
        state
      );

    case actionTypes.REQUEST_PAC_VERIFICATION:
      return u(
        {
          configs: {
            [action.configId]: {
              pacCodeCheck: { fetching: true }
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_PAC_VERIFICATION:
      let pac_expiry_date = _.get(
        action.response,
        "result.pac_expiry_date",
        ""
      );
      if (pac_expiry_date === "n/a") pac_expiry_date = "";

      return u(
        {
          configs: {
            [action.configId]: {
              pacCodeCheck: {
                fetching: false,
                response: () => action.response
              },
              properties: {
                pac_expiry_date
              },
              validation: {
                ...(pac_expiry_date && { pac_expiry_date: false })
              }
            }
          }
        },
        state
      );

    case actionTypes.REQUEST_STAC_VERIFICATION:
      return u(
        {
          configs: {
            [action.configIndex]: {
              stacCodeCheck: { fetching: true }
            }
          }
        },
        state
      );
    case actionTypes.RECEIVE_STAC_VERIFICATION:
      return u(
        {
          configs: {
            [action.configIndex]: {
              stacCodeCheck: {
                fetching: false,
                response: () => action.response
              },
              properties: {
                stac_expiry_date: _.get(
                  action.response,
                  "result.pac_expiry_date"
                ), // Why is this pac not stac?!
                activation_date: format(
                  getMinimumPortDate(STAC),
                  DC_DATE_FORMAT
                )
              }
            }
          }
        },
        state
      );

    case actionTypes.REQUEST_SIM_VERIFICATION:
      return u(
        {
          configs: {
            [action.configId]: {
              simValidCheck: { fetching: true }
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_SIM_VERIFICATION:
      return u(
        {
          configs: {
            [action.configId]: {
              simValidCheck: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionTypes.REQUEST_BARS_COMPATIBILITY:
      return u(
        {
          configs: {
            [action.configId]: {
              barsCompatibility: { fetching: true }
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_BARS_COMPATIBILITY:
      return u(
        {
          configs: {
            [action.configId]: {
              barsCompatibility: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    default:
      return state;
  }
};
