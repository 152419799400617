import { connect } from "react-redux";
import React from "react";
import {
  updateShippingMethodInState,
  getShippingMethods
} from "../../../store/shipping/shippingAction";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import { IndexedCardInfo } from "../../../screens/shared/indexed-card-info";

class Shipping extends React.Component {
  componentDidMount() {
    this.props.getShippingMethods();
  }

  render() {
    const { shipping, updateShippingMethodInState, cardIndex } = this.props;

    const createShippingName = shipping => {
      return `${shipping.name} - £${shipping.price.one_off_price_with_promotions} + VAT`;
    };

    const options = () => {
      let optionsArray = [
        {
          text: "Standard - Free",
          value: 0
        }
      ];
      if (shipping.products)
        shipping.products.forEach(function(product) {
          if (product.name === "Before 10:30AM Delivery by DPD") {
            optionsArray.push({
              text: createShippingName(product),
              value: product.id
            });
          }
        });
      return optionsArray;
    };

    return (
      <IndexedCardInfo index={cardIndex} title="Shipping">
        {shipping.isFetchingShipping ? (
          <CircularProgress />
        ) : (
          <FormControl fullWidth>
            <InputLabel>Choose shipping Method</InputLabel>
            <Select
              value={shipping.chosenProductId}
              onChange={event => {
                updateShippingMethodInState(event.target.value);
              }}
            >
              {options().map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </IndexedCardInfo>
    );
  }
}

const mapStateToProps = state => {
  return {
    shipping: state.shipping
  };
};

export default connect(mapStateToProps, {
  updateShippingMethodInState,
  getShippingMethods
})(Shipping);
