import React, { Component } from "react";
import { connect } from "react-redux";
import { ExpandingCard } from "@akj-dev/design-system";
import HomeIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ResignConfigTableRow from "./ResignConfigTableRow";
import { hasResigns } from "../../../../store/wlrBroadband/selectors";
import ResignConfigTableOuter from "./ResignConfigTableOuter";
import ResignStartDate from "../ResignStartDate";

class Resigns extends Component {
  render() {
    const {
      configurations,
      selectedConfigs,
      configCount,
      checked,
      toggleAll,
      toggleAction,
      editAction,
      deleteAction,
      retryAction
    } = this.props;
    return (
      <ExpandingCard
        title="Resign Products"
        subtitle={`${selectedConfigs.length} Selected / ${configCount} Available`}
        avatar={<HomeIcon />}
        defaultExpanded
      >
        <ResignConfigTableOuter checked={checked} toggleAll={toggleAll}>
          {configurations.map((configuration, index) => {
            if (!configuration.productInstanceId) return false;
            return (
              <ResignConfigTableRow
                key={index}
                configuration={configuration}
                index={index}
                checked={selectedConfigs.indexOf(index) !== -1}
                toggleAction={() => toggleAction(index)}
                editAction={() => editAction(index)}
                deleteAction={() => deleteAction(configuration.locationIndex)}
                retryAction={retryAction}
              />
            );
          })}
        </ResignConfigTableOuter>
        {hasResigns(configurations) && <ResignStartDate />}
      </ExpandingCard>
    );
  }
}

const mapStateToProps = state => {
  return {
    configurations: state.wlrBroadband.configurations
  };
};

export default connect(mapStateToProps)(Resigns);
