import u from "updeep";
import * as actionTypes from "../actionTypes";
import {
  isValidEmail,
  isValidMobileNumber,
  isValidPACCode
} from "../../../helpers/validation";

// Client side validation of a specified config property.
// Note this is similar to VALIDATE_PRODUCT_PROPERTY in wlrBroadband

export default (state, action) => {
  let validationUpdate = {};
  switch (action.type) {
    // Validate a single config property across single or multiple (bulk selected) configs:
    case actionTypes.VALIDATE_CONFIG_PROPERTY:
      action.configIds.forEach(i => {
        const [message, relatedMessage] = validateConfigProperty(
          action.propertyName,
          state.configs[i].properties
        );
        validationUpdate[i] = {
          validation: {
            [action.propertyName]: message,
            ...relatedMessage
          }
        };
      });
      return u(
        {
          configs: validationUpdate
        },
        state
      );

    // Validate all properties on all mobile configs
    case actionTypes.VALIDATE_ALL_CONFIG_PROPERTIES:
      state.configs.forEach((config, i) => {
        if (config.properties) {
          Object.keys(config.properties).forEach(propertyName => {
            const [message, relatedMessage] = validateConfigProperty(
              propertyName,
              config.properties
            );
            validationUpdate[i] = {
              validation: {
                ...validationUpdate[i]?.validation,
                [propertyName]: message,
                ...relatedMessage
              }
            };
          });
        }
      });
      return u(
        {
          configs: validationUpdate
        },
        state
      );

    default:
      return state;
  }
};

function validateConfigProperty(propertyName, configProperties) {
  let message = undefined,
    relatedMessage = {};

  const value = configProperties[propertyName];

  switch (propertyName) {
    // Bill Limits -------------------------------------------------------
    case "bill_limit":
      relatedMessage.bill_limit =
        value < 0 ? "Bill limit cannot be less than 0" : false;
      break;

    case "additional_notification":
      relatedMessage.notification_preference =
        value === 1 ? "Please specify a notification" : false;
      break;

    case "notification_preference":
      if (value) {
        relatedMessage.notification_contact = "Please specify a contact";
      }
      break;

    case "notification_contact":
      switch (configProperties.notification_preference) {
        case "sms":
          if (!isValidMobileNumber(value)) {
            message = "Must be a valid UK mobile number";
          }
          break;
        case "email":
          if (!isValidEmail(value)) {
            message = "Must be a valid email address";
          }
          break;
        default:
      }
      break;

    // Airtime Credit ----------------------------------------------------
    case "airtime_credit_amount":
      if (value < 0) {
        message = "Must be a positive value";
      }

      // TP15881 - TODO: Is there a better way to do validation globally to avoid all these overlapping checks?
      if (Number(value) && !Number(configProperties.airtime_credit_duration))
        relatedMessage.airtime_credit_duration =
          "Duration must be specified with monthly credit amount";

      if (Number(value) && Number(configProperties.airtime_credit_duration))
        relatedMessage.airtime_credit_duration = false;

      if (!Number(value) && Number(configProperties.airtime_credit_duration))
        relatedMessage.airtime_credit_duration =
          "Duration cannot be specified when monthly credit amount is 0";
      break;

    case "airtime_credit_duration":
      if (Number(value) && !Number(configProperties.airtime_credit_amount)) {
        message =
          "Duration cannot be specified when monthly credit amount is 0";
      }
      if (!Number(value) && Number(configProperties.airtime_credit_amount))
        message = "Duration must be specified with monthly credit amount";
      break;

    // Connection --------------------------------------------------------
    case "stac":
      if (value && !/^\d{6}[A-Za-z]{3}$/.test(value)) {
        message = "Must be a valid STAC code: 6 digits then 3 letters";
      }
      break;

    case "old_mobile_number":
      if (value && !isValidMobileNumber(value))
        message = "Must be a valid UK mobile number";
      break;

    case "mobile_number":
      if (value && !isValidMobileNumber(value))
        message = "Must be a valid UK mobile number";
      break;

    case "pac":
      if (value && !isValidPACCode(value)) {
        message = "Must be a valid PAC code: 3 uppercase letters then 6 digits";
      }
      break;

    case "acquisition_method":
      if (value === "port/mig") {
        if (!configProperties.port_date) {
          relatedMessage.port_date = "A port date must be chosen";
        }
        if (!configProperties.pac_expiry_date) {
          relatedMessage.pac_expiry_date = "Please verify a PAC code";
        }
      } else {
        relatedMessage = {
          port_date: false,
          pac_expiry_date: false
        };
      }
      break;

    case "activation_date":
      // https://www.regextester.com/104039
      // Validator to match DC date format TP26504
      if (
        value &&
        !/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value)
      ) {
        message = "Must be a valid activation date";
      }
      break;

    case "port_date":
      if (!value) {
        message = "A port date must be chosen";
      }
      break;

    case "user_name":
      if (value && value.length > 50)
        message = "User name must be no more than 50 characters.";
      break;

    // case "sim_is_buffer": nope...
    //   console.log("cslled sim_is_buffer", configProperties);
    // add sim_is_buffer /  properties.sim_is_buffer == 1 && // eslint-disable-line eqeqeq
    //     !(_.get(config, "simValidCheck.response.is_valid_sim_number") == 1) // eslint-disable-line eqeqeq

    case "sim_buffer_serial":
      if (configProperties.sim_is_buffer === "1" && !value) {
        message = "A SIM Serial must be entered";
      }
      break;

    default:
  }

  return [message, relatedMessage];
}
