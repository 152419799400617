import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import ContractLength from "../../../components/ContractLength";
import { getAccountSettings } from "../../../store/account/selectors";
import { getAccountId } from "../../../store/order/selectors";
import { IndexedCardInfo } from "../../shared/indexed-card-info";
import { MobileProductConfig } from "../../types";
import { ReduxAccountSettingsEnum } from "../../enums";
import { TariffTable } from "./tariff";
import { BoltOnTable } from "./bolt-on";
import { ResignsTable, ResignsToggle } from "./resigns";

export const ChooseMobile = () => {
  const accountId = useSelector(getAccountId);
  const settings = useSelector<
    any,
    { [k in ReduxAccountSettingsEnum]: "0" | "1" }
  >(getAccountSettings);
  const contractLength = useSelector<any, number | undefined>(
    state =>
      state.mobile.contractLengthInMonthsUIFilter ||
      state.mobile.contractLengthInMonthsAllProducts
  );
  const showMobileResigns = useSelector<any, boolean>(
    state => state.uiState.showMobileResigns
  );
  const configs = useSelector<any, MobileProductConfig[]>(
    state => state.mobile.configs ?? []
  );
  const configsCount = configs.filter(config => !config.resignId).length;

  const showTable = !!contractLength;
  const showResignsToggle =
    showTable && !!accountId && settings.as_mobile_resign_enabled === "1";
  const showBoltOn =
    configsCount > 0 && settings.as_account_level_bolt_ons_enabled === "1";

  let cardIndex = 0;

  return (
    <>
      <IndexedCardInfo
        index={++cardIndex}
        title="Please select the required contract length"
      >
        <Grid container spacing={2}>
          <Grid item>
            <ContractLength />
          </Grid>
          <Grid item>{showResignsToggle && <ResignsToggle />}</Grid>
        </Grid>
      </IndexedCardInfo>
      {showMobileResigns && (
        <IndexedCardInfo
          index={++cardIndex}
          title="Please choose your mobile resigns"
        >
          <ResignsTable />
        </IndexedCardInfo>
      )}
      {showTable && (
        <IndexedCardInfo
          index={++cardIndex}
          title="Please select the required tariffs"
        >
          <TariffTable />
        </IndexedCardInfo>
      )}
      {showBoltOn && (
        <IndexedCardInfo
          index={++cardIndex}
          title="Please select any required bolt ons"
        >
          <BoltOnTable />
        </IndexedCardInfo>
      )}
    </>
  );
};
