import React, { FC } from "react";
import {
  Box,
  Card,
  Checkbox,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";

export interface ProductCardProps {
  dataCy?: string;
  errorMessage?: string;
  disabled?: boolean;
  checked?: boolean;
  handleClick?: () => void;
}

export const ProductCard: FC<ProductCardProps> = ({
  dataCy,
  errorMessage,
  disabled,
  checked,
  handleClick,
  children
}) => {
  const classes = useStyles({ checked });
  return (
    <Card
      raised
      className={!disabled ? classes.root : classes.inactive}
      data-cy={dataCy}
      onClick={!disabled ? handleClick : undefined}
    >
      <Box className={classes.box}>
        <Box className={disabled ? classes.inactiveBody : classes.body}>
          {children}
        </Box>
        <Box className={classes.bottom}>
          <Typography className={classes.errorMessage}>
            {errorMessage}
          </Typography>
          <Box className={classes.checkbox}>
            <Checkbox
              key={`${checked}`} // For some reason it wasn't re-rendering on checked change
              color="primary"
              checked={checked}
              disabled={disabled}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    position: "relative",
    padding: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[50]
    }
  },
  inactive: {
    height: "100%",
    position: "relative",
    padding: 0
  },
  box: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  body: {
    padding: theme.spacing(1)
  },
  inactiveBody: {
    padding: theme.spacing(1),
    opacity: 0.5
  },
  bottom: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  errorMessage: {
    fontSize: "0.75rem",
    justifyContent: "center",
    color: theme.palette.error.main,
    padding: `0 ${theme.spacing(1)}px`
  },
  checkbox: {
    height: "100%",
    alignItems: "flex-end"
  }
}));
