import { useEffect, useState } from "react";
import {
  ethernet,
  hardware,
  mobile,
  monitoring,
  universal,
  wlrBroadband
} from "./constants";
import { useSelector } from "react-redux";
import { getAccountSettings } from "../../store/account/selectors";
import { ProductTypeCardType } from "./types";
import { ReduxAccountSettingsEnum } from "../enums";
import { getHostEnv } from "../../store/order/selectors/getHostEnv";

export const useGetProductTypeCards = () => {
  const [dataCards, setDataCards] = useState<ProductTypeCardType[]>([]);

  const hostEnv = useSelector(getHostEnv);
  const settings: {
    [k in ReduxAccountSettingsEnum]: "0" | "1";
  } = useSelector(getAccountSettings);

  useEffect(() => {
    const localData: ProductTypeCardType[] = [];

    if (hostEnv === "my_account") {
      localData.push(hardware);
    } else {
      localData.push(
        mobile,
        hardware,
        wlrBroadband,
        ethernet,
        monitoring,
        universal
      );
    }

    setDataCards(localData.filter(({ id }) => settings[id] === "1"));
  }, [settings, setDataCards]);

  return dataCards;
};
