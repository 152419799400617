import * as actionTypes from "./actionTypes";
import * as OrderProductAPI from "../../api/v1/orderProduct";
import _ from "lodash";

/**
 * Request logic monitoring products (saga)
 * @returns {{type: string}}
 */
export const requestProductSearch = () => ({
  type: actionTypes.REQUEST_PRODUCT_SEARCH
});

/**
 * Set alert preferences for logic monitoring products (saga)
 * @returns {{type: string}}
 */
export const setAlertPreferences = alertPreferences => ({
  type: actionTypes.SET_ALERT_PREFERENCES,
  alertPreferences
});

/**
 * Add a product configuration
 * @param productId
 * @returns {{type: string}}
 */
export const addProduct = (productId, oneOffPrice, recurringPrice) => ({
  type: actionTypes.ADD_PRODUCT,
  productId,
  oneOffPrice,
  recurringPrice
});

/**
 * Remove a product configuration
 * @param configIndex
 * @returns {{type: string}}
 */
export const removeProduct = configIndex => ({
  type: actionTypes.REMOVE_PRODUCT,
  configIndex
});

/**
 * Fire OrderProduct calls for logic monitor products.
 * @returns {Function}
 */
export const orderLogicMonitorProducts = () => async (dispatch, getState) => {
  const state = getState();
  const { configs } = state.monitoringService;

  await Promise.all(
    configs.map(async (config, configIndex) => {
      // Check if the product's already in the order. (If it is, update instead of create)
      const orderProductId = _.get(config, "orderProduct.response.data.id");
      const orderParams = {
        contract_length_in_months: state.order.contractLength,
        account_id: state.order.accountId,
        order_id: state.order.id
      };

      const shouldUpdate = !!orderProductId;
      dispatch({
        type: actionTypes.REQUEST_ORDER_PRODUCT,
        configIndex,
        isUpdate: shouldUpdate
      });
      const response = shouldUpdate
        ? await OrderProductAPI.update(orderProductId, orderParams)
        : await OrderProductAPI.create(config.productId, orderParams);
      dispatch({
        type: actionTypes.RECEIVE_ORDER_PRODUCT,
        configIndex,
        response
      });
    })
  );
};
