import { LostReason } from "@akj-dev/cpq/src/containers/Shared/DeleteDraftModal/enum";
import { kongAxios } from "../../core/kongAxios";

//

export type CancelOrderReason =
  | {
      reason: Exclude<LostReason, LostReason.OTHER>;
      otherReason?: never;
    }
  | {
      reason: LostReason.OTHER;
      otherReason?: string;
    };

type CancelOrderOptsWithAccount = CancelOrderReason & {
  orderId: string;
  accountId?: string;
  leadId?: never;
};

type CancelOrderOptsWithLead = CancelOrderReason & {
  orderId: string;
  accountId?: never;
  leadId?: string;
};

/**
 * Close the opportunity (ie. an order in draft) and provide the reason for deleting.
 * Must pass leadId or accountId during the CPQ process, optional from Sales Status.
 *
 * Note: Also see very similar in packages/cpq/src/api/v1/orders.ts where this was ported from
 */
export const Cancel = (
  options: CancelOrderOptsWithAccount | CancelOrderOptsWithLead
) =>
  kongAxios
    .request<{ status: string; data: {}; message?: string }>({
      url: "ExternalServices/v1/Orders/Cancel",
      params: {
        order: options.orderId,
        lead: options.leadId,
        account: options.accountId,
        reason: options.reason,
        opp_closed_lost_reason: options.otherReason,
        also_mark_opp_as_closed_lost: "1"
      }
    })
    .then(response => response.data);
