import React from "react";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

const Filter = ({ value, setFilter, clearFilter }) => {
  return (
    <Grid container spacing={1} alignItems="flex-end" justify="flex-end">
      <Grid item>
        {value ? (
          <IconButton onClick={clearFilter}>
            <CancelIcon />
          </IconButton>
        ) : (
          <IconButton>
            <SearchIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item>
        <TextField
          label="Search"
          name="filter"
          value={value}
          onChange={setFilter}
          fullWidth
          margin="normal"
        />
      </Grid>
    </Grid>
  );
};

export default Filter;
