import { connect } from "react-redux";
import React from "react";
import _ from "lodash";
import OrderView from "./OrderView";
import UploadSignedContract from "./UploadSignedContract";
import * as Sentry from "@sentry/browser";
import OrderProductStatus from "./OrderProductStatus";
import OrderAPIResponseApprovalMessages from "./OrderAPIResponseApprovalMessages";
import EthernetMessage from "./EthernetMessage";
import ProvisionOrderButton from "./ProvisionOrderButton";
import { isHardwareOnly } from "../../store/hardware/selectors";
import { getOrderProductCallsFetching } from "../../store/order/selectors";
import ETFMethod from "./ETFMethod";
import ETFPrice from "../StepCustomise/Mobile/ETFPrice";
import { Box } from "@material-ui/core";
import GenerateContract from "./GenerateContract";
import { fetchingRemoveSpecialRates } from "../../store/wlrBroadband/selectors";
import { CompleteMessage } from "./CompleteMessage";
import {
  getProvisioningBundleId,
  getUploadedContractId,
  getRequiresCustomerApproval
} from "../../store/order/selectors";
import { getPendingContractId } from "../../store/generateContract/selectors";
import {
  getAccountSettings,
  getIsSalesPerson
} from "../../store/account/selectors";
import AddAppointmentStatus from "./AddAppointmentsStatus";
import { IndexedCardInfo } from "../../screens/shared/indexed-card-info";
import { ApprovalRequiredInfoMessage } from "./ApprovalRequiredInfoMessage";
import { ApproveQuote } from "./ApproveQuote";
import { StatusChip } from "@akj-dev/design-system";
import { getHostEnv } from "../../store/order/selectors/getHostEnv";

class StepSummary extends React.Component {
  componentDidMount() {
    Sentry.addBreadcrumb({
      message: `Step 4 (Summary / Generate Contract) mounted}`,
      category: "action"
    });
  }

  render() {
    const {
      isHardwareOnly,
      order,
      ethernetConfigurations,
      orderProductCallsFetching,
      fetchingRemoveSpecialRates,
      uploadedContractId,
      pendingContractId,
      provisioningBundleId,
      accountSettings,
      isSalesPerson,
      requiresCustomerApproval,
      hostEnv
    } = this.props;

    const approval = _.get(order.orderStatus.response, "data.approval");
    const actions = _.get(order.orderStatus.response, "data.actions");

    // Order complete? Better stop showing those buttons if so....
    // There's multiple ways that can happen though, depending on order type and launch scenario:
    const complete = !!(
      uploadedContractId || // Contract uploaded
      pendingContractId || // Contract generated
      // Hardware only / MyAccount flow:
      // "Provisioned" can either be "sent for approval", which triggers the provisioning process in DC,
      // or "actually provisioned".
      provisioningBundleId ||
      (isHardwareOnly &&
        !!_.get(order.sendForApproval, "response.data.submitted"))
    );

    return (
      <IndexedCardInfo
        index={1}
        title="Please check out the summary information"
      >
        {// TP32603.
        isSalesPerson && <AddAppointmentStatus />}
        {orderProductCallsFetching ||
        _.get(order.orderStatus, "response.data.products", []).length < 1 ||
        fetchingRemoveSpecialRates ? (
          <OrderProductStatus />
        ) : (
          <>
            <OrderProductStatus hideSuccess />
            <OrderView />
            {_.get(order.orderStatus.response, "data.valid_etf_methods", [])
              .length > 0 && (
              <>
                <ETFPrice />
                <ETFMethod />
              </>
            )}
            {!!approval.required &&
              accountSettings.can_access_vf_direct === "1" && (
                <ApprovalRequiredInfoMessage />
              )}

            {!!approval.required &&
              hostEnv !== "my_account" &&
              hostEnv !== "platform" && <OrderAPIResponseApprovalMessages />}
            {ethernetConfigurations.length > 0 && <EthernetMessage />}
            {complete ? (
              <CompleteMessage />
            ) : (
              <Box mb={2} textAlign="right" data-cy="completeOrderActions">
                {requiresCustomerApproval ? (
                  <StatusChip
                    type="info"
                    message={
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box mr={1}>Sale requires approval</Box>
                        {!order.sendForApproval.fetching && <ApproveQuote />}
                      </Box>
                    }
                  />
                ) : null}

                {hostEnv === "my_account" ||
                isHardwareOnly ||
                accountSettings.as_provision_only === "1" ? (
                  <ProvisionOrderButton />
                ) : (
                  !requiresCustomerApproval && (
                    <>
                      {ethernetConfigurations.length < 1 &&
                        !order.sendForApproval.fetching &&
                        (actions.upload_signed_contract?.ok ? (
                          <UploadSignedContract order={order} />
                        ) : (
                          actions.upload_signed_contract?.reason && (
                            <StatusChip
                              type="info"
                              message={
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Box mr={1}>
                                    Cant Upload Contract:{" "}
                                    {actions.upload_signed_contract?.reason}
                                  </Box>
                                </Box>
                              }
                            />
                          )
                        ))}
                      {accountSettings.as_generate_contract === "1" &&
                        (actions.generate_and_send_contract?.ok ? (
                          <GenerateContract />
                        ) : (
                          actions.generate_and_send_contract?.reason && (
                            <StatusChip
                              type="info"
                              message={
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Box mr={1}>
                                    Cant Send E-Sign Contract:{" "}
                                    {actions.generate_and_send_contract?.reason}
                                  </Box>
                                </Box>
                              }
                            />
                          )
                        ))}
                    </>
                  )
                )}
              </Box>
            )}
          </>
        )}
      </IndexedCardInfo>
    );
  }
}

const mapStateToProps = state => ({
  order: state.order,
  ethernetConfigurations: state.ethernetProducts.configurations,
  isHardwareOnly: isHardwareOnly(state),
  orderProductCallsFetching: getOrderProductCallsFetching(state),
  fetchingRemoveSpecialRates: fetchingRemoveSpecialRates(state),
  uploadedContractId: getUploadedContractId(state),
  pendingContractId: getPendingContractId(state),
  provisioningBundleId: getProvisioningBundleId(state),
  // approvalSubmitted: getApprovalSubmitted(state),
  accountSettings: getAccountSettings(state),
  hostEnv: getHostEnv(state),
  isSalesPerson: getIsSalesPerson(state),
  requiresCustomerApproval: getRequiresCustomerApproval(state)
});

export default connect(mapStateToProps)(StepSummary);
