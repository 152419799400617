import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import BulkDynamicField from "../../BulkDynamicField";
import { StatusChip } from "@akj-dev/design-system";
import { verifyStacCode } from "../../../../../../store/mobile/actionCreators";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";

class StacCode extends React.Component {
  static propTypes = {
    targetConfigs: PropTypes.arrayOf(PropTypes.number).isRequired
  };
  state = { showVerificationFeedback: false };

  shouldEnableVerificationButton = () => {
    if (this.props.stacCodeCheck.fetching) {
      return false;
    }

    // is input value empty?
    if (!this.props.stac || !this.props.old_mobile_number) {
      return false;
    }

    // passed client side validation?
    return !this.props.stacValid && !this.props.old_mobile_numberValid;
  };

  handleConfigValueChange = () => {
    this.setState({ showVerificationFeedback: false });
  };

  render() {
    const { stacCodeCheck, verifyStacCode, targetConfigs } = this.props;

    const expiryDate = _.get(stacCodeCheck.response, "result.pac_expiry_date"); // Why pac?!

    return (
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <BulkDynamicField
            propertyName="stac"
            afterUpdate={this.handleConfigValueChange}
          />
        </Grid>
        <Grid item sm={6}>
          <BulkDynamicField
            propertyName="old_mobile_number"
            helperText="Accepts domestic (07) and E164 (+447) formats, but other common formats not accepted (e.g. 00447, 447)"
            afterUpdate={this.handleConfigValueChange}
          />
        </Grid>
        <Grid item sm={4}>
          <Button
            variant="contained"
            onClick={() => {
              this.setState({ showVerificationFeedback: true });
              verifyStacCode(targetConfigs[0]);
            }}
            disabled={!this.shouldEnableVerificationButton()}
            data-cy="StacCodeVerify"
            fullWidth
          >
            Verify
          </Button>
        </Grid>
        <Grid item sm={8}>
          {this.state.showVerificationFeedback &&
            (stacCodeCheck.fetching ? (
              <StatusChip
                type="loading"
                title="Verifying STAC code"
                message="Please wait..."
              />
            ) : expiryDate ? (
              <StatusChip
                type="success"
                title="STAC expiry date"
                message={expiryDate}
              />
            ) : stacCodeCheck.response.status === "error" ? (
              <StatusChip
                type="error"
                title="Problem checking STAC code"
                message={_.get(
                  stacCodeCheck.response,
                  "message.result.errors[0].message"
                )}
              />
            ) : (
              false
            ))}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const config = state.mobile.configs[ownProps.targetConfigs[0]];
  return {
    old_mobile_number: _.get(
      state.mobile.configs[ownProps.targetConfigs[0]],
      "properties.old_mobile_number"
    ),
    stac: _.get(config, "properties.stac"),
    stacCodeCheck: config.stacCodeCheck,
    stacValid: _.get(config.validation, "stac"),
    old_mobile_numberValid: _.get(config.validation, "old_mobile_number")
  };
};

export default connect(mapStateToProps, {
  verifyStacCode
})(StacCode);
