import { connect } from "react-redux";
import React from "react";
import HardwareDelivery from "./HardwareDelivery";
import Ethernet from "./Ethernet/index";
import WlrBroadband from "./WlrBroadband";
import Contacts from "../../containers/StepDelivery/Contacts";
import * as Sentry from "@sentry/browser";
import { getMobileSIMsRequired } from "../../store/mobile/selectors/delivery";
import Shipping from "../../containers/StepDelivery/Mobile/Shipping";
import TrackingDetails from "../../containers/StepDelivery/Mobile/TrackingDetails";
import { getHardwareCount } from "../../store/hardware/selectors";
import Order from "./Order";
import BillingAddressSection from "./BillingAddressSection";
import NewOrder from "../StepCustomise/NewOrder";
import { Grid } from "@material-ui/core";
import { getAccountSettings } from "../../store/account/selectors";
import { IndexedCardInfo } from "../../screens/shared/indexed-card-info";

const cardIndexes = ["A", "B", "C", "D", "E", "F", "G"];

class StepDelivery extends React.Component {
  componentDidMount() {
    Sentry.addBreadcrumb({
      message: `Step 3 (Delivery Details) mounted}`,
      category: "action"
    });
  }

  render() {
    const {
      orderId,
      hardwareProductCount,
      mobileSIMsRequired,
      ethernetConfigurations,
      ethernetProductCount,
      wlrBroadbandProductCount,
      accountSettings
    } = this.props;

    let cardIndex = -1;

    return (
      <IndexedCardInfo
        index={1}
        title="Please fill out the delivery information"
      >
        {!orderId ? (
          <NewOrder />
        ) : (
          <Grid container spacing={2}>
            {hardwareProductCount || mobileSIMsRequired ? (
              <>
                <Grid item sm={12}>
                  <HardwareDelivery cardIndex={cardIndexes[++cardIndex]} />
                </Grid>
                <Grid item sm={12}>
                  <Shipping cardIndex={cardIndexes[++cardIndex]} />
                </Grid>
                <Grid item sm={12}>
                  <TrackingDetails cardIndex={cardIndexes[++cardIndex]} />
                </Grid>
              </>
            ) : (
              <Grid item sm={12}>
                <BillingAddressSection cardIndex={cardIndexes[++cardIndex]} />
              </Grid>
            )}
            {wlrBroadbandProductCount > 0 && (
              <Grid item sm={12}>
                <WlrBroadband cardIndex={cardIndexes[++cardIndex]} />
              </Grid>
            )}
            {ethernetProductCount > 0 && (
              <Grid item sm={12}>
                <Ethernet
                  configurations={ethernetConfigurations}
                  cardIndex={cardIndexes[++cardIndex]}
                />
              </Grid>
            )}
            {accountSettings.dws_reseller_enabled !== "1" && (
              <Grid item sm={12}>
                <Contacts cardIndex={cardIndexes[++cardIndex]} />
              </Grid>
            )}
            {accountSettings.dws_reseller_enabled !== "1" && (
              <Grid item sm={12}>
                <Order cardIndex={cardIndexes[++cardIndex]} />
              </Grid>
            )}
          </Grid>
        )}
      </IndexedCardInfo>
    );
  }
}

const mapStateToProps = state => ({
  orderId: state.order.id,
  mobileSIMsRequired: getMobileSIMsRequired(state),
  hardwareProductCount: getHardwareCount(state),
  ethernetProductCount: state.ethernetProducts.configurations.length,
  wlrBroadbandProductCount: state.wlrBroadband.configurations.length,
  accountSettings: getAccountSettings(state)
});

export default connect(mapStateToProps)(StepDelivery);
