import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateIcon from "@material-ui/icons/DateRange";
import {
  addWeekdays,
  DC_DATE_FORMAT,
  isWorkingDay
} from "../../../../../helpers/date";
import { ExpandingCard } from "@akj-dev/design-system";
import { updateEthernetCustomerData } from "../../../../../store/ethernetProducts/actionsCreators";
import { StatusChip } from "@akj-dev/design-system";
import { ethernetPurchaseArgsValidationErrors } from "../../../../../store/ethernetProducts/selectors";

export default function CustomerRequiredDate({ index }) {
  const [expanded, setExpanded] = useState(true);
  const dispatch = useDispatch();

  const config = useSelector(
    state => state.ethernetProducts.configurations[index]
  );
  const errors = useSelector(state =>
    ethernetPurchaseArgsValidationErrors(state, index)
  );
  const showErrors = useSelector(state =>
    state.ethernetProducts.configurationIndexesSubmitted.includes(index)
  );

  const handleChangeDate = (event, date) => {
    dispatch(updateEthernetCustomerData(index, "customer_required_date", date));
  };

  const hasError = errors.includes("customer_required_date");
  const forceExpanded = showErrors && hasError;

  const handleHeaderClick = () => {
    if (forceExpanded) {
      return;
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (forceExpanded) {
      setExpanded(true);
    }
  }, [forceExpanded]);

  const minDate = addWeekdays(Date.now(), 45, false); // Note: This default value is populated in the reducer too.

  return (
    <ExpandingCard
      title="Customer Required Date"
      avatar={<DateIcon />}
      expanded={forceExpanded || expanded}
      onHeaderClick={handleHeaderClick}
    >
      <KeyboardDatePicker
        label={
          showErrors && hasError
            ? "This is a required field"
            : "Minimum lead time is 45 working days"
        }
        error={showErrors && hasError}
        format={DC_DATE_FORMAT}
        fullWidth={true}
        minDate={minDate}
        shouldDisableDate={date => !isWorkingDay(date)}
        onChange={handleChangeDate}
        KeyboardButtonProps={{
          "aria-label": "change required date"
        }}
        value={config.purchaseArgs.customer_required_date}
      />
      <StatusChip
        type="info"
        message="This is not a guaranteed install date, Daisy will aim to meet this requested date but only once a site survey has been completed will a date be confirmed."
      />
    </ExpandingCard>
  );
}
