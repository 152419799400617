import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TableScrollWrap from "../../../../components/Shared/TableScrollWrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const ResignConfigTableOuter = ({ checked, toggleAll, children }) => {
  return (
    <TableScrollWrap>
      <Table size="small" data-cy="wlrBroadbandStep2ResignsTable">
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={checked}
                onChange={toggleAll}
                data-cy="wlrBroadbandResignToggleAll"
              />
            </TableCell>
            <TableCell>CLI</TableCell>
            <TableCell />
            <TableCell>Product</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Resign Type</TableCell>
            <TableCell>Configured?</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableScrollWrap>
  );
};

export default ResignConfigTableOuter;
