import React from "react";
import { connect } from "react-redux";
import { Grid, MenuItem } from "@material-ui/core";
import { IndexedCardInfo } from "../../../screens/shared/indexed-card-info";
import AccountTextField from "../AccountTextField";
import AccountSelectField from "../AccountSelectField";

const AccountDetails = ({
  business_entity_type,
  cardIndex,
  head_account_id
}) => {
  return (
    <IndexedCardInfo index={cardIndex} title="Account Details">
      <form>
        <Grid container spacing={2}>
          {!!head_account_id && (
            <AccountTextField
              label="Head Account"
              name="head_account_id"
              disabled={true}
            />
          )}
          <AccountTextField label="Company Name" name="name" />
          <AccountSelectField label="Company Type" name="business_entity_type">
            <MenuItem value="Limited Company (LTD)">
              Limited Company (LTD)
            </MenuItem>
            <MenuItem value="Sole Trader / Owner Manager">
              Sole Trader / Owner Manager
            </MenuItem>
            <MenuItem value="Limited Liability Partnership">
              Limited Liability Partnership
            </MenuItem>
            <MenuItem value="Charity">Charity</MenuItem>
          </AccountSelectField>
          {business_entity_type === "Charity" && (
            <AccountTextField label="Charity Number" name="charity_number" />
          )}
          <AccountTextField
            label="Company Registration Number"
            name="registered_number"
          />
          <AccountSelectField label="No. of employees" name="employees">
            <MenuItem value="0">Not Provided</MenuItem>
            <MenuItem value="1">1-10</MenuItem>
            <MenuItem value="11">11-20</MenuItem>
            <MenuItem value="21">21-50</MenuItem>
            <MenuItem value="51">51-100</MenuItem>
            <MenuItem value="101">101-499</MenuItem>
            <MenuItem value="500">500-999</MenuItem>
            <MenuItem value="1000">1000+</MenuItem>
          </AccountSelectField>
          {business_entity_type !== "Charity" && (
            <AccountTextField label="VAT Number" name="vat_number" />
          )}
        </Grid>
      </form>
    </IndexedCardInfo>
  );
};

const mapStateToProps = state => {
  return {
    business_entity_type: state.account.newAccountFields.business_entity_type,
    head_account_id: state.account.newAccountFields.head_account_id
      ? true
      : false,
    orderState: state.order
  };
};

export default connect(mapStateToProps)(AccountDetails);
