import { useEffect } from "react";

import {
  AccountMeta,
  AccountSettings,
  useAuth
} from "@akj-dev/affinity-platform";

const GTM_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NV94JPB');`;

const GTM_NO_SCRIPT = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NV94JPB" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

const getInitScript = (dataLayer: DataLayerInitDate) => `
window.dataLayer = window.dataLayer || [];
window.dataLayer.push(${JSON.stringify(dataLayer)})`;

type DataLayerInitDate = AccountMeta &
  AccountSettings & {
    externalUserName: string | undefined;
    event: "accountMeta";
  };

export const useGtm = () => {
  const { accountSettings, accountMeta, externalUserName } = useAuth();

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = GTM_SCRIPT;

    const noScript = document.createElement("noscript");
    noScript.innerHTML = GTM_NO_SCRIPT;

    document.head.insertBefore(script, document.head.childNodes[0]);
    document.body.insertBefore(noScript, document.body.childNodes[0]);
  }, []);

  useEffect(() => {
    const initScript = document.createElement("script");
    initScript.innerHTML = getInitScript({
      ...accountSettings,
      ...accountMeta,
      externalUserName,
      event: "accountMeta"
    });

    document.head.appendChild(initScript);
  }, [accountSettings, accountMeta, externalUserName]);
};
