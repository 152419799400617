import React from "react";
import { useDispatch } from "react-redux";
import { MenuItem } from "@material-ui/core";
import { updateEthernetConfiguration } from "../../../../store/ethernetProducts/actionsCreators";
import { IndexedCardInfo } from "../../../shared/indexed-card-info";
import { ProductSelect } from "../../../shared/ProductSelect";
import { ReduxEthernetConfiguration } from "../types";
import { EthernetTypeEnum } from "../enums";
import { useEthernetOptions } from "../hooks";
import { EthernetConfigurationType } from "./EthernetConfigurationType";
import { EthernetConfigurationAddress } from "./EthernetConfigurationAddress";
import { EthernetConfigurationRouterSelect } from "./EthernetConfigurationRouterSelect";
import { EthernetConfigurationFailOver } from "./EthernetConfigurationFailOver";

interface EthernetConfigurationProps {
  configuration: ReduxEthernetConfiguration;
  configIndex: number;
}

export const EthernetConfiguration = ({
  configuration,
  configIndex
}: EthernetConfigurationProps) => {
  const {
    site_b_bearer,
    site_b_cli,
    speed,
    type,
    purchaseArgs
  } = configuration;

  const dispatch = useDispatch();
  const { bearerOptions, speedOptions } = useEthernetOptions(
    type,
    site_b_bearer
  );
  const handleOption = (option: string) => (newBearer: string) => {
    dispatch(updateEthernetConfiguration(configIndex, option, newBearer));
  };

  const isGea = type === EthernetTypeEnum.ETHERNET_PRODUCT_GEA;
  const isManaged = type === EthernetTypeEnum.ETHERNET_PRODUCT_MANAGED;
  const isEfm = type === EthernetTypeEnum.ETHERNET_PRODUCT_EFM;
  const isPtp = type === EthernetTypeEnum.ETHERNET_PRODUCT_PTP;

  const filledType = !!type;
  const filledBearer = !!site_b_bearer;
  const filledSpeed = !!speed;
  const filledAddressA = !!purchaseArgs?.site_a_site_address_postcode;
  const filledAddressB = !!purchaseArgs?.site_b_site_address_postcode;

  const showBearer = filledType;
  const showNotGeaSpeed = !isGea && showBearer && filledBearer;
  const showManagedEfmAddressB =
    (isManaged || isEfm) && showNotGeaSpeed && filledSpeed;
  const showGeaAddressB = isGea && showBearer && filledBearer;
  const showPtpAddressA = isPtp && showNotGeaSpeed && filledSpeed;
  const showPtpAddressB = isPtp && showPtpAddressA && filledAddressA;
  const showFailOver =
    (showManagedEfmAddressB && filledAddressB) ||
    (showGeaAddressB && filledAddressB) ||
    (showPtpAddressB && filledAddressB);
  const showManagedEfmRouter = (isManaged || isEfm) && showFailOver;
  const showGeaRouter = isGea && showFailOver;

  let cardIndex = 0;

  return (
    <div>
      <IndexedCardInfo index={++cardIndex} title={"Select Product"}>
        <EthernetConfigurationType
          configIndex={configIndex}
          selectedType={type}
        />
      </IndexedCardInfo>
      {showBearer && (
        <IndexedCardInfo index={++cardIndex} title={"Select bearer"}>
          <ProductSelect
            dataCy={"ethernet-bearer"}
            value={site_b_bearer}
            handleChange={handleOption("site_b_bearer")}
          >
            {bearerOptions.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </ProductSelect>
        </IndexedCardInfo>
      )}
      {showNotGeaSpeed && (
        <IndexedCardInfo index={++cardIndex} title={"Select speed"}>
          <ProductSelect
            dataCy={"ethernet-speed"}
            value={speed}
            handleChange={handleOption("speed")}
          >
            {speedOptions.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </ProductSelect>
        </IndexedCardInfo>
      )}
      {showManagedEfmAddressB && (
        <IndexedCardInfo
          index={++cardIndex}
          title={"Enter postcode for Site B"}
        >
          <EthernetConfigurationAddress
            prefix="site_b_"
            configIndex={configIndex}
            configuration={configuration}
          />
        </IndexedCardInfo>
      )}
      {showGeaAddressB && (
        <IndexedCardInfo
          index={++cardIndex}
          title={"Enter postcode for Site B"}
        >
          <EthernetConfigurationAddress
            prefix="site_b_"
            configIndex={configIndex}
            configuration={configuration}
            withCli={true}
            cli={site_b_cli || ""}
            setCli={newCli =>
              dispatch(
                updateEthernetConfiguration(configIndex, "site_b_cli", newCli)
              )
            }
          />
        </IndexedCardInfo>
      )}
      {showPtpAddressA && (
        <IndexedCardInfo
          index={++cardIndex}
          title={"Enter postcode for Site A"}
        >
          <EthernetConfigurationAddress
            prefix="site_a_"
            configIndex={configIndex}
            configuration={configuration}
          />
        </IndexedCardInfo>
      )}
      {showPtpAddressB && (
        <IndexedCardInfo
          index={++cardIndex}
          title={"Enter postcode for Site B"}
        >
          <EthernetConfigurationAddress
            prefix="site_b_"
            configIndex={configIndex}
            configuration={configuration}
          />
        </IndexedCardInfo>
      )}
      {showFailOver && (
        <IndexedCardInfo index={++cardIndex} title={"Select Failover Product"}>
          <EthernetConfigurationFailOver
            configIndex={configIndex}
            configuration={configuration}
          />
        </IndexedCardInfo>
      )}
      {showManagedEfmRouter && (
        <IndexedCardInfo index={++cardIndex} title={"Select Router"}>
          <EthernetConfigurationRouterSelect
            configIndex={configIndex}
            configuration={configuration}
          />
        </IndexedCardInfo>
      )}
      {showGeaRouter && (
        <IndexedCardInfo index={++cardIndex} title={"Select Router"}>
          <EthernetConfigurationRouterSelect
            configIndex={configIndex}
            configuration={configuration}
          />
        </IndexedCardInfo>
      )}
    </div>
  );
};
