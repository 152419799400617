import { CarrierFilterEnum, DataFilterEnum } from "./enums";

export const tableHeaders = (isReseller: boolean) => [
  {
    label: "Carrier",
    sortIndex: "network",
    width: 250
  },
  {
    label: "Product",
    sortIndex: "name",
    minWidth: 250
  },
  // tp40209
  ...(isReseller
    ? [
        {
          label: "Price Book",
          sortIndex: "priceBook",
          width: 300
        }
      ]
    : []),
  {
    label: "Cost",
    sortIndex: "recurringPrice",
    width: 250
  }
];

export const carrierFilterOptions = [
  {
    id: CarrierFilterEnum.ALL,
    label: "All Products"
  },
  {
    id: CarrierFilterEnum.VODAFONE,
    label: "Vodafone"
  },
  {
    id: CarrierFilterEnum.O2,
    label: "O2"
  }
];

export const dataFilterOptions = [
  {
    id: DataFilterEnum.ALL,
    label: "All Products"
  },
  {
    id: DataFilterEnum.VOICE,
    label: "Mobile Voice"
  },
  {
    id: DataFilterEnum.DATA,
    label: "Mobile Broadband"
  }
];
