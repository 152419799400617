import _ from "lodash";
import { createSelector } from "reselect";
import { isEthernetPurchaseArgValid } from "./validation";

/**
 * Checks all ethernet configurations have been completed with a selected address and quote
 * @param state
 * @returns {boolean}
 */
export function validateEthernetConfigurations(state) {
  const { configurations } = state.ethernetProducts;
  let validated = true;
  configurations.forEach(c => {
    // TODO: This needs to evaluate final form as well.
    if (_.isNil(c.selectedQuoteId)) validated = false;
  });
  return validated;
}

export function ethernetPurchaseArgsValidationErrors(state, configIndex) {
  let errors = [];
  const config = state.ethernetProducts.configurations[configIndex];
  const purchaseArgs = config && config.purchaseArgs;

  if (purchaseArgs) {
    errors = Object.keys(purchaseArgs).filter(
      key => !isEthernetPurchaseArgValid(key, purchaseArgs[key])
    );
  }

  if (purchaseArgs.with_voice === "1" && !purchaseArgs.voice_service) {
    errors.push("voice_service");
  }

  return errors;
}

export const getBackupCapableRouterID = (ethernetState, configIndex) => {
  const routers = _.get(
    ethernetState.configurations[configIndex].availableRouters,
    "response.results.all",
    []
  ).filter(router => {
    return router.suitable_for_adsl_fttc_backup === "1";
  });

  if (routers.length) {
    return routers[0].id;
  } else {
    return false;
  }
};

/**
 * Returns true if any ethernet products are in the process of being ordered.
 * Used for disabling navigation in final step.
 * @param state
 * @returns {boolean}
 */
export const ethernetOrderProductCallsFetching = createSelector(
  [state => state.ethernetProducts.configurations],
  configurations =>
    configurations.reduce(
      (fetching, config) => fetching || config.purchaseIsRequesting,
      false
    )
);

/**
 * Get config price, including any amortisation and optionally discounts
 * Optionally for a single pricing result
 *
 * For step 2 quote select, config form and toolbar price display
 *
 * @param config
 * @param pricingResult
 * @param withDiscounts
 */
// pedantry alert!  This is not really a selector as it doesn't take redux state as an argument
// but we will leave this function in this file in order to preserve git history.
export function getConfigRecurringPrice(
  config,
  pricingResult = false,
  withDiscounts = true,
  roundUp = false
) {
  if (!pricingResult) {
    pricingResult = config.pricingResults.filter(
      r => r.id === config.selectedQuoteId
    )[0];
  }

  //making this one round so it more closely matched DC maths
  const discountPence = withDiscounts
    ? Math.round(
        parseFloat(pricingResult.recurring_price) * config.purchaseArgs.discount
      )
    : 0;

  const fullPricePence = pricingResult.recurring_price * 100;

  if (config.purchaseArgs.amortised) {
    const amortisationPence = Math.ceil(
      parseFloat(pricingResult.one_off_price * 100) /
        parseFloat(pricingResult.contract_length_in_months * 100)
    );

    return roundUp
      ? Math.ceil((fullPricePence - discountPence + amortisationPence) / 100)
      : (fullPricePence - discountPence + amortisationPence) / 100;
  } else {
    return roundUp
      ? Math.ceil((fullPricePence - discountPence) / 100)
      : (fullPricePence - discountPence) / 100;
  }
}
