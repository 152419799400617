import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import {
  selectConfig,
  requestListDocuments
} from "../../../../store/universalProducts/actionCreators";
import {
  getConfigDocuments,
  getIsUniversalProductConfigValid,
  makeGetConfigProductName
} from "../../../../store/universalProducts/selectors";
import ConfigStatus from "../../../../components/Shared/ConfigStatus";
import { getDoesUniversalProductHaveDocuments } from "../../../../store/universalProducts/selectors";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

class ConfigTableRow extends React.Component {
  componentDidMount() {
    const {
      config: { productId },
      configDocuments,
      requestListDocuments
    } = this.props;
    if (
      !_.get(configDocuments, "fetching") &&
      !_.get(configDocuments, "response.documents")
    ) {
      requestListDocuments(productId);
    }
  }

  render() {
    const {
      configIndex,
      config,
      productName,
      selectConfig,
      hideAction
    } = this.props;
    return (
      <TableRow>
        <TableCell>{productName}</TableCell>
        <TableCell>{config.oneOffPrice}</TableCell>
        <TableCell>{config.recurringPrice}</TableCell>
        <TableCell>
          <ConfigStatus
            isValid={
              getIsUniversalProductConfigValid(config) &&
              getDoesUniversalProductHaveDocuments(config)
            }
            onClick={() => selectConfig(configIndex)}
          />
        </TableCell>
        {!hideAction && (
          <TableCell>
            <IconButton
              onClick={() => selectConfig(configIndex)}
              data-cy="editUniversalProduct"
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  }
}

ConfigTableRow.propTypes = {
  configIndex: PropTypes.number.isRequired,
  hideAction: PropTypes.bool
};

const makeMapStateToProps = () => {
  const getConfigProductName = makeGetConfigProductName();
  return (state, ownProps) => ({
    config: state.universalProducts.configs[ownProps.configIndex],
    productName: getConfigProductName(state, ownProps),
    configDocuments: getConfigDocuments(state, ownProps.configIndex)
  });
};

export default connect(makeMapStateToProps, {
  selectConfig,
  requestListDocuments
})(ConfigTableRow);
