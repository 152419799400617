import React from "react";
import _ from "lodash";
import { StatusChip } from "@akj-dev/design-system";

export default ({ configuration }) => {
  switch (configuration.pricingRequestStatus) {
    case "requesting":
      return (
        <StatusChip
          type="loading"
          title="Requesting quotes..."
          message="Please wait."
        />
      );

    case "pending":
      return (
        <StatusChip
          type="loading"
          title="Pending quotes..."
          message="Please wait."
        />
      );

    case "processing":
      return (
        <StatusChip
          type="loading"
          title="Processing quotes..."
          message="Please wait."
        />
      );

    case "partial_results":
      const formattedStatus = _.get(
        configuration,
        "pricingRequestProvidersStatus.formatted.status"
      );
      return (
        <StatusChip
          type="error"
          title="Warning"
          message={formattedStatus || "Some suppliers did not return a price."}
        />
      );

    case "complete":
      if (configuration.pricingResults.length === 0) {
        return (
          <StatusChip type="error" title="Sorry, no results were returned." />
        );
      } else {
        return <StatusChip type="success" title="Pricing request complete" />;
      }

    case "error":
      return (
        <StatusChip type="error" title="Sorry, no results were returned." />
      );

    case "stopped": // Added in reducer
      return <StatusChip type="info" title="Request stopped." />;

    default:
      return (
        <StatusChip
          type="error"
          title="Unknown Ethernet pricingRequestStatus'"
        />
      );
  }
};
