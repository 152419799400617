import React from "react";
import Icon from "@material-ui/icons/SettingsApplications";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";

const BroadbandConfiguration = props => {
  return (
    <Section
      title="Broadband Configuration"
      avatar={<Icon />}
      defaultExpanded={false}
    >
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.cust_reference"
        margin
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.stabilityOption"
        margin
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.fast_track_requested"
        margin
      />
      {props.hasLine && !props.isSOGEA && (
        <WlrBBDynamicField
          productType="broadband"
          propertyName="bb.sim_provide_ref"
          margin
        />
      )}
    </Section>
  );
};

BroadbandConfiguration.propTypes = {};

export default BroadbandConfiguration;
