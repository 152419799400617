import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import ProductTitle from "../../../../components/Shared/mobile/ProductTitle";
import BoltOnChip from "./BoltOnChip";
import {
  getNonGenericInstanceName,
  getResignPrice
} from "../../../../store/mobile/selectors/resigns";
import EvoServicesDrillDown from "./EvoServicesDrillDown";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const NoChangesRow = ({
  isChecked,
  toggleRow,
  productInstance,
  productInstance: { pin, network, evo_services = [], end_date_ymd, price = 0 },
  actions
}) => {
  const boltOns = evo_services.filter(s => s.ServiceType === "bolt_on");
  return (
    <TableRow selected={isChecked}>
      <TableCell>
        <Checkbox onChange={toggleRow} checked={isChecked} />
      </TableCell>
      <TableCell>{pin}</TableCell>
      <TableCell colSpan={2}>
        <EvoServicesDrillDown evo_services={evo_services}>
          <ProductTitle network={network.name}>
            {getNonGenericInstanceName(productInstance)}
          </ProductTitle>
        </EvoServicesDrillDown>
      </TableCell>
      <TableCell>
        {boltOns.length
          ? boltOns.map((boltOn, i) => (
              <BoltOnChip
                title={boltOn.Description}
                price={boltOn.RateBeforeDiscount}
                key={i}
              />
            ))
          : "None"}
      </TableCell>
      <TableCell>{getResignPrice({ evo_services }).toFixed(2)}</TableCell>
      <TableCell className="u-nowrap">{end_date_ymd}</TableCell>
      <TableCell align="right" padding="none">
        {actions}
      </TableCell>
    </TableRow>
  );
};

NoChangesRow.propTypes = {};

export default NoChangesRow;
