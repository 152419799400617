import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import DateIcon from "@material-ui/icons/DateRange";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  setResignStartDate,
  setAccountBoltOnStartDate
} from "../../../store/mobile/actionCreators";
import startOfMonth from "date-fns/startOfMonth";
import { getSelectedBoltOnCount } from "../../../store/mobile/selectors/boltOns";
import { DC_DATE_FORMAT, todaysDateString } from "../../../helpers/date";

class ResignStartDate extends React.Component {
  render() {
    const {
      setResignStartDate,
      setAccountBoltOnStartDate,
      startDate,
      boltOnCount
    } = this.props;

    return (
      <Outer>
        <Icon />
        <KeyboardDatePicker
          label="Resign Start Date"
          format={DC_DATE_FORMAT}
          value={startDate || todaysDateString}
          onChange={(e, date) => {
            setResignStartDate(date);
            if (boltOnCount > 0) setAccountBoltOnStartDate(date);
          }}
          minDate={startOfMonth(new Date())}
        />
      </Outer>
    );
  }
}

const Outer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 20px;
`;

const Icon = styled(DateIcon)`
  margin-right: 10px;
`;

const mapStateToProps = (state, ownProps) => ({
  startDate: state.mobile.resignStartDate,
  boltOnCount:
    getSelectedBoltOnCount(state, 1) + getSelectedBoltOnCount(state, 2)
});

export default connect(mapStateToProps, {
  setResignStartDate,
  setAccountBoltOnStartDate
})(ResignStartDate);
