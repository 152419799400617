import React from "react";
import { Grid, Theme, makeStyles } from "@material-ui/core";
import { FindProducts } from "../../../../containers/StepChoose/wlrBroadband/FindProducts";
import { AddressPicker } from "../../../shared/AddressPicker";
import { WlrLocationTypeEnum } from "../enums";
import { ReduxWlrLocation } from "../types";
import { WlrLocationCliField } from "./WlrLocationCliField";
import { IndexedTitle } from "../../../shared/indexed-card-info/IndexedTitle";

export interface WlrLocationFindLocationProps {
  location: ReduxWlrLocation;
  locationIndex: number;
  handleLocationCli: (index: number, newCli: string) => void;
  saveAddress: (address: any) => void;
}

export const WlrLocationFindLocation = ({
  location,
  locationIndex,
  handleLocationCli,
  saveAddress
}: WlrLocationFindLocationProps) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
      spacing={2}
      alignItems="flex-start"
    >
      <Grid item md={12}>
        <IndexedTitle
          index={"B"}
          title={"Enter location"}
          withMargin={true}
          negative={true}
        />
      </Grid>
      <Grid item>
        <AddressPicker saveAddress={saveAddress} />
      </Grid>
      {[
        WlrLocationTypeEnum.TRANSFER,
        WlrLocationTypeEnum.BROADBAND_ONLY,
        WlrLocationTypeEnum.NEW_FTTP,
        WlrLocationTypeEnum.NEW_SOGEA
      ].some(type => type === location.type) && (
        <Grid item>
          <WlrLocationCliField
            locationIndex={locationIndex}
            isOptional={[
              WlrLocationTypeEnum.NEW_FTTP,
              WlrLocationTypeEnum.NEW_SOGEA
            ].some(type => type === location.type)}
            handleLocationCli={handleLocationCli}
          />
        </Grid>
      )}
      <Grid item>
        <FindProducts locationIndex={locationIndex} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(1)
  }
}));
