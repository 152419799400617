import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import PublishIcon from "@material-ui/icons/Publish";

export default class BulkSelectPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isOpen: false
    };
  }

  handleClick = event => {
    this.setState({
      isOpen: true,
      anchorEl: event.currentTarget
    });
  };

  handleRequestClose = () => {
    this.setState({ isOpen: false });
  };

  bulkSelectByCLI = event => {
    this.props.bulkSelectByCLI(event.target.value);
    this.handleRequestClose();
  };

  render() {
    const { isOpen, anchorEl } = this.state;
    const id = isOpen ? "bulkSelectPopover" : undefined;
    return (
      <>
        <Button
          data-cy="bulkSelectButton"
          color="primary"
          variant="text"
          size="large"
          onClick={this.handleClick}
          startIcon={<PublishIcon />}
        >
          {"Bulk select"}
        </Button>
        <Popover
          id={id}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={this.handleRequestClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Box m={1}>
            <Typography variant="h3">Bulk Select</Typography>
            <Typography color="textSecondary" paragraph id="bulkSelectLabel">
              Enter a list of CLIs, separated by commas:
            </Typography>
            <TextField
              fullWidth
              data-cy="bulkSelectTextField"
              multiline
              name="clis"
              onBlur={this.bulkSelectByCLI}
              aria-labelledby="bulkSelectLabel"
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleRequestClose}
            >
              Select
            </Button>
          </Box>
        </Popover>
      </>
    );
  }
}
