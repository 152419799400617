import u from "updeep";
import * as actionType from "../../actionTypes";

export default (state, action) => {
  switch (action.type) {
    // Per location actions (step 1):

    case actionType.SET_LOCATION_TYPE:
      return u(
        {
          locations: {
            [action.index]: {
              type: action.locationType
            }
          },
          // Changing the location type would invalidate current product choices, so wipe them.
          configurations: u.reject(c => c.locationIndex === action.index)
        },
        state
      );

    case actionType.SET_LOCATION_ADDRESS:
      return u(
        {
          locations: {
            [action.index]: {
              address: action.address,
              // Changing address invalidates search results performed at any previous address
              lineAvailability: { response: () => ({}) },
              installationDetails: { response: () => ({}) },
              broadbandSearch: { response: () => ({}) }
            }
          },
          ...(!action.isResign && {
            // Also invalidates any configs
            configurations: u.reject(c => c.locationIndex === action.index)
          })
        },
        state
      );

    case actionType.SET_LOCATION_CLI:
      return u(
        {
          locations: {
            [action.index]: {
              cli: {
                // White space needs to be trimmed so RADIUS pw generated from this number is valid.
                // ....and maybe other DC things. FB156285
                value: action.cli.replace(/\s+/g, ""),
                validation: () => ({})
              }
            }
          },
          configurations: u.reject(c => c.locationIndex === action.index)
        },
        state
      );

    case actionType.REQUEST_VALIDATE_CLI:
      return u(
        {
          locations: {
            [action.index]: {
              cli: {
                validating: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_VALIDATE_CLI:
      return u(
        {
          locations: {
            [action.index]: {
              cli: {
                validating: false,
                validation: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.REQUEST_LINE_AVAILABILITY:
      return u(
        {
          locations: {
            [action.index]: {
              lineAvailability: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_LINE_AVAILABILITY:
      return u(
        {
          locations: {
            [action.index]: {
              lineAvailability: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.REQUEST_INSTALLATION_DETAILS:
      return u(
        {
          locations: {
            [action.index]: {
              installationDetails: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_INSTALLATION_DETAILS:
      return u(
        {
          locations: {
            [action.index]: {
              installationDetails: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.REQUEST_WLTO_DETAILS:
      return u(
        {
          locations: {
            [action.index]: {
              wltoDetails: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_WLTO_DETAILS:
      return u(
        {
          locations: {
            [action.index]: {
              wltoDetails: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.REQUEST_BROADBAND_SEARCH:
      return u(
        {
          locations: {
            [action.index]: {
              broadbandSearch: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_BROADBAND_SEARCH:
      return u(
        {
          locations: {
            [action.index]: {
              broadbandSearch: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.REQUEST_FIND_NUMBERS:
      return u(
        {
          locations: {
            [action.locationIndex]: {
              numbers: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_FIND_NUMBERS:
      return u(
        {
          locations: {
            [action.locationIndex]: {
              numbers: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.TOGGLE_SOGEA_TERMS_CONFIRMATION:
      return u(
        {
          locations: {
            [action.index]: {
              sogeaTermsAccepted: !state.locations[action.index]
                .sogeaTermsAccepted
            }
          }
        },
        state
      );

    default:
      return state;
  }
};
