import React from "react";
import Section from "../../../../../components/Shared/Section";
import BulkDynamicField from "../BulkDynamicField";
import LanguageIcon from "@material-ui/icons/Language";

const Services = () => {
  return (
    <Section title="Services" avatar={<LanguageIcon />} defaultExpanded={false}>
      <div className="flex-row">
        <div className="col">
          <BulkDynamicField propertyName="service_5g" />
        </div>
        <div className="col">
          <BulkDynamicField propertyName="mms_service" />
        </div>
      </div>
    </Section>
  );
};

Services.propTypes = {};

export default Services;
