import locations from "./actions/locations";
import configurations from "./actions/configurations";
import setProductProperty from "./actions/setProductProperty";
import topLevel from "./actions/topLevel";
import resigns from "./actions/resigns";
import newLocation from "./newLocation";

// WLR+Broadband configurations belong to locations. Each location can have multiple configurations
// Configurations store data used to make the final OrderProduct/Create calls to DC

const initialState = {
  locations: [{ ...newLocation }],

  configurations: [],

  // Special rates which have been earmarked for removal by the user. Used when resigning wlr and broadband products.
  specialRatesToRemove: [],

  // Optional resign start date
  resignStartDate: false,

  // Resignable line products on account
  lineProductInstances: {
    fetching: false,
    response: {}
  },

  // Resignable broadband products on account
  broadbandProductInstances: {
    fetching: false,
    response: {}
  },

  // The product we use to resign products without changes
  resignProductSearch: {
    fetching: false,
    response: {}
  },

  // The product we use to resign same product with change
  lineResignProductSearch: {
    fetching: false,
    response: {}
  },

  // The product we use to resign same product with change
  broadbandResignProductSearch: {
    fetching: false,
    response: {}
  },

  lineSearch: {
    fetching: false,
    response: false
  } // API: v1/Product/LineSearch. All available WLR products independent of location.
};

// The reducer is huge, so is sliced into a number of smaller files.
// TODO: There's some smart router composition stuff we could do here. See:
// https://egghead.io/lessons/javascript-redux-implementing-combinereducers-from-scratch
// (standard combineReducers wouldn't work as there are some actions that work across both locations and configs.)
// For now though, this runs them slices sequentially. Unmodified state is passed down, hence no mutation issue.
export default (state = initialState, action) => {
  return [
    topLevel,
    locations,
    configurations,
    resigns,
    setProductProperty
  ].reduce((nextState, reducer) => reducer(nextState, action), state);
};
