import {
  isValidEmail,
  isValidMobileNumber,
  isValidText,
  isValidPhoneNumber,
  isValidSortCode,
  isValidUKBankAccount,
  isValidUKCompanyRegNumber,
  isValidVATNumber
} from "../../helpers/validation";

import {
  getIsVfDirectConsumerAccount,
  shouldCollectCompanyAddress2,
  shouldCollectCompanyAddress3
} from "./selectors";

/**
 * Validations for new account fields
 *
 * Functions are called individually in the reducer when a single property is validated
 * then all in sequence when everything's validated (on progress to next step).
 * ....hence all functions should be pure=.
 *
 * They receive:
 *  - new field value
 *  - existing state (to validate against when there's interdependency)
 *
 * Field components in `src/js/containers/StepAccount/sections/*` also refer to these to show "required *"
 * When the requirement is dependant on other fields, it can be a function, receiving store state.
 *
 * So format:
 *
 * {
 *   field_name: {
 *     required: bool | fn(state),
 *     message: string,
 *     validator: undefined | fn(value)
 *   }
 * }
 *
 */

const notBlank = {
  required: true,
  message: "Must not be blank"
};

const pleaseSelect = {
  required: true,
  message: "Please select an option"
};

const billAddress = {
  required: state => !state.newAccountFields.billing_address_same,
  message: "Must not be blank"
};

const companyAddress2NotBlank = {
  required: account => shouldCollectCompanyAddress2({ account }),
  message: notBlank.message
};

const companyAddress3NotBlank = {
  required: account => shouldCollectCompanyAddress3({ account }),
  message: notBlank.message
};

export const newAccountValidations = {
  // AccountDetails.js
  name: notBlank,
  business_entity_type: pleaseSelect,
  registered_number: {
    required: state =>
      state.newAccountFields.business_entity_type === "Limited Company (LTD)",
    message: "Must be a valid UK company registration number",
    validator: isValidUKCompanyRegNumber
  },
  vat_number: {
    required: false,
    message: "Must be a valid VAT number. Format 000000000",
    validator: isValidVATNumber
  },

  // CompanyAddress.tsx
  company_building: notBlank,
  company_street: notBlank,
  company_post_town: notBlank,
  company_region: notBlank,
  company_postcode: notBlank,
  company_country: notBlank,
  company_months_at_address: {
    required: account => getIsVfDirectConsumerAccount({ account }),
    message: notBlank.message
  },
  residential_status: {
    required: account => getIsVfDirectConsumerAccount({ account }),
    message: pleaseSelect.message
  },
  // CompanyAddress.tsx first previous address
  company_building2: companyAddress2NotBlank,
  company_street2: companyAddress2NotBlank,
  company_post_town2: companyAddress2NotBlank,
  company_region2: companyAddress2NotBlank,
  company_postcode2: companyAddress2NotBlank,
  company_country2: companyAddress2NotBlank,
  company_months_at_address2: companyAddress2NotBlank,

  // CompanyAddress.tsx second previous address
  company_building3: companyAddress3NotBlank,
  company_street3: companyAddress3NotBlank,
  company_post_town3: companyAddress3NotBlank,
  company_region3: companyAddress3NotBlank,
  company_postcode3: companyAddress3NotBlank,
  company_country3: companyAddress3NotBlank,
  company_months_at_address3: companyAddress3NotBlank,

  // BillingAddress.js
  billing_building: billAddress,
  billing_street: billAddress,
  billing_post_town: billAddress,
  billing_region: billAddress,
  billing_postcode: billAddress,
  billing_country: billAddress,

  // ContactDetails.js
  salutation: pleaseSelect,
  first_name: {
    required: true,
    message: "Must not be blank or contain special characters.",
    validator: isValidText
  },
  last_name: {
    required: true,
    message: "Must not be blank or contain special characters.",
    validator: isValidText
  },
  email: {
    required: true,
    message: "Must be a valid email address",
    // NOTE: The below logic was in place before but this stops a valid order contact being made.
    // required: state =>
    //   state.newAccountFields.business_entity_type ===
    //     "Sole Trader / Owner Manager" &&
    //   !state.newAccountFields.billing_address_same,
    // message: "Valid email address required when Sole Trader / Owner Manager",
    validator: isValidEmail
  },
  phone: {
    required: true,
    message: "Must be a valid phone number",
    validator: isValidPhoneNumber
  },
  mobile: {
    required: account => getIsVfDirectConsumerAccount({ account }),
    message: "Must be an 11 digit mobile number",
    validator: isValidMobileNumber
  },
  birthdate: {
    required: account => getIsVfDirectConsumerAccount({ account }),
    message: notBlank.message
  },
  // Payment.js
  // Note DC validates using: http://www.afd.co.uk/solutions/check-bank-credit-card-details/
  // So client side isn't perfect here.
  bank_name: notBlank,
  bank_account_name: notBlank,
  bank_account_number: {
    required: true,
    message: "Must be an 8 digit account number",
    validator: isValidUKBankAccount
  },
  bank_account_sortcode: {
    required: true,
    message: "Must be a valid sort code. Format 000000 or 00-00-00",
    validator: isValidSortCode
  },

  // BillOptions.js
  ebilling_email: {
    required: true,
    message: "Must be a valid email address",
    validator: isValidEmail
  }
};
