import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ErrorIcon from "@material-ui/icons/Error";
import { ExpandingCard } from "@akj-dev/design-system";
import Spinner from "../../../components/Shared/Spinner";
import { StatusChip } from "@akj-dev/design-system";
import {
  requestDaisyFreshSearch,
  requestHardwareCreditProduct,
  setDaisyFreshEtf,
  setDaisyFreshHardwareCredits,
  setDaisyFreshTerminationFees
} from "../../../store/mobile/actionCreators";
import {
  getDaisyFreshAllowedAmount,
  getDaisyFreshPerLineAmount,
  productDataIsFetching
} from "../../../store/mobile/selectors/daisyFresh";
import { TextField, Grid, Typography } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";

const ChargeStatement = styled.div`
  font-size: 16px;
  color: ${p => p.theme.palette.primary.main};
  margin: 0 10px;
`;

class DaisyFresh extends Component {
  static defaultProps = {};
  static propTypes = {};

  state = {};

  render() {
    const {
      perLineAmount,
      daisyFreshSearch,
      hardwareCreditProductSearch,
      hardwareCredits,
      terminationFees,
      etf,
      limitExceeded,
      allowedAmount,
      contractLengthInMonthsAllProducts,
      productDataIsFetching,
      requestDaisyFreshSearch,
      requestHardwareCreditProduct,
      setDaisyFreshHardwareCredits,
      setDaisyFreshTerminationFees,
      setDaisyFreshEtf
    } = this.props;

    if (
      !daisyFreshSearch.fetching &&
      daisyFreshSearch.response.status === "error"
    )
      return (
        <StatusChip
          type="error"
          title="Error finding Daisy Fresh Product"
          retry={requestDaisyFreshSearch}
        />
      );

    if (
      !hardwareCreditProductSearch.fetching &&
      hardwareCreditProductSearch.response.status === "error"
    )
      return (
        <StatusChip
          type="error"
          title="Error finding Hardware Credit Product"
          retry={requestHardwareCreditProduct}
        />
      );

    const anythingFetching =
      daisyFreshSearch.fetching ||
      hardwareCreditProductSearch.fetching ||
      productDataIsFetching;

    return (
      <ExpandingCard
        title={"Daisy Fresh"}
        subtitle={"£" + perLineAmount}
        avatar={
          anythingFetching ? (
            <Spinner />
          ) : limitExceeded ? (
            <ErrorIcon color="error" />
          ) : (
            <AccountBalanceIcon data-cy="daisyFreshAPIOK" />
          )
        }
        data-cy="DaisyFresh"
      >
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <TextField
              type="number"
              label="Hardware Credits"
              value={hardwareCredits}
              onChange={event =>
                setDaisyFreshHardwareCredits(event.target.value)
              }
              fullWidth={true}
              disabled={anythingFetching}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              type="number"
              label="Termination Fees"
              value={terminationFees}
              onChange={event =>
                setDaisyFreshTerminationFees(event.target.value)
              }
              fullWidth={true}
              disabled={anythingFetching}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              type="number"
              label="Remaining Contract Charges"
              value={etf}
              onChange={event => setDaisyFreshEtf(event.target.value)}
              fullWidth={true}
              disabled={anythingFetching}
            />
          </Grid>
          <Grid item sm={3}>
            <Typography variant="h3" style={{ marginTop: "34px" }}>
              {" "}
              = <b>
                £{(hardwareCredits + terminationFees + etf).toFixed(2)}
              </b>{" "}
              total
            </Typography>
            {limitExceeded && (
              <Grid
                item
                sm={12}
                style={{
                  marginTop: "30px",
                  color: this.props.theme.palette.secondary.main,
                  fontSize: "16px"
                }}
              >
                Max: <b>£{allowedAmount}</b> (excluding remaining contractual
                charges)
              </Grid>
            )}
          </Grid>
          {perLineAmount > 0 && (
            <Grid item sm={12}>
              <ChargeStatement>
                <b>£{perLineAmount}</b> will be charged monthly, per mobile
                number for the <b>{contractLengthInMonthsAllProducts} month</b>{" "}
                term of the contract.
              </ChargeStatement>
            </Grid>
          )}
        </Grid>
      </ExpandingCard>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contractLengthInMonthsAllProducts:
      state.mobile.contractLengthInMonthsAllProducts,
    daisyFreshSearch: state.mobile.daisyFreshSearch,
    hardwareCreditProductSearch: state.mobile.hardwareCreditProductSearch,
    productDataIsFetching: productDataIsFetching(state),
    hardwareCredits: state.mobile.daisyFreshAmounts.hardwareCredits,
    terminationFees: state.mobile.daisyFreshAmounts.terminationFees,
    etf: state.mobile.daisyFreshAmounts.etf,
    limitExceeded: state.mobile.daisyFreshAmounts.limitExceeded,
    perLineAmount: getDaisyFreshPerLineAmount(state).toFixed(2),
    allowedAmount: getDaisyFreshAllowedAmount(state.mobile).toFixed(2)
  };
};

export default connect(mapStateToProps, {
  requestDaisyFreshSearch,
  requestHardwareCreditProduct,
  setDaisyFreshHardwareCredits,
  setDaisyFreshTerminationFees,
  setDaisyFreshEtf
})(withTheme(DaisyFresh));
