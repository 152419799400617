import React from "react";
import { connect } from "react-redux";
import { IndexedCardInfo } from "../../screens/shared/indexed-card-info";
import BillingAddress from "./BillingAddress";

class BillingAddressSection extends React.Component {
  static propTypes = {};
  state = {};

  render() {
    const { cardIndex } = this.props;
    return (
      <IndexedCardInfo index={cardIndex} title="Billing Address">
        <BillingAddress />
      </IndexedCardInfo>
    );
  }
}

const mapStateToProps = state => {
  return {
    fetching: state.account.billingAddress.fetching
  };
};

export default connect(mapStateToProps, {})(BillingAddressSection);
