import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  arrayUpdateConfigProperty,
  validateConfigProperty
} from "../../../../store/mobile/actionCreators";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import { TargetConfigsContext } from "../../context/TargetConfigsContext";

class ArrayDynamicField extends Component {
  // TODO: This currently doesn't show non-uniform value warnings like the standard bulk dynamic field.
  // Either make it do so or conditionally switch between the two... or something.
  // Make this a controlled input somehow. Just array split / join to get data in and out makes new line unworkable.
  // Perhaps the whole UI for this needs rethinking.

  static propTypes = {
    floatingLabelText: PropTypes.string,
    propertyName: PropTypes.string.isRequired,
    isRootProperty: PropTypes.bool // Currently only required for user-defined fields which sits at product level: TP20164
  };

  static contextType = TargetConfigsContext;

  render() {
    const {
      floatingLabelText,
      propertyName, // Props
      configs,
      productData, // Store
      arrayUpdateConfigProperty,
      validateConfigProperty, // Dispatch,
      isRootProperty,
      helperText
    } = this.props;
    const { targetConfigs } = this.context;

    const dynamicProperty = _.get(
      productData[configs[targetConfigs[0]].productId].response,
      [
        ...(!isRootProperty ? ["mobile"] : []),
        "dynamic_properties",
        propertyName
      ]
    );

    if (!dynamicProperty) return false;

    const isMultiple = targetConfigs.length > 1;
    const isRequired = dynamicProperty.is_required === 1;

    let value = "";
    const currentValue = configs[targetConfigs[0]].properties[propertyName];
    if (!isMultiple && currentValue) {
      value = { value: currentValue };
    }

    let validation = false;

    targetConfigs.forEach(id => {
      if (!validation) validation = _.get(configs[id].validation, propertyName);
    });

    return (
      <TextField
        data-cy="arrayDynamicTextField"
        label={
          floatingLabelText +
          (isMultiple ? "(s)" : "") +
          (isRequired ? "*" : "")
        }
        multiline={isMultiple}
        fullWidth={true}
        onChange={e => {
          arrayUpdateConfigProperty(
            propertyName,
            e.target.value.split(/,|\n/),
            targetConfigs
          );
        }}
        onBlur={() => {
          validateConfigProperty(propertyName, targetConfigs);
        }}
        {...value}
        helperText={
          validation ||
          helperText ||
          (isMultiple
            ? "Enter multiple items separated by commas or on new lines."
            : "")
        }
        error={!!validation}
        margin="normal"
        name={dynamicProperty.name}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    configs: state.mobile.configs,
    productData: state.mobile.productData
  };
};

export default connect(mapStateToProps, {
  arrayUpdateConfigProperty,
  validateConfigProperty
})(ArrayDynamicField);
