import React from "react";
import { Box, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { CancelSaleButton } from "../../../containers/global/CancelSaleButton";
import { useSelector } from "react-redux";
import { getHostEnv } from "../../../store/order/selectors/getHostEnv";

export interface ActionsProps {
  continueDisabled?: boolean;
  backDisabled?: boolean;

  continueHidden?: boolean;
  backHidden?: boolean;

  onContinue?: () => void;
  onBack?: () => void;
}

export const Footer = ({
  continueDisabled,
  backDisabled,
  continueHidden,
  backHidden,
  onContinue,
  onBack
}: ActionsProps) => {
  const hostEnv = useSelector(getHostEnv);
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <div>
        {!backHidden && (
          <Button
            color="primary"
            variant="outlined"
            size="large"
            startIcon={<ArrowBackIcon />}
            disabled={backDisabled}
            onClick={onBack}
          >
            Previous
          </Button>
        )}
        {hostEnv !== "dc" && <CancelSaleButton />}
      </div>
      {!continueHidden && (
        <Button
          color="primary"
          variant="contained"
          size="large"
          endIcon={<ArrowForwardIcon />}
          disabled={continueDisabled}
          onClick={onContinue}
          data-cy={"StepNavFooterNext"}
        >
          Next
        </Button>
      )}
    </Box>
  );
};
