import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";
import { StatusChip } from "@akj-dev/design-system";
import Expanded from "./Expanded";
import {
  setFields,
  toggleExpand
} from "../../../../store/hardware/configurations/actions";
import {
  configurationTotalLeasePrice,
  configurationTotalOneOffPrice,
  getTotalOrderHardwareCredit,
  totalLeasedProducts,
  totalLeasePrice,
  totalOneOffPrice,
  totalQuantity
} from "../../../../store/hardware/configurations/selectors";
import Popover from "../../../../components/Popover";
import Section from "../../../../components/Shared/Section";
import ContractLength from "../../../../components/ContractLength";
import {
  getHardwareCount,
  getHasLeasedHardware
} from "../../../../store/hardware/selectors";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { getHostEnv } from "../../../../store/order/selectors/getHostEnv";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productFilter: ""
    };
  }

  render() {
    const {
      products, // Redux State
      configurations,
      availableHardwareCredit,
      isFetchingAvailableHardwareCredit,
      toggleExpand,
      setFields,
      hasConnections,
      contractLengthInMonthsAllProducts,
      hardwareCount,
      hasLeasedHardware,
      hostEnv
    } = this.props;

    const configurationsWithQuantity = _.filter(
      configurations,
      config => !!config.quantity
    );

    const totalHWCredit = getTotalOrderHardwareCredit(
      configurationsWithQuantity
    );
    const remainingHWCredit = availableHardwareCredit - totalHWCredit;

    let rows = [];

    _.forEach(configurationsWithQuantity, config => {
      const product = products.find(p => p.id == config.product.id); // eslint-disable-line eqeqeq
      const quantity = config.quantity;
      const canBeLeased =
        hasConnections &&
        (!!product.price.leaseRV || !!product.price.leaseNonRV);
      const canBeLeasedRV = hasConnections && !!product.price.leaseRV;
      const expanded = config.expanded;
      const is_leasing = !!config.rows[0].is_leasing;
      const isLeasedRV = !!config.rows[0].isLeasedRV;
      const credit_used = config.rows[0].credit_used || "";

      if (quantity > 0) {
        rows.push(
          <TableRow key={product.id}>
            <TableCell>
              {!expanded && quantity > 1 && (
                <IconButton onClick={() => toggleExpand(product.id)}>
                  <AddCircleIcon />
                </IconButton>
              )}
              {expanded && quantity > 1 && (
                <IconButton onClick={() => toggleExpand(product.id)}>
                  <RemoveCircleIcon />
                </IconButton>
              )}
            </TableCell>
            <TableCell>{product.name}</TableCell>
            <TableCell>{!expanded && <span>{quantity}</span>}</TableCell>
            <TableCell>
              &pound;
              {configurationTotalOneOffPrice(config, products, hasConnections)}
            </TableCell>
            {hostEnv !== "my_account" && [
              <TableCell key={0}>
                <Checkbox
                  data-cy="leasingCheckbox"
                  onChange={(e, checked) =>
                    setFields(product.id, {
                      is_leasing: checked,
                      ...(!checked && { isLeasedRV: false }),
                      credit_used: ""
                    })
                  }
                  checked={is_leasing}
                  disabled={!canBeLeased}
                />
              </TableCell>,
              <TableCell key={1}>
                &pound;{configurationTotalLeasePrice(config, products)}
              </TableCell>,
              <TableCell key={2}>
                <Checkbox
                  onChange={(e, checked) =>
                    setFields(product.id, {
                      isLeasedRV: checked,
                      ...(checked && { is_leasing: true })
                    })
                  }
                  checked={isLeasedRV}
                  disabled={!canBeLeasedRV}
                />
              </TableCell>
            ]}
            <TableCell>
              <TextField
                data-cy="hardwareCreditInput"
                id={"credit_used_" + product.id}
                name={"credit_used_" + product.id}
                value={credit_used}
                onChange={e =>
                  setFields(product.id, {
                    credit_used: e.target.value,
                    expanded: quantity > 1
                  })
                }
                style={{ width: "100px" }}
                disabled={is_leasing}
                type="number"
              />
            </TableCell>
            <TableCell className="actions" />
          </TableRow>
        );

        if (expanded && quantity > 1) {
          for (var i = 0; i < quantity; i++) {
            rows.push(
              <Expanded
                key={product.id + "_i_" + i}
                index={i}
                product={product}
                config={config}
                setFields={setFields}
                {...{ hasConnections, canBeLeased, canBeLeasedRV }}
              />
            );
          }
        }
      }
    });

    return (
      <>
        {contractLengthInMonthsAllProducts !== 24 &&
          hardwareCount > 0 &&
          hasLeasedHardware && <ContractLength />}
        <Section title="Customise Pricing" avatar={<MonetizationOnIcon />}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Product</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>One Off Price</TableCell>
                {hostEnv !== "my_account" && [
                  <TableCell key={0}>Lease</TableCell>,
                  <TableCell key={1}>Lease Price</TableCell>,
                  <TableCell key={2}>
                    Residual Value
                    <Popover>
                      To keep the hardware at the end of the contract - a
                      maximum of 30% of the original price will be chargeable
                      for RV. An admin fee only will apply to Non-RV.
                    </Popover>
                  </TableCell>
                ]}
                <TableCell>
                  Hardware Credit
                  <Popover position={"left"}>
                    The hardware fund can be used to purchase hardware against
                    your account. See below remaining value.
                  </Popover>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
            <TableFooter>
              <TableRow>
                <TableCell />
                <TableCell>Total</TableCell>
                <TableCell>
                  {totalQuantity(configurationsWithQuantity)}
                </TableCell>
                <TableCell>
                  &pound;
                  {totalOneOffPrice(
                    configurationsWithQuantity,
                    products,
                    hasConnections
                  )}
                </TableCell>
                {hostEnv !== "my_account" && [
                  <TableCell key={0}>
                    {totalLeasedProducts(configurationsWithQuantity)}
                  </TableCell>,
                  <TableCell key={1} colSpan={2}>
                    &pound;
                    {totalLeasePrice(configurationsWithQuantity, products)}
                  </TableCell>
                ]}
                <TableCell colSpan={2}>
                  <TotalUsed>
                    £
                    {getTotalOrderHardwareCredit(
                      configurationsWithQuantity
                    ).toFixed(2)}
                  </TotalUsed>
                  <TotalRemaining>
                    (£{remainingHWCredit.toFixed(2)} remaining)
                  </TotalRemaining>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Section>

        {isFetchingAvailableHardwareCredit && (
          <StatusChip
            type="loading"
            title="Please wait"
            message="Loading available hardware credit"
          />
        )}
      </>
    );
  }
}

const TotalUsed = styled.span`
  margin-right: 10px;
`;

const TotalRemaining = styled.span`
  color: ${p => p.theme.palette.info.main};
`;

const mapStateToProps = state => ({
  products: state.hardwareProducts.response.products,
  configurations: state.hardwareConfigurations,
  availableHardwareCredit: state.account.availableHardwareCredit,
  isFetchingAvailableHardwareCredit:
    state.account.isFetchingAvailableHardwareCredit,
  hasConnections: state.mobile.configs.length > 0,
  contractLengthInMonthsAllProducts:
    state.mobile.contractLengthInMonthsAllProducts,
  hardwareCount: getHardwareCount(state),
  hasLeasedHardware: getHasLeasedHardware(state),
  hostEnv: getHostEnv(state)
});

const mapDispatchProps = dispatch => {
  return {
    toggleExpand: productId => {
      dispatch(toggleExpand(productId));
    },

    setFields: (productId, fields, index) => {
      dispatch(setFields(productId, fields, index));
    }
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Products);
