import u from "updeep";
import * as actionTypes from "../actionTypes";
import { getDaisyFreshAllowedAmount } from "../selectors/daisyFresh";

export default (state, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_DAISY_FRESH_SEARCH:
      return u(
        {
          daisyFreshSearch: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_DAISY_FRESH_SEARCH:
      return u(
        {
          daisyFreshSearch: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    case actionTypes.SET_DAISY_FRESH_HARDWARE_CREDITS:
      return setDaisyFreshAmount("hardwareCredits", state, action);
    case actionTypes.SET_DAISY_FRESH_TERMINATION_FEES:
      return setDaisyFreshAmount("terminationFees", state, action);
    case actionTypes.SET_DAISY_FRESH_ETF:
      return setDaisyFreshAmount("etf", state, action);

    case actionTypes.REQUEST_HARDWARE_CREDIT_PRODUCT:
      return u(
        {
          hardwareCreditProductSearch: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_HARDWARE_CREDIT_PRODUCT:
      return u(
        {
          hardwareCreditProductSearch: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    default:
      return state;
  }
};

const setDaisyFreshAmount = (type, state, action) => {
  // Update the actual amount
  const update = u(
    {
      daisyFreshAmounts: {
        [type]: parseFloat(action.amount) || 0
      }
    },
    state
  );

  // ...then check the new values don't exceed the limits + set flag accordingly.
  const total =
    update.daisyFreshAmounts.hardwareCredits +
    update.daisyFreshAmounts.terminationFees;
  // Note: update.daisyFreshAmounts.etf (Remaining Contractual Charges) isn't included in the limiting total. FB

  return u(
    {
      daisyFreshAmounts: {
        limitExceeded: total > getDaisyFreshAllowedAmount(update)
      }
    },
    update
  );
};
