import React, { useCallback, useState } from "react";
import { Box, CircularProgress, makeStyles, Theme } from "@material-ui/core";
import { QuantityInfoType } from "./enum";
import { QuantityFieldProps } from "./types";
import { useQuantityInfoTypeHandler, useStockLevelHandler } from "./hooks";
import { QuantitySelectField } from "./QuantitySelectField";
import { QuantityInputField } from "./QuantityInputField";
import { QuantityInfo } from "./QuantityInfo";
import { QuantitySyncButton } from "./QuantitySyncButton";
import { PriceInfo } from "./PriceInfo";

export const QuantityField = (props: QuantityFieldProps) => {
  const {
    color,
    disabled = false,
    price,
    label,
    quantity,
    actualQuantity,
    maxQuantity,
    quantityThreshold,
    stockLevel,
    setQuantity,
    getStockLevel
  } = props;
  const classes = useStyles();
  const { fetching } = stockLevel ?? {};

  const [inputValue, setInputValue] = useState(quantity);
  const [showInput, setShowInput] = useState(false);

  const handleQuantitySync = useCallback(() => {
    setQuantity(inputValue);
    setShowInput(false);
    if (!stockLevel) {
      getStockLevel?.();
    }
  }, [inputValue, stockLevel, setQuantity, setShowInput, getStockLevel]);

  useStockLevelHandler({ stockLevel, quantity, getStockLevel });
  const { type, message } = useQuantityInfoTypeHandler({
    quantity,
    actualQuantity,
    maxQuantity,
    stockLevel,
    getStockLevel
  });

  const showPrice = !!color || !!price || !!label;
  const showInputField = showInput || (quantity ?? 0) >= quantityThreshold;
  const showLoader = !!fetching;
  const showInfo = !showLoader && !showInput;
  const showError =
    type === QuantityInfoType.QTY_ERROR ||
    type === QuantityInfoType.STOCK_ERROR;

  return (
    <Box className={classes.wrapper}>
      {showPrice && <PriceInfo color={color} price={price} label={label} />}
      <Box data-cy={"quantityInput"}>
        {showInputField ? (
          <QuantityInputField
            disabled={disabled}
            showError={showError}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleBlur={handleQuantitySync}
            showInput={showInput}
            setShowInput={setShowInput}
          />
        ) : (
          <QuantitySelectField
            disabled={disabled}
            showError={showError}
            quantity={quantity}
            quantityThreshold={quantityThreshold}
            maxQuantity={maxQuantity}
            setQuantity={setQuantity}
            setShowInput={setShowInput}
          />
        )}
      </Box>
      <Box className={classes.infoBox}>
        {showLoader && <CircularProgress />}
        {showInput && <QuantitySyncButton handleSync={handleQuantitySync} />}
        {showInfo && <QuantityInfo type={type}>{message}</QuantityInfo>}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content"
  },
  infoBox: {
    width: 100,
    padding: theme.spacing(0.5)
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`
  }
}));
