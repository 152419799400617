import React from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { IndexedCardInfo } from "../../../screens/shared/indexed-card-info";
import AccountSelectField from "../AccountSelectField";
import AccountTextField from "../AccountTextField";

const BillOptions = ({ cardIndex }) => {
  return (
    <IndexedCardInfo index={cardIndex} title="Bill Options">
      <form>
        <Grid container spacing={2}>
          <AccountSelectField label="Bill Type" name="ebilling_enabled">
            <MenuItem value="0">Paper</MenuItem>
            <MenuItem value="1">Online</MenuItem>
          </AccountSelectField>
          <AccountTextField label="Email Address" name="ebilling_email" />
          <AccountSelectField label="Large Print Bill?" name="large_print_bill">
            <MenuItem value="0">No</MenuItem>
            <MenuItem value="1">Yes</MenuItem>
          </AccountSelectField>
          <AccountSelectField
            label="Cost Centre CSV?"
            name="create_cost_centre_csv"
          >
            <MenuItem value="0">No</MenuItem>
            <MenuItem value="1">Yes</MenuItem>
          </AccountSelectField>
        </Grid>
      </form>
    </IndexedCardInfo>
  );
};

export default BillOptions;
