import Cookies from "js-cookie";

export enum AuthCookies {
  REFRESH_TOKEN = "ap_refreshToken",
  USER_ROLE = "ap_userRole",
  API_BASE_URL = "ap_apiBaseUrl",
  SESSION_TOKEN = "ap_sessionToken",
  UAT_BANNER_AUTH_API = "ap_uatBannerAuthApi"
}

export const setCookieValue = (key: AuthCookies, value: string) => {
  const hostname = window.location.hostname;

  Cookies.set(key, value, {
    expires: 365,
    domain: hostname.substring(hostname.indexOf("."), hostname.length)
  });
};

export const getCookieValue = (key: AuthCookies) => Cookies.get(key);

export const clearCookies = () =>
  Object.values(AuthCookies).map(cookie => Cookies.remove(cookie));
