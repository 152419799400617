import React, { useState } from "react";
import { BulkOrderStageEnum } from "./enum";
import { RequestParams } from "./RequestParams";
import { RequestStatus } from "./RequestStatus";
import { TemplateUploadDownload } from "./TemplateUploadDownload";

export const DialogContent = () => {
  const [dialogState, setDialogState] = useState<{
    stage: BulkOrderStageEnum;
    file?: any;
    jobId?: string;
  }>({ stage: BulkOrderStageEnum.TEMPLATE });

  const goBack = () => {
    setDialogState({ stage: BulkOrderStageEnum.TEMPLATE });
  };

  const chooseFile = (files: any) => {
    if (files.length)
      setDialogState({
        file: files[0],
        stage: BulkOrderStageEnum.PARAMS
      });
  };

  const setJobId = (jobId: string) => {
    setDialogState({
      jobId,
      stage: BulkOrderStageEnum.STATUS
    });
  };

  const { file, jobId, stage } = dialogState;

  switch (stage) {
    case BulkOrderStageEnum.PARAMS:
      return <RequestParams file={file} goBack={goBack} setJobId={setJobId} />;
    case BulkOrderStageEnum.STATUS:
      return <RequestStatus jobId={jobId} />;
    case BulkOrderStageEnum.TEMPLATE:
      return <TemplateUploadDownload chooseFile={chooseFile} />;
    default:
      return <></>;
  }
};
