import React, { useEffect, useLayoutEffect } from "react";
import { LoadWizardState } from "./containers/common/LoadWizardState";
import { Wizard } from "./containers/Wizard";
import { ReduxProvider } from "./store/ReduxProvider";
import { useDispatch } from "react-redux";
import { INIT_CPQ } from "./store/root/actionTypes";
import { LoadAccountSettings } from "./containers/common/LoadAccountSettings";
import { AxiosInstance } from "axios";

interface CpqProps {
  /**
   * Init params from either
   * v1/Orders/Create
   * v1/GuidedSales/NewOrderForAccountOrLead
   * var wizardData from DC init
   */
  accountId?: string | null;
  accountName?: string | null;
  orderId?: string | null;
  leadId?: string | null;
  leadNumber?: string | null;
  opportunityId?: string | null;
  opportunityNumber?: string | null;
  opportunityName?: string | null;
  customerName?: string | null;

  /**
   * Customisation params that can't live in AccountSettings
   */
  /**
   * Env from which the CPQ is launched. Drives various customisations
   * TODO: Ideally we'd get rid of this and do everything from account and user
   *  settings, but it's out of scope at the moment
   */
  hostEnv: HostEnv;
  /**
   * This only gets set from wizardData on DC launch but is a user, not account level permission, so there's no other
   * way to access
   * TODO: Expose user level permissions generically via an API
   */
  userResignsPermission?: 1 | 0;

  /**
   * Axios instance for all API requests
   * This handles auth, either Kong+JWT and associated token expiry in the platform or Basic in direct DC scenario
   * Hence we can't just pass a header in a string
   */
  fetchFn: AxiosInstance;

  /**
   * If we want to load a saved order, populate this.
   */
  savedOrderId?: string | null;
}

/**
 * Host environment
 * Used for customisation / feature toggles where account settings can't be used for some DC reason
 * TODO: This should be in a common package perhaps, as it's use by both sales-dc and cpq
 *  also it should be an enum but that requires further refactoring and we already have a lot of potential merge conflicts
 */
export type HostEnv = "dc" | "platform" | "my_account";

/**
 * Fetch is required outside of components in the CPQ for redux operations,
 * hence provide a reference to it on the global scope
 */
export let globalAxiosInstance: AxiosInstance;

/**
 * Main Wrapper for the Contract, Price & Quote Wizard
 * aka 80% of Affinity Sales and 60% of Affinity Orders
 */
export const Cpq = ({ savedOrderId, fetchFn, ...otherProps }: CpqProps) => {
  useLayoutEffect(() => {
    globalAxiosInstance = fetchFn;
  }, [fetchFn]);

  return (
    <ReduxProvider>
      <InitCpq {...otherProps} />
      <LoadWizardState savedOrderId={savedOrderId}>
        <LoadAccountSettings>
          <Wizard />
        </LoadAccountSettings>
      </LoadWizardState>
    </ReduxProvider>
  );
};

export type InitCpqProps = Omit<CpqProps, "savedOrderId" | "fetchFn">;

/**
 * Initialises values in the CPQ store
 * Such as Account Settings and any order or lead ID.
 * Would be nice to have these just in context but impractical while there's so much pre-existing redux code
 */
const InitCpq = (props: InitCpqProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: INIT_CPQ, ...props });
  }, [dispatch, props]);
  return null;
};
