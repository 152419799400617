import React from "react";
import { useDispatch } from "react-redux";
import { Grid, TextField } from "@material-ui/core";
import {
  setEthernetConfigAddress,
  updateEthernetConfiguration,
  updateEthernetCustomerData
} from "../../../../store/ethernetProducts/actionsCreators";
import AddressChip from "../../../../components/Step1/Ethernet/AddressChip";
import AddressCorrect from "./AddressCorrect";
import { isValidPhoneNumber } from "../../../../helpers/validation";
import {
  getDCAddressLine,
  getDCEthernetAddressValid
} from "../../../../helpers/addresses";
import { AddressPicker } from "../../../shared/AddressPicker";
import { ReduxEthernetConfiguration } from "../types";

export interface EthernetConfigurationAddressProps {
  prefix: "site_a_" | "site_b_";
  configIndex: number;
  configuration: ReduxEthernetConfiguration;
  withCli?: boolean;
  cli?: string;
  setCli?: (cli: string) => void;
}

export const EthernetConfigurationAddress = ({
  prefix,
  configIndex,
  configuration,
  withCli,
  cli,
  setCli
}: EthernetConfigurationAddressProps) => {
  const dispatch = useDispatch();

  const addressLine = getDCAddressLine(configuration.purchaseArgs, prefix);
  const prefixUprn = `${prefix}uprn` as keyof ReduxEthernetConfiguration;
  const uprn = configuration[prefixUprn];
  const addressValid = getDCEthernetAddressValid(
    configuration.purchaseArgs,
    prefix
  );

  return (
    <Grid container spacing={2}>
      {addressLine && (
        <Grid item md={12}>
          <AddressChip
            address={addressLine}
            remove={() =>
              dispatch(setEthernetConfigAddress(configIndex, {}, prefix))
            }
          />
        </Grid>
      )}
      {uprn && (
        <Grid item md={12}>
          <AddressChip
            address={
              <span>
                UPRN: <b>{uprn}</b>
              </span>
            }
            remove={() =>
              dispatch(
                updateEthernetConfiguration(configIndex, prefixUprn, false)
              )
            }
          />{" "}
        </Grid>
      )}
      <Grid item md={withCli ? 6 : 12}>
        <AddressPicker
          saveAddress={wlr3Address =>
            dispatch(setEthernetConfigAddress(configIndex, wlr3Address, prefix))
          }
          saveUPRN={uprn =>
            updateEthernetConfiguration(configIndex, prefixUprn, uprn)
          }
          includeUPRN
        />
      </Grid>
      {withCli && (
        <Grid item md={6}>
          <TextField
            label={`Enter CLI (optional)`}
            variant={"outlined"}
            data-cy={"ethernet-CLI"}
            value={cli}
            helperText={
              cli && !isValidPhoneNumber(cli) && "Must be a valid CLI"
            }
            error={!!cli && !isValidPhoneNumber(cli)}
            onChange={e => setCli?.(e.target.value)}
          />
        </Grid>
      )}
      <Grid item md={12}>
        <AddressCorrect
          address={configuration.purchaseArgs}
          prefix={prefix}
          updateAddress={(address: any) =>
            dispatch(updateEthernetCustomerData(configIndex, address))
          }
          open={!!(addressLine && !addressValid)}
        />
      </Grid>
    </Grid>
  );
};
