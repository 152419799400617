import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Pricing from "../../../../../containers/Shared/Pricing/index";
import {
  requestMobilePricingData,
  setMobileProductDiscount,
  updateConfigProperty
} from "../../../../../store/mobile/actionCreators";
import { StatusChip } from "@akj-dev/design-system";
import { makeGetMergedProductData } from "../../../../../store/mobile/selectors/productConfig";
import { getHasMatchingPricingRules } from "../../../../../store/mobile/selectors/pricing";

class MobilePricingWrapper extends Component {
  static propTypes = {
    targetConfigs: PropTypes.array.isRequired
  };

  render() {
    if (
      this.props.targetConfigs.length > 1 &&
      !this.props.hasMatchingPricingRules
    )
      return (
        <StatusChip
          type="info"
          title="Sorry, pricing is not bulk editable"
          message="You must select products with the same pricing rule."
        />
      );
    const {
      // Store
      productData,
      properties,

      // Actions
      setPricingScheme,
      setDiscount,
      setServiceTariff
    } = this.props;
    return (
      <Pricing
        title="Pricing"
        productData={productData}
        configProperties={properties}
        setPricingScheme={setPricingScheme}
        setDiscount={setDiscount}
        setServiceTariff={setServiceTariff}
      />
    );
  }
}

const makeMapStateToProps = () => {
  const getMergedProductData = makeGetMergedProductData();
  return (state, ownProps) => ({
    productData: getMergedProductData(state, ownProps),
    properties: state.mobile.configs[ownProps.targetConfigs[0]].properties,
    hasMatchingPricingRules: getHasMatchingPricingRules(
      state,
      ownProps.targetConfigs
    )
  });
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setPricingScheme: scheme => {
      dispatch(
        updateConfigProperty("pricing_scheme", scheme, ownProps.targetConfigs)
      );
      // ....and fetch config specific product (pricing) data TODO: Limit to one config.
      dispatch(requestMobilePricingData(ownProps.targetConfigs));
    },
    setDiscount: (priceType, discountType, discountValue) => {
      dispatch(
        setMobileProductDiscount(
          discountType,
          discountValue,
          ownProps.targetConfigs
        )
      );
      dispatch(requestMobilePricingData(ownProps.targetConfigs));
    },
    setServiceTariff: tariff => {
      dispatch(
        updateConfigProperty(
          "service_tariff_id",
          tariff,
          ownProps.targetConfigs
        )
      );
      dispatch(requestMobilePricingData(ownProps.targetConfigs));
    }
  };
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(MobilePricingWrapper);
