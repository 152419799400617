import * as actionTypes from "./actionTypes";
import u from "updeep";
import { STEP_CHOOSE } from "./constants";
import { getSteps } from "./helpers";
import { ReduxAccountSettingsEnum } from "../../screens/enums";
import { INIT_CPQ } from "../root/actionTypes";

const initialState = {
  flashMessages: [],
  alertMessages: [],
  stepOneWlrOpen: false,
  stepOneBBOpen: false,
  stepOneMobileOpen: false,
  stepOneHardwareOpen: false,
  stepOneEthernetOpen: false,
  stepOneWlrBroadbandOpen: false,
  stepOneUniversalProductsOpen: false,
  stepOneMonitoringServiceOpen: false,
  stepTwoHardwareOpen: false,
  stepTwoBoltOnsOpen: false,
  orderStatusOpen: false,
  activeStep: STEP_CHOOSE,
  steps: getSteps(false),
  visited: [true], // This will expand to store visited state on each step, matching above
  showFinalStepWarning: false,
  finalStepWarningShown: false,
  productTypes: [],
  activeSubStepChoose: ReduxAccountSettingsEnum.SELECT,
  chooseHardwareConfig: {},
  showMobileResigns: false,
  showWlrResigns: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_CPQ:
      return u(
        {
          steps: getSteps(!!action.leadId)
        },
        state
      );

    case actionTypes.ADD_ALERT_MESSAGE:
      return Object.assign({}, state, {
        alertMessages: action.alertMessages
      });

    case actionTypes.ADD_FLASH_MESSAGE:
      return Object.assign({}, state, {
        flashMessages: action.flashMessages
      });

    case actionTypes.REMOVE_FLASH_MESSAGES:
      return Object.assign({}, state, {
        flashMessages: action.flashMessages
      });

    case actionTypes.REMOVE_ALERT_MESSAGES:
      return u(
        {
          alertMessages: []
        },
        state
      );

    case actionTypes.TOGGLE_STEP_ONE_MOBILE:
      return {
        ...state,
        stepOneMobileOpen: !state.stepOneMobileOpen
      };

    case actionTypes.TOGGLE_STEP_ONE_HARDWARE:
      return {
        ...state,
        stepOneHardwareOpen: !state.stepOneHardwareOpen
      };

    case actionTypes.TOGGLE_STEP_ONE_ETHERNET:
      return {
        ...state,
        stepOneEthernetOpen: !state.stepOneEthernetOpen
      };

    case actionTypes.TOGGLE_STEP_ONE_WLR_BROADBAND:
      return {
        ...state,
        stepOneWlrBroadbandOpen: !state.stepOneWlrBroadbandOpen
      };

    case actionTypes.TOGGLE_STEP_ONE_UNIVERSAL_PRODUCTS:
      return {
        ...state,
        stepOneUniversalProductsOpen: !state.stepOneUniversalProductsOpen
      };

    case actionTypes.TOGGLE_STEP_ONE_MONITORING_SERVICE:
      return {
        ...state,
        stepOneMonitoringServiceOpen: !state.stepOneMonitoringServiceOpen
      };

    case actionTypes.TOGGLE_ORDER_STATUS:
      return u(
        {
          orderStatusOpen: !state.orderStatusOpen
        },
        state
      );

    case actionTypes.SET_ACTIVE_STEP:
      return u(
        {
          activeStep: action.step,
          visited: { [state.steps.indexOf(action.step)]: true }
        },
        state
      );

    case actionTypes.SHOW_FINAL_STEP_WARNING:
      return u(
        {
          showFinalStepWarning: true
        },
        state
      );

    case actionTypes.HIDE_FINAL_STEP_WARNING:
      return u(
        {
          showFinalStepWarning: false
        },
        state
      );

    case actionTypes.CONFIRM_FINAL_STEP_WARNING:
      return u(
        {
          showFinalStepWarning: false,
          finalStepWarningShown: true
        },
        state
      );

    case actionTypes.SET_STEPS:
      return u({ steps: action.payload }, state);

    case actionTypes.RESET_UI_STATE:
      return initialState;

    case actionTypes.SET_PRODUCT_TYPES:
      return u(
        {
          productTypes: action.productTypes
        },
        state
      );

    case actionTypes.SET_ACTIVE_SUB_STEP_CHOOSE:
      return u(
        {
          activeSubStepChoose: action.activeSubStepChoose
        },
        state
      );
    case actionTypes.SET_HARDWARE_CONFIG:
      return u(
        {
          chooseHardwareConfig: action.chooseHardwareConfig
        },
        state
      );
    case actionTypes.SET_SHOW_MOBILE_RESIGNS:
      return u(
        {
          showMobileResigns: !state.showMobileResigns
        },
        state
      );
    case actionTypes.SET_SHOW_WLR_RESIGNS:
      return u(
        {
          showWlrResigns: !state.showWlrResigns
        },
        state
      );

    default:
      return state;
  }
};
