import React, { Component } from "react";
import PropTypes from "prop-types";
import CliBoltOnSelectField from "./CliBoltOnSelectField";
import Section from "../../../../../../components/Shared/Section";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CliTopUpBoltOnSelectField from "./CliTopUpBoltOnSelectField";
import { TargetConfigsContext } from "../../../../context/TargetConfigsContext";

class CliBoltOns extends Component {
  static defaultProps = { mode: "type" };
  static propTypes = { mode: PropTypes.oneOf(["slot", "type"]) };
  state = {};
  static contextType = TargetConfigsContext;

  render() {
    const { targetConfigs } = this.context;
    const configId = targetConfigs[0];

    return (
      <Section title="Bolt Ons" avatar={<AddBoxIcon />}>
        {this.props.mode === "slot" ? (
          Array.from(Array(8)).map((e, i) => (
            <CliBoltOnSelectField
              key={i}
              configId={configId}
              boltOnType={String(i + 1)}
              title={`VAS${i + 1}`}
              mode="slot"
            />
          ))
        ) : (
          <>
            <CliBoltOnSelectField
              configId={configId}
              boltOnType="voice"
              title="International Minutes"
            />
            <CliBoltOnSelectField
              configId={configId}
              boltOnType="roaming_voice"
              title="Worldwide Roaming Minutes"
            />
            <CliBoltOnSelectField
              configId={configId}
              boltOnType="text"
              title="International SMS"
            />
            <CliBoltOnSelectField
              configId={configId}
              boltOnType="data"
              title="Data"
            />
            <CliTopUpBoltOnSelectField
              configId={configId}
              boltOnType="data"
              title="Data Top Up"
            />
            <CliBoltOnSelectField
              configId={configId}
              boltOnType="roaming_data"
              title="Worldwide Roaming Data"
            />
            <CliBoltOnSelectField
              configId={configId}
              boltOnType="additional_bundle_2"
              title="Non Geographic Numbers"
            />
          </>
        )}
      </Section>
    );
  }
}

export default CliBoltOns;
