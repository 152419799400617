export const ADDING_TRACKING_DETAILS_TO_ORDER =
  "order.ADDING_TRACKING_DETAILS_TO_ORDER";

// Create a new blank order to add products to.
export const REQUEST_NEW_ORDER = "order.REQUEST_NEW_ORDER";
export const RECEIVE_NEW_ORDER = "order.RECEIVE_NEW_ORDER";

// View or update order status (used by a few different actionCreators)
export const REQUEST_UPDATE_ORDER_STATUS = "order.REQUEST_UPDATE_ORDER_STATUS";
export const RECEIVE_UPDATE_ORDER_STATUS = "order.RECEIVE_UPDATE_ORDER_STATUS";

export const REQUEST_UPDATE_OPPORTUNITY = "order.REQUEST_UPDATE_OPPORTUNITY";
export const RECEIVE_UPDATE_OPPORTUNITY = "order.RECEIVE_UPDATE_OPPORTUNITY";

export const SET_CONTRACT_LENGTH = "order.SET_CONTRACT_LENGTH";

export const SET_QUOTE_ONLY = "order.SET_QUOTE_ONLY";
export const REQUEST_REMOVE_BOLT_ON = "order.REQUEST_REMOVE_BOLT_ON";
export const RECEIVE_REMOVE_BOLT_ON = "order.RECEIVE_REMOVE_BOLT_ON";
export const REQUEST_RECALCULATE_PRICES = "order.REQUEST_RECALCULATE_PRICES";
export const RECEIVE_RECALCULATE_PRICES = "order.RECEIVE_RECALCULATE_PRICES";

export const SET_ORDER_CONTACT = "order.SET_ORDER_CONTACT";

export const RECEIVE_GENERATE_CONTRACT = "order.RECEIVE_GENERATE_CONTRACT";
export const REQUEST_GENERATE_CONTRACT = "order.REQUEST_GENERATE_CONTRACT";
export const SET_TRACKING_DETAILS = "order.SET_TRACKING_DETAILS";
export const REQUEST_SEND_FOR_APPROVAL = "order.REQUEST_SEND_FOR_APPROVAL";
export const RECEIVE_SEND_FOR_APPROVAL = "order.RECEIVE_SEND_FOR_APPROVAL";
export const REQUEST_PROVISION_ORDER = "order.REQUEST_PROVISION_ORDER";
export const RECEIVE_PROVISION_ORDER = "order.RECEIVE_PROVISION_ORDER";

export const REQUEST_APPROVE_OR_REJECT_APPROVAL =
  "order.REQUEST_APPROVE_OR_REJECT_APPROVAL";
export const RECEIVE_APPROVE_OR_REJECT_APPROVAL =
  "order.RECEIVE_APPROVE_OR_REJECT_APPROVAL";

export const SET_CONTRACT_META = "order.SET_CONTRACT_META";

export const REQUEST_UPLOAD_CONTRACT = "order.REQUEST_UPLOAD_CONTRACT";
export const RECEIVE_UPLOAD_CONTRACT = "order.RECEIVE_UPLOAD_CONTRACT";
export const RESET_UPLOAD_CONTRACT = "order.RESET_UPLOAD_CONTRACT";
export const SET_FIELD = "order.SET_FIELD";

export const REQUEST_ORDERS_FOR_ACCOUNT = "order.REQUEST_ORDERS_FOR_ACCOUNT";
export const RECEIVE_ORDERS_FOR_ACCOUNT = "order.RECEIVE_ORDERS_FOR_ACCOUNT";
export const RECEIVE_MORE_ORDERS_FOR_ACCOUNT =
  "order.RECEIVE_MORE_ORDERS_FOR_ACCOUNT";

// When meta is set from a local user login
export const SET_ACCOUNT_ID = "order.SET_ACCOUNT_ID";

export const RESET_ORDER_STATE = "order.RESET_ORDER_STATE";
