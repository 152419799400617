import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PhoneIcon from "@material-ui/icons/Phone";
import PublicIcon from "@material-ui/icons/Public";
import { getTotalPrice } from "../../../../../store/wlrBroadband/selectors";
import { useTheme } from "@material-ui/core/styles";
import LinkIcon from "../../../../../components/Shared/LinkIcon";
import AddBroadband from "../AddBroadband";

const CustomCell = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

const CustomArrowForwardIcon = styled(ArrowForwardIcon)`
  color: ${p => p.theme.palette.text.disabled};
  margin-left: 10px;
  margin-right: 10px;
`;

function ProductChangesRow({
  configuration,
  isChecked,
  toggleRow,
  productInstance,
  resignProduct,
  isLinkedProductInstance,
  canAddBroadband,
  actions
}) {
  const theme = useTheme();
  const isResignProduct = resignProduct.components[0].is_resign;
  const resignProductName = resignProduct.name_for_resign || resignProduct.name;
  const resignProductPrice =
    resignProduct.price.first_bill_recurring_price_with_promotions;
  return (
    <TableRow selected={isChecked}>
      <TableCell>
        <Checkbox onChange={toggleRow} checked={isChecked} />
      </TableCell>
      <TableCell>{productInstance.pin}</TableCell>
      <TableCell>{isLinkedProductInstance && <LinkIcon />}</TableCell>
      <TableCell colSpan={2}>
        <CustomCell>
          {productInstance.broadband ? (
            <PublicIcon
              style={{ color: theme.palette.grey[500], marginRight: 10 }}
            />
          ) : (
            <PhoneIcon
              style={{ color: theme.palette.grey[500], marginRight: 10 }}
            />
          )}
          <div>{productInstance.name_for_resign || productInstance.name}</div>
          <CustomArrowForwardIcon />
          {productInstance.broadband ? (
            <PublicIcon
              style={{ color: theme.palette.grey[500], marginRight: 10 }}
            />
          ) : (
            <PhoneIcon
              style={{ color: theme.palette.grey[500], marginRight: 10 }}
            />
          )}
          <div>{resignProductName}</div>
        </CustomCell>
      </TableCell>
      <TableCell>
        {canAddBroadband && (
          <AddBroadband
            productInstance={productInstance}
            configuration={configuration}
            locationIndex={configuration.locationIndex}
          />
        )}
      </TableCell>
      <TableCell>
        {isResignProduct
          ? getTotalPrice(productInstance.evo_services)
          : resignProductPrice}
      </TableCell>
      <TableCell className="u-nowrap">{productInstance.end_date_ymd}</TableCell>
      <TableCell className="u-text-right" style={{ paddingRight: 0 }}>
        {actions}
      </TableCell>
    </TableRow>
  );
}

export default ProductChangesRow;
