import React from "react";

/**
 * HOC for injecting context into component props.
 * This is needed because react-redux doesn't expose the context API to mapStateToProps
 *
 * @param WrappedComponent
 * @param targetContext
 * @returns WrappedComponent component with context properties injected as props
 */
function withContextToProps(WrappedComponent, targetContext) {
  return class extends React.Component {
    static contextType = targetContext;

    render() {
      return <WrappedComponent {...this.context} {...this.props} />;
    }
  };
}

export default withContextToProps;
