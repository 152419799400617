import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core";
import { setBroadbandProduct } from "../../../../../store/wlrBroadband/actions";
import {
  ReduxWlrLocation,
  WlrLocationBroadbandProduct,
  WlrLocationProductConfig
} from "../../types";
import { WlrLocationTypeEnum } from "../../enums";
import { BroadbandProductSelect } from "../shared/BroadbandProductSelect";

export interface BroadbandSelectProps {
  locationIndex: number;
  productId: WlrLocationTypeEnum;
  broadbandProductId?: string;
}

export const BroadbandSelect = ({
  locationIndex,
  productId,
  broadbandProductId = ""
}: BroadbandSelectProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contractLength = useSelector<any, number>(
    state => state.wlrBroadband.contractLength
  );
  const locations = useSelector<any, ReduxWlrLocation[]>(
    state => state.wlrBroadband.locations
  );
  const location = locations?.[locationIndex];
  const broadbandProducts: WlrLocationBroadbandProduct[] =
    location.broadbandSearch?.response?.products ?? [];

  const configurations = useSelector<any, WlrLocationProductConfig[]>(
    state => state.wlrBroadband.configurations ?? []
  ).map((config, configId) => ({ ...config, configId }));

  const productConfigurations = configurations.filter(
    c => c.wlrProductId === productId && c.locationIndex === locationIndex
  );

  const handleBroadbandProduct = useCallback(
    (configId: number, broadbandId: string) => {
      dispatch(setBroadbandProduct(configId, broadbandId));
    },
    [dispatch]
  );

  return (
    <div className={classes.root}>
      <BroadbandProductSelect
        label={"Add broadband"}
        broadbandProductId={broadbandProductId}
        broadbandProducts={broadbandProducts}
        wlrProductId={productId}
        configId={productConfigurations[0].configId}
        contractLength={contractLength}
        handleBroadbandProduct={handleBroadbandProduct}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1)
  }
}));
