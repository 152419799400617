import React from "react";
import styled from "styled-components";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {
  getAddressLine,
  mapAndFormatCallBundleOrSelectServices,
  mapToLocationAddress
} from "../../../../helpers/addresses";
import InfoIcon from "@material-ui/icons/Info";
import HomeIcon from "@material-ui/icons/Home";
import SettingsPhoneIcon from "@material-ui/icons/SettingsPhone";
import GroupIcon from "@material-ui/icons/Group";
import RedeemIcon from "@material-ui/icons/Redeem";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import PublicIcon from "@material-ui/icons/Public";
import { withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";

const Row = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  margin-bottom: 8px;
  margin-top: 8px;
`;

const Name = styled.div`
  font-weight: bold;
  margin-left: 8px;
`;

const Description = styled.div`
  margin-left: 8px;
`;

const Price = styled.div`
  border: 1px solid ${p => p.theme.palette.success.main};
  border-radius: 5px;
  color: ${p => p.theme.palette.success.main};
  font-weight: bold;
  font-size: 12px;
  margin-left: 8px;
  padding: 5px;
`;

class MoreInformationPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isOpen: false
    };
  }

  handleClick = event => {
    this.setState({
      isOpen: true,
      anchorEl: event.currentTarget
    });
  };

  handleRequestClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen, anchorEl } = this.state;
    const { productInstance, theme, location } = this.props;
    const { address, line_details, specials } = productInstance;
    const callBundle =
      line_details &&
      line_details.call_bundle &&
      mapAndFormatCallBundleOrSelectServices(line_details.call_bundle);
    const selectServices =
      line_details &&
      line_details.select_services &&
      mapAndFormatCallBundleOrSelectServices(line_details.select_services);
    const hasSpecialRates = specials && (specials.tariffs || specials.numbers);
    const id = isOpen ? "moreInformationPopover" : undefined;
    return (
      <>
        <IconButton
          color={"primary"}
          aria-describedby={id}
          onClick={this.handleClick}
        >
          <InfoIcon />
        </IconButton>
        <Popover
          id={id}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={this.handleRequestClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Box m={1}>
            {address && (
              <Row>
                <HomeIcon style={{ color: theme.palette.text.disabled }} />
                <Name>Address</Name>
                <Description>
                  {getAddressLine(
                    location ? location.address : mapToLocationAddress(address)
                  ) || "None"}
                </Description>
              </Row>
            )}
            {line_details && (
              <>
                {line_details.call_tariff && (
                  <Row>
                    <SettingsPhoneIcon
                      style={{ color: theme.palette.text.disabled }}
                    />
                    <Name>Call Tariff</Name>
                    <Description>
                      {line_details.call_tariff.service_description || "None"}
                    </Description>
                    {line_details.call_tariff.price && (
                      <Price>
                        {parseFloat(line_details.call_tariff.price).toFixed(2)}
                      </Price>
                    )}
                  </Row>
                )}
                {line_details.care_level && (
                  <Row>
                    <GroupIcon style={{ color: theme.palette.text.disabled }} />
                    <Name>Care Level</Name>
                    <Description>
                      {line_details.care_level.service_description || "None"}
                    </Description>
                    {line_details.care_level.price && (
                      <Price>
                        {parseFloat(line_details.care_level.price).toFixed(2)}
                      </Price>
                    )}
                  </Row>
                )}
                {line_details.bb_service && (
                  <Row>
                    <PublicIcon
                      style={{ color: theme.palette.text.disabled }}
                    />
                    <Name>Broadband Rental</Name>
                    <Description>
                      {line_details.bb_service.service_description || "None"}
                    </Description>
                    {line_details.bb_service.price && (
                      <Price>
                        {parseFloat(line_details.bb_service.price).toFixed(2)}
                      </Price>
                    )}
                  </Row>
                )}
                {callBundle && (
                  <Row>
                    <RedeemIcon
                      style={{ color: theme.palette.text.disabled }}
                    />
                    <Name>Call Bundle</Name>
                    <Description>
                      {callBundle.description || "None"}
                    </Description>
                    {callBundle.price && (
                      <Price>{parseFloat(callBundle.price).toFixed(2)}</Price>
                    )}
                  </Row>
                )}
                {selectServices && (
                  <Row>
                    <RedeemIcon
                      style={{ color: theme.palette.text.disabled }}
                    />
                    <Name>Calling Features</Name>
                    <Description>
                      {selectServices.description || "None"}
                    </Description>
                    {selectServices.price && (
                      <Price>
                        {parseFloat(selectServices.price).toFixed(2)}
                      </Price>
                    )}
                  </Row>
                )}
              </>
            )}
            <Row>
              <InsertChartIcon style={{ color: theme.palette.text.disabled }} />
              <Name>Special Rates</Name>
              {hasSpecialRates ? (
                <CheckCircleIcon
                  style={{ color: theme.palette.success.main, marginLeft: 8 }}
                />
              ) : (
                <CancelIcon
                  style={{ color: theme.palette.error.main, marginLeft: 8 }}
                />
              )}
            </Row>
          </Box>
        </Popover>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const locations = state.wlrBroadband.locations;
  const location = locations.find(
    location => location.cli.value === ownProps.productInstance.pin
  );
  return {
    location
  };
};

export default withTheme(connect(mapStateToProps)(MoreInformationPopover));
