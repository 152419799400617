import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { setTrackingDetails } from "../../../store/order/trackingDetailsActions";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { isValidEmail, isValidMobileNumber } from "../../../helpers/validation";
import posed from "react-pose";
import TextField from "@material-ui/core/TextField";
import { IndexedCardInfo } from "../../../screens/shared/indexed-card-info";

class TrackingDetails extends React.Component {
  render() {
    const { setTrackingDetails, trackingDetails, cardIndex } = this.props;

    const validEmail = isValidEmail(trackingDetails);
    const validMobile = isValidMobileNumber(trackingDetails);
    const error = trackingDetails && !validEmail && !validMobile;

    return (
      <IndexedCardInfo index={cardIndex} title="Tracking Details">
        <FieldWrapper data-cy="TrackingDetailsEmail">
          <TextField
            fullWidth
            label="Email or Mobile Number"
            onChange={event => setTrackingDetails(event.target.value)}
            value={trackingDetails}
            error={!!error}
            helperText={
              error && "Must be a valid email address or mobile number."
            }
          />
          <Indicator>
            <ShowHide pose={validEmail || validMobile ? "show" : "hide"}>
              {validEmail ? <EmailIcon /> : <PhoneIcon />}
              <CheckCircleIcon />
            </ShowHide>
          </Indicator>
        </FieldWrapper>
      </IndexedCardInfo>
    );
  }
}

const FieldWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Indicator = styled.div`
  margin-left: 15px;
  width: 70px;
  height: 100%;
  overflow: hidden;
`;

const ShowHide = posed.div({
  hide: {
    x: -48,
    opacity: 0
  },
  show: {
    x: 0,
    opacity: 1
  }
});

const mapStateToProps = state => ({
  trackingDetails: state.order.trackingDetails
});

export default connect(mapStateToProps, {
  setTrackingDetails
})(TrackingDetails);
