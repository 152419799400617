import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import posed from "react-pose";
import { CheckIcon } from "@akj-dev/design-system";
import ErrorIcon from "@material-ui/icons/ErrorOutline";

const Pop = posed.div({
  open: {
    scale: 1
  },
  closed: {
    scale: 0.5
  }
});

function StatusLarge({ type, title, message }) {
  return (
    <Outer>
      <Pop initialPose="closed" pose="open">
        <>
          {type === "success" && (
            <CheckIcon style={{ width: 100, height: 100 }} />
          )}
          {type === "error" && (
            <ErrorIcon style={{ width: 100, height: 100 }} color="error" />
          )}
        </>
      </Pop>
      {title && <h4>{title}</h4>}
      {message && <p>{message}</p>}
    </Outer>
  );
}

StatusLarge.propTypes = {
  type: PropTypes.oneOf(["loading", "error", "success", "info"]).isRequired,
  title: PropTypes.string,
  message: PropTypes.string
};

const Outer = styled.div`
  text-align: center;
`;

export default StatusLarge;
