import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addContact,
  requestAllContacts,
  requestContactTypes
} from "../../../store/account/actions";
import {
  Grid,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  FormHelperText,
  DialogActions,
  DialogContent,
  Tooltip,
  DialogTitle,
  Dialog
} from "@material-ui/core";
import _ from "lodash";
import { StatusChip } from "@akj-dev/design-system";
import { Add } from "@material-ui/icons";
import StatusLarge from "../StatusLarge";
import { getAccountId } from "../../../store/order/selectors";
import {
  isValidEmail,
  isValidMobileNumber,
  isValidText
} from "../../../helpers/validation";
import { addAlertMessage } from "../../../store/uiState/actions";

interface CreateContactProps {
  // TODO: Proper typing for this component. Out of time atm....
  /* Callback after contact created */
  onCreate: (contactId: string) => void;
}

/**
 * Creates a contact either on an account, or a lead if no account exists.
 * @param selectAction
 * @constructor
 */
export default function CreateContact({ onCreate }: CreateContactProps) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [closeTimeout, setCloseTimeout] = useState(0);
  const { fetching, response } = useSelector(
    (state: any) => state.account.addContact
  );
  const contactTypes = useSelector((state: any) => state.account.contactTypes);
  const accountId = useSelector(getAccountId);
  const types: any[] = _.get(contactTypes.response, "data.types", []);
  useEffect(() => {
    if (types.length < 1) {
      dispatch(requestContactTypes());
    }
    // TODO: Figure out a replacement pattern to stop over fetching here.
    //  Used to be if (types.length < 1) ...not ok with hooks.
    //  https://github.com/facebook/create-react-app/issues/6880#issuecomment-488158024
    //  For now, lint ignore.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const [values, setValues] = useState({
    title: {
      required: false,
      value: "",
      error: false
    },
    authority: {
      required: true,
      value: "",
      error: false
    },
    first_name: {
      required: true,
      value: "",
      error: false
    },
    last_name: {
      required: true,
      value: "",
      error: false
    },
    primary_email_address: {
      required: true,
      value: "",
      error: false
    },
    mobile_phone_number: {
      required: false,
      value: "",
      error: false
    }
  });

  const handleChange = (
    event: React.ChangeEvent<any>,
    error: string | boolean
  ) =>
    setValues({
      ...values,
      [event.target.name]: { value: event.target.value, error }
    });

  if (response.status === "success" && !closeTimeout) {
    setCloseTimeout(
      setTimeout(() => {
        setOpen(false);
        dispatch(requestAllContacts(true));
      }, 2000) as any
    );
  }

  const getCreateContactFieldsError = () => {
    for (let name in values) {
      if ((values as any)[name].error) return true;
    }
    return false;
  };

  return (
    <>
      <Tooltip title="Create new contact" placement="top">
        <Button
          onClick={() => setOpen(true)}
          startIcon={<Add />}
          variant="outlined"
        >
          Add new Contact
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionProps={{ unmountOnExit: true }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Create Contact</DialogTitle>
        {response.status !== "success" ? (
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Salutation</InputLabel>
                  <Select
                    value={values.title.value}
                    name="title"
                    onChange={event => handleChange(event, false)}
                    required={values.title.required}
                  >
                    <MenuItem value="Dr.">Dr.</MenuItem>
                    <MenuItem value="Miss">Miss</MenuItem>
                    <MenuItem value="Mr.">Mr.</MenuItem>
                    <MenuItem value="Mrs.">Mrs.</MenuItem>
                    <MenuItem value="Ms.">Ms.</MenuItem>
                    <MenuItem value="Prof.">Prof.</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl fullWidth disabled={contactTypes.fetching}>
                  <InputLabel>Contact Type</InputLabel>
                  <Select
                    value={values.authority.value}
                    name="authority"
                    onChange={event => handleChange(event, false)}
                    disabled={fetching}
                    required={values.authority.required}
                  >
                    {types.map(type => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                    {contactTypes.fetching && (
                      <FormHelperText>Loading...</FormHelperText>
                    )}
                  </Select>
                  {values.authority.error && (
                    <FormHelperText className="Mui-error Mui-required">
                      Must be set.
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  label="First Name"
                  fullWidth
                  name="first_name"
                  value={values.first_name.value}
                  onChange={event =>
                    handleChange(event, !isValidText(event.target.value))
                  }
                  disabled={fetching}
                  required={values.first_name.required}
                  error={values.first_name.error}
                  helperText={
                    values.first_name.error &&
                    "Must not be blank or contain special characters."
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  label="Last Name"
                  fullWidth
                  name="last_name"
                  value={values.last_name.value}
                  onChange={event =>
                    handleChange(event, !isValidText(event.target.value))
                  }
                  disabled={fetching}
                  required={values.last_name.required}
                  error={values.last_name.error}
                  helperText={
                    values.last_name.error &&
                    "Must not be blank or contain special characters."
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  label="Email"
                  fullWidth
                  name="primary_email_address"
                  value={values.primary_email_address.value}
                  onChange={event =>
                    handleChange(event, !isValidEmail(event.target.value))
                  }
                  disabled={fetching}
                  required={values.primary_email_address.required}
                  error={values.primary_email_address.error}
                  helperText={
                    values.primary_email_address.error &&
                    "Must be a valid email address."
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  label="Mobile"
                  fullWidth
                  name="mobile_phone_number"
                  value={values.mobile_phone_number.value}
                  onChange={event =>
                    handleChange(
                      event,
                      !isValidMobileNumber(event.target.value)
                    )
                  }
                  disabled={fetching}
                  required={values.mobile_phone_number.required}
                  error={values.mobile_phone_number.error}
                  helperText={
                    values.mobile_phone_number.error &&
                    "Must be an 11 digit mobile number."
                  }
                />
              </Grid>
            </Grid>

            {fetching && (
              <DialogContent>
                <StatusChip
                  type="loading"
                  title="Saving Contact"
                  message="Please wait..."
                />
              </DialogContent>
            )}

            {response.status === "error" && (
              <DialogContent>
                <StatusChip
                  type="error"
                  title="Sorry, there was a problem saving this contact."
                  message={response.message}
                />
              </DialogContent>
            )}
          </DialogContent>
        ) : (
          <DialogContent>
            <StatusLarge type="success" title="Contact added" />
          </DialogContent>
        )}

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              for (let name in values) {
                if (
                  (values as any)[name].required &&
                  (values as any)[name].value === ""
                ) {
                  (values as any)[name].error = "Must be set";
                }
              }
              if (getCreateContactFieldsError()) {
                dispatch(
                  addAlertMessage(
                    "Please correct highlighted errors on the create contact form."
                  )
                );
              } else {
                const newContactId = await dispatch(
                  addContact(values, !accountId)
                );
                if (newContactId) {
                  // It's kinda weird returning a string like this from a dispatch. It seems to work though.
                  //  ...Typing problem just because the store isn't typed in general I think.
                  // @ts-ignore
                  onCreate(newContactId);
                }
              }
            }}
            disabled={fetching || response.status === "success"}
          >
            Save Contact
          </Button>
          <Button onClick={() => setOpen(false)}>Back</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
