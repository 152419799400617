import React from "react";
import { Box } from "@material-ui/core";
import StepDelivery from "../../containers/StepDelivery";
import { TitleAndInfo } from "../shared/title-and-info";

export const Delivery = () => {
  return (
    <Box>
      <TitleAndInfo title={"Delivery"} />
      <StepDelivery />
    </Box>
  );
};
