import { postJSONBackgroundIdempotent } from "../helpers/idempotency";
import { getJSON } from "../helpers/core";
import { platformApiFetch } from "@akj-dev/affinity-platform";

export const ApprovalsApprove = (orderId: string) =>
  postJSONBackgroundIdempotent<ApprovalsApproveResponse>(
    `/Affinity/Sales/Approvals/approve/${orderId}`
  );

export type ApprovalsApproveResponse = {
  status: string;
  data: any; // orders object...always?
};

export const ApprovalsReject = (orderId: string) =>
  postJSONBackgroundIdempotent<ApprovalsRejectResponse>(
    `/Affinity/Sales/Approvals/reject/${orderId}`
  );

export type ApprovalsRejectResponse = {
  status: string;
  data: any; // orders object...always?
};

export const SalesStatusQuotes = ({
  page = 1,
  limit = 10,
  sortBy,
  order = "asc",
  filters = {},
  orderSource
}: any) =>
  getJSON(
    "Affinity/Sales/Quotes",
    {
      _page: page,
      _limit: limit,
      _sort: sortBy,
      _order: order,
      order_source: orderSource,
      ...filters
    },
    false
  );

export const SalesStatusDateRange = (filters: any) =>
  getJSON(`Affinity/Sales/Quotes/DateRange`, filters, false);

export const SalesStatusEndCustomerAccounts = (status: any) =>
  getJSON(
    `Affinity/Sales/Quotes/Options/endCustomerAccountNo`,
    { status },
    false
  ).then((data: any) =>
    data.map((account: any) => ({
      displayName: account.displayName,
      value: account.value
    }))
  );

export const SalesStatusOptions = (filters = {}, option: any) =>
  getJSON(`Affinity/Sales/Quotes/Options/${option}`, filters, false);

export const SalesStatusPdfBlob = (url: string) => {
  let headers = new Headers();
  headers.append("pragma", "no-cache");
  headers.append("cache-control", "no-cache");
  return platformApiFetch(
    "ExternalServices/v1/Affinity/Sales/Quotes/PDF/" + url,
    {
      headers
    }
  ).then((response: any) => {
    let filename;
    const disposition = response.headers.get("content-disposition");

    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /^attachment; filename="(.+)"$/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1];
      }
    }

    return {
      filename: filename,
      file: response
    };
  });
};
