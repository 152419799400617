import _ from "lodash";
import {
  call,
  put,
  takeEvery,
  select,
  takeLatest,
  takeLeading
} from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import * as GuidedSalesAPI from "../../api/v1/guidedSales";
import * as ProductAPI from "../../api/v1/product";
import { getDCOrderParams } from "./selectors";
import { getAccountId } from "../order/selectors";

/**
 * Get product data for either the wlr or bb product in a config
 *
 * @param configurationIndex
 * @param {string} productType - (wlr || broadband)
 */
function* fetchProductData({ configurationIndex, productType }) {
  const config = yield select(
    state => state.wlrBroadband.configurations[configurationIndex]
  );
  const productId = config[`${productType}ProductId`];

  if (!productId) return; // Prevents requests for non-existent products during bulk config price updates
  // ... where one product might not have broadband but the other one does for instance.

  /**
   * If this is the first time, call product data to find out about possible dynamic property values
   * of things we want to auto populate at the start of the config process (going into step 2)
   * Router & radius realms currently.
   * We need to do this before the _normal_ product data call as things like router selection can
   * influence price. A second call therefore has to be made after this to figure out said price.
   * We can't do it all in one go, or we'd end up hard coding product codes etc.
   *
   * Horribly convoluted, but necessary for DC.
   */
  if (
    productType === "broadband" &&
    _.isEmpty(config.broadbandProductData.response)
  ) {
    const dcOrderParams = yield select(state =>
      getDCOrderParams(state, configurationIndex, productType)
    );
    const initialResponse = yield call(
      GuidedSalesAPI.productData,
      null, // All populated by dcOrderParams
      null,
      null,
      dcOrderParams
    );

    yield put({
      type: actionTypes.SET_PROPERTIES_FROM_PRODUCT_DATA,
      configurationIndex,
      productType,
      response: initialResponse
    });
  }

  // Build new order params from the above...
  const dcOrderParams = yield select(state =>
    getDCOrderParams(state, configurationIndex, productType)
  );

  const response = yield call(
    GuidedSalesAPI.productData,
    null,
    null,
    null,
    dcOrderParams
  );
  yield put({
    type: actionTypes.RECEIVE_PRODUCT_DATA,
    configurationIndex,
    productType,
    response
  });
}

export function* watchProductData() {
  yield takeEvery(actionTypes.REQUEST_PRODUCT_DATA, fetchProductData);
}

/**
 * Get Resign-able line product instances.
 * There can be a load of these, so we get the first 10 ordered by end date,
 * to give the user something to have a crack at, then lazy load the rest page by page.
 *
 * @param page
 * @returns {IterableIterator<*>}
 */
function* fetchLineProductInstances({ page = 1 }) {
  const accountId = yield select(getAccountId);
  const params = {
    expires_within: 6, // Services expiring > 6 months in the future are not resignable.
    sort: "pin",
    with: "line_details,special_numbers,evo_services"
  };
  const response = yield call(
    ProductAPI.ProductInstance,
    "Line",
    accountId,
    page,
    params
  );
  yield put({
    type: actionTypes.RECEIVE_LINE_PRODUCT_INSTANCES,
    response
  });

  // If there's a next page, do the above again.
  const nextPage = _.get(response, "pagination.next_page");
  if (nextPage && nextPage > page) {
    yield put({
      type: actionTypes.REQUEST_LINE_PRODUCT_INSTANCES,
      page: nextPage
    });
  }
}

export function* watchLineProductInstances() {
  yield takeLatest(
    actionTypes.REQUEST_LINE_PRODUCT_INSTANCES,
    fetchLineProductInstances
  );
}

/**
 * Get Resign-able broadband product instances.
 * There can be a load of these, so we get the first 10 ordered by end date,
 * to give the user something to have a crack at, then lazy load the rest page by page.
 *
 * @param page
 * @returns {IterableIterator<*>}
 */
function* fetchBroadbandProductInstances({ page = 1 }) {
  const accountId = yield select(getAccountId);
  const params = {
    expires_within: 6, // Services expiring > 6 months in the future are not resignable.
    sort: "pin",
    with: "line_details,special_numbers,evo_services"
  };
  const response = yield call(
    ProductAPI.ProductInstance,
    "Broadband",
    accountId,
    page,
    params
  );
  yield put({
    type: actionTypes.RECEIVE_BROADBAND_PRODUCT_INSTANCES,
    response
  });

  // If there's a next page, do the above again.
  const nextPage = _.get(response, "pagination.next_page");
  if (nextPage && nextPage > page) {
    yield put({
      type: actionTypes.REQUEST_BROADBAND_PRODUCT_INSTANCES,
      page: nextPage
    });
  }
}

export function* watchBroadbandProductInstances() {
  yield takeLatest(
    actionTypes.REQUEST_BROADBAND_PRODUCT_INSTANCES,
    fetchBroadbandProductInstances
  );
}

/**
 * Find the product used to do resigns without change.
 * @returns {IterableIterator<*>}
 */
function* fetchResignProductSearch() {
  const response = yield call(ProductAPI.search, {
    has_extra_services: 1,
    extra_services_type: "resign"
  });
  yield put({ type: actionTypes.RECEIVE_SAME_RESIGN_PRODUCT, response });
}

export function* watchResignProductSearch() {
  yield takeLeading(
    actionTypes.REQUEST_SAME_RESIGN_PRODUCT,
    fetchResignProductSearch
  );
}

/**
 * Find the product used to do resign for same product with change.
 * @returns {IterableIterator<*>}
 */
function* fetchLineResignProductSearch() {
  const accountId = yield select(getAccountId);
  const response = yield call(ProductAPI.search, {
    account: accountId,
    has_line: 1,
    line_is_resign: 1
  });
  yield put({ type: actionTypes.RECEIVE_LINE_RESIGN_PRODUCT, response });
}

export function* watchLineResignProductSearch() {
  yield takeLeading(
    actionTypes.REQUEST_LINE_RESIGN_PRODUCT,
    fetchLineResignProductSearch
  );
}

/**
 * Find the product used to do resign for same product with change.
 * @returns {IterableIterator<*>}
 */
function* fetchBroadbandResignProductSearch() {
  const accountId = yield select(getAccountId);
  const response = yield call(ProductAPI.search, {
    account: accountId,
    has_broadband: 1,
    broadband_is_resign: 1
  });
  yield put({ type: actionTypes.RECEIVE_BROADBAND_RESIGN_PRODUCT, response });
}

export function* watchBroadbandResignProductSearch() {
  yield takeLeading(
    actionTypes.REQUEST_BROADBAND_RESIGN_PRODUCT,
    fetchBroadbandResignProductSearch
  );
}
