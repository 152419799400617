import { getJSON, postJSON } from "../helpers/core";

export const checkPacCode = (mobile, pac) =>
  postJSON("Mobile/check_pac_code", { mobile, pac });

export const checkStacCode = (stac, old_mobile_number) =>
  postJSON("Mobile/check_stac_code", { stac, old_mobile_number }, false);

export const reservedNumbersList = (account, network = "Vodafone") =>
  getJSON("Mobile/reserved_numbers_list", { account, network });

export const getStockLevel = productId =>
  getJSON(`Mobile/navision_stock_check/${productId}`);
