import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import FileIcon from "@material-ui/icons/InsertDriveFile";

const StyledPaper = styled(Paper)`
  margin: 20px;
  padding: 20px;
  vertical-align: baseline;
  svg {
    vertical-align: text-bottom;
  }
`;

const FileDisplay = ({ file }) => (
  <StyledPaper zDepth={2}>
    <FileIcon /> {file?.name}
  </StyledPaper>
);

FileDisplay.propTypes = {
  file: PropTypes.object.isRequired
};
export default FileDisplay;
