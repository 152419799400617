import _ from "lodash";
import * as actionTypes from "./actionTypes";
import { ERROR } from "../../helpers/constants";
import { getNextIdFromArrayOfObjectsWithIdAsProperty } from "../../helpers/action";
import * as Sentry from "@sentry/browser";
import { ETHERNET_PRODUCT_PTP } from "../ethernetProducts/constants";
import { saveDraftAction } from "../drafts/actionCreators";
import {
  validateEthernetConfigurations,
  ethernetPurchaseArgsValidationErrors
} from "../ethernetProducts/selectors";
import { setEthernetConfigPurchaseArgsSubmitted } from "../ethernetProducts/actionsCreators";
import {
  getDaisyOwnedLineSelected,
  validateWlrBroadbandConfigs
} from "../wlrBroadband/selectors";
import {
  createAccount,
  validateAllNewAccountFields,
  requestCreditVet
} from "../account/actions";
import {
  DCAddressIsValid,
  getNewAddressFieldValidation
} from "../../helpers/addresses";
import {
  getBillLimitsNotSet,
  validateMobileConfigs
} from "../mobile/selectors/productConfig";
import { getMobileSIMsRequired } from "../mobile/selectors/delivery";
import { getHardwareCount, getHasLeasedHardware } from "../hardware/selectors";
import { addAllProductsToOrder } from "../order/actions";
import { isValidMobileNumber, isValidEmail } from "../../helpers/validation";
import {
  getResignWithoutChangeProduct,
  hasResignsWithoutChange
} from "../mobile/selectors/resigns";
import { getDCEthernetAddressValid } from "../../helpers/addresses";
import {
  getDoesUniversalProductHaveDocuments,
  getIsUniversalProductConfigValid
} from "../universalProducts/selectors";
import { getDeliveryParams } from "../hardwareDelivery/selectors";
import { BILLING, NEW } from "../hardwareDelivery/constants";
import {
  getAccountSettings,
  getNewAccountFieldsValid,
  getValidForResign,
  getIsSalesPerson,
  getIsVfDirectConsumerAccount
} from "../account/selectors";
import { getDoesOrderNotHaveAnyProducts } from "../order/selectors";
import {
  STEP_CHOOSE,
  STEP_CUSTOMISE,
  STEP_ACCOUNT,
  STEP_DELIVERY,
  STEP_SUMMARY
} from "./constants";
import { getHardwareCreditError } from "../hardware/configurations/selectors";
import {
  BROADBAND_ONLY,
  EXISTING_SOGEA,
  NEW_SOGEA
} from "../wlrBroadband/constants";
import { validateAllConfigProperties } from "../mobile/actionCreators";
import { ProductTypesEnum } from "../../screens/enums";
import { addAppointments } from "../wlrBroadband/actions";
import { SubStepActionEnum } from "../../screens/choose/enums";
import {
  ETHERNET_PRODUCT_MANAGED,
  ETHERNET_PRODUCT_EFM,
  ETHERNET_PRODUCT_GEA
} from "../ethernetProducts/constants";

export function addFlashMessage(message) {
  return function(dispatch, getState) {
    const messages = getState().uiState.flashMessages;
    const nextId = getNextIdFromArrayOfObjectsWithIdAsProperty(messages);
    const newMessage = { id: nextId, message: message };
    let copyMessages = _.cloneDeep(messages);
    copyMessages.push(newMessage);
    const action = {
      type: actionTypes.ADD_FLASH_MESSAGE,
      flashMessages: copyMessages
    };
    dispatch(action);
  };
}

export function addAlertMessage(alert, type = ERROR, title = "Error") {
  return function(dispatch, getState) {
    const messages = getState().uiState.alertMessages;
    const nextId = getNextIdFromArrayOfObjectsWithIdAsProperty(messages);

    let message;
    if (alert instanceof Error) {
      // Important to log here as addAlertMessage try/catches a lot of stuff. Not just API related.
      Sentry.captureException(alert);
      console.error(alert);
      message = alert.message;
    } else {
      message = alert;
    }

    const newMessage = { id: nextId, message, type, title };
    let copyMessages = _.cloneDeep(messages);
    copyMessages.push(newMessage);
    const action = {
      type: actionTypes.ADD_ALERT_MESSAGE,
      alertMessages: copyMessages
    };
    dispatch(action);
  };
}

export function removeFlashMessages(flashMessages, id) {
  let copyFlashMessages = flashMessages.filter(function(flashMessage) {
    return flashMessage.id !== id;
  });

  return {
    type: actionTypes.REMOVE_FLASH_MESSAGES,
    flashMessages: copyFlashMessages
  };
}

export function removeFlashMessageByIdAction(flashMessageId) {
  return function(dispatch, getState) {
    dispatch(
      removeFlashMessages(getState().uiState.flashMessages, flashMessageId)
    );
  };
}

export const removeAlertMessages = () => ({
  type: actionTypes.REMOVE_ALERT_MESSAGES
});

export const toggleStepOneMobile = () => {
  return {
    type: actionTypes.TOGGLE_STEP_ONE_MOBILE
  };
};

export const toggleStepOneHardware = () => {
  return {
    type: actionTypes.TOGGLE_STEP_ONE_HARDWARE
  };
};

export const toggleStepOneEthernet = () => {
  return {
    type: actionTypes.TOGGLE_STEP_ONE_ETHERNET
  };
};

export const toggleStepOneWlrBroadband = () => {
  return {
    type: actionTypes.TOGGLE_STEP_ONE_WLR_BROADBAND
  };
};

export const toggleStepOneUniversalProducts = () => {
  return {
    type: actionTypes.TOGGLE_STEP_ONE_UNIVERSAL_PRODUCTS
  };
};

export const toggleStepOneMonitoringServiceProducts = () => {
  return {
    type: actionTypes.TOGGLE_STEP_ONE_MONITORING_SERVICE
  };
};

export const toggleOrderStatus = () => {
  return {
    type: actionTypes.TOGGLE_ORDER_STATUS
  };
};

export const showFinalStepWarning = () => {
  return {
    type: actionTypes.SHOW_FINAL_STEP_WARNING
  };
};

export const hideFinalStepWarning = () => {
  return {
    type: actionTypes.HIDE_FINAL_STEP_WARNING
  };
};

export const confirmFinalStepWarning = () => {
  return {
    type: actionTypes.CONFIRM_FINAL_STEP_WARNING
  };
};

/**
 * Set Global Active Step
 *
 *  - Performs checks to see if the step can be changed
 *  - Fires inter-step events like getting product data and creating products on the order
 *  - Auto-saves drafts
 *
 * @param step - a constant from src/js/store/uiState/constants.js
 * @returns {function(*, *)}
 */
export const setActiveStep = step => async (dispatch, getState) => {
  const state = getState();
  const getIsAccountCreatedState = () =>
    _.get(getState().account.createAccount.response, "status") === "success";
  const {
    mobile,
    wlrBroadband,
    ethernetProducts,
    hardwareDelivery,
    order,
    uiState: { visited, steps, finalStepWarningShown, activeStep }
  } = state;
  const accountSettings = getAccountSettings(state);

  // A step can't be missed out
  const stepIndex = steps.indexOf(step);
  if (stepIndex > 1 && !visited[stepIndex - 1]) return;

  // if we're on the summary step, don't allow the user to go back
  if (activeStep === STEP_SUMMARY) {
    return;
  }

  // We change the actual UI at slightly different points depending on which step we're transitioning, hence the helper function:
  const setStep = () => {
    const element = document.getElementsByClassName("WizardClass")[0];
    if (element !== undefined) element.scrollIntoView(true);
    dispatch({ type: actionTypes.SET_ACTIVE_STEP, step });
  };
  // TODO: Maybe homogenise the way things get fired on transition. Either all here or all componentDidMount()

  // Now do the step specific things:

  if (step === STEP_CHOOSE) {
    setStep();
  }

  // Step 2 - Configure Products
  if (step === STEP_CUSTOMISE) {
    // Allow only if the user has added products
    if (getDoesOrderNotHaveAnyProducts(state)) {
      dispatch(
        addAlertMessage("You must select at least one product to proceed.")
      );
      return;
    }

    // If WLR or broadband products have been added, check that no additional, empty locations exist.
    // See: https://auroratarget.tpondemand.com/entity/7479-166437-affinitysales-allowing-users-to-proceed.
    const unusedLocations = wlrBroadband.locations.filter((location, index) => {
      const locationHasConfig = wlrBroadband.configurations.find(
        config => config.locationIndex === index
      );
      return !locationHasConfig;
    });
    if (wlrBroadband.locations.length > 1 && unusedLocations.length) {
      dispatch(
        addAlertMessage(
          "You have added a location and not selected a product. Please select a product or remove the location to continue."
        )
      );
      return;
    }

    // MIC / Bionic customers can't place orders on existing Daisy lines.
    // https://gitlab.com/akj-dev/inbox/issues/628
    if (
      accountSettings.as_stop_daisy_line_orders === "1" &&
      getDaisyOwnedLineSelected(state)
    ) {
      dispatch(
        addAlertMessage(
          "Sorry, you have selected an existing Daisy line. You may not proceed with this order."
        )
      );
      return;
    }

    // Dont allow "resigns without change" to progress if DC has failed to give us a resign product.
    if (
      hasResignsWithoutChange(state) &&
      !getResignWithoutChangeProduct(state)
    ) {
      dispatch(
        addAlertMessage(
          "No resign product returned from Daisy Central. Please contact support."
        )
      );
      return;
    }

    // Validate ethernet config addresses set.
    if (
      ethernetProducts.configurations.find(
        c =>
          !getDCEthernetAddressValid(c.purchaseArgs, "site_b_") ||
          (c.type === ETHERNET_PRODUCT_PTP &&
            !getDCEthernetAddressValid(c.purchaseArgs, "site_a_"))
      )
    ) {
      dispatch(
        addAlertMessage(
          "You must choose valid installation addresses for your ethernet products to proceed."
        )
      );
      return;
    }

    // Check if address a is missing details
    if (
      ethernetProducts.configurations.find(
        c =>
          !_.isEmpty(c.site_a_full_address) &&
          !c.site_a_full_address.thoroughfareNumber &&
          !c.site_a_full_address.premisesName
      )
    ) {
      dispatch(
        addAlertMessage("You must provide address Number or Building name.")
      );
      return;
    }

    // Check if address b is missing details
    if (
      ethernetProducts.configurations.find(
        c =>
          !_.isEmpty(c.site_b_full_address) &&
          !c.site_b_full_address.thoroughfareNumber &&
          !c.site_b_full_address.premisesName
      )
    ) {
      dispatch(
        addAlertMessage("You must provide address Number or Building name.")
      );
      return;
    }

    // Check if a broadband product has been selected if a location is "Existing Line (Broadband Only)"
    // ...people were mistakenly using that pathway to try and "WLR Change" lines to add new call bundles etc.
    // ...which is impossible as WLR Change relies on having a broadband component to communicate changes to DC
    if (
      wlrBroadband.configurations.find(
        c =>
          wlrBroadband.locations[c.locationIndex]?.type === BROADBAND_ONLY &&
          !c.broadbandProductId
      )
    ) {
      dispatch(
        addAlertMessage("You must select a broadband product to continue.")
      );
      return;
    }
    setStep();
  }

  // if the wizard included step for creating account, we should try to perform that if not already completed.
  if (
    step === STEP_DELIVERY &&
    state.uiState.steps.includes(STEP_ACCOUNT) &&
    !getIsAccountCreatedState()
  ) {
    dispatch(validateAllNewAccountFields());
    if (!getNewAccountFieldsValid(getState())) {
      dispatch(
        addAlertMessage(
          "Please correct highlighted errors on the new account form"
        )
      );
      return;
    }

    if (getIsVfDirectConsumerAccount(getState())) {
      const {
        company_months_at_address,
        company_months_at_address2,
        company_months_at_address3
      } = getState().account.newAccountFields;
      if (
        parseInt(company_months_at_address) +
          parseInt(company_months_at_address2) +
          parseInt(company_months_at_address3) <
        36
      ) {
        dispatch(
          addAlertMessage(
            `Company Accounts of type "Sole Trader" must provide at least 3 years of continuous address history to proceed`
          )
        );
        return;
      }
    }

    await dispatch(createAccount());

    if (!getIsAccountCreatedState()) return;

    // vodafone direct user creating new account
    // perform side effect to get credit vet status
    if (accountSettings.can_access_vf_direct === "1") {
      dispatch(requestCreditVet());
    }
  }

  if (step === STEP_ACCOUNT) {
    // if account has already been created, we can't access this screen
    if (getIsAccountCreatedState()) {
      return;
    }
  }

  // Delivery details or Account, after customize.... depending on which flow this is.
  if (step === STEP_DELIVERY || step === STEP_ACCOUNT) {
    // Allow only if the user has added products
    if (getDoesOrderNotHaveAnyProducts(state)) {
      dispatch(
        addAlertMessage("You must have at least one product to proceed.")
      );
      return;
    }
    // Run form validation
    dispatch(validateAllConfigProperties());
    // Allow only if product configs are valid
    if (!validateMobileConfigs(getState())) {
      dispatch(
        addAlertMessage(
          "Please correct errors in your mobile product configurations in order to continue"
        )
      );
      return;
    }

    const hardwareCreditError = getHardwareCreditError(getState());
    if (hardwareCreditError) {
      dispatch(addAlertMessage(hardwareCreditError));
      return;
    }

    if (!validateWlrBroadbandConfigs(getState())) {
      dispatch(
        addAlertMessage(
          "There are errors in the data you have entered for Lines, Calls & Broadband configurations."
        )
      );
      return;
    }

    if (!validateEthernetConfigurations(getState())) {
      dispatch(
        addAlertMessage(
          "Please select a quote for each ethernet order to continue."
        )
      );
      return;
    }

    // If there's mobile products, ensure the mobile bill cap checkbox has been ticked. (this all came from the business apparently)
    if (
      mobile.configs.length &&
      !mobile.billCapConfirmed &&
      getBillLimitsNotSet(state) &&
      accountSettings.dws_reseller_enabled !== "1"
    ) {
      dispatch(
        addAlertMessage(
          "Please confirm you have offered a mobile bill cap before proceeding."
        )
      );
      return;
    }

    // Don't allow if mobile contact length has been set to something other than 24, and there's leased hardware on the order.
    if (
      getHardwareCount(state) &&
      mobile.contractLengthInMonthsAllProducts !== 24 &&
      getHasLeasedHardware(state)
    ) {
      dispatch(
        addAlertMessage(
          "You must select a 24 month contract length when your order contains leased hardware."
        )
      );
      return;
    }

    if (
      !state.universalProducts.configs.every(getIsUniversalProductConfigValid)
    ) {
      dispatch(
        addAlertMessage("Please enter prices for all product configurations")
      );
      return;
    }

    if (
      !state.universalProducts.configs.every(
        getDoesUniversalProductHaveDocuments
      )
    ) {
      dispatch(
        addAlertMessage(
          "Warning: You have not attached any document to this order meaning no order breakdown/summary will be attached to this contract.  Please ensure this is uploaded before continuing."
        )
      );
      return;
    }

    // If email address or phone number is entered and not validated, do not proceed.
    const {
      emailAddress,
      phoneNumber
    } = state.monitoringService.alertPreferences;

    if (
      (emailAddress && !isValidEmail(emailAddress)) ||
      (phoneNumber && !isValidMobileNumber(phoneNumber))
    ) {
      return;
    }

    if (step === STEP_DELIVERY) {
      // Perform step 2 -> 3 actions
      // None any more... All on component mounts
    }

    // if there are ethernet configurations in flight then validate them before moving to next step
    // also set a flag that a submission has been attempted (useful for whether to display errors or not)
    {
      let errors = [];

      ethernetProducts.configurations.forEach((config, index) => {
        if (config.type === ETHERNET_PRODUCT_PTP) {
          errors = ethernetPurchaseArgsValidationErrors(state, index);
          dispatch(setEthernetConfigPurchaseArgsSubmitted(index));
        } else {
          errors = ethernetPurchaseArgsValidationErrors(state, index).filter(
            field => !field.startsWith("site_a")
          );
          dispatch(setEthernetConfigPurchaseArgsSubmitted(index));
        }
      });

      if (errors.length > 0) {
        dispatch(
          addAlertMessage(
            "Please correctly complete each Ethernet configuration before continuing."
          )
        );
        return;
      }
    }

    setStep();
  }

  // Step 4 - Summary
  if (step === STEP_SUMMARY) {
    if (!finalStepWarningShown) {
      dispatch(showFinalStepWarning());
      return;
    }
    if (
      order.orderContactId === "" &&
      accountSettings.dws_reseller_enabled !== "1"
    ) {
      dispatch(addAlertMessage("Please select a contact for this order."));
      return;
    }

    if (getHardwareCount(state) || getMobileSIMsRequired(state)) {
      if (
        !isValidEmail(order.trackingDetails) &&
        !isValidMobileNumber(order.trackingDetails)
      ) {
        dispatch(
          addAlertMessage(
            "Tracking details must be a valid email address or mobile number."
          )
        );
        return;
      }

      const getNewAddressFieldsError = () => {
        const newAddress = state.hardwareDelivery.newAddress;
        return Object.keys(newAddress).some(field => {
          const fieldValidation = getNewAddressFieldValidation(
            field,
            newAddress[field]
          );
          return !!fieldValidation.error;
        });
      };

      if (
        hardwareDelivery.addressType === NEW &&
        (!DCAddressIsValid(getDeliveryParams(state)) ||
          getNewAddressFieldsError())
      ) {
        dispatch(addAlertMessage("Delivery details are not valid."));
        return;
      }
    }

    if (hardwareDelivery.addressType === BILLING && !getValidForResign(state)) {
      dispatch(
        addAlertMessage(
          "Please update low quality billing address before proceeding with this order."
        )
      );
      return;
    }

    setStep();

    // TP32603: If the user is a sales person, reserve any relevant appointments
    // here, after account creation and just before ordering of the products.
    // Because the Tenant ID is not known before account creation, the incorrect
    // SI Operator was being used to reserve the appointment. This meant that
    // after account creation, when the order was being placed with the correct
    // SI Operator, it failed.
    if (getIsSalesPerson(state) && wlrBroadband.configurations.length > 0)
      await dispatch(addAppointments());

    await dispatch(addAllProductsToOrder(false));
  }

  // If we're progressing further than step 2, we'll have an order ID to save a draft against.
  if (step !== STEP_CHOOSE && order.id) {
    await dispatch(saveDraftAction());
  }
};

/**
 * Reset the ui state to initial values
 * This is used by the platform to allow for multiple wizard completions in same session
 * @returns {{ type: string }}
 */
export const resetUiState = () => ({
  type: actionTypes.RESET_UI_STATE
});

export const setProductTypes = productTypes => async dispatch => {
  const action = {
    type: actionTypes.SET_PRODUCT_TYPES,
    productTypes
  };

  dispatch(action);
};

export const setActiveSubStepChoose = direction => async (
  dispatch,
  getState
) => {
  const state = getState();
  const {
    mobile,
    universalProducts,
    monitoringService,
    wlrBroadband,
    ethernetProducts,
    uiState: { productTypes, activeSubStepChoose }
  } = state;

  const accountSettings = getAccountSettings(state);

  const subSteps = [ProductTypesEnum.SELECT, ...productTypes];
  const activeSubStep = activeSubStepChoose ?? ProductTypesEnum.SELECT;
  const activeSubStepIndex = subSteps.indexOf(activeSubStep);

  if (direction === SubStepActionEnum.BACK) {
    dispatch({
      type: actionTypes.SET_ACTIVE_SUB_STEP_CHOOSE,
      activeSubStepChoose: subSteps[activeSubStepIndex - 1]
    });
    return;
  }

  if (direction === SubStepActionEnum.CONTINUE) {
    // This is the last subStep, so the next is STEP_CUSTOMISE
    const isLastSubStep = activeSubStepIndex === subSteps.length - 1;

    // Set the next sub step
    const setSubStep = () => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      dispatch({
        type: actionTypes.SET_ACTIVE_SUB_STEP_CHOOSE,
        activeSubStepChoose: subSteps[activeSubStepIndex + 1]
      });
    };
    const setStep = () => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      // if vodafone direct with existing account
      // perform side effect to get credit vet status
      if (
        accountSettings.can_access_vf_direct === "1" &&
        !getState().uiState.steps.includes(STEP_ACCOUNT)
      ) {
        dispatch(requestCreditVet());
      }
      dispatch({ type: actionTypes.SET_ACTIVE_STEP, step: STEP_CUSTOMISE });
    };

    // Don't allow for SoGEA products to proceed if the terms and conditions have not been agreed and user is a reseller.
    if (
      wlrBroadband.locations.find(
        c =>
          (c.type === NEW_SOGEA || c.type === EXISTING_SOGEA) &&
          c.sogeaTermsAccepted === false
      ) &&
      accountSettings.dws_reseller_enabled === "1"
    ) {
      dispatch(
        addAlertMessage(
          "You must agree to the terms and conditions to proceed."
        )
      );
      return;
    }

    const alertMessage = "You must select at least one product to proceed.";
    // Check data for the current sub-step
    if (activeSubStep === ProductTypesEnum.MOBILE) {
      if (mobile.configs.length < 1) {
        dispatch(addAlertMessage(alertMessage));
        return;
      }

      // Dont allow "resigns without change" to progress if DC has failed to give us a resign product.
      if (
        hasResignsWithoutChange(state) &&
        !getResignWithoutChangeProduct(state)
      ) {
        dispatch(
          addAlertMessage(
            "No resign product returned from Daisy Central. Please contact support."
          )
        );
        return;
      }
    }

    if (activeSubStep === ProductTypesEnum.ETHERNET) {
      if (ethernetProducts.configurations.length < 1) {
        dispatch(addAlertMessage(alertMessage));
        return;
      }

      // Validate ethernet config addresses set.
      if (
        ethernetProducts.configurations.find(
          c =>
            !getDCEthernetAddressValid(c.purchaseArgs, "site_b_") ||
            (c.type === ETHERNET_PRODUCT_PTP &&
              !getDCEthernetAddressValid(c.purchaseArgs, "site_a_"))
        )
      ) {
        dispatch(
          addAlertMessage(
            "You must choose valid installation addresses for your ethernet products to proceed."
          )
        );
        return;
      }

      // Check if address a is missing details
      if (
        ethernetProducts.configurations.find(
          c =>
            !_.isEmpty(c.site_a_full_address) &&
            !c.site_a_full_address.thoroughfareNumber &&
            !c.site_a_full_address.premisesName
        )
      ) {
        dispatch(
          addAlertMessage("You must provide address Number or Building name.")
        );
        return;
      }

      // Check if address b is missing details
      if (
        ethernetProducts.configurations.find(
          c =>
            !_.isEmpty(c.site_b_full_address) &&
            !c.site_b_full_address.thoroughfareNumber &&
            !c.site_b_full_address.premisesName
        )
      ) {
        dispatch(
          addAlertMessage("You must provide address Number or Building name.")
        );
        return;
      }

      // Check router selected
      if (
        ethernetProducts.configurations.find(
          c =>
            _.isEmpty(c.selectedRouterId) &&
            (c.type === ETHERNET_PRODUCT_EFM ||
              c.type === ETHERNET_PRODUCT_MANAGED ||
              c.type === ETHERNET_PRODUCT_GEA)
        )
      ) {
        dispatch(addAlertMessage("You must choose a router."));
        return;
      }
    }

    if (activeSubStep === ProductTypesEnum.BROADBAND) {
      // Check if a broadband product has been selected if a location is "Existing Line (Broadband Only)"
      // ...people were mistakenly using that pathway to try and "WLR Change" lines to add new call bundles etc.
      // ...which is impossible as WLR Change relies on having a broadband component to communicate changes to DC
      if (
        wlrBroadband.configurations.find(
          c =>
            wlrBroadband.locations[c.locationIndex]?.type === BROADBAND_ONLY &&
            !c.broadbandProductId
        )
      ) {
        dispatch(
          addAlertMessage("You must select a broadband product to continue.")
        );
        return;
      }

      if (wlrBroadband.configurations.length < 1) {
        dispatch(addAlertMessage(alertMessage));
        return;
      }

      // If WLR or broadband products have been added, check that no additional, empty locations exist.
      // See: https://auroratarget.tpondemand.com/entity/7479-166437-affinitysales-allowing-users-to-proceed.
      const unusedLocations = wlrBroadband.locations.filter(
        (location, index) => {
          const locationHasConfig = wlrBroadband.configurations.find(
            config => config.locationIndex === index
          );
          return !locationHasConfig;
        }
      );
      if (wlrBroadband.locations.length > 1 && unusedLocations.length) {
        dispatch(
          addAlertMessage(
            "You have added a location and not selected a product. Please select a product or remove the location to continue."
          )
        );
        return;
      }

      // MIC / Bionic customers can't place orders on existing Daisy lines.
      // https://gitlab.com/akj-dev/inbox/issues/628
      if (
        accountSettings.as_stop_daisy_line_orders === "1" &&
        getDaisyOwnedLineSelected(state)
      ) {
        dispatch(
          addAlertMessage(
            "Sorry, you have selected an existing Daisy line. You may not proceed with this order."
          )
        );
        return;
      }
    }

    if (activeSubStep === ProductTypesEnum.HARDWARE) {
      if (getHardwareCount(state) < 1) {
        dispatch(addAlertMessage(alertMessage));
        return;
      }
    }

    if (activeSubStep === ProductTypesEnum.UNIVERSAL) {
      if (universalProducts.configs.length < 1) {
        dispatch(addAlertMessage(alertMessage));
        return;
      }
    }

    if (activeSubStep === ProductTypesEnum.MONITORING) {
      if (monitoringService.length < 1) {
        dispatch(addAlertMessage(alertMessage));
        return;
      }
    }

    // Continue on next step/subStep
    if (isLastSubStep) {
      setStep();
    } else {
      setSubStep();
    }
  }
};

export const setHardwareConfig = newHardwareConfig => async (
  dispatch,
  getState
) => {
  const state = getState();
  const oldHardwareConfig = state.uiState.chooseHardwareConfig;
  const chooseHardwareConfig = { ...oldHardwareConfig, ...newHardwareConfig };

  dispatch({ type: actionTypes.SET_HARDWARE_CONFIG, chooseHardwareConfig });
};

export const setShowMobileResigns = () => async dispatch => {
  dispatch({ type: actionTypes.SET_SHOW_MOBILE_RESIGNS });
};

export const setShowWlrResigns = () => async dispatch => {
  dispatch({ type: actionTypes.SET_SHOW_WLR_RESIGNS });
};
