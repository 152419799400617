import { createSelector } from "reselect";
import _ from "lodash";
import {
  NEW_SIM,
  PRE_DISPATCHED_SIM,
  RESIGN_WITHOUT_CHANGES,
  STAC
} from "../constants";
import { addDays, getHours, getDay } from "date-fns";
import { addWeekdays } from "../../../helpers/date";

/**
 * Get CLI level mobile bolt-ons of a certain type, available for a config.
 * @returns {Reselect.Selector<any, any>}
 */
export const makeGetAvailableCliBoltOnsByType = () =>
  createSelector(
    [
      state => state.mobile.cliBoltOnSearch,
      (state, props) => state.mobile.configs[props.configId].productId,
      (state, props) => props.boltOnType,
      (state, props) => getProductDataForConfig(state, props.configId)
    ],
    (boltOns, productId, boltOnType, productData) => {
      return boltOns[productId].response.products.filter(product => {
        const component = product.first_mobile_bolt_on_component;
        // Filter out all top up bolt ons as we are showing these in a different select.
        // See: https://auroratarget.tpondemand.com/entity/6400-data-top-ups.
        if (
          component.bolt_on_type === "data" &&
          component.free_data_type === "4g_data_uk"
        )
          return false;
      });
    }
  );

/**
 * Get CLI level mobile bolt-ons for a given slot index and config.
 * @returns {Reselect.Selector<any, any>}
 */
export const makeGetAvailableCliBoltOnsBySlot = () =>
  createSelector(
    [
      state => state.mobile.cliBoltOnSearch,
      (state, props) => state.mobile.configs[props.configId],
      (state, props) => props.boltOnType
    ],
    (boltOns, config, slotIndex) => {
      // get all the mobile boltons provided by the API for the parent product
      const cliBoltOns = boltOns[config.productId].response.products;

      // get the identifiers of boltons already selected by other slots for this config
      const selectedBoltOnIds = Object.entries(config.selectedCliBoltOns || {})
        .filter(([type, id]) => type !== slotIndex) // exclude boltons selected by this slot
        .map(([type, id]) => id);

      // return symetric difference of cli boltons from API and those selected on other slots
      return cliBoltOns.filter(
        boltOn =>
          !selectedBoltOnIds.includes(
            boltOn.first_mobile_bolt_on_component.product_id
          )
      );
    }
  );

/**
 * Get CLI level top up mobile bolt-ons available for a config.
 * @returns {Reselect.Selector<any, any>}
 */
export const makeGetAvailableCliTopUpBoltOns = () =>
  createSelector(
    [
      state => state.mobile.cliBoltOnSearch,
      (state, props) => state.mobile.configs[props.configId].productId
    ],
    (boltOns, productId) => {
      return boltOns[productId].response.products.filter(product => {
        const component = product.first_mobile_bolt_on_component;
        // Filter out all non top up bolt ons as we are showing these in a different select.
        // See: https://auroratarget.tpondemand.com/entity/6400-data-top-ups.
        return (
          component.bolt_on_type === "data" &&
          component.free_data_type === "4g_data_uk"
        );
      });
    }
  );

/**
 * Get the selected CLI level mobile bolt-on of a certain type on a specific config.
 * @returns {Reselect.Selector<any, any>}
 */
export const makeGetSelectedCliBoltOn = () =>
  createSelector(
    [
      (state, props) => props.configId,
      (state, props) => state.mobile.configs[props.configId].selectedCliBoltOns,
      (state, props) => props.boltOnType
    ],
    (configId, selectedCliBoltOns, boltOnType) => {
      return _.get(selectedCliBoltOns, boltOnType, 0);
    }
  );

/**
 * Get product data for a specified config
 * @param state
 * @param configId
 * @returns {*}
 */

export const getProductDataForConfig = (state, configId) => {
  const { productId } = state.mobile.configs[configId];
  if (!productId) return false;
  return _.get(state.mobile.productData, [productId, "response"], {});
};

/**
 * Get a configuration's selected CLI bolt-on of a specified type
 * Each config can have one bolt on for each type (voice, data etc.)
 * @param configIndex
 * @param type
 * @param mobileState
 * @returns {*}
 */
export const getCliBoltOnByType = (configIndex, type, mobileState) => {
  const config = mobileState.configs[configIndex];
  const { productId } = config;
  if (productId) {
    return _.get(
      mobileState.cliBoltOnSearch[productId],
      "response.products",
      []
    ).find(p => p.id === _.get(config, `selectedCliBoltOns.${type}`));
  }
};

export const getValidationErrors = (configIndex, state) => {
  const config = state.mobile.configs[configIndex];
  const array =
    config && config.validation ? Object.entries(config.validation) : [];
  // Filter out false values.
  const filteredArray = array.filter(item => item[1]);
  return filteredArray;
};

/**
 * Validate a single config.
 * @param configIndex
 * @param state
 * @returns {boolean}
 */
export const getIsConfigValid = (configIndex, state) => {
  const config = state.mobile.configs[configIndex];
  const { resignId, properties = {}, validation = {} } = config;
  const resign = resignId
    ? state.mobile.productInstances.response.results.find(
        r => r.id === resignId
      )
    : {};

  // Check validation messages in store (TODO: perhaps move everything to store validation so we can show errors contextually)
  for (let field in validation) {
    if (validation[field]) return false;
  }

  // Note: This should be auto-filled. ...but just in case...
  if (properties.acquisition_method === "resign" && !properties.port_date)
    return false;

  if (
    properties.sim_is_buffer == 1 && // eslint-disable-line eqeqeq
    !(_.get(config, "simValidCheck.response.is_valid_sim_number") == 1) // eslint-disable-line eqeqeq
  )
    return false;

  // Internal port only needs a PAC if Moving from Dise.  Not 02-voda or Voda - O2. - @lisa
  if (
    properties.acquisition_method === "internal_port" &&
    _.get(resign, "network.name") === "Dise" &&
    !properties.pac_expiry_date
  )
    return false;

  return true;
};

/**
 * Validate all configs.
 * Used to check going from step 2 to 3
 *
 * @param state
 * @returns {boolean}
 */
export const validateMobileConfigs = state => {
  if (state.mobile.daisyFreshAmounts.limitExceeded) return false;
  for (let i = 0; i < state.mobile.configs.length; i++) {
    if (!getIsConfigValid(i, state)) return false;
  }
  return true;
};

/**
 * Check if bill limits haven't been set on any applicable mobile config.
 *
 * TODO: The _.get shouldn't be necessary - there currently to stop things blowing up when users select a resign with change, don't select a product and try to proceed. Should fix underlying logic.
 * @param state
 * @returns {boolean}
 */
export const getBillLimitsNotSet = state =>
  !!state.mobile.configs.find(
    c =>
      c.resignType !== RESIGN_WITHOUT_CHANGES &&
      !_.get(c, "properties.bill_limit")
  );

/**
 * Decide if a mobile config will be a migration
 * True if the new product supplier matches the one from the PAC code response
 * @param state (full)
 * @param configId
 * @returns {boolean}
 */
export const isMigration = (state, configId) => {
  const config = state.mobile.configs[configId];
  const { supplier } = getProductComponentData(state, configId);
  return supplier === _.get(config, "pacCodeCheck.response.result.no_name");
};

/**
 * Users can keep their existing SIM cards if the PAC code doesn't contain BRA or VUK apparently.
 * @param config
 * @returns {boolean}
 */
export const canKeepSim = config =>
  !(config.properties.pac || "").match("(BRA|VUK)");

/**
 * Get merged product data.
 * From the per config stuff used for pricing and the table of non-altered data
 * fetched at the start of step 2
 * @returns {Reselect.Selector<any, any>}
 */
export const makeGetMergedProductData = () =>
  createSelector(
    [
      (state, props) => state.mobile.configs[props.targetConfigs[0]],
      (state, props) =>
        state.mobile.productData[
          state.mobile.configs[props.targetConfigs[0]].productId
        ]
    ],
    (config, productData) => {
      let mergedProductData = {};
      _.merge(mergedProductData, productData, config.productData);
      return mergedProductData;
    }
  );

/**
 * Get mobile config properties
 * @param state
 * @param configId
 * @returns {*}
 */
export const getConfigProperties = (state, configId) =>
  state.mobile.configs[configId].properties;

/**
 * Get mobile product component data
 * @param state
 * @param configId
 * @returns {*}
 */
export const getProductComponentData = (state, configId) => {
  const config = state.mobile.configs[configId];
  return state.mobile.productData[config.productId].response.mobile
    .product_component_data;
};

/**
 * Get the soonest date a port can be made
 *
 * For STAC and pre-dispatched SIM:
 * before 4pm +1 day, after 4pm + 2 days
 *
 * For new SIM cards:
 * + 3 days
 *
 * No Fridays, no weekends for all ports.
 * Fridays are counted in the lead time, but ports can't happen on them
 * eg. If Thursday before 4pm, then can happen on Monday
 * If Friday before 4pm, then can also happen on Monday
 *
 * @see src/js/containers/step2/Mobile/ConfigurationForm/sections/Connection/index.js
 * @param portType
 */
export const getMinimumPortDate = portType => {
  const today = new Date();
  let daysToAdd = 0;

  switch (portType) {
    case STAC:
    case PRE_DISPATCHED_SIM:
      if (getHours(today) >= 16) {
        daysToAdd += 2;
      } else {
        daysToAdd += 1;
      }
      break;
    case NEW_SIM:
    default:
      daysToAdd += 2;
  }

  let portDate = addWeekdays(today, daysToAdd, false);
  if (getDay(portDate) === 5) portDate = addDays(portDate, 3);
  return portDate;
};

/**
 * Configs filtered by airtime_credit_amount > 0
 * @param state
 * @returns {*}
 */
export const getConfigsWithMonthlyAirtimeCredit = createSelector(
  [state => state.mobile.configs],
  configs =>
    configs.filter(
      config => Number(config?.properties?.airtime_credit_amount) > 0
    )
);

/**
 * Configs filtered by airtime_credit_amount_oneoff > 0
 * @param state
 * @returns {*}
 */
export const getConfigsWithOneOffAirtimeCredit = createSelector(
  [state => state.mobile.configs],
  configs =>
    configs.filter(
      config => Number(config?.properties?.airtime_credit_amount_oneoff) > 0
    )
);
