import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@material-ui/core";
import * as actionsToBind from "../../../../../store/supportCase/actions";

export const SupportCase = () => {
  const dispatch = useDispatch();
  const supportCase = useSelector<any, any>(state => state.supportCase);
  const open = !!supportCase?.open;
  const loading = !!supportCase?.loading;

  const actions = bindActionCreators(actionsToBind, dispatch);

  if (!supportCase.open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={actions.close} fullWidth maxWidth="sm">
      <DialogTitle>Create New Support Case</DialogTitle>
      <DialogContent>
        <TextField
          name="short_description"
          autoFocus={true}
          fullWidth={true}
          onChange={event => actions.onChange(event)}
          label="Issue Title *"
          value={supportCase.short_description}
          error={supportCase.invalid && !supportCase.short_description}
          helperText={
            supportCase.invalid &&
            !supportCase.short_description &&
            "This field is required."
          }
          disabled={loading}
        />

        <TextField
          name="description"
          fullWidth={true}
          onChange={event => actions.onChange(event)}
          required={true}
          label="Issue Description *"
          value={supportCase.description}
          multiline={true}
          rows={4}
          error={supportCase.invalid && !supportCase.description}
          helperText={
            supportCase.invalid && !supportCase.description
              ? "This field is required."
              : "Full description, including steps to reproduce issue"
          }
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.close} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={actions.create}
          disabled={loading}
          endIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          Submit Case
        </Button>
      </DialogActions>
    </Dialog>
  );
};
