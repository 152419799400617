import React from "react";
import { useDispatch } from "react-redux";
import { StatusChip } from "@akj-dev/design-system";
import {
  makeStyles,
  FormControlLabel,
  Switch,
  MenuItem,
  Theme
} from "@material-ui/core";
import { updateEthernetConfiguration } from "../../../../store/ethernetProducts/actionsCreators";
import { ProductSelect } from "../../../shared/ProductSelect";
import { ReduxEthernetConfiguration } from "../types";

export interface EthernetConfigurationFailOverProps {
  configIndex: number;
  configuration: ReduxEthernetConfiguration;
}

export const EthernetConfigurationFailOver = ({
  configIndex,
  configuration
}: EthernetConfigurationFailOverProps) => {
  const classes = useStyles();

  const {
    backup_line,
    backup_broadband,
    backup_broadband_technology
  } = configuration;

  const dispatch = useDispatch();

  return (
    <div>
      <div className={classes.toggleGroup}>
        <FormControlLabel
          control={
            <Switch
              data-cy={"ethernet-backup-line"}
              onChange={event =>
                dispatch(
                  updateEthernetConfiguration(
                    configIndex,
                    "backup_line",
                    event.target.checked
                  )
                )
              }
              checked={backup_line}
            />
          }
          label="Backup Line"
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Switch
              data-cy={"ethernet-backup-broadband"}
              onChange={event =>
                dispatch(
                  updateEthernetConfiguration(
                    configIndex,
                    "backup_broadband",
                    event.target.checked
                  )
                )
              }
              checked={backup_broadband}
            />
          }
          label="Backup Broadband"
          labelPlacement="end"
        />
      </div>
      <ProductSelect
        label={"Technology"}
        dataCy={"ethernet-fail-over"}
        value={backup_broadband_technology}
        handleChange={newTechnology =>
          dispatch(
            updateEthernetConfiguration(
              configIndex,
              "backup_broadband_technology",
              newTechnology
            )
          )
        }
      >
        <MenuItem value="Fibre">Fibre</MenuItem>
        <MenuItem value="ADSL">ADSL</MenuItem>
      </ProductSelect>
      {backup_broadband && (
        <StatusChip
          type="info"
          message={`Quote prices will include ${backup_broadband_technology} Failover`}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  toggleGroup: {
    paddingBottom: theme.spacing(1.5)
  }
}));
