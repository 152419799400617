import React from "react";
import TextField from "@material-ui/core/TextField";

/**
 * This lets us update redux state only when the TextField is blurred, by storing
 * intermediate values in local component state.
 * Standard behaviour is onChange, but the store is so large this is becomes laggy.
 * onChange also creates problems when async calls are fired. Stale calls returning
 * before current ones create state errors.
 * (the latter would probably be solved with Redux Saga, but that's a big job)
 *
 * ...also with validation... which makes it a bit messy...
 */
class TextFieldLowUpdate extends React.Component {
  state = {
    value: this.props.value,
    errorText: this.props.validate
      ? this.props.validate(this.props.value)
      : false
  };

  render() {
    const { onBlur, validate } = this.props;
    return (
      <TextField
        {...this.props}
        validate={undefined}
        value={this.state.value}
        onChange={event => {
          const errorText = validate ? validate(event.target.value) : false;
          this.setState({ value: event.target.value, errorText });
        }}
        onBlur={onBlur}
        helperText={this.state.errorText}
        error={this.state.errorText}
        onKeyDown={event => {
          if (
            this.props.type === "number" &&
            ["e", "E", "+", "-"].includes(event.key)
          )
            event.preventDefault();
        }}
      />
    );
  }
}

export default TextFieldLowUpdate;
