import React, { useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  max: {
    color: theme.palette.success.main
  }
}));

function SliderLowUpdate({ value, onDragStop, max, ...rest }) {
  const classes = useStyles();
  const [intValue, setIntValue] = React.useState(0);
  useEffect(() => setIntValue(value), [value]);

  return (
    <Box my={2}>
      <Slider
        {...rest}
        value={intValue}
        getAriaValueText={value => `${value}%`}
        valueLabelDisplay="on"
        onChange={(event, value) => setIntValue(value)}
        onChangeCommitted={(event, value) => onDragStop(value)}
        className={
          intValue == max // eslint-disable-line eqeqeq
            ? classes.max
            : ""
        }
      />
    </Box>
  );
}

export default SliderLowUpdate;
