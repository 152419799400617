import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import FileIcon from "./FileIcon";
import Checkbox from "@material-ui/core/Checkbox";
import { filetype } from "../../../../helpers/files";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function UserDocumentChip({ file, sendPref, removeAction, sendPrefAction }) {
  const isPDF = filetype(file.name) === ".pdf";
  return (
    <Outer>
      <Container>
        <IconOuter>
          <FileIcon filename={file.name} />
        </IconOuter>
        {file.name}
      </Container>
      <Actions>
        <FormControlLabel
          label={`Send with contract${isPDF ? "" : " - PDF only"}`}
          control={
            <Checkbox
              checked={sendPref || false}
              disabled={!isPDF}
              onChange={(_, checked) => sendPrefAction(checked)}
            />
          }
        />
        <IconButton onClick={removeAction}>
          <DeleteIcon />
        </IconButton>
      </Actions>
    </Outer>
  );
}

UserDocumentChip.propTypes = {
  file: PropTypes.object.isRequired,
  sendPref: PropTypes.bool,
  removeAction: PropTypes.func.isRequired,
  sendPrefAction: PropTypes.func.isRequired
};

const Outer = styled(Paper)`
  margin-top: 16px;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
`;
const IconOuter = styled.div`
  width: 70px;
  padding: 10px;
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  background: ${p => p.theme.palette.grey[50]};
  padding: 10px;
`;

export default UserDocumentChip;
