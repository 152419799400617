import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleStepOneMonitoringServiceProducts } from "../../../store/uiState/actions";
import Products from "./Products";

const MonitoringServiceProducts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleStepOneMonitoringServiceProducts());
  }, [dispatch]);

  return <Products />;
};

export default MonitoringServiceProducts;
