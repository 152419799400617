import React, { useReducer } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NewProspect } from "./NewProspect";
import { ExistingProspects } from "./ExistingProspects";

const useStyles = makeStyles(theme => ({
  saleType: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  search: {
    marginBottom: theme.spacing(1)
  },
  results: {
    td: {
      cursor: "pointer"
    }
  }
}));

/**
 * New Sale Screen
 * This was StepInit when part of affinity-sales package but....
 *
 * TODO: Restructure this in line with Tom H's new mocks http://8q7k3e.axshare.com/
 * It should be "customers". Do this after merging cpq split branch
 */
export const StepInit = () => {
  const classes = useStyles();
  const [newFormOpen, toggleNewFormOpen] = useReducer(open => !open, false);

  return (
    <Box mt={2}>
      <Typography variant="h2" gutterBottom>
        New Sale
      </Typography>
      <Button
        variant={newFormOpen ? "contained" : "outlined"}
        color="primary"
        className={classes.saleType}
        onClick={toggleNewFormOpen}
      >
        New Prospect
      </Button>
      {newFormOpen && <NewProspect />}
      <ExistingProspects />
    </Box>
  );
};
