import {
  FETCH_SHIPPING_METHODS,
  RECEIVE_SHIPPING_METHODS,
  RECEIVE_UPDATING_SHIPPING_METHOD_IN_API,
  UPDATING_SHIPPING_METHOD_IN_STATE
} from "../../helpers/constants";
import * as ProductAPI from "../../api/v1/product";
import { addAlertMessage } from "../uiState/actions";
import { getAccountId } from "../order/selectors";

export function updateShippingMethodInState(id) {
  return {
    type: UPDATING_SHIPPING_METHOD_IN_STATE,
    id
  };
}

export function fetchShippingMethods() {
  return {
    type: FETCH_SHIPPING_METHODS
  };
}

export function receiveShippingMethods(response) {
  return {
    type: RECEIVE_SHIPPING_METHODS,
    products: response
  };
}

export const getShippingMethods = () => async (dispatch, getState) => {
  // we already have shipping methods
  if (getState().shipping.products.length) {
    return;
  }

  dispatch(fetchShippingMethods());

  try {
    const data = await ProductAPI.shippingSearch(getAccountId(getState()));
    dispatch(receiveShippingMethods(data.products));
  } catch (e) {
    dispatch(addAlertMessage(e));
    dispatch(receiveShippingMethods([]));
  }
};

export function receiveUpdatedShippingMethodInApi(response) {
  return {
    type: RECEIVE_UPDATING_SHIPPING_METHOD_IN_API,
    response
  };
}
