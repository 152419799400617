import React from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ReportIcon from "@material-ui/icons/Report";
import { SalesStatusDataTable } from "./shared/components/SalesStatus/SalesStatusDataTable";
import { createRoutingObject } from "@akj-dev/affinity-suite";
import { AccountSettings } from "@akj-dev/affinity-platform";
import { Dashboard } from "./screens/dashboard";
import { NewSale } from "./screens/NewSale";

export const getRoutes = (settings: AccountSettings) =>
  createRoutingObject(
    [
      {
        path: "/",
        title: "Dashboard",
        subject: <Dashboard />,
        icon: <DashboardIcon color="inherit" fontSize="small" />
      },
      {
        path: "/new",
        title: "New Sale",
        subject: <NewSale />,
        icon: <AddCircleOutlineIcon color="inherit" fontSize="small" />
      },
      {
        path: "/status",
        title: "Sales Status",
        subject: [
          ...(settings.dws_reseller_enabled === "0"
            ? [
                {
                  path: "/prospects",
                  title: "Prospects",
                  subject: (
                    <SalesStatusDataTable
                      status="lead"
                      title="Prospects"
                      description="A complete list of your prospective customers"
                    />
                  )
                }
              ]
            : []),
          {
            path: "/opportunities",
            title: "Opportunities",
            subject: (
              <SalesStatusDataTable
                status="draft"
                title="Opportunities"
                description="Open quotes for your prospective and existing customers"
              />
            )
          },
          ...(settings.requires_external_approval_for_external_user_orders ===
          "1"
            ? [
                {
                  path: "/approvals",
                  title: "Approvals",
                  subject: (
                    <SalesStatusDataTable
                      status="approval"
                      title="Approvals"
                      description="Quotes requiring approval"
                    />
                  )
                }
              ]
            : []),
          ...(settings.as_supplier_approvals_enabled === "1"
            ? [
                {
                  path: "/supplier-approvals",
                  title: "Supplier Approvals",
                  subject: (
                    <SalesStatusDataTable
                      status="internal_approval"
                      title="Supplier Approvals"
                      description="Quotes requiring supplier approval"
                    />
                  )
                }
              ]
            : []),
          {
            path: "/sent",
            title: "Sent",
            subject: (
              <SalesStatusDataTable
                status="sent"
                title="Sent"
                description="Contracts that have been sent out"
              />
            )
          },
          {
            path: "/viewed",
            title: "Viewed",
            subject: (
              <SalesStatusDataTable
                status="viewed"
                title="Viewed"
                description="Contracts viewed but not yet signed"
              />
            )
          },
          {
            path: "/completed",
            title: "Completed",
            subject: (
              <SalesStatusDataTable
                status="completed"
                title="Completed"
                description="All signed contracts - yay!"
              />
            )
          },
          {
            path: "/expired",
            title: "Expired",
            subject: (
              <SalesStatusDataTable
                status="expired"
                title="Expired"
                description="Unsigned contracts which have now expired (sent 30+ days)"
              />
            )
          },
          {
            path: "/declined",
            title: "Declined",
            subject: (
              <SalesStatusDataTable
                status="declined"
                title="Declined"
                description="Contracts that have been declined by the customer"
              />
            )
          },
          {
            path: "/cancelled",
            title: "Deleted",
            subject: (
              <SalesStatusDataTable
                status="cancelled"
                title="Deleted"
                description="Quotes that have been deleted"
              />
            )
          }
        ],
        icon: <ReportIcon color="inherit" fontSize="small" />
      }
    ],
    "/sales"
  );
