import { getHardwareCount } from "../hardware/selectors";
import _ from "lodash";
import { newAccountValidations } from "./validation";
import { createSelector } from "reselect";
import { getHostEnv } from "../order/selectors/getHostEnv";

export const isMobileOnly = state =>
  state.mobile.configs.length > 0 &&
  getHardwareCount(state) < 1 &&
  state.wlrBroadband.configurations.length < 1 &&
  state.ethernetProducts.configurations.length < 1;

/**
 * Get the "Valid for Resign" flag from the billing address response.
 * This indicates low quality addresses in general.
 *
 * @param state
 * @returns {*}
 */
export const getValidForResign = state =>
  _.get(state.account.billingAddress.response, "data.valid_for_resign");

/**
 * Get if a new account form field is required.
 * Used to display "*" on the field component.
 *
 * If a function's needed to decide that, run it.
 * If not, return the boolean value
 * @param state
 * @param ownProps
 * @returns {*}
 */
export const getNewAccountFieldRequired = (state, ownProps) => {
  const validation = newAccountValidations[ownProps.name];
  if (!validation) return false;
  const required = validation.required;
  if (typeof required === "function") {
    return required(state.account);
  } else {
    return required;
  }
};

/**
 * Get field validation message for required or non-required params. False if valid.
 * Used in the reducer.
 *
 * If a validator is detected, run a specified validation fn, such as a regex.
 * If it's not required and a value doesn't exist, always return false.
 *
 * 24/06/2020: Changed to optionally validate if a value is entered as part of
 * https://auroratarget.tpondemand.com/entity/5486-bug-affinity-sales-missing-validation-on.
 *
 * @param accountState
 * @param fieldId
 * @returns {string | boolean}
 */
export const getFieldValidationMessage = (accountState, fieldId) => {
  const validation = newAccountValidations[fieldId];
  if (!validation) return false;
  const fieldValue = accountState.newAccountFields[fieldId];
  const required =
    typeof validation.required === "function"
      ? validation.required(accountState)
      : validation.required;
  const valid =
    typeof validation.validator === "function"
      ? validation.validator(fieldValue)
      : !!fieldValue;
  return (!required && !fieldValue) || valid ? false : validation.message;
};

/**
 * Check if there's any validation messages against new account creation fields
 * @param state
 * @returns {boolean}
 */
export const getNewAccountFieldsValid = state => {
  const { newAccountFieldsValidation } = state.account;
  for (let name in newAccountFieldsValidation) {
    if (newAccountFieldsValidation[name]) return false;
  }
  return true;
};

/**
 * True if contacts have been fetched / exist on the current account
 * @param state
 * @returns {boolean}
 */
export const getHasContacts = state =>
  _.get(state.account.contacts.response, "data.contacts", []).length > 0;

/**
 * Get account settings (data from DC or defaulted from config/accountSettings.default.js
 * @param state
 * @returns {*}
 */
export const getAccountSettings = state =>
  _.get(state.account, "settings.response.settings", {});

/**
 * Get selected contact for the order
 * @type {OutputSelector<unknown, *, (res1: *, res2: *) => *>}
 */
export const getOrderContact = createSelector(
  [state => state.account.contacts, state => state.generateContract.contactId],
  (contacts, contactId) => {
    return contacts.response.data?.contacts.find(
      contact => contact.id === contactId
    );
  }
);

/**
 * Get account meta
 * @param state
 * @returns {*}
 */
export const getAccountMeta = state =>
  _.get(state.account, "settings.response.meta", {});

/**
 * Check if the user is a sales person
 */
export const getIsSalesPerson = createSelector([state => state], state => {
  const meta = getAccountMeta(state);
  const hostEnv = getHostEnv(state);
  return hostEnv === "platform" && meta?.type === "sales_person";
});

/**
 * Determine whether we need to capture residential status from the user to create an account
 * @param state
 * @returns {boolean}
 */
export const getIsVfDirectConsumerAccount = createSelector(
  [
    state => state,
    state => state.account.newAccountFields.business_entity_type
  ],
  (state, businessEntityType) => {
    const { can_access_vf_direct } = getAccountSettings(state);
    return (
      can_access_vf_direct === "1" &&
      businessEntityType === "Sole Trader / Owner Manager"
    );
  }
);

/**
 * Determine whether we need to capture a second company address from the user to create an account
 * @param state
 * @returns {boolean}
 */
export const shouldCollectCompanyAddress2 = createSelector(
  [state => state.account.newAccountFields, getIsVfDirectConsumerAccount],
  ({ company_months_at_address }, isVfDirectConsumerAccount) =>
    isVfDirectConsumerAccount && parseInt(company_months_at_address) < 36
);

/**
 * Determine whether we need to capture a third company address from the user to create an account
 * @param state
 * @returns {boolean}
 */
export const shouldCollectCompanyAddress3 = createSelector(
  [state => state.account.newAccountFields, getIsVfDirectConsumerAccount],
  (
    { company_months_at_address, company_months_at_address2 },
    isVfDirectConsumerAccount
  ) =>
    isVfDirectConsumerAccount &&
    parseInt(company_months_at_address) + parseInt(company_months_at_address2) <
      36
);

export const isCreditVetProcessAttempted = createSelector(
  [state => state.account.creditVet],
  creditVet => {
    if (!creditVet) {
      // guard against an old version of redux state from LoadWizardState API
      return false;
    }
    return Object.keys(creditVet.response).length > 0 || creditVet.hasError;
  }
);
