import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { StatusChip } from "@akj-dev/design-system";
import { useDispatch, useSelector } from "react-redux";
import PhoneIcon from "@material-ui/icons/Phone";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {
  getLineAvailabilityForLocation,
  getResignProductForProductInstanceServiceType,
  getTagsCheckForLocation
} from "../../../../store/wlrBroadband/selectors";
import {
  getLineAvailabilityForResign,
  doBroadbandSearchForResign,
  setWlrResignProduct,
  doUpdateProductInstance
} from "../../../../store/wlrBroadband/actions";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES
} from "../../../../store/wlrBroadband/constants";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { ChooseAddressDialog } from "../../../../components/Shared/AddressPicker/ChooseAddressDialog";
import NoAddressError from "../../../../components/Step1/wlrBroadband/Resigns/NoAddressError";
import { hasLocationAddress } from "../../../../helpers/addresses";
import { LineProductSelectorStatusRow } from "./LineProductSelectorStatusRow";

interface Props {
  configuration: any;
  productInstance: any;
}

export const LineProductSelector = ({
  configuration,
  productInstance
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // setup variables for selectors
  const locationIndex = configuration.locationIndex;
  const configurations = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );
  const configurationIndex = configurations.indexOf(configuration);

  // selectors
  const location = useSelector(
    (state: any) => state.wlrBroadband.locations[locationIndex]
  );
  const lineAvailability = useSelector((state: any) =>
    getLineAvailabilityForLocation(state, { configuration })
  );
  const products = useSelector(
    (state: any) => state.wlrBroadband.lineSearch.response.products
  );
  const resignProduct = useSelector((state: any) =>
    getResignProductForProductInstanceServiceType(state, productInstance)
  );
  const tagsCheck = useSelector((state: any) =>
    getTagsCheckForLocation(state, { configuration })
  );

  // other setup
  const { updateProductInstance } = configuration;

  const setResignProduct = (
    productInstance: any,
    resignProduct: any,
    resignProductType: any
  ) => {
    dispatch(
      setWlrResignProduct(productInstance, resignProduct.id, resignProductType)
    );
  };

  if (tagsCheck.fetching) {
    return (
      <LineProductSelectorStatusRow
        type="loading"
        title="Please wait"
        message="Checking Tags"
      />
    );
  }
  if (tagsCheck.response.status === "error") {
    return (
      <LineProductSelectorStatusRow
        type="error"
        title="Error"
        message={tagsCheck.response.message}
      />
    );
  }
  if (updateProductInstance.fetching) {
    return (
      <LineProductSelectorStatusRow
        type="loading"
        title="Please wait"
        message="Updating Product Instance"
      />
    );
  }

  if (updateProductInstance.response.status === "error") {
    return (
      <>
        <TableRow>
          <TableCell colSpan={9}>
            <StatusChip
              type="error"
              title="Error"
              message={updateProductInstance.response.message}
            />
          </TableCell>
        </TableRow>
      </>
    );
  }

  // This field is required to be able to select an address.
  const hasPostcode = hasLocationAddress({ location, isPostcodeOnly: true });
  // These are the fields required to be able to do a line availability and broadband search.
  if (!hasPostcode) {
    return <NoAddressError />;
  }
  if (hasPostcode && !hasLocationAddress({ location })) {
    const { address } = location;
    return (
      <>
        <TableRow>
          <TableCell colSpan={9}>
            <StatusChip
              type="error"
              title="Error"
              message="This product instance doesn't have a full address. Please select an address to continue with
              the resign process."
              link={
                <div
                  className={classes.action}
                  onClick={() => setIsDialogOpen(true)}
                >
                  Select an address
                </div>
              }
            />
          </TableCell>
        </TableRow>
        <ChooseAddressDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          postcode={address.postcode}
          setAddress={address => {
            dispatch(
              doUpdateProductInstance(
                productInstance.id,
                productInstance.pin,
                configurationIndex,
                address
              )
            );
            if (configuration.resignProductType === NEW_PRODUCT) {
              dispatch(getLineAvailabilityForResign(productInstance));
              dispatch(doBroadbandSearchForResign(productInstance));
            }
          }}
        />
      </>
    );
  }
  return (
    <TableRow>
      <TableCell colSpan={9}>
        <div className={classes.outer}>
          <div style={{ flex: 1 }}>
            <div className={classes.headerCtr}>
              <AutorenewIcon style={{ color: "#fff", marginRight: 10 }} />
              <b style={{ color: "#fff" }}>Select product</b>
            </div>
            <Table style={{ backgroundColor: "#fff" }}>
              <TableBody>
                {resignProduct &&
                  configuration.resignProductType ===
                    SAME_PRODUCT_WITH_CHANGES && (
                    <TableRow data-cy="wlrBroadbandResignLineSameRow">
                      <TableCell>
                        <div className={classes.productCtr}>
                          <PhoneIcon
                            style={{
                              color: theme.palette.grey[500],
                              marginRight: 10
                            }}
                          />
                          <div style={{ fontWeight: "bold" }}>
                            {resignProduct.name}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>{resignProduct.description}</TableCell>
                      <TableCell>
                        {
                          resignProduct.price
                            .first_bill_recurring_price_with_promotions
                        }
                      </TableCell>
                      <TableCell>
                        <div style={{ float: "right" }}>
                          <Checkbox
                            onChange={() =>
                              setResignProduct(
                                productInstance,
                                resignProduct,
                                SAME_PRODUCT_WITH_CHANGES
                              )
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                {configuration.resignProductType === NEW_PRODUCT &&
                  lineAvailability.response.status === "success" &&
                  products &&
                  products.length > 0 &&
                  products.map((product: any) => {
                    return (
                      <TableRow
                        key={product.id}
                        data-cy="wlrBroadbandResignLineNewRow"
                      >
                        <TableCell>
                          <div className={classes.productCtr}>
                            <PhoneIcon
                              style={{
                                color: theme.palette.grey[500],
                                marginRight: 10
                              }}
                            />
                            <div style={{ fontWeight: "bold" }}>
                              {product.name}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>{product.description}</TableCell>
                        <TableCell>
                          {
                            product.price
                              .first_bill_recurring_price_with_promotions
                          }
                        </TableCell>
                        <TableCell>
                          <div style={{ float: "right" }}>
                            <Checkbox
                              onChange={() =>
                                setResignProduct(
                                  productInstance,
                                  product,
                                  NEW_PRODUCT
                                )
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </div>
        {lineAvailability.fetching && (
          <StatusChip
            type="loading"
            title="Please wait"
            message="Checking Line & Broadband Availability"
          />
        )}
        {lineAvailability.response.status === "error" && (
          <StatusChip
            type="error"
            title="Error"
            message={lineAvailability.response.message}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  outer: {
    backgroundColor: theme.palette.grey[500],
    display: "flex",
    marginBottom: 10,
    marginTop: 10,
    padding: 10
  },
  productCtr: {
    alignItems: "center",
    display: "flex",
    flex: 1
  },
  headerCtr: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    paddingBottom: 16
  },
  action: {
    marginLeft: 10,
    color: "inherit",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer"
  }
}));
