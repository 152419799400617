// strings should be unique across reducers so namespace them with the reducer name
export const OPEN = "supportCase.OPEN";
export const CLOSE = "supportCase.CLOSE";
export const SCREENSHOT = "supportCase.SCREENSHOT";
export const UPDATE = "supportCase.UPDATE";
export const VALIDATION_FAILED = "supportCase.VALIDATION_FAILED";
export const CREATING_SUPPORT_CASE = "supportCase.CREATING_SUPPORT_CASE";
export const SUPPORT_CASE_CREATED = "supportCase.SUPPORT_CASE_CREATED";
export const CREATING_SUPPORT_CASE_FAILED =
  "supportCase.CREATING_SUPPORT_CASE_FAILED";
