import React from "react";
import NoProductChangesRow from "./NoProductChangesRow";
import ProductChangesRow from "./ProductChangesRow";
import { connect } from "react-redux";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES
} from "../../../../../store/wlrBroadband/constants";
import { BroadbandProductSelector } from "../BroadbandProductSelector";
import RowActions from "../RowActions";
import { getProducts } from "../../../../../store/wlrBroadband/selectors";

const BroadbandProductOverview = ({
  configuration,
  isChecked,
  toggleRow,
  productInstance,
  setChanges,
  setNoChanges,
  removeResign,
  isLinkedProductInstance,
  products
}) => {
  const resignProduct = products.find(
    product => product.id === configuration.broadbandProductId
  );
  return (
    <>
      {configuration.broadbandProductId && resignProduct ? (
        <ProductChangesRow
          configuration={configuration}
          isChecked={isChecked}
          toggleRow={toggleRow}
          productInstance={productInstance}
          isLinkedProductInstance={isLinkedProductInstance}
          resignProduct={resignProduct}
          canAddBroadband={false}
          actions={
            <RowActions
              configuration={configuration}
              productInstance={productInstance}
              removeResign={removeResign}
              hasProductId={configuration.broadbandProductId}
              resignProduct={resignProduct}
            />
          }
        />
      ) : (
        <NoProductChangesRow
          isChecked={isChecked}
          toggleRow={toggleRow}
          productInstance={productInstance}
          isLinkedProductInstance={isLinkedProductInstance}
          actions={
            <RowActions
              configuration={configuration}
              productInstance={productInstance}
              setChanges={setChanges}
              setNoChanges={setNoChanges}
              removeResign={removeResign}
              hasProductId={configuration.broadbandProductId}
              resignProduct={resignProduct}
            />
          }
        />
      )}
      {(configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES ||
        configuration.resignProductType === NEW_PRODUCT) &&
        !configuration.broadbandProductId && (
          <BroadbandProductSelector
            configuration={configuration}
            productInstance={productInstance}
          />
        )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const locationIndex = ownProps.configuration.locationIndex;
  return {
    products: getProducts(state, locationIndex)
  };
};

export default connect(mapStateToProps)(BroadbandProductOverview);
