import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getAvailableNumbers,
  setReservedNumber
} from "../../../../../../store/wlrBroadband/actions";
import { StatusChip } from "@akj-dev/design-system";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

class ChooseAvailable extends Component {
  static defaultProps = {};
  static propTypes = {
    targetConfigs: PropTypes.array
  };

  state = {};

  componentDidMount() {
    const { numbers, locationIndex, getAvailableNumbers } = this.props;
    if (
      _.get(numbers.response, "available_telephone_numbers.string", []).length <
      1
    ) {
      getAvailableNumbers(locationIndex);
    }
  }

  render() {
    const {
      numberReservation,
      numbers,
      setReservedNumber,
      targetConfigs,
      locationIndex
    } = this.props;

    if (numbers.fetching)
      return (
        <StatusChip
          type="loading"
          title="Please Wait"
          message="Loading Available Numbers"
        />
      );

    if (_.get(numbers.response, "status") === "error")
      return (
        <StatusChip
          type="error"
          title="Sorry"
          message="Something went wrong finding available numbers."
          retry={() => getAvailableNumbers(locationIndex)}
        />
      );

    const availableNumbers = _.get(
      numbers.response,
      "available_telephone_numbers.string",
      []
    );
    if (availableNumbers.length)
      return (
        <FormControl fullWidth>
          <InputLabel>Choose a number</InputLabel>
          <Select
            onChange={event =>
              setReservedNumber(targetConfigs[0], event.target.value)
            }
            value={numberReservation.selectedNumber}
            disabled={numberReservation.response.status === "success"}
          >
            <MenuItem value={null} />
            {availableNumbers.map((number, i) => (
              <MenuItem key={i} value={number}>
                {number}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );

    return false;
  }
}

const mapStateToProps = (state, ownProps) => {
  const config = state.wlrBroadband.configurations[ownProps.targetConfigs[0]];
  return {
    locationIndex: config.locationIndex,
    numberReservation: config.numberReservation,
    numbers: state.wlrBroadband.locations[config.locationIndex].numbers
  };
};

export default connect(mapStateToProps, {
  getAvailableNumbers,
  setReservedNumber
})(ChooseAvailable);
