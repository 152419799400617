import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { formatCurrency } from "../../../../../helpers/formatCurrency";
import { setNewLineConfiguration } from "../../../../../store/wlrBroadband/actions";
import { QuantityField } from "../../../../../components/QuantityField";
import {
  ReduxWlrLocation,
  WlrLocationBroadbandProduct,
  WlrLocationProduct,
  WlrLocationProductConfig
} from "../../types";
import { BroadbandCell } from "./broadband-cell";
import { WlrLocationBroadbandModal } from "./broadband-cell/broadband-cell-modal";
import { MAX_QUANTITY_BROADBAND } from "./WlrLocationNewLineTable";
import { getIsMultiLineProduct } from "../../../../../store/wlrBroadband/selectors";

export interface WlrLocationNewLineTableRowProps {
  style: any;
  locationIndex: number;
  product: WlrLocationProduct;
}

export const WlrLocationNewLineTableRow = ({
  style,
  locationIndex,
  product
}: WlrLocationNewLineTableRowProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  // Location
  const location = useSelector<any, ReduxWlrLocation>(
    state => state.wlrBroadband.locations[locationIndex]
  );

  // Broadband Products
  const broadbandProducts: WlrLocationBroadbandProduct[] =
    location.broadbandSearch?.response?.products ?? [];

  // Location configurations (quantities of products)
  const configurations = useSelector<any, WlrLocationProductConfig[]>(
    state => state.wlrBroadband.configurations ?? []
  );
  const productConfigurations = configurations.filter(
    c => c.wlrProductId === product.id && c.locationIndex === locationIndex
  );

  // For the max qty (across all the locations)
  const configCount = configurations.length;

  // Qty for this location and this product
  const productConfigCount = productConfigurations.length;

  // One off price of a product
  const oneOffPrice = parseFloat(
    product.price.first_bill_recurring_price_with_promotions
  );

  // Total price of all broadbands (in this location and this product)
  const prices = productConfigurations
    .filter(c => !!c.broadbandProductId)
    .map(
      c =>
        broadbandProducts.find(b => b.id === c.broadbandProductId)?.price
          .first_bill_recurring_price_with_promotions ?? "0.00"
    );
  const totalPrice = prices
    .reduce((total, p) => total + parseFloat(p), 0)
    .toFixed(2);

  const handleQuantity = (qty: number = 0) => {
    dispatch(setNewLineConfiguration(locationIndex, product, qty));
  };

  const isMultiLineProduct =
    useSelector(state => getIsMultiLineProduct(state, product.id)) ||
    location?.installationDetails?.response?.service_type ===
      "PSTN_MULTI_LINE_AUX";

  const isAddBroadbandDisabled = productConfigCount === 0 || isMultiLineProduct;

  return (
    <Box data-cy={`${product.name}`} className={classes.root} style={style}>
      <Box className={classes.product}>{product.name}</Box>
      <Box className={classes.rest}>{formatCurrency(oneOffPrice)}</Box>
      <Box className={classes.rest}>
        <QuantityField
          quantity={productConfigCount}
          actualQuantity={configCount}
          maxQuantity={MAX_QUANTITY_BROADBAND}
          quantityThreshold={10}
          setQuantity={handleQuantity}
        />
      </Box>
      <Box className={classes.rest}>
        <BroadbandCell
          disabled={isAddBroadbandDisabled}
          locationIndex={locationIndex}
          handleClick={() => setOpen(true)}
          message={
            isMultiLineProduct
              ? "Broadband products can not be added to a multi-line."
              : false
          }
        />
      </Box>
      <Box className={classes.rest}>{`£${totalPrice}`}</Box>
      {open && (
        <WlrLocationBroadbandModal
          open={open}
          locationIndex={locationIndex}
          location={location}
          product={product}
          handleClose={() => setOpen(false)}
        />
      )}
    </Box>
  );
};

export interface RenderWlrLocationNewLineTableRowProps {
  index: number;
  style: any;
  data: {
    locationIndex: number;
    products: WlrLocationProduct[];
  };
}

export const RenderWlrLocationNewLineTableRow = ({
  index,
  style,
  data
}: RenderWlrLocationNewLineTableRowProps) => {
  const { locationIndex, products } = data;

  return (
    <WlrLocationNewLineTableRow
      style={style}
      locationIndex={locationIndex}
      product={products[index]}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: `0 ${theme.spacing(1.5)}px`
  },
  product: {
    flexGrow: 1,
    minWidth: 200,
    paddingRight: theme.spacing(1),
    fontWeight: "bold"
  },
  rest: {
    width: 250,
    minWidth: 250
  }
}));
