/* eslint-disable */
// @ts-nocheck

import React from "react";
import { findCurrentRoute, RoutingObject } from "./helpers";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
//import { ChevronRight } from "@material-ui/icons";
import { affinityColors as colors } from "@akj-dev/design-system";

interface BreadcrumbsProps {
  routes: RoutingObject;
  rootTitle: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  margin: 16px 32px 8px 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.neutralSilverfox.value};
`;

const Crumb = styled.div`
  font-size: 12px;
  padding: 3px 12px;
  margin: 0 4px;
  border-radius: 14px;
  text-decoration: none;
  background: ${colors.neutralSilverfox.value};
  font-style: italic;
`;

// Oh jeez. https://github.com/styled-components/styled-components/issues/1959#issuecomment-497761798
interface LinkCrumbProps {
  to: string;
}
const LinkCrumb = styled(Crumb).attrs<LinkCrumbProps>({ as: Link })<
  LinkCrumbProps
>`
  background: white;
  font-style: normal;
  &:active {
    color: inherit;
  }
`;

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  routes,
  rootTitle
}) => {
  const location = useLocation();

  let current = findCurrentRoute(location.pathname, routes.hierarchy);

  const crumbs = [];

  while (current?.parent) {
    crumbs.push(current);
    current = current.parent;
  }

  return null;

  // return (
  //   <Root data-cy="Breadcrumbs">
  //     {crumbs.map((c, i) => (
  //       <React.Fragment key={i}>
  //         {c.subject.length ? (
  //           <Crumb>{c.title}</Crumb>
  //         ) : (
  //           <LinkCrumb to={c.path}>{c.title}</LinkCrumb>
  //         )}
  //         <ChevronRight fontSize="small" />
  //       </React.Fragment>
  //     ))}
  //     <LinkCrumb to={routes.rootPath}>{rootTitle}</LinkCrumb>
  //   </Root>
  // );
};
