import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { StatusChip } from "@akj-dev/design-system";
import { Box } from "@material-ui/core";

class RemoteValidationErrors extends Component {
  static defaultProps = {};
  static propTypes = {
    targetConfig: PropTypes.number.isRequired
  };

  state = {};

  render() {
    const {
      wlrValidation,
      wlrValidationQuote,
      broadbandValidation
    } = this.props;

    if (
      wlrValidation?.status === "error" ||
      wlrValidationQuote?.status === "error" ||
      broadbandValidation?.status === "error"
    ) {
      return (
        <StatusChip
          type="error"
          title="This configuration has errors."
          message={
            <div style={{ display: "flex" }}>
              {!_.isEmpty(wlrValidation?.errors) && (
                <div style={{ marginRight: 10 }}>
                  <h4>WLR:</h4>
                  <ul>
                    {Object.keys(wlrValidation.errors).map(k => (
                      <li key={k}>{wlrValidation.errors[k]}</li>
                    ))}
                  </ul>
                </div>
              )}
              {!_.isEmpty(wlrValidationQuote?.errors) && (
                <Box mr={1}>
                  <h4>WLR:</h4>
                  <ul>
                    {Object.keys(wlrValidationQuote.errors).map(k => (
                      <li key={k}>{wlrValidationQuote.errors[k]}</li>
                    ))}
                  </ul>
                </Box>
              )}
              {!_.isEmpty(broadbandValidation?.errors) && (
                <div>
                  <h4>Broadband:</h4>
                  <ul>
                    {Object.keys(broadbandValidation.errors).map(k => (
                      <li key={k}>{broadbandValidation.errors[k]}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          }
        />
      );
    }

    return false;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    wlrValidation:
      state.wlrBroadband.configurations[ownProps.targetConfig]?.wlrValidation
        ?.response,
    wlrValidationQuote:
      state.wlrBroadband.configurations[ownProps.targetConfig]
        ?.wlrValidationQuote?.response,
    broadbandValidation:
      state.wlrBroadband.configurations[ownProps.targetConfig]
        ?.broadbandValidation?.response
  };
};

export default connect(mapStateToProps)(RemoteValidationErrors);
