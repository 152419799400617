// import { getJSON } from "../../api/helpers/core";
import { SaleStatusEnum } from "./sale-status/enums";
import { DashboardDataResponse, ErrorResponse } from "./types";
import { kongAxios } from "../../core/kongAxios";

interface GetDashboardDataProps {
  fromUnixTime: number;
  toUnixTime: number;
  interval: number;
  status: SaleStatusEnum;
}

export const getDashboardData = ({
  fromUnixTime,
  toUnixTime,
  interval,
  status
}: GetDashboardDataProps) =>
  kongAxios
    .get<DashboardDataResponse | ErrorResponse>(
      "ExternalServices/v1/Affinity/Sales/Quotes/AggregateStats",
      {
        params: {
          interval,
          status,
          createdAtTimestamp_gte: fromUnixTime,
          createdAtTimestamp_lte: toUnixTime
        }
      }
    )
    .then(response => response.data);
