import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ConfigTableRow from "../ConfigTableRow";
import { makeGetTargetConfigs } from "../../../../store/wlrBroadband/selectors";
import { getSelectedProductData } from "../../../../store/wlrBroadband/actions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ResignConfigTableRow from "../ResignsConfigurationTable/ResignConfigTableRow";

class SelectedConfigs extends Component {
  static defaultProps = {};
  static propTypes = {
    targetConfigs: PropTypes.array.isRequired
  };

  state = {};

  render() {
    const { getSelectedProductData } = this.props;
    const standardConfigs = this.props.configs.filter(
      config => !config.productInstanceId
    );
    const resignConfigs = this.props.configs.filter(
      config => config.productInstanceId
    );
    return (
      <Table>
        {standardConfigs.length > 0 && (
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Total One Off Price</TableCell>
              <TableCell>Total Recurring Price</TableCell>
              <TableCell>Broadband</TableCell>
              <TableCell>Configured?</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {standardConfigs.map((c, i) => (
            <ConfigTableRow
              c={c}
              configIndex={i}
              key={i}
              retryAction={getSelectedProductData}
            />
          ))}
        </TableBody>
        {resignConfigs.length > 0 && (
          <TableHead>
            <TableRow>
              <TableCell>CLI</TableCell>
              <TableCell />
              <TableCell>Product</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Resign Type</TableCell>
              <TableCell>Configured?</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {resignConfigs.map((c, i) => (
            <ResignConfigTableRow
              configuration={c}
              index={i}
              key={i}
              retryAction={getSelectedProductData}
            />
          ))}
        </TableBody>
      </Table>
    );
  }
}

const makeMapStateToProps = () => {
  const getTargetConfigs = makeGetTargetConfigs();
  return (state, ownProps) => ({
    configs: getTargetConfigs(state, ownProps)
  });
};

export default connect(makeMapStateToProps, {
  getSelectedProductData
})(SelectedConfigs);
