import { useDebounce } from "../../shared/hooks/useDebounce";
import {
  DataTable,
  DataTableSortState,
  SearchField
} from "@akj-dev/design-system";
import {
  Box,
  Button,
  ClickAwayListener,
  makeStyles,
  Popper
} from "@material-ui/core";
import {
  AddCircle,
  Archive,
  MoreHoriz,
  Person,
  PersonOutline
} from "@material-ui/icons";
import React, { useState } from "react";
import { useInfiniteQuery } from "react-query";
import {
  DCCustomersAPI,
  Customer,
  searchCustomers
} from "./api/searchCustomers";
import { NewOpportunity } from "./NewOpportunity";

export const ExistingProspects = () => {
  const classes = useStyles();

  const columns = [
    {
      name: "name",
      label: "Company Name",
      sortable: true
    },
    {
      name: "customerReference",
      label: "Reference Number",
      sortable: true
    },
    {
      name: "primaryContactName",
      label: "Contact Name",
      sortable: true
    },
    { name: "status", label: "Status", sortable: true },
    { name: "customer_uuid", label: "" }
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [sortState, setSortState] = useState<{
    sort?: string;
    order?: "asc" | "desc";
  }>({});

  // Popper
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = React.useState<null | HTMLElement>(null);

  const handleClickNewSale = (
    event: React.MouseEvent<HTMLElement>,
    row: Customer
  ) => {
    if (!anchorEl) {
      event.stopPropagation();
      setSelectedCustomer(row);
      setAnchorEl(event.currentTarget);
    }
  };

  const open = Boolean(anchorEl);

  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();

  const handleClickAway = (event: React.MouseEvent<Document, MouseEvent>) => {
    event.stopPropagation();
    setSelectedCustomer(undefined);
    setAnchorEl(null);
  };

  const handleChangeSort = ({ columnIndex, dir }: DataTableSortState) => {
    if (!dir) {
      setSortState({});
    } else {
      setSortState({
        sort: columns[columnIndex].name,
        order: dir === "ASC" ? "asc" : "desc"
      });
    }
  };

  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery<DCCustomersAPI, Error>(
    [
      "customer-global-search",
      debouncedSearchTerm,
      sortState.sort,
      sortState.order
    ],
    params =>
      searchCustomers({
        searchTerm: debouncedSearchTerm,
        sort: sortState.sort,
        order: sortState.order,
        ...params
      }),
    {
      getNextPageParam: lastPage => {
        if (lastPage.meta.page < lastPage.meta.totalPages) {
          return lastPage.meta.page + 1;
        }
      }
    }
  );

  const flattenedData = data?.pages.flatMap(({ results }) => results) || [];
  const count = data?.pages[data.pages.length - 1].meta.totalCount;

  return (
    <div>
      <Box my={1}>
        <SearchField
          value={searchTerm || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(event.target.value)
          }
          placeholder="Search existing prospects and customers"
        />
      </Box>
      <DataTable
        title="Existing Prospects and Customers"
        noDataCTA="Sorry, no prospects or customers found."
        columns={columns}
        count={Number(count) > 0 ? count : undefined}
        loading={isLoading || isFetchingNextPage}
        error={error?.message}
        onChangeSort={handleChangeSort}
        onLoadMore={hasNextPage ? () => fetchNextPage() : undefined}
        data={flattenedData.map(row => {
          return [
            row.name,
            row.customerReference,
            row.primaryContact?.name,
            <Box display="flex" alignItems="center">
              {row.status === "Active" && (
                <>
                  <Person />
                  <Box ml={1}>Active</Box>
                </>
              )}
              {row.status === "Prospect" && (
                <>
                  <PersonOutline />
                  <Box ml={1}>Prospect</Box>
                </>
              )}
              {row.status === "Archived" && (
                <>
                  <Archive />
                  <Box ml={1}>Archived</Box>
                </>
              )}
            </Box>,
            <Box className={classes.action}>
              <Button
                id={`new-sale-button-${row.customer_uuid}`}
                color="primary"
                startIcon={
                  selectedCustomer?.customer_uuid === row.customer_uuid ? (
                    <MoreHoriz />
                  ) : (
                    <AddCircle />
                  )
                }
                onClick={event => {
                  handleClickNewSale(event, row);
                }}
                disabled={row.status === "Archived"}
              >
                New Sale
              </Button>
            </Box>
          ];
        })}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}
        placement="left"
        modifiers={{ arrow: { enabled: true, element: arrowRef } }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={classes.newOppContainer}>
            <span className={classes.arrow} ref={setArrowRef} />
            <NewOpportunity customer={selectedCustomer} />
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  action: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    margin: theme.spacing(-1, 0)
  },
  newOppContainer: {
    width: 400
  },
  popper: {
    zIndex: 1
  },
  arrow: {
    width: "1em",
    height: "1em",
    right: 0,
    marginRight: "-0.5em",
    transform: "rotate(45deg)",
    position: "absolute",
    boxShadow:
      "0 10px 0 #fff, -10px 0px 0 #fff, 0px 0px 5px 1px rgb(0 0 0 / 20%)",
    background: "#fff"
  }
}));
