import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { StatusChip } from "@akj-dev/design-system";
import { useDispatch, useSelector } from "react-redux";
import PublicIcon from "@material-ui/icons/Public";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {
  getBroadbandSearchForLocation,
  getLineAvailabilityForLocation,
  getResignProductForProductInstanceServiceType,
  getTagsCheckForLocation
} from "../../../../store/wlrBroadband/selectors";
import {
  getLineAvailabilityForResign,
  doBroadbandSearchForResign,
  setWlrResignProduct,
  setBroadbandResignProduct,
  removeResign,
  doUpdateProductInstance
} from "../../../../store/wlrBroadband/actions";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
  SAME_PRODUCT,
  RESIGN
} from "../../../../store/wlrBroadband/constants";
import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { ChooseAddressDialog } from "../../../../components/Shared/AddressPicker/ChooseAddressDialog";
import NoAddressError from "../../../../components/Step1/wlrBroadband/Resigns/NoAddressError";
import { hasLocationAddress } from "../../../../helpers/addresses";

interface Props {
  configuration: any;
  productInstance: any;
}

export const BroadbandProductSelector = ({
  configuration,
  productInstance
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const dispatch = useDispatch();

  // setup variables for selectors
  const configurations = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );
  const linkedConfiguration = configurations.find(
    (config: any) =>
      config.pin === productInstance.pin &&
      config.wlrProductId &&
      config.wlrProductId !== RESIGN
  );
  const locationIndex = linkedConfiguration
    ? linkedConfiguration.locationIndex
    : configuration.locationIndex;
  const configurationIndex = configurations.indexOf(configuration);

  // selectors
  const location = useSelector(
    (state: any) => state.wlrBroadband.locations[locationIndex]
  );
  const lineAvailability = useSelector((state: any) =>
    getLineAvailabilityForLocation(state, { configuration })
  );
  const broadbandSearch = useSelector((state: any) =>
    getBroadbandSearchForLocation(state, { configuration })
  );

  const resignProduct = useSelector((state: any) =>
    getResignProductForProductInstanceServiceType(state, productInstance)
  );
  const tagsCheck = useSelector((state: any) =>
    getTagsCheckForLocation(state, { configuration })
  );

  const setResignProduct = (
    productInstance: any,
    resignProduct: any,
    resignProductType: any
  ) => {
    if (
      !!linkedConfiguration &&
      linkedConfiguration.resignProductType !== SAME_PRODUCT
    ) {
      // The below doesn't apply to MPF, as MPF comes through as one product
      // instance.
      if (linkedConfiguration.type !== "mpf") {
        // When initially selecting 'Same Product With Changes' or 'New Product'
        // for both a line and broadband product instance a new config is added
        // for each. If they are linked, the second config is never needed, as
        // the subsequent broadband details will be added under the first.
        dispatch(removeResign([productInstance.id]));
      }
      dispatch(
        setBroadbandResignProduct(
          productInstance,
          resignProduct.id,
          linkedConfiguration.type
        )
      );
    } else {
      dispatch(
        setWlrResignProduct(productInstance, "Resign", resignProductType)
      );
      dispatch(
        setBroadbandResignProduct(
          productInstance,
          resignProduct.id,
          configuration.type
        )
      );
    }
  };

  // other setup
  const updateProductInstance = linkedConfiguration
    ? linkedConfiguration.updateProductInstance
    : configuration.updateProductInstance;

  if (tagsCheck.fetching)
    return (
      <>
        <TableRow>
          <TableCell colSpan={9}>
            <StatusChip
              type="loading"
              title="Please wait"
              message="Checking Tags"
            />
          </TableCell>
        </TableRow>
      </>
    );
  if (tagsCheck.response.status === "error")
    return (
      <>
        <TableRow>
          <TableCell colSpan={9}>
            <StatusChip
              type="error"
              title="Error"
              message={tagsCheck.response.message}
            />
          </TableCell>
        </TableRow>
      </>
    );
  if (updateProductInstance.fetching)
    return (
      <>
        <TableRow>
          <TableCell colSpan={9}>
            <StatusChip
              type="loading"
              title="Please wait"
              message="Updating Product Instance"
            />
          </TableCell>
        </TableRow>
      </>
    );
  if (updateProductInstance.response.status === "error")
    return (
      <>
        <TableRow>
          <TableCell colSpan={9}>
            <StatusChip
              type="error"
              title="Error"
              message={updateProductInstance.response.message}
            />
          </TableCell>
        </TableRow>
      </>
    );
  // This field is required to be able to select an address.
  const hasPostcode = hasLocationAddress({ location, isPostcodeOnly: true });
  // These are the fields required to be able to do a line availability and broadband search.
  if (!hasPostcode) return <NoAddressError />;
  if (!hasLocationAddress({ location })) {
    const { address } = location;
    return (
      <>
        <TableRow>
          <TableCell colSpan={9}>
            <StatusChip
              type="error"
              title="Error"
              message="This product instance doesn't have a full address. Please select an address to continue with
                the resign process."
              link={
                <div
                  className={classes.action}
                  onClick={() => setIsDialogOpen(true)}
                >
                  Select an address
                </div>
              }
            />
          </TableCell>
        </TableRow>
        <ChooseAddressDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          postcode={address.postcode}
          setAddress={address => {
            dispatch(
              doUpdateProductInstance(
                productInstance.id,
                productInstance.pin,
                configurationIndex,
                address
              )
            );
            if (configuration.resignProductType === NEW_PRODUCT) {
              dispatch(getLineAvailabilityForResign(productInstance));
              dispatch(doBroadbandSearchForResign(productInstance));
            }
          }}
          //@TODO - account undefined in original component?
          //accountId={account}
        />
      </>
    );
  }
  const products =
    broadbandSearch &&
    broadbandSearch.response.products &&
    broadbandSearch.response.products.filter((product: any) => {
      if (product.first_broadband_component.type === "MPF") return false;
      if (
        productInstance.broadband.technology_type !== "FTTP" &&
        product.first_broadband_component.type === "FTTP"
      )
        return false;
      return true;
    });
  return (
    <TableRow>
      <TableCell colSpan={9}>
        <div className={classes.outer}>
          <div style={{ flex: 1 }}>
            <div className={classes.headerCtr}>
              <AutorenewIcon style={{ color: "#fff", marginRight: 10 }} />
              <b style={{ color: "#fff" }}>Select product</b>
            </div>
            <Table style={{ backgroundColor: "#fff" }}>
              <TableBody>
                {resignProduct &&
                  configuration.resignProductType ===
                    SAME_PRODUCT_WITH_CHANGES && (
                    <TableRow data-cy="wlrBroadbandResignBBSameRow">
                      <TableCell>
                        <div className={classes.productCtr}>
                          <PublicIcon
                            style={{
                              color: theme.palette.grey[500],
                              marginRight: 10
                            }}
                          />
                          <div style={{ fontWeight: "bold" }}>
                            {resignProduct.name}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>{resignProduct.description}</TableCell>
                      <TableCell>
                        {
                          resignProduct.price
                            .first_bill_recurring_price_with_promotions
                        }
                      </TableCell>
                      <TableCell>
                        <div style={{ float: "right" }}>
                          <Checkbox
                            onChange={() =>
                              setResignProduct(
                                productInstance,
                                resignProduct,
                                SAME_PRODUCT_WITH_CHANGES
                              )
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                {configuration.resignProductType === NEW_PRODUCT &&
                  lineAvailability.response.status === "success" &&
                  products &&
                  products.length > 0 &&
                  products.map((product: any) => {
                    return (
                      <TableRow
                        key={product.id}
                        data-cy="wlrBroadbandResignBBNewRow"
                      >
                        <TableCell>
                          <div className={classes.productCtr}>
                            <PublicIcon
                              style={{
                                color: theme.palette.grey[500],
                                marginRight: 10
                              }}
                            />
                            <div style={{ fontWeight: "bold" }}>
                              {product.name}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>{product.description}</TableCell>
                        <TableCell>
                          {
                            product.price
                              .first_bill_recurring_price_with_promotions
                          }
                        </TableCell>
                        <TableCell>
                          <div style={{ float: "right" }}>
                            <Checkbox
                              onChange={() =>
                                setResignProduct(
                                  productInstance,
                                  product,
                                  NEW_PRODUCT
                                )
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </div>
        {lineAvailability.fetching && (
          <StatusChip
            type="loading"
            title="Please wait"
            message="Checking Line & Broadband Availability"
          />
        )}
        {lineAvailability.response.status === "error" && (
          <StatusChip
            type="error"
            title="Error"
            message={lineAvailability.response.message}
          />
        )}
        {broadbandSearch.fetching && (
          <StatusChip
            type="loading"
            title="Please wait"
            message="Searching for Broadband Products"
          />
        )}
        {broadbandSearch.response.status === "error" && (
          <StatusChip
            type="error"
            title="Error"
            message={broadbandSearch.response.message}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  outer: {
    backgroundColor: theme.palette.grey[500],
    display: "flex",
    marginBottom: 10,
    marginTop: 10,
    padding: 10
  },
  productCtr: {
    alignItems: "center",
    display: "flex",
    flex: 1
  },
  headerCtr: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    paddingBottom: 16
  },
  action: {
    marginLeft: 10,
    color: "inherit",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer"
  }
}));
