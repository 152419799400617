import React from "react";
import MPFBroadbandProductOverview from "./MPFBroadbandProductOverview";
import MPFLineProductOverview from "./MPFLineProductOverview";
import MPFNoProductChangesRow from "./MPFNoProductChangesRow";
import RowActions from "../RowActions";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES
} from "../../../../../store/wlrBroadband/constants";
import { BroadbandProductSelector } from "../BroadbandProductSelector";
import MPFProductChangesRow from "./MPFProductChangesRow";
import { getProducts } from "../../../../../store/wlrBroadband/selectors";
import { connect } from "react-redux";

class MPF extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  removeResign = () => {
    this.props.removeResign();
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    const {
      configuration,
      isChecked,
      toggleRow,
      productInstance,
      setChanges,
      setNoChanges,
      products
    } = this.props;
    const resignProduct = products.find(
      product => product.id === configuration.broadbandProductId
    );
    return (
      <>
        {configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES &&
        resignProduct ? (
          <MPFProductChangesRow
            configuration={configuration}
            isChecked={isChecked}
            toggleRow={toggleRow}
            productInstance={productInstance}
            resignProduct={resignProduct}
            actions={
              <RowActions
                configuration={configuration}
                productInstance={productInstance}
                removeResign={this.removeResign}
                hasProductId={configuration.broadbandProductId}
                resignProduct={resignProduct}
              />
            }
          />
        ) : (
          <MPFNoProductChangesRow
            isChecked={isChecked}
            toggleRow={toggleRow}
            productInstance={productInstance}
            actions={
              <RowActions
                configuration={configuration}
                productInstance={productInstance}
                setChanges={resignProductType => {
                  if (resignProductType === NEW_PRODUCT) {
                    this.setState({ isOpen: !isOpen });
                  } else {
                    setChanges(SAME_PRODUCT_WITH_CHANGES);
                  }
                }}
                setNoChanges={setNoChanges}
                removeResign={this.removeResign}
              />
            }
          />
        )}
        {isOpen && (
          <>
            <MPFLineProductOverview
              configuration={configuration}
              productInstance={productInstance}
              setChanges={() => setChanges(NEW_PRODUCT)}
              removeResign={this.removeResign}
            />
            <MPFBroadbandProductOverview
              configuration={configuration}
              productInstance={productInstance}
              setChanges={() => setChanges(NEW_PRODUCT)}
              removeResign={this.removeResign}
            />
          </>
        )}
        {configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES &&
          !configuration.broadbandProductId && (
            <BroadbandProductSelector
              configuration={configuration}
              productInstance={productInstance}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const locationIndex = ownProps.configuration.locationIndex;
  return {
    products: getProducts(state, locationIndex)
  };
};

export default connect(mapStateToProps)(MPF);
