import React from "react";
import TierBase from "./TierBase";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useTheme } from "@material-ui/core/styles";

const SelectTier = ({
  name,
  price,
  description,
  quantity,
  index,
  addProduct,
  removeProduct
}) => {
  const theme = useTheme();
  return (
    <TierBase>
      <div className="header">
        <div>{name}</div>
        <div className="price">&pound;{price}</div>
      </div>
      <div className="description">{description}</div>
      <div className="action">
        <IconButton data-cy={"removeTier" + index} onClick={removeProduct}>
          <RemoveCircleOutlineIcon htmlColor={theme.palette.success.main} />
        </IconButton>
        <div className="quantity">{quantity}</div>
        <IconButton data-cy={"addTier" + index} onClick={addProduct}>
          <AddCircleOutlineIcon htmlColor={theme.palette.success.main} />
        </IconButton>
      </div>
    </TierBase>
  );
};

export default SelectTier;
