import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import VoiceIcon from "@material-ui/icons/KeyboardVoice";
import { ExpandingCard } from "@akj-dev/design-system";
import FormSelectField from "../FormSelectField";
import { useSelector } from "react-redux";
import { ethernetPurchaseArgsValidationErrors } from "../../../../../store/ethernetProducts/selectors";

const Voice = ({ purchaseArgs, index }) => {
  const [expanded, setExpanded] = useState(false);

  const validationFields = ["with_voice", "voice_service"];

  const validationErrors = useSelector(state =>
    ethernetPurchaseArgsValidationErrors(state, index)
  ).filter(field => validationFields.includes(field));

  const showErrors = useSelector(state =>
    state.ethernetProducts.configurationIndexesSubmitted.includes(index)
  );

  const forceExpanded = showErrors && validationErrors.length > 0;

  const handleHeaderClick = () => {
    if (forceExpanded) {
      return;
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (forceExpanded) {
      setExpanded(true);
    }
  }, [forceExpanded]);

  return (
    <ExpandingCard
      expanded={expanded}
      title="Voice"
      avatar={<VoiceIcon />}
      onHeaderClick={handleHeaderClick}
    >
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <FormSelectField
            index={index}
            helperText="Is this circuit being sold with Voice? *"
            param="with_voice"
            options={{
              1: "Yes",
              0: "No"
            }}
          />
        </Grid>
        {purchaseArgs.with_voice === "1" && (
          <Grid item sm={6}>
            <FormSelectField
              index={index}
              helperText="Which voice service is this being sold with? *"
              param="voice_service"
              options={{
                HVS: "HVS",
                "Daisy SIP": "Daisy SIP",
                "Gamma SIP": "Gamma SIP",
                Other: "Other"
              }}
            />
          </Grid>
        )}
      </Grid>
    </ExpandingCard>
  );
};

Voice.propTypes = {
  purchaseArgs: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default Voice;
