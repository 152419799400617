import o2Icon from "../../../images/o2@2x.png";
import vodaIcon from "../../../images/voda@2x.png";
import { ReduxMobileProductType } from "../../types";

export const getNetworkIcon = (network: string = "") => {
  const upperCaseNetwork = network.toLocaleUpperCase();
  switch (upperCaseNetwork) {
    case "O2":
      return o2Icon;
    case "VODAFONE":
      return vodaIcon;
    default:
      return undefined;
  }
};

export const transformMobileProductForDisplay = (
  product: ReduxMobileProductType
) => {
  const originalSupplier = product.first_mobile_component.supplier;
  return {
    id: product.id,
    name:
      originalSupplier === "VF Direct"
        ? `${product.name} (${product.first_mobile_component.soc_code})`
        : product.name,
    network: originalSupplier === "VF Direct" ? "vodafone" : originalSupplier,
    recurringPrice: product.price.first_bill_recurring_price_with_promotions,
    isPromotion:
      product.price.first_bill_recurring_price_with_promotions !==
      product.recurring_price_without_promotions,
    dataOnly: product.first_mobile_component.is_data_only,
    priceBook: product.end_user_price_list
  };
};
