import React from "react";
import { Alert } from "@akj-dev/design-system";
import { Box } from "@material-ui/core";

export const ApprovalRequiredInfoMessage = () => (
  <Box mt={1} mb={1}>
    <Alert
      type="info"
      message="Your order requires approval. Please proceed with generating an E-Sign Contract. The contract will only be sent to the customer once the order has been approved"
    />
  </Box>
);
