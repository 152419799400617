import { getJSON, postJSON, convertBoolValues } from "../helpers/core";
import { getDCOrderParams } from "../../store/wlrBroadband/selectors";

/**
 * Talk to the various Daisy Central API endpoints associated with WLR & Broadband ordering
 * Docs can be found at:
 * http://daisycentralenfield.daisy.group/cgi-bin/index.cgi/Admin/DocumentationFrame
 *
 * TODO: Split this into separate files per endpoint namespace like the others.
 */

/**
 * Available line products.
 * Not address dependent, but contract length influences price
 *
 * @param contract_length_in_months
 * @returns {Promise.<*>}
 */
export const lineSearch = contract_length_in_months =>
  getJSON("Product/LineSearch", {
    guided_sales: 1,
    contract_length_in_months
  });

/**
 * Available broadband products at an address
 * Optionally with additional CLI param for transfers.
 * Contract length influences price.
 * Note other price changes are to do with extras added like service care, not the core product.
 *
 * @param account - necessary to check wlr_change_possible according to @mike
 * @param address_reference
 * @param css_database_code
 * @param postcode
 * @param cli
 * @param contract_length_in_months
 * @returns {Promise.<*>}
 */
export const broadbandSearch = (
  account,
  address_reference,
  css_database_code,
  postcode,
  cli,
  contract_length_in_months,
  no_realms = 1
) =>
  getJSON(
    `Product/BroadbandSearch`,
    {
      account,
      guided_sales: 1,
      standard_lead_times: 1,
      address_reference,
      css_database_code,
      postcode,
      cli,
      contract_length_in_months,
      no_realms
    },
    true,
    true
  );

/**
 * Line Availability
 *
 * Returns info on capacity (eg. spare pairs) and install type at address.
 *
 * @param css_database_code
 * @param address_reference
 * @param account
 * @returns {Promise.<*>}
 */
export const lineAvailability = (
  css_database_code,
  address_reference,
  account
) =>
  getJSON(`WLR3/LineAvailability`, {
    css_database_code,
    address_reference,
    account,
    include_tags: 1
  });

/**
 * Installation details
 *
 * Returns info about a specific existing line (used to get info for transfers)
 *
 * Returns info about a
 * @param postcode
 * @param cli
 * @param product_instance_id
 * @param account
 * @returns {Promise.<*>}
 */
export const installationDetails = (
  postcode,
  cli,
  product_instance_id,
  account
) =>
  getJSON(
    `WLR3/InstallationDetails`,
    { postcode, cli, product_instance_id, account },
    false
  );

/**
 * Validate a working line takeover
 *
 * @param cli
 * @param address_reference
 * @param css_database_code
 * @param account_id
 * @returns {Promise<*>}
 */
export const validateWorkingLineTakeOver = (
  cli,
  address_reference,
  css_database_code,
  account_id
) =>
  postJSON(
    `WLR3/ValidateWorkingLineTakeOver`,
    {
      cli,
      address_reference,
      css_database_code,
      account_id
    },
    false
  );

/**
 * Validate CLI
 * Note: While you'd have thought this could be done with a client side regex,
 * it actually leads back to an acc4billing fn with 120k lines of phone number data.
 * As advised by Mike Jones.
 *
 * @param cli
 * @returns {Promise.<*>}
 */
export const validateCLI = cli => getJSON(`GuidedSales/ValidateCLI`, { cli });

/**
 * Get engineer appointment slots for broadband installs (only required for FTTC)
 * @param supplier
 * @param supplier_product_ref,
 * @param service_type
 * @param address_reference
 * @param css_database_code
 * @param start_date
 * @param account
 * @returns {Promise.<*>}
 */
export const getBBAppointmentAvailability = (
  supplier,
  supplier_product_ref,
  service_type,
  address_reference,
  css_database_code,
  start_date,
  site_visit_reason,
  account
) =>
  getJSON(`BroadbandAppointments/GetAppointmentAvailability`, {
    supplier,
    service_type,
    product_type: "BASIC",
    number_of_channels: 1,
    address_reference,
    css_database_code,
    start_date,
    site_visit_reason,
    // Note gfast is to do with https://gitlab.com/akj-dev/daisy-central/-/commit/9fa25e9fe29
    // FB113122: G.Fast appointments now require GFast flag
    ...(supplier_product_ref === "BT_21CN_FTTC_GFAST" && { gfast: 1 }),
    account
  });

/**
 * Get engineer appointment slots for WLR3 (line) installs
 *
 * @param service_type
 * @param product_type
 * @param number_of_channels
 * @param address_reference
 * @param css_database_code
 * @param supplier_product_ref
 * @param account
 * @returns {Promise.<*>}
 */

export const wlr3GetAppointments = (
  service_type,
  product_type,
  number_of_channels,
  address_reference,
  css_database_code,
  supplier_product_ref,
  account
) =>
  getJSON(`WLR3/GetAppointments`, {
    service_type,
    product_type,
    number_of_channels,
    address_reference,
    css_database_code,
    ...(supplier_product_ref === "BT_21CN_FTTC_GFAST" && { gfast: 1 }),
    account
  });

/**
 * Add a user selected WLR3 appointment
 *
 * @param params - object:
 *        address_reference
 *        appointment_date
 *        appointment_timeslot
 *        css_database_code
 *        number_of_channels
 *        product_type
 *        service_type
 *        account
 * @returns {Promise}
 */
export const wlr3AddAppointment = params =>
  postJSON("WLR3/AddAppointment", params);

/**
 * Add a user selected broadband appointment
 *
 * @param params - object
 *        product_type
 *        supplier
 *        service_type
 *        number_of_channels
 *        address_reference
 *        css_database_code
 *        appointment_date
 *        appointment_timeslot
 *        gfast
 *        account
 * @returns {Promise}
 */
export const broadbandAddAppointment = params =>
  postJSON("BroadbandAppointments/AddAppointment", params);

/**
 * Find available CLIs at address for reservation of specific numbers
 * Every new WLR line install must have a reserved CLI
 *
 * @param address_reference
 * @param css_database_code
 * @param account
 * @returns {Promise.<*>}
 */
export const findNumbersAtAddress = (
  address_reference,
  css_database_code,
  account
) =>
  getJSON(`WLR3/FindNumbersAtAddress`, {
    address_reference,
    css_database_code,
    account
  });

/**
 * Reserve a specified CLI
 *
 * @param css_exchange_code
 * @param service_type
 * @param telephone_number
 * @param account
 * @returns {Promise.<*>}
 */
export const reserveNumber = (
  css_exchange_code,
  service_type,
  telephone_number,
  account
) =>
  postJSON("WLR3/ReserveNumber", {
    css_exchange_code,
    service_type,
    telephone_number,
    account
  });

/**
 * Reserve the next available CLI
 *
 * @param css_database_code
 * @param css_exchange_code
 * @param service_type
 * @param account
 * @returns {Promise.<*>}
 */
export const reserveNextNumber = (
  css_exchange_code,
  service_type,
  css_database_code,
  account
) =>
  postJSON("WLR3/ReserveNextNumber", {
    css_database_code,
    css_exchange_code,
    service_type,
    account
  });

/**
 * Validate WLR ordering parameters
 *
 * @param state
 * @param configurationIndex
 * @param quoteOnly
 * @returns {Promise.<*>}
 */
export const wlrValidation = (state, configurationIndex, quoteOnly) =>
  postJSON(
    "WLR3/Validate",
    {
      ...getDCOrderParams(state, configurationIndex, "wlr", quoteOnly)
    },
    false
  );

/**
 * Validate broadband ordering parameters
 *
 * @param state
 * @param configurationIndex
 * @param requirements
 * @param quoteOnly {boolean}
 * @returns {Promise.<*>}
 */
export const broadbandValidation = (
  state,
  configurationIndex,
  requirements,
  quoteOnly
) =>
  getJSON(
    `BroadbandValidation/Validate`,
    {
      data: JSON.stringify(
        convertBoolValues(
          getDCOrderParams(state, configurationIndex, "broadband", quoteOnly)
        )
      ),
      requirements: JSON.stringify(requirements)
    },
    false
  );

/**
 * Used during a resign when a product instance does not have a postcode.
 * Returns the postcode that is linked to a cli.
 *
 * @param account
 * @param cli
 * @returns {Promise.<*>}
 */
export const getTagsCheck = (account, cli) =>
  getJSON(`BroadbandAvailability/TagsCheck`, {
    account,
    cli
  });

/**
 * Update a product instance.
 * Used during a resign.
 *
 * @param id
 * @param params
 * @returns {Promise}
 */
export const updateProductInstance = (id, params) =>
  postJSON(`Product/Instance/Update/${id}`, params);
