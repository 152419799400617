import React from "react";
import styled from "styled-components";
import EditIcon from "@material-ui/icons/Edit";

const NonUniformIndicator = ({ label, action }) => {
  return (
    <Outer>
      <div className="label">{label}</div>
      <button onClick={action} className="non-uniform">
        <span>Multiple values</span>
        <EditIcon />
      </button>
    </Outer>
  );
};

const Outer = styled.div`
  position: relative;
  height: 72px;
  width: 100%;
  .label {
    font-size: 12px;
    color: #aaa;
    position: absolute;
    bottom: 42px;
  }
  .non-uniform {
    position: absolute;
    bottom: 10px;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    color: #aaa;
    padding: 3px 0;
    height: 30px;
    width: 100%;
    background: none;
    cursor: pointer;
  }
  span {
    float: left;
    font-size: 16px;
    line-height: 24px;
  }
`;

export default NonUniformIndicator;
