import React from "react";
import { Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

export interface BroadbandCellProps {
  locationIndex: number;
  disabled: boolean;
  handleClick: (index: number) => void;
  message: string | boolean;
}

export const BroadbandCell = ({
  locationIndex,
  disabled,
  handleClick,
  message
}: BroadbandCellProps) => {
  return (
    <Tooltip
      title={message}
      disableFocusListener={!message}
      disableHoverListener={!message}
      disableTouchListener={!message}
    >
      <span>
        <Button
          data-cy={"broadband-modal-button"}
          color="primary"
          variant="outlined"
          disabled={disabled}
          onClick={() => handleClick(locationIndex)}
        >
          {"Add Broadband"}
        </Button>
      </span>
    </Tooltip>
  );
};
