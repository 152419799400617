import React from "react";
import { useDispatch } from "react-redux";
import { Box, makeStyles, Theme } from "@material-ui/core";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import { setProductQuantity } from "../../../../../store/mobile/actionCreators";
import { QuantityField } from "../../../../../components/QuantityField";
import { MobileProduct } from "../../../../types";
import { CarrierField } from "../../../../shared/CarrierField";

export const MAX_QTY = 50;
export const QTY_THRESHOLD = 10;

interface ProductTableRowProps {
  style: any;
  product: MobileProduct;
  configsCount: number;
  allConfigsCount: number;
}

export const ProductTableRow = ({
  style,
  product,
  configsCount,
  allConfigsCount
}: ProductTableRowProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleQuantity = (quantity: number | undefined) => {
    dispatch(setProductQuantity(quantity, product.id));
  };

  return (
    <Box data-cy={`${product.name}`} className={classes.root} style={style}>
      <Box className={classes.rest}>
        <CarrierField network={product.network} />
      </Box>
      <Box className={classes.product}>
        {product.name}
        {!!product.dataOnly && (
          <WifiTetheringIcon titleAccess="Data only tariff" />
        )}
      </Box>
      {product.priceBook && (
        <Box className={classes.priceBook}>{product.priceBook}</Box>
      )}
      <Box className={classes.rest}>
        <QuantityField
          color={"secondary"}
          price={product.recurringPrice}
          label={"Monthly"}
          quantity={configsCount}
          quantityThreshold={QTY_THRESHOLD}
          actualQuantity={allConfigsCount}
          maxQuantity={MAX_QTY}
          setQuantity={handleQuantity}
        />
      </Box>
    </Box>
  );
};

export interface RenderProductTableRowProps {
  index: number;
  style: any;
  data: {
    products: MobileProduct[];
    allConfigsCount: number;
  };
}

export const RenderProductTableRow = ({
  index,
  style,
  data
}: RenderProductTableRowProps) => {
  const { allConfigsCount, products } = data;
  const product = products[index];

  return (
    <ProductTableRow
      style={style}
      product={product}
      configsCount={product.configsCount!}
      allConfigsCount={allConfigsCount}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: `0 ${theme.spacing(1.5)}px`
  },
  product: {
    flexGrow: 1,
    minWidth: 250,
    paddingRight: theme.spacing(1),
    fontWeight: "bold"
  },
  priceBook: {
    width: 300,
    minWidth: 300
  },
  rest: {
    width: 250,
    minWidth: 250
  }
}));
