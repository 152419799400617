import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";
import MoreInformationPopover from "../../../../../components/Step1/wlrBroadband/Resigns/MoreInformationPopover";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PhoneIcon from "@material-ui/icons/Phone";
import PublicIcon from "@material-ui/icons/Public";
import { getTotalPrice } from "../../../../../store/wlrBroadband/selectors";
import LinkIcon from "../../../../../components/Shared/LinkIcon";
import { useTheme } from "@material-ui/core/styles";

const CustomCell = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

function NoProductChangesRow({
  isChecked,
  toggleRow,
  productInstance,
  isLinkedProductInstance,
  actions
}) {
  const theme = useTheme();
  const { evo_services } = productInstance;
  return (
    <>
      <TableRow selected={isChecked}>
        <TableCell>
          <Checkbox onChange={toggleRow} checked={isChecked} />
        </TableCell>
        <TableCell>{productInstance.pin}</TableCell>
        <TableCell>{isLinkedProductInstance && <LinkIcon />}</TableCell>
        <TableCell colSpan={2}>
          <CustomCell>
            {productInstance.broadband ? (
              <PublicIcon
                style={{ color: theme.palette.grey[500], marginRight: 10 }}
              />
            ) : (
              <PhoneIcon
                style={{ color: theme.palette.grey[500], marginRight: 10 }}
              />
            )}
            <div>{productInstance.name_for_resign || productInstance.name}</div>
          </CustomCell>
        </TableCell>
        <TableCell>
          <MoreInformationPopover productInstance={productInstance} />
        </TableCell>
        <TableCell>{getTotalPrice(evo_services)}</TableCell>
        <TableCell className="u-nowrap">
          {productInstance.end_date_ymd}
        </TableCell>
        <TableCell className="u-text-right" style={{ paddingRight: 0 }}>
          {actions}
        </TableCell>
      </TableRow>
    </>
  );
}

export default NoProductChangesRow;
