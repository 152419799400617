import React, { useCallback, useMemo } from "react";
import { makeStyles, Select, Theme } from "@material-ui/core";
import { QuantitySelectFieldProps } from "./types";

export const QuantitySelectField = ({
  disabled,
  showError,
  quantity,
  quantityThreshold,
  maxQuantity,
  setQuantity,
  setShowInput
}: QuantitySelectFieldProps) => {
  const classes = useStyles();

  const options = useMemo(() => {
    return Array.from(Array(quantityThreshold ?? maxQuantity).keys());
  }, [quantityThreshold, maxQuantity]);

  const handleSelectChange = useCallback(
    (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const newValue = parseInt(e.target.value as string);

      if (newValue !== quantityThreshold) {
        setQuantity(newValue);
      } else {
        setQuantity(undefined);
        setShowInput(true);
      }
    },
    [quantityThreshold, setQuantity, setShowInput]
  );

  const showThreshold = !!quantityThreshold && quantityThreshold < maxQuantity;

  return (
    <Select
      native
      className={classes.field}
      variant={"outlined"}
      error={showError}
      disabled={disabled}
      value={quantity}
      onChange={handleSelectChange}
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
      {showThreshold && (
        <option value={quantityThreshold}>{`${quantityThreshold}+`}</option>
      )}
    </Select>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    minWidth: theme.spacing(5)
  }
}));
