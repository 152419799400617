import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import Checkbox from "@material-ui/core/Checkbox";
import ConfigStatus from "../../../../components/Shared/ConfigStatus";
import Spinner from "../../../../components/Shared/Spinner";
import {
  RESIGN_WITH_CHANGES,
  RESIGN_WITHOUT_CHANGES
} from "../../../../store/mobile/constants";
import {
  getNonGenericInstanceName,
  getResignPrice
} from "../../../../store/mobile/selectors/resigns";
import ProductTitle from "../../../../components/Shared/mobile/ProductTitle";
import ConnectionType from "../../../../components/Shared/mobile/ConnectionType";
import {
  requestAllMobileProductData,
  requestAllResignPropertyValues,
  removeConfig
} from "../../../../store/mobile/actionCreators";
import { StatusChip } from "@akj-dev/design-system";
import {
  getConfigsWithMonthlyAirtimeCredit,
  getConfigsWithOneOffAirtimeCredit,
  getIsConfigValid
} from "../../../../store/mobile/selectors/productConfig";
import InlinePriceMeta from "../../../Shared/Pricing/InlinePriceMeta";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableActionsCell from "../../../../components/Shared/TableActionsCell";

class ConfigTableRow extends Component {
  static defaultProps = {};
  static propTypes = {
    configIndex: PropTypes.number.isRequired,
    checked: PropTypes.bool,
    editSingle: PropTypes.func,
    toggleRow: PropTypes.func
  };

  state = {};

  /**
   * Get total recurring price of a configuration including selected CLI level bolt-ons
   * Note: Total price isn't calculated in productData responses so has to be client side.
   * CLI bolt-ons and Airtime credit (?) are technically separate entities
   * TODO: Discounts ARE returned in product data. ....so use that somehow probably.. We need to show user applied discounts
   * @returns {string}
   */

  totalRecurringPrice = () => {
    const {
      cliBoltOnSearch,
      productData,
      perConfigProductData,
      selectedCliBoltOns
    } = this.props;

    const perConfig = _.get(
      perConfigProductData,
      "response.overall_price.first_bill_recurring_price_with_promotions"
    );
    let total = parseFloat(
      perConfig ||
        productData.response.overall_price
          .first_bill_recurring_price_with_promotions
    );

    if (!selectedCliBoltOns) return total.toFixed(2);

    for (let type in selectedCliBoltOns) {
      if (selectedCliBoltOns[type] && cliBoltOnSearch.response.products) {
        total += parseFloat(
          cliBoltOnSearch.response.products.find(
            p => p.id === selectedCliBoltOns[type]
          ).price.first_bill_recurring_price_with_promotions
        );
      }
    }
    return total.toFixed(2);
  };

  render() {
    const {
      configIndex,
      checked,
      toggleRow,
      editSingle,
      resignType,
      acquisitionMethod,
      productData,
      resign,
      isValid,
      resignPropertyValues,
      airtimeCreditAmount,
      airtimeCreditOneOffAmount,
      airttimeCreditDurationMonths,
      removeConfig,
      requestAllMobileProductData,
      requestAllResignPropertyValues,
      hasConfigsWithMonthyAirtimeCredit,
      hasConfigsWithOneOffAirtimeCredit
    } = this.props;

    // "resign without changes" configs don't need product data or edit options, so are simple to display
    if (resignType === RESIGN_WITHOUT_CHANGES)
      return (
        <TableRow selected={checked}>
          {/* Do we even need the checkbox? No edits! */}
          {toggleRow && <TableCell> </TableCell>}
          <TableCell>{resign.pin}</TableCell>
          <TableCell>
            <ProductTitle network={resign.network.name}>
              {getNonGenericInstanceName(resign)}
            </ProductTitle>
          </TableCell>
          <TableCell colSpan={2}>{getResignPrice(resign).toFixed(2)}</TableCell>
          <TableCell>
            <ConnectionType type={RESIGN_WITHOUT_CHANGES} />
          </TableCell>
          <TableCell>
            <ConfigStatus isValid={isValid} />
          </TableCell>
          {editSingle && (
            <TableActionsCell>
              <IconButton onClick={() => removeConfig(configIndex)}>
                <DeleteIcon />
              </IconButton>
            </TableActionsCell>
          )}
        </TableRow>
      );

    // Check we have product data for this config (first render will be before request init)
    if (!productData) return false;

    const {
      fetching,
      response: { status, message }
    } = productData;

    // Show loading / error states
    if (fetching || resignPropertyValues.fetching)
      return (
        <TableRow>
          <TableCell colSpan={8}>
            <Spinner size={30} />
          </TableCell>
        </TableRow>
      );

    if (status !== "success")
      return (
        <TableRow>
          <TableCell colSpan={8}>
            <StatusChip
              type="error"
              title="Error retrieving product data."
              message={message}
              retry={requestAllMobileProductData}
            />
          </TableCell>
        </TableRow>
      );

    if (
      resignPropertyValues.status &&
      resignPropertyValues.status !== "success"
    )
      return (
        <TableRow>
          <TableCell colSpan={8}>
            <StatusChip
              type="error"
              title="Error retrieving resign property values."
              message={resignPropertyValues.message}
              retry={requestAllResignPropertyValues}
            />
          </TableCell>
        </TableRow>
      );

    if (!_.get(productData, "response.mobile.product_component_data"))
      return (
        <TableRow>
          <TableCell colSpan={8}>
            <StatusChip
              type="error"
              title="No product component data"
              retry={requestAllMobileProductData}
            />
          </TableCell>
        </TableRow>
      );

    // Show the row and edit option.
    return (
      <TableRow selected={checked}>
        {toggleRow && (
          <TableCell>
            <Checkbox
              checked={checked}
              onChange={() => toggleRow(configIndex)}
            />
          </TableCell>
        )}
        <TableCell>{resign.pin}</TableCell>
        <TableCell>
          <ProductTitle
            network={
              productData.response.mobile?.product_component_data.supplier
            }
          >
            {productData.response.product_name}
          </ProductTitle>
        </TableCell>
        <TableCell>
          {this.totalRecurringPrice()}
          <InlinePriceMeta
            productData={productData.response}
            priceType="RECURRING"
          />
        </TableCell>
        {hasConfigsWithMonthyAirtimeCredit && (
          <TableCell>
            {airtimeCreditAmount
              ? `${Number.parseFloat(airtimeCreditAmount).toFixed(2)}${
                  airttimeCreditDurationMonths > 0
                    ? " x " + airttimeCreditDurationMonths + "m"
                    : ""
                }`
              : "0.00"}
          </TableCell>
        )}
        {hasConfigsWithOneOffAirtimeCredit && (
          <TableCell>
            {airtimeCreditOneOffAmount
              ? Number.parseFloat(airtimeCreditOneOffAmount).toFixed(2)
              : "0.00"}
          </TableCell>
        )}
        <TableCell>
          {(() => {
            if (resignType === RESIGN_WITH_CHANGES)
              return <ConnectionType type={RESIGN_WITH_CHANGES} />;
            // New or Port/mig:
            return <ConnectionType type={acquisitionMethod} />;
          })()}
        </TableCell>
        <TableCell>
          <ConfigStatus
            isValid={isValid}
            onClick={() => editSingle && editSingle(configIndex)}
          />
        </TableCell>
        {editSingle && (
          <TableActionsCell>
            <IconButton
              onClick={() => editSingle(configIndex)}
              data-cy="editMobileConfig"
            >
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => removeConfig(configIndex)}>
              <DeleteIcon />
            </IconButton>
          </TableActionsCell>
        )}
      </TableRow>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const config = state.mobile.configs[ownProps.configIndex];
  return {
    resignType: config.resignType,
    acquisitionMethod:
      config.properties && config.properties.acquisition_method, // Resign without change wouldn't have any properies
    selectedCliBoltOns: config.selectedCliBoltOns,
    isValid: getIsConfigValid(ownProps.configIndex, state),
    // If it's a resign, get the data for that (which would have been loaded in step 1)
    resign: config.resignId
      ? state.mobile.productInstances.response.results.find(
          r => r.id === config.resignId
        )
      : false,
    // "Resigns with changes" and new provides need product data so...
    productData: state.mobile.productData[config.productId],
    // Pricing data we pull per config, when price adjustment / pricing scheme changes take place.
    // Could use the makeGetMergedProductData selector? ...although the pricing still requires computation
    // as cli bolt-ons aren't included. FFS.
    perConfigProductData: config.productData,
    cliBoltOnSearch: state.mobile.cliBoltOnSearch[config.productId],
    // Resign with changes also needs another call to get any existing property values, which often takes ages, so:
    resignPropertyValues: config.resignPropertyValues || {},
    airtimeCreditAmount: config?.properties?.airtime_credit_amount,
    airttimeCreditDurationMonths: config?.properties?.airtime_credit_duration,
    airtimeCreditOneOffAmount: config?.properties?.airtime_credit_amount_oneoff,
    hasConfigsWithMonthyAirtimeCredit:
      getConfigsWithMonthlyAirtimeCredit(state).length > 0,
    hasConfigsWithOneOffAirtimeCredit:
      getConfigsWithOneOffAirtimeCredit(state).length > 0
  };
};

export default connect(mapStateToProps, {
  removeConfig,
  requestAllMobileProductData,
  requestAllResignPropertyValues
})(ConfigTableRow);
