import React from "react";
import { Box } from "@material-ui/core";
import StepCustomise from "../../containers/StepCustomise";
import { TitleAndInfo } from "../shared/title-and-info";

export const Customise = () => {
  return (
    <Box>
      <TitleAndInfo title={"Customise Products"} />
      <StepCustomise />
    </Box>
  );
};
