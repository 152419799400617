import React, { Component } from "react";
import { connect } from "react-redux";
import FieldContainer from "../../WlrBBDynamicField/FieldContainer";
import ChangeIcon from "../../WlrBBDynamicField/ChangeIcon";
import {
  doRemoteValidation,
  getProductData,
  removeProductDiscount,
  setProductDiscount
} from "../../../../../../store/wlrBroadband/actions";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import withContextToProps from "../../../../../../helpers/withContextToProps";
import { TargetConfigsContext } from "../../../../context/TargetConfigsContext";

class Field extends Component {
  render() {
    const {
      propertyNamePrefix,
      label,
      targetConfigs,
      propertyValue,
      discountEnabled,
      setProductDiscount,
      removeProductDiscount,
      getProductData,
      doRemoteValidation
    } = this.props;

    // if(targetConfigs.length > 0) {
    //     // TODO: Probably could bulk edit these with a bit of thought ...if that's what we want.
    //     return (
    //         <StatusChip
    //             type="info"
    //             title="WLR Change discounts cannot be bulk edited."
    //             message="Please set them per product."
    //         />
    //     )
    // }
    return (
      <FieldContainer>
        <TextField
          label={label}
          onChange={e =>
            setProductDiscount(
              targetConfigs,
              "broadband",
              propertyNamePrefix,
              e.target.value
            )
          }
          onBlur={() => {
            // targetConfigs.forEach(t => {
            //     getProductData(t, 'broadband')
            //     doRemoteValidation(t)
            // })
            getProductData(targetConfigs[0], "broadband");
            doRemoteValidation(targetConfigs[0]);
          }}
          type="number"
          value={propertyValue || ""}
        />
        {discountEnabled && <ChangeIcon />}
        {discountEnabled && (
          <Tooltip title="Remove Discount">
            <IconButton
              onClick={() => {
                removeProductDiscount(
                  targetConfigs,
                  "broadband",
                  propertyNamePrefix
                );
                getProductData(targetConfigs[0], "broadband");
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      </FieldContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  propertyValue:
    state.wlrBroadband.configurations[ownProps.targetConfigs[0]]
      .broadbandProperties[`${ownProps.propertyNamePrefix}_discount_value`],
  discountEnabled:
    state.wlrBroadband.configurations[ownProps.targetConfigs[0]]
      .broadbandProperties[
      `${ownProps.propertyNamePrefix}_discount_override`
    ] == 1 // eslint-disable-line eqeqeq
  // Could be string or number 1.
});

const ConnectedField = connect(mapStateToProps, {
  setProductDiscount,
  removeProductDiscount,
  getProductData,
  doRemoteValidation
})(Field);

export default withContextToProps(ConnectedField, TargetConfigsContext);
