import _ from "lodash";
import { NEW_LINE, NUMBER_RES_NEXT, RESIGN, TRANSFER } from "../constants";
import { addSpaceToPostcode } from "../../../helpers/addresses";
import { v4 as uuidv4 } from "uuid";

// Configurations belong to locations and store data used to make the final OrderProduct/Create calls to DC

const newConfiguration = (action, state) => {
  const location = state.locations[action.locationIndex];
  const installationDetails = location.installationDetails.response;
  const wltoDetails = _.get(location, "wltoDetails.response", {});
  const productId = action.productId || action.resignProductId;
  return {
    locationIndex: action.locationIndex,
    wlrProductId: productId, // Each product config must have a WLR product
    broadbandProductId: undefined, // It can optionally have a broadband product as well.

    // WLR & Broadband properties will be used as parameters for the OrderProduct/Create call
    // Some special cases need to be pre-populated
    // More will be added to this list from current & default values in ProductData,
    // when that's fetched going into step 2. See getDCOrderParams selector

    wlrProperties: {
      // TODO: Refactor this to be lineProperties - makes more sense as all these need to be prefixed `line-`

      as_frontend_uuid: uuidv4(),
      // New line installs have some defaults that aren't present in the dynamic_properties data:
      ...((location.type === NEW_LINE || location.type === RESIGN) && {
        acquisition_method: "install",
        termination_type: "LINE_BOX",
        installation_type: "STANDARD", // User select with no DC default.
        force_new_provide: 1, // This seems to be a thing. TODO: Check if it's always required for new installs.
        caller_display: 1,
        got_installation_details: location.type === RESIGN ? 1 : 0
      }),

      // Transfers need values copying over from the InstallationDetails call fired in step 1
      // that tells us about the existing setup at the location
      ...(location.type === TRANSFER && {
        // Note: 'WORKING_LINE' is for "Working Line Takeover". Which is a special case. See FB71602 :
        // TODO: Old code had 'conversion' as a transfer method too, but logic unclear... Check it can be ignored. @martinw thinks that might be the case
        // Conversions are to do with going from single to multi line or basic to premium according to Lindsey
        // See miro https://miro.com/app/board/o9J_lRZqr3k=/ (Activation fee - conversion)
        acquisition_method:
          wltoDetails.line_plant_type === "WORKING_LINE"
            ? "install"
            : "transfer",
        installation_type:
          wltoDetails.line_plant_type === "WORKING_LINE"
            ? "STANDARD"
            : installationDetails.installation_type,
        product_type: installationDetails.line_type || "BASIC", // Basic for WLTO
        termination_type: (() => {
          // Dynamic property only has opts for NTTP & LINE_BOX. Hence this, as advised by @davet :
          const type = location.installationDetails.response.termination_type;
          if (!type) return "LINE_BOX"; // For WLTO
          return type === "NTE" ? "LINE_BOX" : type;
        })(),
        care_level: installationDetails.care_level,
        number_of_channels:
          installationDetails.number_of_channels ||
          installationDetails.line_quantity_including_main_line ||
          1, // For WLTO
        like_for_like: wltoDetails.line_plant_type === "WORKING_LINE" ? 0 : 1,
        got_installation_details: 1,
        cli: location.cli.value
      }),

      autoprovision: 1, // This seems to open up a load of other options... Must be important
      line_plant_type: "SPARE_PAIR", // This is a free text field in DC with no default. Weird.

      // Pre-populate location from original address lookup.
      address_category: location.address.addressCategory,
      address_reference: location.address.addressReference,
      county: location.address.county,
      css_database_code: location.address.cssDatabaseCode,
      dependentthoroughfarename: location.address.dependentThoroughfareName,
      dependentlocality: location.address.doubleDependentLocality,
      locality: location.address.locality,
      company: location.address.organisationName,
      pobox: location.address.poBox,
      // Postcode needs a space according to DC, even though it comes back from the checker without one.
      postcode: addSpaceToPostcode(location.address.postcode),
      posttown: location.address.postTown,
      premisesname: location.address.premisesName,
      subpremises: location.address.subPremises,
      thoroughfarename: location.address.thoroughfareName,
      thoroughfarenumber: location.address.thoroughfareNumber
    },
    broadbandProperties: {},

    // The above properties have corresponding entries here to show validation states
    validation: {},

    // API: v1/GuidedSales/ProductData - details about price and config fields.
    // This is influenced by config properties and hence is stored per config (not globally)
    // eg. Prices can change for install type, or product discount levels (and this data would be updated)
    // As advised by Jim Butler, available_options on dynamic_properties can also change
    // eg. available care levels might be limited depending on install type
    // The actual list of dynamic_properties won't change though.
    wlrProductData: {
      fetching: false,
      response: {}
    },
    // Same for broadband....
    broadbandProductData: {
      fetching: false,
      response: {},
      // wlr_change.* dynamic properties should only be sent when changed by the user.
      // Hence we need to store their current values returned from the initial ProductData request.
      initialWlrChangeValues: {}
    },

    wlrAppointments: {
      fetching: false,
      response: {
        appointments: []
      },
      selectedIndex: null
    }, // API: v1/WLR3/GetAppointments - new lines only

    addWlrAppointment: {
      fetching: false,
      response: {}
    }, // API: v1/WLR3/AddAppointment

    broadbandAppointments: {
      fetching: false,
      response: {
        appointments: []
      },
      selectedIndex: null
    }, // API: v1/BroadbandAppointments/GetAppointmentAvailability - fttc only
    // To be booked later with BroadbandAppointments/AddAppointment ( see reserveBBAppointment() )
    // which will return a value for broadbandProperties -> bb.appointment.appointmentReference

    addBroadbandAppointment: {
      fetching: false,
      response: {}
    }, // API: v1/BroadbandAppointments/AddAppointment

    numberReservation: {
      fetching: false,
      response: {},
      type: NUMBER_RES_NEXT,
      selectedNumber: ""
    }, // API:
    // /WLR3/ReserveNextNumber - returns reservation reference to populate the WLR line order with
    // /WLR3/ReserveNumber - also see `numbers` on parent location, where we get the list of available ones from.

    wlrValidation: {
      fetching: false,
      response: {}
    }, // API: v1/WLR3/Validate

    wlrValidationQuote: {
      fetching: false,
      response: {}
    }, // API: v1/WLR3/Validate

    broadbandValidation: {
      fetching: false,
      response: {}
    }, // API: v1/BroadbandValidation/Validate

    // This is populated from the final GS addAllProductsToOrder call.
    // Stores whether the OrderProduct/* call worked or not basically
    orderProduct: {
      wlr: {
        fetching: false,
        response: {}
      },
      broadband: {
        fetching: false,
        response: {}
      }
    }
  };
};

export default newConfiguration;
