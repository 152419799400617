import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { StatusChip } from "@akj-dev/design-system";
import { setOtherConfiguration } from "../../../store/wlrBroadband/actions";
import {
  getIsWlrChangePossible,
  getIsWLTO,
  getMPFProducts,
  getMultiLineProducts,
  getSingleLineProducts,
  isActivationCodeP,
  makeGetConfigsAtLocation,
  makeGetTransferProduct,
  getSOGEAProducts,
  makeGetRequiredSparePairs
} from "../../../store/wlrBroadband/selectors";
import {
  BROADBAND_ONLY,
  EXISTING_LINE,
  NEW_LINE,
  TRANSFER
} from "../../../store/wlrBroadband/constants";
import InstallationDetails from "./InstallationDetails";
import { Grid, Typography } from "@material-ui/core";
import {
  EXISTING_SOGEA,
  NEW_SOGEA
} from "../../../store/wlrBroadband/constants";
import ExistingSoGEAAlertDialog from "../../../components/Step1/wlrBroadband/ExistingSoGEAAlertDialog";
import { getAccountSettings } from "../../../store/account/selectors";
import { WlrLocationNewLineTable } from "../../../screens/choose/choose-broadband/wlr-location/wlr-location-new-line-option/WlrLocationNewLineTable";
import { WlrLocationProductCard } from "../../../screens/choose/choose-broadband/wlr-location/wlr-location-product-card/WlrLocationProductCard";
import { IndexedTitle } from "../../../screens/shared/indexed-card-info/IndexedTitle";
import Box from "@material-ui/core/Box";

class ChooseLineProducts extends Component {
  static defaultProps = {};
  static propTypes = {
    locationIndex: PropTypes.number.isRequired
  };

  state = {
    existingSoGEAAlertOpen: false
  };

  render() {
    const {
      locationIndex,
      locationType,
      lineAvailability,
      broadbandSearch,
      installationDetails,
      wltoDetails,
      lineSearch,
      isWLTO,
      singleLineProducts,
      multiLineProducts,
      mpfProducts,
      transferProduct,
      configurations,
      requiredSparePairs,
      isWlrChangePossible,
      isActivationCodeP,
      sogeaProducts,
      accountSettings,
      setOtherConfiguration
    } = this.props;

    // Display loading conditions
    // Note: There are different calls depending on if it's a new or existing install

    if (lineAvailability.fetching || installationDetails.fetching)
      return (
        <StatusChip
          type="loading"
          title="Please wait"
          message="Checking Line & Broadband Availability"
        />
      );

    if (broadbandSearch.fetching)
      return (
        <StatusChip
          type="loading"
          title="Please wait"
          message="Searching for Broadband Products"
        />
      );

    // WLTO call happens after a failed installationDetails one, so show a separate loading message
    if (wltoDetails.fetching)
      return (
        <StatusChip
          type="loading"
          title="Please wait"
          message="Validating Working Line Takeover"
        />
      );

    // Display error conditions.
    if (_.get(broadbandSearch.response, "status") === "error")
      return (
        <StatusChip
          type="error"
          title="Error"
          message={broadbandSearch.response.message}
        />
      );

    // New lines look for line availability at a property
    if (locationType === NEW_LINE) {
      if (_.get(lineAvailability.response, "status") === "error") {
        // FB126927 - we want to differentiate this error message from what comes back from DC. The advice for GS users is different.
        if (lineAvailability.response.message.includes("OR2450")) {
          return (
            <StatusChip
              type="error"
              title="Address Not Found"
              message="Openreach have deleted this address from CSS but not Siebel. This address is now unusable; if there is no suitable alternative address listed please pass this to the provisioning team to manually raise the order"
            />
          );
        } else {
          return (
            <StatusChip
              type="error"
              title="Error"
              message={lineAvailability.response.message}
            />
          );
        }
      }
    }

    // Transfer and BB only look for params of existing install.
    // If the line turn out to not be on BT, a WLTO call is attempted, leaving the failed installationDetails call in place
    // ...as we'll need the css_exchange_code from it to complete the order later...
    if (locationType === TRANSFER || locationType === BROADBAND_ONLY) {
      if (
        _.get(installationDetails.response, "status") === "error" &&
        _.get(wltoDetails.response, "status") !== "success"
      )
        return (
          <StatusChip
            type="error"
            title="Working Line Takeover Error"
            message={
              _.get(wltoDetails.response, "message") ||
              installationDetails.response.message
            }
          />
        );
    }

    // Display product options....
    // New lines: Single and multi-line, limited by lineAvailability (capacity) at address

    if (locationType === NEW_LINE) {
      const sparePairs = _.get(lineAvailability.response, "dp_spare_pairs");
      const products = _.get(lineSearch.response, "products");

      const lineProducts = [...(products ?? []), ...mpfProducts];
      const dcProducts = [
        singleLineProducts?.[0],
        multiLineProducts?.[0],
        ...mpfProducts
      ].filter(p => !!p);

      if (sparePairs && (lineProducts.length || dcProducts.length)) {
        return (
          <div>
            <p>
              {sparePairs - requiredSparePairs} spare pairs remaining at
              location.
            </p>
            <Grid container spacing={2}>
              {accountSettings.dws_reseller_enabled === "1" ? (
                // DWS Resellers want to see all the line products returned                  from the search, unlike DC users
                <WlrLocationNewLineTable
                  products={lineProducts}
                  locationIndex={locationIndex}
                />
              ) : (
                // DC users should only see a single line and multi-line product. More are returned for some reason.
                <WlrLocationNewLineTable
                  products={dcProducts}
                  locationIndex={locationIndex}
                />
              )}
            </Grid>
            {configurations.find(c => c.wlrProductId === NEW_SOGEA) && (
              <StatusChip
                type="info"
                message="This is a broadband only line which does NOT include a WLR voice service. If the customer requires a voice service, please quote for a VoIP service separately."
              />
            )}
          </div>
        );
      } else {
        if (!sparePairs && !_.isEmpty(lineAvailability.response)) {
          return (
            <StatusChip
              type="error"
              title="No available lines found at this address."
              message="This order cannot be completed via Guided sales, please place the order manually. Note: Lead times maybe increased due to no infrastructure in the premises."
            />
          );
        }
      }
    }

    // Transfers: Product matching service_type info retrieved by InstallationDetails call
    if (locationType === TRANSFER && transferProduct) {
      return (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h4" gutterBottom>
              Current Line
            </Typography>
            <InstallationDetails
              installationDetails={installationDetails.response}
            />
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h4" gutterBottom>
              <IndexedTitle
                index={"C"}
                title={"Choose transfer product"}
                withMargin={true}
                negative={true}
              />
            </Typography>

            <Grid container spacing={2}>
              <Grid item sm={6}>
                <WlrLocationProductCard
                  locationIndex={locationIndex}
                  productId={transferProduct.id}
                  title={transferProduct.name}
                  subtitle={`from £${transferProduct.price.first_bill_recurring_price_with_promotions} per line (RRP)`}
                  handleClick={() =>
                    setOtherConfiguration(locationIndex, transferProduct)
                  }
                />
              </Grid>

              {mpfProducts.length > 0 &&
              !isActivationCodeP && ( // TODO: What's the deal with MPF transfers for DWP Pricebooks? Show all here too as above? What about service type matching?
                  <Grid item sm={6}>
                    <WlrLocationProductCard
                      locationIndex={locationIndex}
                      productId={mpfProducts[0].id}
                      title={mpfProducts[0].name}
                      subtitle={`from £${mpfProducts[0].price.first_bill_recurring_price_with_promotions} per line (RRP)`}
                      handleClick={() =>
                        setOtherConfiguration(locationIndex, mpfProducts[0])
                      }
                    />
                  </Grid>
                )}

              {(installationDetails.response.service_type ===
                "PSTN_MULTI_LINE_AUX" ||
                installationDetails.response.service_type ===
                  "DC_PRODUCT_MULTI_LINE") && (
                <Grid item sm={12}>
                  <WlrLocationProductCard
                    locationIndex={locationIndex}
                    productId={multiLineProducts[0].id}
                    title={multiLineProducts[0].name}
                    subtitle={`from £${multiLineProducts[0].price.first_bill_recurring_price_with_promotions} per line (RRP)`}
                    handleClick={() =>
                      setOtherConfiguration(locationIndex, multiLineProducts[0])
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }

    // Working Line Take over
    if (isWLTO) {
      return (
        <div>
          <Typography variant="h4" gutterBottom>
            Choose WLTO transfer product
          </Typography>
          <Box mb={1}>
            <StatusChip
              type="info"
              title={`This will be a working line takeover of an MPF line.`}
              message={`Access Line ID: ${_.get(
                wltoDetails.response,
                "existing_line_id",
                "Unknown"
              )}`}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <WlrLocationProductCard
                locationIndex={locationIndex}
                productId={singleLineProducts[0].id}
                title={singleLineProducts[0].name}
                subtitle={`from £${singleLineProducts[0].price.first_bill_recurring_price_with_promotions} per line (RRP)`}
                handleClick={() =>
                  setOtherConfiguration(locationIndex, singleLineProducts[0])
                }
              />
            </Grid>
          </Grid>
        </div>
      );
    }

    // We can't supply broadband to a non-BT line, and if that's the case, a WLTO check will have been carried out. Advise the user... FB126268
    if (
      locationType === BROADBAND_ONLY &&
      _.get(wltoDetails.response, "line_plant_type") === "WORKING_LINE"
    ) {
      return (
        <StatusChip
          type="info"
          title="This line is not on the BT network."
          message="Please choose Line Transfer to perform a Working Line Takeover if required."
        />
      );
    }

    if (
      locationType === TRANSFER &&
      installationDetails.response.service_type &&
      !transferProduct
    ) {
      return (
        <StatusChip
          type="error"
          title="No line transfer product available for this location."
          message={
            <span>
              Service type is reported as{" "}
              <b>{installationDetails.response.service_type}</b>. For further
              details please see the Provisioning teams
            </span>
          }
        />
      );
    }

    // Broadband only: A dummy product that will later have broadband added to it
    // ....it'll be on a line still, just not one of ours. Hence this data structure makes sense.

    // TODO: Seems sensible to use installationDetails here as additional check. New though, so confirm. ....check it's PSTN_EXISTING_LINE?
    // TODO: Show some of the extra info that comes back from that check... company name n that
    if (
      locationType === BROADBAND_ONLY &&
      _.get(installationDetails.response, "service_type")
    ) {
      const existingLineTitle = isWlrChangePossible
        ? "Existing Daisy Line"
        : "Existing Line (non-Daisy)";
      const existingLineSubtitle = isWlrChangePossible
        ? "Change requests can be made on the next step"
        : "£0.00";
      return (
        <>
          <div>
            <Typography variant="h4" gutterBottom>
              <IndexedTitle
                index={"C"}
                title={"Choose existing line"}
                withMargin={true}
                negative={true}
              />
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <WlrLocationProductCard
                  locationIndex={locationIndex}
                  productId={EXISTING_LINE}
                  title={existingLineTitle}
                  subtitle={existingLineSubtitle}
                  product={{ id: EXISTING_LINE }}
                  handleClick={() =>
                    setOtherConfiguration(locationIndex, { id: EXISTING_LINE })
                  }
                />
              </Grid>
              {sogeaProducts?.length > 0 && (
                <Grid item sm={6}>
                  <WlrLocationProductCard
                    locationIndex={locationIndex}
                    productId={EXISTING_SOGEA}
                    title={EXISTING_SOGEA}
                    subtitle={"£0.00"}
                    handleClick={() => {
                      this.setState({ existingSoGEAAlertOpen: true });
                      setOtherConfiguration(locationIndex, {
                        id: EXISTING_SOGEA
                      });
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </div>
          <ExistingSoGEAAlertDialog
            open={this.state.existingSoGEAAlertOpen}
            onClose={() => this.setState({ existingSoGEAAlertOpen: false })}
          />
        </>
      );
    }

    if (installationDetails.response.warning)
      return (
        <StatusChip
          type="info"
          title="Installation Details Warning"
          message={installationDetails.response.warning}
        />
      );

    return false;
  }
}

const makeMapStateToProps = () => {
  const getTransferProduct = makeGetTransferProduct();
  const getRequiredSparePairs = makeGetRequiredSparePairs();
  const getConfigsAtLocation = makeGetConfigsAtLocation();
  return (state, ownProps) => ({
    locationType: state.wlrBroadband.locations[ownProps.locationIndex]?.type,
    lineAvailability:
      state.wlrBroadband.locations[ownProps.locationIndex]?.lineAvailability,
    installationDetails:
      state.wlrBroadband.locations[ownProps.locationIndex]?.installationDetails,
    wltoDetails:
      state.wlrBroadband.locations[ownProps.locationIndex]?.wltoDetails,
    broadbandSearch:
      state.wlrBroadband.locations[ownProps.locationIndex]?.broadbandSearch,
    isActivationCodeP: isActivationCodeP(state, ownProps),
    lineSearch: state.wlrBroadband.lineSearch,
    configurations: getConfigsAtLocation(state, ownProps),
    singleLineProducts: getSingleLineProducts(state),
    multiLineProducts: getMultiLineProducts(state),
    mpfProducts: getMPFProducts(state, ownProps),
    transferProduct: getTransferProduct(state, ownProps),
    requiredSparePairs: getRequiredSparePairs(state, ownProps),
    isWlrChangePossible: getIsWlrChangePossible(state, ownProps.locationIndex),
    isWLTO: getIsWLTO(state.wlrBroadband, ownProps.locationIndex),
    sogeaProducts: getSOGEAProducts(state, ownProps),
    accountSettings: getAccountSettings(state)
  });
};

export default connect(makeMapStateToProps, {
  setOtherConfiguration
})(ChooseLineProducts);
