import React, { Component } from "react";
import { connect } from "react-redux";
import ConfigurationForm from "./ConfigurationForm";
import MobileConfigurationTable from "./MobileConfigurationTable";
import BoltOns from "./BoltOns/BoltOns";
import * as Sentry from "@sentry/browser";
import {
  requestDaisyFreshSearch,
  requestHardwareCreditProduct,
  requestAllCliBoltOnProducts,
  requestAllMobileProductData,
  requestAllResignPropertyValues
} from "../../../store/mobile/actionCreators";
import DaisyFresh from "./DaisyFresh";
import { getStandardConfigCount } from "../../../store/mobile/selectors/daisyFresh";
import BillCapConfirmation from "./BillCapConfirmation";
import { getAccountSettings } from "../../../store/account/selectors";
import {
  TargetConfigsProvider,
  TargetConfigsConsumer
} from "../context/TargetConfigsContext";

class MobileConfigurations extends Component {
  componentDidMount() {
    Sentry.addBreadcrumb({
      message: `Step 2 Mobile mounted`,
      category: "action"
    });
    this.props.requestAllMobileProductData();
    if (this.props.settings.dws_reseller_enabled !== "1") {
      this.props.requestAllCliBoltOnProducts();
    }
    this.props.requestAllResignPropertyValues();
    this.props.requestDaisyFreshSearch(); // TODO: This could be fired in step 1 to speed things up.
    this.props.requestHardwareCreditProduct();
  }

  render() {
    const { standardConfigCount, settings } = this.props;

    return (
      <TargetConfigsProvider>
        <div className="MobileConfigurations">
          <TargetConfigsConsumer>
            {({ targetConfigs }) =>
              targetConfigs.length > 0 ? (
                <ConfigurationForm />
              ) : (
                <>
                  <MobileConfigurationTable />
                  <BoltOns />
                  {settings.as_daisy_fresh_enabled === "1" &&
                    standardConfigCount > 0 && <DaisyFresh />}
                </>
              )
            }
          </TargetConfigsConsumer>

          {settings.dws_reseller_enabled !== "1" && <BillCapConfirmation />}
        </div>
      </TargetConfigsProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  standardConfigCount: getStandardConfigCount(state),
  settings: getAccountSettings(state)
});

export default connect(mapStateToProps, {
  requestAllMobileProductData,
  requestAllCliBoltOnProducts,
  requestAllResignPropertyValues,
  requestDaisyFreshSearch,
  requestHardwareCreditProduct
})(MobileConfigurations);
