import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import Spinner from "../../../components/Shared/Spinner";

function PriceMeta({ component_price, priceType, fetching }) {
  let data = [];

  if (priceType === "RECURRING") {
    const priceWithPromo =
      component_price.first_bill_recurring_price_with_promotions; // ....and discounts
    const priceWithoutPromo =
      component_price.recurring_price_without_promotions; // ....but still with discounts
    const priceWithoutDiscounts =
      component_price.recurring_price_without_discounts;
    data.push({
      title: "Recurring Price",
      value: `£${priceWithPromo}`,
      subValue:
        (priceWithPromo !== priceWithoutPromo ||
          priceWithoutPromo !== priceWithoutDiscounts) &&
        `£${priceWithoutDiscounts} without discount`
    });

    if (component_price.promotion_id) {
      const promo = _.find(
        component_price.pricing_scheme_details,
        s => s.promotion_id === component_price.promotion_id
      );

      // A promo ID can exist, with no data on said promo, hence this.
      // API logic thing probably... This is around @robert.moore's non-dynamic product work for the platform.
      if (promo) {
        data.push({
          title: "Promotion",
          value: promo.promotion_name
        });
        data.push({
          title: "Promotion Length",
          value: `${promo.promotion_length_in_months} months`
        });
      }
    }
  }

  if (priceType === "ONE_OFF") {
    const priceWithPromo = component_price.one_off_price_with_promotions;
    const priceWithoutPromo = component_price.one_off_price_without_promotions;
    // Note: there's also one_off_price_without_discounts... which one is it?
    data.push({
      title: "One off price",
      value: `£${priceWithPromo}`,
      subValue:
        priceWithPromo !== priceWithoutPromo &&
        `£${priceWithoutPromo} without discount`
    });
  }

  return (
    <Outer data-cy="PriceMeta">
      {fetching && (
        <Fetching>
          <Spinner color="white" />
        </Fetching>
      )}
      <table>
        <thead>
          <tr>
            {data.map((e, i) => (
              <th key={i}>{e.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {data.map((e, i) => (
              <td key={i}>
                {e.value}
                <span>{e.subValue}</span>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </Outer>
  );
}

PriceMeta.propTypes = {
  component_price: PropTypes.object.isRequired,
  priceType: PropTypes.oneOf(["ONE_OFF", "RECURRING"])
};

const Outer = styled.div`
  position: relative;
  background: ${p => p.theme.palette.info.main};
  border-radius: 5px;
  color: white;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead > tr {
    background: rgba(255, 255, 255, 0.25);
    text-align: left;
    font-size: 0.9em;
  }
  th,
  td {
    padding: 8px 10px;
  }
  th {
    font-weight: normal;
  }
  td {
    font-weight: bold;
  }
  span {
    opacity: 0.6;
    font-size: 0.9em;
    font-weight: normal;
    margin-left: 5px;
  }
`;

const Fetching = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
`;

export default PriceMeta;
