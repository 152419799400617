import account from "./account/reducer";
import accountSelect from "./accountSelect/reducer";
import quote from "./quote/reducer";
import generateContract from "./generateContract/reducer";
import supportCase from "./supportCase/reducer";
import uiState from "./uiState/reducer";
import { combineReducers } from "redux";
import drafts from "./drafts/reducer";
import shipping from "./shipping/shippingReducer";
import ethernetProducts from "./ethernetProducts/reducer";
import hardwareDelivery from "./hardwareDelivery/reducer";
import order from "./order/reducer";
import wlrBroadband from "./wlrBroadband/reducer/index";
import hardwareProducts from "./hardware/products/reducer";
import hardwareConfigurations from "./hardware/configurations/reducer";
import hardwareOrders from "./hardware/hardwareOrders/reducer";
import mobile from "./mobile/reducer/index";
import universalProducts from "./universalProducts/reducer";
import monitoringService from "./monitoringService/reducer";
import * as rootActionTypes from "./root/actionTypes";
import { resetLoadingStates } from "./drafts/actionCreators";

const rootReducer = combineReducers({
  account,
  accountSelect,
  quote,
  generateContract,
  supportCase,
  drafts,
  mobile,
  hardwareDelivery,
  order,
  shipping,
  uiState,
  ethernetProducts,
  wlrBroadband,
  hardwareProducts,
  hardwareConfigurations,
  hardwareOrders,
  universalProducts,
  monitoringService
});

export default (state, action) => {
  if (action.type === rootActionTypes.CLEAR_STATE) {
    state = undefined;
  }
  if (action.type === rootActionTypes.LOAD_WIZARD_STATE) {
    state = resetLoadingStates(action.state);
  }
  return rootReducer(state, action);
};
