import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

function TableOuter({ hideAction, children }) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Product</TableCell>
          <TableCell>One Off Price</TableCell>
          <TableCell>Recurring Price</TableCell>
          <TableCell>Configured?</TableCell>
          {!hideAction && <TableCell>Action</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
}

TableOuter.propTypes = {
  hideAction: PropTypes.bool
};

export default TableOuter;
