import * as actionTypes from "./actionTypes";
import { trackSimpleEvent } from "../../helpers/reduxBeacon";

const gaEventsMap = {
  ...trackSimpleEvent(actionTypes.ADD_PRODUCT),
  ...trackSimpleEvent(actionTypes.REMOVE_PRODUCT),
  ...trackSimpleEvent(actionTypes.ADD_USER_DOCUMENT)
};

export default gaEventsMap;
