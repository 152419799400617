import React from "react";
import { Box, Button, makeStyles, Theme, Typography } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import BlockIcon from "@material-ui/icons/Block";
import BulkSelectPopover from "../../../../components/Shared/BulkSelectPopover";

interface ResignsTableActionsProps {
  infoLabel: string;
  disabled: boolean;
  doResignWithoutChanges: () => void;
  doResignNone: () => void;
  bulkSelectByCLI: (event: any) => void;
}

export const ResignsTableActions = ({
  infoLabel,
  disabled,
  doResignWithoutChanges,
  doResignNone,
  bulkSelectByCLI
}: ResignsTableActionsProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box>
        <Typography className={classes.info}>{infoLabel}</Typography>
      </Box>
      <Box className={classes.actions}>
        {[
          {
            label: "Resign Without Changes",
            Icon: ReplayIcon,
            show: !!doResignWithoutChanges,
            handleClick: doResignWithoutChanges
          },
          {
            label: "Do Not Resign",
            Icon: BlockIcon,
            show: !!doResignNone,
            handleClick: doResignNone
          }
        ]
          .filter(({ show }) => show)
          .map(({ label, Icon, handleClick }) => (
            <Button
              key={label}
              color="primary"
              variant="text"
              size="large"
              disabled={disabled}
              onClick={handleClick}
              startIcon={<Icon />}
            >
              {label}
            </Button>
          ))}
        <BulkSelectPopover bulkSelectByCLI={bulkSelectByCLI} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  info: {
    color: theme.palette.grey[300]
  },
  actions: {}
}));
