import React, { Component } from "react";
import { connect } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ExpandingCard } from "@akj-dev/design-system";
import ExtensionIcon from "@material-ui/icons/Extension";
import { isWorkingDay } from "../../../../helpers/date";
import { getSelectedBoltOnCount } from "../../../../store/mobile/selectors/boltOns";
import { setAccountBoltOnStartDate } from "../../../../store/mobile/actionCreators";
import { DC_DATE_FORMAT, todaysDateString } from "../../../../helpers/date";

class BoltOns extends Component {
  static propTypes = {};

  state = {
    expanded: false,
    selected: false
  };

  render() {
    const {
      resignStartDate,
      boltOnStartDate,
      boltOnCount,
      setAccountBoltOnStartDate
    } = this.props;

    if (boltOnCount > 0) {
      return (
        <ExpandingCard
          defaultExpanded
          title="Bolt Ons"
          subtitle={boltOnCount + " Selected"}
          avatar={<ExtensionIcon />}
        >
          <KeyboardDatePicker
            name="boltOnStartDate"
            label="Add start date"
            format={DC_DATE_FORMAT}
            shouldDisableDate={date => !isWorkingDay(date)}
            minDate={resignStartDate}
            onChange={(e, date) => setAccountBoltOnStartDate(date)}
            value={boltOnStartDate || todaysDateString}
          />
        </ExpandingCard>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    resignStartDate: state.mobile.resignStartDate,
    boltOnStartDate: state.mobile.boltOnStartDate,
    boltOnSearch: state.mobile.boltOnSearch.response,
    boltOnCount:
      getSelectedBoltOnCount(state, 1) + getSelectedBoltOnCount(state, 2)
  };
};

export default connect(mapStateToProps, {
  setAccountBoltOnStartDate
})(BoltOns);
