import React from "react";
import Icon from "@material-ui/icons/Redeem";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";

const CallBundles = props => {
  return (
    <Section title="Call Bundles" avatar={<Icon />} defaultExpanded={false}>
      <WlrBBDynamicField
        productType="wlr"
        propertyName="fixed_line_call_bundle_id"
        margin
      />
      <WlrBBDynamicField
        productType="wlr"
        propertyName="mobile_call_bundle_id"
        margin
      />
    </Section>
  );
};

export default CallBundles;
