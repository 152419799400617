import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Content from "./Content";
import { resetUploadStatus } from "../../../store/order/actions";
import {
  Button,
  Dialog,
  DialogTitle,
  CircularProgress
} from "@material-ui/core";

class UploadSignedContract extends Component {
  static defaultProps = {};
  static propTypes = {
    order: PropTypes.object
  };

  state = {
    showDialog: false
  };

  openDialog = () => {
    this.props.dispatch(resetUploadStatus());
    this.setState({ showDialog: true });
  };

  closeDialog = () => {
    this.setState({ showDialog: false });
  };

  render() {
    const { orderStatus } = this.props;

    return (
      <>
        <Dialog
          open={this.state.showDialog}
          onClose={this.closeDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Upload Signed Contract</DialogTitle>
          <Content closeDialog={this.closeDialog} />
        </Dialog>
        <div style={{ marginTop: "16px" }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: 20 }}
            onClick={this.openDialog}
            endIcon={
              orderStatus.fetching && (
                <CircularProgress size={20} color="inherit" />
              )
            }
            disabled={
              !orderStatus.response.data?.actions?.upload_signed_contract?.ok
            }
            data-cy={!orderStatus.fetching && "UploadSignedContractAvailable"}
          >
            Upload Signed Contract
          </Button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orderStatus: state.order.orderStatus
  };
};

export default connect(mapStateToProps)(UploadSignedContract);
