import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ExpandingCard } from "@akj-dev/design-system";

const Section = ({
  title,
  avatar,
  defaultExpanded = true,
  children,
  "data-cy": dataCy = null
}) => {
  return (
    <SectionOuter data-cy={dataCy}>
      <ExpandingCard {...{ title, avatar, defaultExpanded }}>
        {children}
      </ExpandingCard>
    </SectionOuter>
  );
};

const SectionOuter = styled.div`
  break-inside: avoid;
`;

Section.propTypes = {
  title: PropTypes.string,
  avatar: PropTypes.object,
  initiallyExpanded: PropTypes.bool
};

export default Section;
