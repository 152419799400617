import _ from "lodash";

/**
 * Transforms DC v1/Product/HardwareSearch response.
 *
 * - Reduces amount of data we need to store
 * - Applies logic for pricing schemes we should display in step 1 table.
 *
 * Returns data like:
 *
 * {
     "status": "success",
     "products": [
       {
         name: "phone name",
         price: {
           oneOff: "1.23",
           oneOffWithConnection: "1.45",
           leaseRV: "1.00",
           leaseNonRV: "1.11",
         }
       }
     ]
   }
 * @param response
 */
export function transformHardwareSearch(response) {
  return {
    status: response.status,
    message: response.message,
    products: (response.products || []).map(product => {
      const oneOffScheme = findSchemeByPriority(
        product,
        scheme => scheme.is_leasing === 0 && scheme.is_hardware_only === 1
      );
      const oneOffWithConnectionScheme = findSchemeByPriority(
        product,
        scheme => scheme.is_leasing === 0 && scheme.is_hardware_only === 0
      );
      const leaseRVScheme = findSchemeByPriority(
        product,
        scheme => scheme.is_leasing === 1 && scheme.residual_value === 1
      );
      const leaseNonRVScheme = findSchemeByPriority(
        product,
        scheme => scheme.is_leasing === 1 && scheme.residual_value === 0
      );

      const o = {
        id: product.id,
        name: product.name,

        // Figures for displaying to the user + calculating totals etc.
        price: {
          oneOff: (oneOffScheme || product.price).one_off_price_with_promotions,
          oneOffWithConnection: (oneOffWithConnectionScheme || product.price)
            .one_off_price_with_promotions,
          leaseRV: _.get(
            leaseRVScheme,
            "first_bill_recurring_price_with_promotions"
          ),
          leaseNonRV: _.get(
            leaseNonRVScheme,
            "first_bill_recurring_price_with_promotions"
          )
        },

        // Names of the above pricing schemes for OrderProduct calls.
        pricingSchemes: {
          oneOff: _.get(oneOffScheme, "pricing_scheme"),
          oneOffWithConnection: _.get(
            oneOffWithConnectionScheme,
            "pricing_scheme"
          ),
          leaseRV: _.get(leaseRVScheme, "pricing_scheme"),
          leaseNonRV: _.get(leaseNonRVScheme, "pricing_scheme")
        }
      };
      return o;
    })
  };
}

/**
 * Find the first scheme that matches the specified conditions,
 * prioritised by the order set in available_pricing_schemes[]
 * @param product - Object
 * @param predicate - Function
 * @returns {*}
 */
function findSchemeByPriority(product, predicate) {
  for (let i = 0; i < product.available_pricing_schemes.length; i++) {
    const scheme =
      product.pricing_scheme_details[product.available_pricing_schemes[i]];
    if (predicate(scheme)) return scheme;
  }
}
