import React, { createContext, ReactElement, useContext } from "react";
import { parseISO, getUnixTime } from "date-fns";
import {
  AccountMeta,
  AccountSettings,
  authAPI,
  SessionJwtPayload,
  UserRole
} from "./authAPI";
import jwtDecode from "jwt-decode";
import {
  AuthCookies,
  clearCookies,
  getCookieValue,
  setCookieValue
} from "./cookiesHandler";

interface APIFetch<T> {
  (resource: string, options: object): Promise<T>;
}

export interface Login {
  (username: string, password: string): void;
}

interface LoginUsingToken {
  (token: string): void;
}

export interface Logout {
  (): Promise<"success" | string>;
}

type AuthProviderProps = {
  /** Base URL for the External Users app
   * eg. https://pub-external-users-testenv01.uat.aurora.io/api/v2/
   * The response from here will set the Kong API base
   */
  authApiBaseUrl: string;

  /**
   * A short-lived login token can be supplied by systems doing pseudo SSO
   * such as the DWP. It's used against the same /v2/login endpoint as a
   * standard Basic Auth, but it's a bearer token. A refresh token will be
   * returned as in a standard login flow.
   */
  loginToken?: string;

  /**
   * A refresh token can be supplied manually.
   * This is mainly for dev where we want to ignore the full login workflow.
   */
  refreshToken?: string;

  /**
   * UI to collect username and password from user and show loading and error
   * state following submission
   */
  loginScreen: (
    handleLogin: Login,
    loading: boolean,
    error: string
  ) => ReactElement;

  /**
   * UI to show initial session loading, where a refresh token already exists
   * from a previous login
   */
  initScreen: () => ReactElement;

  /**
   * UI to show general auth errors with retry option
   */
  errorScreen: (message: string, retry: () => void) => ReactElement;
};

type AuthProviderState = {
  refreshToken?: string;
  sessionToken: string;
  sessionTokenExpiry: number; // Unix time
  accountMeta: AccountMeta;
  accountSettings: AccountSettings;
  salesPersonId?: string;
  userRole: UserRole;
  externalUserName: string;
  apiBaseUrl: string;
  loading: boolean;
  error: string;
};

interface AuthContext {
  /**
   * Authed fetch function to use for any API request via Kong
   */
  apiFetch: APIFetch<Response>;
  /**
   * Base URL used for API fetch.
   * Populated from session token response. Just only in uat/dev to show in the
   * UATBanner
   */
  apiBaseUrl: string;
  /**
   * User role object returned rom the Users App at initial login
   */
  userRole: UserRole;
  /**
   * Full User name
   * Decoded from JWT as that's the only place it exists for some unknown reason
   */
  externalUserName?: string;
  /**
   * Account Meta info from DC. Head account ID and name
   */
  accountMeta: AccountMeta;
  /**
   * Account settings from DC
   */
  accountSettings: AccountSettings;
  /**
   * Sales Person ID if this is a sales person login.
   *
   * See TP23267
   * When the user logged in belongs directly to an account
   * a sales person is someone that has users
   * but a sales person has access to multiple accounts
   */
  salesPersonId?: string;
  /**
   * Logout function
   * Invalidates the refresh token on the users app and wipes local credentials
   * and user meta
   */
  logout: Logout;
}

const AuthContext = createContext<AuthContext>({} as AuthContext);

/**
 * Proxy to api fetch function so it can be used outside context
 * In Redux for instance.
 */
export let platformApiFetch: APIFetch<Response>;
export let authApiFetch: APIFetch<Response>;

/**
 * Use authenticated API functions
 */
export const useAuth = () => useContext(AuthContext);

/**
 * Auth provider:
 *  - looks for existing refresh token or login token
 *  - gets refresh token
 *  - gets and refreshes session tokens
 *  - provides API secure fetch method
 *  - provides user meta
 *
 * Note this cannot be hooks based - lifecycle issues
 * @param children
 * @constructor
 */
export class AuthProvider extends React.Component<
  AuthProviderProps,
  AuthProviderState
> {
  state: AuthProviderState = {
    refreshToken:
      this.props.refreshToken || getCookieValue(AuthCookies.REFRESH_TOKEN),
    sessionToken: "",
    sessionTokenExpiry: 0,
    accountMeta: {
      id: "",
      name: ""
    },
    accountSettings: {},
    salesPersonId: undefined,
    externalUserName: "",
    userRole: JSON.parse(getCookieValue(AuthCookies.USER_ROLE) || "{}"),
    apiBaseUrl: "",
    loading: false,
    error: ""
  };

  /**
   * Perform a username + password login request against the external users app.
   * Returns a refresh token to get session tokens with.
   */
  login: Login = async (username, password) => {
    this.setState({ loading: true, error: "" });
    try {
      const response = await authAPI.login(
        this.props.authApiBaseUrl,
        username,
        password
      );
      this.setRefreshToken(response.refresh_token);
      this.setUserRole(response.user_role);
      this.setState({ loading: false });
    } catch (e) {
      this.setState({
        loading: false,
        error: e.message
      });
    }
  };

  /**
   * Perform a login request like login() but with a bearer token supplied by a
   * third party system. Pseudo-SSO
   */
  loginUsingToken: LoginUsingToken = async token => {
    this.setState({
      loading: true
    });
    try {
      const response = await authAPI.loginUsingToken(
        this.props.authApiBaseUrl,
        token
      );
      this.setRefreshToken(response.refresh_token);
      this.setUserRole(response.user_role);
      this.setState({ loading: false, error: "" });
    } catch (e) {
      this.setState({
        loading: false,
        error: e.message
      });
    }
  };

  /**
   * Perform a logout request.
   * This invalidates the refresh token with the users app and clears local data
   */
  logout: Logout = async () => {
    const { refreshToken } = this.state;
    if (refreshToken) {
      try {
        await authAPI.logout(this.props.authApiBaseUrl, refreshToken);
        this.destroyTokens();
        return "success";
      } catch (e) {
        return e.message;
      }
    } else {
      return "No refresh token. Cannot log out.";
    }
  };

  /**
   * Set and persist a refresh token
   */
  setRefreshToken = (refreshToken: string) => {
    this.setState({ refreshToken });
    setCookieValue(AuthCookies.REFRESH_TOKEN, refreshToken);
  };

  /**
   * Set and persist the user role object
   * This is authorisation data from the users app we receive with the refresh
   * token. It's different from Account Settings data.
   */
  setUserRole = (userRole: UserRole | any) => {
    // Can't trust the API, as usual...
    if (typeof userRole === "object") {
      this.setState({ userRole });
      setCookieValue(AuthCookies.USER_ROLE, JSON.stringify(userRole));
    }
  };

  /**
   * Check if we should refresh the session token.
   * Note setTimeout doesn't work here as it doesn't fire after the browser sleeps.
   * Hence we setInterval and poll if the token is expired vs system time every 2s
   */
  refreshSessionTokenPoller = () => {
    const unixNow = getUnixTime(new Date());
    if (
      this.state.sessionTokenExpiry > 0 &&
      this.state.sessionTokenExpiry - 120 < unixNow
    ) {
      this.fetchSessionToken();
    }
  };

  tokenIntervalID = 0;

  /**
   * On mount:
   * If there's a login token passed as a prop when initialising, start the flow
   * to get a refresh token.
   *
   * If there's a refresh token passed on init, start the normal session token
   * request - this is just for dev if we want to skip the login form and supply
   * a token direct
   *
   * Start the poller to refresh stale session tokens
   */
  componentDidMount() {
    if (this.props.loginToken && !this.state.refreshToken) {
      this.loginUsingToken(this.props.loginToken);
    }

    if (this.state.refreshToken && !this.state.sessionToken) {
      this.fetchSessionToken();
    }
    this.tokenIntervalID = window.setInterval(
      this.refreshSessionTokenPoller,
      4000
    );
  }

  componentWillUnmount() {
    clearInterval(this.tokenIntervalID);
  }

  /**
   * We don't want to re-render the AuthProvider unless absolutely necessary as
   * that triggers re-fetching of queries by the Apollo provider inside.
   * Mainly just "don't do it if the session token refreshes"
   */
  shouldComponentUpdate(
    nextProps: Readonly<AuthProviderProps>,
    nextState: Readonly<AuthProviderState>,
    nextContext: any
  ): boolean {
    if (nextProps !== this.props) return true;
    if (nextState.refreshToken !== this.state.refreshToken) return true;
    if (nextState.apiBaseUrl !== this.state.apiBaseUrl) return true;
    if (nextState.externalUserName !== this.state.externalUserName) return true;
    if (nextState.loading !== this.state.loading) return true;
    if (nextState.error !== this.state.error) return true;
    return false;
  }

  /**
   * If the refresh token changes (i.e we now have one following a login), get a
   * session token
   */
  componentDidUpdate(
    prevProps: Readonly<AuthProviderProps>,
    prevState: Readonly<AuthProviderState>
  ) {
    if (
      this.state.refreshToken !== prevState.refreshToken &&
      this.state.refreshToken
    ) {
      this.fetchSessionToken();
    }
  }

  /**
   * Get A JWT session token from the users app
   *
   * This will be used to authenticate API requests within apiFetch().
   * It's granted from the refresh token we get in login() / loginUsingToken()
   * The expiry time is also stored in state so the poller above knows when to
   * call this method again.
   * Also parses the username from te token as that's the only place it's
   * available for some reason...
   */
  fetchSessionToken = async () => {
    if (this.state.refreshToken) {
      try {
        // TODO: Can this differentiate 401 from network error so latter doesn;t
        //  cause logout?
        const response = await authAPI.jwt(
          this.props.authApiBaseUrl,
          this.state.refreshToken
        );
        if (
          response.jwt &&
          response.expires_utc &&
          response.api_url_base &&
          response.settings &&
          response.meta
        ) {
          // Fake JWT passed from mock API for Cypress tests, so don't fail on parse error
          let externalUserName = "Unknown";
          try {
            const sessionTokenDecoded = jwtDecode<SessionJwtPayload>(
              response.jwt
            );
            externalUserName = sessionTokenDecoded.external_user_name;
          } catch (e) {
            console.error("Error parsing JWT session token");
          }

          // Set session token and base for access outside of component lifecycle
          setCookieValue(AuthCookies.SESSION_TOKEN, response.jwt);
          setCookieValue(AuthCookies.API_BASE_URL, response.api_url_base);

          // Set all session data for use in context (useAuth)
          this.setState({
            sessionToken: response.jwt,
            apiBaseUrl: response.api_url_base,
            externalUserName,
            sessionTokenExpiry: getUnixTime(
              parseISO(`${response.expires_utc}Z`)
            ),
            accountMeta: response.meta,
            accountSettings: response.settings,
            salesPersonId: response.sales_person_id,
            error: "",
            loading: false
          });
        } else {
          this.setState({ error: "Session token format invalid" });
        }
      } catch (e) {
        if (e.message === "Unauthorized") {
          this.destroyTokens();
        }
        this.setState({
          error: e.message
        });
      }
    } else {
      console.log("No refresh token. Will not fetch session token.");
    }
  };

  /**
   * Fetch API resource with authorization
   * This is the main thing we came for!
   */
  apiFetch: APIFetch<Response> = async (resource, options: RequestInit) => {
    let { sessionToken, apiBaseUrl } = this.state;
    return fetch(`${apiBaseUrl}${resource.replace(/^\/+/g, "")}`, {
      ...options,
      referrerPolicy: "origin",
      headers: {
        ...options.headers,
        Authorization: `Bearer ${sessionToken}`
      }
    });
  };

  /**
   * Fetch API resource with authorization
   * @param resource
   * @param options
   */
  authApiFetch: APIFetch<Response> = async (resource, options: RequestInit) => {
    const { refreshToken } = this.state;

    console.log("authApiFetch", resource);

    return authAPI._fetch(this.props.authApiBaseUrl, resource, {
      ...options,
      referrerPolicy: "origin",
      headers: {
        ...options.headers,
        Authorization: `Bearer ${refreshToken}`
      }
    });
  };

  /**
   * Destroy user auth tokens and meta
   * Note it's the Suite / consuming app's responsibility to clear other local
   * state - unmount components, redux etc.
   */
  destroyTokens = () => {
    console.log("Destroying tokens (state & cookies)...");
    clearCookies();
    this.setState({
      refreshToken: "",
      sessionToken: "",
      apiBaseUrl: ""
    });
  };

  /**
   * Render provider
   * It will only show children if user is fully authenticated. If not it will
   * show login / loading / error screens passed as render props.
   */
  render() {
    platformApiFetch = this.apiFetch;
    authApiFetch = this.authApiFetch;

    /**
     * Login token but no refresh token?
     * We must be doing one of these pseudo-SSO logins (eg.DWP)
     */
    if (this.props.loginToken && !this.state.refreshToken) {
      return this.props.initScreen();
    }

    /**
     * Just no refresh token? We want to login.
     */
    if (!this.state.refreshToken) {
      return this.props.loginScreen(
        this.login,
        this.state.loading,
        this.state.error
      );
    }

    /**
     * There's been a problem fetching a JWT or something. Show an error screen.
     */
    if (this.state.error) {
      return this.props.errorScreen(this.state.error, () => {
        this.destroyTokens();
        this.setState({ loading: false, error: "" });
      });
    }

    /**
     * No session token? We have a refresh token so we must be fetching one at
     * the start of the session.
     */
    if (!this.state.sessionToken || !this.state.apiBaseUrl) {
      return this.props.initScreen();
    }

    /**
     * We are authenticated.
     * Render the provider and children. i.e. the app.
     */
    const value: AuthContext = {
      apiFetch: this.apiFetch,
      userRole: this.state.userRole,
      externalUserName: this.state.externalUserName,
      accountMeta: this.state.accountMeta,
      accountSettings: this.state.accountSettings,
      salesPersonId: this.state.salesPersonId,
      logout: this.logout,
      apiBaseUrl: this.state.apiBaseUrl
    };

    return (
      <AuthContext.Provider value={value}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
