// Set contract length
export const SET_CONTRACT_LENGTH = "wlrBroadband.SET_CONTRACT_LENGTH";

// Locations are the base level for product configs.
export const ADD_LOCATION = "wlrBroadband.ADD_LOCATION";
export const REMOVE_LOCATION = "wlrBroadband.REMOVE_LOCATION";
export const REMOVE_PRODUCTS_FOR_LOCATION =
  "wlrBroadband.REMOVE_PRODUCTS_FOR_LOCATION";

// They can be one of three types. (see `constants.js`)
export const SET_LOCATION_TYPE = "wlrBroadband.SET_LOCATION_TYPE";

// Each location has an address, populated by a postcode lookup
export const SET_LOCATION_ADDRESS = "wlrBroadband.SET_LOCATION_ADDRESS";
// ...and a CLI if it's a transfer or BB only.
export const SET_LOCATION_CLI = "wlrBroadband.SET_LOCATION_CLI";

// We query the API on things:

// What line products are available in general (Single, Multi, ISDN etc...)
export const REQUEST_LINE_SEARCH = "wlrBroadband.REQUEST_LINE_SEARCH";
export const RECEIVE_LINE_SEARCH = "wlrBroadband.RECEIVE_LINE_SEARCH";

// Check a CLI's valid
export const REQUEST_VALIDATE_CLI = "wlrBroadband.REQUEST_VALIDATE_CLI";
export const RECEIVE_VALIDATE_CLI = "wlrBroadband.RECEIVE_VALIDATE_CLI";

// What capacity is available at each location (when it's a new provide) ...
// Single line requires 1 dp_spare_pairs, multi-line requires 5
export const REQUEST_LINE_AVAILABILITY =
  "wlrBroadband.REQUEST_LINE_AVAILABILITY";
export const RECEIVE_LINE_AVAILABILITY =
  "wlrBroadband.RECEIVE_LINE_AVAILABILITY";

// What the current line is when it's a transfer
export const REQUEST_INSTALLATION_DETAILS =
  "wlrBroadband.REQUEST_INSTALLATION_DETAILS";
export const RECEIVE_INSTALLATION_DETAILS =
  "wlrBroadband.RECEIVE_INSTALLATION_DETAILS";

// What the details are for a working line takeover
export const REQUEST_WLTO_DETAILS = "wlrBroadband.REQUEST_WLTO_DETAILS";
export const RECEIVE_WLTO_DETAILS = "wlrBroadband.RECEIVE_WLTO_DETAILS";

// What broadband products are available at each location
export const REQUEST_BROADBAND_SEARCH = "wlrBroadband.REQUEST_BROADBAND_SEARCH";
export const RECEIVE_BROADBAND_SEARCH = "wlrBroadband.RECEIVE_BROADBAND_SEARCH";

// WLR configurations belong to locations.
// They can be either one of the products returned from the `LineSearch` API or a special placeholder product when it's a BB-only order on an existing line.
export const ADD_WLR_CONFIGURATION = "wlrBroadband.ADD_WLR_CONFIGURATION";
export const REMOVE_WLR_CONFIGURATION = "wlrBroadband.REMOVE_WLR_CONFIGURATION";
export const SET_CONFIGURATION = "wlrBroadband.SET_CONFIGURATION";

// Broadband products belong to WLR products, as they are dependent upon a line.
export const SET_BROADBAND_PRODUCT = "wlrBroadband.SET_BROADBAND_PRODUCT";
// export const REMOVE_BROADBAND_PRODUCT = 'wlrBroadband.REMOVE_BROADBAND_PRODUCT'

// Then when progressing to the "Configure Products" step, we get product data for all selected products
export const REQUEST_PRODUCT_DATA = "wlrBroadband.REQUEST_PRODUCT_DATA";
export const RECEIVE_PRODUCT_DATA = "wlrBroadband.RECEIVE_PRODUCT_DATA";

// Product meta (care levels, address etc.) is set by the user. The ProductData API call tells us what options are available
export const SET_PRODUCT_PROPERTY = "wlrBroadband.SET_PRODUCT_PROPERTY";
// ...There's also some properties we send initialise automatically from the product data endpoint,
// that we need to populate from it's initial response...
export const SET_PROPERTIES_FROM_PRODUCT_DATA =
  "wlrBroadband.SET_PROPERTIES_FROM_PRODUCT_DATA";

// Pricing schemes can be switched and sometimes differ by account.
// They alter pricing (obvs!) and can have promotional offers.
export const SET_PRICING_SCHEME = "wlrBroadband.SET_PRICING_SCHEME";

// Discounts are still product meta, but have dependent properties, hence the separate action.
export const SET_PRODUCT_DISCOUNT = "wlrBroadband.SET_PRODUCT_DISCOUNT";
export const REMOVE_PRODUCT_DISCOUNT = "wlrBroadband.REMOVE_PRODUCT_DISCOUNT";

// Properties are subject to client side validation after entry
export const VALIDATE_PRODUCT_PROPERTY =
  "wlrBroadband.VALIDATE_PRODUCT_PROPERTY";

// Some of these options require further info:

// Appointment slots are needed for new line installs
export const REQUEST_WLR_APPOINTMENTS = "wlrBroadband.REQUEST_WLR_APPOINTMENTS";
export const RECEIVE_WLR_APPOINTMENTS = "wlrBroadband.RECEIVE_WLR_APPOINTMENTS";
export const SET_WLR_APPOINTMENT = "wlrBroadband.SET_WLR_APPOINTMENT";

// Once a slot is selected, it needs to be reserved.
export const REQUEST_ADD_WLR_APPOINTMENT =
  "wlrBroadband.REQUEST_ADD_WLR_APPOINTMENT";
export const RECEIVE_ADD_WLR_APPOINTMENT =
  "wlrBroadband.RECEIVE_ADD_WLR_APPOINTMENT";

// ....and for FTTC broadband installs
// /v1/BroadbandAppointments/GetAppointmentAvailability
export const REQUEST_BROADBAND_APPOINTMENTS =
  "wlrBroadband.REQUEST_BROADBAND_APPOINTMENTS";
export const RECEIVE_BROADBAND_APPOINTMENTS =
  "wlrBroadband.RECEIVE_BROADBAND_APPOINTMENTS";
export const SET_BROADBAND_APPOINTMENT =
  "wlrBroadband.SET_BROADBAND_APPOINTMENT";

// Again, once a slot is selected, it needs to be reserved.
export const REQUEST_ADD_BROADBAND_APPOINTMENT =
  "wlrBroadband.REQUEST_ADD_BROADBAND_APPOINTMENT";
export const RECEIVE_ADD_BROADBAND_APPOINTMENT =
  "wlrBroadband.RECEIVE_ADD_BROADBAND_APPOINTMENT";

// Numbers can be reserved if the user wishes. A list of available ones can be fetched per location
export const REQUEST_FIND_NUMBERS = "wlrBroadband.REQUEST_FIND_NUMBERS";
export const RECEIVE_FIND_NUMBERS = "wlrBroadband.RECEIVE_FIND_NUMBERS";

// The user picks one (or opts to reserve the next available)
export const SET_NUMBER_RESERVATION_TYPE =
  "wlrBroadband.SET_NUMBER_RESERVATION_TYPE";
export const SET_RESERVED_NUMBER = "wlrBroadband.SET_RESERVED_NUMBER";

// We call a service to perform the reservation and get refs back.
// Note there's two possible API calls for this one. `ReserveNextNumber` or `ReserveNumber` if the user hasn't chosen a specific one.
export const REQUEST_RESERVE_NUMBER = "wlrBroadband.REQUEST_RESERVE_NUMBER";
export const RECEIVE_RESERVE_NUMBER = "wlrBroadband.RECEIVE_RESERVE_NUMBER";

// There's remote validation endpoints (linked to acc4billing according to @jim)
// We send params to them to spot errors before firing OrderProduct/Create calls
// Unfortunately these errors don't refer to dynamic_property names. *sigh*
export const REQUEST_VALIDATE_WLR = "wlrBroadband.REQUEST_VALIDATE_WLR";
export const RECEIVE_VALIDATE_WLR = "wlrBroadband.RECEIVE_VALIDATE_WLR";

export const REQUEST_VALIDATE_WLR_QUOTE_ONLY =
  "wlrBroadband.REQUEST_VALIDATE_WLR_QUOTE_ONLY";
export const RECEIVE_VALIDATE_WLR_QUOTE_ONLY =
  "wlrBroadband.RECEIVE_VALIDATE_WLR_QUOTE_ONLY";

export const REQUEST_VALIDATE_BROADBAND =
  "wlrBroadband.REQUEST_VALIDATE_BROADBAND";
export const RECEIVE_VALIDATE_BROADBAND =
  "wlrBroadband.RECEIVE_VALIDATE_BROADBAND";

// All the data collected above gets used by the ordering process to add products to our order.

// We store the response to the resulting OrderProduct/Create call with the config
// That way we can show progress and errors per config and use it later to determine if
// a product is already part of the order (i.e. it has been previously added for a quote)
export const REQUEST_ORDER_PRODUCT = "wlrBroadband.REQUEST_ORDER_PRODUCT";
export const RECEIVE_ORDER_PRODUCT = "wlrBroadband.RECEIVE_ORDER_PRODUCT";

// What line product instances are on the account, that we could resign.
export const REQUEST_LINE_PRODUCT_INSTANCES =
  "wlrBroadband.REQUEST_LINE_PRODUCT_INSTANCES";
export const RECEIVE_LINE_PRODUCT_INSTANCES =
  "wlrBroadband.RECEIVE_LINE_PRODUCT_INSTANCES";

// What broadband product instances are on the account, that we could resign.
export const REQUEST_BROADBAND_PRODUCT_INSTANCES =
  "wlrBroadband.REQUEST_BROADBAND_PRODUCT_INSTANCES";
export const RECEIVE_BROADBAND_PRODUCT_INSTANCES =
  "wlrBroadband.RECEIVE_BROADBAND_PRODUCT_INSTANCES";

// Action types for resigns.

// Set the resign type. This can be one of three - 'New Product', 'Same Product With Changes' or 'Same Product'
export const SET_RESIGN_TYPE = "wlrBroadband.SET_RESIGN_TYPE";
// Set the new wlr product the user is resigning to.
export const SET_RESIGN_WLR_PRODUCT = "wlrBroadband.SET_RESIGN_WLR_PRODUCT";
// Set the new broadband product the user is resigning to.
export const SET_RESIGN_BROADBAND_PRODUCT =
  "wlrBroadband.SET_RESIGN_BROADBAND_PRODUCT";
// Remove the new resign configuration if the user chooses not to resign.
export const REMOVE_RESIGN = "wlrBroadband.REMOVE_RESIGN";

export const REQUEST_SAME_RESIGN_PRODUCT =
  "wlrBroadband.REQUEST_SAME_RESIGN_PRODUCT";
export const RECEIVE_SAME_RESIGN_PRODUCT =
  "wlrBroadband.RECEIVE_SAME_RESIGN_PRODUCT";

export const REQUEST_LINE_RESIGN_PRODUCT =
  "wlrBroadband.REQUEST_LINE_RESIGN_PRODUCT";
export const RECEIVE_LINE_RESIGN_PRODUCT =
  "wlrBroadband.RECEIVE_LINE_RESIGN_PRODUCT";

export const REQUEST_BROADBAND_RESIGN_PRODUCT =
  "wlrBroadband.REQUEST_BROADBAND_RESIGN_PRODUCT";
export const RECEIVE_BROADBAND_RESIGN_PRODUCT =
  "wlrBroadband.RECEIVE_BROADBAND_RESIGN_PRODUCT";

export const ADD_RESIGN_LOCATION = "wlrBroadband.ADD_RESIGN_LOCATION";
export const REMOVE_RESIGN_LOCATION = "wlrBroadband.REMOVE_RESIGN_LOCATION";

// Remove a special rate in step 2 when resigning a product instance.
export const REQUEST_REMOVE_SPECIAL_RATE =
  "wlrBroadband.REQUEST_REMOVE_SPECIAL_RATE";
export const RECEIVE_REMOVE_SPECIAL_RATE =
  "wlrBroadband.RECEIVE_REMOVE_SPECIAL_RATE";

export const SET_RESIGN_START_DATE = "wlrBroadband.SET_RESIGN_START_DATE";

export const ADD_SPECIAL_RATE_TO_REMOVE =
  "wlrBroadband.ADD_SPECIAL_RATE_TO_REMOVE";
export const DELETE_SPECIAL_RATE_TO_REMOVE =
  "wlrBroadband.DELETE_SPECIAL_RATE_TO_REMOVE";

export const REQUEST_TAGS_CHECK = "wlrBroadband.REQUEST_TAGS_CHECK";
export const RECEIVE_TAGS_CHECK = "wlrBroadband.RECEIVE_TAGS_CHECK";

export const REQUEST_UPDATE_PRODUCT_INSTANCE =
  "wlrBroadband.REQUEST_UPDATE_PRODUCT_INSTANCE";
export const RECEIVE_UPDATE_PRODUCT_INSTANCE =
  "wlrBroadband.RECEIVE_UPDATE_PRODUCT_INSTANCE";

export const TOGGLE_SOGEA_TERMS_CONFIRMATION =
  "wlrBroadband.TOGGLE_SOGEA_TERMS_CONFIRMATION";
