import { stringify } from "query-string";
import { kongAxios } from "../../../core/kongAxios";

export type Customer = {
  customer_uuid: string;
  accountNumber: string;
  name: string;
  thirdPartyBillingRef: string | null;
  primaryContact: {
    phone: {
      work: string | null;
      mobile: string | null;
    };
    name: string;
  };
  postcode: string;
  customerReference: string;
  status: "Prospect" | "Active" | "Archived" | null; // note capitalisation here
};

export type DCCustomersAPI = {
  meta: {
    start: number;
    totalCount: number;
    end: number;
    page: number;
    totalPages: number;
    limit: number;
  };
  results: Customer[];
};

export interface SearchCustomersResponse {
  status?: "error";
  code?: string; // in error state
  message?: string; // in error state
  customers: DCCustomersAPI;
}

type SearchCustomersParams = Partial<{
  pageParam?: number;
  searchTerm: string;
  order: "asc" | "desc";
  sort: string;
}>;
export const searchCustomers = ({
  pageParam = 1,
  searchTerm = "",
  order,
  sort
}: SearchCustomersParams) => {
  const params = stringify({
    "include.customers": 1,
    "include.all": 0,
    "customers.include_leads": 1,
    "customers._limit": 10,
    "customers._page": pageParam,
    "customers._sort": sort,
    "customers._order": order,
    search: searchTerm.length > 0 ? searchTerm : undefined
  });
  return kongAxios
    .get<SearchCustomersResponse>(
      `/ExternalServices/v1/Affinity/Platform/Search?${params}`
    )
    .then(res => {
      if (res.data.status === "error") {
        // this will be 200 OK so need to manually throw.
        throw new Error(res.data.message || "Something went wrong");
      }

      return res.data.customers;
    });
};
