import React from "react";
import Icon from "@material-ui/icons/Book";
import Section from "../../../../../../components/Shared/Section";
import WlrBBDynamicField from "../../WlrBBDynamicField/";

// These all have the category "Directory Info (Existing Line)" in product data.

const DirectoryInfo = props => {
  return (
    <Section
      title="Directory Info (Existing Line)"
      avatar={<Icon />}
      defaultExpanded={false}
    >
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.directory_suffix"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.end_user_name_for_999"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.directory_description"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.company"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.directory_initials"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.entry_type"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.directory_title"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.location_option"
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.directory_surname"
      />
    </Section>
  );
};

DirectoryInfo.propTypes = {};

export default DirectoryInfo;
