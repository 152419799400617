import u from "updeep";
import _ from "lodash";
import * as actionType from "../../actionTypes";
import newConfiguration from "../newConfiguration";
import setBroadbandProduct from "../setBroadbandProduct";
import { AWAITING } from "../../constants";
import { format } from "date-fns";
import { DC_DATE_FORMAT } from "../../../../helpers/date";

export default (state, action) => {
  let thisConfig;

  switch (action.type) {
    // Per configuration actions:

    case actionType.ADD_WLR_CONFIGURATION:
      return u(
        {
          configurations: c => [...c, newConfiguration(action, state)]
        },
        state
      );

    case actionType.REMOVE_WLR_CONFIGURATION:
      return u(
        {
          configurations: c => [
            ...c.slice(0, action.index),
            ...c.slice(action.index + 1)
          ]
        },
        state
      );

    case actionType.SET_CONFIGURATION:
      return u(
        {
          configurations: action.configurations
        },
        state
      );

    case actionType.SET_BROADBAND_PRODUCT:
      return setBroadbandProduct(action, state);

    // UI Step 2 - configuration

    case actionType.REQUEST_PRODUCT_DATA:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              [action.productType + "ProductData"]: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_PRODUCT_DATA:
      thisConfig = state.configurations[action.configurationIndex];
      if (action.response.status === "error") {
        // slightly diff approach here to stop this wiping too many props
        // and breaking everything, just set the error related things
        return u(
          {
            configurations: {
              [action.configurationIndex]: {
                [action.productType + "ProductData"]: {
                  fetching: false,
                  response: {
                    status: action.response.status,
                    message: action.response.message,
                    code: action.response.code
                  }
                }
              }
            }
          },
          state
        );
      }
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              [action.productType + "ProductData"]: {
                fetching: false,
                response: () => action.response,
                // If this is Broadband product data, and this is the first time it's loaded,
                // store the current wlr_change values, so we can detect if they've been changed by the user later.
                // (they will change themselves in subsequent ProductData responses, as user updated values are passed back)
                ...(action.productType === "broadband" &&
                  _.isEmpty(
                    thisConfig.broadbandProductData.initialWlrChangeValues
                  ) && {
                    initialWlrChangeValues: (() => {
                      const properties = _.get(
                        action.response,
                        "broadband.dynamic_properties",
                        {}
                      );
                      const res = {};

                      for (let k in properties) {
                        if (k.includes("wlr_change"))
                          res[k] = properties[k].current_value;
                      }

                      return res;
                    })()
                  })
              }
            }
          }
        },
        state
      );

    /**
     * Set default broadband properties based on a product data response.
     * See the rest of the defaulted params under SET_BROADBAND_PRODUCT
     */
    case actionType.SET_PROPERTIES_FROM_PRODUCT_DATA:
      if (action.productType !== "broadband") return state; // Guess we might need to do this for WLR in future?
      thisConfig = state.configurations[action.configurationIndex];
      const dynamic_properties = _.get(
        action.response,
        "broadband.dynamic_properties"
      );
      // Success? Set properties....
      if (dynamic_properties) {
        return u(
          {
            configurations: {
              [action.configurationIndex]: {
                broadbandProductData: {
                  // fetching: false,
                  response: () => action.response
                },
                broadbandProperties: {
                  // Default the Radius realm ID:
                  // It should be whatever the first daisy one is in available options.
                  // Users may want to select different ones, and realms change depending on product
                  // Apparently this is not straight forward to default in DC.

                  "radius.radius_realm_id": (() => {
                    const realms =
                      action.response.broadband.dynamic_properties[
                        "radius.radius_realm_id"
                      ].available_option_map;
                    // Note: Accounts can be erroneously set up to not have any linked realms,
                    // in which case available_option_map won't exist. Hence check it first so this doesn't blow up.
                    if (realms) {
                      return (
                        Object.keys(realms).find(realmId =>
                          realms[realmId].includes("@daisybb.co.uk")
                        ) || Object.keys(realms)[0]
                      );
                    } else {
                      return undefined;
                    }
                  })(),

                  "bb.contact.title":
                    action.response.broadband.dynamic_properties[
                      "bb.contact.title"
                    ].default_value

                  // FB131932 - not ready for release after all apparently.
                  // 'router_id':(() => {
                  //     if(getIsWLTO(state, thisConfig.locationIndex) && dynamic_properties.router_id) {
                  //         const routers = dynamic_properties.router_id.available_option_details
                  //         return Object.keys(routers)
                  //             .find(id => routers[id].install_type === 'Managed');
                  //     }
                  //     return null
                  // })(),
                }
              }
            }
          },
          state
        );
      } else {
        // Failure? Save response for UI to show/
        return u(
          {
            configurations: {
              [action.configurationIndex]: {
                broadbandProductData: {
                  fetching: false,
                  response: () => action.response
                }
              }
            }
          },
          state
        );
      }

    case actionType.REQUEST_WLR_APPOINTMENTS:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              wlrAppointments: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_WLR_APPOINTMENTS:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              wlrAppointments: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.SET_WLR_APPOINTMENT:
      // User clicked on the blank select option?
      if (action.selectedIndex === null) return state;
      // TP32603: A few variables needed for setting the placeholder fields
      // further down.
      const wlrExpiryDate = new Date().setDate(new Date().getDate() + 1);
      const appointments =
        state.configurations[action.configurationIndex].wlrAppointments;
      const wlrAppointment =
        appointments.response.appointments[action.selectedIndex];
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              wlrAppointments: {
                selectedIndex: action.selectedIndex
              },
              // Set the broadband required_by_date to match the WLR appointment date. See sim_provide stuff in getDCOrderParams()
              broadbandProperties: {
                "bb.required_by_date":
                  state.configurations[action.configurationIndex]
                    .wlrAppointments.response.appointments[action.selectedIndex]
                    .date
              },
              // TP32603: If the user is a sales person, reservation now doesn't
              // occur until after account creation, so set these placeholder
              // fields for now to pass validation.
              ...(action.isSalesPerson && {
                wlrProperties: {
                  appointment_reference: AWAITING,
                  appointment_date: wlrAppointment.date,
                  appointment_timeslot: wlrAppointment.timeslot,
                  appointment_expiry: format(wlrExpiryDate, DC_DATE_FORMAT)
                }
              })
            }
          }
        },
        state
      );

    case actionType.REQUEST_ADD_WLR_APPOINTMENT:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              addWlrAppointment: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_ADD_WLR_APPOINTMENT:
      const { wlrAppointments } = state.configurations[
        action.configurationIndex
      ];
      const appointment =
        wlrAppointments.response.appointments[wlrAppointments.selectedIndex];
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              // Store the response
              addWlrAppointment: {
                fetching: false,
                response: () => action.response
              },
              // and if it's successful, add the data to the params ready for ordering.
              ...(action.response.status === "success" && {
                wlrProperties: {
                  appointment_date: appointment.date,
                  appointment_expiry: action.response.appointment_expiry,
                  appointment_reference: action.response.appointment_reference,
                  appointment_timeslot: appointment.timeslot
                }
              })
            }
          }
        },
        state
      );

    case actionType.REQUEST_ADD_BROADBAND_APPOINTMENT:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              addBroadbandAppointment: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_ADD_BROADBAND_APPOINTMENT:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              // Store the response
              addBroadbandAppointment: {
                fetching: false,
                response: () => action.response
              },
              // and if it's successful, add the data to the params ready for ordering.
              // Annoyingly the shape of all this is slightly different so we can't reuse the WLR actions.
              // TP21765: Pass in appointment date and timeslot.
              ...(action.response.status === "success" && {
                broadbandProperties: {
                  "bb.appointment_expiry": action.response.appointment_expiry,
                  "bb.appointment.appointmentReference":
                    action.response.appointment_reference,
                  "bb.appointment_date": action.appointment.date,
                  "bb.appointment_datetime":
                    action.appointment.date + " 00:00:00",
                  "bb.appointment_timeslot": action.appointment.timeslot
                }
              })
            }
          }
        },
        state
      );

    case actionType.REQUEST_BROADBAND_APPOINTMENTS:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              broadbandAppointments: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_BROADBAND_APPOINTMENTS:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              broadbandAppointments: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.SET_BROADBAND_APPOINTMENT:
      // User clicked on the blank select option?
      if (action.selectedIndex === null) return state;
      // TP32603: A few variables needed for setting the placeholder fields
      // further down.
      const bbExpiryDate = new Date().setDate(new Date().getDate() + 1);
      const bbAppointments =
        state.configurations[action.configurationIndex].broadbandAppointments;
      const bbAppointment =
        bbAppointments.response.appointments[action.selectedIndex];
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              broadbandAppointments: {
                selectedIndex: action.selectedIndex
              },
              // TP32603: If the user is a sales person, reservation now doesn't
              // occur until after account creation, so set these placeholder
              // fields for now to pass validation.
              ...(action.isSalesPerson && {
                broadbandProperties: {
                  "bb.appointment.appointmentReference": AWAITING,
                  "bb.appointment_date": bbAppointment.date,
                  "bb.appointment_datetime": bbAppointment.date + " 00:00:00",
                  "bb.appointment_timeslot": bbAppointment.timeslot,
                  "bb.appointment_expiry": format(bbExpiryDate, DC_DATE_FORMAT)
                }
              })
            }
          }
        },
        state
      );

    case actionType.SET_PRICING_SCHEME:
      return u(
        {
          configurations: {
            [action.targetConfig]: {
              [`${action.productType}Properties`]: {
                pricing_scheme: action.schemeName
              }
            }
          }
        },
        state
      );

    case actionType.SET_PRODUCT_DISCOUNT:
      let productDiscountUpdate = {};
      action.targetConfigs.forEach(
        i =>
          (productDiscountUpdate[i] = {
            [`${action.productType}Properties`]: {
              ...(action.discountType !== null && {
                [`${action.priceType}_discount_type`]: action.discountType
              }),
              [`${action.priceType}_discount_override`]:
                action.value === false ? 0 : 1,
              [`${action.priceType}_discount_value`]: action.value
            }
          })
      );

      return u(
        {
          configurations: productDiscountUpdate
        },
        state
      );

    case actionType.REMOVE_PRODUCT_DISCOUNT:
      let productDiscountRemovals = {};
      action.targetConfigs.forEach(
        i =>
          (productDiscountRemovals[i] = {
            [`${action.productType}Properties`]: u.omit([
              `${action.propertyNamePrefix}_discount_override`,
              `${action.propertyNamePrefix}_discount_type`,
              `${action.propertyNamePrefix}_discount_value`
            ])
          })
      );

      return u(
        {
          configurations: productDiscountRemovals
        },
        state
      );

    case actionType.VALIDATE_PRODUCT_PROPERTY:
      let validationUpdate = {};
      action.targetConfigs.forEach(i => {
        let message = false;
        const value =
          state.configurations[i][`${action.productType}Properties`][
            action.propertyName
          ];

        // Check for value if field is_required
        if (action.dynamicProperty.is_required && !value) {
          message = "Required";
        }

        // Check email valid:
        // Note: Email field was actually removed, but you never know... may come back again.
        if (
          action.dynamicProperty.name.toLowerCase().indexOf("email") > -1 &&
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) // eslint-disable-line no-useless-escape
        ) {
          message = "Must be valid email address";
        }

        // Check phone number valid:
        // Ridiculous regex taken from https://www.aa-asterisk.org.uk/index.php/Regular_Expressions_for_Validating_and_Formatting_GB_Telephone_Numbers
        // via https://stackoverflow.com/questions/5286046/javascript-phone-number-validation
        if (
          action.dynamicProperty.name.toLowerCase().indexOf("phone") > -1 &&
          // eslint-disable-next-line no-useless-escape
          !/^\(?(?:(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?\(?(?:0\)?[\s-]?\(?)?|0)(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}|\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4}|\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3})|\d{5}\)?[\s-]?\d{4,5}|8(?:00[\s-]?11[\s-]?11|45[\s-]?46[\s-]?4\d))(?:(?:[\s-]?(?:x|ext\.?\s?|\#)\d+)?)$/.test(
            value
          )
        ) {
          message = "Must be a valid UK phone number";
        }

        validationUpdate[i] = {
          validation: {
            [action.dynamicProperty.name]: message
          }
        };
      });
      return u(
        {
          configurations: validationUpdate
        },
        state
      );

    case actionType.SET_NUMBER_RESERVATION_TYPE:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              numberReservation: {
                type: action.reservationType
              }
            }
          }
        },
        state
      );

    case actionType.SET_RESERVED_NUMBER:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              numberReservation: {
                selectedNumber: action.number
              }
            }
          }
        },
        state
      );

    case actionType.REQUEST_RESERVE_NUMBER:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              numberReservation: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_RESERVE_NUMBER:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              numberReservation: {
                fetching: false,
                response: () => action.response
              },
              ...(action.response.status === "success" && {
                wlrProperties: {
                  reserved_number: action.response.reserved_number,
                  reserved_number_expiry: action.response.reservation_expiry,
                  reserved_number_reference:
                    action.response.reservation_reference
                }
              })
            }
          }
        },
        state
      );

    case actionType.REQUEST_VALIDATE_WLR:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              wlrValidation: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_VALIDATE_WLR:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              wlrValidation: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.REQUEST_VALIDATE_WLR_QUOTE_ONLY:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              wlrValidationQuote: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_VALIDATE_WLR_QUOTE_ONLY:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              wlrValidationQuote: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.REQUEST_VALIDATE_BROADBAND:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              broadbandValidation: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_VALIDATE_BROADBAND:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              broadbandValidation: {
                fetching: false,
                response: () => action.response
              }
            }
          }
        },
        state
      );

    case actionType.REQUEST_ORDER_PRODUCT:
      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              orderProduct: {
                [action.productType]: {
                  fetching: true,
                  isUpdate: action.isUpdate,
                  response: {}
                }
              }
            }
          }
        },
        state
      );

    case actionType.RECEIVE_ORDER_PRODUCT:
      /**
       * Failures where the product has been added, but has config errors result in some
       * crazy message formats, not dealt with by the base API, which just deals with strings.
       * (it's an error but still has an order_product_id, which needs storing).
       *
       * Example:
       *
       * {
       *    "code" : "FAILED_REQUEST",
       *    "message" : {
       *       "code" : "FAILED_REQUEST",
       *       "is_configured" : 0,
       *       "message" : [
       *          {
       *             "message" : "Appointment Reservation Reference not present",
       *             "method" : "Appointment Reservation Reference",
       *             "value" : null
       *          },
       *          null,
       *          null,
       *          null,
       *          null
       *       ],
       *       "order_product_id" : "7007242",
       *       "status" : "error"
       *    },
       *    "status" : "error"
       * }
       *
       * ...so let's clean that up into a useful format:
       */
      if (action.response.status !== "success") {
        const orderProductId = _.get(
          action.response,
          "message.order_product_id"
        );
        if (orderProductId) {
          action.response.data = { id: orderProductId };
        }
        if (typeof action.response.message !== "string") {
          action.response.message = _.get(
            action.response.message,
            "message[0].message"
          );
        }
      }

      return u(
        {
          configurations: {
            [action.configurationIndex]: {
              orderProduct: {
                [action.productType]: {
                  fetching: false,
                  response: () => action.response
                }
              }
            }
          }
        },
        state
      );

    default:
      return state;
  }
};
