import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setNewAccountField } from "../../store/account/actions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Grid } from "@material-ui/core";

class AccountToggle extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  };

  render() {
    const { label, name, value, handleToggle } = this.props;
    return (
      <Grid item sm={6}>
        <FormControlLabel
          control={
            <Switch onChange={handleToggle} name={name} checked={!!value} />
          }
          label={label}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  value: state.account.newAccountFields[ownProps.name]
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleToggle: event =>
    dispatch(setNewAccountField(ownProps.name, event.target.checked ? 1 : 0))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountToggle);
