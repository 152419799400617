import React from "react";
import { useSelector } from "react-redux";
import { Button, makeStyles, Theme } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {
  ReduxWlrLocation,
  WlrLocationBroadbandProduct,
  WlrLocationProduct,
  WlrLocationProductConfig
} from "../../../../types";
import { BroadbandProductTable } from "./broadband-product-table";
import { BroadbandTotalPrice } from "./BroadbandTotalPrice";

export interface WlrLocationBroadbandModalProps {
  open: boolean;
  locationIndex: number;
  location: ReduxWlrLocation;
  product: WlrLocationProduct;
  handleClose: () => void;
}

export const WlrLocationBroadbandModal = ({
  open,
  locationIndex,
  location,
  product,
  handleClose
}: WlrLocationBroadbandModalProps) => {
  const classes = useStyles();

  const configurations = useSelector<any, WlrLocationProductConfig[]>(
    state => state.wlrBroadband.configurations ?? []
  ).map((config, configId) => ({ ...config, configId }));
  const productConfigurations = configurations.filter(
    c => c.wlrProductId === product.id && c.locationIndex === locationIndex
  );

  const broadbandProducts: WlrLocationBroadbandProduct[] =
    location.broadbandSearch?.response?.products ?? [];

  const title = `Add Broadband to ${productConfigurations.length} line(s)`;

  const prices = productConfigurations
    .filter(c => !!c.broadbandProductId)
    .map(
      c =>
        broadbandProducts.find(b => b.id === c.broadbandProductId)?.price
          .first_bill_recurring_price_with_promotions ?? "0.00"
    );

  const totalPrice = prices
    .reduce((total, p) => total + parseFloat(p), 0)
    .toFixed(2);

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={handleClose}
      TransitionComponent={Slide}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <BroadbandProductTable
          location={location}
          product={product}
          configurations={productConfigurations}
        />
        <BroadbandTotalPrice totalPrice={totalPrice} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Save & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: "75vw",
    width: "75vw",
    maxHeight: "650px",
    height: "80vh"
  },
  actions: {
    padding: theme.spacing(1.5),
    paddingTop: 0
  }
}));
