import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import SmallStatus from "../../../../components/Shared/SmallStatus";
import { setAccountBoltOn } from "../../../../store/mobile/actionCreators";
import {
  ALB_NOT_REQUIRED,
  KEEP_CURRENT_ALB,
  REMOVE_EXISTING_ALB
} from "../../../../store/mobile/constants";
import {
  makeGetAvailableAccountBoltOns,
  makeGetExistingAccountBoltOn
} from "../../../../store/mobile/selectors/boltOns";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

class BoltOnSelectField extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    boltOnType: PropTypes.string.isRequired,
    serviceProviderId: PropTypes.string.isRequired
  };

  render() {
    const {
      boltOnType,
      serviceProviderId,
      availableBoltOns,
      selectedBoltOn,
      existingBoltOn, // state
      setAccountBoltOn // dispatch
    } = this.props;

    return (
      <div>
        <FormControl variant="outlined" fullWidth>
          <Select
            onChange={event =>
              setAccountBoltOn(
                serviceProviderId,
                boltOnType,
                event.target.value
              )
            }
            value={selectedBoltOn}
          >
            {existingBoltOn && (
              <MenuItem value={KEEP_CURRENT_ALB}>
                <span>
                  <b>Current bolt on:</b> {existingBoltOn.description}
                </span>
              </MenuItem>
            )}
            <MenuItem
              value={existingBoltOn ? REMOVE_EXISTING_ALB : ALB_NOT_REQUIRED}
            >
              Not Required
            </MenuItem>
            {availableBoltOns.map(product => {
              const price = _.get(
                product,
                ["price", "first_bill_recurring_price_with_promotions"],
                false
              );
              return (
                <MenuItem value={product.id} key={product.id}>
                  {product.name + (price ? " - £" + price : "")}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {(() => {
          if (existingBoltOn) {
            if (selectedBoltOn === REMOVE_EXISTING_ALB)
              return (
                <SmallStatus type="error">
                  {existingBoltOn.description} will be removed
                </SmallStatus>
              );
            if (selectedBoltOn !== KEEP_CURRENT_ALB)
              return (
                <SmallStatus type="info">
                  {existingBoltOn.description} will be replaced
                </SmallStatus>
              );
          }
          return false;
        })()}
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getExistingAccountBoltOn = makeGetExistingAccountBoltOn();
  const getAvailableAccountBoltOns = makeGetAvailableAccountBoltOns();

  return (state, ownProps) => ({
    selectedBoltOn:
      state.mobile.selectedBoltOns[ownProps.serviceProviderId][
        ownProps.boltOnType
      ],
    availableBoltOns: getAvailableAccountBoltOns(state, ownProps),
    existingBoltOn: getExistingAccountBoltOn(state, ownProps)
  });
};

export default connect(makeMapStateToProps, {
  setAccountBoltOn
})(BoltOnSelectField);
