import { createSelector } from "reselect";
import _ from "lodash";
import {
  ALB_NOT_REQUIRED,
  KEEP_CURRENT_ALB,
  REMOVE_EXISTING_ALB
} from "../constants";

// ALB selectors

// Existing Account Level Bolt-ons are shown with a completely different schema to the available ones so:
export const boltOnsMap = {
  data: ["data#4g_data_uk", "data"],
  voice: ["voice#international", "voice#non_geo", "voice"],
  additional_bundle_1: ["voice#worldwide_roaming", "data#worldwide_roaming"],
  text: ["text#international"]
};
// Apparently this is because bolt-ons themselves can have categories (of which we are showing 4... there are more)
// ...however each bolton can be made up of different voice / data / text elements.
// It's those elements that make the categories we're getting back. (data#4g_data_uk etc.)

// Having logic here that limits bolt ons to 1 per 4 categories, then mapping over to 6 could cause problems...
// Not been an issue yet though according to Lindsey D.
// Take the first one that matches for now...

/**
 * Get Existing Account Level Bolt-on
 * Memoized here so react can still shallow diff correctly and avoid unnecessary renders.
 *
 * @returns {Reselect.Selector<any, any>}
 */

export const makeGetExistingAccountBoltOn = () =>
  createSelector(
    [
      state => state.mobile.accountBoltOns,
      (state, props) => props.serviceProviderId,
      (state, props) => props.boltOnType
    ],
    (accountBoltOns, serviceProviderId, boltOnType) => {
      const existing = _.get(accountBoltOns.response, "data.result");

      let serviceProviderName;
      if (serviceProviderId == 1) serviceProviderName = "O2"; // eslint-disable-line eqeqeq
      if (serviceProviderId == 2) serviceProviderName = "Vodafone"; // eslint-disable-line eqeqeq
      if (existing && serviceProviderName) {
        for (let type of boltOnsMap[boltOnType]) {
          if (existing[type] && existing[type][serviceProviderName]) {
            return existing[type][serviceProviderName];
          }
        }
      }

      return false;
    }
  );

/**
 * Get available ALBs by service provider and type
 * @returns {Reselect.Selector<any, any>}
 */
export const makeGetAvailableAccountBoltOns = () =>
  createSelector(
    [
      (state, props) => state.mobile.boltOnSearch.response.products,
      (state, props) => props.serviceProviderId,
      (state, props) => props.boltOnType
    ],
    (products, serviceProviderId, boltOnType) => {
      if (products) {
        return products.filter(
          product =>
            product.first_mobile_bolt_on_component.service_provider_id ===
              serviceProviderId &&
            product.first_mobile_bolt_on_component.bolt_on_type === boltOnType
        );
      }
      return [];
    }
  );

/**
 * Count new bolt-ons to be ordered.
 * i.e. slots that have a product ID and aren't "keep existing" etc.
 * @param state
 * @returns {number}
 */
export const getSelectedBoltOnCount = (state, providerId) => {
  let count = 0;
  const { selectedBoltOns } = state.mobile;

  for (let type in selectedBoltOns[providerId]) {
    const p = selectedBoltOns[providerId][type];
    if (
      p !== ALB_NOT_REQUIRED &&
      p !== KEEP_CURRENT_ALB &&
      p !== REMOVE_EXISTING_ALB
    )
      count += 1;
  }

  return count;
};

/**
 * Get an account level bolt-on product by it's ID
 * ....or undefined if there isn't one. Such as when the ID = ALB_NOT_REQUIRED etc
 * @param mobileState
 * @param id
 * @returns {*}
 */
export const getBoltOnProductDataByID = (mobileState, id) =>
  (mobileState.boltOnSearch.response.products || []).find(p => p.id === id);

/**
 * Get the Evo bundle IDs of account level bolt-ons marked for removal by the user.
 * @param mobileState
 */
export const getALBRemovals = mobileState => {
  if (!mobileState.boltOnSearch.response.products) return [];

  const selectedBoltOns = mobileState.selectedBoltOns,
    existingBoltOns = _.get(mobileState.accountBoltOns.response, "data.result");

  if (!existingBoltOns) return [];

  const removals = [];

  // Iterate over the state describing user selections
  // For any that are "remove existing", find what that bundle ID of that existing one is
  // and add to the list we return. (that will be used to generate calls)

  for (let supplier in selectedBoltOns) {
    for (let type in selectedBoltOns[supplier]) {
      if (selectedBoltOns[supplier][type] === REMOVE_EXISTING_ALB) {
        boltOnsMap[type].forEach(t => {
          const bundle = _.get(existingBoltOns, [
            t,
            supplier == 1 ? "O2" : "Vodafone" // eslint-disable-line eqeqeq
          ]);
          if (bundle)
            removals.push({
              id: bundle.id,
              description: bundle.description
            });
        });
      }
    }
  }
  return removals;
};
