import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestAccountSettings } from "../../store/account/actions";
import { FullScreenStatus } from "../../components/Shared/FullScreenStatus";

interface LoadAccountSettingsProps {
  children: ReactNode;
}

export const LoadAccountSettings = ({ children }: LoadAccountSettingsProps) => {
  const accountSettings = useSelector((state: any) => state.account.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestAccountSettings());
  }, [dispatch]);

  if (accountSettings.fetching)
    return (
      <FullScreenStatus
        statusType="loading"
        message="Loading account settings"
      />
    );

  if (accountSettings.response.status === "error")
    return (
      <FullScreenStatus
        statusType="error"
        message="Error retrieving account settings."
        action={() => dispatch(requestAccountSettings())}
      />
    );
  return <>{children}</>;
};
