import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import {
  getIsWlrChangePossible,
  getRequiresBBAppointment,
  getRequiresWLRAppointment,
  getIsBTWFTTC,
  getIsMPFLine
} from "../../../../store/wlrBroadband/selectors";
import {
  NEW_FTTP,
  NEW_LINE,
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
  TRANSFER
} from "../../../../store/wlrBroadband/constants";
import RemoteValidationErrors from "./RemoteValidationErrors";
import EndUserAddress from "./sections/EndUserAddress";
import Appointment from "./sections/Appointment/";
import MultiLineOptions from "./sections/MultiLineOptions";
import CallingFeatures from "./sections/CallingFeatures";
import CareLevels from "./sections/CareLevels";
import CallTariff from "./sections/CallTariff";
import CallBundles from "./sections/CallBundles";
import CallBundlesWlrChange from "./sections/CallBundlesWlrChange";
import Router from "./sections/Router";
import WlrChangePriceAdjustment from "./sections/WlrChangePriceAdjustment/index";
import DirectoryEnquiry from "./sections/DirectoryEnquiry";
import MPFDirectoryEnquiry from "./sections/MPFDirectoryEnquiry";
import NumberReservation from "./sections/NumberReservation/";
import BroadbandConfiguration from "./sections/BroadbandConfiguration";
import RadiusConfiguration from "./sections/RadiusConfiguration";
import LineConfiguration from "./sections/LineConfiguration";
import MPFVoiceOptions from "./sections/MPFVoiceOptions";
import SelectedConfigs from "./SelectedConfigs";
import RequiredByDate from "./sections/RequiredByDate";
import SelectServices from "./sections/WlrChange/SelectServices";
import DirectoryInfo from "./sections/WlrChange/DirectoryInfo";
import WlrBBPricingWrapper from "./sections/WlrBBPricingWrapper";
import SiteVisitReason from "./sections/SiteVisitReason/index";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Grid, Typography, Button, IconButton } from "@material-ui/core";
import SpecialRates from "./sections/SpecialRates";
import {
  EXISTING_SOGEA,
  NEW_SOGEA,
  RESIGN
} from "../../../../store/wlrBroadband/constants";
import { getProductInstances } from "../../../../store/wlrBroadband/selectors";
import { getAccountSettings } from "../../../../store/account/selectors";
import withContextToProps from "../../../../helpers/withContextToProps";
import { TargetConfigsContext } from "../../context/TargetConfigsContext";

class ConfigurationForm extends Component {
  static defaultProps = {};
  static propTypes = {
    targetConfigs: PropTypes.arrayOf(PropTypes.number).isRequired,
    closeAction: PropTypes.func.isRequired
  };

  // TODO: This layout might be better with some sort of masonry thing to make it more adaptive.
  // eg: https://medium.com/@_jh3y/how-to-the-masonry-layout-56f0fe0b19df
  // Lots of screen real estate goes unused when sections are expanded.
  // There's problems with that though (sections jumping around etc.) and no time currently.
  //
  // Resigns:
  // TP32002: Hide Number Reservation and show call tariff for same product with
  // change (line).
  // TP33744: Show call bundle for same product with change (line).
  // FB178925: Show Router and related options (SVR and required by date) for
  // same product with change (broadband).

  render() {
    const {
      accountSettings,
      targetConfigs,
      isNewLine,
      isTransfer,
      isMultiLine,
      isWLTO,
      requiresWLRAppointment,
      requiresBBAppointment,
      isMPF,
      isFTTP,
      isSOGEA,
      isBTWFTTC,
      hasBroadband,
      hasLine,
      isWlrChangePossible,
      closeAction,
      configurations,
      productInstance,
      isISDN,
      isNewProductLineResign,
      isSameProductWCLineResign,
      isNewProductBroadbandResign,
      isSameProductWCBroadbandResign
    } = this.props;
    return (
      <div data-cy="wlrBroadbandConfigurationForm">
        <Paper style={{ marginBottom: 20 }}>
          <Typography variant="h3">
            <IconButton
              data-cy="wlrBroadbandConfigurationFormBackButton"
              onClick={closeAction}
            >
              <ArrowBackIcon />
            </IconButton>
            <span>
              {targetConfigs.length > 1
                ? `Editing ${targetConfigs.length} Product Configurations`
                : `Editing Product Configuration`}
            </span>
          </Typography>
          <SelectedConfigs targetConfigs={targetConfigs} />
        </Paper>
        <Grid container spacing={2}>
          <Grid item md={6}>
            {!isISDN && (
              <EndUserAddress
                hasBroadband={
                  hasBroadband ||
                  isNewProductBroadbandResign ||
                  isFTTP ||
                  isSOGEA
                }
                isNewLine={isNewLine || isNewProductLineResign}
              />
            )}
          </Grid>
          <Grid item md={6}>
            {isWlrChangePossible && (
              <div>
                <SelectServices />
                <DirectoryInfo />
                <CallBundlesWlrChange />
              </div>
            )}
            {isMultiLine && <MultiLineOptions />}
            {(hasBroadband ||
              isNewProductBroadbandResign ||
              isSameProductWCBroadbandResign) &&
              (isSOGEA || isBTWFTTC || isFTTP) && (
                <SiteVisitReason targetConfigs={targetConfigs} />
              )}
            {(hasBroadband ||
              isNewProductBroadbandResign ||
              isSameProductWCBroadbandResign) && (
              <Router
                targetConfigs={targetConfigs}
                isSOGEA={isSOGEA}
                isBTWFTTC={isBTWFTTC}
              />
            )}
            {/*{isFTTP && <ONT/>}*/}
            {(requiresWLRAppointment || requiresBBAppointment) &&
              !isSameProductWCBroadbandResign && (
                <Appointment targetConfigs={targetConfigs} />
              )}
            {!requiresBBAppointment &&
              (hasBroadband || isNewProductBroadbandResign) && (
                <RequiredByDate isFTTP={isFTTP} />
              )}
            {!isISDN && (
              <CareLevels isWlrChangePossible={isWlrChangePossible} />
            )}
            {(isNewLine ||
              isNewProductLineResign ||
              isSameProductWCLineResign ||
              isTransfer ||
              (hasLine && isWlrChangePossible)) &&
              !isSOGEA && (
                <CallTariff isWlrChangePossible={isWlrChangePossible} />
              )}
            {accountSettings.dws_reseller_enabled !== "1" && (
              <WlrBBPricingWrapper
                targetConfig={targetConfigs[0]}
                configurations={configurations}
              />
            )}
            {(isNewLine || isNewProductLineResign) &&
              !isMPF &&
              !isSOGEA &&
              !isISDN && <NumberReservation targetConfigs={targetConfigs} />}
            {isWlrChangePossible && <WlrChangePriceAdjustment />}
            {(isNewLine ||
              isNewProductLineResign ||
              isSameProductWCLineResign ||
              isTransfer) &&
              !isSOGEA && <CallBundles />}
            {(isNewLine || isNewProductLineResign) &&
              !isMPF &&
              !isSOGEA &&
              !isISDN && <CallingFeatures targetConfig={targetConfigs[0]} />}
            {(isNewLine || isNewProductLineResign) &&
              !isMPF &&
              !isSOGEA &&
              !isISDN && <DirectoryEnquiry />}
            {isMPF && <MPFDirectoryEnquiry />}
            {(hasBroadband || isNewProductBroadbandResign) && (
              <BroadbandConfiguration hasLine={hasLine} isSOGEA={isSOGEA} />
            )}
            {(hasBroadband || isNewProductBroadbandResign) && (
              <RadiusConfiguration />
            )}
            {(((isNewLine || isNewProductLineResign) &&
              !isMPF &&
              !isSOGEA &&
              !isISDN) ||
              isWLTO) && <LineConfiguration />}
            {isMPF && <MPFVoiceOptions />}
          </Grid>
        </Grid>
        {targetConfigs.length < 2 && (
          <RemoteValidationErrors targetConfig={targetConfigs[0]} />
        )}
        {productInstance && <SpecialRates productInstance={productInstance} />}
        <Button
          variant="contained"
          color="primary"
          onClick={closeAction}
          endIcon={<ArrowBackIcon />}
          data-cy="wlrConfigBackButton"
        >
          Back
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // Set flags based on what sort of configs we're targeting with this form
  const findInTargets = test => {
    for (let t of ownProps.targetConfigs) {
      if (test(state.wlrBroadband.configurations[t])) return true;
    }
    return false;
  };
  const productInstances = getProductInstances(state);
  const productInstance = productInstances.find(
    product =>
      product.id ===
      state.wlrBroadband.configurations[ownProps.targetConfigs[0]]
        .productInstanceId
  );
  return {
    accountSettings: getAccountSettings(state),
    requiresWLRAppointment: getRequiresWLRAppointment(state, ownProps),
    requiresBBAppointment: getRequiresBBAppointment(state, ownProps),
    isNewLine: findInTargets(
      c => state.wlrBroadband.locations[c.locationIndex].type === NEW_LINE
    ),
    isTransfer: findInTargets(
      c => state.wlrBroadband.locations[c.locationIndex].type === TRANSFER
    ),
    isWLTO: findInTargets(
      c =>
        state.wlrBroadband.locations[c.locationIndex].wltoDetails.response
          .line_plant_type === "WORKING_LINE"
    ),
    isMultiLine: findInTargets(
      c =>
        c.wlrProductData?.response?.line?.product_component_data
          ?.service_type === "PSTN_MULTI_LINE_AUX"
    ),
    isMPF: findInTargets(c => getIsMPFLine(c)),
    isSOGEA: findInTargets(
      c => c.wlrProductId === NEW_SOGEA || c.wlrProductId === EXISTING_SOGEA
    ),
    hasBroadband: findInTargets(
      c =>
        state.wlrBroadband.locations[c.locationIndex].type !== RESIGN &&
        c.broadbandProductId
    ),
    isWlrChangePossible: findInTargets(c =>
      getIsWlrChangePossible(state, c.locationIndex)
    ),
    isFTTP: findInTargets(
      c => state.wlrBroadband.locations[c.locationIndex].type === NEW_FTTP
    ),
    isBTWFTTC: getIsBTWFTTC(state, ownProps),
    configurations: state.wlrBroadband.configurations,
    productInstance,
    isISDN:
      productInstance &&
      productInstance.type === "wlr" &&
      productInstance.line_details &&
      (productInstance.line_details.service_type === "ISDN30E" ||
        productInstance.line_details.service_type === "ISDN2E_SYSTEM"),
    hasLine: findInTargets(c => c.wlrProductId && c.wlrProductId !== RESIGN),
    isNewProductLineResign: findInTargets(
      c =>
        state.wlrBroadband.locations[c.locationIndex].type === RESIGN &&
        c.wlrProductId &&
        c.wlrProductId !== RESIGN &&
        c.resignProductType === NEW_PRODUCT
    ),
    isSameProductWCLineResign: findInTargets(
      c =>
        state.wlrBroadband.locations[c.locationIndex].type === RESIGN &&
        c.wlrProductId &&
        c.wlrProductId !== RESIGN &&
        c.resignProductType === SAME_PRODUCT_WITH_CHANGES
    ),
    isNewProductBroadbandResign: findInTargets(
      c =>
        state.wlrBroadband.locations[c.locationIndex].type === RESIGN &&
        c.broadbandProductId &&
        c.resignProductType === NEW_PRODUCT
    ),
    isSameProductWCBroadbandResign: findInTargets(
      c =>
        state.wlrBroadband.locations[c.locationIndex].type === RESIGN &&
        c.broadbandProductId &&
        c.resignProductType === SAME_PRODUCT_WITH_CHANGES
    )
  };
};

export default withContextToProps(
  connect(mapStateToProps)(ConfigurationForm),
  TargetConfigsContext
);
