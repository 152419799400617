import * as GuidedSalesAPI from "../../api/v1/guidedSales";
import { addAlertMessage, addFlashMessage } from "../uiState/actions";
import {
  FAILED_TO_SAVE_DRAFT,
  INVALID_SAVE_DRAFT,
  REQUEST_SAVE_DRAFT,
  SAVED_DRAFT
} from "./actionTypes";
export const saveDraftAction = () => async (dispatch, getState) => {
  const state = getState();
  dispatch(requestSaveDraft());

  try {
    const data = await GuidedSalesAPI.SaveWizardState(
      state.order.id,
      JSON.stringify(state)
    );
    dispatch(receiveSavedDraft(data));
    if (
      data.status === "success" &&
      window.location.pathname === "/GuidedSales/LaunchWizardCreateOrder"
    ) {
      // TODO: Fix this. hard because the CPQ ends up with params like order
      //  and opp ID in the URL params on a new order, but saved orders just
      //  have an order id. We don’t want to lose those initial params though,
      //  or reload the whole thing. What do we do about that?
      // window.location.href = `${config.dc_api_url_base}/GuidedSales/LaunchWizard?order_id=${data.order_id}`;
    }
  } catch (error) {
    dispatch(invalidateSaveDraft(error.message));
    dispatch(addAlertMessage(error.message));
  }
};

function requestSaveDraft() {
  return {
    type: REQUEST_SAVE_DRAFT
  };
}

function invalidateSaveDraft(error) {
  return {
    type: INVALID_SAVE_DRAFT,
    error: error
  };
}

function receiveSavedDraft(json) {
  return function(dispatch) {
    if (json.status === "success") {
      const savedDraft = { type: SAVED_DRAFT };
      dispatch(savedDraft);
      dispatch(addFlashMessage("Draft Saved"));
    } else {
      const failed = { type: FAILED_TO_SAVE_DRAFT, error: json.message };
      dispatch(failed);
      dispatch(addAlertMessage(json.message));
    }
  };
}

// Temporary: Somehow drafts are being saved with loading states set, which obviously will never be
// cleared as the in-progress requests themselves aren't part of state. Hence we manually reset those
// states. See crazyness of OverlayContainer for these.

// TODO: Sort loading and error states so this doesn't happen, then remove this horribleness...
// TODO: A lot of these are from abandoned reducer functions. Clean up.

export function resetLoadingStates(state) {
  // Temporary: Refactoring this router into the uiState will break old drafts. Hence update them.
  try {
    delete state.steps;
    delete state.invalidFields;
    delete state.contractLengths;
    delete state.quote;
    delete state.mobileHardwareCredits;
    delete state.mobileHardware;

    state.ethernetProducts.configurations.forEach(c => {
      c.purchaseIsRequesting = false;
    });
    state.wlrBroadband.locations.forEach(l => {
      l.cli.validating = false;
      l.lineAvailability.fetching = false;
      l.installationDetails.fetching = false;
      l.broadbandSearch.fetching = false;
      l.numbers.fetching = false;
    });
    state.wlrBroadband.configurations.forEach(c => {
      c.wlrProductData.fetching = false;
      c.broadbandProductData.fetching = false;
      c.wlrAppointments.fetching = false;
      c.addWlrAppointment.fetching = false;
      c.broadbandAppointments.fetching = false;
      c.addBroadbandAppointment.fetching = false;
      c.numberReservation.fetching = false;
      c.wlrValidation.fetching = false;
      c.broadbandValidation.fetching = false;
    });
  } catch (e) {
    console.log("Could not resetLoadingStates:", e.message, state);
  }
  return state;
}
