import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import {
  addLocation,
  doLineSearch,
  setContractLength,
  setLocationType
} from "../../../store/wlrBroadband/actions";
import { getAccountSettings } from "../../../store/account/selectors";
import { getAccountId } from "../../../store/order/selectors";
import { IndexedCardInfo } from "../../shared/indexed-card-info";
import { ReduxAccountSettingsEnum } from "../../enums";
import { ReduxWlrLocation } from "./types";
import { ContractLength } from "./ContractLength";
import { WlrLocation } from "./wlr-location";
import { ResignsTable, ResignsToggle } from "./resigns";

export const ChooseBroadband = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const contractLength = useSelector<any, number>(
    state => state.wlrBroadband.contractLength
  );
  const locations = useSelector<any, ReduxWlrLocation[]>(
    state => state.wlrBroadband.locations
  );
  const settings = useSelector<
    any,
    { [k in ReduxAccountSettingsEnum]: "0" | "1" }
  >(state => getAccountSettings(state));

  const accountId = useSelector(getAccountId);
  const showWlrResigns = useSelector<any, boolean>(
    state => state.uiState.showWlrResigns
  );
  const userResignsPermission = useSelector(
    (state: any) => state.order.userResignsPermission
  );

  const [locationOpened, setLocationOpened] = useState(0);

  const handleLocationOpened = useCallback(
    index => setLocationOpened(oldIndex => (oldIndex === index ? -1 : index)),
    [setLocationOpened]
  );

  const handleContractLength = useCallback(
    (newContractLength: number) => {
      dispatch(setContractLength(newContractLength));
    },
    [dispatch]
  );
  const handleLocationType = useCallback(
    (index: number, type: string) => {
      dispatch(setLocationType(index, type));
    },
    [dispatch]
  );

  const handleLineSearch = useCallback(() => {
    dispatch(doLineSearch());
  }, [dispatch]);

  const locationsLength = locations.length;
  const handleAddLocation = useCallback(() => {
    setLocationOpened(locationsLength);
    dispatch(addLocation());
  }, [dispatch, setLocationOpened, locationsLength]);

  useEffect(() => {
    handleLineSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSecondPart = !!contractLength;
  const showResignsToggle =
    showSecondPart &&
    !!accountId &&
    (settings.as_wlrbb_resign_enabled === "1" ||
      // eslint-disable-next-line eqeqeq
      userResignsPermission == "1");

  let cardIndex = 0;

  return (
    <Box className={classes.root}>
      <IndexedCardInfo
        index={++cardIndex}
        title={"Please select the required contract length"}
      >
        <Grid container spacing={2}>
          <Grid item>
            <ContractLength
              contractLength={contractLength}
              locations={locations}
              setContractLength={handleContractLength}
              setLocationType={handleLocationType}
              doLineSearch={handleLineSearch}
            />
          </Grid>
          <Grid item>{showResignsToggle && <ResignsToggle />}</Grid>
        </Grid>
      </IndexedCardInfo>
      {showWlrResigns && (
        <IndexedCardInfo
          index={++cardIndex}
          title="Please choose your resigns products"
        >
          <ResignsTable />
        </IndexedCardInfo>
      )}
      {showSecondPart && (
        <>
          <IndexedCardInfo
            index={++cardIndex}
            title={"Please select the Locations"}
          >
            <Box className={classes.locations}>
              {locations.map((location, index) => (
                <WlrLocation
                  key={index}
                  open={index === locationOpened}
                  locationIndex={index}
                  location={location}
                  handleLocationOpened={handleLocationOpened}
                />
              ))}
              <Button
                variant="outlined"
                color="primary"
                fullWidth={true}
                startIcon={<AddCircle />}
                onClick={handleAddLocation}
              >
                Add Another Location
              </Button>
            </Box>
          </IndexedCardInfo>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  locations: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  }
});
