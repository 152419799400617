import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import { getNewAddressFieldValidation } from "../helpers/addresses";

function AddressEntryFields({
  existingAddress,
  newAddress,
  fields,
  disabled,
  updateField
}) {
  const fieldLabels = fields.map(f =>
    _.capitalize(f)
      .replace(/([^a-zA-Z_])/g, " $1")
      .replace(/_/g, " ")
  );

  return (
    <Grid container spacing={1}>
      {fields.map((field, i) => {
        const value =
          (newAddress.hasOwnProperty(field)
            ? newAddress[field]
            : _.get(existingAddress, field)) || "";

        const fieldValidation = getNewAddressFieldValidation(field, value);

        const fieldProps = {
          label: fieldLabels[i],
          value,
          id: field,
          onChange: updateField,
          disabled,
          required: fieldValidation.required
        };

        if (field === "country")
          return (
            <Grid item md={3} key={i}>
              <FormControl fullWidth error={fieldValidation.error}>
                <InputLabel>{`${fieldLabels[i]}${
                  fieldProps.required ? " *" : ""
                }`}</InputLabel>
                <Select
                  {...fieldProps}
                  onChange={event => updateField("country", event.target.value)}
                >
                  <MenuItem value="United Kingdom" key="uk">
                    <b>United Kingdom</b>
                  </MenuItem>
                  {_.get(existingAddress, "countries", []).map((country, i) => (
                    <MenuItem value={country} key={i}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
                {fieldValidation.error && (
                  <FormHelperText error={!!fieldValidation.error}>
                    {fieldValidation.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          );

        return (
          <Grid item md={3} key={i}>
            <TextField
              {...fieldProps}
              fullWidth
              onChange={event =>
                updateField(event.target.id, event.target.value)
              }
              error={fieldValidation.error}
              helperText={fieldValidation.error && fieldValidation.message}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

AddressEntryFields.propTypes = {
  existingAddress: PropTypes.object.isRequired,
  newAddress: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  updateField: PropTypes.func.isRequired
};

export default AddressEntryFields;
