export function calculateQuantity(price, qty) {
  let temp = qty * (price * 100);
  return temp / 100;
}

export const getParsedTotalToTwoDecimalPlaces = (
  number1 = 0,
  number2 = 0,
  number3 = 0
) => {
  let total = parseFloat(number1) + parseFloat(number2) + parseFloat(number3);

  //divide by 1 as otherwise it returns a string
  return total.toFixed(2);
};

/**
 * Generate a reasonably random string of characters
 * For passwords and such.
 * See https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
 * @returns {string}
 */
export const getRandomString = (length = 9) =>
  Math.random()
    .toString(36)
    .substr(2, length);
