import React from "react";
import { connect } from "react-redux";
import { getConfigDocuments } from "../../../../store/universalProducts/selectors";
import Section from "../../../../components/Shared/Section";
import Spinner from "../../../../components/Shared/Spinner";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DocumentChip from "./DocumentChip";
import { StatusChip } from "@akj-dev/design-system";

class DownloadDocuments extends React.Component {
  render() {
    const { documents } = this.props;
    return (
      <Section
        title="Download Documents"
        avatar={documents.fetching ? <Spinner /> : <InsertDriveFileIcon />}
      >
        {documents.response.documents && (
          <>
            {documents.response.documents.length ? (
              documents.response.documents.map((doc, key) => {
                if (doc)
                  return (
                    <DocumentChip
                      key={key}
                      filename={doc.filename}
                      href={`/cgi-bin/index.cgi/ExternalServices/v1/Product/DownloadDocument?product_id=${documents.response.product_id}&document_id=${doc.id}`}
                    />
                  );
                return false;
              })
            ) : (
              <StatusChip
                type="info"
                message="No documents available for this product."
              />
            )}
          </>
        )}
      </Section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    documents: getConfigDocuments(state)
  };
};

export default connect(mapStateToProps, {})(DownloadDocuments);
