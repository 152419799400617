import React from "react";
import MultiLineIcon from "@material-ui/icons/SettingsPhone";
import Section from "../../../../../components/Shared/Section";
import WlrBBDynamicField from "../WlrBBDynamicField/";

// TODO: Product data reports this as a text field, but gives a number for the value. Get corrected in DC.

const MultiLineOptions = props => {
  return (
    <Section title="Multi-Line Options" avatar={<MultiLineIcon />}>
      <WlrBBDynamicField productType="wlr" propertyName="number_of_channels" />
    </Section>
  );
};

MultiLineOptions.propTypes = {};

export default MultiLineOptions;
