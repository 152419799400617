import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  makeGetAvailableCliBoltOnsByType,
  makeGetAvailableCliTopUpBoltOns,
  makeGetSelectedCliBoltOn
} from "../../../../../../store/mobile/selectors/productConfig";
import { chooseCliBoltOn } from "../../../../../../store/mobile/actionCreators";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

class CliTopUpBoltOnSelectField extends React.PureComponent {
  static defaultProps = {};
  static propTypes = {
    title: PropTypes.string.isRequired,
    configId: PropTypes.number.isRequired,
    boltOnType: PropTypes.string.isRequired // DC category
  };
  state = {};

  render() {
    const {
      chooseCliBoltOn,
      configId,
      boltOnType,
      availableCliBoltOns,
      availableCliTopUpBoltOns,
      selectedCliBoltOn,
      title
    } = this.props;
    const hasSelectedCliBoltOn = !!availableCliBoltOns.find(
      boltOn => boltOn.id === selectedCliBoltOn
    );
    return (
      <div>
        <FormControl fullWidth margin="normal">
          <InputLabel>{title}</InputLabel>
          <Select
            onChange={event => {
              chooseCliBoltOn(configId, boltOnType, event.target.value);
            }}
            value={hasSelectedCliBoltOn ? 0 : selectedCliBoltOn}
            disabled={hasSelectedCliBoltOn}
          >
            {[{ id: 0, name: "Not required" }]
              .concat(availableCliTopUpBoltOns)
              .map(boltOn => {
                const price = _.get(
                  boltOn,
                  ["price", "first_bill_recurring_price_with_promotions"],
                  false
                );
                return (
                  <MenuItem value={boltOn.id} key={boltOn.id}>
                    {boltOn.name + (price ? " - £" + price : "")}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getSelectedCliBoltOn = makeGetSelectedCliBoltOn();
  const getAvailableCliBoltOns = makeGetAvailableCliBoltOnsByType();
  const getAvailableCliTopUpBoltOns = makeGetAvailableCliTopUpBoltOns();
  return (state, ownProps) => ({
    selectedCliBoltOn: getSelectedCliBoltOn(state, ownProps),
    availableCliBoltOns: getAvailableCliBoltOns(state, ownProps),
    availableCliTopUpBoltOns: getAvailableCliTopUpBoltOns(state, ownProps)
  });
};

export default connect(makeMapStateToProps, {
  chooseCliBoltOn
})(CliTopUpBoltOnSelectField);
