import _ from "lodash";
import { getHardwareOrderTotalBeforeCredit } from "../../order/selectors";

/**
 * Get the total one off price for a configuration
 *
 * @param configuration {Object}
 * @param products {Array}
 * @param hasConnections {Boolean}
 * @param ignoreCredit {Boolean}
 *
 * @returns {float}
 */
export function configurationTotalOneOffPrice(
  configuration,
  products,
  hasConnections,
  ignoreCredit = false
) {
  const product = configuration.product;

  let price =
    configuration["quantity"] *
    (hasConnections
      ? product.price.oneOffWithConnection
      : product.price.oneOff);

  if (configuration["rows"]) {
    configuration["rows"].forEach(row => {
      if (row["is_leasing"]) {
        price =
          price -
          parseFloat(
            hasConnections
              ? product.price.oneOffWithConnection
              : product.price.oneOff
          );
      } else if (row["credit_used"] && !ignoreCredit) {
        price = price - parseFloat(row["credit_used"]);
      }
    });
  }

  return parseFloat(price).toFixed(2);
}

/**
 * Get the total one off price for all products
 *
 * @param configurations {Object}
 * @param products {Array}
 * @param {boolean} hasConnections
 *
 * @returns {float}
 */
export function totalOneOffPrice(configurations, products, hasConnections) {
  let total = 0;

  _.forEach(configurations, configuration => {
    total =
      total +
      parseFloat(
        configurationTotalOneOffPrice(configuration, products, hasConnections)
      );
  });

  return parseFloat(total).toFixed(2);
}

/**
 * Get the total lease price for a configuration
 *
 * @param config {Object}
 *
 * @returns {float}
 */
export function configurationTotalLeasePrice(config) {
  const { price } = config.product;

  const total = config.rows.reduce((acc, row) => {
    const rowPrice = row.is_leasing
      ? row.isLeasedRV
        ? price.leaseRV
        : price.leaseNonRV
      : 0;
    return acc + parseFloat(rowPrice);
  }, 0);

  return total.toFixed(2);
}

/**
 * Get the total lease price for all products
 *
 * @param configurations {Object}
 * @param products {Array}
 *
 * @returns {float}
 */
export function totalLeasePrice(configurations, products) {
  let total = 0;

  _.forEach(configurations, config => {
    total = total + parseFloat(configurationTotalLeasePrice(config, products));
  });

  return parseFloat(total).toFixed(2);
}

/**
 * Get the total quantity
 *
 * @param configurations {Object}
 * @param products {Array}
 *
 * @returns {float}
 */
export function totalQuantity(configurations) {
  return _.sumBy(
    _.keys(configurations),
    index => configurations[index].quantity
  );
}

/**
 * Get the total leased products
 *
 * @param configurations {Object}
 *
 * @returns {float}
 */
export function totalLeasedProducts(configurations) {
  return _.sumBy(_.keys(configurations), index =>
    _.sumBy(configurations[index].rows, row => (row && row.is_leasing ? 1 : 0))
  );
}

/**
 * Get total hardware credit for a single product config (across all it's rows)
 *
 * @param config
 * @returns {number}
 */
export function totalConfigHardwareCredit(config) {
  return _.sumBy(config.rows, row => row && parseFloat(row.credit_used || 0));
}
/**
 * Get the total hardware credit for all products on the order
 *
 * @param configurations {Object}
 * @returns {number}
 */
export function getTotalOrderHardwareCredit(configurations) {
  return _.sumBy(_.keys(configurations), index =>
    totalConfigHardwareCredit(configurations[index])
  );
}

/**
 * Get any error message involving HW credit
 * @param state
 * @returns {string|boolean}
 */
export function getHardwareCreditError(state) {
  const totalOrderHardwareCredit = getTotalOrderHardwareCredit(
    state.hardwareConfigurations
  );
  const hardwareOrderTotal = getHardwareOrderTotalBeforeCredit(state);

  if (totalOrderHardwareCredit > state.account.availableHardwareCredit) {
    return `You are trying to use £${totalOrderHardwareCredit} credit. Only £${state.account.availableHardwareCredit} is available.`;
  }

  if (totalOrderHardwareCredit > hardwareOrderTotal) {
    return "Hardware credit must not exceed hardware order value.";
  }

  return false;
}
