import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { StatusChip } from "@akj-dev/design-system";
import { Grid, Typography } from "@material-ui/core";
import { setOtherConfiguration } from "../../../store/wlrBroadband/actions";
import { IndexedTitle } from "../../../screens/shared/indexed-card-info/IndexedTitle";
import { WlrLocationProductCard } from "../../../screens/choose/choose-broadband/wlr-location/wlr-location-product-card/WlrLocationProductCard";
import { WlrLocationTypeEnum } from "../../../screens/choose/choose-broadband/enums";
import ONTInfo from "./ONTInfo";

class ChooseFTTPProduct extends Component {
  static defaultProps = {};
  static propTypes = {
    locationIndex: PropTypes.number.isRequired
  };

  state = {};

  render() {
    const {
      broadbandSearch,
      lineAvailability,
      locationIndex,
      setOtherConfiguration
    } = this.props;

    if (broadbandSearch.fetching || lineAvailability.fetching)
      return (
        <StatusChip
          type="loading"
          title="Please wait"
          message="Checking Line & Broadband Availability"
        />
      );

    if (_.get(lineAvailability.response, "status") === "error")
      return (
        <StatusChip
          type="error"
          title="Error"
          message={lineAvailability.response.message}
        />
      );

    if (_.get(broadbandSearch.response, "status") === "error")
      return (
        <StatusChip
          type="error"
          title="Error"
          message={broadbandSearch.response.message}
        />
      );

    if (!broadbandSearch.response.products)
      return (
        <StatusChip
          type="info"
          message="FTTP doesn’t require separate line rental. An existing line can be used to check FTTP availability at the premises but the FTTP install will be independent of any existing line at the premises. If a line for voice is required, please order this as a separate item on the wizard."
        />
      );

    const fttpProducts = broadbandSearch.response.products.filter(
      p => p.first_broadband_component.type === "FTTP"
    );
    if (
      fttpProducts.length < 1 &&
      broadbandSearch.response.status === "success"
    )
      return (
        <StatusChip
          type="error"
          title="No FTTP products available at this address."
          message="Please try again."
        />
      );

    return (
      <div>
        <Typography variant="h4" gutterBottom>
          <IndexedTitle
            index={"C"}
            title={"Choose a Product"}
            withMargin={true}
            negative={true}
          />
        </Typography>
        <ONTInfo
          ontDetails={_.get(lineAvailability.response, "ont_details", {})}
        />
        <Grid item md={6}>
          <WlrLocationProductCard
            locationIndex={locationIndex}
            productId={WlrLocationTypeEnum.NEW_FTTP}
            title={WlrLocationTypeEnum.NEW_FTTP}
            subtitle={``}
            handleClick={() =>
              setOtherConfiguration(locationIndex, {
                id: WlrLocationTypeEnum.NEW_FTTP
              })
            }
          />
        </Grid>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  return (state, ownProps) => ({
    lineAvailability:
      state.wlrBroadband.locations[ownProps.locationIndex]?.lineAvailability,
    broadbandSearch:
      state.wlrBroadband.locations[ownProps.locationIndex]?.broadbandSearch
  });
};

export default connect(makeMapStateToProps, {
  setOtherConfiguration
})(ChooseFTTPProduct);
