import { call, put, takeLeading, select, takeLatest } from "redux-saga/effects";
import * as AccountAPI from "../../api/v1/account";
import * as LeadsAPI from "../../api/v1/leads";
import * as VodafoneAPI from "../../api/v1/vodafone";
import { getAccountId, getLeadId, getOrderId } from "../order/selectors";
import { getNumberOfMobileConfigs } from "../mobile/selectors/order";
import * as actionTypes from "./actionTypes";
import {
  receiveAccountSettings,
  setCreditVetError,
  setCreditVetSuccess
} from "./actions";

function* fetchAccountSettings(action) {
  const account = yield select(getAccountId);
  const lead_id = yield select(getLeadId);
  let query;
  if (account) {
    query = { account };
  } else if (lead_id) {
    query = { lead_id };
  }

  if (query) {
    const response = yield call(AccountAPI.Settings, query);
    yield put(receiveAccountSettings(response));
  } else {
    throw new Error("No account or lead ID set");
  }
}

export function* watchAccountSettings() {
  yield takeLeading(actionTypes.REQUEST_ACCOUNT_SETTINGS, fetchAccountSettings);
}
/**
 * Fetch the billing address for an account
 * @param action
 * @returns {IterableIterator<*>}
 */
function* fetchBillingAddress(action) {
  const accountId = yield select(getAccountId);
  const orderId = yield select(getOrderId);
  const response = yield call(AccountAPI.billingAddress, accountId, orderId);
  yield put({ type: actionTypes.RECEIVE_BILLING_ADDRESS, response });
}

export function* watchBillingAddress() {
  yield takeLeading(actionTypes.REQUEST_BILLING_ADDRESS, fetchBillingAddress);
}

/**
 * Update an account's billing address
 * @param action
 * @returns {IterableIterator<*>}
 */
function* fetchUpdateBillingAddress(action) {
  const accountId = yield select(getAccountId);
  const response = yield call(
    AccountAPI.billingAddressPost,
    accountId,
    action.address
  );
  yield put({ type: actionTypes.RECEIVE_BILLING_ADDRESS, response });
}

export function* watchSetBillingAddress() {
  yield takeLeading(
    actionTypes.REQUEST_UPDATE_BILLING_ADDRESS,
    fetchUpdateBillingAddress
  );
}

/**
 * Fetch account addresses
 * @param action
 * @returns {IterableIterator<*>}
 */
function* fetchAllAccountAddresses(action) {
  const accountId = yield select(getAccountId);
  const orderId = yield select(getOrderId);
  const response = yield call(
    AccountAPI.allAccountAddresses,
    accountId,
    orderId
  );
  yield put({ type: actionTypes.RECEIVE_ALL_ACCOUNT_ADDRESSES, response });
}

export function* watchAllAccountAddresses() {
  yield takeLeading(
    actionTypes.REQUEST_ALL_ACCOUNT_ADDRESSES,
    fetchAllAccountAddresses
  );
}

/**
 * Fetch contacts associated with this account.
 * ....or lead if we don't have an account yet.
 * @param action
 * @returns {IterableIterator}
 */
function* fetchAllContacts(action) {
  const accountId = yield select(getAccountId);
  const leadId = yield select(getLeadId);
  const orderId = yield select(getOrderId);
  // TP12082: For now, use a flag to only ever show the lead contacts for the lead contact select.
  const { isLeadContactSelect } = action;
  let response;
  if (isLeadContactSelect) {
    response = yield call(LeadsAPI.AllLeadContacts, leadId);
  } else {
    if (!accountId) {
      if (!leadId)
        console.error("No lead or account ID when fetching contacts.");
      response = yield call(LeadsAPI.AllLeadContacts, leadId);
    } else {
      response = yield call(AccountAPI.AllAccountContacts, accountId, orderId);
    }
  }
  yield put({ type: actionTypes.RECEIVE_ALL_CONTACTS, response });
}

export function* watchAllAccountContacts() {
  yield takeLeading(actionTypes.REQUEST_ALL_CONTACTS, fetchAllContacts);
}

/**
 * Get availablecontact types
 * @param action
 * @returns {IterableIterator}
 */
function* fetchContactTypes(action) {
  const response = yield call(AccountAPI.ContactTypes);
  yield put({ type: actionTypes.RECEIVE_CONTACT_TYPES, response });
}

export function* watchContactTypes() {
  yield takeLeading(actionTypes.REQUEST_CONTACT_TYPES, fetchContactTypes);
}

export function* fetchCreditVet() {
  const accountId = yield select(getAccountId);
  const numberOfConnections = yield select(getNumberOfMobileConfigs);

  // first perform some defensive checks to verify that we're ready to do this
  if (!accountId) {
    return console.error(
      "Cannot fetch credit vet status before an account ID is set"
    );
  }

  if (!numberOfConnections) {
    return console.error("Cannot process credit vet before products selected ");
  }

  // two endpoints involved (one to process, one to retreive status)
  try {
    // call 1. only call the process endpoint if we've not had a succesful chain of responses yet
    const creditVetResponseState = yield select(
      state => state.account.creditVet.response
    );
    if (creditVetResponseState.status !== "success") {
      const processApiResponse = yield call(
        VodafoneAPI.VodaFoneProcessCreditVet,
        {
          account_id: accountId,
          number_of_connections: numberOfConnections
        }
      );
      if (processApiResponse.status !== "success") {
        throw new Error();
      }
    }

    // call 2. get the results of the credit vet
    const creditStatusApiResponse = yield call(
      AccountAPI.ThirdPartyBillingCreditVetStatus,
      accountId
    );
    if (creditStatusApiResponse.status !== "success") {
      throw new Error();
    }
    yield put(setCreditVetSuccess(creditStatusApiResponse));
  } catch (e) {
    yield put(setCreditVetError());
  }
}

export function* watchRequestCreditVetStatus() {
  yield takeLatest(actionTypes.REQUEST_CREDIT_VET, fetchCreditVet);
}
