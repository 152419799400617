import u from "updeep";
import * as actionType from "../../actionTypes";

const broadbandToWlrFieldMappings = {
  "bb.contact.contactTelephone": "contact_telephone",
  "bb.contact.contactEmail": "contact_email",
  "bb.address.premisesName": "premisesname",
  "bb.address.thoroughfareNumber": "thoroughfarenumber",
  "bb.address.thoroughfareName": "thoroughfarename",
  "bb.address.postTown": "posttown",
  "bb.address.county": "county", // bb one doesn't exist yet, it seems
  "bb.address.postcode": "postcode"
};

export default (state, action) => {
  switch (action.type) {
    case actionType.SET_PRODUCT_PROPERTY:
      let productPropsUpdate = {};
      action.targetConfigs.forEach(i => {
        // Set the property for each of the specified configs
        productPropsUpdate[i] = (() => {
          let config = state.configurations[i];
          // Unfortunately nothing is straightforward with DC and we need some exceptions here:
          switch (action.propertyName) {
            // Broadband has names as separate forename and surname fields. WLR has just the one.
            // Hence this horribleness joining them and copying them over.... to the multiple places they are required 😳:
            case "bb.contact.contactForename":
              return {
                broadbandProperties: {
                  "bb.contact.contactForename": action.value,
                  // Also, separate name fields for appointments, which we don't want to show the user. Oh god.
                  "bb.appointment.contact.contactForename": action.value,
                  // ..and the router address, if they're having one.
                  "router.first_name": action.value
                },
                wlrProperties: {
                  contact_name: `${action.value} ${config.broadbandProperties["bb.contact.contactSurname"]}`,
                  end_user_name_for_999: `${action.value} ${config.broadbandProperties["bb.contact.contactSurname"]}`
                }
              };
            case "bb.contact.contactSurname":
              return {
                broadbandProperties: {
                  "bb.contact.contactSurname": action.value,
                  "bb.appointment.contact.contactSurname": action.value,
                  "router.last_name": action.value
                },
                wlrProperties: {
                  contact_name: `${config.broadbandProperties["bb.contact.contactForename"]} ${action.value}`,
                  end_user_name_for_999: `${config.broadbandProperties["bb.contact.contactForename"]} ${action.value}`
                }
              };

            // TODO bb.contact.title ....also combine in contact_name? Required?

            // When the order is line only, the above fields won't be used,
            // so we need to copy the single field over to the 999 entry when it's used directly too.
            case "contact_name":
              return {
                wlrProperties: {
                  contact_name: action.value,
                  end_user_name_for_999: action.value
                }
              };

            // Telephone needs to be sent in the request four times...
            case "bb.contact.contactTelephone":
              return {
                broadbandProperties: {
                  "bb.contact.contactTelephone": action.value,
                  "bb.appointment.contact.contactTelephone": action.value,
                  "router.telephone": action.value
                },
                wlrProperties: {
                  contact_telephone: action.value
                }
              };

            // The business name field needs copying over to WLR if there's an associated line install or transfer.
            // ...or if it's an MPF product, to it's separate business directory field. BB Business directory fields only
            // exist for MPF, not other broadband types, and the WLR directory firelds don't have a separate business name,
            // presumably using the main 'company' field.
            // And that's a Go Johnny Go Go Go Go! https://www.youtube.com/watch?v=WtSvaSZ-xLU
            case "bb.contact.businessName":
              return {
                broadbandProperties: {
                  "bb.contact.businessName": action.value,
                  "bb.businessDirectory.businessName": action.value, // This will just be ignored if not MPF hopefully.
                  "router.company": action.value
                },
                wlrProperties: {
                  company: action.value
                }
              };

            case "redcare_installation":
              return {
                wlrProperties: {
                  redcare_installation: action.value
                },
                ...(action.value === 1 && {
                  broadbandProductId: false,
                  broadbandProductData: {
                    response: () => ({})
                  }
                })
              };

            default:
              // Some broadband fields have direct WLR counterparts. Check for these and copy:
              if (
                broadbandToWlrFieldMappings.hasOwnProperty(action.propertyName)
              )
                return {
                  broadbandProperties: { [action.propertyName]: action.value },
                  wlrProperties: {
                    [broadbandToWlrFieldMappings[action.propertyName]]:
                      action.value
                  }
                };

              // If it's a wlr change property reverting to it's initial value, remove the prop from the store entirely.
              // Note: loose equality comparison == used as we can have number 0 or string 0 for booleans
              if (
                action.productType === "broadband" &&
                action.propertyName.includes("wlr_change") &&
                config.broadbandProductData.initialWlrChangeValues[
                  action.propertyName
                ] === action.value
              ) {
                return {
                  broadbandProperties: u.omit(action.propertyName)
                };
              }

              return {
                // ...or under normal circumstances,
                // just take the property name specified in the component and save to state:
                [`${action.productType}Properties`]: {
                  [action.propertyName]: action.value
                }
              };
          }
        })();
      });

      return u(
        {
          configurations: productPropsUpdate
        },
        state
      );

    default:
      return state;
  }
};
