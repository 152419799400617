import React, { useState, useEffect } from "react";
import PersonIcon from "@material-ui/icons/Person";
import { ExpandingCard } from "@akj-dev/design-system";
import FormTextField from "../FormTextField";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ethernetPurchaseArgsValidationErrors } from "../../../../../store/ethernetProducts/selectors";

const SiteContact = ({ prefix, index }) => {
  const [expanded, setExpanded] = useState(true);

  const validationFields = [
    prefix + "_site_address_company",
    prefix + "_site_contact_name",
    prefix + "_site_contact_phone"
  ];

  const validationErrors = useSelector(state =>
    ethernetPurchaseArgsValidationErrors(state, index)
  ).filter(field => validationFields.includes(field));

  const showErrors = useSelector(state =>
    state.ethernetProducts.configurationIndexesSubmitted.includes(index)
  );

  const forceExpanded = showErrors && validationErrors.length > 0;

  const handleHeaderClick = () => {
    if (forceExpanded) {
      return;
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (forceExpanded) {
      setExpanded(true);
    }
  }, [forceExpanded]);

  return (
    <ExpandingCard
      title="Site Contact"
      avatar={<PersonIcon />}
      expanded={expanded}
      onHeaderClick={handleHeaderClick}
    >
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <FormTextField
            label="End User Company Name *"
            param={prefix + "_site_address_company"}
            index={index}
          />
        </Grid>
        <Grid item sm={4}>
          <FormTextField
            label="Contact Name *"
            param={prefix + "_site_contact_name"}
            index={index}
          />
        </Grid>
        <Grid item sm={4}>
          <FormTextField
            label="Contact Phone *"
            param={prefix + "_site_contact_phone"}
            index={index}
            errorText="A valid phone number is required"
          />
        </Grid>
      </Grid>
    </ExpandingCard>
  );
};

SiteContact.propTypes = {
  index: PropTypes.number.isRequired,
  prefix: PropTypes.oneOf(["site_a", "site_b"]).isRequired
};

export default SiteContact;
