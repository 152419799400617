import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem } from "@material-ui/core";
import { useAuth } from "@akj-dev/affinity-platform";
import { useSaleStatuses } from "../hooks";
import { SaleStatusEnum } from "./enums";

export interface SaleStatusSelectorProps {
  saleStatusSelected: SaleStatusEnum;
  setSaleStatusSelected: Dispatch<SetStateAction<SaleStatusEnum>>;
}

export const SaleStatusSelector = ({
  saleStatusSelected,
  setSaleStatusSelected,
}: SaleStatusSelectorProps) => {
  const { root } = useStyles();
  const accountSettings = useAuth().accountSettings;
  const saleStatuses = useSaleStatuses(accountSettings);

  const handleChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const newSaleType = event.target.value as SaleStatusEnum;
    setSaleStatusSelected(newSaleType);
  };

  return (
    <Select
      classes={{ root }}
      variant="outlined"
      value={saleStatusSelected}
      onChange={handleChange}
    >
      {saleStatuses.map(({ id, label }) => (
        <MenuItem key={id} value={id}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

const useStyles = makeStyles({
  root: {
    width: "200px",
  },
});
