import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import DateIcon from "@material-ui/icons/DateRange";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { setResignStartDate } from "../../../store/wlrBroadband/actions";
import { DC_DATE_FORMAT } from "../../../helpers/date";
import { startOfMonth, addMonths, format } from "date-fns";

const Outer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 20px;
`;

const CustomDateIcon = styled(DateIcon)`
  margin-right: 10px;
`;

class ResignStartDate extends React.Component {
  render() {
    const { setResignStartDate, resignStartDate } = this.props;
    const startOfNextMonth = startOfMonth(addMonths(new Date(), 1));
    return (
      <Outer>
        <CustomDateIcon />
        <KeyboardDatePicker
          label="Resign Start Date"
          format={DC_DATE_FORMAT}
          value={resignStartDate || format(startOfNextMonth, DC_DATE_FORMAT)}
          onChange={(event, date) => setResignStartDate(date)}
          minDate={startOfNextMonth}
        />
      </Outer>
    );
  }
}

const mapStateToProps = state => ({
  resignStartDate: state.wlrBroadband.resignStartDate
});

export default connect(mapStateToProps, {
  setResignStartDate
})(ResignStartDate);
