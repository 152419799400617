import u from "updeep";
import _ from "lodash";
import * as actionTypes from "../actionTypes";
import { RESIGN_WITH_CHANGES } from "../constants";
import { addWeekdays } from "../../../helpers/date";
import { configDefaults } from "../defaults";

export default (state, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_PRODUCT_INSTANCES:
      return u(
        {
          productInstances: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_PRODUCT_INSTANCES:
      if (
        state.productInstances.response.results &&
        action.response.status === "success"
      ) {
        return u(
          {
            productInstances: {
              fetching: false,
              response: {
                results: r => [...r, ...action.response.results]
              }
            }
          },
          state
        );
      } else {
        return u(
          {
            productInstances: {
              fetching: false,
              response: () => action.response
            }
          },
          state
        );
      }

    case actionTypes.REQUEST_RESIGN_PRODUCT:
      return u(
        {
          resignProductSearch: {
            fetching: true
          }
        },
        state
      );

    case actionTypes.RECEIVE_RESIGN_PRODUCT:
      return u(
        {
          resignProductSearch: {
            fetching: false,
            response: () => action.response
          }
        },
        state
      );

    // Create or update configs for resign products
    case actionTypes.SET_RESIGN_TYPE:
      return u(
        {
          configs: configs => {
            let newConfigs = [...configs];
            action.resignIds.forEach(id => {
              const i = newConfigs.findIndex(c => c.resignId === id);
              if (i > -1) {
                newConfigs[i] = {
                  ...newConfigs[i],
                  resignType: action.resignType
                };
              } else {
                newConfigs.push({
                  resignId: id,
                  resignType: action.resignType
                });
              }
            });
            return newConfigs;
          }
        },
        state
      );

    // Set the new product on a resign config, or create if not already there.
    // TODO: There must be a simpler way to assign / create. Merge objects with comparison fn?
    case actionTypes.SET_RESIGN_PRODUCT:
      const defaultProperties = {
        ...configDefaults(state, action.productId, {}),
        acquisition_method: "resign",
        port_date: addWeekdays(Date.now(), 5, true)
      };

      return u(
        {
          configs: configs => {
            let newConfigs = [...configs];
            action.resignIds.forEach(id => {
              const i = newConfigs.findIndex(c => c.resignId === id);
              // If a config for this resign already exists, update it
              if (i > -1) {
                newConfigs[i] = {
                  ...newConfigs[i],
                  resignType: RESIGN_WITH_CHANGES,
                  productId: action.productId,
                  properties: defaultProperties
                };
                // or create a new one if it doesn't
              } else {
                newConfigs.push({
                  resignId: id,
                  resignType: RESIGN_WITH_CHANGES,
                  productId: action.productId,
                  properties: defaultProperties
                });
              }
            });
            return newConfigs;
          }
        },
        state
      );

    case actionTypes.REMOVE_RESIGN:
      return u(
        {
          configs: c => c.filter(c => !action.resignIds.includes(c.resignId))
        },
        state
      );

    case actionTypes.REQUEST_RESIGN_PROPERTY_VALUES:
      return u(
        {
          configs: {
            [action.configId]: {
              resignPropertyValues: {
                fetching: true
              }
            }
          }
        },
        state
      );

    case actionTypes.RECEIVE_RESIGN_PROPERTY_VALUES:
      // Get any set current values to add to config properties
      // These will override any set by configDefaults() in SET_RESIGN_PRODUCT, which is what we want.
      let propertyUpdate = {};
      const returnedProperties = _.get(
        action.response,
        "mobile.dynamic_properties",
        {}
      );
      for (let k in returnedProperties) {
        const { current_value } = returnedProperties[k];
        if (
          current_value !== null &&
          k !== "port_date" // Port date is auto-filled by GS earlier.
        )
          propertyUpdate[k] = current_value;

        // Correct a DC error:
        // available_option_map for this field has string 1, but current_value can be number 1
        if (k === "is_sim_required" && current_value === 1)
          propertyUpdate[k] = "1";
        if (k === "is_sim_required" && current_value === 0)
          propertyUpdate[k] = "0";
      }

      return u(
        {
          configs: {
            [action.configId]: {
              resignPropertyValues: {
                fetching: false,
                status: action.response.status,
                message: action.response.message
              },
              properties: propertyUpdate
            }
          }
        },
        state
      );

    case actionTypes.SET_RESIGN_START_DATE:
      return u(
        {
          resignStartDate: action.date
        },
        state
      );
    default:
      return state;
  }
};
