import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import styled from "styled-components";
import { StatusChip } from "@akj-dev/design-system";
import LowPriorityInfoChip from "../../components/Shared/LowPriorityInfoChip";
import {
  requestBillingAddress,
  requestUpdateBillingAddress
} from "../../store/account/actions";
import AddressEntryFields from "../../components/AddressEntryFields";
import { getValidForResign } from "../../store/account/selectors";
import Button from "@material-ui/core/Button";

/**
 * Show billing address with option to correct a low quality one
 * Note: There's a similar thing in src/js/containers/StepChoose/Ethernet/sections/AddressCorrect.js
 * where we correct low quality addresses generated from a postcode lookup at the start of the Ethernet process.
 */
class BillingAddress extends React.Component {
  static propTypes = {};
  state = {};

  componentDidMount() {
    if (this.props.status !== "success") {
      this.props.requestBillingAddress();
    }
  }

  fields = [
    "address1",
    "address2",
    "address3",
    "address4",
    "address5",
    "postcode",
    "country"
  ];

  updateAddressField = (field, value) => this.setState({ [field]: value });

  updateAddress = () => {
    const address = this.state;
    this.props.requestUpdateBillingAddress(address);
  };

  render() {
    const {
      billingAddress,
      validForResign,
      status,
      fetching,
      errorMessage
    } = this.props;
    return (
      <>
        {status === "error" && (
          <MarginWrapper>
            <StatusChip
              type="error"
              title="Billing Address Error"
              message={errorMessage}
            />
          </MarginWrapper>
        )}
        {validForResign === 0 ? (
          <>
            <MarginWrapper>
              <StatusChip
                type="error"
                title="Low quality billing address"
                message="Please update before proceeding with order."
              />
            </MarginWrapper>
            <MarginWrapper>
              <AddressEntryFields
                existingAddress={billingAddress}
                newAddress={this.state}
                fields={this.fields}
                disabled={fetching}
                updateField={this.updateAddressField}
              />
            </MarginWrapper>
            <Button
              variant="contained"
              color="primary"
              disabled={_.isEmpty(this.state) || fetching}
              onClick={this.updateAddress}
              data-cy="BillingAddressUpdateButton"
            >
              Update
            </Button>
          </>
        ) : (
          <LowPriorityInfoChip>
            {this.fields.map((field, i) => (
              <div key={i}>{_.get(billingAddress, field)}</div>
            ))}
          </LowPriorityInfoChip>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    fetching: state.account.billingAddress.fetching,
    status: state.account.billingAddress.response.status,
    billingAddress: state.account.billingAddress.response.data,
    errorMessage: state.account.billingAddress.response.message,
    validForResign: getValidForResign(state)
  };
};

export default connect(mapStateToProps, {
  requestBillingAddress,
  requestUpdateBillingAddress
})(BillingAddress);

const MarginWrapper = styled.div`
  margin: 16px 0;
`;
