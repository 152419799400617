import React from "react";
import { connect } from "react-redux";
import { setConfigProperty } from "../../../../store/universalProducts/actionCreators";
import Section from "../../../../components/Shared/Section";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { getSelectedConfig } from "../../../../store/universalProducts/selectors";
import TextField from "@material-ui/core/TextField";

class Pricing extends React.Component {
  render() {
    const { oneOffPrice, recurringPrice, setConfigProperty } = this.props;
    return (
      <Section title="Pricing" avatar={<MonetizationOnIcon />}>
        <TextField
          label="One Off Price"
          type="number"
          value={oneOffPrice}
          onChange={event =>
            setConfigProperty("oneOffPrice", event.target.value)
          }
          data-cy="PricingOneOff"
        />
        <TextField
          label="Recurring Price"
          type="number"
          value={recurringPrice}
          onChange={event =>
            setConfigProperty("recurringPrice", event.target.value)
          }
          data-cy="PricingRecurring"
        />
      </Section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const config = getSelectedConfig(state);
  return {
    oneOffPrice: config.oneOffPrice,
    recurringPrice: config.recurringPrice
  };
};

export default connect(mapStateToProps, {
  setConfigProperty
})(Pricing);
