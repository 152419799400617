export enum SaleStepEnum {
  STEP_CHOOSE = "uiState.STEP_CHOOSE",
  STEP_CUSTOMISE = "uiState.STEP_CUSTOMISE",
  STEP_ACCOUNT = "uiState.STEP_ACCOUNT", // New accounts only
  STEP_DELIVERY = "uiState.STEP_DELIVERY",
  STEP_SUMMARY = "uiState.STEP_SUMMARY"
}

export enum ProductTypesEnum {
  MOBILE = "as_mobile_order_enabled",
  HARDWARE = "as_hardware_order_enabled",
  BROADBAND = "as_wlrbb_order_enabled",
  ETHERNET = "as_ethernet_order_enabled",
  MONITORING = "as_monitoring_order_enabled",
  UNIVERSAL = "as_universal_enabled",

  // custom not existing product type for selection of products
  SELECT = "select_product_types"
}

export enum ReduxAccountSettingsEnum {
  // product types
  MOBILE = "as_mobile_order_enabled",
  HARDWARE = "as_hardware_order_enabled",
  BROADBAND = "as_wlrbb_order_enabled",
  ETHERNET = "as_ethernet_order_enabled",
  MONITORING = "as_monitoring_order_enabled",
  UNIVERSAL = "as_universal_enabled",

  // custom not existing product type for selection of products
  SELECT = "select_product_types",

  // rest
  O2_SELECT_ENABLED = "as_can_select_o2_products",
  MOBILE_RESIGN = "as_mobile_resign_enabled",
  MOBILE_RESELLER = "dws_reseller_enabled",
  MOBILE_BOLT_ON = "as_account_level_bolt_ons_enabled",
  WLRBB_RESIGN = "as_wlrbb_resign_enabled",
  QUOTE_GENERATION = "as_quote_generation_enabled"
}

export enum LocationTypeEnum {
  NEW_LINE = "New Line",
  LINE_TRANSFER = "Line Transfer",
  EXISTING_LINE = "Existing Line (Broadband Only)",
  FTTP = "New FTTP Provide",
  SOGEA = "New SoGEA Provide"
}
