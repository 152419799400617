import React from "react";
import Icon from "@material-ui/icons/Person";
import Section from "../../../../../../components/Shared/Section";
import SelectSiteVisitReason from "./SelectSiteVisitReason";

const SiteVisitReason = ({ targetConfigs }) => {
  return (
    <Section
      title="Site Visit Reason"
      avatar={<Icon />}
      data-cy="siteVisitReasonSection"
    >
      <SelectSiteVisitReason
        targetConfigs={targetConfigs}
        productType="broadband"
        propertyName="bb.site_visit_reason"
      />
    </Section>
  );
};

SiteVisitReason.propTypes = {};

export default SiteVisitReason;
