import React, { Component } from "react";
import { connect } from "react-redux";
import Section from "../../../../../components/Shared/Section";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import {
  addSpecialRateToRemove,
  deleteSpecialRateToRemove
} from "../../../../../store/wlrBroadband/actions";
import { NUMBER, TARIFF } from "../../../../../store/wlrBroadband/constants";
import { DC_DATE_FORMAT } from "../../../../../helpers/date";
import { parseISO, format, startOfMonth, addMonths } from "date-fns";
import Checkbox from "@material-ui/core/Checkbox";

class SpecialRates extends Component {
  hasSpecialRateToRemove = id => {
    return this.props.specialRatesToRemove.find(
      specialRate => specialRate.id === id
    );
  };

  setSpecialRateToRemove = (id, type) => {
    const startOfNextMonth = format(
      startOfMonth(addMonths(new Date(), 1)),
      DC_DATE_FORMAT
    );
    if (this.hasSpecialRateToRemove(id)) {
      this.props.deleteSpecialRateToRemove(
        id,
        type,
        this.props.resignStartDate || startOfNextMonth
      );
    } else {
      this.props.addSpecialRateToRemove(
        id,
        type,
        this.props.resignStartDate || startOfNextMonth
      );
    }
  };

  render() {
    const { productInstance } = this.props;
    const specials = productInstance && productInstance.specials;
    const tariffs = specials && specials.tariffs;
    const numbers = specials && specials.numbers;
    if (!tariffs && !numbers) return false;
    return (
      <Section title="Special Rates" avatar={<InsertChartIcon />}>
        <Table data-cy="wlrBroadbandSRTable">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>CLI</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell>Setup</TableCell>
              <TableCell>Remainder Setup</TableCell>
              <TableCell>Min Charge</TableCell>
              <TableCell>Charge Cap</TableCell>
              <TableCell>Initial Rating</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tariffs && (
              <>
                <TableRow>
                  <TableCell colSpan={12}>Special Charge Codes</TableCell>
                </TableRow>
                {Object.values(tariffs).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.ChargeCodeDestination}</TableCell>
                    <TableCell>{item.CLI}</TableCell>
                    <TableCell>{item.RateTypeName}</TableCell>
                    <TableCell>{item.Peak || "0.00"}</TableCell>
                    <TableCell>{item.SetupPeak || "0.00"}</TableCell>
                    <TableCell>{item.RemainderSetupPeak || "0.00"}</TableCell>
                    <TableCell>{item.PeakMinCharge || "0.00"}</TableCell>
                    <TableCell>{item.PeakMaxCharge || "0.00"}</TableCell>
                    <TableCell>
                      {item.InitialPeriodPeakRate || "0.00"}
                    </TableCell>
                    <TableCell>
                      {item.FromDate &&
                        format(parseISO(item.FromDate), DC_DATE_FORMAT)}
                    </TableCell>
                    <TableCell>
                      {item.ToDate &&
                        format(parseISO(item.ToDate), DC_DATE_FORMAT)}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        data-cy="wlrBroadbandSRTariffCheckbox"
                        onChange={() =>
                          this.setSpecialRateToRemove(item.Id, TARIFF)
                        }
                        checked={!!this.hasSpecialRateToRemove(item.Id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
            {numbers && (
              <>
                <TableRow>
                  <TableCell colSpan={12}>Special Numbers</TableCell>
                </TableRow>
                {Object.values(numbers).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.PIN}</TableCell>
                    <TableCell>{item.CLI}</TableCell>
                    <TableCell>{item.RateTypeName}</TableCell>
                    <TableCell>{item.Peak || "0.00"}</TableCell>
                    <TableCell>{item.SetupPeak || "0.00"}</TableCell>
                    <TableCell>{item.RemainderSetupPeak || "0.00"}</TableCell>
                    <TableCell>{item.PeakMinCharge || "0.00"}</TableCell>
                    <TableCell>{item.PeakMaxCharge || "0.00"}</TableCell>
                    <TableCell>
                      {item.InitialPeriodPeakRate || "0.00"}
                    </TableCell>
                    <TableCell>
                      {item.FromDate &&
                        format(parseISO(item.FromDate), DC_DATE_FORMAT)}
                    </TableCell>
                    <TableCell>
                      {item.ToDate &&
                        format(parseISO(item.ToDate), DC_DATE_FORMAT)}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        data-cy="wlrBroadbandSRNumberCheckbox"
                        onChange={() =>
                          this.setSpecialRateToRemove(item.Id, NUMBER)
                        }
                        checked={!!this.hasSpecialRateToRemove(item.Id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </Section>
    );
  }
}

const mapStateToProps = state => {
  return {
    resignStartDate: state.wlrBroadband.resignStartDate,
    specialRatesToRemove: state.wlrBroadband.specialRatesToRemove
  };
};

export default connect(mapStateToProps, {
  addSpecialRateToRemove,
  deleteSpecialRateToRemove
})(SpecialRates);
