import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Theme, MenuItem } from "@material-ui/core";
import { StatusChip } from "@akj-dev/design-system";
import {
  getAvailableRouters,
  updateEthernetConfiguration
} from "../../../../store/ethernetProducts/actionsCreators";
import Spinner from "../../../../components/Shared/Spinner";
import { ProductSelect } from "../../../shared/ProductSelect";
import { ReduxEthernetConfiguration } from "../types";

export interface EthernetConfigurationRouterSelectProps {
  configIndex: number;
  configuration: ReduxEthernetConfiguration;
}

export const EthernetConfigurationRouterSelect = ({
  configIndex,
  configuration
}: EthernetConfigurationRouterSelectProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const routersFetch = configuration?.availableRouters;
  const selectedRouterId = configuration.selectedRouterId;
  const backup_broadband = configuration.backup_broadband;

  const allRouters = routersFetch?.response?.results?.all;
  const routerOptions = useMemo(() => {
    return (allRouters ?? [])
      .slice()
      .sort((a, b) => {
        if (!a.prices) return 0;
        if (a.prices.recurring > b.prices.recurring) return 1;
        if (a.prices.recurring < b.prices.recurring) return -1;
        return 0;
      })
      .filter(
        router =>
          !backup_broadband || router.suitable_for_adsl_fttc_backup === "1"
      );
  }, [allRouters, backup_broadband]);

  const handleRouterChange = (routerId: string) =>
    dispatch(
      updateEthernetConfiguration(configIndex, "selectedRouterId", routerId)
    );

  useEffect(() => {
    dispatch(getAvailableRouters(configIndex));
  }, [dispatch, configIndex]);

  if (routersFetch.fetching) return <Spinner />;

  if (!routersFetch.response?.results)
    return (
      <StatusChip
        type="error"
        message="Problem fetching router options."
        retry={() => getAvailableRouters(configIndex)}
      />
    );

  return (
    <ProductSelect
      dataCy={"ethernet-router"}
      value={selectedRouterId}
      handleChange={handleRouterChange}
    >
      {routerOptions.map(router => (
        <MenuItem value={router.id} key={router.id}>
          <div>
            <b>{router.name}</b>
            <div className={classes.description}>{router.description}</div>
          </div>
        </MenuItem>
      ))}
    </ProductSelect>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    fontSize: "0.9em",
    color: theme.palette.text.disabled
  }
}));
