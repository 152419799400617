import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { requestAllAccountAddresses } from "../../store/account/actions";
import {
  selectAccountAddress,
  setAddressType,
  setNewAddressElement
} from "../../store/hardwareDelivery/actionCreators";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  Box
} from "@material-ui/core";
import { BILLING, EXISTING, NEW } from "../../store/hardwareDelivery/constants";
import AddressEntryFields from "../../components/AddressEntryFields";
import { IndexedCardInfo } from "../../screens/shared/indexed-card-info";
import BillingAddress from "./BillingAddress";

class HardwareDelivery extends React.Component {
  static propTypes = {};

  componentDidMount() {
    if (this.props.accountAddresses.response.status !== "success") {
      this.props.requestAllAccountAddresses();
    }
  }

  addressFields = [
    "building",
    "street",
    "locale",
    "post_town",
    "region",
    "postcode",
    "country"
  ];

  addressToString = address => {
    return _.compact(this.addressFields.map(e => address[e])).join(", ");
  };

  render() {
    const {
      accountAddresses,
      countries,
      addressType,
      setAddressType,
      newAddress,
      setNewAddressElement,
      selectAccountAddress,
      accountAddressIndex,
      cardIndex
    } = this.props;
    const addresses = _.get(accountAddresses.response, "data.addresses", []);
    return (
      <IndexedCardInfo index={cardIndex} title="Hardware Delivery">
        <FormControl fullWidth>
          <RadioGroup
            aria-label="address preference"
            name="address_preference"
            value={addressType}
            onChange={setAddressType}
          >
            <Box display="flex" my={1}>
              <FormControlLabel
                control={<Radio />}
                value={BILLING}
                label="Use billing address"
              />
              <FormControlLabel
                control={<Radio />}
                value={EXISTING}
                label="Choose from existing addresses"
              />
              <FormControlLabel
                control={<Radio />}
                value={NEW}
                label="Add new address"
              />
            </Box>
          </RadioGroup>
        </FormControl>
        {addressType === BILLING && <BillingAddress />}
        {addressType === EXISTING && (
          <FormControl fullWidth>
            <InputLabel>Choose existing address</InputLabel>
            <Select
              data-cy="ChooseExistingAddress"
              disabled={addresses.length < 1}
              value={accountAddressIndex}
              onChange={selectAccountAddress}
            >
              {addresses.map((address, i) => (
                <MenuItem value={i} key={i}>
                  {this.addressToString(address)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {addressType === NEW && (
          <AddressEntryFields
            updateField={setNewAddressElement}
            fields={this.addressFields}
            newAddress={newAddress}
            existingAddress={{ countries }}
            disabled={false}
          />
        )}
      </IndexedCardInfo>
    );
  }
}

const mapStateToProps = state => {
  return {
    billingAddressFetching: state.account.billingAddress.fetching,
    countries: _.get(state.account.billingAddress.response.data, "countries"),
    accountAddresses: state.account.accountAddresses,
    addressType: state.hardwareDelivery.addressType,
    accountAddressIndex: state.hardwareDelivery.accountAddressIndex,
    newAddress: state.hardwareDelivery.newAddress
  };
};

export default connect(mapStateToProps, {
  requestAllAccountAddresses,
  setAddressType,
  selectAccountAddress,
  setNewAddressElement
})(HardwareDelivery);
