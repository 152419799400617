import React, { Component } from "react";
import { connect } from "react-redux";
import { setField } from "../../store/order/actions";
import { Checkbox, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getAccountSettings } from "../../store/account/selectors";
import { IndexedCardInfo } from "../../screens/shared/indexed-card-info";
import { getHostEnv } from "../../store/order/selectors/getHostEnv";

class Order extends Component {
  state = {};

  setReceiveUpdatesField = (_, isInputChecked) =>
    this.props.setField("cc_sales_person", isInputChecked);

  setWelcomeEmailField = (_, isInputChecked) =>
    this.props.setField("suppress_welcome_email", !isInputChecked);

  render() {
    const { order, setField, accountSettings, cardIndex, hostEnv } = this.props;
    return (
      <IndexedCardInfo index={cardIndex} title="Order">
        <TextField
          label="Purchase order number"
          fullWidth
          onChange={event =>
            setField("customer_purchase_number", event.target.value)
          }
          value={order.customer_purchase_number || ""}
        />
        {accountSettings.as_can_set_order_updates === "1" && (
          <FormControlLabel
            label="I want to receive updates on this order"
            control={
              <Checkbox
                name="cc_sales_person"
                onChange={this.setReceiveUpdatesField}
                checked={order.cc_sales_person || false}
              />
            }
          />
        )}
        {accountSettings.dws_reseller_enabled !== "1" &&
          accountSettings.can_access_vf_direct !== "1" &&
          hostEnv !== "my_account" && (
            <>
              <FormControlLabel
                label="Send welcome email"
                control={
                  <Checkbox
                    onChange={this.setWelcomeEmailField}
                    checked={!order.suppress_welcome_email}
                    data-cy="sendWelcomeEmail"
                  />
                }
              />
            </>
          )}
      </IndexedCardInfo>
    );
  }
}

const mapStateToProps = state => {
  return {
    order: state.order,
    accountSettings: getAccountSettings(state),
    hostEnv: getHostEnv(state)
  };
};

export default connect(mapStateToProps, {
  setField
})(Order);
