import React from "react";
import PropTypes from "prop-types";
import DoneIcon from "@material-ui/icons/Done";

const CompletedOrderInfo = ({ configuration }) => {
  const green = "#5cb85c";

  return (
    <div className="CompletedOrderInfo">
      <h2 style={{ color: green }}>
        <DoneIcon color={green} /> Complete.
      </h2>
      Order ID: {configuration.purchaseResponse.order_id}
    </div>
  );
};

CompletedOrderInfo.propTypes = {
  configuration: PropTypes.object.isRequired
};

export default CompletedOrderInfo;
