import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEthernetConfiguration } from "../../../store/ethernetProducts/actionsCreators";
import { ReduxEthernetConfiguration } from "./types";
import { EthernetConfiguration } from "./ethernet-configuration/EthernetConfiguration";

export const ChooseEthernet = () => {
  const dispatch = useDispatch();
  const configs = useSelector<any, ReduxEthernetConfiguration[]>(
    state => state.ethernetProducts.configurations ?? []
  );

  const configsLength = configs.length;

  useEffect(() => {
    if (configsLength === 0) {
      dispatch(addEthernetConfiguration());
    }
  }, [configsLength, dispatch]);

  return (
    <div>
      {configs.map((config, i) => {
        return (
          <EthernetConfiguration
            configuration={config}
            configIndex={i}
            key={i}
          />
        );
      })}
    </div>
  );
};
