import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as GuidedSalesAPI from "../../api/v1/guidedSales";
import { FullScreenStatus } from "../../components/Shared/FullScreenStatus";
import { loadWizardState } from "../../store/root/actionCreators";
import { Base64Decode } from "../../helpers/base64";

interface LoadWizardStateProps {
  /* Wizard state will be loaded from DC if this param is set */
  savedOrderId?: string | null;
  children: ReactNode;
}
/**
 * Load Wizard State if order_id exists in URL
 * ....which means AS has been launched from an existing order with a saved draft.
 * order_id is always passed in wizard data, but only in query string when it's a previously saved order,
 * Confusing....
 * @param orderId
 * @param children
 * @constructor
 */
export const LoadWizardState = ({
  savedOrderId,
  children
}: LoadWizardStateProps) => {
  const [loading, setLoading] = useState(!!savedOrderId);
  const [error, setError] = useState<string | undefined>();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getWizardState(orderId: string) {
      const response = await GuidedSalesAPI.LoadWizardState(orderId);

      if (response.status === "success") {
        // @ts-ignore Value of type 'typeof Base64Decode' is not callable.
        dispatch(loadWizardState(JSON.parse(Base64Decode(response.content))));
      } else {
        console.log(response);
        if (
          response.message &&
          response.message.includes("Wizard state not found")
        ) {
          setError("Sorry, no saved quote found.");
        } else {
          setError(response.message);
        }
      }
      setLoading(false);
    }

    if (savedOrderId) {
      getWizardState(savedOrderId);
    }
  }, [savedOrderId, dispatch]);

  if (loading)
    return (
      <FullScreenStatus
        statusType="loading"
        message="Loading saved wizard state"
      />
    );

  if (error) return <FullScreenStatus statusType="error" message={error} />;

  return <>{children}</>;
};
