import React, { useState } from "react";
import { Button, FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import InputLabel from "@material-ui/core/InputLabel";
import FileDrop from "../../../../../../components/Shared/FileUpload/FileDrop";

interface TemplateUploadDownloadProps {
  chooseFile: (file: any) => void;
}

export const TemplateUploadDownload = ({
  chooseFile
}: TemplateUploadDownloadProps) => {
  const [type, setType] = useState("mobile");

  const changeType = (event: React.ChangeEvent<{ value: unknown }>) => {
    // TODO: The || is for tests. See https://github.com/callemall/material-ui/issues/5259. Must be a cleaner way.
    setType(event.target.value as string);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Order Type</InputLabel>
          <Select value={type} onChange={changeType}>
            <MenuItem value={"mobile"}>Mobile</MenuItem>
            {/*<MenuItem value={"broadband"}>Broadband</MenuItem>*/}
            {/*<MenuItem value={"wlr"}>Lines & Calls</MenuItem>*/}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          disabled={!type}
          endIcon={<CloudDownloadIcon />}
          href={`/cgi-bin/index.cgi/ExternalServices/v1/Orders/BulkOrderTemplate?order_type=${type}`}
          fullWidth={true}
        >
          Download Template
        </Button>
      </Grid>
      <Grid item md={6}>
        <FileDrop
          onDrop={chooseFile}
          message="Drag a completed CSV file here"
          maxSize={3e7}
          accept=".csv"
        />
      </Grid>
    </Grid>
  );
};
