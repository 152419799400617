import * as actionTypes from "./actionTypes";
import * as OrdersAPI from "../../api/v1/orders";

/**
 * Select a contact for the quote.
 * Note this is copied from the old basket code...
 * @param id
 * @returns {{contact: *, type: string}}
 */
export const setQuoteContact = id => ({
  type: actionTypes.SET_QUOTE_CONTACT,
  id
});

/**
 * Make a request to generate a quote.
 * @returns {Function}
 */
export const generateQuote = () => async (dispatch, getState) => {
  const {
    order,
    quote: { quoteContactId }
  } = getState();

  dispatch({ type: actionTypes.REQUEST_GENERATE_QUOTE });
  const response = await OrdersAPI.generateQuote(
    order.accountId,
    order.id,
    // We shouldn't send both lead and account ID as contact IDs are different
    // across leads and accounts according to @jon.taylor. See AllAccountContacts
    order.accountId ? null : order.leadId,
    quoteContactId
  );
  dispatch({ type: actionTypes.RECEIVE_GENERATE_QUOTE, response });
};

/**
 * Get rid of the quote ID that came back with any previous call.
 * ....hence clearing the UI so the user can generate another one.
 * @returns {object}
 */
export const resetQuote = () => ({
  type: actionTypes.RECEIVE_GENERATE_QUOTE,
  response: {}
});
