import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import InfoIcon from "@material-ui/icons/Info";
import { withTheme } from "@material-ui/core/styles";

class Popover extends Component {
  static propTypes = {
    title: PropTypes.string,
    position: PropTypes.string
  };

  state = {
    visible: false
  };

  show = () => this.setState(s => ({ visible: true }));
  hide = () => this.setState(s => ({ visible: false }));

  render() {
    const { title, position, children, theme } = this.props;
    return (
      <Outer>
        <InfoIcon
          onMouseEnter={this.show}
          onMouseLeave={this.hide}
          htmlColor={theme.palette.info.main}
          style={{ cursor: "pointer" }}
        />
        {this.state.visible && (
          <Pop position={position}>
            {title && <h4>{title}</h4>}
            {children}
          </Pop>
        )}
      </Outer>
    );
  }
}

const Outer = styled.div`
  position: relative;
  display: inline;
  margin-left: 10px;
`;

const Pop = styled.div`
  position: absolute;
  z-index: 100;
  top: -12px;
  ${p => {
    switch (p.position) {
      case "left":
        return `
            right:30px;
          `;
      default:
        return `
            left:30px;
          `;
    }
  }}
  width: 300px;
  background: ${p => p.theme.palette.info.main};
  padding: 10px;
  border-radius: 5px;
  color: white;
  box-shadow: 0 0 5px 3px white;
  h4 {
    margin-top: 0;
  }
  :after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    ${p => {
      switch (p.position) {
        case "left":
          return `
              right:-7px;
              transform: rotate(180deg); 
            `;
        default:
          return `
              left:-7px;
            `;
      }
    }}
    top: 10px;
    border-right-color: ${p => p.theme.palette.info.main};
    border-width: 7px 7px 7px 0;
  }
`;
export default withTheme(Popover);
