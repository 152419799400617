import * as actionTypes from "./actionTypes";

/**
 * Set the type of address to be used for ordering mobile / hardware products
 * See ./constants.js
 * @param value
 * @returns {{addressType: *, type: string}}
 */
export const setAddressType = (_, value) => ({
  type: actionTypes.SET_ADDRESS_TYPE,
  addressType: value
});

/**
 * Select existing account address for Hardware and Mobile orders
 * Sets the index to use in order selector later
 * @param event
 * @param child
 * @returns {{index: *, type: string}}
 */
export const selectAccountAddress = (event, child) => ({
  type: actionTypes.SELECT_ACCOUNT_ADDRESS,
  index: child.props.value
});

/**
 * Set new address element
 * @param element
 * @param value
 * @returns {{type: string, value: *, element: *[]}}
 */
export const setNewAddressElement = (element, value) => ({
  type: actionTypes.SET_NEW_ADDRESS_ELEMENT,
  element,
  value
});
