import styled from "styled-components";
import Paper from "@material-ui/core/Paper";

const TierBase = styled(Paper)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 10px;
  .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  }
  .price {
    color: ${p => p.theme.palette.success.main};
    margin-left: auto;
  }
  .description {
  color: ${p => p.theme.palette.text.disabled};
  padding-bottom: 10px;
  padding-top: 10px;
  }
  .action {
  align-items: center;
  display: flex;
  flex-direction: row;
  }
  .quantity {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  width: 25px
`;

export default TierBase;
