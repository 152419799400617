import React, { Component } from "react";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { FormHelperText, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FieldContainer from "../../WlrBBDynamicField/FieldContainer";
import {
  getDynamicPropertyValue,
  makeGetDynamicPropertyInstances
} from "../../../../../../store/wlrBroadband/selectors";
import {
  doRemoteValidation,
  getProductData,
  setProductProperty,
  validateProductProperty,
  getBroadbandAppointments
} from "../../../../../../store/wlrBroadband/actions";
import _ from "lodash";
import {
  NEW_FTTP,
  NEW_SOGEA
} from "../../../../../../store/wlrBroadband/constants";

class SelectSiteVisitReason extends Component {
  render() {
    const {
      targetConfigs,
      productType,
      propertyName,
      propertyInstances,
      propertyValidation,
      propertyValue,
      setProductProperty,
      getProductData,
      doRemoteValidation,
      validateProductProperty,
      getBroadbandAppointments,
      location,
      isFTTP,
      isSOGEA
    } = this.props;

    const property = propertyInstances[0];

    // Don't display the select if property doesn't exist.
    if (!property) return false;

    const availableOptions = property.available_options;
    const availableOptionMap = property.available_option_map;
    // TP21765: Is there an existing ONT (Optical Network Termination). We use
    // this to determine what SVR (Site Visit Reasons) options to show for FTTP.
    const isExistingONT =
      _.get(location.broadbandSearch.response, "ont_details.ont_reference") &&
      _.get(
        location.broadbandSearch.response,
        "ont_details.ont_spare_data_ports",
        0
      ) > 0;
    const isMDUBuildComplete =
      _.get(
        location.broadbandSearch.response,
        "ont_details.mdu_build_complete",
        "0"
      ) === "1";
    const menuItems = availableOptions
      .filter(option => {
        // TP21765: If this is not an existing ONT and mdu build is not
        // complete, then filter out the NO_SITE_VISIT option for FTTP.
        if (
          isFTTP &&
          !isExistingONT &&
          !isMDUBuildComplete &&
          option === "NO_SITE_VISIT"
        )
          return false;
        // TP12277: If a new connection for SOGEA, always filter out the
        // NO_SITE_VISIT option
        if (isSOGEA && option === "NO_SITE_VISIT") return false;
        // Premium currently unavailable due to covid (as of 01/03/2021).
        if (option === "PREMIUM") return false;
        return true;
      })
      .map(option => {
        return {
          value: option,
          label: availableOptionMap[option]
        };
      });
    return (
      <FieldContainer>
        <FormControl fullWidth error={propertyValidation} margin="normal">
          <InputLabel>{`${property.label}${
            property.is_required ? " *" : ""
          }`}</InputLabel>
          <Select
            value={propertyValue || ""}
            onChange={event => {
              setProductProperty(
                targetConfigs,
                productType,
                propertyName,
                event.target.value
              );
              targetConfigs.forEach(config => {
                getProductData(config, productType);
              });
              validateProductProperty(
                targetConfigs,
                productType,
                propertyName,
                property
              );
              targetConfigs.forEach(config => {
                doRemoteValidation(config);
              });
              getBroadbandAppointments(targetConfigs);
            }}
          >
            {menuItems.map(menuItem => {
              return (
                <MenuItem key={menuItem.value} value={menuItem.value}>
                  {menuItem.label}
                </MenuItem>
              );
            })}
          </Select>
          {propertyValidation && (
            <FormHelperText>{propertyValidation}</FormHelperText>
          )}
        </FormControl>
      </FieldContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const getDynamicPropertyInstances = makeGetDynamicPropertyInstances();
  const configuration =
    state.wlrBroadband.configurations[ownProps.targetConfigs[0]];
  const location = state.wlrBroadband.locations[configuration.locationIndex];
  return {
    propertyInstances: getDynamicPropertyInstances(state, ownProps),
    propertyValidation: configuration.validation[ownProps.propertyName],
    propertyValue: getDynamicPropertyValue(
      state,
      ownProps.productType,
      ownProps.targetConfigs[0],
      ownProps.propertyName
    ),
    location,
    isFTTP: location.type === NEW_FTTP,
    isSOGEA: location.type === NEW_SOGEA
  };
};

export default connect(mapStateToProps, {
  setProductProperty,
  getProductData,
  doRemoteValidation,
  validateProductProperty,
  getBroadbandAppointments
})(SelectSiteVisitReason);
