import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, TextField } from "@material-ui/core";
import { validatePostcode } from "../../../helpers/validation";
import { getAccountId } from "../../../store/order/selectors";
import { ChooseAddressDialog } from "../../../components/Shared/AddressPicker/ChooseAddressDialog";

export interface AddressPickerProps {
  saveAddress: (address: any) => void;
  saveUPRN?: (uprn: any) => void;
  includeUPRN?: boolean;
}

export const AddressPicker = ({
  saveAddress,
  saveUPRN,
  includeUPRN
}: AddressPickerProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [postcode, setPostcode] = useState("");

  const account = useSelector(getAccountId);

  const handlePostcode = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPostcode(e.target.value);
  };

  const openDialog = () => {
    setDialogOpen(true);
    // setUprnSelect(false);
    // setFilter("");
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const getAddresses = () => {
    openDialog();
    // setAddresses(false);
    // setFetching(true);
    // setError("");
  };

  return (
    <>
      <Box mr={2} display={"flex"} data-cy="AddressPicker">
        <TextField
          variant={"outlined"}
          label="Postcode"
          name="postcode"
          style={{ width: 140, marginRight: 10 }}
          onChange={handlePostcode}
          onKeyDown={e => e.key === "Enter" && getAddresses()}
          value={postcode}
          helperText={
            postcode &&
            !validatePostcode(postcode) &&
            "Must be a valid postcode"
          }
          error={!!(postcode && !validatePostcode(postcode))}
        />
        <Button
          style={{ height: 56 }}
          variant="outlined"
          color="primary"
          onClick={getAddresses}
          data-cy="AddressPickerButton"
          disabled={!validatePostcode(postcode)}
        >
          Choose Address
        </Button>
      </Box>
      <ChooseAddressDialog
        open={dialogOpen}
        onClose={closeDialog}
        postcode={postcode}
        includeUPRN={includeUPRN}
        setAddress={saveAddress}
        setUPRN={uprn => saveUPRN?.(uprn.uprn)}
        accountId={account}
      />
    </>
  );
};
