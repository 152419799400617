import * as actions from "./actionTypes";
import * as OrdersAPI from "../../api/v1/orders";
import { addAlertMessage } from "../uiState/actions";

/**
 * Select contact ID to send the contract to
 * @param id
 * @returns {{id: *, type: string}}
 */
export const selectContactForContract = id => ({
  type: actions.SELECT_CONTACT_FOR_CONTRACT,
  id
});

/**
 * Generate contract for the current order
 * @returns {function(...[*]=)}
 */
export const generateContract = () => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.GENERATING_CONTRACT });

    // Get the order from the state
    const order = getState().order;

    // Get the contact from the state
    const contactId = getState().generateContract.contactId;

    const response = await OrdersAPI.createAndSendEsignContract(
      order.accountId,
      order.id,
      contactId
    );

    if (response.status === "success") {
      dispatch({ type: actions.CONTRACT_GENERATED, contract: response.data }); // TODO: contract unused
    } else {
      dispatch({ type: actions.GENERATING_CONTRACT_FAILED });
      dispatch(addAlertMessage(response.message));
    }
  };
};
