import _ from "lodash";
import * as actions from "./actionTypes";
import * as AccountAPI from "../../api/v1/account";
import * as OrdersAPI from "../../api/v1/orders";
import * as LeadsAPI from "../../api/v1/leads";
import { addAlertMessage } from "../uiState/actions";
import { setAccountId as setAccountIdOnOrderState } from "../order/actions";
import {
  getAccountSettings,
  getIsVfDirectConsumerAccount,
  shouldCollectCompanyAddress2,
  shouldCollectCompanyAddress3
} from "./selectors";
import { getAccountId } from "../order/selectors";
import {
  mapBroadbandToAccountProperty,
  mapBroadbandToAccountSalutation
} from "../../helpers/account/mapBroadbandToAccountProperty";

/**
 * Request account settings
 * Things like which product sections to show & hide
 *
 * @returns {{type: string}}
 */
export const requestAccountSettings = () => ({
  type: actions.REQUEST_ACCOUNT_SETTINGS
});

/**
 * Receive account settings.
 * This could either be via
 *  - direct call oin standalone / dc
 *  - via provider in platform
 * @param response
 * @returns {{response: *, type: string}}
 */
export const receiveAccountSettings = response => ({
  type: actions.RECEIVE_ACCOUNT_SETTINGS,
  response
});

export const availableHardwareCredit = () => {
  return async (dispatch, getState) => {
    const accountId = getAccountId(getState());

    if (accountId) {
      let credit = 0;
      dispatch({ type: actions.REQUEST_AVAILABLE_HARDWARE_CREDIT, credit });
      try {
        const response = await AccountAPI.AccountHardwareCredit(accountId);
        credit = response?.data?.amount ?? 0;
      } catch (error) {
        dispatch(addAlertMessage(error));
      }
      dispatch({ type: actions.RECEIVE_AVAILABLE_HARDWARE_CREDIT, credit });
    }
  };
};

/**
 * Create a new account
 * Either with v1/Orders/CreateAccount for the leads flow
 * or v1/Account/Create for the MIC head account / sub account flow
 *
 * Some special params discussed with @ianc:
 * contract_type_id comes from evo ContractType table:
 * SQL> select * from ContractType;
 +------------+---------------------------------------------------+
 | ID         | Description                                       |
 +------------+---------------------------------------------------+
 | 1          | Signed                                            |
 | 2          | Re-sign (Signed)                                  |
 | 3          | Verbal                                            |
 | 4          | Re-sign (Verbal)                                  |
 | 5          | Refused                                           |
 | 6          | Affiliate e-sign                                  |
 | 7          | Web e-sign                                        |
 +------------+---------------------------------------------------+
 *
 * @returns {Function}
 */
export const createAccount = () => async (dispatch, getState) => {
  // Existing user account settings from DC
  const settings = getAccountSettings(getState());
  dispatch({ type: actions.REQUEST_CREATE_ACCOUNT });

  // Strip hyphens on the sort code if they exist.
  // See: https://auroratarget.tpondemand.com/entity/8116-as-allow-xx-xx-xx-or.
  const bank_account_sortcode = getState().account.newAccountFields.bank_account_sortcode.replace(
    /-/g,
    ""
  );

  let response;
  const state = getState();
  if (state.order.leadId) {
    response = await OrdersAPI.CreateAccount({
      ...state.account.newAccountFields,
      order: state.order.id,
      lead: state.order.leadId,
      contract_type_id: settings.contract_type_id,
      bank_account_sortcode
    });
  } else {
    response = await AccountAPI.Create({
      ...getState().account.newAccountFields,
      mic_account: 1,
      contract_type_id: settings.contract_type_id,
      sales_person_id: settings.sales_person_id,
      dbam_name: settings.dbam_name,
      external_account_manager_name: settings.external_account_manager_name,
      assigned_user_name: settings.assigned_user_name,
      bank_account_sortcode
    });
  }
  dispatch({ type: actions.RECEIVE_CREATE_ACCOUNT, response });
  if (response.status === "success") {
    // TODO: Move account ID to the account reducer, so the below can happen there.
    dispatch(
      setAccountIdOnOrderState(
        _.get(response, "data.id") || _.get(response, "data.account.id")
      )
    );

    // Now refresh the list of contacts in state.
    // We have a new account that will have new company / billing addresses, set as part of the call above.
    dispatch(requestAllContacts(true));
  }
};

/**
 * Request contact types available for AddContact calls
 * @returns {{type: string}}
 */
export const requestContactTypes = () => ({
  type: actions.REQUEST_CONTACT_TYPES
});

/**
 * Add a contact to an account.
 * If it's a newly created account, add it there. If not, to the head account.
 * Also used to create contacts on leads for quote generation
 *
 * @param values
 * @param lead {boolean} - whether to crete the contact on a lead
 * @returns {Function}
 */
export const addContact = (values, lead = false) => async (
  dispatch,
  getState
) => {
  dispatch({ type: actions.REQUEST_ADD_CONTACT });
  let response;
  const state = getState();
  const contact = {
    title: values.title.value,
    authority: values.authority.value,
    first_name: values.first_name.value,
    last_name: values.last_name.value,
    primary_email_address: values.primary_email_address.value,
    mobile_phone_number: values.mobile_phone_number.value,
    order_id: state.order.id
  };
  if (lead) {
    response = await LeadsAPI.AddContact(state.order.leadId, contact);
  } else {
    response = await AccountAPI.AddContact(
      state.account.newAccountId || state.order.accountId,
      contact
    );
  }
  dispatch({ type: actions.RECEIVE_ADD_CONTACT, response });

  // If a new contact was successfully created, return it's ID so it can be selected
  return _.get(response, "data.id");
};

/**
 * Fetch the billing address for an account (saga)
 * @returns {{type: string}}
 */
export const requestBillingAddress = () => ({
  type: actions.REQUEST_BILLING_ADDRESS
});

/**
 * Update an account's billing address (saga)
 * @param address
 * @returns {{address: *, type: string}}
 */
export const requestUpdateBillingAddress = address => ({
  type: actions.REQUEST_UPDATE_BILLING_ADDRESS,
  address
});

/**
 * Fetch all postal addresses associated with an account (saga)
 * @returns {{type: string}}
 */
export const requestAllAccountAddresses = () => ({
  type: actions.REQUEST_ALL_ACCOUNT_ADDRESSES
});

/**
 * Fetch all contacts on the current account or lead
 * @param force {boolean}
 * @param isLeadContactSelect {boolean}
 * @returns {{type: string}}
 */
export const requestAllContacts = (
  force = false,
  isLeadContactSelect = false
) => {
  return {
    type: actions.REQUEST_ALL_CONTACTS,
    force,
    isLeadContactSelect
  };
};

/**
 * Set a field value on new account sign-up form
 * @param fieldId
 * @param value
 * @returns {{type: *, value: *, fieldId: *}}
 */
export const setNewAccountField = (fieldId, value) => (dispatch, getState) => {
  dispatch({
    type: actions.SET_NEW_ACCOUNT_FIELD,
    fieldId,
    value
  });

  const state = getState();
  // handle cases where user removes the conditions that require us to capture additional details
  if (getAccountSettings(state).can_access_vf_direct === "1") {
    const companyDetails2FieldIds = [
      "company_postcode2",
      "company_months_at_address2",
      "company_building2",
      "company_street2",
      "company_post_town2",
      "company_locale2",
      "company_region2",
      "company_country2"
    ];

    const companyDetails3FieldsIds = [
      "company_postcode3",
      "company_months_at_address3",
      "company_building3",
      "company_street3",
      "company_post_town3",
      "company_locale3",
      "company_region3",
      "company_country3"
    ];

    if (
      fieldId === "business_entity_type" &&
      !getIsVfDirectConsumerAccount(state)
    ) {
      [
        "residential_status",
        "company_months_at_address",
        ...companyDetails2FieldIds,
        ...companyDetails3FieldsIds
      ].forEach(fieldId => dispatch(deleteNewAccountField(fieldId)));
    }

    if (
      fieldId === "company_months_at_address" &&
      !shouldCollectCompanyAddress2(state)
    ) {
      [
        ...companyDetails2FieldIds,
        ...companyDetails3FieldsIds
      ].forEach(fieldId => dispatch(deleteNewAccountField(fieldId)));
    }

    if (
      fieldId === "company_months_at_address2" &&
      !shouldCollectCompanyAddress3(state)
    ) {
      [...companyDetails3FieldsIds].forEach(fieldId =>
        dispatch(deleteNewAccountField(fieldId))
      );
    }
  }
};

/**
 * Delete field value on new account sign-up form
 * @param fieldId
 * @returns {{type: *, fieldId: *}}
 */

const deleteNewAccountField = fieldId => ({
  type: actions.DELETE_NEW_ACCOUNT_FIELD,
  fieldId
});

/**
 * Validate a field value on new account sign-up form
 * @param fieldId
 * @returns {{type: *, fieldId: *}}
 */
export const validateNewAccountField = fieldId => ({
  type: actions.VALIDATE_NEW_ACCOUNT_FIELD,
  fieldId
});

/**
 * Validate al field values on new account sign-up form
 * @returns {{type: *, fieldId: *}}
 */
export const validateAllNewAccountFields = () => ({
  type: actions.VALIDATE_ALL_NEW_ACCOUNT_FIELDS
});

/**
 * Autofill new account fields with defaults conditionally and/or from other state.
 * @returns {Function}
 */
export const setNewAccountFields = () => {
  return async (dispatch, getState) => {
    // If broadband has been added, use those properties instead.
    const properties =
      getState().wlrBroadband.configurations.length > 0
        ? _.isEmpty(
            getState().wlrBroadband.configurations[0].broadbandProperties
          )
          ? getState().wlrBroadband.configurations[0].wlrProperties
          : getState().wlrBroadband.configurations[0].broadbandProperties
        : {};
    let newAccountFields = {};
    Object.entries(properties).forEach(([key, value]) => {
      newAccountFields[mapBroadbandToAccountProperty(key)] =
        key === "bb.contact.title"
          ? mapBroadbandToAccountSalutation(value)
          : value;
    });
    // Only set head_account_id if it's specified to do so in account settings (Love Energy)
    // or AS is launched standalone (MIC).
    // This should not passed as param when creating account for lead.
    if (
      getAccountSettings(getState()).as_create_new_account_as_sub_account ===
      "1"
    ) {
      newAccountFields.head_account_id = getAccountId(getState());
    }

    if (getAccountSettings(getState()).can_access_vf_direct === "1") {
      newAccountFields.ok_to_post = "0";
      newAccountFields.ok_to_mms = "0";
      newAccountFields.ok_to_sms = "0";
    }

    dispatch({ type: actions.SET_NEW_ACCOUNT_FIELDS, newAccountFields });
  };
};

/**
 * Auto-populate new account fields from those on a lead contact
 * @param contactIndex
 * @returns {{contactId: *, type: string}}
 */
export const setNewAccountFieldsFromContact = contactIndex => ({
  type: actions.SET_NEW_ACCOUNT_FIELDS_FROM_CONTACT,
  contactIndex
});

/**
 * Reset the account state to initial values
 * This is used by the plaform to allow for multiple wizard completions in same session
 * @returns {{ type: string }}
 */
export const resetAccountState = () => ({
  type: actions.RESET_ACCOUNT_STATE
});

/**
 * User has requested the latest credit vet status for an account
 * @returns {{type: string}}
 */
export const requestCreditVet = () => ({
  type: actions.REQUEST_CREDIT_VET
});

/**
 * An error occured in the credit vet process
 * @returns {{type: string}}
 */
export const setCreditVetError = () => ({
  type: actions.SET_CREDIT_VET_ERROR
});

/**
 * Set the response from a successful credit vet call
 * @param {any}
 * @returns {{type: string, payload: any}}
 */
export const setCreditVetSuccess = payload => ({
  type: actions.SET_CREDIT_VET_SUCCESS_RESPONSE,
  payload
});
