import { cloneDeep } from "lodash";
import {
  FETCH_SHIPPING_METHODS,
  RECEIVE_SHIPPING_METHODS,
  RECEIVE_UPDATING_SHIPPING_METHOD_IN_API,
  UPDATING_SHIPPING_METHOD_IN_STATE
} from "../../helpers/constants";

const initialState = {
  chosenProductId: "",
  products: [],
  isUpdatingShippingInApi: false,
  isFetchingShipping: false,
  response: {}
};

export default (state = initialState, action) => {
  let newState = cloneDeep(state);
  switch (action.type) {
    case FETCH_SHIPPING_METHODS:
      newState.isFetchingShipping = true;
      return newState;
    case RECEIVE_SHIPPING_METHODS:
      newState.products = action.products;
      newState.chosenProductId = 0; // Standard - Free. TODO: sort this reducer out. This should be in initial state.
      newState.isFetchingShipping = false;
      return newState;
    case UPDATING_SHIPPING_METHOD_IN_STATE:
      newState.chosenProductId = action.id;
      return newState;
    case RECEIVE_UPDATING_SHIPPING_METHOD_IN_API:
      newState.isUpdatingShippingInApi = false;
      newState.response = action.response;
      return newState;
    default:
      return state;
  }
};
