import React from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import { ChartData } from "../types";
import { ChartTooltip } from "./ChartTooltip";

export interface ChartProps {
  fetching: boolean;
  data: ChartData[];
}

export const Chart = ({ fetching, data }: ChartProps) => {
  const { loader } = useStyles();

  return (
    <Box width="100%" position="relative" mb={2}>
      {fetching && (
        <div className={loader}>
          <CircularProgress />
        </div>
      )}
      <ResponsiveContainer height={350} width={"100%"}>
        <AreaChart
          data={data}
          margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
        >
          <Area
            type="monotone"
            dot={{ fill: "#7A5AFF" }}
            dataKey="count"
            stroke="#7A5AFF"
            fill="#7A5AFF0F"
          />
          <XAxis dataKey="label" />
          <YAxis allowDecimals={false} />
          <Tooltip content={ChartTooltip} />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

const useStyles = makeStyles({
  loader: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
