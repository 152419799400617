import React, { Component } from "react";
import PropTypes from "prop-types";

import { TargetConfigsContext } from "../../../context/TargetConfigsContext";

import FieldDataWrapper from "./FieldDataWrapper";

class DynamicFieldContextWrapper extends Component {
  static propTypes = {
    productType: PropTypes.oneOf(["wlr", "broadband"]),
    propertyName: PropTypes.string,
    isRootProperty: PropTypes.bool,
    disabled: PropTypes.bool
  };

  // Context can't be accessed from Redux bindings directly, hence this wrapper.
  // We need context so we don't have to declare `targetConfigs` for every field.
  static contextType = TargetConfigsContext;

  // FieldDataWrapper is where most stuff happens:
  render() {
    return (
      <FieldDataWrapper
        {...this.props}
        targetConfigs={this.context.targetConfigs}
      />
    );
  }
}

export default DynamicFieldContextWrapper;
