import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Menu, MenuItem, makeStyles, Theme } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { toggleOrderStatus } from "../../../../../store/uiState/actions";
import {
  STEP_CHOOSE,
  STEP_SUMMARY
} from "../../../../../store/uiState/constants";
import { open } from "../../../../../store/supportCase/actions";
import { getAccountSettings } from "../../../../../store/account/selectors";
import {
  getAccountId,
  getLeadId,
  getOrderId,
  getOrderProductCallsFetching,
  getUploadedContractId
} from "../../../../../store/order/selectors";
import { getHostEnv } from "../../../../../store/order/selectors/getHostEnv";
import GenerateQuote from "../../../../../containers/Shared/Toolbar/GenerateQuote";
import { ReduxAccountSettingsEnum, SaleStepEnum } from "../../../../enums";
import { SupportCase } from "./SupportCase";
import { BulkOrderUpload } from "./BulkOrderUpload";
import { RenameDraft } from "./RenameDraft";
import { DeleteDraftModal } from "../../../../../containers/Shared/DeleteDraftModal";
import { clearState } from "../../../../../store/root/actionCreators";
import { getPendingContractId } from "../../../../../store/generateContract/selectors";

export interface OptionsProps {
  saveDraft: (newName?: string) => void;
}

export const Options = ({ saveDraft }: OptionsProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [bulkOrderOpen, setBulkOrderOpen] = useState(false);
  const [deleteDraftOpen, setDeleteDraftOpen] = useState(false);
  const [renameDraftOpen, setRenameDraftOpen] = useState(false);
  const [generateQuoteOpen, setGenerateQuoteOpen] = useState(false);

  const dispatch = useDispatch();
  const activeStep = useSelector<any, SaleStepEnum>(
    state => state.uiState.activeStep
  );
  const accountSettings = useSelector<
    any,
    { [k in ReduxAccountSettingsEnum]: "0" | "1" }
  >(getAccountSettings);
  const orderProductCallsFetching = useSelector(getOrderProductCallsFetching);
  const orderStatusOpen = useSelector<any, boolean>(
    state => state.uiState.orderStatusOpen
  );
  const accountId = useSelector(getAccountId);
  const leadId = useSelector(getLeadId);
  const orderId = useSelector(getOrderId);
  const uploadedContractId = useSelector(getUploadedContractId);
  const pendingContractId = useSelector(getPendingContractId);

  const hostEnv = useSelector(getHostEnv);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenSupportCaseDialog = () => {
    dispatch(open());
    handleCloseMenu();
  };

  const handleOpenBulkOrder = () => {
    setBulkOrderOpen(true);
    handleCloseMenu();
  };
  const handleCloseBulkOrder = () => {
    setBulkOrderOpen(false);
  };
  const handleCloseRenameDraft = () => {
    setRenameDraftOpen(false);
  };
  const handleCloseGenerateQuote = () => {
    setGenerateQuoteOpen(false);
  };

  const handleOrderStatus = () => {
    dispatch(toggleOrderStatus());
  };
  const handleSaveDraft = () => {
    saveDraft();
    handleCloseMenu();
  };
  // const handleRenameDraft = () => {
  //   setRenameDraftOpen(true);
  //   handleClose();
  // };
  const handleGenerateQuote = () => {
    setGenerateQuoteOpen(true);
    handleCloseMenu();
  };

  const handleOpenDeleteDraft = () => {
    setDeleteDraftOpen(true);
    handleCloseMenu();
  };

  const quoteDisabled =
    activeStep === STEP_CHOOSE ||
    activeStep === STEP_SUMMARY ||
    accountSettings.as_quote_generation_enabled === "0" ||
    orderProductCallsFetching;

  const showDeleteOption = !uploadedContractId && !pendingContractId;

  const showDcOption = hostEnv === "dc";

  return (
    <>
      <Button
        data-cy={"ToolbarOptions"}
        aria-haspopup="true"
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleOpenMenu}
      >
        <div className={classes.text}>Options</div>
        <div className={classes.icon}>
          {!!anchorEl ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </div>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={!!anchorEl}
        onClose={handleCloseMenu}
      >
        {showDcOption && (
          <MenuItem
            data-cy={"ToolbarRaiseSupportCase"}
            className={classes.menuItem}
            onClick={handleOpenSupportCaseDialog}
          >
            Raise Support Case
          </MenuItem>
        )}
        {showDcOption && (
          <MenuItem
            data-cy={"ToolbarBulkUpload"}
            className={classes.menuItem}
            onClick={handleOpenBulkOrder}
          >
            Bulk Upload
          </MenuItem>
        )}
        {showDcOption && !!accountId && (
          <MenuItem
            data-cy={"ToolbarOrderStatus"}
            className={classes.menuItem}
            onClick={handleOrderStatus}
          >
            {`${orderStatusOpen ? "Hide" : "Show"} Order Status`}
          </MenuItem>
        )}
        <MenuItem
          data-cy={"ToolbarSaveDraft"}
          className={classes.menuItem}
          onClick={handleSaveDraft}
        >
          Save Draft
        </MenuItem>
        {/*<MenuItem className={classes.menuItem} onClick={handleRenameDraft}>*/}
        {/*  Rename Draft*/}
        {/*</MenuItem>*/}
        <MenuItem
          data-cy={"ToolbarSendQuote"}
          className={classes.menuItem}
          disabled={quoteDisabled}
          onClick={quoteDisabled ? undefined : handleGenerateQuote}
        >
          Send Quote
        </MenuItem>
        {showDeleteOption && (
          <MenuItem
            data-cy={"ToolbarDeleteDraft"}
            className={classes.menuItem}
            onClick={handleOpenDeleteDraft}
          >
            <span className={classes.dangerText}>Delete Draft</span>
          </MenuItem>
        )}
      </Menu>
      <SupportCase />
      <BulkOrderUpload
        open={bulkOrderOpen}
        handleClose={handleCloseBulkOrder}
      />
      <RenameDraft
        open={renameDraftOpen}
        handleRename={saveDraft}
        handleClose={handleCloseRenameDraft}
      />
      <GenerateQuote
        open={generateQuoteOpen}
        handleClose={handleCloseGenerateQuote}
      />
      <DeleteDraftModal
        open={deleteDraftOpen}
        onClose={reason => {
          setDeleteDraftOpen(false);
          if (reason === "success") {
            dispatch(clearState()); // clear wizard state, returns user to "New Sale" screen
          }
        }}
        leadId={!accountId && leadId}
        accountId={accountId}
        orderId={orderId}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0
  },
  text: {
    fontWeight: "bold",
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
    borderRight: `1px solid ${theme.palette.primary.dark}`
  },
  dangerText: {
    color: theme.palette.error.main
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `0 ${theme.spacing(0.5)}px`
  },
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100]
    }
  }
}));
