import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import CliBoltOns from "./sections/CliBoltOns/index";
import AirtimeCredit from "./sections/AirtimeCredit";
import SimCard from "./sections/SimCard";
import { Connection } from "./sections/Connection/";
import { AdvancedConfiguration } from "./sections/AdvancedConfiguration";
import Bars from "./sections/Bars";
import APNs from "./sections/APNs";
import CostCentres from "./sections/CostCentres";
import ConfigTableRow from "../MobileConfigurationTable/ConfigTableRow";
import { ConfigTableOuter } from "../MobileConfigurationTable/ConfigTableOuter";
import MobilePricingWrapper from "./sections/MobilePricingWrapper";
import Paper from "@material-ui/core/Paper";
import BillLimits from "./sections/BillLimits";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Grid } from "@material-ui/core";
import Services from "./sections/Services";
import { getIsDynamicProduct } from "../../../../store/mobile/selectors/pricing";
import { getAccountSettings } from "../../../../store/account/selectors";
import UserDefinedFields from "./sections/UserDefinedFields";
import { StatusChip } from "@akj-dev/design-system";
import { getValidationErrors } from "../../../../store/mobile/selectors/productConfig";
import { TargetConfigsContext } from "../../context/TargetConfigsContext";
import withContextToProps from "../../../../helpers/withContextToProps";

class ConfigurationForm extends Component {
  static propTypes = {
    targetConfigs: PropTypes.arrayOf(PropTypes.number).isRequired,
    setTargetConfigs: PropTypes.func.isRequired
  };
  closeEditConfig = () => {
    const { setTargetConfigs } = this.props;
    setTargetConfigs([]);
  };

  render() {
    const {
      hasCliProducts, // Store
      targetConfigs,
      isDynamicProduct,
      settings,
      productData,
      firstConfigProductId,
      validationErrors
    } = this.props;

    return (
      <div className="ConfigurationForm">
        <Paper style={{ marginBottom: 20 }}>
          <h3>
            <IconButton
              data-cy="configBackButton"
              onClick={this.closeEditConfig}
            >
              <ArrowBackIcon />
            </IconButton>
            <span style={{ verticalAlign: "super" }}>
              {`Editing ${targetConfigs.length} Product Configuration${
                targetConfigs.length > 1 ? "s" : ""
              }`}
            </span>
          </h3>
          <ConfigTableOuter>
            {targetConfigs.map(i => (
              <ConfigTableRow configIndex={i} key={i} />
            ))}
          </ConfigTableOuter>
        </Paper>
        {/*<ProductNames configs={configs} targetConfigs={targetConfigs} />*/}
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Connection targetConfigs={targetConfigs} />
            <SimCard targetConfigs={targetConfigs} />
            {// Show CLI bolt-ons and were not bulk editing.
            targetConfigs.length < 2 && hasCliProducts && (
              <CliBoltOns
                mode={settings.can_access_vf_direct === "1" ? "slot" : "type"}
              />
            )}
            {settings.as_can_add_bill_options === "1" && (
              <BillLimits targetConfigs={targetConfigs} />
            )}
          </Grid>

          <Grid item md={6}>
            {settings.as_airtime_credits_enabled === "1" && <AirtimeCredit />}
            {isDynamicProduct && (
              <MobilePricingWrapper targetConfigs={targetConfigs} />
            )}
            <Bars targetConfigs={targetConfigs} />
            {settings.as_services === "1" && <Services />}
            {settings.as_APN === "1" && <APNs targetConfigs={targetConfigs} />}
            {settings.as_cost_centre_enabled === "1" && <CostCentres /> // TP22408
            }
            {settings.as_user_definable_fields_enabled === "1" && (
              <UserDefinedFields
                dynamicProperties={
                  productData[firstConfigProductId].response.dynamic_properties
                }
              />
            )}
            <AdvancedConfiguration />
          </Grid>
        </Grid>
        {validationErrors.length > 0 && (
          <StatusChip
            type="error"
            title="This configuration has errors."
            message={
              <div>
                <ul>
                  {validationErrors.map(error => {
                    const [name, message] = [error[0], error[1]];
                    if (message)
                      return <li key={name}>{`${name}: ${message}`}</li>;
                    return false;
                  })}
                </ul>
              </div>
            }
          />
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={this.closeEditConfig}
        >
          Back
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const firstConfigProductId =
    state.mobile.configs[ownProps.targetConfigs[0]].productId;
  return {
    isDynamicProduct: getIsDynamicProduct(state, firstConfigProductId),
    hasCliProducts:
      _.get(
        state.mobile.cliBoltOnSearch,
        [firstConfigProductId, "response", "total"],
        0
      ) > 0,
    cliProducts: state.mobile.cliBoltOnSearch,
    settings: getAccountSettings(state),
    productData: state.mobile.productData,
    firstConfigProductId,
    validationErrors: getValidationErrors(ownProps.targetConfigs[0], state)
  };
};

export default withContextToProps(
  connect(mapStateToProps)(ConfigurationForm),
  TargetConfigsContext
);
