import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";

export interface PriceCardProps {
  fetching: boolean;
  price: string;
  title: string;
}
export const PriceCard = ({ title, price, fetching }: PriceCardProps) => {
  const { header, content } = useStyles();

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ align: "center" }}
        className={header}
        title={title}
      />
      <CardContent className={content}>
        {fetching ? (
          <CircularProgress />
        ) : (
          <Typography variant="h1" component="div" align="center">
            {price}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover $actions": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  header: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  content: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    "&:last-child": {
      paddingBottom: theme.spacing(4),
    },
  },
}));
