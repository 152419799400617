import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { updateEthernetCustomerData } from "../../../../store/ethernetProducts/actionsCreators";
import { ethernetPurchaseArgsValidationErrors } from "../../../../store/ethernetProducts/selectors";

class FormTextField extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    param: PropTypes.string.isRequired,
    hintText: PropTypes.string,
    muiSettings: PropTypes.object,
    index: PropTypes.number.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    errorText: PropTypes.string.isRequired,
    showError: PropTypes.bool.isRequired
  };

  static defaultProps = {
    errorText: "This is a required field"
  };

  shouldComponentUpdate(nextProps, nextState) {
    const config = props => props.configurations[this.props.index];
    if (
      config(nextProps).purchaseArgs[nextProps.param] !==
      config(this.props).purchaseArgs[this.props.param]
    )
      return true;
    if (
      config(nextProps).purchaseResponse !== config(this.props).purchaseResponse
    )
      return true;

    if (nextProps.showError !== this.props.showError) {
      return true;
    }

    return false;
  }

  render() {
    const {
      label,
      param,
      configurations,
      hintText,
      muiSettings,
      updateEthernetCustomerData,
      index,
      errors,
      errorText,
      showError
    } = this.props;
    const configuration = configurations[index];
    const hasError = errors.includes(param);

    return (
      <TextField
        label={label}
        helperText={showError && hasError ? errorText : hintText}
        error={showError && hasError}
        fullWidth
        onChange={event =>
          updateEthernetCustomerData(index, param, event.target.value)
        }
        value={configuration.purchaseArgs[param] || ""}
        disabled={configuration.purchaseIsRequesting}
        {...muiSettings}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    configurations: state.ethernetProducts.configurations,
    errors: ethernetPurchaseArgsValidationErrors(state, ownProps.index),
    showError: state.ethernetProducts.configurationIndexesSubmitted.includes(
      ownProps.index
    )
  };
};

const mapDispatchProps = dispatch => {
  return {
    updateEthernetCustomerData: (index, key, value) => {
      dispatch(updateEthernetCustomerData(index, key, value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchProps)(FormTextField);
