import { kongAxios, objectToFormData } from "../../core/kongAxios";

/**
 * Create a new lead (aka Prospect)
 */
export const LeadsCreate = (params: LeadCreateParams) =>
  kongAxios.post<LeadsCreateResponse>(
    "ExternalServices/v1/Accounts/Leads/Create",
    objectToFormData(params)
  );

export type LeadCreateParams = {
  first_name: string;
  last_name: string;
  email: string;
  name: string;
};

export type LeadsCreateResponse = {
  data?: {
    lead?: {
      id: string;
      lead_number: string;
    };
  };
  message?: string;
  status: string;
};
