export const WLR = "wlr";
export const FTTC = "FTTC";

export const TRACKING_DETAILS = "tracking-details";
export const HARDWARE_DELIVERY = "HARDWARE_DELIVERY";

//SHIPPING
export const FETCH_SHIPPING_METHODS = "FETCH_SHIPPING_METHODS";
export const RECEIVE_SHIPPING_METHODS = "RECEIVE_SHIPPING_METHODS";
export const RECEIVE_UPDATING_SHIPPING_METHOD_IN_API =
  "RECEIVE_UPDATING_SHIPPING_METHOD_IN_API";
export const UPDATING_SHIPPING_METHOD_IN_STATE =
  "UPDATING_SHIPPING_METHOD_IN_STATE";

//Validation
export const MAX_NUMBER_OF_PRODUCT_QUANTITY = 20;

//FIELD TYPES
export const FIELD_TYPE_TEXT_INPUT = "text";
export const FIELD_TYPE_SELECT = "select";
export const FIELD_TYPE_SWITCH = "switch";
export const FIELD_TYPE_OTHER = "other";
export const TEXT = "TEXT";
export const INT = "INT";
export const DECIMAL = "DECIMAL";
export const DATE = "DATE";
export const BOOLEAN = "BOOLEAN";
export const SELECT = "SELECT";
export const PORT = "port";
export const NEW = "new";

//LINE TYPE
export const SINGLE = "SINGLE";
export const MULTI = "MULTI";

export const ERROR = "ERROR";

//ADDRESS CATEGORIES
export const GOLD = "GOLD";

//LINE TYPES
export const PSTN_SINGLE_LINE = "PSTN_SINGLE_LINE";
export const PSTN_SINGLE_LINE_RES = "PSTN_SINGLE_LINE_RES";
export const PSTN_SINGLE_LINE_BUS = "PSTN_SINGLE_LINE_BUS";
export const PSTN_MULTI_LINE_AUX = "PSTN_MULTI_LINE_AUX";
export const ISDN2E_SYSTEM = "ISDN2E_SYSTEM";
export const ISDN2E_STANDARD = "ISDN2E_STANDARD";
export const ISDN30E = "ISDN30E";
export const WLR3_ISDN30_ETSI = "WLR3_ISDN30_ETSI";
export const ISDN30_DASS = "ISDN30_DASS";
