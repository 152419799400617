import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Theme, makeStyles } from "@material-ui/core";
import {
  getOneOffOrderTotal,
  getRecurringOrderTotal
} from "../../../../store/order/selectors";
import { saveDraftAction } from "../../../../store/drafts/actionCreators";
import { Prices } from "./prices";
import { Options } from "./options";
import { BasketButton } from "./basket-button";

export const Basket = () => {
  const classes = useStyles();

  const { totalOneOffPrice, totalRecurringPrice } = useSelector(
    (state: any) => ({
      totalOneOffPrice: getOneOffOrderTotal(state),
      totalRecurringPrice: getRecurringOrderTotal(state)
    })
  );
  const dispatch = useDispatch();

  const saveDraft = () => {
    dispatch(saveDraftAction());
  };

  return (
    <Box className={classes.basket}>
      <BasketButton
        data={[]}
        totalCount={0}
        totalRecurringPrice={totalRecurringPrice}
        totalOneOffPrice={totalOneOffPrice}
      />
      <Prices
        monthlyPrice={totalRecurringPrice}
        upFrontPrice={totalOneOffPrice}
      />
      <Options saveDraft={saveDraft} />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  basket: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[100]
  }
}));
