import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LayersIcon from "@material-ui/icons/Layers";

/**
 * This component shows the evo services a (resignable) product instance is composed of.
 * The main `Description` field for the instance does not always match what the tariffs actually are
 * and tariffs can be made up of both voice and data components, hence this.
 *
 * Description fields have sometimes been created initially, then the service mix has been altered,
 * leading to inaccuracies... I think. @ianc advised.
 *
 */

const StyledDrillDown = styled.div`
  .titleRow {
    display: flex;
    align-items: center;
  }
  .children {
    flex: 1;
  }
  .expander {
    text-align: right;
    cursor: pointer;
  }
  .services {
    padding: 10px 0;
  }
  .service {
    color: ${p => p.theme.palette.text.disabled};
    margin-left: 5px;
  }
`;

class EvoServicesDrillDown extends React.Component {
  static propTypes = {
    evo_services: PropTypes.array.isRequired
  };

  state = {
    expanded: false
  };

  toggleExpand = () => this.setState({ expanded: !this.state.expanded });

  render() {
    const { children, evo_services } = this.props;
    const { expanded } = this.state;
    const filteredServices = evo_services.filter(
      s => s.ServiceType !== "bolt_on"
    );
    return (
      <StyledDrillDown>
        <div className="titleRow">
          <div className="children">{children}</div>
          {/*<div className="expander" onClick={this.toggleExpand}>*/}
          <IconButton
            onClick={this.toggleExpand}
            disabled={filteredServices.length < 1}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          {/*</div>*/}
        </div>
        {expanded && (
          <div className="services">
            {evo_services
              .filter(s => s.ServiceType !== "bolt_on")
              .map((s, i) => (
                <div className="service" key={i}>
                  <LayersIcon /> {s.Description}
                </div>
              ))}
          </div>
        )}
      </StyledDrillDown>
    );
  }
}

export default EvoServicesDrillDown;
