import React from "react";
import { ActionButton } from "./ActionButton";
import { useStartNewOrder } from "../../../store/root/hooks";
import { useHistory } from "react-router-dom";
import { useAuth } from "@akj-dev/affinity-platform";

/**
 * View Sales Status Button
 * Platform only
 * This needs to be a separate component as useHistory hook fails without React Router
 *
 * @constructor
 */
export const ViewSalesStatusButton: React.FC = () => {
  let history = useHistory();
  const startNewOrder = useStartNewOrder();
  const { accountSettings } = useAuth();

  const handleViewSalesStatus = () => {
    startNewOrder();
    history.push("/sales/status/completed");
  };

  // Users like LE, with this horrible "account divorce" hack in DC, won't be able to see status of orders as they are
  // no longer connected to the head account apparently. FML
  if (accountSettings?.as_create_new_account_as_sub_account === "1")
    return null;

  return (
    <ActionButton variant="contained" onClick={handleViewSalesStatus}>
      View Sales Status
    </ActionButton>
  );
};
