import React from "react";
import CenterVh from "./CenterVH";
import { CircularProgress, Typography, Button, Box } from "@material-ui/core";
import { Error } from "@material-ui/icons";

interface FullScreenStatusProps {
  statusType: "loading" | "error";
  message: string;
  action?: () => any;
  actionLabel?: string;
}

export const FullScreenStatus: React.FC<FullScreenStatusProps> = ({
  statusType,
  message,
  action,
  actionLabel = "Retry"
}) => (
  <CenterVh>
    {statusType === "loading" && <CircularProgress />}
    {statusType === "error" && <Error color="error" fontSize="large" />}
    <Box m={1}>
      <Typography variant="h4">{message}</Typography>
    </Box>
    {action && (
      <Button variant="contained" color="secondary" onClick={action}>
        {actionLabel}
      </Button>
    )}
  </CenterVh>
);
