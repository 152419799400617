import React from "react";
import PropTypes from "prop-types";
import ErrorIcon from "@material-ui/icons/Error";
import ArrowIcon from "@material-ui/icons/KeyboardArrowRight";
import CheckIcon from "@material-ui/icons/CheckCircle";
import Spinner from "../../../components/Shared/Spinner";
import { getIsConfigValid } from "../../../store/wlrBroadband/selectors";

const ConfigurationStatus = ({ configuration, onClick }) => {
  // Local validation check...
  // let configured = true
  // for(let prop in configuration.validation) {
  //     if(configuration.validation[prop].length > 0) configured = false
  // }

  // DC validation check

  const fetching =
    configuration.wlrValidation.fetching ||
    configuration.broadbandValidation.fetching;
  const configured = getIsConfigValid(configuration);

  return (
    <div className="ConfigurationStatus">
      {fetching ? (
        <Spinner size={30} />
      ) : (
        <span>
          {configured ? (
            <CheckIcon htmlColor="#4caf50" data-cy="wlrConfigStatusOK" />
          ) : (
            <div className="false" onClick={onClick}>
              <ErrorIcon htmlColor="#f44336" />
              <ArrowIcon htmlColor="#f44336" />
            </div>
          )}
        </span>
      )}
    </div>
  );
};

ConfigurationStatus.propTypes = {
  configuration: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default ConfigurationStatus;
