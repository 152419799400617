import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Icon from "@material-ui/icons/SwapVert";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel
} from "@material-ui/core";
import Section from "../../../../../../components/Shared/Section";
import { StatusChip } from "@akj-dev/design-system";
import ChooseAvailable from "./ChooseAvailable";
import {
  NUMBER_RES_CHOOSE,
  NUMBER_RES_CUSTOM,
  NUMBER_RES_NEXT
} from "../../../../../../store/wlrBroadband/constants";
import {
  doNumberReservation,
  setNumberReservationType,
  setReservedNumber
} from "../../../../../../store/wlrBroadband/actions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

class NumberReservation extends Component {
  static propTypes = {
    targetConfigs: PropTypes.array.isRequired
  };

  render() {
    const {
      targetConfigs,
      numberReservation,
      setNumberReservationType,
      setReservedNumber,
      doNumberReservation
    } = this.props;

    if (this.props.targetConfigs.length > 1)
      return (
        <StatusChip
          type="info"
          title="Number reservations cannot be bulk edited"
          message="Please edit configurations individually"
        />
      );

    const disable = numberReservation.response.status === "success";

    return (
      <Section title="Number Reservation" avatar={<Icon />}>
        <FormControl fullWidth>
          <RadioGroup
            aria-label="number reservation"
            name="numberReservation"
            value={numberReservation.type}
            onChange={(e, v) => setNumberReservationType(targetConfigs[0], v)}
          >
            <FormControlLabel
              control={<Radio />}
              value={NUMBER_RES_NEXT}
              label="Reserve next available number"
              disabled={disable}
            />
            <FormControlLabel
              control={<Radio />}
              value={NUMBER_RES_CUSTOM}
              label="Reserve custom number"
              disabled={disable}
            />
            <FormControlLabel
              control={<Radio />}
              value={NUMBER_RES_CHOOSE}
              label="Choose from available numbers"
              disabled={disable}
            />
          </RadioGroup>
        </FormControl>

        {numberReservation.type === NUMBER_RES_CUSTOM && (
          <TextField
            label="Enter your number"
            onChange={event =>
              setReservedNumber(targetConfigs[0], event.target.value)
            }
            value={numberReservation.selectedNumber}
            fullWidth
          />
        )}
        {numberReservation.type === NUMBER_RES_CHOOSE && (
          <ChooseAvailable targetConfigs={targetConfigs} />
        )}
        <Button
          variant="contained"
          color="secondary"
          data-cy="NumberReservationButton"
          disabled={disable}
          onClick={() => doNumberReservation(targetConfigs[0])}
          style={{ marginTop: 20 }}
        >
          Reserve Now
        </Button>

        <div style={{ height: 20 }} />

        {numberReservation.fetching ? (
          <StatusChip
            type="loading"
            title="Reserving number"
            message="Please wait"
          />
        ) : (
          <div>
            {numberReservation.response.reservation_reference && (
              <StatusChip
                type="success"
                title="Number Reserved"
                message={
                  <span>
                    CLI: <b>{numberReservation.response.reserved_number}</b>
                    <br />
                    This number is only reserved for 4 hours.
                    <br />
                    If the contract is signed after 4 hours of receipt a new
                    number will be given.
                  </span>
                }
              />
            )}

            {numberReservation.response.status === "error" && (
              <StatusChip
                type="error"
                title="Sorry, something went wrong."
                message={numberReservation.response.message}
              />
            )}
          </div>
        )}
      </Section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    numberReservation:
      state.wlrBroadband.configurations[ownProps.targetConfigs[0]]
        .numberReservation
  };
};

export default connect(mapStateToProps, {
  setNumberReservationType,
  setReservedNumber,
  doNumberReservation
})(NumberReservation);
