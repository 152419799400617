import {
  Box,
  Chip,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import InfoIcon from "@material-ui/icons/Info";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThirdPartyBillingCreditVetStatusResponse } from "../../../../api/v1/account";
import { requestCreditVet } from "../../../../store/account/actions";

const useStyles = makeStyles(theme => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    background: theme.palette.grey[50],
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(2.5) // this extra 1 spacing value is to offset something that has been set on the main page wrapper. TODO figure out what that is!
  },
  chip: {
    background: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    marginLeft: 4,
    marginRight: 4
  }
}));

export const CreditVetStatus = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const creditVetState = useSelector((state: any) => state.account.creditVet);
  const hasError = creditVetState.hasError;
  const fetching = creditVetState.fetching;
  const status: ThirdPartyBillingCreditVetStatusResponse =
    creditVetState.response;

  const handleRefreshClick = () => {
    if (!fetching) {
      dispatch(requestCreditVet());
    }
  };

  const calculateRemainingConnections = () => {
    const maxConnections = status.data?.credit_check_value || 0;
    const inFlightConnections = status.data?.in_flight_connections_count || 0;
    return Math.max(0, maxConnections - inFlightConnections);
  };

  const getStatusHelperIcon = () => {
    if (hasError || status.data?.credit_check_status === "Refer") {
      const message = hasError
        ? "Please refresh the Credit Vet request. If this error continues, please contact the DWS Support Team."
        : "DWS will review the Credit Vet result with Vodafone and contact you to discuss further.";
      return (
        <Tooltip placement="right" title={message}>
          <IconButton aria-label="status info" size="small">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return null;
  };

  return (
    <Paper className={classes.root} data-cy="CreditVetStatus">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={0.5}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2">
            Account Status:{" "}
            {hasError ? "Error" : status.data?.credit_check_status}
          </Typography>
          {getStatusHelperIcon()}
        </Box>
        <Box display="flex" alignItems="center">
          {status.data?.credit_check_status === "Accepted" && (
            <>
              <Typography variant="subtitle2">
                Remaining Available Connections
              </Typography>
              <Chip
                className={classes.chip}
                size="small"
                label={fetching ? "..." : calculateRemainingConnections()}
              />
            </>
          )}

          <IconButton
            size="small"
            aria-label="refresh"
            color="primary"
            onClick={handleRefreshClick}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};
