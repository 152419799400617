import _ from "lodash";
import { RESIGN_WITH_CHANGES, RESIGN_WITHOUT_CHANGES } from "../constants";
import { getDaisyFreshPerLineAmount } from "./daisyFresh";
import {
  getProductSupplier,
  getResignProductInstanceByConfig,
  getResignWithoutChangeProduct
} from "./resigns";
import flatten from "flat";
import * as OrderProductAPI from "../../../api/v1/orderProduct";
import * as actionTypes from "../actionTypes";
import { addAlertMessage } from "../../uiState/actions";
import { getDeliveryParams } from "../../hardwareDelivery/selectors";
import { createSelector } from "reselect";

/**
 * Build params necessary to order a mobile product from DC
 * ...or fetch product data for it.
 * @param state
 * @param configIndex
 * @param isProductDataRequest (boolean)
 */
export function getMobileDCOrderParams(
  state,
  configIndex,
  isProductDataRequest
) {
  const config = state.mobile.configs[configIndex];

  // These params are common to all types of config
  let params = {
    contract_length_in_months: state.mobile.contractLengthInMonthsAllProducts,
    account_id: state.order.accountId,
    order_id: state.order.id
  };

  // The product ID is normally directly in the config too. However there are exceptions...
  let productId = config.productId;

  const productData = _.get(
    state.mobile,
    `productData[${config.productId}].response`
  );

  // If it's a resign, add the product instance ID, so DC knows what's being resigned.
  if (config.resignType === RESIGN_WITH_CHANGES) {
    params["mobile-product_instance_id"] = getResignProductInstanceByConfig(
      state,
      configIndex
    ).id;

    // Also, if we're resigning to a data only product (i.e. mobile broadband), it needs adding as a linked product to a generic voice one.
    // Data only tariffs can't exist without a parent voice product, hence this.
    // It's not necessary for new orders as there's some "appalling hackery" in DC to do it automatically apparently.
    // This has not been duplicated for resigns though.
    //
    // To add MORE complexity to this, we don't want to do this if it's just a product data request we're running
    // (to get updated pricing data) as the generic product won't have those params... obviously.
    if (
      productData.mobile.product_component_data.is_data_only &&
      !isProductDataRequest
    ) {
      const genericVoiceProduct = state.mobile.mobileSearch.response.products.find(
        p =>
          p.first_mobile_component.is_data_only_voice &&
          p.first_mobile_component.supplier ===
            productData.mobile.product_component_data.supplier
      );
      if (!genericVoiceProduct) {
        console.error(
          "Generic voice product not found in DC. This is required for MBB. Please contact support. "
        );
      }
      productId = genericVoiceProduct.id;
      params["mobile-gprs_tariff_json"] = JSON.stringify({
        product_id: config.productId,
        price: productData.overall_price.recurring_price_with_discounts,
        name: productData.product_name
      });
    }

    // If it's a voice only tariff, the data component of the old tariff needs cancelling.
    // So, basically, GS should send -1 under `mobile-gprs_tariff_json` whenever the new product the user has
    // chosen to resign to has a property `has_data_network_code: 0`
    //
    // Summary of this DC functionality from @ianc:
    //
    // The product instance is currently on voice tariff v0, GPRS tariff g0;
    //
    // GSW resigns onto voice product v1 & possibly GPRS product g1.
    //
    // v1 has_voice_network_code = 1, has_data_network_code = 0
    //
    //    GSW sends no GPRS     -> GPRS product equivalent to g0 is added to order, instance remains on g0
    //    GSW sends -1          -> no gprs added to order, g0 is removed from the instance
    //    GSW sends g1 attached -> instance is switched from g0 to g1
    //
    //
    // v1 has_voice_network_code = 1, has_data_network_code = 1
    //
    //    GSW sends no GPRS     -> no gprs added to order, instance is switched to v1's gprs tariff
    //    GSW sends -1          -> no gprs added to order, instance is switched to v1's gprs tariff
    //    GSW sends g1 attached -> g1 is added to order, instance is switched to g1 (overwriting v1's gprs tariff

    // eslint-disable-next-line eqeqeq
    if (productData.mobile.product_component_data.has_data_network_code == 0) {
      params["mobile-gprs_tariff_json"] = JSON.stringify({
        product_id: "-1"
      });
    }
  }

  // Resign without changes is not a mobile product, so the ID is passed differently
  // It also uses a special product we had to find earlier.
  // Could be different IDs depending on user / whitelists etc. according to @ianc
  if (config.resignType === RESIGN_WITHOUT_CHANGES) {
    params[
      "extra_services-product_instance_id"
    ] = getResignProductInstanceByConfig(state, configIndex).id;
    productId = getResignWithoutChangeProduct(state);
  }

  // If it's a normal product or a "resign with changes",
  // that is basically a normal product with a previous product's instance ID attached
  // map the original product data over to request params
  if (config.resignType !== RESIGN_WITHOUT_CHANGES) {
    const { dynamic_properties } = productData.mobile;

    // Copy any product data properties that have a current value
    for (const prop in dynamic_properties) {
      if (prop !== "bill_limit_info") {
        // except that one... which shouldn't be there according to @davet
        const value = dynamic_properties[prop].current_value;
        if (value && value !== "no" && value !== "0") {
          params[`mobile-${prop}`] = value;
        }
      }
    }

    // Copy any product data properties related to user defined fields that have a current value
    const rootDynamicProperties = productData.dynamic_properties;
    for (const prop in rootDynamicProperties) {
      if (prop.includes("user_defined_field_")) {
        const value = rootDynamicProperties[prop].current_value;
        if (value && value !== "no" && value !== "0") {
          params[prop] = value;
        }
      }
    }

    // ....and now overwrite the result (i.e. defaults) with any user / store updated properties
    for (let prop in config.properties) {
      // User defined fields sit at product level, so therefore shouldn't be prefixed with 'mobile': TP20164
      if (prop.includes("user_defined_field_")) {
        params[prop] = config.properties[prop];
      } else params[`mobile-${prop}`] = config.properties[prop];
    }

    // Select correct acquisition method.
    // `port` if moving to a different network, `migrate` if the same
    if (params["mobile-acquisition_method"] === "port/mig") {
      const pac_network = _.get(config, "pacCodeCheck.response.result.no_code");
      const new_network = _.get(
        productData,
        "mobile.product_component_data.service_provider.mobile_network_operator_id"
      );
      params["mobile-acquisition_method"] =
        new_network === pac_network ? "migration" : "port";
      if (!pac_network)
        console.error("port/mig type config with bad PAC request", config); // TODO: Throw this error to UI?
    }

    // If it's a resign with changes, add more params as well:
    if (config.resignType === RESIGN_WITH_CHANGES) {
      params["mobile-acquisition_method"] = "clone_resign";
      params["mobile-product_instance_type"] = "Resign";
    }

    // Internal port is a type of "resign with change" when the provider is changing, not just the tariff. eg. Voda to O2
    // When this happens, a SIM needs to be sent, and we need to tell DC to do so (advised by @ianc)
    // ....except when it'd a Dise -> O2 internal port (as advised by @lisa) hence disabled this for now.
    // (Field was exposed in the UI then user "no" selection was being overridden
    // if(config.properties.acquisition_method === 'internal_port') {
    //     params['mobile-is_sim_required'] = 1
    // }

    // Set whether full provisioning data has been entered. Skips checks if not.
    params["mobile-has_gathered_provisioning_data"] = state.order.quoteOnly
      ? "0"
      : "1";

    // Set SIM type to always be Triple. This is specified in src/js/store/mobile/defaults.js but overwritten by
    // subsequent DC product data for resigns. It should always be triple apparently.
    // See: https://gitlab.com/akj-dev/inbox/issues/240
    // 14/01/2021: Stop hardcoding value for O2 configurations as per TP13536.
    const supplier = getProductSupplier(state.mobile, productId);
    if (supplier !== "O2") params["mobile-sim_type"] = "triple";

    // Apply Daisy Fresh
    // Note: Product ID was 28985 last time I checked.
    const daisyFreshAmount = getDaisyFreshPerLineAmount(state);
    if (daisyFreshAmount > 0) {
      const daisyFreshProduct =
        state.mobile.daisyFreshSearch.response.products[0];
      params[
        "mobile-bolt_on_" + daisyFreshProduct.components[0].bolt_on_type
      ] = JSON.stringify({
        // Yes... this does need to be stringified here :-( API issue.
        product_id: daisyFreshProduct.id,
        price: daisyFreshAmount
      });
    }

    // Apply selected CLI level bolt-ons
    // Note: Apparently Daisy Fresh shouldn't conflict with this, as it's type is additional_bundle_1, for which no others will be available.
    // Silly that it doesn't have it's own type, but apparently that's a load of DC dev.
    // Update: TODO: There are lots of bolt-ons with type additional_bundle_1. They will conflict.
    if (config.selectedCliBoltOns) {
      for (const type in config.selectedCliBoltOns) {
        params[`mobile-bolt_on_${type}`] = JSON.stringify({
          product_id: config.selectedCliBoltOns[type]
        });
      }
    }
  }

  // For any type of resign, add a start date if the user chose one.
  if (
    config.resignType === RESIGN_WITH_CHANGES ||
    config.resignType === RESIGN_WITHOUT_CHANGES
  ) {
    if (state.mobile.resignStartDate) {
      params["mobile-activation_date"] = state.mobile.resignStartDate;
    }
  }

  // Add delivery address
  params = {
    ...params,
    // Note: While this is called hardware, it seems to apply to everything. Tariffs have delivery address for sim etc.
    ...getDeliveryParams(state)
  };

  return { params, productId };
}
/**
 * Make mobile tariff OrderProduct calls.
 * TODO: Move to an actionCreators file.
 * @param retry {boolean}
 * @param quoteOnly {boolean}
 * @returns {Function}
 */
export const orderMobileTariffs = (retry = false, quoteOnly = false) => async (
  dispatch,
  getState
) => {
  const state = getState();

  await Promise.all(
    state.mobile.configs.map(async (config, i) => {
      const { params, productId } = getMobileDCOrderParams(state, i, false);
      // DC needs to run run different validation for quotes and has no good way of knowing this is one
      params["quoting_only"] = quoteOnly;

      // Check if the product's already in the order. (If it is, update instead of create)
      const orderProductId = _.get(config, "orderProduct.response.data.id");

      // If we're just retrying, skip if there's an order product ID.
      // Retry will most likely be one or two products in the order that DC has failed on, so we shouldn't re-run
      // everything for performance reasons.
      if (retry && orderProductId) return;

      // If we already have an order product ID, said product should already be in the order in DC, so just update it
      // TODO: This logic should probably be generic across products, but there's lots of complexity to doing that.
      const shouldUpdate = !!orderProductId;

      // And now make the order call itself....
      dispatch({
        type: actionTypes.REQUEST_ORDER_PRODUCT,
        configIndex: i,
        isUpdate: shouldUpdate
      });

      const response = shouldUpdate
        ? await OrderProductAPI.update(orderProductId, params)
        : await OrderProductAPI.create(productId, params);

      dispatch({
        type: actionTypes.RECEIVE_ORDER_PRODUCT,
        configIndex: i,
        response
      });
    })
  );
};

/**
 * Builds bolt on requests, if we have bolt-ons.
 * @returns {function(*, *): Array}
 */
export const orderBoltOns = () => async (dispatch, getState) => {
  const state = getState();
  if (!state.mobile.boltOnSearch.response.products) return;

  const boltOns = _.values(flatten(state.mobile.selectedBoltOns));
  const { boltOnStartDate } = state.mobile;
  const { quoteOnly } = state.order;

  await Promise.all(
    boltOns.map(async (productId, i) => {
      const boltOn = state.mobile.boltOnSearch.response.products.find(
        p => p.id === productId
      );

      // If a bolt-on's been specified, add an order request for it
      if (boltOn) {
        let params = {
          account_id: state.order.accountId,
          order_id: state.order.id,
          use_billing_address: 1,
          contract_length_in_months:
            state.mobile.contractLengthInMonthsAllProducts,
          ...(boltOnStartDate && {
            "mobile_bolt_on-activation_date": boltOnStartDate
          }), // TODO: Can this be undefined? It will be if none is specified in UI
          "mobile-has_gathered_provisioning_data": quoteOnly ? "0" : "1"
        };

        // Check if the product's already in the order. (If it is, update instead of create)
        const orderProductId = _.get(
          state.mobile.orderBoltOn,
          `${productId}.response.data.id`
        );

        const doUpdate = !!orderProductId;

        dispatch({
          type: actionTypes.REQUEST_ORDER_BOLT_ON,
          productId,
          isUpdate: doUpdate
        });

        const response = doUpdate
          ? await OrderProductAPI.update(orderProductId, params)
          : await OrderProductAPI.create(productId, params);

        dispatch({
          type: actionTypes.RECEIVE_ORDER_BOLT_ON,
          productId,
          response
        });
      }
    })
  );
};

/**
 * Add optional shipping product
 * TODO: Move this to a proper structure inside the orders reducer & show progress in UI. It's not just a mobile concern
 * TODO: Isn't this going to double up shipping when a quote is generated / order is updated?
 * @returns {Function}
 */
export const orderShipping = () => async (dispatch, getState) => {
  const {
    order,
    shipping: { chosenProductId }
  } = getState();
  if (chosenProductId > 0) {
    const response = await OrderProductAPI.create(chosenProductId, {
      account_id: order.accountId,
      order_id: order.id
    });
    if (response.status !== "success")
      dispatch(
        addAlertMessage(`Problem adding shipping product: ${response.message}`)
      );
  }
};

/**
 * Add hardware credits to the order, if they've been specified by Daisy Fresh (added in `orderMobileTariffs`)
 * This is the total amount of credit Daisy Fresh will accrue over the length of the contract.
 * As advised by @lisa and @ianc
 *
 * @returns {Function}
 */
export const orderHardwareCredits = () => async (dispatch, getState) => {
  const {
    order,
    mobile: {
      daisyFreshAmounts: { hardwareCredits },
      hardwareCreditProductSearch,
      hardwareCredit
    }
  } = getState();

  // No credit added?
  if (!hardwareCredits) return false;

  // Get product ID
  const productId = _.get(
    hardwareCreditProductSearch,
    "response.products[0].id"
  );
  if (!productId) {
    dispatch({
      type: actionTypes.RECEIVE_HARDWARE_CREDIT,
      response: {
        status: "error",
        message: "Hardware credit product ID not found."
      }
    });
    return false;
  }

  // Check if the call's been made already
  const orderProductId = hardwareCredit.response?.data?.id;
  const shouldUpdate = !!orderProductId;

  // Add or update the credit
  dispatch({
    type: actionTypes.REQUEST_HARDWARE_CREDIT,
    isUpdate: shouldUpdate
  });

  const params = {
    account_id: order.accountId,
    order_id: order.id,
    "credit-credit_value": hardwareCredits
  };
  const response = shouldUpdate
    ? await OrderProductAPI.update(orderProductId, params)
    : await OrderProductAPI.create(productId, params);

  dispatch({ type: actionTypes.RECEIVE_HARDWARE_CREDIT, response });
};

/**
 * Returns true if any mobile products are in the process of being ordered.
 * Used for disabling navigation in final step.
 * @param state
 * @returns {boolean}
 */
export const mobileOrderProductCallsFetching = createSelector(
  [state => state.mobile.configs],
  configs =>
    configs.reduce(
      (fetching, config) => fetching || _.get(config, "orderProduct.fetching"),
      false
    )
);

export const getNumberOfMobileConfigs = createSelector(
  [state => state.mobile.configs],
  configs => configs.length || 0
);
