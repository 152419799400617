import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DateIcon from "@material-ui/icons/DateRange";
import Section from "../../../../../../components/Shared/Section";
import { StatusChip } from "@akj-dev/design-system";
import ChooseBroadbandAppointment from "./ChooseBroadbandAppointment";
import ChooseWlrAppointment from "./ChooseWlrAppointment";
import {
  getRequiresBBAppointment,
  getRequiresWLRAppointment
} from "../../../../../../store/wlrBroadband/selectors";

class Appointment extends Component {
  static propTypes = {
    targetConfigs: PropTypes.array
  };

  render() {
    const {
      requiresWLRAppointment,
      requiresBBAppointment,
      targetConfigs,
      sparePairs
    } = this.props;

    /**
         WLR:
         appointment_date
         appointment_expiry
         appointment_reference
         appointment_timeslot

         BB:
         bb.appointment_expiry
         bb.appointment.accessHazards
         bb.appointment.appointmentNotes
         bb.appointment.appointmentReference
         and maybe....
         bb.appointment.contact.contactEmail
         bb.appointment.contact.contactForename
         bb.appointment.contact.contactSurname
         bb.appointment.contact.contactTelephone
         bb.appointment.contactPassword

         */

    if (requiresWLRAppointment || requiresBBAppointment) {
      if (targetConfigs.length < 2) {
        return (
          <Section title="Appointment" avatar={<DateIcon />}>
            {requiresWLRAppointment && (
              <ChooseWlrAppointment targetConfig={targetConfigs[0]} />
            )}
            {requiresBBAppointment && (
              <ChooseBroadbandAppointment targetConfig={targetConfigs[0]} />
            )}
            {sparePairs == 0 && ( // eslint-disable-line eqeqeq
              <StatusChip
                type="info"
                title="No spare pairs available."
                message="Do not confirm appointment time with customer."
              />
            )}
          </Section>
        );
      } else {
        return (
          <StatusChip
            type="info"
            title="Appointments cannot be bulk edited."
            message="Please choose them per line."
          />
        );
      }
    } else {
      return false;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const locationIndex =
    state.wlrBroadband.configurations[ownProps.targetConfigs[0]].locationIndex;
  return {
    requiresWLRAppointment: getRequiresWLRAppointment(state, ownProps),
    requiresBBAppointment: getRequiresBBAppointment(state, ownProps),
    sparePairs: _.get(
      state.wlrBroadband.locations[locationIndex].lineAvailability.response,
      "dp_spare_pairs"
    )
  };
};

export default connect(mapStateToProps)(Appointment);
