import React, { useState, useEffect } from "react";
import LocationIcon from "@material-ui/icons/LocationOn";
import { ExpandingCard } from "@akj-dev/design-system";
import FormTextField from "../FormTextField";
import FormSelectField from "../FormSelectField";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { ethernetPurchaseArgsValidationErrors } from "../../../../../store/ethernetProducts/selectors";

const Location = ({ prefix, index }) => {
  const [expanded, setExpanded] = useState(true);

  const validationFields = [
    prefix + "_site_address_floor",
    prefix + "_site_address_room",
    prefix + "_mounting_type"
  ];

  const validationErrors = useSelector(state =>
    ethernetPurchaseArgsValidationErrors(state, index)
  ).filter(field => validationFields.includes(field));

  const showErrors = useSelector(state =>
    state.ethernetProducts.configurationIndexesSubmitted.includes(index)
  );

  const forceExpanded = showErrors && validationErrors.length > 0;

  const handleHeaderClick = () => {
    if (forceExpanded) {
      return;
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (forceExpanded) {
      setExpanded(true);
    }
  }, [forceExpanded]);

  return (
    <ExpandingCard
      title="Location"
      avatar={<LocationIcon />}
      onHeaderClick={handleHeaderClick}
      expanded={forceExpanded || expanded}
    >
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <FormTextField
            label="Floor *"
            param={prefix + "_site_address_floor"}
            index={index}
          />
        </Grid>
        <Grid item sm={4}>
          <FormTextField
            label="Room *"
            param={prefix + "_site_address_room"}
            index={index}
          />
        </Grid>
        <Grid item sm={4}>
          <FormSelectField
            helperText="Mounting Type *"
            param={prefix + "_mounting_type"}
            options={{
              "Rack Mounted": "Rack Mounted",
              "Wall Mounted": "Wall Mounted",
              "Shelf Mounted": "Shelf Mounted",
              Other: "Other"
            }}
            index={index}
          />
        </Grid>
        <Grid item sm={4}>
          <FormTextField
            label="Cabinet"
            param={prefix + "_cabinet"}
            index={index}
          />
        </Grid>
        <Grid item sm={4}>
          <FormTextField
            label="Shelf"
            param={prefix + "_shelf"}
            index={index}
          />
        </Grid>
        <Grid item sm={12}>
          <FormTextField
            label="Additional install requirements"
            param={prefix + "_install_requirements"}
            hintText="e.g. Exact location for router."
            muiSettings={{ rows: 2, fullWidth: true }}
            index={index}
          />
        </Grid>
      </Grid>
    </ExpandingCard>
  );
};

export default Location;
