import React from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

function PricingScheme({
  available_pricing_schemes,
  pricing_scheme_details,
  pricing_scheme,
  setPricingScheme
}) {
  return (
    <div>
      {available_pricing_schemes && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Pricing Scheme</InputLabel>
          <Select
            value={pricing_scheme || available_pricing_schemes[0]}
            onChange={event => setPricingScheme(event.target.value)}
          >
            {available_pricing_schemes.map((scheme, i) => (
              <MenuItem value={scheme} key={i}>
                {pricing_scheme_details[scheme].pricing_scheme_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
}

export default PricingScheme;
